import React from 'react';
import styled from 'styled-components';
import type { ThemeSet } from 'styled-theming';
import { Loading } from '../../elements';
import COLORS from '../../styles/colors';

import THEMES from '../../styles/themes/app';

const MessageContainer = styled.div<{
  loading: boolean;
  background: ThemeSet;
  hasBorder: boolean;
}>`
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  border: ${(props) =>
    props.hasBorder ? `1px solid ${COLORS.PK.GREY_30}` : 'none'};
  width: 100%;
  min-width: 250px;
  border-radius: 23px;
  min-height: 60px;
  padding: 24px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: ${(props) => (props.loading ? 'flex' : 'inline-block')};
  justify-content: center;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  white-space: pre-line;

  & :before {
    content: '';
    position: absolute;
    z-index: 0;
    bottom: 0;
    right: -8px;
    height: 20px;
    width: 20px;
    background-color: ${THEMES.BACKGROUND_PRIMARY};
    background-attachment: fixed;
    border-bottom: ${(props) =>
      props.hasBorder ? `1px solid ${COLORS.PK.GREY_30}` : 'none'};
    border-bottom-left-radius: 15px;
  }
  & :after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: -10px;
    width: ${(props) => (props.hasBorder ? '9px' : '10px')};
    height: ${(props) => (props.hasBorder ? '21px' : '20px')};
    background: ${(props) => props.background};
    border-left: ${(props) =>
      props.hasBorder ? `1px solid ${COLORS.PK.GREY_30}` : 'none'};
    border-bottom-left-radius: 10px;
  }
`;

const LoadingWrapper = styled.div`
  height: 80%;
  display: flex;
  align-items: center;
  width: fit-content;
`;

interface MessagePreviewProps {
  background?: ThemeSet;
  children: React.ReactNode;
  hasBorder?: boolean;
  loading?: boolean;
}

const MessagePreview = ({
  background = THEMES.BACKGROUND_TERTIARY,
  children,
  hasBorder = false,
  loading = false,
}: MessagePreviewProps) => {
  return (
    <MessageContainer
      loading={loading}
      background={background}
      hasBorder={hasBorder}
    >
      {loading && (
        <LoadingWrapper>
          <Loading size="xs" />
        </LoadingWrapper>
      )}
      {!loading && children}
    </MessageContainer>
  );
};

export default MessagePreview;
