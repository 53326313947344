import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { useApolloClient, useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_TAG_FROM_ANNOUNCEMENT_MUTATION } from 'client-lib';
import styled from 'styled-components';
import Flyout from '../../Flyout2/Flyout.tsx';
import { Loading, TextArea, TextInput } from '../../../elements';
import { MAX_LIST_LENGTH } from '../../../utils/helpers/constants/index.ts';
import { openSnackbar } from '../../../actions/general';

const FlyoutWrapper = styled.div`
  padding-top: 12px;
  padding-bottom: 0;
`;

const LoadingWrapper = styled.div`
  min-width: 400px;
  max-width: 960px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
`;

const BroadcastsSaveAsFlyout = ({ isOpen, setIsOpen, announcementId }) => {
  const [description, setDescription] = React.useState('');
  const [name, setName] = React.useState('');
  const [descriptionError, setDescriptionError] = React.useState('');
  const [nameError, setNameError] = React.useState('');
  const [saving, setSaving] = React.useState(false);

  const accountId = useSelector(
    (state) => state?.session?.currentUser?.accountId
  );

  const client = useApolloClient();
  const dispatch = useDispatch();

  const [createTagFromAnnouncement] = useMutation(
    CREATE_TAG_FROM_ANNOUNCEMENT_MUTATION,
    { client }
  );

  const handleRequestClose = () => {
    setIsOpen(false);
  };

  const handleError = (error) => {
    dispatch(openSnackbar(error, 'error'));
  };

  const handleSuccess = () => {
    dispatch(openSnackbar(i18n.t('broadcasts-BTMFlyout-listSuccessful')));
    setName('');
    setDescription('');
    setIsOpen(false);
  };

  const handleInputTagChange = (e) => {
    if (e.target.value === '') {
      setNameError(i18n.t('slideouts-GroupMessageOverview-listNameRequired'));
    } else if (e.target.value.length > MAX_LIST_LENGTH) {
      setNameError(
        i18n.t('broadcasts-maximum-characterError', {
          defaultValue: "You've exceeded the 255 maximum character limit.",
        })
      );
    } else {
      setNameError('');
    }
    setName(e.target.value);
  };

  const handleInputListDescriptionChange = (e) => {
    if (e.target.value?.length > 255) {
      setDescriptionError(
        i18n.t('broadcasts-maximum-characterError', {
          defaultValue: "You've exceeded the 255 maximum character limit.",
        })
      );
    } else {
      setDescriptionError('');
    }
    setDescription(e.target.value);
  };

  const handleSaveList = async () => {
    if (!name || nameError || descriptionError) {
      return;
    }

    setSaving(true);

    const { data } = await createTagFromAnnouncement({
      variables: {
        input: {
          name,
          description,
          accountId,
          announcementId,
        },
      },
    }).catch(() => {
      handleError(i18n.t('settings-TagList-error'));
    });

    if (data?.createTagFromAnnouncement?.errors) {
      const {
        createTagFromAnnouncement: { errors },
      } = data;
      if (errors[0].reason === 'has already been taken') {
        handleError(i18n.t('settings-TagList-nameTaken'));
      }
    } else {
      handleSuccess();
    }

    setSaving(false);
  };

  return (
    <Flyout
      isOpen={isOpen}
      title={i18n.t('broadcasts-BTMFlyout-createList')}
      onRequestClose={handleRequestClose}
      dataTestId="broadcast-table-flyout"
      buttonLabels={{
        primary: i18n.t('broadcasts-BTMFlyout-create'),
        secondary: i18n.t('broadcasts-ReachableAudienceModal-close', {
          defaultValue: 'Close',
        }),
      }}
      buttonProps={{
        primary: {
          disabled: nameError || descriptionError || saving || !name,
          'data-test-id': 'flyout-save-button',
        },
        secondary: { 'data-test-id': 'flyout-close-button' },
      }}
      buttonActions={{
        primary: handleSaveList,
        secondary: handleRequestClose,
      }}
      customStyles={{ content: () => `padding-bottom: unset;` }}
    >
      {!saving && (
        <FlyoutWrapper>
          <TextInput
            label={i18n.t('modals-AddOrEditList-listName')}
            type="text"
            value={name}
            autoComplete="off"
            onChange={handleInputTagChange}
            dataTestId="flyout-editName-listFlyout"
            error={nameError}
          />
          <TextArea
            label={i18n.t('broadcasts-list-descriptionOptional', {
              defaultValue: 'description(optional)',
            })}
            value={description}
            rows={4}
            onChange={handleInputListDescriptionChange}
            dataTestId="flyout-editDescription-listFlyout"
            error={descriptionError}
          />
        </FlyoutWrapper>
      )}
      {saving && (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      )}
    </Flyout>
  );
};

BroadcastsSaveAsFlyout.propTypes = {
  announcementId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default BroadcastsSaveAsFlyout;
