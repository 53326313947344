import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import styled from 'styled-components';
import { useCustomerFaxEdit } from 'client-lib';
import { contactName } from 'client-lib/src/lib/utils/helpers';
import { useApolloClient } from '@apollo/client';
import { Modal, Text, TextInput } from '../../elements';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Subtitle = styled.div`
  margin-top: -24px;
  margin-bottom: 16px;
`;

const AddFaxNumber = ({ open, onClose, contact, onSave, onError }) => {
  const client = useApolloClient();

  const [number, formattedNumber, onChange, onSubmit] = useCustomerFaxEdit({
    client,
    onSave,
    onError,
    customer: contact,
    i18n,
  });

  return (
    <Modal
      isOpen={open}
      size="sm"
      onRequestClose={onClose}
      primaryButtonOnClick={onSubmit}
      modalTitle={i18n.t('modals-AddFaxNumber-addFax', {
        defaultValue: 'Add Fax Line',
      })}
      primaryButtonText={i18n.t('modals-AddMember-save')}
      secondaryButtonText={i18n.t('modals-AddMember-cancel')}
      primaryButtonProps={{ disabled: !formattedNumber || number.error }}
      secondaryButtonOnClick={onClose}
    >
      <ModalContent>
        <Subtitle>
          <Text>{contactName(contact)}</Text>
        </Subtitle>
        <TextInput
          label={i18n.t('modals-AddFaxNumber-faxLine', {
            defaultValue: 'Fax line',
          })}
          placeholder={i18n.t('modals-AddFaxNumber-enter', {
            defaultValue: 'Enter Fax Line',
          })}
          value={formattedNumber}
          onChange={onChange}
          error={number?.error}
        />
      </ModalContent>
    </Modal>
  );
};

AddFaxNumber.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  contact: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default AddFaxNumber;
