import { useQuery, useMutation } from "@apollo/client";
import usePaginated from "../../../api/query/usePaginated.ts";

import DIRECT_TAG_QUERY from "../../../graphql/query/DirectTagQuery.graphql";
import GROUPS_PAGINATED_QUERY from "../../../graphql/query/GroupsPaginatedQuery.graphql";

import CUSTOMERS_WITH_TAG_REFERENCE_QUERY from "../../../graphql/query/CustomersWithTagReferenceQuery.graphql";
import GROUPS_WITH_TAG_REFERENCE_QUERY from "../../../graphql/query/GroupsWithTagReferenceQuery.graphql";
import TAG_QUERY from "../../../graphql/query/TagQuery.graphql";
import UPDATE_LIST_MUTATION from "../../../graphql/mutation/UpdateTagMutation.graphql";

import REMOVE_COMPANIES_FROM_TAG_MUTATION from "../../../graphql/mutation/RemoveCompaniesFromTagMutation.graphql";
import REMOVE_CONTACTS_FROM_TAG_MUTATION from "../../../graphql/mutation/RemoveContactsFromTagMutation.graphql";
import REMOVE_GROUPS_FROM_TAG_MUTATION from "../../../graphql/mutation/RemoveGroupsFromTagMutation.graphql";

import ADD_COMPANIES_TO_TAG_MUTATION from "../../../graphql/mutation/AddCompaniesToTagMutation.graphql";
import ADD_GROUPS_TO_TAG_MUTATION from "../../../graphql/mutation/AddGroupsToTagMutation.graphql";
import ADD_CONTACTS_TO_TAG_MUTATION from "../../../graphql/mutation/AddContactsToTagMutation.graphql";
import {
  ADD_LABELS_TO_TAG_MUTATION,
  REMOVE_LABELS_FROM_TAG_MUTATION,
} from "../../..";

const NAME_ALREADY_TAKEN = "has already been taken";
const USER_REMOVING_SELF_VISIBILITY = "user_removing_self_visibility";

const useEditList = ({
  client,
  tagId,
  i18n,
  filter,
  setError,
  setSuccess,
  groupIds,
}) => {
  const [updateListMutation] = useMutation(UPDATE_LIST_MUTATION, { client });

  const [removeCompaniesFromListMutation] = useMutation(
    REMOVE_COMPANIES_FROM_TAG_MUTATION,
    { client }
  );
  const [removeContactsFromListMutation] = useMutation(
    REMOVE_CONTACTS_FROM_TAG_MUTATION,
    { client }
  );
  const [removeGroupsFromListMutation] = useMutation(
    REMOVE_GROUPS_FROM_TAG_MUTATION,
    { client }
  );

  const [removeLabelsFromListMutation] = useMutation(
    REMOVE_LABELS_FROM_TAG_MUTATION,
    client
  );

  const [addCompaniesToListMutation] = useMutation(
    ADD_COMPANIES_TO_TAG_MUTATION,
    { client }
  );
  const [addGroupsToListMutation] = useMutation(ADD_GROUPS_TO_TAG_MUTATION, {
    client,
  });
  const [addContactsToListMutation] = useMutation(
    ADD_CONTACTS_TO_TAG_MUTATION,
    { client }
  );
  const [addLabelsToTagMutation] = useMutation(ADD_LABELS_TO_TAG_MUTATION, {
    client,
  });

  const {
    loading: tagLoading,
    data: tagData,
    refetch: refetchTag,
  } = useQuery(TAG_QUERY, {
    client,
    variables: { tagId },
  });

  const unauthorized = !tagLoading && (tagData?.tag?.errors || !tagData);

  const {
    handleFetchMore: handleFetchMoreCustomers,
    pageInfo: pageInfoCustomers,
    customersWithTagReference,
    refetch: refetchCustomers,
  } = usePaginated({
    client,
    query: CUSTOMERS_WITH_TAG_REFERENCE_QUERY,
    key: "customersWithTagReference",
    filter,
    queryVariables: {
      tagId,
      groupIds,
    },
    customMapNode: (edgeNode) => ({
      ...edgeNode.node,
      selected: edgeNode.selected,
    }),
  });

  const {
    handleFetchMore: handleFetchMoreGroups,
    pageInfo: pageInfoGroups,
    groupsWithTagReferencePaginated,
    refetch: refetchGroups,
  } = usePaginated({
    client,
    query: GROUPS_WITH_TAG_REFERENCE_QUERY,
    key: "groupsWithTagReferencePaginated",
    filter,
    queryVariables: {
      tagId,
    },
    customMapNode: (edgeNode) => ({
      ...edgeNode.node,
      selected: edgeNode.selected,
    }),
  });

  const {
    handleFetchMore: handleFetchMoreSelectedCustomers,
    pageInfo: selectedCustomersPageInfo,
    loading: customersSelectedLoading,
    directTagCustomers,
    refetch: refetchDirectTagCustomers,
  } = usePaginated({
    client,
    query: DIRECT_TAG_QUERY,
    key: "directTagCustomers",
    filter,
    queryVariables: {
      tagId,
    },
    customMapNode: (edgeNode) => ({
      ...edgeNode.node,
      selected: edgeNode.selected,
    }),
  });

  const {
    handleFetchMore: handleFetchMoreSelectedGroups,
    pageInfo: selectedGroupsPageInfo,
    groupsPaginated,
    refetch: refetchGroupsPaginated,
  } = usePaginated({
    client,
    query: GROUPS_PAGINATED_QUERY,
    key: "groupsPaginated",
    filter,
    queryVariables: {
      tagFilterId: tagId,
      relayStylePagination: true,
    },
    customMapNode: (edgeNode) => ({
      ...edgeNode.node,
      selected: edgeNode.selected,
    }),
    skip: customersSelectedLoading || selectedCustomersPageInfo?.hasNextPage,
  });

  const handleModifySelectedAudience = async ({
    audienceType,
    audience,
    dataId,
    mutation,
  }) => {
    let refetch = false;
    if (audience.length > 0) {
      const res = await mutation({
        variables: {
          input: {
            [audienceType]: audience,
            tagId,
          },
        },
      }).catch(() => {
        setError(i18n.t("slideouts-CreateCustomerForm-genericError"));
      });

      const { errors } = res.data[dataId];
      if (errors) {
        console.warn(errors);
      } else {
        refetch = true;
        setSuccess(i18n.t("broadcasts-BroadcastList-updateSuccess"));
      }
    }
    return refetch;
  };

  // We pass the adding flag, because the update mutation overrides the existing/saved selected audience
  const handleOnSaveSelectedAudience = async (
    selectedAudience,
    removeAudience,
    addingAudience
  ) => {
    let refetchIndicators = [];

    const refetchArray = await Promise.all([
      handleModifySelectedAudience({
        audienceType: "contactIds",
        audience: removeAudience.contactIds ?? [],
        dataId: "removeContactsFromTag",
        mutation: removeContactsFromListMutation,
      }),
      handleModifySelectedAudience({
        audienceType: "companyIds",
        audience: removeAudience.companyIds ?? [],
        dataId: "removeCompaniesFromTag",
        mutation: removeCompaniesFromListMutation,
      }),
      handleModifySelectedAudience({
        audienceType: "groupIds",
        audience: removeAudience.groupIds ?? [],
        dataId: "removeGroupsFromTag",
        mutation: removeGroupsFromListMutation,
      }),
      handleModifySelectedAudience({
        audienceType: "labelIds",
        audience: removeAudience.labelIds ?? [],
        dataId: "removeLabelsFromTag",
        mutation: removeLabelsFromListMutation,
      }),
    ]);

    refetchIndicators = [...refetchArray];

    if (
      !addingAudience &&
      (selectedAudience.contactIds.length > 0 ||
        selectedAudience.groupIds.length > 0 ||
        selectedAudience.companyIds.length > 0 ||
        selectedAudience.labelIds.length > 0)
    ) {
      const response = await updateListMutation({
        variables: {
          input: {
            tagId,
            contactIds: selectedAudience.contactIds,
            companyIds: selectedAudience.companyIds,
            groupIds: selectedAudience.groupIds,
            labelIds: selectedAudience.labelIds,
          },
        },
      }).catch(() => {
        setError(i18n.t("slideouts-CreateCustomerForm-genericError"));
      });
      if (response?.data?.updateTag?.errors !== null) {
        setError(i18n.t("slideouts-CreateCustomerForm-genericError"));
      } else {
        refetchIndicators.push(true);
        setSuccess(i18n.t("settings-TagList-addedRecordsSuccessfully"));
      }
    }

    if (addingAudience) {
      const addingAudienceRefetchArray = await Promise.all([
        handleModifySelectedAudience({
          audienceType: "contactIds",
          audience: selectedAudience.contactIds ?? [],
          dataId: "addContactsToTag",
          mutation: addContactsToListMutation,
        }),
        handleModifySelectedAudience({
          audienceType: "companyIds",
          audience: selectedAudience.companyIds ?? [],
          dataId: "addCompaniesToTag",
          mutation: addCompaniesToListMutation,
        }),
        handleModifySelectedAudience({
          audienceType: "groupIds",
          audience: selectedAudience.groupIds ?? [],
          dataId: "addGroupsToTag",
          mutation: addGroupsToListMutation,
        }),
        handleModifySelectedAudience({
          audienceType: "labelIds",
          audience: selectedAudience.labelIds ?? [],
          dataId: "addLabelsToTag",
          mutation: addLabelsToTagMutation,
        }),
      ]);

      refetchIndicators = [...refetchIndicators, ...addingAudienceRefetchArray];
    }

    // if one of the indicators is true, refetch everythang.
    if (refetchIndicators.find((r) => !!r)) {
      refetchAll();
    }
  };

  const handleEditTag = async (
    newListName,
    newListDescription,
    newListGroups
  ) => {
    const response = await updateListMutation({
      variables: {
        input: {
          tagId,
          name: newListName,
          description: newListDescription,
          groupShareIds: newListGroups?.map((group) => group.value),
        },
      },
    }).catch(() => {
      setError(i18n.t("slideouts-CreateCustomerForm-genericError"));
    });

    if (response?.data?.updateTag?.errors !== null) {
      const {
        updateTag: { errors },
      } = response.data;
      if (errors[0].reason === NAME_ALREADY_TAKEN) {
        setError(i18n.t("settings-TagList-nameTaken"));
      } else if (errors[0].reason === USER_REMOVING_SELF_VISIBILITY) {
        setError(
          i18n.t("settings-TagList-deleteSelfVisibility", {
            defaultValue:
              "Removing this group will prevent you from viewing this list",
          })
        );
      }
    } else {
      refetchTag();
      setSuccess(i18n.t("settings-TagList-updatedSuccessfully"));
    }
    return response?.data;
  };

  const handleFetchMoreSelected = () => {
    if (selectedCustomersPageInfo.hasNextPage) {
      handleFetchMoreSelectedCustomers();
    } else if (selectedGroupsPageInfo.hasNextPage) {
      handleFetchMoreSelectedGroups();
    }
  };

  const refetchAll = () => {
    refetchCustomers();
    refetchGroups();
    refetchTag();
    refetchGroupsPaginated();
    refetchDirectTagCustomers();
  };

  return {
    audiencePageInfo: {
      groupLists: pageInfoGroups,
      contacts: pageInfoCustomers,
    },
    audienceFetchMore: {
      groupLists: handleFetchMoreGroups,
      contacts: handleFetchMoreCustomers,
    },
    directAudience: {
      groupLists: groupsPaginated,
      contacts: directTagCustomers,
    },
    directAudiencePageInfo: {
      hasNextPage:
        selectedCustomersPageInfo?.hasNextPage ||
        selectedGroupsPageInfo?.hasNextPage,
    },
    groupLists: groupsWithTagReferencePaginated,
    contacts: customersWithTagReference,
    loading: tagLoading,
    listData: !tagData?.tag?.tag
      ? {
          name: "",
          description: "",
          groups: [],
          tagGroupShares: [],
          audienceInfo: {
            optedOutAudienceCount: 0,
            reachableAudienceCount: 0,
          },
        }
      : tagData.tag?.tag,
    handleEditTag,
    handleOnSaveSelectedAudience,
    handleFetchMoreSelected,
    unauthorized,
  };
};

export default useEditList;
