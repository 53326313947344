import { useEffect } from 'react';
import { useWhitelabelFeature } from 'client-lib';
import { useSelector } from 'react-redux';

const useWhitelabel = ({ client, currentUser }) => {
  const whitelabelTabTitle = useSelector(
    (state) => state?.whitelabel?.whitelabelTabTitle
  );

  const {
    whitelabel: whitelabelEnabled,
    whitelabelLogo,
    whitelabelTabTitle: queriedWhitelabelTitle,
  } = useWhitelabelFeature({ client, currentUser });

  useEffect(() => {
    const title =
      whitelabelTabTitle || (whitelabelEnabled && queriedWhitelabelTitle);
    if (title) {
      document.title = title;
    }
  }, [whitelabelTabTitle, queriedWhitelabelTitle, whitelabelEnabled]);

  return {
    whitelabelLogo: whitelabelEnabled && whitelabelLogo ? whitelabelLogo : null,
  };
};

export default useWhitelabel;
