import {
  SET_ACTIVE_WIZARD,
  SET_ACTIVE_CUSTOMER_SOURCE,
  OPEN_SNACKBAR,
  CLOSE_SNACKBAR,
  SET_ACTIVE_SIDEBAR,
  SET_ACTIVE_CREATE_CUSTOMER_MODAL,
  SET_ACTIVE_CREATE_ANNOUNCEMENT_MODAL,
  SET_ACTIVE_CREATE_THREAD_MODAL,
  SET_ACTIVE_CREATE_FAX_MODAL,
  SET_ACTIVE_CREATE_EMAIL_MODAL,
  SET_ACTIVE_CREATE_PAYMENT_MODAL,
  SET_ACTIVE_CREATE_TASK_MODAL,
  CLOSE_CREATE_SECTION_MODAL,
  SET_ACTIVE_TRANSFER_THREAD_SLIDEOUT,
  SET_ACTIVE_CUSTOMER_INFO_SLIDEOUT,
  TOGGLE_CUSTOMER_INFO_SLIDEOUT,
  SET_ACTIVE_MERGE_CUSTOMER_SLIDEOUT,
  CLOSE_MERGE_CUSTOMER_SLIDEOUT,
  SET_ACTIVE_FORWARD_MESSAGE_SLIDEOUT,
  SET_ACTIVE_CREATE_INTERNAL_MODAL,
  CLOSE_ALL_SLIDEOUTS,
  SET_VIDEO_MODAL,
  SET_USERS_FILTER,
  SET_ANNOUNCEMENT_FILTER,
  SET_GROUP_FILTER,
  SET_GROUP_FILTER_USERS_SETTING,
  SET_REGION_FILTER,
  SET_TEMPLATE_FILTER,
  SET_GROUP_FILTER_TEMPLATES_SETTING,
  SET_BACKUP_LANGUAGE,
  SET_SLO_URI,
  SET_ANNOUNCEMENT_STATUS,
  SET_LISTS_SEARCH_FILTER,
  SET_TARGETED_AUDIENCE_FILTER,
  SET_BANNERS,
  SET_UPGRADE_BLURB,
  SET_ACTIVE_FORWARD_MESSAGE_ID,
  SET_CONTACT_FILTER,
  SET_TASK_FILTER,
  SET_CONTACT_FILTER_SETTING,
  SET_EXPORT_CONTACTS_MODAL,
  SET_UPDATE_CONTACTS_DATA,
  UPDATE_ENTITY_SLIDEOUT_DATA,
  SET_SUBMIT_MESSAGE_INPUT_HEIGHT,
  SET_CONTACT_SLIDEOUT_AUTOFOCUS_FIELD,
  TRIGGER_REFETCH_BROADCAST,
  SET_PREVIOUS_ACTIVE_THREAD_ID,
  SET_ACTIVE_CREATE_BROADCASTLIST_MODAL,
  SET_ACTIVE_LOSE_PROGRESS_STATE,
  TRIGGER_REFETCH_ANNOUNCEMENT,
} from '../actions/general';

const DEFAULT_STATE = {
  activeWizard: '',
  activeCustomerSource: 'load',
  snackbarOpen: false,
  snackButtonLink: '',
  snackMessage: '',
  snackType: 'success',
  activeSidebar: 'default',
  snackAutoHide: true,
  activeCreateCustomerModal: false,
  activeCreateAnnouncementModal: false,
  activeCreateBroadcastListModal: false,
  activeCreatePaymentModal: false,
  announcementInitialId: null,
  activeCreateThreadModal: false,
  activeCustomerInfoSlideout: false,
  activeForwardMessageSlideout: false,
  activeTransferThreadSlideout: false,
  activeMergeCustomerSlideout: false,
  activeCreateInternalModal: false,
  activeCreateFaxModal: false,
  activeCreateEmailModal: false,
  activeVideoPlayerModal: false,
  usersFilter: '',
  announcementFilter: '',
  groupFilter: '',
  groupFilterUsersSetting: null,
  templateFilter: '',
  groupFilterTemplatesSetting: null,
  manageContactFilterSetting: null,
  backupLanguage: 'EN_US',
  sloUri: null,
  announcementStatus: 'SENT',
  listsSearchFilter: '',
  targetedAudienceFilter: '',
  banners: [],
  upgradeBlurb: null,
  activeForwardMessageId: null,
  manageContactFilter: '',
  taskFilter: '',
  exportContactsModal: null,
  updateContactsData: null,
  activeSlideoutEntityId: '',
  activeSlideoutEntityType: '',
  submitMessageInputHeight: 0,
  contactSlideoutAutofocusField: '',
  triggerRefetchBroadcast: false,
  previousActiveThreadId: null,
  activeLoseProgressModal: false,
  listId: '',
  triggerRefetchAnnouncement: false,
  activeCreateTaskModal: false,
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_PREVIOUS_ACTIVE_THREAD_ID:
      return {
        ...state,
        previousActiveThreadId: action.previousActiveThreadId,
      };
    case SET_ACTIVE_WIZARD: // TODO not sure if this is needed any longer, look into removing
      return {
        ...state,
        activeWizard: action.activeWizard,
      };
    case SET_ACTIVE_CUSTOMER_SOURCE:
      return {
        ...state,
        activeCustomerSource: action.activeCustomerSource,
      };
    case OPEN_SNACKBAR:
      return {
        ...state,
        snackbarOpen: true,
        snackMessage: action.snackMessage,
        snackType: action.snackType,
        snackButtonLink: action.snackButtonLink,
        snackAutoHide:
          action.snackAutoHide === false ? action.snackAutoHide : true,
      };
    case CLOSE_SNACKBAR:
      return {
        ...state,
        snackbarOpen: false,
        snackAutoHide: true,
        snackButtonLink: '',
      };
    // Sets the active current sidebar type. default/create
    case SET_ACTIVE_SIDEBAR:
      return {
        ...state,
        activeSidebar: action.activeSidebar,
        activeCreateSection: '',
      };
    // Sets the active current create section type. thread/announcement/customer
    case SET_ACTIVE_CREATE_CUSTOMER_MODAL:
      return {
        ...state,
        activeSidebar: 'create',
        activeCreateCustomerModal: true,
        activeCreateAnnouncementModal: false,
        activeCreateThreadModal: false,
        activeVideoPlayerModal: false,
        activeCreateFaxModal: false,
        activeCreateEmailModal: false,
        activeCreatePaymentModal: false,
        activeCreateInternalModal: false,
        activeCreateBroadcastListModal: false,
        activeCreateTaskModal: false,
      };
    case SET_ACTIVE_CREATE_ANNOUNCEMENT_MODAL:
      return {
        ...state,
        activeSidebar: action.dontTriggerCreateSidebar ? 'default' : 'create',
        activeCreateCustomerModal: false,
        activeCreateAnnouncementModal: true,
        announcementInitialId: action.id,
        activeCreateThreadModal: false,
        activeVideoPlayerModal: false,
        activeCreateFaxModal: false,
        activeCreateEmailModal: false,
        activeCreatePaymentModal: false,
        activeCreateInternalModal: false,
        activeCreateBroadcastListModal: false,
        activeCreateTaskModal: false,
      };
    case SET_ACTIVE_CREATE_BROADCASTLIST_MODAL:
      return {
        ...state,
        activeSidebar: action.dontTriggerCreateSidebar ? 'default' : 'create',
        listId: action?.listId,
        activeCreateCustomerModal: false,
        activeCreateAnnouncementModal: false,
        announcementInitialId: action.id,
        activeCreateThreadModal: false,
        activeVideoPlayerModal: false,
        activeCreateFaxModal: false,
        activeCreateEmailModal: false,
        activeCreatePaymentModal: false,
        activeCreateInternalModal: false,
        activeCreateBroadcastListModal: true,
        activeCreateTaskModal: false,
      };
    case SET_ACTIVE_CREATE_THREAD_MODAL:
      return {
        ...state,
        activeSidebar: 'create',
        activeCreateCustomerModal: false,
        activeCreateAnnouncementModal: false,
        activeCreateThreadModal: true,
        activeVideoPlayerModal: false,
        activeCreateFaxModal: false,
        activeCreateEmailModal: false,
        activeCreatePaymentModal: false,
        activeCreateInternalModal: false,
        activeCreateBroadcastListModal: false,
        activeCreateTaskModal: false,
      };
    case SET_ACTIVE_CREATE_FAX_MODAL:
      return {
        ...state,
        activeSidebar: 'create',
        activeCreateCustomerModal: false,
        activeCreateAnnouncementModal: false,
        activeCreateThreadModal: false,
        activeCreateFaxModal: true,
        activeVideoPlayerModal: false,
        activeCreateEmailModal: false,
        activeCreatePaymentModal: false,
        activeCreateInternalModal: false,
        activeCreateBroadcastListModal: false,
        activeCreateTaskModal: false,
      };
    case SET_ACTIVE_CREATE_EMAIL_MODAL:
      return {
        ...state,
        activeSidebar: 'create',
        activeCreateCustomerModal: false,
        activeCreateAnnouncementModal: false,
        activeCreateThreadModal: false,
        activeCreateFaxModal: false,
        activeVideoPlayerModal: false,
        activeCreateEmailModal: true,
        activeCreatePaymentModal: false,
        activeCreateInternalModal: false,
        activeCreateBroadcastListModal: false,
        activeCreateTaskModal: false,
      };
    case SET_ACTIVE_CREATE_INTERNAL_MODAL:
      return {
        ...state,
        activeSidebar: 'create',
        activeCreateCustomerModal: false,
        activeCreateAnnouncementModal: false,
        activeCreateThreadModal: false,
        activeCreateFaxModal: false,
        activeVideoPlayerModal: false,
        activeCreateEmailModal: false,
        activeCreatePaymentModal: false,
        activeCreateInternalModal: true,
        activeCreateBroadcastListModal: false,
        activeCreateTaskModal: false,
      };
    case SET_ACTIVE_CREATE_PAYMENT_MODAL:
      return {
        ...state,
        activeSidebar: 'create',
        activeCreateCustomerModal: false,
        activeCreateAnnouncementModal: false,
        activeCreateThreadModal: false,
        activeCreateFaxModal: false,
        activeVideoPlayerModal: false,
        activeCreateEmailModal: false,
        activeCreatePaymentModal: true,
        activeCreateInternalModal: false,
        activeCreateBroadcastListModal: false,
        activeCreateTaskModal: false,
      };
    case SET_ACTIVE_CREATE_TASK_MODAL:
      return {
        ...state,
        activeSidebar: 'create',
        activeCreateCustomerModal: false,
        activeCreateAnnouncementModal: false,
        activeCreateThreadModal: false,
        activeCreateFaxModal: false,
        activeVideoPlayerModal: false,
        activeCreateEmailModal: false,
        activeCreatePaymentModal: false,
        activeCreateTaskModal: true,
        activeCreateInternalModal: false,
        activeCreateBroadcastListModal: false,
      };
    case CLOSE_CREATE_SECTION_MODAL:
      return {
        ...state,
        activeCreateCustomerModal: false,
        activeCreateAnnouncementModal: false,
        activeCreateThreadModal: false,
        activeCreateFaxModal: false,
        activeCreateEmailModal: false,
        activeCreatePaymentModal: false,
        createCustomerDefaultPhoneNumber: '',
        announcementInitialId: null,
        activeCreateInternalModal: false,
        activeCreateBroadcastListModal: false,
        activeCreateTaskModal: false,
      };
    case SET_ACTIVE_TRANSFER_THREAD_SLIDEOUT:
      return {
        ...state,
        activeCustomerInfoSlideout: false,
        activeForwardMessageSlideout: false,
        activeTransferThreadSlideout: true,
        activeMergeCustomerSlideout: false,
      };
    case SET_ACTIVE_CUSTOMER_INFO_SLIDEOUT:
      return {
        ...state,
        activeTransferThreadSlideout: false,
        activeForwardMessageSlideout: false,
        activeCustomerInfoSlideout: true,
        activeMergeCustomerSlideout: false,
        activeSlideoutEntityId: action.value.activeSlideoutEntityId,
        activeSlideoutEntityType: action.value.activeSlideoutEntityType,
      };
    case TOGGLE_CUSTOMER_INFO_SLIDEOUT:
      return {
        ...state,
        activeTransferThreadSlideout: false,
        activeCustomerInfoSlideout: !state.activeCustomerInfoSlideout,
        activeForwardMessageSlideout: false,
        activeMergeCustomerSlideout: false,
      };
    case SET_ACTIVE_MERGE_CUSTOMER_SLIDEOUT:
      return {
        ...state,
        activeTransferThreadSlideout: false,
        activeCustomerInfoSlideout: false,
        activeForwardMessageSlideout: false,
        activeMergeCustomerSlideout: true,
      };
    case CLOSE_MERGE_CUSTOMER_SLIDEOUT:
      return {
        ...state,
        activeMergeCustomerSlideout: false,
      };
    case SET_ACTIVE_FORWARD_MESSAGE_SLIDEOUT:
      return {
        ...state,
        activeTransferThreadSlideout: false,
        activeCustomerInfoSlideout: false,
        activeForwardMessageSlideout: true,
        activeMergeCustomerSlideout: false,
      };
    case CLOSE_ALL_SLIDEOUTS:
      return {
        ...state,
        activeTransferThreadSlideout: false,
        activeCustomerInfoSlideout: false,
        activeForwardMessageSlideout: false,
        activeMergeCustomerSlideout: false,
      };
    case SET_VIDEO_MODAL:
      return {
        ...state,
        activeVideoPlayerModal: action.activeVideoPlayerModal,
      };
    case SET_USERS_FILTER:
      return {
        ...state,
        usersFilter: action.value,
      };
    case SET_ANNOUNCEMENT_FILTER:
      return {
        ...state,
        announcementFilter: action.value,
      };
    case SET_GROUP_FILTER:
      return {
        ...state,
        groupFilter: action.value,
      };
    case SET_REGION_FILTER:
      return {
        ...state,
        regionFilter: action.value,
      };
    case SET_GROUP_FILTER_USERS_SETTING:
      return {
        ...state,
        groupFilterUsersSetting: action.value,
      };
    case SET_TEMPLATE_FILTER:
      return {
        ...state,
        templateFilter: action.value,
      };
    case SET_CONTACT_FILTER:
      return {
        ...state,
        manageContactFilter: action.value,
      };
    case SET_TASK_FILTER:
      return {
        ...state,
        taskFilter: action.value,
      };
    case SET_GROUP_FILTER_TEMPLATES_SETTING:
      return {
        ...state,
        groupFilterTemplatesSetting: action.value,
      };
    case SET_CONTACT_FILTER_SETTING:
      return {
        ...state,
        manageContactFilterSetting: action.value,
      };
    case SET_BACKUP_LANGUAGE:
      return {
        ...state,
        backupLanguage: action.value,
      };

    case SET_SLO_URI:
      return {
        ...state,
        sloUri: action.value,
      };

    case SET_ANNOUNCEMENT_STATUS:
      return {
        ...state,
        announcementStatus: action.value,
      };

    case SET_LISTS_SEARCH_FILTER:
      return {
        ...state,
        listsSearchFilter: action.value,
      };

    case SET_TARGETED_AUDIENCE_FILTER:
      return {
        ...state,
        targetedAudienceFilter: action.value,
      };

    case SET_BANNERS:
      return {
        ...state,
        banners: action.banners,
      };

    case SET_UPGRADE_BLURB:
      return {
        ...state,
        upgradeBlurb: action.upgradeBlurb,
      };
    case SET_ACTIVE_FORWARD_MESSAGE_ID:
      return {
        ...state,
        activeForwardMessageId: action.activeForwardMessageId,
      };
    case SET_EXPORT_CONTACTS_MODAL:
      return {
        ...state,
        exportContactsModal: action.exportContactsModal,
      };
    case SET_UPDATE_CONTACTS_DATA:
      return {
        ...state,
        updateContactsData: action.updateContactsData,
      };
    case UPDATE_ENTITY_SLIDEOUT_DATA: {
      return {
        ...state,
        activeSlideoutEntityId: action.value.activeSlideoutEntityId,
        activeSlideoutEntityType: action.value.activeSlideoutEntityType,
      };
    }

    case SET_SUBMIT_MESSAGE_INPUT_HEIGHT: {
      return {
        ...state,
        submitMessageInputHeight: action.value,
      };
    }

    case SET_CONTACT_SLIDEOUT_AUTOFOCUS_FIELD: {
      return {
        ...state,
        contactSlideoutAutofocusField: action.value,
      };
    }
    case TRIGGER_REFETCH_BROADCAST: {
      return {
        ...state,
        triggerRefetchBroadcast: action.value,
      };
    }
    case SET_ACTIVE_LOSE_PROGRESS_STATE: {
      return {
        ...state,
        activeLoseProgressModal: action.state,
      };
    }
    case TRIGGER_REFETCH_ANNOUNCEMENT: {
      return {
        ...state,
        triggerRefetchAnnouncement: action.value,
      };
    }
    default:
      return state;
  }
}
