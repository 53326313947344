import React from 'react';
import i18n from 'i18n-js';

import { Modal, Text } from '../../elements';

interface UnsavedChangesProps {
  isOpen: boolean;
  handleClose: () => void;
  handleCancel: () => void;
}

const UnsavedChanges = ({
  isOpen,
  handleClose,
  handleCancel,
}: UnsavedChangesProps): JSX.Element => {
  return (
    <Modal
      size="sm"
      isOpen={isOpen}
      secondaryButtonText={i18n.t('slideouts-GroupMessageOverview-cancel', {
        defaultValue: 'Cancel',
      })}
      secondaryButtonOnClick={handleCancel}
      onRequestClose={handleCancel}
      primaryButtonText={i18n.t('slideouts-GroupMessageOverview-confirm', {
        defaultValue: 'Confirm',
      })}
      primaryButtonOnClick={handleClose}
      modalTitle={i18n.t('slideouts-Modal-closeModalHeader', {
        defaultValue: 'Are you sure you want to exit?',
      })}
      customCardProps={{
        customChildrenStyle: () => `
        display: flex;
        align-items: flex-start;
        div {
          display: flex;
          align-items: flex-start;
        }
      `,
      }}
    >
      <Text>
        {i18n.t('slideouts-Modal-closeModalBody', {
          defaultValue: 'Any unsaved progress to this point will be lost.',
        })}
      </Text>
    </Modal>
  );
};

export default UnsavedChanges;
