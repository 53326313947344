import React from 'react';
import PropTypes from 'prop-types';
import {
  UploadArtWrapper,
  ActionTitle,
  UploadArt as Art,
} from '../../../svgs/UploadArt';

const UploadArt = (props) => (
  <UploadArtWrapper {...props} data-testid="upload-art">
    {props.unsupportedFileType ? (
      <ActionTitle>Unsupported File Type</ActionTitle>
    ) : (
      <ActionTitle>Drop to upload</ActionTitle>
    )}
    <Art />
  </UploadArtWrapper>
);

UploadArt.propTypes = {
  unsupportedFileType: PropTypes.bool,
};

UploadArt.defaultProps = {
  unsupportedFileType: false,
};

export default UploadArt;
