import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconButton, Text } from '..';
import LABEL_THEMES from '../../styles/themes/library/label';
import THEMES from '../../styles/themes/app';

const LabelCardContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  border-bottom: 1px solid ${THEMES.BORDER_COLOR};
  &:hover {
    background-color: ${THEMES.BACKGROUND_SECONDARY};
  }
`;

const LabelTextContainer = styled.div`
  padding-left: 8px;
  width: 100%;
`;

const LabelColorContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  padding-right: 16px;
`;

const CONTACT_LABEL_COLORS = {
  red: LABEL_THEMES.RED_LABEL,
  orange: LABEL_THEMES.ORANGE_LABEL,
  green: LABEL_THEMES.GREEN_LABEL,
  blue: LABEL_THEMES.BLUE_LABEL,
  violet: LABEL_THEMES.PURPLE_LABEL,
};

const LabelCard = ({ suggestion }) => {
  return (
    <LabelCardContainer>
      <LabelTextContainer>
        <Text dataTestId="label-card-name">{suggestion.name}</Text>
      </LabelTextContainer>
      <LabelColorContainer>
        <IconButton
          dataTestId="label-card-button"
          noOutline
          size="sm"
          customStyle={(props) => `
        background-color: ${
          suggestion.color
            ? CONTACT_LABEL_COLORS[suggestion.color](props)
            : LABEL_THEMES.GREY_LABEL(props)
        };`}
        >
          <i className="ri-square-fill" />
        </IconButton>
      </LabelColorContainer>
    </LabelCardContainer>
  );
};

LabelCard.propTypes = { suggestion: PropTypes.object };

LabelCard.defaultProps = {
  suggestion: {},
};

export default LabelCard;
