import {
  SHOW_UPLOAD_PROGRESS_BAR,
  HIDE_UPLOAD_PROGRESS_BAR,
  OPEN_UPLOAD_MODAL,
  CLOSE_UPLOAD_MODAL,
  OPEN_ANNOTATIONS_MODAL,
  CLOSE_ANNOTATIONS_MODAL,
  SET_ATTACHMENT,
  UNSET_ATTACHMENT,
  SHOW_UPLOAD_FAILURE_BAR,
  CLOSE_THREAD,
  CHANGE_ORIGINAL_NAME,
  SET_DEFAULT_CONTACT,
  PUSH_ATTACHMENT,
  DELETE_ATTACHMENT,
  CLEAR_ATTACHMENTS,
  SET_ANNOTATION_ATTACHMENT_INDEX,
} from '../actions/uploadModal';

const DEFAULT_STATE = {
  modalOpen: false,
  annotationsModalOpen: false,
  uploadProgressBarOpen: false,
  uploadFailed: false,
  attachment: null,
  attachments: [],
  openUnblockModal: false,
  numberToBeUnblock: '',
  defaultContact: null,
  annotationAttachmentIndex: 0,
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SHOW_UPLOAD_PROGRESS_BAR:
      return {
        ...state,
        uploadProgressBarOpen: true,
        modalOpen: false,
        uploadFailed: false,
      };
    case SHOW_UPLOAD_FAILURE_BAR:
      return {
        ...state,
        uploadProgressBarOpen: true,
        uploadFailed: true,
      };
    case HIDE_UPLOAD_PROGRESS_BAR:
      return {
        ...state,
        uploadProgressBarOpen: false,
        uploadFailed: false,
        attachment: null,
      };
    case OPEN_UPLOAD_MODAL:
      return {
        ...state,
        modalOpen: true,
      };
    case CLOSE_UPLOAD_MODAL:
      return {
        ...state,
        modalOpen: false,
      };
    case OPEN_ANNOTATIONS_MODAL:
      return {
        ...state,
        annotationsModalOpen: true,
      };
    case CLOSE_ANNOTATIONS_MODAL:
      return {
        ...state,
        annotationsModalOpen: false,
      };
    case SET_ATTACHMENT:
      return {
        ...state,
        attachment: action.attachment,
      };
    case UNSET_ATTACHMENT:
      return {
        ...state,
        attachment: null,
      };
    case CLOSE_THREAD:
      return {
        ...state,
        attachment: null,
      };
    case CHANGE_ORIGINAL_NAME:
      return {
        ...state,
        attachment: {
          ...state.attachment,
          originalFilename: action.fileName,
        },
      };

    case SET_DEFAULT_CONTACT:
      return {
        ...state,
        defaultContact: action.defaultContact,
      };
    case PUSH_ATTACHMENT:
      return {
        ...state,
        attachments: [...state.attachments, action.attachment],
      };
    case DELETE_ATTACHMENT:
      return {
        ...state,
        attachments: [...state.attachments].filter(
          (attachment) => attachment?.id !== action.id
        ),
      };
    case CLEAR_ATTACHMENTS:
      return {
        ...state,
        attachments: [],
      };
    case SET_ANNOTATION_ATTACHMENT_INDEX:
      return {
        ...state,
        annotationAttachmentIndex: action.index,
      };
    default:
      return state;
  }
}
