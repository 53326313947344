import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { maybeRecordTargetUriForAfterLogin } from '../../hooks/signedIn/useDirectUserToIntendedUriAfterLogin';

const PrivateRoute = ({ children, ...rest }) => {
  const currentUser = useSelector((state) => state?.session?.currentUser);

  /**
   * When user has been redirected here for login, record the target URI.
   * After login, pick this up and allow redirect the user to that location.
   */
  maybeRecordTargetUriForAfterLogin(currentUser, window.location.pathname);

  return (
    <Route
      {...rest}
      render={(props) =>
        currentUser ? (
          children(props)
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.func.isRequired,
  location: PropTypes.object,
};

PrivateRoute.defaultProps = {
  location: {},
};

export default PrivateRoute;
