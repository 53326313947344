import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveDeleteMessage } from '../../actions/globalModals.ts';
import DeleteMessageModal from '../Threads/DeleteMessageModal';

const GlobalModalsContainer = () => {
  const dispatch = useDispatch();
  const activeDeleteMessageId = useSelector(
    (state) => state?.globalModals?.activeDeleteMessageId
  );
  return (
    <DeleteMessageModal
      isOpen={!!activeDeleteMessageId}
      messageId={activeDeleteMessageId}
      onRequestClose={() => dispatch(setActiveDeleteMessage(''))}
    />
  );
};

export default GlobalModalsContainer;
