import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import BadgesWithTooltip from './subcomponents/BadgesWithTooltip';
import useMeasure from '../../Animations/useMeasure';

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  position: relative;
  max-width: fit-content;
  width: fit-content;
`;

const AudienceCustomerGroupBadges = ({
  audience,
  groups,
  firstAudienceRow,
  containerRef,
}) => {
  const targetedAudienceFilter = useSelector(
    (state) => state?.general?.targetedAudienceFilter
  );
  const audienceId = audience?.id;
  const caseInsensitiveSearchFilter = targetedAudienceFilter.toLowerCase();
  const [bind, measure] = useMeasure();
  const [groupNames, setGroupNames] = useState([]);
  /**
   * Used to solve clipping bug on first row
   * tooltip will be rendered on bottom rather
   * than top
   */
  const isFirstRow = audienceId === firstAudienceRow?.id;
  const audienceGroupIds = audience?.groupIds;

  useEffect(() => {
    if (audienceGroupIds) {
      const audienceGroups = groups?.groups?.filter((group) =>
        audienceGroupIds.includes(group.id)
      );
      /**
       * Case insensitive sort
       */
      const audienceGroupNames = audienceGroups
        ?.map((group) => group.name)
        ?.sort((a, b) => {
          const x = a.toLowerCase();
          const y = b.toLowerCase();
          if (x < y) {
            return -1;
          }
          if (y < x) {
            return 1;
          }
          return 0;
        });
      /**
       * Checks if there is an active search term
       * if so, it resorts a copy of the array
       * to push the badges that include the term
       * to the front of the array
       */
      if (targetedAudienceFilter) {
        const userSortedGroupNames = audienceGroupNames;
        userSortedGroupNames.sort((a, b) => {
          const x = a.toLowerCase();
          const y = b.toLowerCase();
          if (x.includes(targetedAudienceFilter)) {
            return -1;
          }
          if (y.includes(targetedAudienceFilter)) {
            return 1;
          }
          return 0;
        });
        setGroupNames(userSortedGroupNames);
      } else {
        /**
         * Makes sure to reset the array after the user
         * clears the search term (or there isn't one to
         * begin with)
         */
        setGroupNames(audienceGroupNames);
      }
    }
  }, [targetedAudienceFilter]);

  return (
    <Container {...bind}>
      {/**
       * Removes all group arrays that contain
       * no groups then renders group badges
       * without tooltip if there are three or less
       */}
      {groupNames?.length ? (
        <BadgesWithTooltip
          groups={groupNames}
          audienceId={audienceId}
          measure={measure}
          isFirstRow={isFirstRow}
          targetedAudienceFilter={caseInsensitiveSearchFilter}
          containerRef={containerRef}
        />
      ) : (
        ''
      )}
    </Container>
  );
};

AudienceCustomerGroupBadges.propTypes = {
  audience: PropTypes.object.isRequired,
  groups: PropTypes.object.isRequired,
  firstAudienceRow: PropTypes.object.isRequired,
  containerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
};

AudienceCustomerGroupBadges.defaultProps = {
  containerRef: {},
};

export default AudienceCustomerGroupBadges;
