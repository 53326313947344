import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from 'styled-theming';
import THEMES from '../../styles/themes/app';
import Checkbox from '../Checkbox/Checkbox';

const CHECKBOX_FONTSIZE = theme('fontSize', {
  default: '16px',
  large: '20px',
});

const OptionsWrapper = styled.div`
  display: flex;
  flex: 1;
`;

const OptionsInterior = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`;

const ParentOption = styled.div`
  border-bottom: solid 1px ${THEMES.BORDER_COLOR};
  display: flex;
  width: auto;
`;

const Option = styled.div`
  border-bottom: solid 1px ${THEMES.BORDER_COLOR};
`;

const Arrow = styled.div`
  width: 40px;
  border-left: 1px solid ${THEMES.BORDER_COLOR};
  height: 69px;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  cursor: pointer;
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  color: ${THEMES.FOREGROUND_HIGH};
  &:hover {
    background-color: ${THEMES.BACKGROUND_SECONDARY};
  }
`;

const ParentCheckboxContainer = styled.div`
  padding: 25px 16px;
  width: 100%;
  display: flex;
  cursor: pointer;
  &:hover {
    background-color: ${THEMES.BACKGROUND_SECONDARY};
  }
  div {
    font-weight: 900;
  }
`;

const CheckboxContainer = styled.div`
  // Extra padding for indentation
  padding: 25px 16px 25px 26px;
  display: flex;
  cursor: pointer;
  &:hover {
    background-color: ${THEMES.BACKGROUND_SECONDARY};
  }
`;

const customLabelStyle = (props) => `
  font-size: ${CHECKBOX_FONTSIZE(props)};
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  padding-left: 12px;
  max-width: 100%;
`;

/**
 * Create a nested checkbox list, with a single parent and mulitple child
 * Expects setState to manipulate id values
 * @param {{
 *      data: {
 *        id: Number,
 *        name: String, // Display name for parent header
 *        nestedKey: String,
 *        groups: [
 *          { id: Number, name: String },
 *        ],
 *      }, // data to be displayed
 *      nestedKey: String, // Name of key with array of values to be children
 *      selectedItemIds: Number[], // Array of selected ids
 *      setSelectedItemIds: Function, // Function to set selected ids
 *      onCollapseExpand: Function // Function to handle any special collapse/expand logic needed in caller
 *      hideColapse: Boolean // Hide the collapse arrow
 *  }} param0 - NestedCheckboxList options
 */
const NestedCheckboxList = ({
  data,
  nestedKey,
  selectedItemIds,
  setSelectedItemIds,
  onCollapseExpand,
  hideColapse,
}) => {
  const [open, setOpen] = useState(true);

  // Function to handle checkbox change
  const handleCheckboxChange = (item) => {
    const selectedIndex = selectedItemIds.findIndex((id) => id === item.id);
    const newSelected = [...selectedItemIds];

    if (selectedIndex === -1) {
      newSelected.push(item.id);
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setSelectedItemIds(newSelected);
  };

  // Function to handle parent checkbox change
  const handleParentCheckboxChange = (parent) => {
    const newSelected = [...selectedItemIds];
    let allChildrenSelected = true;

    parent[nestedKey].forEach((child) => {
      if (!newSelected.find((id) => id === child.id)) {
        allChildrenSelected = false;
        newSelected.push(child.id);
      }
    });

    if (allChildrenSelected) {
      // Deselect all children
      parent[nestedKey].forEach((child) => {
        const childIndex = newSelected.findIndex((id) => id === child.id);
        newSelected.splice(childIndex, 1);
      });
    } else {
      // Select all children
      parent[nestedKey].forEach((child) => {
        if (!newSelected.find((id) => id === child.id)) {
          newSelected.push(child.id);
        }
      });
    }

    setSelectedItemIds(newSelected);
  };

  const handleExpandCollapse = () => {
    setOpen((prev) => !prev);
    onCollapseExpand();
  };

  return (
    <OptionsWrapper>
      <OptionsInterior>
        <ParentOption>
          <ParentCheckboxContainer
            onClick={() => handleParentCheckboxChange(data)}
          >
            <Checkbox
              dataTestId={`parent-select-btn-${data.name}`}
              customContainerStyle={() => 'display: block;'}
              checked={data[nestedKey].every((child) =>
                selectedItemIds.includes(child.id)
              )}
              onCheck={() => {}}
              label={data.name}
              labelRight
              customLabelStyle={customLabelStyle}
            />
          </ParentCheckboxContainer>
          {!hideColapse && (
            <Arrow
              style={{ float: 'right' }}
              onClick={handleExpandCollapse}
              data-testid="collapse-nested-arrow"
            >
              {open ? (
                <i className="ri-arrow-drop-up-line" />
              ) : (
                <i className="ri-arrow-drop-down-line" />
              )}
            </Arrow>
          )}
        </ParentOption>
        {open &&
          data[nestedKey].map((child) => (
            <Option key={child.id}>
              <CheckboxContainer onClick={() => handleCheckboxChange(child)}>
                <Checkbox
                  dataTestId={`child-select-btn-${child.id}`}
                  customContainerStyle={() => 'display: block;'}
                  checked={selectedItemIds.includes(child.id)}
                  onCheck={() => {}}
                  label={child.name}
                  labelRight
                  customLabelStyle={customLabelStyle}
                />
              </CheckboxContainer>
            </Option>
          ))}
      </OptionsInterior>
    </OptionsWrapper>
  );
};

NestedCheckboxList.propTypes = {
  data: PropTypes.object.isRequired,
  nestedKey: PropTypes.string.isRequired,
  selectedItemIds: PropTypes.array.isRequired,
  setSelectedItemIds: PropTypes.func.isRequired,
  dataTestId: PropTypes.string,
  onCollapseExpand: PropTypes.func,
  hideColapse: PropTypes.bool,
};

NestedCheckboxList.defaultProps = {
  dataTestId: '',
  onCollapseExpand: () => {},
  hideColapse: false,
};
export default NestedCheckboxList;
