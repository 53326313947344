import i18n from "i18n-js";
import snackbarEventConfigs from "../../utils/global_snackbar/snackbarEventConfigs";
import i18nTranslations from "../../i18n";

/*
DOCUMENTATION

this function is intended to be used for dispatching snackbars for any mutations that might take too long to expect a user 
to sit and wait for them on the page. A good example would be submitting a fax, which is truly async. 

To work with this flow, the BE and FE need to coordinate the name of whichever event the FE should be listening for. 
We will need to define whatever copy goes along with the success/fail version of that event, and attach i18n copy to it. 
This part of the flow can be found in snackbarEventConfigs.

args:
  store: (obj) required. Redux store object
  response: (obj) required. Subscription response

returns:
  n/a

Doesn't return anything, just dispatches a toast message with the appropriate info about the success/fail state of whatever mutation has completed.
*/

i18n.translations = {
  "en-US": { ...i18nTranslations.EN_US },
  "es-MX": { ...i18nTranslations.ES_MX },
  "fr-CA": { ...i18nTranslations.FR_CA },
};

function globalSnackbarHandler(store, response) {
  const locale = store.getState().session.currentUser?.language || "EN_US";

  const formattedLocale =
    locale.slice(0, 2).toLowerCase() + "-" + locale.slice(3);
  i18n.locale = formattedLocale;
  i18n.fallbacks = "en-US";

  const globalSnackbarEvent = response?.data?.globalSnackbarEvent;
  if (globalSnackbarEvent && snackbarEventConfigs?.[globalSnackbarEvent]) {
    store.dispatch({
      type: "OPEN_SNACKBAR",
      snackMessage: i18n.t(snackbarEventConfigs[globalSnackbarEvent].i18nId),
      snackType: snackbarEventConfigs[globalSnackbarEvent].snackType,
    });
  }
}

export default globalSnackbarHandler;
