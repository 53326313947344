import { useAccountQuery } from 'client-lib';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { setAccount } from '../../actions/accountData';

const useOnLoadQueryAccountData = () => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const storeAccountId = useSelector((state) => state.accountData.account?.id);
  const currentUserAccountId = useSelector(
    (state) => state.session.currentUser?.accountId
  );

  // sending the account data to the redux store on sign in, this prevents us having to query
  // it later for things like feature flags.
  const { data: accountData } = useAccountQuery({
    client,
    skip: !!storeAccountId,
    variables: {
      id: currentUserAccountId,
    },
  });

  if (accountData?.web_auth_provider) {
    Sentry.withScope((scope) => {
      scope.setTag('pk-authProvider', accountData.web_auth_provider);
    });
  }

  useEffect(() => {
    if (accountData?.account) {
      dispatch(setAccount(accountData.account));
    }
  }, [accountData?.account?.id]);
};

export default useOnLoadQueryAccountData;
