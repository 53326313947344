import { ApolloClient, useQuery } from "@apollo/client";
import LINK_HOST_QUERY from "../../../graphql/query/LinkHostQuery.graphql";

interface LinkHostProps {
  client: ApolloClient<object>;
  id: string;
}

const useGetLinkHost = ({ client, id }: LinkHostProps) => {
  const { data, loading } = useQuery(LINK_HOST_QUERY, {
    client,
    variables: {
      id,
    },
  });

  return { data, loading };
};

export default useGetLinkHost;
