import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Text } from '../../../../elements';

const customTextStyle = () => `
  font-weight: 600;
  padding-right: 16px;
`;

const ListStatusContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
`;

const ListStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const ListFlyoutRightText = ({ text, dataTestId }) => {
  return (
    <ListStatusContainer>
      <ListStatusWrapper>
        <Text
          customStyle={customTextStyle}
          contrast="low"
          dataTestId={dataTestId}
        >
          {text}
        </Text>
      </ListStatusWrapper>
    </ListStatusContainer>
  );
};

ListFlyoutRightText.propTypes = {
  text: PropTypes.string,
  dataTestId: PropTypes.string,
};

ListFlyoutRightText.defaultProps = {
  text: '',
  dataTestId: 'list-flyout-right-text',
};

export default ListFlyoutRightText;
