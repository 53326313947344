import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import UnclaimedSingleGroupWidget from './UnclaimedSingleGroupWidget';

const UnclaimedSingleGroupWidgetContainer = ({
  unclaimedTotal,
  unclaimedThreads,
}) => {
  const [key, setKey] = useState(0);
  const unclaimedThreadsToSort = unclaimedThreads?.counts?.length
    ? [...unclaimedThreads.counts[0].rawThreadData]
    : [];
  // For the single group version counts will always be an array of size 1
  useEffect(() => {
    /**
     * The BE doesn't handle checking if threads need to move
     * between bars. Dwight and I decided to force the bars to refresh
     * every 10 seconds, in case there are long periods without updates
     * from subscription, this will stay accurate with low latency
     */
    const renderInterval = setInterval(() => {
      const id = uniqid();
      setKey(id);
    }, 10000);
    return () => {
      clearInterval(renderInterval);
    };
  }, []);
  return (
    <UnclaimedSingleGroupWidget
      key={key}
      unclaimedTotal={unclaimedTotal}
      unclaimedThreads={unclaimedThreadsToSort}
    />
  );
};

UnclaimedSingleGroupWidgetContainer.propTypes = {
  unclaimedThreads: PropTypes.object.isRequired,
  unclaimedTotal: PropTypes.number.isRequired,
};

export default UnclaimedSingleGroupWidgetContainer;
