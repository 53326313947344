import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18n-js';
import usePaginated from 'client-lib/src/lib/api/query/usePaginated';
import {
  TAGS_WITH_ANNOUNCEMENT_REFERENCE_QUERY,
  CUSTOMERS_WITH_ANNOUNCEMENT_REFERENCE_QUERY,
  GROUPS_V2_QUERY,
  DIRECT_ANNOUNCEMENT_CUSTOMERS_QUERY,
  DIRECT_ANNOUNCEMENT_TAGS_QUERY,
  GROUPS_PAGINATED_QUERY,
  REMOVE_RECIPIENTS_FROM_ANNOUNCEMENT_MUTATION,
  ADD_RECIPIENTS_TO_ANNOUNCEMENT_MUTATION,
  PROPOSED_ANNOUNCEMENT_AUDIENCE_INFO_QUERY,
  useGroupsQuery,
} from 'client-lib';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import GroupMessagingTemplate from '../GroupMessageTemplate';
import SaveAndExit from './subComponents/SaveAndExit';
import { openSnackbar } from '../../../../actions/general';
import THEMES from '../../../../styles/themes/app';
import ReachableAudienceFooter from './subComponents/ReachableAudienceFooter';
import ReachableAudienceModal from './subComponents/ReachableAudienceModal';
import TargetedAudience from '../../../TargetedAudience/TargetedAudience';
import useTargetedAudience from '../../../TargetedAudience/useTargetedAudience';
import {
  Switch,
  InputLabel,
  Heading3,
  Text,
  Heading2,
} from '../../../../elements';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  margin-bottom: 8px;
`;

export const PageError = styled.div`
  position: absolute;
  color: ${THEMES.THEME_RED};
  top: -15px;
  font-size: 1rem;
`;

const Card = styled.div`
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  height: fit-content;
  border-radius: 4px;
  border-color: ${THEMES.BORDER_COLOR};
  box-shadow: ${THEMES.BOX_SHADOW};
  width: 550px;
  margin: 0 auto;
  margin-bottom: 100px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
const CardContent = styled.div`
  padding: 20px 0 20px 20px;
`;

const HeadingWrapper = styled.div`
  margin-bottom: 20px;
`;

const ContentContainer = styled.div`
  width: 850px;
  position: relative;
  margin: 0 auto;
  padding: 16px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const CardContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const TargetAllSwitchContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 4px;
  margin-top: -40px;
`;

const AllSelctedContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const AllSelectedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// const AnncIcon = styled.i`
//   padding-right: 16px;
//   font-size: 36px;
//   color: ${THEMES.FOREGROUND_MED};
// `;

// const ContactsIcon = styled.i`
//   padding: 0;
//   font-size: 36px;
//   color: ${THEMES.FOREGROUND_MED};
// `;

// const TargetAllIconWrapper = styled.div`
//   display: flex;
//   padding: 20px;
// `;

const GroupMessageAudience = ({
  closeWizard,
  setWizardPage,
  wizardState,
  wizardGlobalProps,
  mutations,
}) => {
  const client = useApolloClient();

  const [openModal, setOpenModal] = useState(false);

  const dispatch = useDispatch();
  const targetedAudienceFilter = useSelector(
    (state) => state?.general?.targetedAudienceFilter
  );
  const groupIds = useSelector(
    (state) => state?.session?.currentUser?.groupIds
  );

  const ff_broadcast_list_improvements = useSelector(
    (state) => state.accountData.account?.ff_broadcast_list_improvements
  );

  const { announcementId } = wizardState;
  const { data } = wizardGlobalProps.announcementQuery;
  const { audienceInfoData } = wizardGlobalProps.audienceInfo;
  const { updateAnnouncementMutation } = mutations;

  const subject = data?.announcement?.announcement?.subject;
  const sendStartedAt = data?.announcement?.announcement?.sendStartedAt;

  const contactCount = data?.announcement?.announcement?.contactCount || 0;
  const companyCount = data?.announcement?.announcement?.companyCount || 0;
  const groupCount = data?.announcement?.announcement?.groupCount || 0;
  const tagCount = data?.announcement?.announcement?.tagCount || 0;
  const announcementItemsCount =
    contactCount + companyCount + groupCount + tagCount;

  const optedOutAudienceCount = audienceInfoData?.optedOutAudienceCount;
  const reachableAudienceCount = audienceInfoData?.reachableAudienceCount;

  const [targetAllContacts, setTargetAllContacts] = useState(
    data?.announcement?.announcement?.targetAllContacts
  );
  const [showRefetchLoading, setShowRefetchLoading] = useState(false);

  const [removeRecipientsFromAnncMutation] = useMutation(
    REMOVE_RECIPIENTS_FROM_ANNOUNCEMENT_MUTATION,
    { client }
  );
  const [addRecipientsToAnncMutation] = useMutation(
    ADD_RECIPIENTS_TO_ANNOUNCEMENT_MUTATION,
    { client }
  );

  const customMapNode = (edgeNode) => ({
    ...edgeNode.node,
    selected: edgeNode.selected,
  });

  const dispatchSuccessMsg = (msg) => {
    dispatch(openSnackbar(msg));
  };

  const dispatchErrorMsg = (msg) => {
    dispatch(openSnackbar(msg, 'error'));
  };

  const genericErrorMsg = i18n.t('slideouts-GroupMessageName-genericError');

  const {
    handleFetchMore: customersHandleFetchMore,
    pageInfo: customersPageInfo,
    customersWithAnnouncementReference: customers,
    // loading: customersLoading,
    refetch: customersRefetch,
  } = usePaginated({
    client,
    query: CUSTOMERS_WITH_ANNOUNCEMENT_REFERENCE_QUERY,
    filter: targetedAudienceFilter,
    queryVariables: {
      announcementId,
      groupIds,
    },
    resultsNumber: 30,
    key: 'customersWithAnnouncementReference',
    customMapNode,
  });

  const {
    handleFetchMore: groupsHandleFetchMore,
    pageInfo: groupsPageInfo,
    groupsV2: groups,
    // loading: groupsLoading,
    refetch: groupsRefetch,
  } = usePaginated({
    client,
    query: GROUPS_V2_QUERY,
    filter: targetedAudienceFilter,
    queryVariables: {
      announcementFilterId: announcementId,
      relayStylePagination: true,
    },
    resultsNumber: 30,
    key: 'groupsV2',
    customMapNode,
  });

  const {
    handleFetchMore: tagsHandleFetchMore,
    pageInfo: tagsPageInfo,
    tagsWithAnnouncementReference: tags,
    // loading: tagsLoading,
    refetch: tagsRefetch,
  } = usePaginated({
    client,
    query: TAGS_WITH_ANNOUNCEMENT_REFERENCE_QUERY,
    filter: targetedAudienceFilter,
    queryVariables: {
      announcementId,
    },
    resultsNumber: 30,
    key: 'tagsWithAnnouncementReference',
    customMapNode,
  });

  /**
   * Central groups query for the group badges on customer targeted audience rows
   */
  const { data: groupsQueryData } = useGroupsQuery({ client });

  const tabOptions = [
    {
      label: i18n.t('customers-CustomerList-contacts'),
      value: 'contacts',
    },
    {
      label: i18n.t('slideouts-GroupMessageRecipients-broadcastListsLabel'),
      value: 'tagLists',
    },
    {
      label: i18n.t('slideouts-GroupMessageRecipients-groupLists'),
      value: 'groupLists',
    },
  ];

  const audience = {
    contacts: customers,
    groupLists: groups,
    tagLists: tags,
  };

  const audiencePageInfo = {
    contacts: customersPageInfo,
    groupLists: groupsPageInfo,
    tagLists: tagsPageInfo,
  };

  const audienceFetchMore = {
    contacts: customersHandleFetchMore,
    groupLists: groupsHandleFetchMore,
    tagLists: tagsHandleFetchMore,
  };

  const defaultShowSelectedAudience = false;

  const {
    selectedAudience,
    setSelectedAudience,
    // buildName,
    // buildDescription,
    getFilteredAudience,
    futureRemovals,
    setFutureRemovals,
    showSelectedAudience,
    setShowSelectedAudience,
    // chainSelectedAudienceArray,
  } = useTargetedAudience(defaultShowSelectedAudience);

  const {
    handleFetchMore: handleFetchMoreDirectCustomers,
    pageInfo: pageInfoDirectCustomers,
    directAnnouncementCustomers,
    loading: loadingDirectCustomers,
    refetch: refetchDirectCustomers,
  } = usePaginated({
    client,
    query: DIRECT_ANNOUNCEMENT_CUSTOMERS_QUERY,
    filter: targetedAudienceFilter,
    queryVariables: {
      announcementId,
    },
    resultsNumber: 30,
    key: 'directAnnouncementCustomers',
  });

  const {
    handleFetchMore: handleFetchMoreDirectTags,
    pageInfo: pageInfoDirectTags,
    directAnnouncementTags,
    loading: loadingDirectTags,
    // refetch: refetchDirectTags,
  } = usePaginated({
    client,
    query: DIRECT_ANNOUNCEMENT_TAGS_QUERY,
    filter: targetedAudienceFilter,
    queryVariables: {
      announcementId,
    },
    resultsNumber: 30,
    key: 'directAnnouncementTags',
    skip: loadingDirectCustomers || pageInfoDirectCustomers?.hasNextPage,
  });

  const {
    handleFetchMore: groupsPaginatedHandleFetchMore,
    pageInfo: groupsPaginatedPageInfo,
    groupsPaginated,
    // loading: groupsPaginatedLoading,
    // refetch: groupsPaginatedRefetch,
  } = usePaginated({
    client,
    query: GROUPS_PAGINATED_QUERY,
    filter: targetedAudienceFilter,
    queryVariables: {
      announcementFilterId: announcementId,
      relayStylePagination: true,
    },
    resultsNumber: 30,
    key: 'groupsPaginated',
    customMapNode,
    skip:
      loadingDirectCustomers ||
      pageInfoDirectCustomers?.hasNextPage ||
      loadingDirectTags ||
      pageInfoDirectTags?.hasNextPage,
  });

  // const chainedAudience = [...directAnnouncementCustomers, ...directAnnouncementTags, ...groupsPaginated];

  const chainSelectedAudienceArray = () => {
    const filterAudienceByInputValue = (audience, groups) => {
      const accountContainsFilterString = (val, filter) =>
        val.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
      const name = audience?.name
        ? audience.name
        : `${audience?.firstName} ${audience?.lastName}`;
      const phoneNumber = audience?.phoneNumber;

      if (accountContainsFilterString(name, targetedAudienceFilter)) {
        return true;
      }
      /**
       * Matches phone numbers and removes special characters from user search
       */
      if (
        accountContainsFilterString(
          phoneNumber,
          targetedAudienceFilter.replace(/[^0-9]/g, '')
        )
      ) {
        return true;
      }

      const groupNames = audience?.groupIds
        ? audience.groupIds
            .map((groupId) => groups.find((group) => group.id === groupId))
            .map((group) => group.name)
        : [];

      return groupNames.reduce((acc, groupName) => {
        if (acc) return true;
        return accountContainsFilterString(groupName, targetedAudienceFilter);
      }, false);
    };

    const filteredSelectedCustomers = directAnnouncementCustomers.filter(
      (audience) =>
        !futureRemovals.find((element) => element.id === audience.id)
    );
    const filteredSelectedTags = directAnnouncementTags.filter(
      (audience) =>
        !futureRemovals.find((element) => element.id === audience.id)
    );
    const filteredSelectedGroups = groupsPaginated.filter(
      (audience) =>
        !futureRemovals.find((element) => element.id === audience.id)
    );
    const filteredSelectedAudience = selectedAudience.filter((audience) =>
      filterAudienceByInputValue(audience, groupsQueryData?.groups)
    );

    return [
      ...filteredSelectedAudience,
      ...filteredSelectedCustomers,
      ...filteredSelectedTags,
      ...filteredSelectedGroups,
    ];
  };

  const handleFetchMoreSelected = () => {
    if (pageInfoDirectCustomers?.hasNextPage) {
      handleFetchMoreDirectCustomers();
    } else if (pageInfoDirectTags?.hasNextPage) {
      handleFetchMoreDirectTags();
    } else if (groupsPaginatedPageInfo?.hasNextPage) {
      groupsPaginatedHandleFetchMore();
    }
  };

  const directAudiencePageInfo = {
    hasNextPage:
      pageInfoDirectCustomers?.hasNextPage ||
      pageInfoDirectTags?.hasNextPage ||
      groupsPaginatedPageInfo?.hasNextPage,
  };

  // getFilteredAudience
  const selectedAudienceByTypename = getFilteredAudience(selectedAudience);
  const removedAudienceByTypename = getFilteredAudience(futureRemovals);

  const { data: proposedCountData, refetch: refetchProposedCount } = useQuery(
    PROPOSED_ANNOUNCEMENT_AUDIENCE_INFO_QUERY,
    {
      client,
      variables: {
        input: {
          addedCompanyIds: selectedAudienceByTypename.companyIds,
          addedContactIds: selectedAudienceByTypename.contactIds,
          addedGroupIds: selectedAudienceByTypename.groupIds,
          addedTagIds: selectedAudienceByTypename.tagIds,
          removedCompanyIds: removedAudienceByTypename.companyIds,
          removedContactIds: removedAudienceByTypename.contactIds,
          removedGroupIds: removedAudienceByTypename.groupIds,
          removedTagIds: removedAudienceByTypename.tagIds,
          targetAllContacts,
          announcementId,
        },
      },
    }
  );

  const proposedOptedOutAudienceCount =
    proposedCountData?.proposedAnnouncementAudienceInfo?.audienceInfo
      ?.optedOutAudienceCount;
  const proposedReachableAudienceCount =
    proposedCountData?.proposedAnnouncementAudienceInfo?.audienceInfo
      ?.reachableAudienceCount;

  useEffect(() => {
    refetchProposedCount();
  }, [selectedAudience?.length, futureRemovals?.length]);

  const updateAnnouncementTargetAllContacts = async (onSuccess) => {
    const onMutationFailure = () => {
      dispatchErrorMsg(genericErrorMsg);
    };

    const { data } = await updateAnnouncementMutation({
      variables: {
        input: {
          id: announcementId,
          targetAllContacts,
        },
      },
    }).catch((e) => {
      console.error(e);
      onMutationFailure();
    });

    if (data?.updateAnnouncement?.errors) {
      onMutationFailure();
    } else {
      onSuccess?.();
      // refetchProposedCount();
    }
  };

  const refetchAll = async () => {
    await Promise.all([
      refetchDirectCustomers(),
      tagsRefetch(),
      groupsRefetch(),
      customersRefetch(),
    ]).catch(() => dispatchErrorMsg(genericErrorMsg));
  };

  const addAndRemoveSelectedAudience = async (
    addAudience,
    removeAudience,
    onSuccess
  ) => {
    setShowRefetchLoading(true);
    // here we go motherf*ckers
    let mutationsHadErrors = false;

    if (
      removeAudience.contactIds.length ||
      removeAudience.companyIds.length ||
      removeAudience.groupIds.length ||
      removeAudience.tagIds.length
    ) {
      // f*ckin' remove that sh*t

      const res = await removeRecipientsFromAnncMutation({
        variables: {
          input: {
            announcementId,
            companyIds: removeAudience.companyIds,
            contactIds: removeAudience.contactIds,
            groupIds: removeAudience.groupIds,
            tagIds: removeAudience.tagIds,
            labelIds: [],
          },
        },
      }).catch(() => {
        dispatchErrorMsg(genericErrorMsg);
        mutationsHadErrors = true;
        // TODO handle errors stuff
        // i18n.t('nothing', defaultMessage: 'There was an error' })
      });

      const { errors } = res.data.removeRecipientsFromAnnouncement;
      if (errors) {
        mutationsHadErrors = true;
      }
    }

    if (
      addAudience.contactIds.length ||
      addAudience.companyIds.length ||
      addAudience.groupIds.length ||
      addAudience.tagIds.length
    ) {
      // f*ckin' add that sh*t

      const res = await addRecipientsToAnncMutation({
        variables: {
          input: {
            announcementId,
            companyIds: addAudience.companyIds,
            contactIds: addAudience.contactIds,
            groupIds: addAudience.groupIds,
            tagIds: addAudience.tagIds,
            labelIds: [],
          },
        },
      }).catch(() => {
        dispatchErrorMsg(genericErrorMsg);
        mutationsHadErrors = true;
        // TODO handle errors stuff
        // i18n.t('nothing', defaultMessage: 'There was an error' })
      });

      const { errors } = res.data.addRecipientsToAnnouncement;
      if (errors) {
        mutationsHadErrors = true;
      }
    }

    if (!mutationsHadErrors) {
      await refetchAll();
      onSuccess?.();
    } else {
      dispatchErrorMsg(genericErrorMsg);
      // TODO handle errors here, perhaps.
    }

    setShowRefetchLoading(false);
  };

  const handleSave = (onSuccess) => {
    let isExecutingMutation = false;

    const onMutationSuccess = () => {
      setSelectedAudience([]);
      setFutureRemovals([]);
      onSuccess?.();
    };

    // only bother to add and remove contacts if targetAllContacts is false
    if (!targetAllContacts) {
      isExecutingMutation = true;
      const addAudience = getFilteredAudience(selectedAudience);
      const removeAudience = getFilteredAudience(futureRemovals);
      addAndRemoveSelectedAudience(
        addAudience,
        removeAudience,
        onMutationSuccess
      );
    }

    // if targetAllContacts state is different than what's currently set on the announcement, update it.
    if (
      targetAllContacts !== data?.announcement?.announcement?.targetAllContacts
    ) {
      isExecutingMutation = true;
      updateAnnouncementTargetAllContacts(onMutationSuccess);
    }

    // in case no save is actually necessary, still run the onSuccess stuff.
    if (!isExecutingMutation) {
      onMutationSuccess();
    }
  };

  const nextStep = () => {
    handleSave(() => setWizardPage('GroupMessageOverviewCombined'));
  };

  const justANormalSave = () => {
    handleSave(() =>
      dispatchSuccessMsg(
        i18n.t('slideouts-GroupMessageAudience-successfullySaved')
      )
    );
  };

  const handleEyeconClick = () => {
    setOpenModal(true);
  };

  // const updatesWaiting = !!(selectedAudience?.length || futureRemovals?.length);

  const selectedAudienceCount =
    announcementItemsCount + (selectedAudience.length - futureRemovals.length);

  return (
    <GroupMessagingTemplate
      title={subject}
      headerRightElement={
        <SaveAndExit
          closeWizard={closeWizard}
          onClose={!sendStartedAt ? justANormalSave : null}
          sendStartedAt={!!sendStartedAt}
        />
      }
      showFooter
      continueButtonText={i18n.t('slideouts-GroupMessageName-saveContinue')}
      continueButtonAction={nextStep}
      continueButtonDisabled={!selectedAudienceCount && !targetAllContacts}
      hideContinueButton={!!sendStartedAt}
      backButtonAction={() => setWizardPage('GroupMessageOverviewCombined')}
      centerFooterElement={
        <ReachableAudienceFooter
          optedOutAudienceCount={
            targetAllContacts
              ? optedOutAudienceCount
              : proposedOptedOutAudienceCount
          }
          reachableAudienceCount={
            targetAllContacts
              ? reachableAudienceCount
              : proposedReachableAudienceCount
          }
          handleEyeconClick={handleEyeconClick}
        />
      }
      bottomFooterProps={{
        secondaryAction: () => setTargetAllContacts(!targetAllContacts),
        secondaryDisabled: sendStartedAt,
        secondaryValue: targetAllContacts,
        secondaryLabel: ff_broadcast_list_improvements
          ? i18n.t('slideouts-GroupMessageRecipients-targetAllContacts')
          : null,
      }}
    >
      <Wrapper>
        <ContentContainer data-testid="group-message-recipients-step">
          {!ff_broadcast_list_improvements && (
            <TargetAllSwitchContainer>
              <InputLabel>
                {i18n.t('slideouts-GroupMessageRecipients-targetAllContacts')}
              </InputLabel>
              <div style={{ width: 8 }} />
              <Switch
                checked={targetAllContacts}
                onCheck={() => setTargetAllContacts(!targetAllContacts)}
                disabled={!!sendStartedAt}
              />
            </TargetAllSwitchContainer>
          )}
          {targetAllContacts ? (
            ff_broadcast_list_improvements ? (
              <AllSelctedContainer>
                <AllSelectedWrapper>
                  <HeadingWrapper>
                    <Heading2>
                      {i18n.t('slideouts-GroupMessageRecipients-allContacts', {
                        defaultValue: 'All Contacts Selected',
                      })}
                    </Heading2>
                  </HeadingWrapper>
                  <Heading2>
                    {i18n.t('broadcasts-broadcast-selectedAll', {
                      defaultValue:
                        'Broadcast will be sent to your entire reachable audience',
                    })}
                  </Heading2>
                </AllSelectedWrapper>
              </AllSelctedContainer>
            ) : (
              <CardContainer>
                <Card>
                  <CardContent>
                    <HeadingWrapper>
                      <Heading3>
                        {i18n.t('slideouts-GroupMessageRecipients-allContacts')}
                      </Heading3>
                    </HeadingWrapper>
                    <Text>
                      {i18n.t(
                        'slideouts-GroupMessageRecipients-messageEveryone'
                      )}
                    </Text>
                  </CardContent>
                  {/* <TargetAllIconWrapper>
                      <AnncIcon className="ri-base-station-fill" />
                      <ContactsIcon className="ri-contacts-book-2-fill" />
                    </TargetAllIconWrapper> */}
                </Card>
              </CardContainer>
            )
          ) : (
            <TargetedAudience
              tabOptions={tabOptions}
              handleSelectedAudience={() => setShowSelectedAudience(true)}
              audience={
                showSelectedAudience ? chainSelectedAudienceArray() : audience
              }
              audiencePageInfo={
                showSelectedAudience ? directAudiencePageInfo : audiencePageInfo
              }
              audienceFetchMore={
                showSelectedAudience
                  ? handleFetchMoreSelected
                  : audienceFetchMore
              }
              selectedAudience={selectedAudience}
              setSelectedAudience={setSelectedAudience}
              futureRemovals={futureRemovals}
              setFutureRemovals={setFutureRemovals}
              showSelectedAudience={showSelectedAudience}
              loading={showRefetchLoading}
              scrollHeight="calc(100vh - 456px)"
              handleTabChange={() => setShowSelectedAudience(false)}
              selectedAudienceCount={selectedAudienceCount}
              disableAddRemove={!!sendStartedAt}
              filterInputPlaceholder={
                showSelectedAudience
                  ? i18n.t('slideouts-TargetedAudience-searchByName')
                  : i18n.t('slideouts-TargetedAudience-search')
              }
              nullValueMessage={
                showSelectedAudience
                  ? i18n.t('slideouts-TargetedAudience-noneSelectedByParams')
                  : ''
              }
              groups={groupsQueryData ?? {}}
              targetedAudienceFilter={targetedAudienceFilter}
              groupsPaginated={groupsPaginated}
            />
          )}

          <ReachableAudienceModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            wizardGlobalProps={wizardGlobalProps}
            announcementId={announcementId}
            initiateRefetch={!showRefetchLoading}
            proposedAdded={selectedAudienceByTypename}
            proposedRemoved={removedAudienceByTypename}
            allContacts={targetAllContacts}
            onSuccessListSave={refetchAll}
          />
        </ContentContainer>
      </Wrapper>
    </GroupMessagingTemplate>
  );
};

GroupMessageAudience.propTypes = {
  closeWizard: PropTypes.func.isRequired,
  setWizardPage: PropTypes.func.isRequired,
  wizardState: PropTypes.object.isRequired,
  wizardGlobalProps: PropTypes.object.isRequired,
  mutations: PropTypes.object.isRequired,
};

export default GroupMessageAudience;
