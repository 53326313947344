import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import {
  AVAILABLE_PERMISSIONS,
  checkIfCurrentUserHasPermission,
  GET_RANDOM_UPGRADE_BLURB_QUERY,
} from 'client-lib';
import { useDispatch, useSelector } from 'react-redux';
import { setUpgradeBlurb } from '../../actions/general';
import useGetUserAccountPolicies from '../customer/useGetUserAccountPolicies';

const useOnLoadQueryUpgradeBlurbData = () => {
  const hour = 60 * 60 * 1000;
  const dispatch = useDispatch();

  const accountPolicies = useSelector(
    (state) => state?.session?.currentUser?.accountPolicies
  );
  const groupPolicies = useSelector(
    (state) => state?.session?.currentUser?.groupPolicies
  );
  const upgradeButtonFeatureAvailable = useSelector(
    (state) => state.accountData.account?.ff_upgrade_button
  );
  const userId = useSelector((state) => state.session.currentUser?.userId);

  useGetUserAccountPolicies({
    userId,
    actionList: [AVAILABLE_PERMISSIONS.VIEW_UPGRADE_BLURBS],
  });

  const upgradeProposalPermission = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.VIEW_UPGRADE_BLURBS,
    accountPolicies,
    groupPolicies
  );

  const allowUpgradeBlurbQuery =
    upgradeButtonFeatureAvailable && upgradeProposalPermission;

  const { data } = useQuery(GET_RANDOM_UPGRADE_BLURB_QUERY, {
    skip: !allowUpgradeBlurbQuery,
    pollInterval: hour,
  });

  useEffect(() => {
    if (data?.getRandomUpgradeBlurb) {
      dispatch(setUpgradeBlurb(data?.getRandomUpgradeBlurb));
    }
  }, [data?.getRandomUpgradeBlurb]);
};

export default useOnLoadQueryUpgradeBlurbData;
