const defaultArgs = { isBackupQuery: false };

const THREAD_EDGE_AMOUNT = process.env.PLATFORM === 'mobile' ? 10 : 30;

const threadsQueryVars = ({ isBackupQuery } = defaultArgs) => {
  const first = isBackupQuery ? 29 : THREAD_EDGE_AMOUNT;
  return {
    genMyInboxThreadsVars: ({ threadsActiveGroupIds, includeInternal }) => ({
      first,
      groupIds: threadsActiveGroupIds,
      includeInternal,
    }),

    genInboxSpamThreadsVars: ({
      threadsActiveGroupIds,
      contactId,
      includeInternal,
    }) => ({
      first,
      groupIds: threadsActiveGroupIds,
      ownerContactId: contactId,
      includeInternal,
    }),

    genMyOpenThreadsVars: ({
      threadsActiveGroupIds,
      contactId,
      includeInternal,
    }) => ({
      first,
      groupIds: threadsActiveGroupIds,
      ownerContactId: contactId,
      includeInternal,
    }),

    genAllOpenThreadsVars: ({
      threadsActiveGroupIds,
      contactId,
      includeInternal,
    }) => ({
      first,
      groupIds: threadsActiveGroupIds,
      exceptOwnerContactId: contactId,
      includeInternal,
    }),

    genMyClosedThreadVars: ({
      threadsActiveGroupIds,
      contactId,
      includeInternal,
    }) => ({
      first,
      groupIds: threadsActiveGroupIds,
      ownerContactId: contactId,
      includeInternal,
    }),

    genAllClosedThreadVars: ({
      threadsActiveGroupIds,
      contactId,
      includeInternal,
    }) => ({
      first,
      groupIds: threadsActiveGroupIds,
      exceptOwnerContactId: contactId,
      includeInternal,
    }),
  };
};

export default threadsQueryVars;
