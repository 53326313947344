import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { SquareBadge, Tooltip } from '../../../../elements';
import TextWithHighlights from '../../../Common/TextWithHighlights';

const TooltipContent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  max-width: 220px;
  padding: 4px;
`;

const DisplayedGroups = styled.div`
  display: flex;
  width: fit-content;
`;

const Wrapper = styled.div`
  display: flex;
`;

const OverflowWrapper = styled.div`
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const BadgesWithTooltip = ({
  audienceId,
  measure,
  isFirstRow,
  groups,
  targetedAudienceFilter,
  containerRef,
}) => {
  const [initialWidth, setInitialWidth] = useState();
  const displayedGroupsRef = useRef();
  const tooltipContentRef = useRef();

  const badgeRowContainer = containerRef?.current;
  const displayedGroups = displayedGroupsRef?.current;
  const displayedGroupBadges = groups.slice(0, initialWidth);
  const overflowGroupBadges = groups.slice(initialWidth);

  useEffect(() => {
    /**
     * Checks if the array of 3 badges would be wider
     * than the container, and if so will only render 2
     * before pushing the rest to the tooltip
     */
    const displayedGroupsWidth = displayedGroups?.clientWidth;
    const containerWidth = badgeRowContainer?.clientWidth;
    if (displayedGroupsWidth > containerWidth) {
      setInitialWidth(2);
    } else {
      setInitialWidth(3);
    }
  }, [displayedGroups]);

  const tooltipWidth = tooltipContentRef?.current?.clientWidth;
  const tooltipHeight = tooltipContentRef?.current?.clientHeight;
  const top = isFirstRow ? measure?.height : -tooltipHeight - 16;
  const right = isFirstRow ? -tooltipWidth / 2 + 4 : -tooltipWidth / 2 + 2;

  return (
    <Tooltip
      multiline
      place={isFirstRow ? 'bottom' : 'top'}
      elementsContent={
        <TooltipContent ref={tooltipContentRef}>
          {overflowGroupBadges.map((group) => (
            <SquareBadge key={group}>
              <OverflowWrapper>
                <TextWithHighlights
                  text={group}
                  highlightTarget={targetedAudienceFilter}
                />
              </OverflowWrapper>
            </SquareBadge>
          ))}
        </TooltipContent>
      }
      id={audienceId}
      customStyle={() => `position: absolute!important; 
        top: ${top}px !important; 
        right: ${right}px !important; 
        left: auto !important;`}
    >
      <Wrapper>
        <DisplayedGroups ref={displayedGroupsRef}>
          {displayedGroupBadges.map((group) => (
            <SquareBadge key={group}>
              <OverflowWrapper>
                <TextWithHighlights
                  text={group}
                  highlightTarget={targetedAudienceFilter}
                />
              </OverflowWrapper>
            </SquareBadge>
          ))}
        </DisplayedGroups>

        <SquareBadge
          data-tip
          data-for={audienceId}
          customStyle={() =>
            `display ${overflowGroupBadges.length === 0 ? 'none' : 'block'}`
          }
        >
          <>+{overflowGroupBadges.length}</>
        </SquareBadge>
      </Wrapper>
    </Tooltip>
  );
};

BadgesWithTooltip.propTypes = {
  groups: PropTypes.array.isRequired,
  audienceId: PropTypes.string.isRequired,
  measure: PropTypes.object.isRequired,
  isFirstRow: PropTypes.bool.isRequired,
  targetedAudienceFilter: PropTypes.string.isRequired,
  containerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
};

BadgesWithTooltip.defaultProps = {
  containerRef: {},
};

export default BadgesWithTooltip;
