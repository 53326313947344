import { useMutation } from "@apollo/client";
import UPDATE_SELECTED_GROUPS_MUTATION from "../../../graphql/mutation/UpdateSelectedGroupsMutation.graphql";

const useUpdateSelectedGroups = ({ client }) => {
  const [updateSelectedGroups] = useMutation(UPDATE_SELECTED_GROUPS_MUTATION, {
    client,
  });

  const updateSelectedGroupsHander = async ({
    groupIds,
    onSuccess,
    onError,
  }) => {
    const response = await updateSelectedGroups({
      variables: {
        groupIds,
      },
    }).catch((err) => onError(err));

    if (response?.data?.updateSelectedGroups?.errors) {
      onError(response.data.updateSelectedGroups.errors);
    } else {
      onSuccess(response?.data?.updateSelectedGroups?.groupIds);
    }
  };

  return {
    updateSelectedGroupsHander,
  };
};

export default useUpdateSelectedGroups;
