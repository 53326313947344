/* eslint-disable */
import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { formatPhoneNumber } from 'client-lib/src/lib/utils/helpers';
import { Select } from '../../../../../elements';

const SelectWrapper = styled.div`
  margin-bottom: 8px;
  width: 100%;
`;

const AnnouncementSenderField = ({
  announcementId,
  updateAnnouncementMutation,
  onError,
  outboundChannelId,
  channelsData,
  sendStartedAt,
  exteriorMutationHandling,
  onChange,
}) => {
  const [optimisticOutboundId, setOptmimisticOutboundId] = useState(undefined);

  const groupsDropdownList = channelsData?.channels?.map((channel) => {
    const label = channel.smsConfig.phoneNumber
      ? `${channel.group.name}, ${formatPhoneNumber(
          channel.smsConfig.phoneNumber
        )}`
      : `${channel.group.name}`;
    return {
      label,
      value: channel.id,
    };
  });

  const handleTriggerAnncUpdate = async (selectedGroup) => {
    setOptmimisticOutboundId(selectedGroup.value);
    const input = {
      id: announcementId,
      outboundChannelId: selectedGroup.value,
    };
    const { data } = await updateAnnouncementMutation({
      variables: { input },
    }).catch((e) => {
      console.error(e);
      onError?.();
    });
    setOptmimisticOutboundId(undefined);

    if (data?.updateAnnouncement?.errors) {
      onError?.();
    }
  };

  const handleChange = exteriorMutationHandling ? onChange : handleTriggerAnncUpdate

  return (
    <SelectWrapper>
      {groupsDropdownList ? (
        <Select
          label={i18n.t('broadcasts-BroadcastList-sender')}
          options={groupsDropdownList}
          disabled={groupsDropdownList?.length <= 1 || sendStartedAt}
          value={optimisticOutboundId || outboundChannelId}
          onChange={handleChange}
          helperText={i18n.t('slideouts-GroupMessageOverview-sendingNumber')}
        />
      ) : null}
    </SelectWrapper>
  );
};

AnnouncementSenderField.propTypes = {
  announcementId: PropTypes.string,
  outboundChannelId: PropTypes.string,
  updateAnnouncementMutation: PropTypes.func,
  onError: PropTypes.func.isRequired,
  channelsData: PropTypes.object,
  sendStartedAt: PropTypes.bool,
  exteriorMutationHandling: PropTypes.bool,
  onChange: PropTypes.func,
};

AnnouncementSenderField.defaultProps = {
  announcementId: null,
  updateAnnouncementMutation: () => {},
  outboundChannelId: '',
  channelsData: null,
  sendStartedAt: false,
  exteriorMutationHandling: false,
  onChange: () => {}
};

export default AnnouncementSenderField;
/* eslint-enable */
