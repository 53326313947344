import {
  ApolloClient,
  useMutation,
  type OperationVariables,
} from "@apollo/client";

import POST_SEND_COMPLETE_TASK_MUTATION from "../../../graphql/mutation/PostSendCompleteTaskMutation.graphql";
import TASKS_QUERY from "../../../graphql/query/TasksQuery.graphql";
import type { Task } from "./types";

interface PostSendCompleteTaskToDb {
  messageId: string;
  taskId: string;
}

interface UsePostSendCompleteTaskReturn {
  handlePostSendCompleteTask: (variables: PostSendCompleteTaskToDb) => void;
  postSendLoading: boolean;
}

interface UsePostSendCompleteTaskProps {
  client: ApolloClient<object>;
  handlePostSendError?: (error: unknown) => void;
  handlePostSendSuccess?: (data: Task) => void;
}

const usePostSendCompleteTask = ({
  client,
  handlePostSendError,
  handlePostSendSuccess,
}: UsePostSendCompleteTaskProps): UsePostSendCompleteTaskReturn => {
  const options: OperationVariables = { ...client };

  const [postSendCompleteTask, { loading: postSendLoading }] = useMutation(
    POST_SEND_COMPLETE_TASK_MUTATION,
    options
  );

  const handlePostSendCompleteTask = async (
    variables: PostSendCompleteTaskToDb
  ) => {
    try {
      const { data } = await postSendCompleteTask({
        variables,
        refetchQueries: [TASKS_QUERY],
        onQueryUpdated: (observableQuery) => {
          return observableQuery.refetch();
        },
      });

      if (data?.postSendCompleteTask?.errors) {
        handlePostSendError?.(data?.postSendCompleteTask?.errors);
      } else {
        handlePostSendSuccess?.(data?.postSendCompleteTask);
      }
    } catch (error) {
      handlePostSendError?.(error);
    }

    return {
      handlePostSendCompleteTask,
    };
  };

  return {
    handlePostSendCompleteTask,
    postSendLoading,
  };
};

export default usePostSendCompleteTask;
