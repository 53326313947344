import React, { useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { useDispatch, useSelector } from 'react-redux';
import { formatPhoneNumber } from 'client-lib/src/lib/utils/helpers';
import styled from 'styled-components';
import usePaginated from 'client-lib/src/lib/api/query/usePaginated';
import {
  ANNOUNCEMENT_AUDIENCE_QUERY,
  CUSTOMERS_QUERY_NO_CONNECTION,
  CREATE_TAG_FROM_ANNOUNCEMENT_MUTATION,
} from 'client-lib';
import { useApolloClient, useMutation } from '@apollo/client';
import InfiniteScroll from '../../../../InfiniteScroll/InfiniteScroll';
import {
  Modal,
  TabList,
  EntityCard,
  EmphasisText,
  Loading,
  Text,
} from '../../../../../elements';
import CreateEditList from '../../../../Modals/CreateEditList';
import { openSnackbar } from '../../../../../actions/general';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  height: 100%;
  ${(props) => {
    return props.scrollType === props.selectedTab ? 'display: none' : '';
  }}
`;

const InteriorModalBody = styled.div`
  width: 100%;
  height: 480px;
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TabListContainer = styled.div`
  padding-bottom: 4px;
`;

const tabOptions = (i18n) => [
  {
    label: i18n.t('slideouts-GroupMessageRecipients-reachable'),
    value: 'reachable audience',
  },
  {
    label: i18n.t('slideouts-GroupMessageRecipients-optedOut'),
    value: 'opted-out',
  },
];

const CustomerEntityCard = ({ customer, index }) => {
  const { firstName, lastName, phoneNumber, account } = customer;

  const accountName = account?.name || null;
  const accountNumber = account?.accountNumber || null;
  const subtext = accountNumber ? (
    <>
      {accountName}
      {'  '}|{'  '}
      <EmphasisText customStyle={() => 'display: inline-block;'}>
        ACCT:
      </EmphasisText>{' '}
      {accountNumber}
    </>
  ) : (
    accountName
  );
  return (
    <EntityCard
      key={index}
      maintext={
        firstName && lastName
          ? `${firstName} ${lastName}`
          : formatPhoneNumber(phoneNumber)
      }
      subtext={subtext}
      avatarChildren={firstName && lastName ? `${firstName} ${lastName}` : null}
    />
  );
};

CustomerEntityCard.propTypes = {
  customer: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

const ReachableAudienceModal = ({
  openModal,
  setOpenModal,
  announcementId,
  allContacts,
  proposedAdded,
  proposedRemoved,
  onSuccessListSave,
}) => {
  const client = useApolloClient();

  /**
   * LIST MODAL STUFF
   *
   */
  const [openListModal, setOpenListModal] = useState(false);

  const [selectedTab, setSelectedTab] = useState(tabOptions(i18n)[0].value);
  const [optOutStatus, setOptOutStatus] = useState(
    selectedTab !== 'reachable audience'
  );

  const dispatch = useDispatch();
  const accountId = useSelector(
    (state) => state?.session?.currentUser?.accountId
  );

  const activeOptions = tabOptions(i18n).map((option) => ({
    ...option,
    active: selectedTab === option.value,
  }));

  const handleTabSelect = ({ value }) => {
    setSelectedTab(value);
    setOptOutStatus(value !== 'reachable audience');
  };

  const proposal =
    proposedAdded && proposedRemoved
      ? {
          addedCompanyIds: proposedAdded.companyIds,
          addedContactIds: proposedAdded.contactIds,
          addedGroupIds: proposedAdded.groupIds,
          addedTagIds: proposedAdded.tagIds,
          removedCompanyIds: proposedRemoved.companyIds,
          removedContactIds: proposedRemoved.contactIds,
          removedGroupIds: proposedRemoved.groupIds,
          removedTagIds: proposedRemoved.tagIds,
        }
      : null;

  const query = allContacts
    ? CUSTOMERS_QUERY_NO_CONNECTION
    : ANNOUNCEMENT_AUDIENCE_QUERY;
  const queryVariables = allContacts
    ? { type: 'CUSTOMER_CONTACT' }
    : { announcementId, proposal };
  const queryKey = allContacts ? 'customers' : 'announcementAudience';

  const {
    handleFetchMore,
    pageInfo,
    announcementAudience,
    customers,
    loading,
  } = usePaginated({
    client,
    query,
    queryVariables: {
      ...queryVariables,
      optOutStatus: false,
      relayStylePagination: true,
    },
    resultsNumber: 30,
    key: queryKey,
    skip: !openModal,
  });

  const {
    handleFetchMore: handleFetchMoreOptOut,
    pageInfo: optOutPageInfo,
    announcementAudience: optOutAnnouncementAudience,
    customers: optOutCustomers,
    loading: optOutLoading,
  } = usePaginated({
    client,
    query,
    queryVariables: {
      ...queryVariables,
      optOutStatus: true,
      relayStylePagination: true,
    },
    resultsNumber: 30,
    key: queryKey,
    skip: !openModal,
  });

  const noResultsMessage =
    selectedTab === 'reachable audience'
      ? i18n.t('broadcasts-ReachableAudienceModal-noReachable')
      : i18n.t('broadcasts-ReachableAudienceModal-noOptedOut');

  const scrollCustomers = allContacts ? customers : announcementAudience;
  const optOutScrollCustomers = allContacts
    ? optOutCustomers
    : optOutAnnouncementAudience;

  /**
   * LIST MODAL STUFF
   *
   */
  const handleOnCloseListModal = () => {
    setOpenListModal(false);
  };

  const handleError = (message) => {
    dispatch(openSnackbar(message, 'error'));
  };

  const handleSuccess = () => {
    onSuccessListSave();
    dispatch(openSnackbar(i18n.t('settings-TagList-createdSuccessfully')));
  };

  const [createTagFromAnnouncement] = useMutation(
    CREATE_TAG_FROM_ANNOUNCEMENT_MUTATION,
    { client }
  );

  const handleCreateList = async (listName, listDescription) => {
    const { data } = await createTagFromAnnouncement({
      variables: {
        input: {
          name: listName,
          description: listDescription,
          accountId,
          announcementId,
          proposal: {
            addedCompanyIds: proposedAdded?.companyIds,
            addedContactIds: proposedAdded?.contactIds,
            addedGroupIds: proposedAdded?.groupIds,
            removedCompanyIds: proposedRemoved?.companyIds,
            removedContactIds: proposedRemoved?.contactIds,
            removedGroupIds: proposedRemoved?.groupIds,
            targetAllContacts: allContacts,
          },
        },
      },
    }).catch(() => {
      handleError(i18n.t('settings-TagList-error'));
    });

    setOpenModal(false);
    if (data?.createTagFromAnnouncement?.errors) {
      const {
        createTagFromAnnouncement: { errors },
      } = data;
      if (errors[0].reason === 'has already been taken') {
        handleError(i18n.t('settings-TagList-nameTaken'));
      }
    } else {
      handleSuccess();
    }
  };

  return (
    <Modal
      isOpen={openModal}
      size="sm"
      onRequestClose={() => setOpenModal(false)}
      disableOverlay={openListModal}
      modalTitle={i18n.t('broadcasts-ReachableAudienceModal-breakdown', {
        defaultValue: 'Audience Breakdown',
      })}
      customStyle={() => `
        min-width: 550px;
        width: 35vw!important; 
        max-width: 35vw!important;`}
      secondaryButtonText={i18n.t('broadcasts-ReachableAudienceModal-saveList')}
      secondaryButtonOnClick={() => {
        setOpenListModal(true);
      }}
      secondaryButtonProps={{ disabled: allContacts }}
      primaryButtonText={i18n.t('broadcasts-ReachableAudienceModal-close')}
      primaryButtonOnClick={() => setOpenModal(false)}
    >
      <Wrapper scrollType="opted-out" selectedTab={selectedTab}>
        <TabListContainer>
          <TabList
            options={activeOptions}
            onClick={handleTabSelect}
            type="secondary"
          />
        </TabListContainer>
        {(loading || optOutLoading) && !scrollCustomers?.length ? (
          <InteriorModalBody>
            <Loading />
          </InteriorModalBody>
        ) : (
            optOutStatus
              ? optOutScrollCustomers?.length
              : scrollCustomers?.length
          ) ? (
          <InfiniteScroll
            bidirectionalScroll={false}
            scrollableList={scrollCustomers}
            height="480px"
            noRowsElement={<div />}
            renderRow={({ list, index }) => (
              <CustomerEntityCard customer={list[index]} index={index} />
            )}
            loading={false}
            hasNextPage={pageInfo.hasNextPage}
            loadingBorderBottom={false}
            loadingHeight={77}
            listItemHeight={77}
            loadMoreRows={handleFetchMore}
          />
        ) : (
          <InteriorModalBody>
            <Text>{noResultsMessage}</Text>
          </InteriorModalBody>
        )}
      </Wrapper>
      <Wrapper scrollType="reachable audience" selectedTab={selectedTab}>
        <TabListContainer>
          <TabList
            options={activeOptions}
            onClick={handleTabSelect}
            type="secondary"
          />
        </TabListContainer>
        {(loading || optOutLoading) && !scrollCustomers?.length ? (
          <InteriorModalBody>
            <Loading />
          </InteriorModalBody>
        ) : (
            optOutStatus
              ? optOutScrollCustomers?.length
              : scrollCustomers?.length
          ) ? (
          <InfiniteScroll
            bidirectionalScroll={false}
            scrollableList={optOutScrollCustomers}
            height="480px"
            noRowsElement={<div />}
            renderRow={({ list, index }) => (
              <CustomerEntityCard customer={list[index]} index={index} />
            )}
            loading={false}
            hasNextPage={optOutPageInfo.hasNextPage}
            loadingBorderBottom={false}
            loadingHeight={77}
            listItemHeight={77}
            loadMoreRows={handleFetchMoreOptOut}
          />
        ) : (
          <InteriorModalBody>
            <Text>{noResultsMessage}</Text>
          </InteriorModalBody>
        )}
      </Wrapper>
      <CreateEditList
        handleOnClose={handleOnCloseListModal}
        open={openListModal}
        client={client}
        handleOnConfirm={handleCreateList}
      />
    </Modal>
  );
};

ReachableAudienceModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  announcementId: PropTypes.string.isRequired,
  allContacts: PropTypes.bool,
  proposedAdded: PropTypes.object,
  proposedRemoved: PropTypes.object,
  onSuccessListSave: PropTypes.func,
};

ReachableAudienceModal.defaultProps = {
  allContacts: null,
  proposedAdded: null,
  proposedRemoved: null,
  onSuccessListSave: () => {},
};

export default ReachableAudienceModal;
