import React from 'react';
import PropTypes from 'prop-types';
import LabelCard from '../../../EntityCard/LabelCard';

const LabelSuggestion = ({ suggestion }) => {
  return <LabelCard suggestion={suggestion} />;
};

LabelSuggestion.propTypes = {
  suggestion: PropTypes.object.isRequired,
  isHighlighted: PropTypes.bool,
};

LabelSuggestion.defaultProps = {
  isHighlighted: false,
};

export default LabelSuggestion;
