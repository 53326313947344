import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

const RouteFilter = ({ path, children }) => (
  <Route exact path={path}>
    {(props) => {
      if (!props.match) {
        return null;
      }
      return children;
    }}
  </Route>
);

RouteFilter.propTypes = {
  path: PropTypes.string.isRequired,
  match: PropTypes.string,
  children: PropTypes.object.isRequired,
};

RouteFilter.defaultProps = {
  match: '',
};

export default RouteFilter;
