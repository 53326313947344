/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { useApolloClient } from '@apollo/client';
import {
  useGroupQuery,
  USERS_QUERY,
  SEARCH_CUSTOMERS_QUERY,
  AVAILABLE_PERMISSIONS,
  checkIfCurrentUserHasPermission,
} from 'client-lib';
import styled from 'styled-components';
import i18n from 'i18n-js';
import theme from 'styled-theming';
import Tooltip from '../../elements/Tooltip/Tooltip';
import {
  Heading2,
  Text,
  EmphasisText,
  Button,
  TextInput,
  Switch,
  Checkbox,
  InfiniteSuggest,
  SelectedEntity,
  Select,
} from '../../elements';
import THEMES from '../../styles/themes/app';
import LabelFormInput from '../LabelFormInput/LabelFormInput.tsx';
import GroupAsyncSelect from '../AsyncSelects/GroupAsyncSelect';

const ROW_HEIGHT = theme('fontSize', {
  default: '95px',
  large: '128px',
});

const GROUP_ERROR_CONTAINER_MIN_HEIGHT = theme('fontSize', {
  default: '118px',
  large: '132px',
});

const GROUP_ERROR_POSITION = theme('fontSize', {
  default: '70px',
  large: '80px',
});

const Container = styled(Grid)`
  width: 100%;
  padding-top: 50px;
  display: flex;
  justify-content: center;
`;

const FlexContainer = styled(Grid)`
  width: 600px;
  max-width: 600px;
`;

const FormContainer = styled(Grid)`
  width: 100%;
`;

const InputWrapperRight = styled(Grid)`
  flex: 1;
  margin-left: 5px;
`;

const PriorityNotificationContainer = styled(Grid)`
  display: flex;
  align-items: center;
  flex: 1;
  margin-left: 8px;
`;

const InputRow = styled(Grid)`
  display: flex;
  justify-content: space-between;

  &:first-of-type {
    /* This allows enough room for the errors in the first and lastnames */
    height: ${ROW_HEIGHT};
  }
`;

const InputWrapper = styled(Grid)`
  flex: 1;
  margin-right: ${(props) => (props.marginrightval ? '0' : '5px')};
`;

const WelcomeMessageInput = styled(Grid)`
  height: 30px;
  margin-bottom: 8px;
  flex: 1;
`;

const InfoWrapper = styled(Grid)`
  margin-left: 8px;
  font-size: 20px;
  color: ${THEMES.FOREGROUND_LOW};
  display: flex;
  align-items: flex-end;
`;

const SingleInputRow = styled(Grid)`
  flex: 1;
`;

const AccountInput = styled(Grid)`
  flex: 1;
`;

const TooltipContainer = styled.div`
  max-width: 400px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const PriorityLabelContainer = styled(Grid)`
  margin-bottom: 7px;
  flex: 1;
`;

const WrapGroupDropDown = styled.div`
  padding-bottom: 40px;
  position: relative;
  min-height: ${GROUP_ERROR_CONTAINER_MIN_HEIGHT};

  input + div {
    position: absolute;
    top: ${GROUP_ERROR_POSITION};
  }

  div[open] {
    top: 0 !important;
  }
`;

const BottomPad = styled.div`
  height: 130px;
`;

const FIRST = 10;

const GroupWelcomeMessageContent = ({
  group,
  firstName,
  lastName,
  accountName,
}) => {
  let welcomeMessageTemplate = '';

  const client = useApolloClient();

  const { data } = useGroupQuery({
    client,
    fetchPolicy: 'network-only',
    variables: { id: group.value },
  });

  const customStyle = () => 'text-overflow: ellipsis; overflow: hidden;';

  if (data && data.group && data.group.welcomeMessageTemplate) {
    welcomeMessageTemplate = data.group.welcomeMessageTemplate;
  }

  const welcomeMessageText = welcomeMessageTemplate
    .replace('{contact_first_name}', firstName)
    .replace('{contact_last_name}', lastName)
    .replace('{account_name}', accountName)
    .replace('{group_name}', group.text);

  return (
    <>
      <EmphasisText
        customStyle={customStyle}
      >{`From ${group.text}:`}</EmphasisText>
      <Text customStyle={customStyle}>{welcomeMessageText}</Text>
    </>
  );
};

GroupWelcomeMessageContent.propTypes = {
  group: PropTypes.object.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  accountName: PropTypes.string.isRequired,
};

/* eslint-disable react/prop-types */
const genTooltipContent = ({ firstName, lastName, accountName, groups }) => {
  if (!groups || !groups.length) return null;

  return (
    <>
      {groups.map((group) => (
        <TooltipContainer key={group.value}>
          <GroupWelcomeMessageContent
            group={group}
            firstName={firstName}
            lastName={lastName}
            accountName={accountName}
          />
        </TooltipContainer>
      ))}
    </>
  );
};
/* eslint-enable react/prop-types */

const CreateCustomerForm = ({
  customerFormProps,
  handleOnClose,
  dispatchSetCreateCustomer,
  accountPolicies,
  groupPolicies,
}) => {
  const [firstNameBlur, setFirstNameBlur] = useState(false);
  const [lastNameBlur, setLastNameBlur] = useState(false);

  const {
    updateField,
    onSubmit,
    isVisitedForm,
    handleOnCompanySelect,
    handleAccountRepSelect,
    handleAccountRepDelete,
    handleAccountRepBlur,
    handlePhoneNumberChange,
    handleFaxNumberChange,
    handleEmailChange,
    handleOnSelectGroup,
    handleCompanyChange,
    fields,
    existingPhoneNumberId,
    existingFaxNumberId,
    existingEmailId,
    accountName,
    accountRepEditPermission,
    priorityNotificationsTogglePermission,
    createMutationLoading,
  } = customerFormProps;

  const {
    firstName,
    lastName,
    jobTitle,
    email,
    phoneNumber,
    faxNumber,
    welcomeMessage,
    group,
    company,
    accountEmail,
    accountNumber,
    accountPhoneNumber,
    accountRep,
    prioritizeRep,
    updateContact,
    updateContactType,
    isMergeContact,
    mergeOn,
    newEmail,
    newFaxNumber,
    newPhoneNumber,
    labels,
  } = fields;

  const phoneOptions = () => {
    if (mergeOn.value === 'phoneNumber' && phoneNumber?.value) {
      return [
        {
          label: newPhoneNumber?.otherProps?.text,
          value: newPhoneNumber?.value,
        },
        {
          label: i18n.t('slideouts-CreateCustomerForm-oldData', {
            mergeData: phoneNumber.otherProps.text,
            defaultValue: `${phoneNumber.otherProps.text} (old)`,
          }),
          value: phoneNumber.value,
        },
      ];
    }
    if (mergeOn.value === 'phoneNumber' && !phoneNumber?.value) {
      return [
        {
          label: newPhoneNumber?.otherProps?.text,
          value: newPhoneNumber?.value,
        },
      ];
    }
    return [];
  };

  const faxOptions = () => {
    if (mergeOn.value === 'faxNumber' && faxNumber?.value) {
      return [
        {
          label: newFaxNumber.otherProps.text,
          value: newFaxNumber.value,
        },
        {
          label: i18n.t('slideouts-CreateCustomerForm-oldData', {
            mergeData: faxNumber.otherProps.text,
            defaultValue: `${faxNumber.otherProps.text} (old)`,
          }),
          value: faxNumber.value,
        },
      ];
    }
    if (mergeOn.value === 'faxNumber' && !faxNumber?.value) {
      return [
        {
          label: newFaxNumber.otherProps.text,
          value: newFaxNumber.value,
        },
      ];
    }
    return [];
  };

  const emailOptions = () => {
    if (mergeOn.value === 'emailAddress' && email?.value) {
      return [
        {
          label: newEmail.value,
          value: newEmail.value,
        },
        {
          label: i18n.t('slideouts-CreateCustomerForm-oldData', {
            mergeData: email.value,
            defaultValue: `${email.value} (old)`,
          }),
          value: email.value,
        },
      ];
    }
    if (mergeOn.value === 'emailAddress' && !email?.value) {
      return [
        {
          label: newEmail.value,
          value: newEmail.value,
        },
      ];
    }
    return [];
  };

  const [phoneSelectValue, setPhoneSelectValue] = useState(
    phoneOptions()?.[0]?.value
  );
  const [faxSelectValue, setFaxSelectValue] = useState(
    faxOptions()?.[0]?.value
  );
  const [emailSelectValue, setEmailSelectValue] = useState(
    emailOptions()?.[0]?.value
  );

  useEffect(() => {
    const {
      group: { value },
    } = fields;
    const defaultGroup = value.map((cur) => {
      return { label: cur.text, value: cur.value };
    });

    const createCustomerRedux = {
      id: fields.id.value,
      firstName: fields.firstName.value,
      lastName: fields.lastName.value,
      jobTitle: fields.jobTitle.value,
      email: fields.email.value,
      phoneNumber: fields.phoneNumber.value,
      faxNumber: fields.faxNumber.value,
      welcomeMessage: fields.welcomeMessage.value,
      company: {
        value: fields.company.value,
        text: fields.company.otherProps.text,
        addCompanySuggestion: fields.company.otherProps.addCompanySuggestion,
      },
      accountPhoneNumber: fields?.accountPhoneNumber
        ? fields?.accountPhoneNumber?.value
        : '',
      accountEmail: fields?.accountEmail ? fields?.accountEmail?.value : '',
      accountNumber: fields?.accountNumber ? fields?.accountNumber?.value : '',
      group: {
        value: defaultGroup,
        label: fields.group.otherProps.text,
      },
      accountRep: {
        value: fields.accountRep.value,
        id: fields.accountRep.otherProps.id,
        lastSelectedName: fields.accountRep.otherProps.lastSelectedName,
        selectedUser: fields.accountRep.otherProps.selectedUser,
      },
      prioritizeRep: fields.prioritizeRep.value,
      updateContact: fields?.updateContact?.value,
      updateContactType: fields?.updateContactType?.value,
      isMergeContact: fields?.isMergeContact?.value,
      mergeOn: fields?.mergeOn?.value,
      newPhoneNumber: fields?.newPhoneNumber.value,
      newFaxNumber: fields?.newFaxNumber.value,
      newEmail: fields?.newEmail.value,
      phoneSelectValue: fields?.phoneSelectValue.value,
    };
    dispatchSetCreateCustomer(createCustomerRedux);
  }, [
    fields.firstName.value,
    fields.lastName.value,
    fields.jobTitle.value,
    fields.email.value,
    fields.phoneNumber.value,
    fields.faxNumber.value,
    fields.welcomeMessage.value,
    fields.company.value,
    fields?.accountPhoneNumber?.value,
    fields?.accountEmail?.value,
    fields?.accountNumber?.value,
    fields.group.value,
    fields.accountRep.value,
    fields.prioritizeRep.value,
    fields.id.value,
    fields?.updateContact?.value,
    fields?.updateContactType?.value,
    fields?.isMergeContact?.value,
    fields?.mergeOn?.value,
    fields?.newPhoneNumber.value,
    fields?.newFaxNumber.value,
    fields?.newEmail.value,
    fields?.phoneSelectValue.value,
  ]);

  const phoneNumberError =
    phoneNumber.visited && existingPhoneNumberId
      ? i18n.t('slideouts-CreateCustomerForm-numAlreadyExists')
      : phoneNumber.error;

  const faxError =
    faxNumber.visited && existingFaxNumberId
      ? i18n.t('slideouts-CreateCustomerForm-numAlreadyExists')
      : faxNumber.error;

  const emailError =
    email.visited && existingEmailId
      ? i18n.t('settings-ProfileFormContainer-emailDuplicateError')
      : email.error;

  const firstNameError =
    firstName.visited && firstNameBlur ? firstName.error : '';

  const lastNameError = lastName.visited && lastNameBlur ? lastName.error : '';

  const handleFirstNameChange = (ev) => {
    const value = ev.target.value;
    updateField({ name: 'firstName', value });
  };

  const handleLastNameChange = (ev) => {
    const value = ev.target.value;
    updateField({ name: 'lastName', value });
  };

  const handleJobTitleChange = (ev) => {
    const value = ev.target.value;
    updateField({ name: 'jobTitle', value });
  };

  const handleLabelsChange = (val) => {
    updateField({
      name: 'labels',
      value: val,
    });
  };

  const handleAccountNumberChange = (ev) => {
    const value = ev.target.value;
    updateField({ name: 'accountNumber', value });
  };

  const handleAccountEmailChange = (ev) => {
    const value = ev.target.value;
    updateField({ name: 'accountEmail', value });
  };

  const handleWelcomeMessageChange = () => {
    const value = !welcomeMessage.value;
    updateField({ name: 'welcomeMessage', value });
  };

  const createDisplayCompanySuggestionCallback =
    (companyName) => (companySuggestions) => {
      // don't let user create customer account with just spaces
      if (companyName?.trim() === '') return false;

      const displaySticky = companySuggestions.reduce((acc, company) => {
        if (!acc) return acc;
        return !(company?.name?.toLowerCase() === companyName?.toLowerCase());
      }, true);
      return displaySticky;
    };

  const userCanEditCompanies = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.EDIT_COMPANIES,
    accountPolicies,
    groupPolicies
  );

  const editContactLabelsPermissions = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.EDIT_CONTACT_LABELS,
    accountPolicies,
    groupPolicies
  );

  return (
    <Container container justifyContent="center">
      <FlexContainer>
        <Grid container alignItems="flex-start">
          <Heading2 dataTestid="new-contact-header">
            {isMergeContact?.value
              ? i18n.t('slideouts-CreateCustomerForm-updateContact', {
                  defaultValue: 'Update Contact',
                })
              : i18n.t('slideouts-CreateCustomerForm-newContact', {
                  defaultValue: 'New Contact',
                })}
          </Heading2>
        </Grid>
        <FormContainer item>
          <InputRow container justifyContent="center">
            <InputWrapper>
              <TextInput
                label={i18n.t('slideouts-CreateCustomerForm-firstName')}
                error={firstNameError}
                dataTestId="first-name-input"
                value={firstName.value}
                onChange={handleFirstNameChange}
                autoFocus
                ref={firstName.ref}
                onBlur={() => setFirstNameBlur(true)}
              />
            </InputWrapper>

            <InputWrapperRight>
              <TextInput
                label={i18n.t('slideouts-CreateCustomerForm-lastName')}
                error={lastNameError}
                dataTestId="last-name-input"
                value={lastName.value}
                onChange={handleLastNameChange}
                ref={lastName.ref}
                onBlur={() => setLastNameBlur(true)}
              />
            </InputWrapperRight>
          </InputRow>

          <InputRow container justifyContent="center">
            <InputWrapper>
              <TextInput
                label={i18n.t('customers-Contacts-title', {
                  defaultValue: 'Job Title',
                })}
                dataTestId="job-title-input"
                value={jobTitle.value}
                onChange={handleJobTitleChange}
                ref={jobTitle.ref}
                error={jobTitle.error}
              />
            </InputWrapper>
          </InputRow>

          <InputRow container justifyContent="center">
            <InputWrapper>
              {isMergeContact && phoneSelectValue ? (
                <Select
                  label={i18n.t('general-mobile')}
                  options={phoneOptions()}
                  value={phoneSelectValue}
                  onChange={(e) => {
                    setPhoneSelectValue(e.value);
                    updateField({
                      name: 'phoneSelectValue',
                      value: e.value,
                      otherProps: { text: e.label },
                    });
                  }}
                />
              ) : (
                <>
                  <TextInput
                    label={i18n.t('general-mobile')}
                    error={phoneNumberError}
                    disabled={
                      updateContact.value &&
                      updateContactType.value === 'conversations'
                    }
                    dataTestId="phone-number-input"
                    value={phoneNumber.otherProps.text}
                    onChange={(ev) => handlePhoneNumberChange(ev, 'contact')}
                    ref={phoneNumber.ref}
                  />
                  {phoneNumber.visited && existingPhoneNumberId ? (
                    <Button
                      type="link"
                      size="md"
                      noOutline
                      customStyle={() => 'padding: 0;'}
                      onClick={() => handleOnClose(existingPhoneNumberId, true)}
                    >
                      {i18n.t('slideouts-CreateCustomerForm-clickHere')}
                    </Button>
                  ) : null}
                </>
              )}
            </InputWrapper>

            <InputWrapperRight>
              {isMergeContact && faxSelectValue ? (
                <Select
                  label={i18n.t('slideouts-CreateCustomerForm-fax', {
                    defaultValue: 'Fax',
                  })}
                  options={faxOptions()}
                  value={faxSelectValue}
                  onChange={(e) => {
                    setFaxSelectValue(e.value);
                    updateField({
                      name: 'faxSelectValue',
                      value: e.value,
                      otherProps: { text: e.label },
                    });
                  }}
                />
              ) : (
                <>
                  <TextInput
                    label={i18n.t('slideouts-CreateCustomerForm-fax', {
                      defaultValue: 'Fax',
                    })}
                    error={faxError}
                    disabled={
                      updateContact.value && updateContactType.value === 'fax'
                    }
                    dataTestId="fax-number-input"
                    value={faxNumber.otherProps.text}
                    onChange={(ev) => handleFaxNumberChange(ev)}
                    ref={faxNumber.ref}
                  />
                  {faxNumber.visited && existingFaxNumberId ? (
                    <Button
                      type="link"
                      size="md"
                      noOutline
                      customStyle={() => 'padding: 0;'}
                      onClick={() => handleOnClose(existingFaxNumberId, true)}
                    >
                      {i18n.t('slideouts-CreateCustomerForm-clickHere')}
                    </Button>
                  ) : null}
                </>
              )}
            </InputWrapperRight>
          </InputRow>

          <InputRow container>
            {isMergeContact && emailSelectValue ? (
              <Select
                label={i18n.t('slideouts-CreateCustomerForm-email')}
                options={emailOptions()}
                value={emailSelectValue}
                onChange={(e) => {
                  setEmailSelectValue(e.value);
                  updateField({ name: 'emailSelectValue', value: e.value });
                }}
              />
            ) : (
              <>
                <TextInput
                  label={i18n.t('slideouts-CreateCustomerForm-email')}
                  error={emailError}
                  disabled={
                    updateContact.value && updateContactType.value === 'email'
                  }
                  dataTestId="email-input"
                  value={email.value}
                  onChange={handleEmailChange}
                  ref={email.ref}
                />
                {email.visited && existingEmailId ? (
                  <Button
                    type="link"
                    size="md"
                    noOutline
                    customStyle={() => 'padding: 0;'}
                    onClick={() => handleOnClose(existingEmailId, true)}
                  >
                    {i18n.t('slideouts-CreateCustomerForm-clickHere')}
                  </Button>
                ) : null}
              </>
            )}
          </InputRow>

          {accountRepEditPermission && (
            <InputRow container justifyContent="center">
              <InputWrapper
                item
                marginrightval={
                  !priorityNotificationsTogglePermission ? '0' : '5px'
                }
              >
                {!accountRep?.otherProps?.selectedUser?.id ? (
                  <InfiniteSuggest
                    inputProps={{
                      label: i18n.t('slideouts-CreateCustomerForm-accountRep'),
                      onBlur: handleAccountRepBlur,
                      value: accountRep.value,
                      onChange: (e) => accountRep.setValue(e.target.value),
                      placeholder: i18n.t(
                        'slideouts-CreateThread-enterNameOrNumber'
                      ),
                    }}
                    query={USERS_QUERY}
                    queryKey="users"
                    queryVariables={{
                      after: null,
                      first: FIRST,
                      around: null,
                      before: null,
                      filter: accountRep.value,
                      last: null,
                      groupIds: null,
                    }}
                    onSelect={(e, { suggestion }) =>
                      handleAccountRepSelect(suggestion)
                    }
                  />
                ) : (
                  <SelectedEntity
                    label={i18n.t('slideouts-CreateCustomerForm-accountRep')}
                    maintext={accountRep.otherProps.lastSelectedName}
                    subtext={accountRep.otherProps.selectedUser.role}
                    onDelete={handleAccountRepDelete}
                    avatarProps={{
                      avatarUrl: accountRep.otherProps.selectedUser.avatarUrl,
                      icon: 'user',
                    }}
                  />
                )}
              </InputWrapper>
              {priorityNotificationsTogglePermission && (
                <PriorityNotificationContainer>
                  <PriorityLabelContainer>
                    <Text contrast="med">
                      {i18n.t('slideouts-CreateCustomerForm-priority')}
                    </Text>
                    <Text contrast="low" customStyle={() => 'font-size: 12px;'}>
                      {i18n.t('slideouts-CreateCustomerForm-showNewMessages')}
                    </Text>
                  </PriorityLabelContainer>
                  <Grid>
                    <Switch
                      checked={prioritizeRep.value}
                      onCheck={() =>
                        prioritizeRep.setValue(!prioritizeRep.value)
                      }
                      disabled={!accountRep.otherProps.id}
                    />
                  </Grid>
                </PriorityNotificationContainer>
              )}
            </InputRow>
          )}

          {editContactLabelsPermissions && (
            <InputRow>
              <SingleInputRow>
                <LabelFormInput
                  labelValues={labels?.value}
                  setLabelValues={handleLabelsChange}
                />
              </SingleInputRow>
            </InputRow>
          )}

          <InputRow container justifyContent="center">
            <SingleInputRow>
              <InfiniteSuggest
                inputProps={{
                  label: i18n.t('slideouts-CreateCustomerForm-company'),
                  onBlur: () => handleCompanyChange({ target: { value: '' } }),
                  value: company.otherProps.name,
                  onChange: handleCompanyChange,
                  placeholder: i18n.t(
                    'slideouts-CreateCustomerForm-searchCompanies'
                  ),
                }}
                query={SEARCH_CUSTOMERS_QUERY}
                queryKey="searchCustomers"
                queryVariables={{
                  typeFilter: 'CUSTOMER_ACCOUNT',
                  query: company.otherProps.name,
                  first: FIRST,
                }}
                onSelect={(e, { suggestion }) =>
                  handleOnCompanySelect(suggestion)
                }
                displayStickyButton={() =>
                  userCanEditCompanies &&
                  createDisplayCompanySuggestionCallback(
                    company.otherProps.name
                  )
                }
                stickyButtonText={i18n.t(
                  'slideouts-CompanyDropDown-createNew',
                  { companyName: company.otherProps.name }
                )}
                onStickyButtonClick={() =>
                  handleOnCompanySelect({
                    addCompanySuggestion: true,
                    name: company.otherProps.name,
                  })
                }
              />
            </SingleInputRow>
          </InputRow>

          {company.otherProps.addCompanySuggestion && (
            <>
              <InputRow container justifyContent="center">
                <InputWrapper
                  // item
                  name="companyPhone"
                >
                  <TextInput
                    label={i18n.t('slideouts-CreateCustomerForm-companyPhone')}
                    error={accountPhoneNumber.error}
                    value={accountPhoneNumber.otherProps.text}
                    onChange={(ev) => handlePhoneNumberChange(ev, 'account')}
                    ref={accountPhoneNumber.ref}
                  />
                </InputWrapper>
                <InputWrapperRight
                  // item
                  name="companyEmail"
                >
                  <TextInput
                    label={i18n.t('slideouts-CreateCustomerForm-companyEmail')}
                    error={accountEmail.error}
                    value={accountEmail.value}
                    onChange={handleAccountEmailChange}
                    ref={accountEmail.ref}
                  />
                </InputWrapperRight>
              </InputRow>

              <InputRow container>
                <AccountInput item name="accountNumber">
                  <TextInput
                    label={i18n.t('slideouts-CreateCustomerForm-accountNumber')}
                    error={accountNumber.error}
                    value={accountNumber.value}
                    onChange={handleAccountNumberChange}
                    ref={accountNumber.ref}
                  />
                </AccountInput>
              </InputRow>
            </>
          )}

          <InputRow container justifyContent="center">
            <SingleInputRow item>
              <WrapGroupDropDown>
                <GroupAsyncSelect
                  dataTestId="create-contact-group"
                  value={group?.value || []}
                  setValue={(e) => {
                    handleOnSelectGroup(e);
                  }}
                  label={i18n.t('settings-TeamMemberForm-groups', {
                    defaultValue: 'Groups',
                  })}
                  placeholder={i18n.t(
                    'settings-GroupDropDown-groupDropDownPlaceholder'
                  )}
                  isMulti
                  omitAll
                  omitFilter
                />
              </WrapGroupDropDown>
            </SingleInputRow>
          </InputRow>
          <WelcomeMessageInput
            container
            name="welcomeMessage"
            alignItems="center"
          >
            <Checkbox
              disabled={!phoneNumber.value}
              checked={welcomeMessage.value}
              onCheck={handleWelcomeMessageChange}
              label={i18n.t('slideouts-CreateCustomerForm-welcomeMessage')}
            />
            <div style={{ position: 'relative' }}>
              {group.value.length > 0 ? (
                <Tooltip
                  id="welcomeTooltip"
                  place="top"
                  elementsContent={genTooltipContent({
                    firstName: firstName.value,
                    lastName: lastName.value,
                    accountName,
                    groups: group.value,
                  })}
                  customStyle={() => `position: absolute!important; 
                                      top: -140px !important; 
                                      right: auto !important; 
                                      left: -116px !important;
                                      width: 250px;`}
                >
                  <InfoWrapper>
                    <i
                      data-tip
                      data-for="welcomeTooltip"
                      className="ri-information-line"
                    />
                  </InfoWrapper>
                </Tooltip>
              ) : (
                ''
              )}
            </div>
          </WelcomeMessageInput>
          <Button
            loadingSpinner={createMutationLoading}
            disabled={
              (!isVisitedForm && !isMergeContact.value) || createMutationLoading
            }
            onClick={onSubmit}
            dataTestId="submit-button"
          >
            {i18n.t('slideouts-CreateCustomerForm-submit')}
          </Button>
          <BottomPad />
        </FormContainer>
      </FlexContainer>
    </Container>
  );
};

CreateCustomerForm.propTypes = {
  customerFormProps: PropTypes.object,
  handleOnClose: PropTypes.func,
  dispatchSetCreateCustomer: PropTypes.func,
  accountPolicies: PropTypes.array.isRequired,
  groupPolicies: PropTypes.array.isRequired,
};

CreateCustomerForm.defaultProps = {
  customerFormProps: {},
  handleOnClose: () => {},
  dispatchSetCreateCustomer: () => {},
};

export default CreateCustomerForm;
