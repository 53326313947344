import {
  $applyNodeReplacement,
  createCommand,
  ElementNode,
  type DOMConversionOutput,
  type LexicalCommand,
  type LexicalNode,
} from 'lexical';
import { ImageNode } from '../nodes/ImageNode';
import type { Attachment } from '../../../utils/helpers/types';

/**
 * Enum representing the types of insert operations that can be performed in the Rich Text Editor.
 *
 * @enum {number}
 * @property {number} insertAtSelection - Represents an insert operation that inserts content at the current selection.
 * @property {number} replaceNodes - Represents an insert operation that replaces all the content in the editor.
 */

// eslint-disable-next-line import/prefer-default-export
export enum InsertType {
  appendNodes,
  insertAtSelection,
  replaceNodes,
}

interface ImagePayload {
  src: string;
  altText: string;
  width?: number | 'inherit';
  height?: number | 'inherit';
  attachment: Attachment;
  key?: string;
}

export const MIN_LEXICAL_IMAGE_WIDTH = 50;

export const DEFAULT_IMAGE_WIDTH = 300;

// This function will return a new instance of the ImageNode based on the payload
export const $createImageNode = ({
  src,
  altText,
  width,
  height,
  attachment,
}: ImagePayload): ImageNode => {
  return $applyNodeReplacement(
    new ImageNode({
      src,
      altText,
      width,
      height,
      attachment,
    })
  );
};

// This function will convert a DOM node to an ImageNode
export const $convertImageElement = (
  domNode: Node
): null | DOMConversionOutput => {
  const img = domNode as HTMLImageElement;

  const src = img.getAttribute('src');
  const altText = img.getAttribute('alt');
  const styleString = img.getAttribute('style') || '';
  const attachmentType = img.getAttribute('data-attachment-type');
  const attachmentSize = img.getAttribute('data-attachment-size');
  const attachmentData = img.getAttribute('data-attachment-data');
  const attachmentId = img.getAttribute('data-attachment-id');
  const attachmentOriginalFilename = img.getAttribute(
    'data-attachment-original-filename'
  );

  const widthMatch = styleString.match(/width:\s*(\d+)px/);
  const widthPx = widthMatch ? widthMatch[1] : null;
  const heightMatch = styleString.match(/height:\s*(\d+)px/);
  const heightPx = heightMatch ? heightMatch[1] : null;

  if (!src || !altText) {
    return null;
  }

  const attachment: Attachment = {
    data: attachmentData || '',
    id: attachmentId || '',
    originalFilename: attachmentOriginalFilename || '',
    size: attachmentSize ? parseInt(attachmentSize, 10) : 0,
    type: attachmentType || '',
  };

  const node = $createImageNode({
    src: src || '',
    altText: altText || '',
    width: widthPx ? parseInt(widthPx, 10) : undefined,
    height: heightPx ? parseInt(heightPx, 10) : undefined,
    attachment: attachment as Attachment,
  });

  return { node };
};
type Func = () => void;

/**
 * Returns a function that will execute all functions passed when called. It is generally used
 * to register multiple lexical listeners and then tear them down with a single function call, such
 * as React's useEffect hook.
 * @example
 * ```ts
 * useEffect(() => {
 *   return mergeRegister(
 *     editor.registerCommand(...registerCommand1 logic),
 *     editor.registerCommand(...registerCommand2 logic),
 *     editor.registerCommand(...registerCommand3 logic)
 *   )
 * }, [editor])
 * ```
 * In this case, useEffect is returning the function returned by mergeRegister as a cleanup
 * function to be executed after either the useEffect runs again (due to one of its dependencies
 * updating) or the component it resides in unmounts.
 * Note the functions don't neccesarily need to be in an array as all arguments
 * are considered to be the func argument and spread from there.
 * The order of cleanup is the reverse of the argument order. Generally it is
 * expected that the first "acquire" will be "released" last (LIFO order),
 * because a later step may have some dependency on an earlier one.
 * @param func - An array of cleanup functions meant to be executed by the returned function.
 * @returns the function which executes all the passed cleanup functions.
 */
export function mergeRegister(...func: Array<Func>): () => void {
  return () => {
    // for (let i = func.length - 1; i >= 0; i--) {
    //   func[i]();
    // }
    func.forEach((f) => f());
    // Clean up the references and make future calls a no-op
    // eslint-disable-next-line no-param-reassign
    func.length = 0;
  };
}

/**
 * Wraps the node into another node created from a createElementNode function, eg. $createParagraphNode
 * @param node - Node to be wrapped.
 * @param createElementNode - Creates a new lexical element to wrap the to-be-wrapped node and returns it.
 * @returns A new lexical element with the previous node appended within (as a child, including its children).
 */
export function $wrapNodeInElement(
  node: LexicalNode,
  createElementNode: () => ElementNode
): ElementNode {
  const elementNode = createElementNode();
  node.replace(elementNode);
  elementNode.append(node);
  return elementNode;
}

export const CAN_USE_DOM: boolean =
  typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined';

export type InsertImagePayload = Readonly<ImagePayload>;

export const getDOMSelection = (
  targetWindow: Window | null
): Selection | null =>
  CAN_USE_DOM ? (targetWindow || window).getSelection() : null;

export const INSERT_IMAGE_COMMAND: LexicalCommand<InsertImagePayload> =
  createCommand('INSERT_IMAGE_COMMAND');
/**
 * Enum for type of key combinations that can be watched for in the Rich Text Editor.
 */
export enum KeyCombo {
  SHIFT_ENTER = 'ShiftEnter',
}
