import { CHANNELS_QUERY } from 'client-lib';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useApolloClient, useLazyQuery } from '@apollo/client';

const useCacheCommonQueries = () => {
  const client = useApolloClient();
  const currentUserAccountId = useSelector(
    (state) => state.session.currentUser?.accountId
  );

  const [loadChannels] = useLazyQuery(CHANNELS_QUERY, {
    client,
    variables: {
      accountId: currentUserAccountId,
    },
  });

  useEffect(() => {
    if (currentUserAccountId) {
      loadChannels();
    }
  }, [currentUserAccountId]);
};

export default useCacheCommonQueries;
