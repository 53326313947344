import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import SingleGroupDashboard from './SingleGroupDashboard';
import WidgetsContainer from './WidgetsContainer';

const Wrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 24px 32px 24px;
`;

const Dashboard = () => {
  const dashboardsActiveGroupIds = useSelector(
    (state) => state.session?.dashboardsActiveGroupIds
  );

  return (
    <Wrapper>
      {dashboardsActiveGroupIds.length === 1 ? (
        <SingleGroupDashboard groupIds={dashboardsActiveGroupIds} />
      ) : (
        <WidgetsContainer groupIds={dashboardsActiveGroupIds} />
      )}
    </Wrapper>
  );
};

export default Dashboard;
