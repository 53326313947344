import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import i18n from 'i18n-js';
import { Heading2 } from './elements';
import ExternalScreenTemplate from './containers/Login/ExternalScreenTemplate';
import useHideSidebarForRoute from './utils/hooks/useHideSidebarForRoute';
import { setActiveSidebar } from './actions/general';

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 70%;
  flex-wrap: wrap;
`;
const SectionWrapper = styled.div`
  margin-bottom: 2em;
  text-align: center;
`;

const ErrorMessagePage = () => {
  const { search } = location;
  const params = new URLSearchParams(search);
  const errorMsg = params.get('msg');
  const location = useLocation();
  const dispatch = useDispatch();
  const dispatchActiveSidebar = (action) => dispatch(setActiveSidebar(action));
  useHideSidebarForRoute({ dispatchActiveSidebar });

  const formattedErrorMsg = errorMsg
    ? i18n.t('signin-ErrorMessagePage-errorColon', { errorMsg })
    : i18n.t('signin-ErrorMessagePage-errorSomethingWentWrong');

  return (
    <ThemeProvider theme={{ mode: 'light' }}>
      <ExternalScreenTemplate screenType="maintenance">
        <ErrorWrapper>
          <SectionWrapper>
            <Heading2 dataTestId="signin-ErrorMessagePage-header">
              {formattedErrorMsg}
            </Heading2>
          </SectionWrapper>
        </ErrorWrapper>
      </ExternalScreenTemplate>
    </ThemeProvider>
  );
};

export default ErrorMessagePage;
