import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CONTACTS_QUERY, usePaginated } from 'client-lib';
import { useApolloClient } from '@apollo/client';
import {
  contactName,
  parseCorrectAccountInfo,
} from 'client-lib/src/lib/utils/helpers';
import { EntityCard } from '../../../../elements';
import InfiniteScroll from '../../../InfiniteScroll/InfiniteScroll';
import THEMES from '../../../../styles/themes/app';
import { TABS } from './ListAudienceFlyout';
import ListAudienceContactStatus from './ListAudienceContactStatus';

const InfiniteScrollContainer = styled.div`
  height: 100%;
  width: 438px;
  max-width: 438px;
`;

const ListFlyoutContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 438px;
  max-width: 438px;
  flex-wrap: nowrap;
  border-bottom: 1px solid ${THEMES.BORDER_COLOR};
`;

const customTextStyle = () => `
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const customContainerStyle = () => `
  border-bottom: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const EntityCardContainer = styled.div`
  display: flex;
  align-content: stretch;
  width: 64%;
`;

const ListAudienceContactList = ({ listFlyout, search, flyoutTab }) => {
  const client = useApolloClient();
  const listId = listFlyout?.id;

  const { contacts, handleFetchMore, pageInfo } = usePaginated({
    client,
    filter: search,
    key: 'contacts',
    nextPolicy: 'cache-first',
    policy: 'network-only',
    queryVariables: {
      announcementsOptOut: flyoutTab === TABS.OPTED_OUT,
      promotionalBroadcastOptOut: flyoutTab === TABS.OPTED_OUT,
      withTagIds: [listId],
      inGroupsWithTagIds: [listId],
      relayStylePagination: true,
    },
    query: CONTACTS_QUERY,
    resultsNumber: 30,
  });

  return (
    <InfiniteScrollContainer>
      <InfiniteScroll
        dataTestId="broadcast-list-flyout"
        bidirectionalScroll={false}
        height="100%"
        scrollableList={contacts}
        renderRow={({ list, index }) => {
          const contact = list[index];
          return contact?.__typename === 'CustomerContact' ? (
            <ListFlyoutContactContainer>
              <EntityCardContainer>
                <EntityCard
                  avatarChildren={contactName(contact)}
                  maintext={contactName(contact)}
                  subtext={parseCorrectAccountInfo(contact)}
                  customSubtextStyle={customTextStyle}
                  customMaintextStyle={customTextStyle}
                  customContainerStyle={customContainerStyle}
                />
              </EntityCardContainer>
              <ListAudienceContactStatus {...contact} />
            </ListFlyoutContactContainer>
          ) : null;
        }}
        listItemHeight={77}
        loadingHeight={77}
        hasNextPage={pageInfo?.hasNextPage}
        loadMoreRows={handleFetchMore}
        loading={false}
      />
    </InfiniteScrollContainer>
  );
};

ListAudienceContactList.propTypes = {
  flyoutTab: PropTypes.string.isRequired,
  listFlyout: PropTypes.object,
  search: PropTypes.string,
};

ListAudienceContactList.defaultProps = {
  listFlyout: {},
  search: '',
};

export default ListAudienceContactList;
