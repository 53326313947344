import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  getLocalValue,
  removeLocalValue,
  saveLocalValue,
} from '../../localStorage';

const REDIRECT_AFTER_LOGIN_URI = 'redirectAfterLoginUri';

/**
 * Helper function to allow storing the intended URI after login.
 * Records the target URI for after login.
 * Only performs the action when the user is _not_ logged in and the target URI
 *   includes '/threads/'.
 * @param {object} currentUser current user object from login or local storage
 * @param {string} targetUri target URI to redirect to after login
 */
export const maybeRecordTargetUriForAfterLogin = (currentUser, targetUri) => {
  // When currentUser is null, the user is not logged in.
  // When the target URI includes '/threads/', the user is trying to
  //  access threads, but still needs to auth.  Record the target URI
  //  and redirect after login.
  if (!currentUser && targetUri.includes('/threads/')) {
    // Store value in local storage to support SSO login
    // which needs to preform a full redirect to the SSO provider.
    // Keeping for 5 min to allow for the redirect to happen.
    saveLocalValue(REDIRECT_AFTER_LOGIN_URI, targetUri, 60 * 5);
  }
};

/**
 * Hook to direct the user to the intended URI after login.
 * Checks for existance of the redirectAfterLoginUri in the application state.
 * If present, send the user to that URI after login.
 * Only processes after the application has fully loaded.
 */
const useDirectUserToIntendedUriAfterLogin = () => {
  const history = useHistory();

  const applicationLoaded = useSelector(
    (state) => state.applicationState?.applicationLoaded
  );

  useEffect(() => {
    const redirectAfterLoginUri = getLocalValue(REDIRECT_AFTER_LOGIN_URI);
    if (!redirectAfterLoginUri || !applicationLoaded) return;
    // Clear the redirectAfterLoginUri so as not to always redirect
    removeLocalValue(REDIRECT_AFTER_LOGIN_URI);
    history.push(redirectAfterLoginUri);
  }, [applicationLoaded]);
};

export default useDirectUserToIntendedUriAfterLogin;
