import theme from 'styled-theming';
import COLORS from '../../colors';

const BORDER = theme('mode', {
  classic: COLORS.PK_CLASSIC.SECONDARY_GRAY,
  light: COLORS.PK.GREY_20,
  dark: COLORS.PK_DARK.GREY_4,
  holiday: COLORS.PK.GREY_20,
});

const SWITCH = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.WHITE,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_HIGH,
  holiday: COLORS.PK.WHITE,
});

const BACKGROUND_CHECKED = theme('mode', {
  classic: COLORS.PK_CLASSIC.SWITCH_GREEN,
  light: COLORS.PK.GREEN_80,
  dark: COLORS.PK.GREEN_80,
  holiday: COLORS.PK.GREEN_80,
});

const BACKGROUND_UNCHECKED = theme('mode', {
  classic: COLORS.PK_CLASSIC.SECONDARY_GRAY,
  light: COLORS.PK.GREY_20,
  dark: COLORS.PK_DARK.GREY_3,
  holiday: COLORS.PK.GREY_20,
});

const DISABLED_SWITCH = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_GRAY,
  light: COLORS.PK.GREY_60,
  dark: COLORS.PK_DARK.GREY_2,
  holiday: COLORS.PK.GREY_60,
});

const THEMES = {
  BORDER,
  SWITCH,
  BACKGROUND_CHECKED,
  BACKGROUND_UNCHECKED,
  DISABLED_SWITCH,
};

export default THEMES;
