import { useMemo } from 'react';

/*
DOCUMENTATION

useComponentWillMount is designed to be used in the rare cases where we need to leverage
the old API of ComponentWillMount(). The hook uses useMemo, which returns a memoized value (cached result)
from the passed in func and runs during rendering. According to the offical React documentation,
useMemo should be used as performance optimization and not a semantic guarentee. Use this hook sparingly.

*/

const useComponentWillMount = (func) => {
  useMemo(func, []);
};

export default useComponentWillMount;
