import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useApolloClient } from '@apollo/client';
import autosize from 'autosize';
import type {
  TaskForm,
  TaskFormValues,
} from 'client-lib/src/lib/controllers/hooks/tasks/types';
import useUpsertTask from 'client-lib/src/lib/controllers/hooks/tasks/useUpsertTask';
import REQUIRED_FIELDS from 'client-lib/src/lib/controllers/hooks/tasks/utils/constants';
import type {
  UiLabel,
  TemplateForm,
} from 'client-lib/src/lib/utils/helpers/types';
import i18n from 'i18n-js';
import styled from 'styled-components';

import { clearCreateSection } from '../../actions/createSection';
import TemplateAsyncSelect from '../AsyncSelects/TemplateAsyncSelect';
import {
  closeCreateSectionModal,
  openSnackbar,
  setActiveLoseProgressState,
  setActiveSidebar,
} from '../../actions/general';
import {
  Select,
  Button,
  SelectDate,
  TextArea,
  TextInput,
  Span,
} from '../../elements';
import InputLabel from '../../elements/inputCommonElements/InputLabel';
import type { CustomerContact, AppState } from '../../utils/helpers/types';
import GroupAsyncSelect from '../AsyncSelects/GroupAsyncSelect';
import UserAsyncSelect from '../AsyncSelects/UserAsyncSelect';
import SectionFormCard from '../CreateSection/SectionFormCard/SectionFormCard';
import PageCompose from '../CreateSection/Templates/PageCompose';
import UnsavedChanges from '../Modals/UnsavedChanges';
import {
  templateTokenLabelStyle,
  TOKENS,
} from '../Settings/Templates/TemplateForm';
import TokenButton from '../Settings/Templates/TokenButton';
import ContactInput from './ContactInput';
import FONTSIZE_THEMES from '../../styles/themes/fontSize/fontSize';

const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 960px;
  padding-top: 10px;
  gap: 24px;
`;

const FooterContentContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const InputPart = styled.div`
  display: flex;
`;

const InputGroup = styled(InputPart)<{ isBottomRow?: boolean }>`
  flex-direction: column;
  ${({ isBottomRow }) => !isBottomRow && 'margin-bottom: 8px;'}
`;

const ButtonRow = styled(InputPart)`
  display: flex;
  margin-top: 4px;

  * {
    box-sizing: content-box;
  }
`;

const ParentInputError = styled.div`
  .element-input-error-text-container {
    box-sizing: content-box;
  }
`;

interface UpsertTaskProps {
  initialFormValues?: TaskFormValues | null;
  taskRecipientFromApi?: CustomerContact | null;
  templateMessageFromApi?: string | null;
}

const UpsertTask = ({
  initialFormValues = null,
  taskRecipientFromApi = null,
  templateMessageFromApi = null,
}: UpsertTaskProps): JSX.Element => {
  const dispatch = useDispatch();
  // TODO: (Tasks) Try to resolve this without using any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const client = useApolloClient() as any;

  const history = useHistory();
  const accountName = useSelector((state: AppState) => {
    return state?.accountData?.account?.name;
  });
  const [taskRecipient, setTaskRecipient] = useState<CustomerContact | null>(
    taskRecipientFromApi
  );
  const [templateMessage, setTemplateMessage] = useState<string | null>(
    templateMessageFromApi
  );

  const emailFeatureAvailable = useSelector(
    (state: AppState) => state?.accountData?.account?.ff_email
  );

  const [templateModalOpen, setTemplateModalOpen] = useState(false);
  const [nameTokenBubbleOpen, setNameTokenBubbleOpen] = useState(false);
  const [descriptionTokenBubbleOpen, setDescriptionTokenBubbleOpen] =
    useState(false);
  const [taskIsSubmitting, setTaskIsSubmitting] = useState(false);

  const nameInput = useRef<HTMLInputElement>(null);
  const descriptionInput = useRef<HTMLInputElement>(null);

  const closeModal = () => {
    dispatch(setActiveLoseProgressState(false));
    dispatch(closeCreateSectionModal());
    dispatch(clearCreateSection());
    dispatch(setActiveSidebar('default'));
    history.push('/tasks');
  };

  const { updateField, fields, onSubmit, isVisitedForm } = useUpsertTask({
    client,
    initialFormValues,
    translatedRequiredMessage: i18n.t('slideouts-CreateCustomerForm-required'),
    i18n,
    handleOnSuccess: () => {
      setTaskIsSubmitting(false);
      dispatch(
        openSnackbar(
          initialFormValues
            ? i18n.t('tasks-update-success')
            : i18n.t('tasks-create-success'),
          'success'
        )
      );
      closeModal();
    },
    handleOnError: () => {
      setTaskIsSubmitting(false);
      dispatch(
        openSnackbar(i18n.t('settings-ProfileFormContainer-error'), 'error')
      );
    },
    setIsSubmitting: () => {
      setTaskIsSubmitting(true);
    },
  });

  useEffect(() => {
    if (descriptionInput?.current) {
      autosize(descriptionInput.current);
    }
  }, []);

  useEffect(() => {
    if (descriptionInput?.current) {
      autosize.update(descriptionInput.current);
    }
  }, [fields.description.value]);

  const handleNameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;

    updateField({ name: 'name', value, otherProps: {} });
  };

  const handleDateChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;

    updateField({ name: 'dueDate', value, otherProps: {} });
  };

  const handleDescriptionChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target;

    updateField({ name: 'description', value, otherProps: {} });
  };

  const handleGroupIdChange = (value: UiLabel) => {
    updateField({ name: 'group', value, otherProps: {} });
    updateField({
      name: 'assignee',
      value: {
        value: '',
        label: 'Unassigned',
      },
      otherProps: {},
    });
  };

  const handleContactChange = (value: CustomerContact | null) => {
    setTaskRecipient(value);
    updateField({ name: 'recipient', value, otherProps: {} });
  };

  const handleAssigneeChange = (value: UiLabel) => {
    updateField({ name: 'assignee', value, otherProps: {} });
  };

  const handleTemplateChange = (value: TemplateForm) => {
    setTemplateMessage(value.message);
    updateField({ name: 'template', value, otherProps: {} });
  };

  const handleChannelTypeChange = (ev: UiLabel) => {
    const { value } = ev;

    updateField({ name: 'channelType', value, otherProps: {} });
  };

  const handleTokenSelection = (
    name: string,
    input: HTMLInputElement | null,
    token: string,
    setBubbleOpen: (setTo: boolean) => void
  ) => {
    if (!input) {
      return;
    }

    const selectionStart = input.selectionStart || 0;
    const beforeSelection = input.value.slice(0, selectionStart);
    const afterSelection = input.value.slice(selectionStart);

    updateField({
      name,
      value: `${beforeSelection}${token} ${afterSelection}`,
      otherProps: {},
    });

    setBubbleOpen(false);
    setTimeout(() => input.focus(), 0);
  };

  const closeOrShowNotice = () => {
    if (isVisitedForm) {
      setTemplateModalOpen(true);

      return;
    }

    closeModal();
  };

  const setTokenBubbleOrToggle = (
    setFunction: React.Dispatch<React.SetStateAction<boolean>>,
    optionalState?: boolean
  ) => {
    if (optionalState) {
      setFunction(optionalState);

      return;
    }

    setFunction((prev) => !prev);
  };

  const setNameTokenBubble = (optionalState?: boolean) => {
    setTokenBubbleOrToggle(setNameTokenBubbleOpen, optionalState);
  };

  const setDescriptionTokenBubble = (optionalState?: boolean) => {
    setTokenBubbleOrToggle(setDescriptionTokenBubbleOpen, optionalState);
  };

  const userCanSave = useMemo(() => {
    if (!isVisitedForm) {
      return false;
    }

    const allRequiredFieldsFilled = REQUIRED_FIELDS.every((key) => {
      if (key === 'group') {
        return (fields as TaskForm)?.[key]?.value?.value;
      }

      return (fields as TaskForm)?.[key]?.value;
    });

    const anyFieldHasError =
      fields &&
      Object.values(fields as { error: string }[]).some(
        (field) => field.error !== ''
      );

    return allRequiredFieldsFilled && !taskIsSubmitting && !anyFieldHasError;
  }, [fields, isVisitedForm, taskIsSubmitting]);

  const populateTemplatePreview = () => {
    if (templateMessage)
      return (
        <Span
          customStyle={() =>
            `font-size: ${FONTSIZE_THEMES.METATEXT}; padding-top: 8px`
          }
        >
          <Span style={{ fontWeight: 600 }}>
            {i18n.t('tasks-create-message-template-preview')}{' '}
          </Span>
          {templateMessage
            .replaceAll('{FIRST_NAME}', taskRecipient?.firstName ?? ' ')
            .replaceAll('{LAST_NAME}', taskRecipient?.lastName ?? ' ')
            .replaceAll('{COMPANY_NAME}', taskRecipient?.account?.name ?? ' ')
            .replaceAll('{ACCOUNT_NAME}', accountName)
            .replaceAll('{GROUP_NAME}', fields?.group?.value?.label)
            .replaceAll('{AMOUNT_DUE}', ' ')
            .replaceAll('{INVOICE_NUMBER}', ' ')}
        </Span>
      );
    return null;
  };

  return (
    <PageCompose
      translationKey={
        !initialFormValues ? 'tasks-view-buttonCreate' : 'tasks-edit-header'
      }
      translationFallback={!initialFormValues ? 'Create New Task' : 'Edit Task'}
      footerContent={
        <FooterContentContainer>
          <Button
            dataTestId="upsert-task-cancel-button"
            type="tertiary"
            onClick={closeOrShowNotice}
          >
            {i18n.t('slideouts-EditSaveCancel-cancel')}
          </Button>
          <Button
            dataTestId="upsert-task-save-button"
            disabled={!userCanSave}
            onClick={onSubmit}
          >
            {!initialFormValues
              ? i18n.t('tasks-create-buttonCreate')
              : i18n.t('modals-DocumentForm-submit')}
          </Button>
        </FooterContentContainer>
      }
      isFixed
    >
      <ContentContainer>
        <SectionFormCard
          avatarIconKey="task"
          headerKey="tasks-create-sectionDetailsHeader"
          subHeaderKey="tasks-create-sectionDetailsSubHeader"
          headerKeyFallback="Task Details"
          subHeaderKeyFallback="Provide the essential details about the task, including a name, description, and due date. This will help guide the team member completing the task."
        >
          <InputGroup>
            <TextInput
              dataTestId="upsert-task-name-input"
              label={i18n.t('tasks-general-name')}
              placeholder={i18n.t('tasks-create-formName')}
              error={fields.name.error}
              value={fields.name.value}
              onChange={handleNameChange}
              ref={nameInput}
              hideBottomSpace
              isRequired
              {...(fields.name.error && { title: fields.name.error })}
            />
            <ButtonRow>
              <TokenButton
                labelStyle={templateTokenLabelStyle}
                optionBubbleOpen={nameTokenBubbleOpen}
                tokens={TOKENS}
                handleTokenSelection={(token) =>
                  handleTokenSelection(
                    'name',
                    nameInput.current,
                    token,
                    setNameTokenBubble
                  )
                }
                setOptionBubbleOrToggle={setNameTokenBubble}
                fixedPosition
              />
            </ButtonRow>
          </InputGroup>
          <InputGroup>
            <ParentInputError>
              <SelectDate
                dataTestId="upsert-task-date-input"
                label={i18n.t('tasks-general-date')}
                placeholder={i18n.t('tasks-create-formDate')}
                error={fields.dueDate.error}
                value={fields.dueDate.value}
                onChange={handleDateChange}
                calendarProps={{ maxDate: null, minDate: new Date() }}
                max="9999-12-31"
                allowPlaceholder
                hideBottomSpace
                isRequired
              />
            </ParentInputError>
          </InputGroup>
          <InputGroup>
            <TextArea
              dataTestId="upsert-task-description-input"
              label={i18n.t('tasks-general-description')}
              placeholder={i18n.t('tasks-create-formDescription')}
              error={fields.description.error}
              value={fields.description.value}
              onChange={handleDescriptionChange}
              id="upsert-task-description"
              name="upsert-task-description"
              rows={3}
              ref={descriptionInput}
              customTextAreaStyle={() => `
                max-height: calc(100vh - 80px);
              `}
              hideBottomSpace
            />
            <ButtonRow>
              <TokenButton
                labelStyle={templateTokenLabelStyle}
                optionBubbleOpen={descriptionTokenBubbleOpen}
                tokens={TOKENS}
                handleTokenSelection={(token) =>
                  handleTokenSelection(
                    'description',
                    descriptionInput.current,
                    token,
                    setDescriptionTokenBubble
                  )
                }
                setOptionBubbleOrToggle={setDescriptionTokenBubble}
              />
            </ButtonRow>
          </InputGroup>
          <InputGroup isBottomRow>
            <InputPart>
              <InputLabel error={fields.group.error} isRequired>
                {i18n.t('tasks-general-associatedGroup')}
              </InputLabel>
            </InputPart>
            <GroupAsyncSelect
              value={fields?.group?.value}
              setValue={handleGroupIdChange}
              error={fields.group.error}
              placeholder={i18n.t('tasks-general-associatedGroup-placeholder')}
              queryVars={{ taskEnabled: true }}
              noComponents={undefined}
              omitIds={[]}
              omitAll
              hideBottomSpace
            />
          </InputGroup>
        </SectionFormCard>
        <SectionFormCard
          avatarIconKey="contact"
          headerKey="tasks-create-recipient"
          subHeaderKey="tasks-create-recipient-details"
          headerKeyFallback="Recipient"
          subHeaderKeyFallback="Select or enter the recipient who will be the subject of this outreach task."
        >
          <InputGroup isBottomRow>
            <ContactInput
              contact={fields.recipient.value}
              error={fields.recipient.error}
              setContact={handleContactChange}
              translatedLabel={i18n.t('slideouts-CreateThread-toLabel')}
              hideBottomSpace
              isRequired
            />
          </InputGroup>
        </SectionFormCard>
        {fields?.group?.value && (
          <>
            <SectionFormCard
              avatarIconKey="user"
              headerKey="tasks-create-assignTo"
              subHeaderKey="tasks-create-assignTo-description"
            >
              <InputGroup isBottomRow>
                <InputPart>
                  <InputLabel error={fields.group.error}>
                    {i18n.t('tasks-create-assignTo')}
                  </InputLabel>
                </InputPart>
                <UserAsyncSelect
                  value={fields.assignee.value}
                  setValue={handleAssigneeChange}
                  isClearable={fields.assignee.value.value !== ''}
                  error={fields.assignee.error}
                  onClear={() =>
                    updateField({
                      name: 'assignee',
                      value: { value: '', label: 'Unassigned' },
                      otherProps: {},
                    })
                  }
                  queryVars={{ groupIds: [fields.group.value.value] }}
                  placeholder={i18n.t('tasks-create-assignTo-placeholder')}
                  hideBottomSpace
                />
              </InputGroup>
            </SectionFormCard>

            <SectionFormCard
              avatarIconKey="template"
              headerKey="tasks-create-template"
              subHeaderKey="tasks-create-template-description"
            >
              {emailFeatureAvailable && (
                <InputGroup>
                  <InputPart>
                    <InputLabel error={fields.group.error}>
                      {i18n.t('tasks-create-message-type')}
                    </InputLabel>
                  </InputPart>
                  <Select
                    options={[
                      {
                        value: 'SMS',
                        label: i18n.t('tasks-create-message-selection-sms'),
                      },
                      {
                        value: 'email',
                        label: i18n.t('tasks-create-message-selection-email'),
                      },
                    ]}
                    value={fields?.channelType?.value}
                    placeholder={i18n.t('settings-manageRules-select', {
                      defaultValue: 'Select',
                    })}
                    onChange={handleChannelTypeChange}
                  />
                </InputGroup>
              )}
              <InputGroup isBottomRow>
                <InputPart>
                  <InputLabel error={fields.group.error}>
                    {i18n.t('tasks-create-message-template-label')}
                  </InputLabel>
                </InputPart>
                <TemplateAsyncSelect
                  value={fields?.template?.value}
                  setValue={handleTemplateChange}
                  onClear={() => {
                    updateField({
                      name: 'template',
                      value: { value: '', label: '' },
                      otherProps: {},
                    });
                  }}
                  isClearable
                  placeholder={i18n.t('settings-manageRules-selectMessage', {
                    defaultValue: 'Select Message',
                  })}
                  queryVars={{
                    groupIds: [fields.group.value.value],
                    channelTypeFilter: [
                      fields.channelType.value === 'email' ? 'EMAIL' : 'SMS',
                    ],
                  }}
                  error={fields.template.error}
                  hideBottomSpace
                />
              </InputGroup>
              {populateTemplatePreview()}
            </SectionFormCard>
          </>
        )}
      </ContentContainer>
      <UnsavedChanges
        isOpen={templateModalOpen}
        handleClose={closeModal}
        handleCancel={() => {
          setTemplateModalOpen(false);
        }}
      />
    </PageCompose>
  );
};

export default UpsertTask;
