import { useQuery } from "@apollo/client";
import { ACCOUNT_TRANSACTIONAL_FEEDBACK_CONFIG } from "../../../index";

const useGetFeedbackStatus = ({ client, accountId }) =>
  useQuery(ACCOUNT_TRANSACTIONAL_FEEDBACK_CONFIG, {
    client,
    variables: { accountId },
  });

export default useGetFeedbackStatus;
