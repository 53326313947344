import { useQuery } from "@apollo/client";
import useHasEmailChannel from "./useHasEmailChannel";
import ACCOUNT_QUERY from "../../../graphql/query/AccountQuery.graphql";

function useIsEmailAvailable({ currentUser, threadsActiveGroupIds, client }) {
  const { hasEmailChannel } = useHasEmailChannel({
    currentUser,
    threadsActiveGroupIds,
    client,
  });

  const { data } = useQuery(ACCOUNT_QUERY, {
    client,
    variables: {
      id: currentUser?.accountId,
    },
  });

  const emailFeatureAvailable = !!(hasEmailChannel && data?.account.ff_email);
  return { emailFeatureAvailable };
}

export default useIsEmailAvailable;
