import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import PublicRoute from './PublicRoute';
import SignIn from './SignIn';
import Diagnostics from './Diagnostics';
import ResetPassword from './ResetPassword';
import ForgotPassword from './ForgotPassword';
import JoinTeam from './JoinTeam';
// import { media } from '../../components/Common/style-utils';
import RouteFilter from '../../components/Common/RouteFilter';
import { setActiveSidebar } from '../../actions/general';
import useHideSidebarForRoute from '../../utils/hooks/useHideSidebarForRoute';

import ExternalScreenTemplate from './ExternalScreenTemplate';

const Login = () => {
  const history = useHistory();
  const { pathname } = history.location;

  const dispatch = useDispatch();
  useHideSidebarForRoute({
    dispatchActiveSidebar: (string) => dispatch(setActiveSidebar(string)),
  });

  return (
    <ExternalScreenTemplate
      screenType="default"
      waitForWhiteLabelQuery={pathname === '/login'}
    >
      <RouteFilter path="/login">
        <SignIn />
      </RouteFilter>
      <RouteFilter path="/diagnostics">
        <Diagnostics />
      </RouteFilter>
      <RouteFilter path="/forgot">
        <ForgotPassword />
      </RouteFilter>
      <PublicRoute exact path="/reset/:token">
        {(props) => <ResetPassword {...props} />}
      </PublicRoute>
      <PublicRoute exact path="/join/:token" allowAuthenticatedUsers>
        {(props) => <JoinTeam {...props} />}
      </PublicRoute>
    </ExternalScreenTemplate>
  );
};

export default Login;
