import React, { useEffect, useState } from 'react';
import i18n from 'i18n-js';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from '../../../../../elements';

const SaveAndExit = (props) => {
  const { closeWizard, onClose, sendStartedAt } = props;
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState(location.pathname);

  useEffect(() => {
    if (location?.pathname && location?.pathname !== currentLocation) {
      setCurrentLocation(location.pathname);
      if (onClose) onClose();
      if (closeWizard) closeWizard('saved');
    }
  }, [location, onClose, closeWizard, currentLocation]);

  return (
    <Button
      onClick={() => {
        if (onClose) onClose();
        closeWizard('saved');
      }}
    >
      {sendStartedAt
        ? i18n.t('slideouts-GroupMessageRecipients-exitReview')
        : i18n.t('slideouts-GroupMessageRecipients-saveExit')}
    </Button>
  );
};

export default SaveAndExit;

SaveAndExit.propTypes = {
  closeWizard: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  sendStartedAt: PropTypes.bool,
};

SaveAndExit.defaultProps = {
  onClose: null,
  sendStartedAt: false,
};
