import React from 'react';
import PropTypes from 'prop-types';
import { SquareBadge } from '../../../../elements';

export const CutoffBadge = ({ displayNumber }) => {
  return (
    <div>
      <SquareBadge>{`+${displayNumber}`}</SquareBadge>
    </div>
  );
};

CutoffBadge.propTypes = {
  displayNumber: PropTypes.number.isRequired,
};
