import React from 'react';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Switch, InputLabel } from '../../../../../elements';

const BottomBorder = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 12px;
`;
const SecondaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 12px;
`;
const SaveAndContinue = ({
  buttonLabels,
  buttonActions,
  buttonDisabled,
  buttonValues,
}) => {
  return (
    <BottomBorder>
      {buttonLabels?.secondary && (
        <SecondaryContainer>
          <InputLabel contrast="colorHigh">
            {i18n.t('slideouts-GroupMessageRecipients-targetAllContacts')}
          </InputLabel>
          <Switch
            onCheck={buttonActions?.secondary}
            checked={buttonValues?.secondary}
            disabled={buttonDisabled?.secondary}
          />
        </SecondaryContainer>
      )}
      {buttonLabels?.primary && (
        <Button
          onClick={buttonActions?.primary}
          dataTestId="gmt-continue-button"
          disabled={buttonDisabled?.primary}
        >
          {buttonLabels.primary}
        </Button>
      )}
    </BottomBorder>
  );
};

export default SaveAndContinue;

SaveAndContinue.propTypes = {
  buttonActions: PropTypes.shape({
    primary: PropTypes.func,
    secondary: PropTypes.func,
  }),
  buttonLabels: PropTypes.shape({
    primary: PropTypes.string,
    secondary: PropTypes.string,
  }),
  buttonProps: PropTypes.shape({
    primary: PropTypes.object,
    secondary: PropTypes.string,
  }),
  buttonDisabled: PropTypes.shape({
    primary: PropTypes.bool,
    secondary: PropTypes.string,
  }),
  buttonValues: PropTypes.shape({
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
  }),
};

SaveAndContinue.defaultProps = {
  buttonLabels: null,
  buttonActions: null,
  buttonProps: null,
  buttonDisabled: null,
  buttonValues: null,
};
