import React from 'react';
import PropTypes from 'prop-types';
import {
  h1Style,
  h2Style,
  h3Style,
  h4Style,
  h5Style,
  pStyle,
  emphasisStyle,
  metaStyle,
} from '../Text/Text';
import Button from './Button';

const inlineTextStyleMatch = (textType) => {
  switch (textType) {
    case 'Heading1':
      return h1Style;

    case 'Heading2':
      return h2Style;

    case 'Heading3':
      return h3Style;

    case 'Heading4':
      return h4Style;

    case 'Heading5':
      return h5Style;

    case 'Text':
      return pStyle;

    case 'EmphasisText':
      return emphasisStyle;

    case 'MetaText':
      return metaStyle;

    default:
      return pStyle;
  }
};

const inlineTextCustomStyle = (props) => `
  text-decoration: none !important;
  padding: 0;
  margin 0;
  height: auto !important;
  line-height: normal !important;

  span {
    height: auto !important;
    line-height: normal !important;
  }

  ${inlineTextStyleMatch(props.textType)(props)}
`;

const InlineTextButton = ({ customStyle, dataTestId, ...rest }) => {
  return (
    <Button
      role="button"
      {...rest}
      dataTestId={dataTestId}
      type="link"
      customStyle={(props) => `
        ${inlineTextCustomStyle(props)}
        ${customStyle?.(props)}
      `}
    />
  );
};

InlineTextButton.propTypes = {
  textType: PropTypes.string,
  customStyle: PropTypes.func,
  dataTestId: PropTypes.string,
};

InlineTextButton.defaultProps = {
  textType: 'Text',
  customStyle: null,
  dataTestId: '',
};

export default InlineTextButton;
