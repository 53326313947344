import { type RangeSelection } from 'lexical';
import { $isAtNodeEnd } from '@lexical/selection';

/**
 * Gets the selected node from a RangeSelection.
 *
 * @param {RangeSelection} selection - The range selection from which to get the selected node.
 * @returns {Node} The selected node.
 */
const getSelectedNode = (selection: RangeSelection) => {
  const { anchor } = selection;
  const { focus } = selection;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  }
  return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
};

export default getSelectedNode;
