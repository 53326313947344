import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import HorizontalBarWidget from './HorizontalBar/HorizontalBarWidget.tsx';
import COLORS from '../../styles/colors';

const sortedUsers = (users) => {
  return users?.sort((a, b) => {
    if (a.numOpenThreads < b.numOpenThreads) {
      return 1;
    }
    if (a.numOpenThreads > b.numOpenThreads) {
      return -1;
    }
    return 0;
  });
};

const OpenSingleGroupWidgetContainer = ({
  openTotal,
  openUsers,
  openUpperBound,
  setOpenConvoFlyoutOpen,
}) => {
  const openArrayToSort = openUsers && [...openUsers.counts];

  return (
    <HorizontalBarWidget
      barColor={COLORS.PK.GOLD_100}
      graphData={sortedUsers(openArrayToSort)}
      graphDataName="userName"
      title={i18n.t('threads-Dashboard-openConversations', {
        defaultValue: 'Open Conversations',
      })}
      total={openTotal}
      upperBound={openUpperBound}
      countKey="numOpenThreads"
      breakpointWidth="100%"
      btnText={i18n.t('search-LabelBar-viewAll')}
      btnOnClick={() => setOpenConvoFlyoutOpen(true)}
      btnDataTestId="open-view-all-btn-singleGroup"
    />
  );
};

OpenSingleGroupWidgetContainer.propTypes = {
  openTotal: PropTypes.number,
  openUsers: PropTypes.object,
  openUpperBound: PropTypes.number,
  setOpenConvoFlyoutOpen: PropTypes.func.isRequired,
};

OpenSingleGroupWidgetContainer.defaultProps = {
  openTotal: undefined,
  openUsers: null,
  openUpperBound: undefined,
};

export default OpenSingleGroupWidgetContainer;
