import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import {
  TableHead,
  Checkbox,
  Button,
  Avatar,
  useMultiSelectTableItems,
  Table,
} from '../../../../elements';
import {
  FlexCenterContainer,
  FlexBetweenContainer,
  EmptyListHeader,
  ContactDataWrapper,
  AvatarWrapper,
  StyledTableRow,
  StyledCheckboxTD,
} from '../sharedStyles';
import AudienceFilterSort from './subComponents/AudienceFilterSort';
import { getSortOptions } from '../utils.ts';
import InfiniteScroll from '../../../InfiniteScroll/InfiniteScroll';
import THEMES from '../../../../styles/themes/app';
import ChipLabels from '../../../ChipLabels/ChipLabels';

const LabelsTable = ({
  labels,
  loading,
  loadMoreRows,
  onAdd,
  onSort,
  pageInfo,
  sort,
  disableCheckboxes,
}) => {
  const sortOptions = getSortOptions(i18n);

  const { handleCheckItem, handleSelectNone, selectedItems, shouldCheckItem } =
    useMultiSelectTableItems({
      items: labels,
    });

  // Adds selected Ids to list and then resets selection
  const handleAdd = () => {
    onAdd({ labelIds: selectedItems });
    handleSelectNone();
  };

  /**
   * For accessibility, if the whole row is clickable for the select action,
   * then it must be tabbable and hitting the enter key should serve as a click
   * @param {*} e KeyBoard Event
   * @param {string} id Group ID
   */
  const handleKeyDown = (e, id) => {
    if (e.key === 'Enter') {
      handleCheckItem(id);
    }
  };

  return (
    <div>
      <FlexBetweenContainer>
        <AudienceFilterSort
          sortBy={sort}
          sortOptions={sortOptions}
          handleSubmitSort={onSort}
        />
        <Button
          onClick={() => handleAdd()}
          disabled={selectedItems.length === 0}
        >
          {i18n.t('broadcasts-broadcast-addSelected', {
            defaultValue: 'Add %{number} Selected',
            number: selectedItems.length,
          })}
        </Button>
      </FlexBetweenContainer>
      <Table>
        <TableHead>
          <tr>
            <th>
              {i18n.t('broadcasts-broadcastList-labelName', {
                defaultValue: 'Label Name',
              })}
            </th>
          </tr>
        </TableHead>
      </Table>
      <InfiniteScroll
        dataTestId="broadcast-recipient-list"
        bidirectionalScroll={false}
        height="calc(100vh - 354px)"
        scrollableList={labels}
        noRowsElement={
          <FlexCenterContainer>
            <EmptyListHeader dataTestId="create-broadcastList-empty">
              {i18n.t('slideouts-TargetedAudience-noResults', {
                defaultValue:
                  'No results were found for this type of audience.',
              })}
            </EmptyListHeader>
          </FlexCenterContainer>
        }
        renderRow={({ list, index }) => {
          const label = list[index];

          return (
            <StyledTableRow
              key={label.id}
              role="button"
              tabIndex={0}
              onClick={() => {
                if (disableCheckboxes) return;
                handleCheckItem(label.id);
              }}
              onKeyDown={(e) => {
                if (disableCheckboxes) return;
                handleKeyDown(e, label.id);
              }}
            >
              <StyledCheckboxTD>
                <Checkbox
                  checked={shouldCheckItem(label.id)}
                  dataTestId="create-broadcastList-checkboxGroup"
                  onCheck={() => {
                    /* Not needed - entire table row is clickable */
                  }}
                  disabled={disableCheckboxes}
                />
                <AvatarWrapper>
                  <Avatar size="md" icon="label" disabled={disableCheckboxes} />
                  <ContactDataWrapper>
                    <ChipLabels
                      options={{
                        value: [
                          { ...label, text: label.name, value: label.id },
                        ],
                      }}
                      size="sm"
                    />
                  </ContactDataWrapper>
                </AvatarWrapper>
              </StyledCheckboxTD>
            </StyledTableRow>
          );
        }}
        hasNextPage={pageInfo.hasNextPage}
        hasPreviousPage={pageInfo.hasPreviousPage}
        listItemHeight={64}
        loadingHeight={64}
        loadingBackgroundColor={THEMES.BACKGROUND_PRIMARY}
        loadingBorderBottom
        loadMoreRows={loadMoreRows}
        loading={loading}
      />
    </div>
  );
};

LabelsTable.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  loadMoreRows: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  pageInfo: PropTypes.shape({
    hasNextPage: PropTypes.bool,
    hasPreviousPage: PropTypes.bool,
    totalCount: PropTypes.number,
  }),
  sort: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  disableCheckboxes: PropTypes.bool,
};

LabelsTable.defaultProps = {
  disableCheckboxes: false,
  pageInfo: {
    hasNextPage: false,
    hasPreviousPage: false,
    totalCount: 0,
  },
};

export default LabelsTable;
