import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { EmphasisText, Heading1, TabList } from '../../elements';
import Dashboard from '../Dashboard';
import Feedback from '../Feedback/index.tsx';
import THEMES from '../../styles/themes/app';

const Container = styled.main`
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  color: ${THEMES.FOREGROUND_HIGH};
  overflow: auto;
  width: 100%;
`;

const Header = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 32px 24px 0 24px;
  max-width: ${(props) =>
    props.activeView === VIEW_TOGGLE_TYPES.DASHBOARD ? '1314px' : 'unset'};
`;

const TabListWrapper = styled.div`
  background-color: ${THEMES.BACKGROUND_QUATERNARY};
  border-radius: 4px;
  max-height: 40px;
  padding: 8px;
`;

const getHeadingStyles = () => `margin-top: 8px;`;

export const VIEW_TOGGLE_TYPES = {
  DASHBOARD: 'dashboard',
  FEEDBACK: 'feedback',
};

const getOptions = (activeView) => [
  {
    label: i18n.t('search-Search-threads', { defaultValue: 'Threads' }),
    value: VIEW_TOGGLE_TYPES.DASHBOARD,
    active: activeView === VIEW_TOGGLE_TYPES.DASHBOARD,
  },
  {
    label: i18n.t('settings-Support-feedback', { defaultValue: 'Feedback' }),
    value: VIEW_TOGGLE_TYPES.FEEDBACK,
    active: activeView === VIEW_TOGGLE_TYPES.FEEDBACK,
  },
];

const DashboardAndFeedback = ({
  activeView,
  dashboardPagePermission,
  feedbackPagePermission,
}) => {
  const history = useHistory();
  const [options, setOptions] = useState(getOptions(activeView));

  const currentUser = useSelector((state) => state.session.currentUser);
  let welcome = i18n.t('threads-Dashboard-welcome', {
    defaultValue: 'Welcome back',
  });
  if (currentUser?.firstName) {
    welcome = i18n.t('threads-Dashboard-welcomeName', {
      name: currentUser?.firstName,
      defaultValue: `Welcome back, ${currentUser?.firstName}`,
    });
  }

  const dateHeader = i18n.strftime(new Date(), '%A, %B %-d, %Y');

  const handleViewToggle = (option) => {
    setOptions(getOptions(option.value));
    history.push(`/${option.value}`);
  };

  return (
    <Container>
      <Header activeView={activeView}>
        <div>
          <EmphasisText dataTestId="date-header">{dateHeader}</EmphasisText>
          <Heading1 dataTestId="welcome-header" customStyle={getHeadingStyles}>
            {welcome}
          </Heading1>
        </div>
        {dashboardPagePermission && feedbackPagePermission && (
          <TabListWrapper>
            <TabList options={options} onClick={handleViewToggle} />
          </TabListWrapper>
        )}
      </Header>
      {activeView === VIEW_TOGGLE_TYPES.DASHBOARD && <Dashboard />}
      {activeView === VIEW_TOGGLE_TYPES.FEEDBACK && <Feedback />}
    </Container>
  );
};

DashboardAndFeedback.propTypes = {
  activeView: PropTypes.oneOf([
    VIEW_TOGGLE_TYPES.DASHBOARD,
    VIEW_TOGGLE_TYPES.FEEDBACK,
  ]).isRequired,
  dashboardPagePermission: PropTypes.bool,
  feedbackPagePermission: PropTypes.bool,
};

DashboardAndFeedback.defaultProps = {
  dashboardPagePermission: false,
  feedbackPagePermission: false,
};

export default DashboardAndFeedback;
