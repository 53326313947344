import { $generateHtmlFromNodes } from '@lexical/html';
import { $getRoot, type LexicalEditor } from 'lexical';

/**
 * Converts the content of a LexicalEditor instance to HTML and plain text.
 *
 * @param {LexicalEditor} editor - The LexicalEditor instance.
 * @returns {Object} An object containing the HTML and plain text representation of the editor content.
 * @returns {string} return.html - The HTML representation of the editor content.
 * @returns {string} return.text - The plain text representation of the editor content.
 */

const convertLexicalToHtmlAndText = (editor: LexicalEditor) => {
  let html = '';
  let text = '';

  editor.update(() => {
    html = $generateHtmlFromNodes(editor, null);
    text = $getRoot().getTextContent();
  });

  return {
    html,
    text,
  };
};

export default convertLexicalToHtmlAndText;
