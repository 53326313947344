import { useState } from "react";
import { useMutation } from "@apollo/client";
import DIAGNOSTIC_SIGN_IN_MUTATION from "../../../graphql/mutation/DiagnosticSignInMutation.graphql";
import useForm from "../../../utils/hooks/useForm";
import useAction from "../../../utils/hooks/useAction";

/*

DOCUMENTATION
  this hook was created to be used with DiagnosticSignIn. handles input values, validation, mutation, and any errors that may arise

  args:
    client: (obj) required. Apollo Client instance
    handleDiagnosticSignInResponse: (func) required. Callback function when mutation succeed

  returns:
    fields: (obj) fields object returned by the useForm hook.
    onSubmit: (func) callback function that will fire the sign in team mutation.
    updateField: (func) callback function returned by the useForm hook.
    isVisitedForm: (bool) boolean state returned by the useForm hook.
    signInError: (string) states general error
    handlePasswordToggle: (func) callback function to update password type
    passwordFieldType: (str) state, password type value. Two values => password/text

*/

const useDiagnosticSignIn = ({ handleDiagnosticSignInResponse, client }) => {
  const [diagnosticSignInMutation] = useMutation(DIAGNOSTIC_SIGN_IN_MUTATION, {
    client,
  });
  const [passwordFieldType, setPasswordFieldType] = useState("password");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formConfig = {
    username: {
      value: "",
      validations: [
        { type: "required", errorMessage: "Email address is required." },
        { type: "emailFormat", errorMessage: "Invalid email address." },
      ],
    },
    password: {
      value: "",
      validations: [{ type: "required", errorMessage: "Password required." }],
    },
  };

  const { error, setError } = useAction();

  const { fields, onSubmit, updateField, isVisitedForm } = useForm(
    formConfig,
    async (values) => {
      setIsSubmitting(true);
      await fetch("/cookie?type=domain", { method: "DELETE" });
      await fetch("/cookie?type=subdomain", { method: "DELETE" });
      const response = await diagnosticSignInMutation({
        variables: {
          input: {
            username: values.username.value,
            password: values.password.value,
          },
        },
      }).catch((err) => setError(err.message || "Login failed"));

      handleDiagnosticSignInResponse(response);
      setIsSubmitting(false);
    }
  );

  const handleOnKeyPress = (ev) => {
    if (ev.key === "Enter") {
      onSubmit();
    }
  };

  const handlePasswordToggle = () => {
    if (passwordFieldType === "password") {
      setPasswordFieldType("text");
    } else {
      setPasswordFieldType("password");
    }
  };

  return {
    fields,
    onSubmit,
    updateField,
    isVisitedForm,
    handlePasswordToggle,
    passwordFieldType,
    signInError: error,
    isSubmitting,
    handleOnKeyPress,
  };
};

export default useDiagnosticSignIn;
