import React from 'react';
import i18n from 'i18n-js';
import { useDispatch } from 'react-redux';
import useHideSidebarForRoute from '../../utils/hooks/useHideSidebarForRoute';
import { setActiveSidebar } from '../../actions/general';

const AzureSuccess = () => {
  const dispatch = useDispatch();
  const dispatchActiveSidebar = (input) => dispatch(setActiveSidebar(input));
  useHideSidebarForRoute({ dispatchActiveSidebar });
  return (
    <div style={{ margin: '2rem auto', textAlign: 'center' }}>
      <p>
        {i18n.t('settings-azure-success', {
          defaultValue: 'You have successfully connected Prokeep to Azure AD.',
        })}
      </p>
    </div>
  );
};

export default AzureSuccess;
