import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { IconButton, Text } from '../../elements';

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: ${(props) =>
    props.showPageCount
      ? props.totalPages > 0
        ? '-24px auto 0 auto'
        : '0'
      : '20px 0 0 0'};
`;

const getPageText = () => `
  font-weight: 400;
  margin-top: 8px;
`;

const PaginationWrapper = styled.div`
  justify-content: center;
  display: ${(props) => (props.showPageCount ? 'inline' : 'flex')};
  ${(props) => props.customStyles}
`;

const PaginationButtonsWrapper = ({
  customStyles,
  handleNext,
  handlePrevious,
  page,
  pageInfo,
  showPageCount,
  totalPages,
}) => (
  <PaginationWrapper customStyles={customStyles} showPageCount={showPageCount}>
    {showPageCount && totalPages > 0 && (
      <Text customStyle={getPageText}>
        {i18n.t('feedback-feedback-pagination', {
          page,
          totalPages,
          defaultValue: `Page ${page} of ${totalPages}`,
        })}
      </Text>
    )}
    <ButtonsWrapper
      data-testid="pagination-buttons-wrapper"
      showPageCount={showPageCount}
      totalPages={totalPages}
    >
      <IconButton
        size="lg"
        onClick={handlePrevious}
        disabled={!pageInfo.hasPreviousPage}
      >
        <i
          className="ri-arrow-left-s-line"
          data-testid="pagination-buttons-arrow"
        />
      </IconButton>
      <IconButton
        size="lg"
        onClick={handleNext}
        disabled={!pageInfo.hasNextPage}
      >
        <i
          className="ri-arrow-right-s-line"
          data-testid="pagination-buttons-arrow"
        />
      </IconButton>
    </ButtonsWrapper>
  </PaginationWrapper>
);

PaginationButtonsWrapper.propTypes = {
  customStyles: PropTypes.func,
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
  page: PropTypes.number,
  pageInfo: PropTypes.object.isRequired,
  showPageCount: PropTypes.bool,
  totalPages: PropTypes.number,
};

PaginationButtonsWrapper.defaultProps = {
  customStyles: () => {},
  page: 0,
  showPageCount: null,
  totalPages: 0,
};

export default PaginationButtonsWrapper;
