import theme from 'styled-theming';
import COLORS from '../../colors';

// used with background primary/secondary/tertiary
const FOREGROUND_HIGH = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_BLACK,
  light: COLORS.PK.GREY_90,
  dark: COLORS.PK.GREY_10,
  holiday: COLORS.PK.GREY_90,
});

const FOREGROUND_HIGH_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.TERTIARY_BLACK,
  light: COLORS.PK.GREY_70,
  dark: COLORS.PK.GREY_30,
  holiday: COLORS.PK.GREY_70,
});

const FOREGROUND_MED = theme('mode', {
  classic: COLORS.PK_CLASSIC.SECONDARY_BLACK,
  light: COLORS.PK.GREY_70,
  dark: COLORS.PK.GREY_30,
  holiday: COLORS.PK.GREY_70,
});

const FOREGROUND_MED_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.TERTIARY_BLACK,
  light: COLORS.PK.GREY_50,
  dark: COLORS.PK.GREY_50,
  holiday: COLORS.PK.GREY_50,
});

const FOREGROUND_LOW = theme('mode', {
  classic: COLORS.PK_CLASSIC.DARK_GRAY,
  light: COLORS.PK.GREY_50,
  dark: COLORS.PK.GREY_50,
  holiday: COLORS.PK.GREY_50,
});

const FOREGROUND_LOW_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.DARK_GRAY_HOVER,
  light: COLORS.PK.GREY_60,
  dark: COLORS.PK.GREY_40,
  holiday: COLORS.PK.GREY_60,
});

// use with background colored_primary/colored_secondary/colored_tertiary
const COLORED_FOREGROUND_HIGH = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.WHITE,
  dark: COLORS.PK.WHITE,
  holiday: COLORS.PK.WHITE,
});

const COLORED_FOREGROUND_HIGH_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.LIGHT_GRAY,
  light: COLORS.PK.GREY_30,
  dark: COLORS.PK.GREY_30,
  holiday: COLORS.PK.GREY_30,
});

const COLORED_FOREGROUND_MED = theme('mode', {
  classic: COLORS.PK_CLASSIC.LIGHT_GRAY,
  light: COLORS.PK.GOLD_20,
  dark: COLORS.PK.GOLD_20,
  holiday: COLORS.PK.GOLD_20,
});

const COLORED_FOREGROUND_MED_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.SECONDARY_GRAY,
  light: COLORS.PK.GOLD_40,
  dark: COLORS.PK.GOLD_40,
  holiday: COLORS.PK.GOLD_40,
});

const COLORED_FOREGROUND_LOW = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_GRAY,
  light: COLORS.PK.GOLD,
  dark: COLORS.PK.GOLD,
  holiday: COLORS.PK.GOLD,
});

const COLORED_FOREGROUND_LOW_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.SECONDARY_GRAY,
  light: COLORS.PK.GOLD_20,
  dark: COLORS.PK.GOLD_20,
  holiday: COLORS.PK.GOLD_20,
});

// used with background primary/secondary/tertiary, these are color variations to be used likely sparingly
const FOREGROUND_HIGH_COLOR = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_BLUE,
  light: COLORS.PK.BLUE,
  dark: COLORS.PK.BLUE_60,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_SECONDARY,
});

const FOREGROUND_HIGH_COLOR_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.LIGHT_BLUE,
  light: COLORS.PK.BLUE_80,
  dark: COLORS.PK.BLUE_40,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_TERTIARY,
});

const FOREGROUND_MED_COLOR = theme('mode', {
  classic: COLORS.PK_CLASSIC.LIGHT_BLUE,
  light: COLORS.PK.BLUE_60,
  dark: COLORS.PK.BLUE_40,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_TERTIARY,
});

const FOREGROUND_MED_COLOR_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.LIGHT_BLUE_HOVER,
  light: COLORS.PK.BLUE_40,
  dark: COLORS.PK.BLUE_60,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_SECONDARY,
});

// bg
const BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.TRANSPARENT,
  light: COLORS.PK.TRANSPARENT,
  dark: COLORS.PK.TRANSPARENT,
  holiday: COLORS.PK.TRANSPARENT,
});

// red icon button
const DESTRUCTIVE_BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_RED,
  light: COLORS.PK.RED_80,
  dark: COLORS.PK.RED_80,
  holiday: COLORS.PK.RED_80,
});
const DESTRUCTIVE_BACKGROUND_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_RED_HOVER,
  light: COLORS.PK.RED_100,
  dark: COLORS.PK.RED_100,
  holiday: COLORS.PK.RED_100,
});

const THEMES = {
  FOREGROUND_HIGH,
  FOREGROUND_HIGH_HOVER,
  FOREGROUND_MED,
  FOREGROUND_MED_HOVER,
  FOREGROUND_LOW,
  FOREGROUND_LOW_HOVER,

  COLORED_FOREGROUND_HIGH,
  COLORED_FOREGROUND_HIGH_HOVER,
  COLORED_FOREGROUND_MED,
  COLORED_FOREGROUND_MED_HOVER,
  COLORED_FOREGROUND_LOW,
  COLORED_FOREGROUND_LOW_HOVER,

  FOREGROUND_HIGH_COLOR,
  FOREGROUND_HIGH_COLOR_HOVER,
  FOREGROUND_MED_COLOR,
  FOREGROUND_MED_COLOR_HOVER,

  BACKGROUND,

  DESTRUCTIVE_BACKGROUND,
  DESTRUCTIVE_BACKGROUND_HOVER,
};

export default THEMES;
