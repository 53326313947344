import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18n-js';
import { useAnnouncementList, useEditList } from 'client-lib';
import { useApolloClient } from '@apollo/client';
import { useLocation, useHistory } from 'react-router-dom/cjs/react-router-dom';
import Wizard from '../index.tsx';
import { closeCreateSectionModal } from '../../../actions/general';
import CreateBroadcastListPage from './pages/CreateBroadcastListPage';
import AudienceBuilder from './pages/AudienceBuilder/AudienceBuilder';
import { onError, onSuccess } from '../shared/utils.ts';
import { MAX_LIST_LENGTH } from '../../../utils/helpers/constants/index.ts';

const GroupMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const createPages = (isNew = true) => ({
  CreateBroadcastListPage: {
    start: isNew,
    component: CreateBroadcastListPage,
  },
  NextPage: {
    start: !isNew,
    component: AudienceBuilder,
  },
});

const CreateBroadcastListWizard = () => {
  const listId = useSelector((state) => state.general?.listId);
  const [tagError, setTagError] = useState('');
  const [tagName, setTagName] = useState('');
  const [tagDescr, setTagDescr] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [tagId, setTagId] = useState(listId);
  const [search, setSearch] = useState('');
  const currentUser = useSelector((state) => state?.session?.currentUser);
  const dispatch = useDispatch();
  const client = useApolloClient();
  const location = useLocation();
  const history = useHistory();

  const [listGroups, setListGroups] = useState(null);

  useEffect(() => {
    if (!location.pathname.includes('/broadcasts/lists')) handleOnClose();
  }, [location]);

  // Creates List object
  const { handleCreateTag } = useAnnouncementList({
    client,
    accountId: currentUser.accountId,
    setError: (msg) => onError(dispatch, msg),
    setSuccess: (msg) => onSuccess(dispatch, msg),
    i18n,
  });

  // Edits List details and saves Audiences to List
  const { listData, handleEditTag, loading, handleOnSaveSelectedAudience } =
    useEditList({
      client,
      tagId,
      i18n,
      setError: (msg) => onError(dispatch, msg),
      setSuccess: (msg) => onSuccess(dispatch, msg),
    });

  useEffect(() => {
    if (tagId) {
      setTagName(listData?.name || '');
      setTagDescr(listData?.description || '');
    }
  }, [listData]);

  // Handles naming List
  const handleInputTagChange = (e) => {
    if (e.target.value === '') {
      setTagError(i18n.t('slideouts-GroupMessageOverview-listNameRequired'));
    } else if (e.target.value.length > MAX_LIST_LENGTH) {
      setTagError(
        i18n.t('broadcasts-maximum-characterError', {
          defaultValue: "You've exceeded the 255 maximum character limit.",
        })
      );
    } else {
      setTagError('');
    }
    setTagName(e.target.value);
  };

  const handleInputListDescriptionChange = (e) => {
    if (e.target.value?.length > 255) {
      setDescriptionError(
        i18n.t('broadcasts-maximum-characterError', {
          defaultValue: "You've exceeded the 255 maximum character limit.",
        })
      );
    } else {
      setDescriptionError('');
    }
    setTagDescr(e.target.value);
  };

  // Closes wizard
  const handleOnClose = () => {
    dispatch(closeCreateSectionModal());
    if (history.location.pathname.includes('edit')) history.goBack();
  };

  return (
    <GroupMessageContainer data-testid="create-broadcastList-wizard">
      <Wizard
        pages={createPages(!listId)}
        wizardGlobalProps={{
          handleCreateTag,
          handleInputTagChange,
          handleEditTag,
          handleOnSaveSelectedAudience,
          search,
          setListGroups,
          setSearch,
          handleInputListDescriptionChange,
          setTagId,
          setTagDescr,
          loading,
          isEdit: !!listId,
          setListError: setTagError,
          tag: {
            groups: listGroups,
            name: tagName,
            error: tagError,
            description: tagDescr,
            descriptionError,
          },
          tagId,
          title: i18n.t('slideouts-GroupMessageRecipients-createList'),
        }}
        closeWizard={handleOnClose}
      />
    </GroupMessageContainer>
  );
};

export default CreateBroadcastListWizard;
