import {
  AUTH_SIGNOUT,
  SET_ACTIVE_THREAD,
  SET_ACTIVE_UNCLAIMED_THREAD,
  SET_ACTIVE_CLOSED_THREAD,
  RESET_ACTIVE_THREAD,
  OPEN_GROUP_SELECT,
  CLOSE_GROUP_SELECT,
  SET_THREADS_FILTER,
  TOTAL_UNREAD_THREADS,
  TOGGLE_BLOCK_CHANNEL_PROMPT_THREAD,
  TOGGLE_BLOCK_CHANNEL_CONFIRM,
  SET_THREAD_COUNTS,
  SET_LATEST_CLOSED_THREAD,
} from '../actions';

const DEFAULT_STATE = {
  activeThreadId: '',
  activeUnclaimedThreadId: '',
  activeClosedThreadId: '',
  filter: 'inbox',
  activeThreadExternalContactId: '',
  groupSelectOpen: false,
  threadSelected: false,
  contactDetailsSelected: false,
  totalUnreadThreads: 0,
  showBlockChannelConfirmation: false,
  channelToBeBlocked: '',
  blockChannelConfirm: false,
  latestClosedThread: {},
  threadCounts: {
    unclaimedThreadCount: 0,
    unreadThreadCount: 0,
  },
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case AUTH_SIGNOUT:
      return DEFAULT_STATE;
    case SET_THREADS_FILTER:
      return {
        ...state,
        filter: action.filter,
      };
    case SET_ACTIVE_THREAD:
      return {
        ...state,
        activeThreadId: action.activeThreadId,
      };
    case SET_ACTIVE_UNCLAIMED_THREAD:
      return {
        ...state,
        activeUnclaimedThreadId: action.activeUnclaimedThreadId,
      };
    case SET_ACTIVE_CLOSED_THREAD:
      return {
        ...state,
        activeClosedThreadId: action.activeClosedThreadId,
      };
    case RESET_ACTIVE_THREAD:
      return {
        ...state,
        activeThreadId: action.activeThreadId,
        threadSelected: action.threadSelected,
      };
    case OPEN_GROUP_SELECT:
      return {
        ...state,
        groupSelectOpen: action.groupSelectOpen,
      };
    case CLOSE_GROUP_SELECT:
      return {
        ...state,
        groupSelectOpen: action.groupSelectOpen,
      };
    case TOTAL_UNREAD_THREADS:
      return {
        ...state,
        totalUnreadThreads: action.total,
      };
    case TOGGLE_BLOCK_CHANNEL_PROMPT_THREAD:
      return {
        ...state,
        showBlockChannelConfirmation: action.toggleValue,
        channelToBeBlocked: action.channelToBeBlocked,
      };
    case TOGGLE_BLOCK_CHANNEL_CONFIRM:
      return {
        ...state,
        blockChannelConfirm: action.blockChannelConfirm,
      };
    case SET_THREAD_COUNTS:
      return {
        ...state,
        threadCounts: action.threadCounts,
      };
    case SET_LATEST_CLOSED_THREAD:
      return {
        ...state,
        latestClosedThread: action.latestClosedThread,
      };
    default:
      return state;
  }
}
