import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useApolloClient, useQuery } from '@apollo/client';

import { GET_TASK_QUERY } from 'client-lib';
import type {
  TaskFormValues,
  TaskFormValuesFromDb,
} from 'client-lib/src/lib/controllers/hooks/tasks/types';
import type { UiLabel } from 'client-lib/src/lib/utils/helpers/types';

import { Loading } from '../../elements';
import type { Group, AppState } from '../../utils/helpers/types';
import PageCompose from '../CreateSection/Templates/PageCompose';
import { LoadingWrapper } from '../Settings/styles';
import UpsertTask from './UpsertTask';

const CreateAndEditTask = (): JSX.Element | JSX.Element[] => {
  const taskId = useSelector(
    (state: AppState) => state?.createSection?.createTask?.id
  );

  const allGroups = useSelector(
    (state: AppState) => state?.accountData?.allGroups
  );

  const client = useApolloClient();

  const { called, data, loading } = useQuery(GET_TASK_QUERY, {
    client,
    variables: {
      id: taskId,
    },
    fetchPolicy: 'network-only',
    skip: !taskId,
  });

  const tasksWithLabels = useMemo((): TaskFormValues | null => {
    const groups: Group[] | undefined = allGroups;
    const task: TaskFormValuesFromDb | undefined = data?.task?.task;

    if (!groups?.length || !task?.groupId) return null;

    const group = groups.find(({ id }) => id === task.groupId);

    if (!group?.id || !group?.name) return null;

    const groupLabel: UiLabel = {
      value: group.id,
      label: group.name,
    };

    const assigneeLabel: UiLabel = task?.assignee?.id
      ? {
          value: task?.assignee?.id,
          label: `${task?.assignee?.firstName ?? ''} ${task?.assignee?.lastName ?? ''}`,
        }
      : {
          value: '',
          label: 'Unassigned',
        };

    return {
      ...task,
      assignee: assigneeLabel,
      group: groupLabel,
    };
  }, [allGroups, data?.task?.task]);

  if (!taskId) {
    return <UpsertTask />;
  }

  if (loading || !called) {
    return (
      <PageCompose
        translationKey="tasks-edit-header"
        translationFallback="Edit Task"
        footerContent={<div />}
      >
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      </PageCompose>
    );
  }

  return (
    <UpsertTask
      initialFormValues={tasksWithLabels}
      taskRecipientFromApi={data?.task?.task?.recipient}
      templateMessageFromApi={data?.task?.task?.template?.message}
    />
  );
};

export default CreateAndEditTask;
