import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { contactName } from 'client-lib/src/lib/utils/helpers';
import i18n from 'i18n-js';
import { useApolloClient } from '@apollo/client';
import {
  Table,
  TableHead,
  TableBody,
  Loading,
  Heading3,
  InlineTextButton,
  Select,
  Heading2,
  Button,
} from '../../elements';
import SortButton from '../../elements/Table/SortButton';
import FormattedDateTime from '../Common/FormattedDateTime.tsx';
import {
  ColumnHeaderOverflow,
  NotFoundWrapper,
  OscillatingTableRow,
  RightAlignedItems,
  TableColumnHeader,
  TableContainer,
  TableHeader,
  TableHeaderContainer,
  TableLoadingWrapper,
  TableRow,
  TableWidgetContainer,
  Timestamp,
} from './styles';
import {
  ORDER_BY_OPTIONS,
  SORT_BY_TYPES,
  getTimeFrameOptions,
} from './constants';
import formatTimeOrNA from '../../utils/helpers/timeUtils.ts';
import { ExportGroupTableDataModal } from './ExportGroupTableDataModal';

const LeftAlignedItems = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 4px;
  align-items: center;
  gap: 16px;
  flex: 1;
`;

const MultiGroupTable = ({
  handleRefetch,
  loading,
  data,
  sortType,
  orderBy,
  timeFrame,
  setTimeFrame,
  setOrderBy,
  setSortType,
  groupIds,
  showRefresh,
  lastUpdated,
}) => {
  const client = useApolloClient();

  const [openModal, setOpenModal] = useState(false);

  const handleSortColumn = (orderKey) => {
    if (orderKey === orderBy) {
      setSortType(
        sortType === SORT_BY_TYPES.asc ? SORT_BY_TYPES.desc : SORT_BY_TYPES.asc
      );
    } else {
      setOrderBy(orderKey);
      setSortType(SORT_BY_TYPES.asc);
    }
  };

  return (
    <TableWidgetContainer>
      <TableHeaderContainer>
        <LeftAlignedItems>
          <Heading3>
            {i18n.t('threads-Dashboard-groupBreakdown', {
              defaultValue: 'Group Breakdown',
            })}
          </Heading3>
          {showRefresh && (
            <>
              <Timestamp data-testid="multiGroup-timeFrame-timestamp">
                {' '}
                {i18n.t('threads-Dashboard-lastUpdated', {
                  defaultValue: 'Last Updated:',
                })}{' '}
                <FormattedDateTime value={lastUpdated} />
              </Timestamp>

              <InlineTextButton
                dataTestId="multiGroup-refresh-button"
                noOutline
                onClick={handleRefetch}
              >
                {i18n.t('threads-Dashboard-refresh', {
                  defaultValue: 'Refresh',
                })}
              </InlineTextButton>
            </>
          )}
        </LeftAlignedItems>
        <RightAlignedItems>
          <Button
            dataTestId="dashboard-multiGroupTable-exportBtn"
            onClick={() => setOpenModal(true)}
            customStyle={() => 'margin-right: 25px;'}
          >
            {i18n.t('reports-Reports-export')}
          </Button>

          <Select
            data-testid="multiGroup-timeFrame-dropdown"
            options={getTimeFrameOptions(i18n)}
            value={timeFrame}
            onChange={(option) => setTimeFrame(option)}
            hideBottomSpace
          />
        </RightAlignedItems>
      </TableHeaderContainer>
      {loading ? (
        <TableLoadingWrapper>
          <Loading />
        </TableLoadingWrapper>
      ) : data?.groupTableData?.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <tr>
                <TableHeader>
                  <TableColumnHeader>
                    <ColumnHeaderOverflow
                      title={i18n.t('settings-TeamTable-name')}
                    >
                      {i18n.t('settings-TeamTable-name')}
                    </ColumnHeaderOverflow>
                    <SortButton
                      data-testid="multiGroup-name-sort"
                      onClick={() =>
                        handleSortColumn(ORDER_BY_OPTIONS.groupName)
                      }
                      sortType={sortType}
                      orderBy={orderBy}
                      orderKey={ORDER_BY_OPTIONS.groupName}
                    />
                  </TableColumnHeader>
                </TableHeader>
                <TableHeader>
                  <TableColumnHeader>
                    <ColumnHeaderOverflow
                      title={i18n.t('threads-Dashboard-numOfUsers', {
                        defaultValue: '# of Users',
                      })}
                    >
                      {i18n.t('threads-Dashboard-numOfUsers', {
                        defaultValue: '# of Users',
                      })}
                    </ColumnHeaderOverflow>
                    <SortButton
                      data-testid="multiGroup-users-sort"
                      onClick={() =>
                        handleSortColumn(ORDER_BY_OPTIONS.numUsers)
                      }
                      sortType={sortType}
                      orderBy={orderBy}
                      orderKey={ORDER_BY_OPTIONS.numUsers}
                    />
                  </TableColumnHeader>
                </TableHeader>
                <TableHeader>
                  <TableColumnHeader>
                    <ColumnHeaderOverflow
                      title={i18n.t('threads-Dashboard-closedConv', {
                        defaultValue: 'Closed Conversations',
                      })}
                    >
                      {i18n.t('threads-Dashboard-closedConv', {
                        defaultValue: 'Closed Conversations',
                      })}
                    </ColumnHeaderOverflow>
                    <SortButton
                      data-testid="multiGroup-closed-sort"
                      onClick={() =>
                        handleSortColumn(ORDER_BY_OPTIONS.closedThreads)
                      }
                      sortType={sortType}
                      orderBy={orderBy}
                      orderKey={ORDER_BY_OPTIONS.closedThreads}
                    />
                  </TableColumnHeader>
                </TableHeader>
                <TableHeader>
                  <TableColumnHeader>
                    <ColumnHeaderOverflow
                      title={i18n.t('threads-Dashboard-avgTimeClose', {
                        defaultValue: 'Average Time To Close',
                      })}
                    >
                      {i18n.t('threads-Dashboard-avgTimeClose', {
                        defaultValue: 'Average Time To Close',
                      })}
                    </ColumnHeaderOverflow>
                    <SortButton
                      data-testid="multiGroup-avTimeClose-sort"
                      onClick={() =>
                        handleSortColumn(ORDER_BY_OPTIONS.averageTimeToClose)
                      }
                      sortType={sortType}
                      orderBy={orderBy}
                      orderKey={ORDER_BY_OPTIONS.averageTimeToClose}
                    />
                  </TableColumnHeader>
                </TableHeader>
                <TableHeader>
                  <TableColumnHeader>
                    <ColumnHeaderOverflow
                      title={i18n.t('threads-Dashboard-claimedConv', {
                        defaultValue: 'Claimed Conversations',
                      })}
                    >
                      {i18n.t('threads-Dashboard-claimedConv', {
                        defaultValue: 'Claimed Conversations',
                      })}
                    </ColumnHeaderOverflow>
                    <SortButton
                      data-testid="multiGroup-claimed-sort"
                      onClick={() =>
                        handleSortColumn(ORDER_BY_OPTIONS.claimedConversations)
                      }
                      sortType={sortType}
                      orderBy={orderBy}
                      orderKey={ORDER_BY_OPTIONS.claimedConversations}
                    />
                  </TableColumnHeader>
                </TableHeader>
                <TableHeader>
                  <TableColumnHeader>
                    <ColumnHeaderOverflow
                      title={i18n.t('threads-Dashboard-avgTime', {
                        defaultValue: 'Average Time To Claim',
                      })}
                    >
                      {i18n.t('threads-Dashboard-avgTime', {
                        defaultValue: 'Average Time To Claim',
                      })}
                    </ColumnHeaderOverflow>
                    <SortButton
                      data-testid="multiGroup-avTimeClaim-sort"
                      onClick={() =>
                        handleSortColumn(ORDER_BY_OPTIONS.averageTimeToClaim)
                      }
                      sortType={sortType}
                      orderBy={orderBy}
                      orderKey={ORDER_BY_OPTIONS.averageTimeToClaim}
                    />
                  </TableColumnHeader>
                </TableHeader>
              </tr>
            </TableHead>
            <TableBody>
              {data?.groupTableData?.map((group, index) => (
                <OscillatingTableRow key={index}>
                  <TableRow>{group?.groupName || contactName(group)}</TableRow>
                  <TableRow>{group?.numUsers}</TableRow>
                  <TableRow>{group?.closedThreads}</TableRow>
                  <TableRow>
                    {formatTimeOrNA(group?.averageTimeToClose)}
                  </TableRow>
                  <TableRow>{group?.claimedConversations}</TableRow>
                  <TableRow>
                    {formatTimeOrNA(group?.averageTimeToClaim)}
                  </TableRow>
                </OscillatingTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <NotFoundWrapper>
          <Heading2>{timeFrame.otherProps.warning}</Heading2>
        </NotFoundWrapper>
      )}
      <ExportGroupTableDataModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        activeGroupIds={groupIds}
        timeFrame={timeFrame}
        timeFrameOptions={getTimeFrameOptions(i18n)}
        setTimeFrame={setTimeFrame}
        client={client}
      />
    </TableWidgetContainer>
  );
};

MultiGroupTable.propTypes = {
  handleRefetch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  sortType: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  timeFrame: PropTypes.object.isRequired,
  setTimeFrame: PropTypes.func.isRequired,
  data: PropTypes.object,
  setOrderBy: PropTypes.func.isRequired,
  setSortType: PropTypes.func.isRequired,
  groupIds: PropTypes.array.isRequired,
  showRefresh: PropTypes.bool,
  lastUpdated: PropTypes.string,
};

MultiGroupTable.defaultProps = {
  data: null,
  showRefresh: false,
  lastUpdated: new Date().toISOString(),
};

export default MultiGroupTable;
