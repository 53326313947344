import { type MutableRefObject } from "react";
import { type LexicalEditor } from "lexical";

import { useQuery, type ApolloClient } from "@apollo/client";
import EMAIL_SIGNATURE_QUERY from "../../../graphql/query/EmailSignatureQuery.graphql";

interface UseEmailSignatureParams {
  client: ApolloClient<object>;
  userId: string;
  messageVal: string;
  setMessageVal: (value: string) => void;
  richTextEditorRef: MutableRefObject<LexicalEditor | null>;
  setHTMLVal?: (editor: LexicalEditor, emailSignature: string) => void;
}

interface UseEmailSignatureReturn {
  emailSigOn: boolean;
  autoAddSig: boolean;
  appendEmailSignature: (value: string) => string;
  handleAddSignature: (callback?: () => void) => void;
}

/**
 * Custom hook to manage email signatures.
 *
 * @param {UseEmailSignatureParams} params - The parameters for the hook.
 * @returns {UseEmailSignatureReturn} - The hook's return values.
 */
const useEmailSignature = ({
  client,
  userId,
  messageVal,
  setMessageVal,
  richTextEditorRef,
  setHTMLVal,
}: UseEmailSignatureParams): UseEmailSignatureReturn => {
  const { data } = useQuery(EMAIL_SIGNATURE_QUERY, {
    client,
    variables: {
      userId,
    },
  });

  /**
   * If html version of the signature is present, use that.
   * Otherwise, use the text version.
   * @returns {string} - The HTML email signature.
   */
  const getHTMLSignature = (): string => {
    let signature;
    if (data?.emailSignature?.emailSignature?.htmlBody) {
      signature = data?.emailSignature?.emailSignature?.htmlBody;
    } else {
      signature = data?.emailSignature?.emailSignature?.body;
    }
    return signature;
  };

  const textSignature = data?.emailSignature?.emailSignature?.body;
  const htmlSignature = getHTMLSignature();

  const appendEmailSignature = (value: string) =>
    `${value}\n\n${textSignature}`;

  // Callback used to set cursor, close bubble, or any other UI
  const handleAddSignature = (onAddSigCb?: () => void) => {
    if (richTextEditorRef?.current && setHTMLVal) {
      setHTMLVal(richTextEditorRef.current, htmlSignature);
    }
    setMessageVal(appendEmailSignature(messageVal));
    onAddSigCb?.();
  };

  return {
    emailSigOn: data?.emailSignature?.emailSigOn || false,
    autoAddSig: data?.emailSignature?.autoAddSig || false,
    appendEmailSignature,
    handleAddSignature,
  };
};

export default useEmailSignature;
