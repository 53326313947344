/**
 * Represents a time frame option object.
 *
 * @typedef {Object} TimeFrameOption
 * @property {string} value - The value.
 * @property {string} label - The label.
 * @property {Object} otherProps - Additional properties.
 * @property {string} otherProps.warning - The warning message.
 */

/**
 * Returns an array of time frame options.
 *
 * @param {Object} i18n - The i18n object used for localization.
 * @returns {Array<TimeFrameOption>} An array of time frame options.
 */
export const getTimeFrameOptions = (i18n) => [
  {
    value: 'LAST_24_HOURS',
    label: i18n.t('threads-Dashboard-24hrs', {
      defaultValue: 'Last 24 Hours',
    }),
    otherProps: {
      warning: i18n.t('threads-Dashboard-no24hrs', {
        defaultValue: 'No activity in the last 24 hours',
      }),
    },
  },
  {
    value: 'LAST_7_DAYS',
    label: i18n.t('threads-Dashboard-7days', { defaultValue: 'Last 7 Days' }),
    otherProps: {
      warning: i18n.t('threads-Dashboard-no7days', {
        defaultValue: 'No activity in the last 7 days',
      }),
    },
  },
  {
    value: 'LAST_30_DAYS',
    label: i18n.t('threads-Dashboard-30days', {
      defaultValue: 'Last 30 Days',
    }),
    otherProps: {
      warning: i18n.t('threads-Dashboard-no30days', {
        defaultValue: 'No activity in the last 30 days',
      }),
    },
  },
  {
    value: 'THIS_WEEK',
    label: i18n.t('threads-Dashboard-thisWeek', {
      defaultValue: 'This Week',
    }),
    otherProps: {
      warning: i18n.t('threads-Dashboard-nothisWeek', {
        defaultValue: 'No activity this week',
      }),
    },
  },
  {
    value: 'THIS_MONTH',
    label: i18n.t('threads-Dashboard-thisMonth', {
      defaultValue: 'This Month',
    }),
    otherProps: {
      warning: i18n.t('threads-Dashboard-nothisMonth', {
        defaultValue: 'No activity this month',
      }),
    },
  },
];

export const ORDER_BY_OPTIONS = {
  averageTimeToClaim: 'AVERAGE_TIME_TO_CLAIM',
  averageTimeToClose: 'AVERAGE_TIME_TO_CLOSE',
  claimedConversations: 'CLAIMED_CONVERSATIONS',
  closedThreads: 'CLOSED_THREADS',
  groupName: 'GROUP_NAME',
  name: 'NAME',
  numUsers: 'NUM_USERS',
  numTransfersInitiated: 'NUM_TRANSFERS_INITIATED',
};

export const SORT_BY_TYPES = {
  asc: 'ASC',
  desc: 'DESC',
};
