import { useGroupsQuery } from 'client-lib';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { setGroups } from '../../actions/accountData';

const useOnLoadQueryGroupsData = (/* { history } */) => {
  const client = useApolloClient();
  const dispatch = useDispatch();

  const groupsRefetchKey = useSelector(
    (state) => state.accountData?.groupsRefetchKey
  );

  const { data: groupsQueryData, refetch } = useGroupsQuery({
    client,
    variables: {
      excludeBusinessHours: true,
    },
  });

  const sortGroups = (groupsQueryData) => {
    const sortedGroups = groupsQueryData?.groups.slice();
    sortedGroups.sort((a, b) => ('' + a.name).localeCompare(b.name));
    return sortedGroups;
  };

  // We want to store the sorted version of the groups array. Whenever the query retrieves
  // the data we want to make sure we keep that updated in the store.
  useEffect(() => {
    if (groupsQueryData?.groups) {
      const sortedGroups = sortGroups(groupsQueryData);
      dispatch(setGroups(sortedGroups));
    }
  }, [groupsQueryData?.groups?.length]);

  const refetchGroupsData = async () => {
    const { data } = await refetch();
    if (data) {
      const sortedGroups = sortGroups(data);
      dispatch(setGroups(sortedGroups));
    }
  };

  // refetch data and set within store again if we update the groupsRefetchKey
  useEffect(() => {
    if (groupsRefetchKey) {
      refetchGroupsData();
    }
  }, [groupsRefetchKey]);
};

export default useOnLoadQueryGroupsData;
