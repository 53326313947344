import { useLazyQuery } from "@apollo/client";
import CAN_USER_PERFORM_ACTIONS from "../../../../graphql/query/CanUserPerformActions.graphql";

const useCanUserPerformActions = ({ client, userId, actions }) => {
  const canUserPerformActionsLazy = useLazyQuery(CAN_USER_PERFORM_ACTIONS, {
    client,
    variables: { userId, actions },
  });

  return canUserPerformActionsLazy;
};

export default useCanUserPerformActions;
