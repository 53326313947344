import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import OverviewMessageView from './OverviewMessageView.tsx';
import { Text } from '../../../../../elements';

const MessageSubTitle = ({ text, attachment, link }) => {
  return text?.length || attachment?.originalFilename || link?.length ? (
    <OverviewMessageView
      messageText={text}
      attachment={attachment}
      link={link}
    />
  ) : (
    <Text>{i18n.t('slideouts-GroupMessageOverview-whatToSay')}</Text>
  );
};

MessageSubTitle.propTypes = {
  text: PropTypes.string,
  attachment: PropTypes.object,
  link: PropTypes.string,
};

MessageSubTitle.defaultProps = {
  text: '',
  attachment: {},
  link: '',
};

export default MessageSubTitle;
