import theme from 'styled-theming';
import COLORS from '../../colors';

const BORDER = theme('mode', {
  classic: COLORS.PK_CLASSIC.SECONDARY_GRAY,
  light: COLORS.PK.GREY_50,
  dark: COLORS.PK_DARK.GREY_3,
  holiday: COLORS.PK.GREY_50,
});

const BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.WHITE,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_HIGH,
  holiday: COLORS.PK.WHITE,
});

const FOCUS = theme('mode', {
  classic: COLORS.PK_CLASSIC.SKY_BLUE,
  light: COLORS.PK.GREY_50,
  dark: COLORS.PK.GREY_50,
  holiday: COLORS.PK.GREY_50,
});

const CHECK_BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.BRIGHT_BLUE,
  light: COLORS.PK.BLUE,
  dark: COLORS.PK.BLUE,
  holiday: COLORS.PK.BLUE,
});

const LABEL = theme('mode', {
  classic: COLORS.PK_CLASSIC.TERTIARY_BLACK,
  light: COLORS.PK.GREY_80,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_MED,
  holiday: COLORS.PK.GREY_80,
});

const DISABLED_BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.LIGHT_GRAY,
  light: COLORS.PK.GREY_20,
  dark: COLORS.PK.GREY_20,
  holiday: COLORS.PK.GREY_20,
});

const DISABLED_CHECK_BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.DARK_GRAY,
  light: COLORS.PK.GREY_40,
  dark: COLORS.PK_DARK.GREY_3,
  holiday: COLORS.PK.GREY_40,
});

const DISABLED_BORDER = theme('mode', {
  classic: COLORS.PK_CLASSIC.SECONDARY_GRAY,
  light: COLORS.PK.GREY_30,
  dark: COLORS.PK.GREY_30,
  holiday: COLORS.PK.GREY_30,
});

const DISABLED_LABEL = theme('mode', {
  classic: COLORS.PK_CLASSIC.TERTIARY_BLACK,
  light: COLORS.PK.GREY_40,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_LOW,
  holiday: COLORS.PK.GREY_40,
});

const THEMES = {
  BORDER,
  BACKGROUND,
  FOCUS,
  CHECK_BACKGROUND,
  LABEL,
  DISABLED_BACKGROUND,
  DISABLED_CHECK_BACKGROUND,
  DISABLED_BORDER,
  DISABLED_LABEL,
};

export default THEMES;
