import React from 'react';
import styled from 'styled-components';
import { Loading } from '../../elements';
import THEMES from '../../styles/themes/app';

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LazyFallback = () => (
  <Container>
    <Loading />
  </Container>
);

export default LazyFallback;
