import {
  isAttachmentFileTypeSupported,
  fileNameWithoutExtension,
  fileExtension,
  ALLOWED_FILENAME_REGEX,
  NOT_ALLOWED_FILENAME_REGEX,
  calcCombinedAttachmentSize,
} from 'client-lib/src/lib/utils/helpers';

import {
  I18N_ERROR_MESSAGES,
  I18nError,
  MAX_COMBINED_FILE_SIZE,
} from './constants';
import type { Attachment } from './types';

const validateAttachment = (
  originalFile: File,
  onFileError: (error: I18nError) => void,
  handleSetAttachment: (file: File, originalFilename?: string) => void,
  attachments?: Attachment[],
  maxFileSize?: number
): void => {
  const filename = fileNameWithoutExtension(originalFile.name);
  const extension = `.${fileExtension(originalFile.name)}`;

  if (maxFileSize && originalFile.size > maxFileSize) {
    // if maxFileSize is provided, check if the file size is greater than the maxFileSize (used for single file methods like CreateThread or CreateFax)
    onFileError(
      new I18nError(I18N_ERROR_MESSAGES.FILE_SIZE_LIMIT, {
        fileSizeLimit: maxFileSize / 1000000,
      })
    );
  } else if (
    attachments &&
    calcCombinedAttachmentSize([originalFile], attachments) >
      MAX_COMBINED_FILE_SIZE
  ) {
    // if attachments are provided, check if the combined attachment size is greater than the MAX_COMBINED_FILE_SIZE
    onFileError(
      new I18nError(I18N_ERROR_MESSAGES.FILE_SIZE_LIMIT, {
        fileSizeLimit: MAX_COMBINED_FILE_SIZE / 1000000,
      })
    );
  } else if (!isAttachmentFileTypeSupported(originalFile.name)) {
    onFileError(new I18nError(I18N_ERROR_MESSAGES.UNSUPPORTED_FILE));
  } else if (!filename.match(ALLOWED_FILENAME_REGEX)) {
    const removedSpecialCharacters = filename.replace(
      NOT_ALLOWED_FILENAME_REGEX,
      ''
    );
    const adaptedFilename = `${removedSpecialCharacters}${extension}`;
    handleSetAttachment(originalFile, adaptedFilename);
  } else {
    handleSetAttachment(originalFile);
  }
};

export default validateAttachment;
