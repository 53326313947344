import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { URL_ARG_ENTITY_TYPES, PK_TYPENAMES } from 'client-lib';
import {
  Avatar,
  Checkbox,
  EmphasisText,
  Text,
  Tooltip,
} from '../../../../../elements';
import MeasurableBadgeContainer from '../../../../Common/MeasurableContainers/MeasurableBadgeContainer';
import useMeasure from '../../../../Animations/useMeasure';
import {
  AvatarWrapper,
  ContactDataWrapper,
  StyledCheckboxTD,
  StyledTD,
  StyledTableRow,
  getStyledCompanyText,
  getStyledNameText,
  getStyledStatusText,
} from '../../sharedStyles';
import MeasurableLabelContainer from '../../../../Common/MeasurableContainers/MeasurableLabelContainer';
import ChipLabels from '../../../../ChipLabels/ChipLabels';

const CommonTableRow = ({
  name,
  type,
  color,
  company,
  groupIds,
  labels,
  checked,
  onCheck,
  optInStatusText,
  disabled,
  id,
}) => {
  const [groupRef, groupSize] = useMeasure();
  const [labelRef, labelSize] = useMeasure();
  const [statusRef, statusSize] = useMeasure();
  const statusContainerWidth = statusSize?.width;
  const groupContainerWidth = groupSize?.width;
  const labelContainerWidth = labelSize?.width;

  const allGroups = useSelector((state) => state?.accountData?.allGroups);
  const groupNames = allGroups
    .filter((group) => groupIds.includes(group.id))
    .map((group) => group.name);

  const getAvatarIcon = () => {
    if (type === PK_TYPENAMES.CUSTOMER_ACCOUNT) return 'company';
    else if (type === URL_ARG_ENTITY_TYPES.COMPANY) return 'company';
    else if (type === URL_ARG_ENTITY_TYPES.GROUP) return 'tag';
    else if (type === URL_ARG_ENTITY_TYPES.LABEL) return 'label';
    else if (type === URL_ARG_ENTITY_TYPES.LIST) return 'tag';
    return null;
  };

  /**
   * @type {number} maxStatusTextLength - Maximum length of status text before truncation
   */
  const maxStatusTextLength = 30;
  /**
   * (private) Check to see if the status text should be truncated
   * @param {string} text Text of status message to display
   * @param {number} boxSize Size of the box the status is displayed in
   * @returns {boolean} Whether the status text should be truncated
   */
  const shouldTruncateStatusText = (text, boxSize) =>
    text.length > maxStatusTextLength && boxSize < 140;
  /**
   * (private) Gets the text to display in the status box, truncating if necessary
   * @param {string} text Text of status message to display
   * @param {boolean} truncate Whether the status text should be truncated
   * @returns {string} The text to display in the status box
   */
  const getPrimaryStatusDisplay = (text, truncate) => {
    if (truncate) {
      return `${text.slice(0, maxStatusTextLength)}...`;
    }
    return text;
  };

  /**
   * For accessibility, if the whole row is clickable for the select action,
   * then it must be tabbable and hitting the enter key should serve as a click
   * @param {*} e KeyBoard Event
   */
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onCheck();
    }
  };

  return (
    <StyledTableRow
      role="button"
      tabIndex={0}
      onClick={!disabled ? onCheck : () => {}}
      onKeyDown={handleKeyDown}
    >
      <StyledCheckboxTD>
        <Checkbox
          dataTestId="create-broadcastList-checkboxCommonAudience"
          checked={checked}
          onCheck={() => {
            /* Not needed - entire table row is clickable */
          }}
          disabled={disabled}
        />
        <AvatarWrapper>
          <Avatar
            size="md"
            icon={getAvatarIcon()}
            dataTestId="create-broadcastList-avatar"
            disabled={disabled}
          >
            {name}
          </Avatar>
          <ContactDataWrapper>
            {type !== URL_ARG_ENTITY_TYPES.LABEL && (
              <EmphasisText customStyle={getStyledNameText} disabled={disabled}>
                {name}
              </EmphasisText>
            )}
            {type === URL_ARG_ENTITY_TYPES.LABEL && (
              <ChipLabels
                options={{
                  value: [{ color, text: name, value: id }],
                }}
                size="sm"
              />
            )}
            {company && (
              <Text customStyle={getStyledCompanyText} disabled={disabled}>
                {company}
              </Text>
            )}
          </ContactDataWrapper>
        </AvatarWrapper>
      </StyledCheckboxTD>
      <StyledTD {...groupRef}>
        {groupContainerWidth && groupIds && (
          <MeasurableBadgeContainer
            dataTestIds="btm-list-group-badge"
            badgeLabels={groupNames}
            maxWidth={groupContainerWidth}
          />
        )}
      </StyledTD>
      <StyledTD {...labelRef}>
        {labelContainerWidth && labels && (
          <MeasurableLabelContainer
            dataTestIds="btm-list-label-badge"
            labels={labels}
            maxWidth={labelContainerWidth}
          />
        )}
      </StyledTD>
      <StyledTD {...statusRef}>
        <Tooltip
          id={`option-status-tool-tip_${id}`}
          elementsContent={<Text>{optInStatusText}</Text>}
          disabled={
            !shouldTruncateStatusText(optInStatusText, statusContainerWidth)
          }
          // Normal tooltip position is determined by the Tooltip component
          // However, because this is in the infinite scroll component,
          //  the y axis position is not calculated correctly.
          // Use the fact that we're in a table to display the position
          //  absolutely, relative to the row.
          overridePosition={() => ({ left: 50, top: 10 })}
          customStyle={() => ({
            position: 'absolute !important',
          })}
        >
          <EmphasisText
            data-tip
            data-for={`option-status-tool-tip_${id}`}
            customStyle={getStyledStatusText}
            dataTestId="create-broadcastList-optin-status"
          >
            {getPrimaryStatusDisplay(
              optInStatusText,
              shouldTruncateStatusText(optInStatusText, statusContainerWidth)
            )}
          </EmphasisText>
        </Tooltip>
      </StyledTD>
    </StyledTableRow>
  );
};

CommonTableRow.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  color: PropTypes.string,
  company: PropTypes.string,
  groupIds: PropTypes.arrayOf(PropTypes.string),
  labels: PropTypes.arrayOf(PropTypes.object),
  checked: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  optInStatusText: PropTypes.string,
  id: PropTypes.string.isRequired,
};

CommonTableRow.defaultProps = {
  color: '',
  company: '',
  groupIds: [],
  labels: [],
  disabled: false,
  optInStatusText: '',
};

export default CommonTableRow;
