import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BUTTON_THEMES from '../../styles/themes/library/button';
import COLORS from '../../styles/colors';
import THEMES from '../../styles/themes/library/iconButton';
import FONTSIZE_THEMES from '../../styles/themes/fontSize/button';

const contrastStyles = {
  high: {
    normal: THEMES.FOREGROUND_HIGH,
    hover: THEMES.FOREGROUND_HIGH_HOVER,
  },
  med: {
    normal: THEMES.FOREGROUND_MED,
    hover: THEMES.FOREGROUND_MED_HOVER,
  },
  low: {
    normal: THEMES.FOREGROUND_LOW,
    hover: THEMES.FOREGROUND_LOW_HOVER,
  },
  colorHigh: {
    normal: THEMES.COLORED_FOREGROUND_HIGH,
    hover: THEMES.COLORED_FOREGROUND_HIGH_HOVER,
  },
  colorMed: {
    normal: THEMES.COLORED_FOREGROUND_MED,
    hover: THEMES.COLORED_FOREGROUND_MED_HOVER,
  },
  colorLow: {
    normal: THEMES.COLORED_FOREGROUND_LOW,
    hover: THEMES.COLORED_FOREGROUND_LOW_HOVER,
  },
  highColor: {
    normal: THEMES.FOREGROUND_HIGH_COLOR,
    hover: THEMES.FOREGROUND_HIGH_COLOR_HOVER,
  },
  medColor: {
    normal: THEMES.FOREGROUND_MED_COLOR,
    hover: THEMES.FOREGROUND_MED_COLOR_HOVER,
  },
  destructive: {
    normal: THEMES.DESTRUCTIVE_BACKGROUND,
    hover: THEMES.DESTRUCTIVE_BACKGROUND_HOVER,
  },
};

const sizeStyles = {
  xs: {
    fontSize: FONTSIZE_THEMES.ICON_BUTTON_SM,
    widthHeight: 15,
  },
  sm: {
    fontSize: FONTSIZE_THEMES.ICON_BUTTON_SM,
    widthHeight: 20,
  },
  md: {
    fontSize: FONTSIZE_THEMES.ICON_BUTTON_MD,
    widthHeight: 26,
  },
  lg: {
    fontSize: FONTSIZE_THEMES.ICON_BUTTON_LG,
    widthHeight: 32,
  },
};

export const IconButtonStyled = styled.button`
  padding: 0;
  margin: 0;
  background-color: ${THEMES.BACKGROUND};
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  color: ${(props) =>
    props.disabled
      ? contrastStyles.low.normal
      : contrastStyles[props.contrast].normal};

  font-size: ${(props) => sizeStyles[props.size].fontSize}px;
  line-height: ${(props) => sizeStyles[props.size].fontSize}px;
  height: ${(props) => sizeStyles[props.size].widthHeight}px;
  width: ${(props) => sizeStyles[props.size].widthHeight}px;

  :hover {
    color: ${(props) =>
      props.disabled
        ? contrastStyles.low.normal
        : contrastStyles[props.contrast].hover};
  }

  :focus {
    outline: none;
    box-shadow: 0 0 0 3px
      ${(props) =>
        props.noOutline
          ? COLORS.PK_CLASSIC.TRANSPARENT
          : BUTTON_THEMES.BUTTON_OUTLINE};
  }
`;

const IconButton = React.forwardRef(
  ({ contrast, size, disabled, noOutline, dataTestId, ...otherProps }, ref) => (
    <IconButtonStyled
      contrast={contrast}
      size={size}
      disabled={disabled}
      noOutline={noOutline}
      css={(props) => props?.customStyle?.(props)} // eslint-disable-line react/prop-types
      {...otherProps}
      data-testid={dataTestId}
      ref={ref}
    />
  )
);

IconButton.propTypes = {
  contrast: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  noOutline: PropTypes.bool,
  dataTestId: PropTypes.string,
};

IconButton.defaultProps = {
  contrast: 'high',
  size: 'md',
  disabled: false,
  noOutline: false,
  dataTestId: null,
};

IconButton.displayName = 'IconButton';

export default IconButton;
