import type { ThemeSet } from 'styled-theming';
import LABEL_THEMES from '../label';

// eslint-disable-next-line import/prefer-default-export
export const getLabelThemeColor = (color: string): ThemeSet => {
  const themeColorDefault = LABEL_THEMES.GREY_LABEL;

  if (!color) {
    return themeColorDefault;
  }

  const colorMap: { [key: string]: ThemeSet } = {
    red: LABEL_THEMES.RED_LABEL,
    orange: LABEL_THEMES.ORANGE_LABEL,
    violet: LABEL_THEMES.PURPLE_LABEL,
    blue: LABEL_THEMES.BLUE_LABEL,
    green: LABEL_THEMES.GREEN_LABEL,
  };

  return colorMap[color] || themeColorDefault;
};
