import { isInternalThreadAndOwnedByUser } from "../../../utils/helpers";
import useReadThreadsFromApolloCache from "./useReadThreadsFromApolloCache";

/*

DOCUMENTATION
  useThreadNotificationQueue is used to query unclaimed and unread threads, typically to place them in a queue for notifications purposes.

  args:
    client: (obj) apollo client instance
    contactId: (str) contact id of user
    userId: (str) userId of user
    threadsActiveGroupIds: (arr) user's active group ids

  returns:
    notificationQueue: (arr) array of unclaimed and unread threads
    error: (err) apollo query error if any

*/

const useThreadNotificationQueue = ({
  client,
  contactId,
  userId,
  threadsActiveGroupIds,
  groupInboxNotificationsEnabled,
  includeInternal,
}) => {
  const {
    inboxThreads,
    myOpenThreads,

    inboxThreadsPageInfo,
    myOpenThreadsPageInfo,
    threadsForUserPageInfo,
  } = useReadThreadsFromApolloCache({
    client,
    threadsActiveGroupIds,
    contactId,
    userId,
    includeInternal,
  });

  const unreadThreads = myOpenThreads.filter((t) => {
    if (
      t?.ownerContact?.id === contactId ||
      isInternalThreadAndOwnedByUser(t, userId) ||
      t?.watchers?.some((watcher) => watcher?.user?.id === userId) // include users who have turned watcher notifications on
    ) {
      const participant =
        t.participants.find((p) => p.contactId === contactId) || null;
      const lastReadAt = participant !== null ? participant.readAt : null;

      return lastReadAt === null || lastReadAt < t?.latestMessage?.createdAt;
    }

    return false;
  });

  let notificationQueue;

  // if user has groupInboxNotificationsEnabled false, they don't want to recieve notifications for
  // the inbox, so only give them notifications for unread threads.
  if (groupInboxNotificationsEnabled === false) {
    notificationQueue = [...unreadThreads];
  } else {
    notificationQueue = [...inboxThreads, ...unreadThreads];
  }

  const combinedPageInfo = [
    inboxThreadsPageInfo,
    myOpenThreadsPageInfo,
    threadsForUserPageInfo,
  ];

  const queryPagesLoaded = combinedPageInfo.reduce((accumulator, pageInfo) => {
    if (!accumulator) return accumulator;
    return !pageInfo ? false : pageInfo.hasNextPage === false;
  }, true);

  return { notificationQueue, queryPagesLoaded };
};

export default useThreadNotificationQueue;
