/**
 * File to allow for including dependencies that are not part of the main bundle
 * Specifically items in the greater Prokeep library.  This helps walk a line of
 * allowing easy use of components, while calling out and providing a way to see
 * the RichTextEditor is being tightly coupled to these dependencies.
 *
 * It hopefully allows for easily swiping out the dependencies in the future.
 */

import PKInputError from '../../inputCommonElements/InputError';

export type InputError = {
  error?: string;
  helperText?: string;
  dataTestId?: string;
  customErrorContainerStyle?: object;
};
export const InputError = PKInputError;
