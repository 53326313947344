import { $getNodeByKey, type LexicalEditor } from 'lexical';
import { UPLOAD_FILE_AND_GENERATE_SHARED_LINK_MUTATION } from 'client-lib';
import type { ApolloClient } from '@apollo/client';
import { ImageNode } from '../nodes/ImageNode';
import {
  I18N_ERROR_MESSAGES,
  I18nError,
} from '../../../utils/helpers/constants';
import type { ImageToUpload } from '../../../utils/helpers/types';

const readImageNodes = (editor: LexicalEditor) => {
  const imageNodes: ImageToUpload[] = [];
  editor.getEditorState().read(() => {
    editor.getEditorState()._nodeMap.forEach((node) => {
      if (node.getType() === 'image') {
        const imageNode = node as ImageNode;
        imageNodes.push({
          nodeKey: imageNode.getKey(),
          attachment: imageNode.getAttachment(),
          url: imageNode.__src,
        });
      }
    });
  });
  return imageNodes;
};

const uploadImages = async (
  client: ApolloClient<object>,
  imageNodes: ImageToUpload[]
) => {
  const uploadPromises = imageNodes.map(
    async ({ nodeKey, attachment, url: existingUrl }) => {
      if (!existingUrl?.startsWith('blob:'))
        return { nodeKey, url: existingUrl, error: null, attachment };
      const result = await client
        .mutate({
          mutation: UPLOAD_FILE_AND_GENERATE_SHARED_LINK_MUTATION,
          variables: {
            input: {
              data: attachment.data,
              filename: attachment.originalFilename,
            },
          },
        })
        .catch((err: Error) => {
          console.error('Error uploading image to S3:', err);
          return null;
        });

      if (!result || !result.data) {
        return {
          nodeKey,
          url: '',
          error: new I18nError(I18N_ERROR_MESSAGES.FILE_FAILURE, {
            originalFilename: attachment.originalFilename,
          }),
          attachment,
        };
      }

      const { url } = result.data.uploadFileAndGenerateSharedLink;
      return { nodeKey, url, error: null, attachment };
    }
  );

  return Promise.all(uploadPromises);
};

const updateImageNodesWithLinks = (
  editor: LexicalEditor,
  uploadedImages: ImageToUpload[],
  onFileError: (error: I18nError) => void
) => {
  editor.update(() => {
    uploadedImages.forEach(({ url, nodeKey, error }) => {
      const node = $getNodeByKey(nodeKey) as ImageNode;
      if (error) {
        onFileError(error);
        return;
      }
      node.setSrc(url || '');
      node.setHasUploaded(true);
    });
  });
};

/**
 * A hook that provides functionality to upload images from a Lexical editor to S3.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {ApolloClient<object>} params.client - The Apollo client used for making GraphQL mutations.
 * @returns {Function} - A function that uploads images from the Lexical editor to S3.
 *
 * The returned function performs the following steps:
 * 1. Gathers all image nodes in the editor.
 * 2. Uploads the images to S3.
 * 3. Updates the image nodes with the new S3 URLs.
 *
 * @example
 * ```typescript
 * const uploadImages = useUploadLexicalImagesToS3({ client });
 * await uploadImages(editor, (error: I18nError) => {
 *   dispatch(openSnackbar(i18n.t(error.i18n_id, error.i18n_variables), 'error'));
 * });
 * ```
 */

const useUploadLexicalImagesToS3 = ({
  client,
}: {
  client: ApolloClient<object>;
}) => {
  const uploadLexicalImagesToS3 = async (
    editor: LexicalEditor,
    onFileError: (error: I18nError) => void
  ) => {
    // Step 1: Gather all image nodes in the editor
    const imageNodes = readImageNodes(editor);
    // Step 2: Perform uploads outside of `editor.update`
    const uploadedImages = await uploadImages(client, imageNodes);
    // Step 3: Update the nodes with the new S3 URLs
    updateImageNodesWithLinks(editor, uploadedImages, onFileError);

    return uploadedImages;
  };

  return uploadLexicalImagesToS3;
};

export default useUploadLexicalImagesToS3;
