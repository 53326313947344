import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  SINGLE_GROUP_USER_TABLE_QUERY,
  useOpenThreadsByUserWidget,
  useUnclaimedThreadsByTimeWidget,
  useActiveThreadCountWidget,
} from 'client-lib';
import { DEFAULT_DASHBOARD_TABLE_ROW_COUNT } from 'client-lib/src/lib/utils/helpers';
import i18n from 'i18n-js';
import { useApolloClient, useQuery } from '@apollo/client';
import SingleGroupThreadWidgetsContainer from './SingleGroupThreadWidgetsContainer';
import SingleGroupTable from './SingleGroupTable';
import {
  ORDER_BY_OPTIONS,
  SORT_BY_TYPES,
  getTimeFrameOptions,
} from './constants';
import DashboardActionBar from '../DashboardAndFeedback/ActionBar/DashboardActionBar';

const TOP_N = DEFAULT_DASHBOARD_TABLE_ROW_COUNT;

const SingleGroupDashboard = ({ groupIds }) => {
  const client = useApolloClient();
  const groupId = groupIds[0];
  const [openConvoFlyoutOpen, setOpenConvoFlyoutOpen] = useState(false);
  const [sortType, setSortType] = useState(SORT_BY_TYPES.asc);
  const [orderBy, setOrderBy] = useState(ORDER_BY_OPTIONS.name);
  const [lastUpdatedWidgets, setLastUpdatedWidgets] = useState(
    new Date().toISOString()
  );
  const [lastUpdatedTable, setLastUpdatedTable] = useState(
    new Date().toISOString()
  );
  const [timeFrame, setTimeFrame] = useState(getTimeFrameOptions(i18n)[0]);

  const {
    openThreadCountTotal,
    openThreadCountUpperBound,
    openThreadCountByUser,
    widgetsLoading: openWidgetLoading,
    refetchOpenThreadsByUser,
  } = useOpenThreadsByUserWidget({ client, groupId });

  const {
    unclaimedThreadCountTotal,
    unclaimedThreadCountByGroup,
    widgetsLoading: unclaimedWidgetLoading,
    refetchUnclaimedThreadsByTime,
  } = useUnclaimedThreadsByTimeWidget({ client, groupId });

  const { activeGroups, activeTotal, activeLoading, refetchActiveThreadCount } =
    useActiveThreadCountWidget({
      client,
      i18n,
      groupIds: [groupId],
    });

  const variables = {
    groupIds: [groupId],
    sortType,
    orderBy,
    timeFrame: timeFrame?.value,
    topN: TOP_N,
  };
  const {
    data: tableData,
    loading: tableLoading,
    refetch: tableRefetch,
  } = useQuery(SINGLE_GROUP_USER_TABLE_QUERY, {
    client,
    variables,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: () => setLastUpdatedTable(new Date().toISOString()),
  });

  const dataIsLoading =
    openWidgetLoading ||
    unclaimedWidgetLoading ||
    activeLoading ||
    tableLoading;

  const handleWidgetRefresh = () => {
    refetchOpenThreadsByUser();
    refetchUnclaimedThreadsByTime();
    refetchActiveThreadCount();
    setLastUpdatedWidgets(new Date().toISOString());
  };

  const handleTableRefetch = async () => {
    await tableRefetch({ fetchPolicy: 'network-only' });
  };

  const refreshData = () => {
    handleWidgetRefresh();
    handleTableRefetch();
  };

  return (
    <>
      <DashboardActionBar
        activeGroupIds={groupIds}
        handleRefetch={refreshData}
        loading={dataIsLoading}
        completedDate={lastUpdatedWidgets}
      />
      <SingleGroupThreadWidgetsContainer
        openTotal={openThreadCountTotal}
        openUpperBound={openThreadCountUpperBound}
        openUsers={openThreadCountByUser}
        openWidgetLoading={openWidgetLoading}
        unclaimedTotal={unclaimedThreadCountTotal}
        unclaimedThreads={unclaimedThreadCountByGroup}
        unclaimedWidgetLoading={unclaimedWidgetLoading}
        activeTotal={activeTotal}
        activeData={activeGroups}
        activeWidgetLoading={activeLoading}
        openConvoFlyoutOpen={openConvoFlyoutOpen}
        setOpenConvoFlyoutOpen={setOpenConvoFlyoutOpen}
      />
      <SingleGroupTable
        handleRefetch={handleTableRefetch}
        loading={tableLoading}
        data={tableData}
        sortType={sortType}
        orderBy={orderBy}
        timeFrame={timeFrame}
        setOrderBy={setOrderBy}
        setSortType={setSortType}
        setTimeFrame={setTimeFrame}
        lastUpdated={lastUpdatedTable}
      />
    </>
  );
};

SingleGroupDashboard.propTypes = {
  groupIds: PropTypes.array.isRequired,
};

export default SingleGroupDashboard;
