const snackbarEventConfigs = {
  CUSTOMER_UPDATE_SUCCEEDED: {
    i18nId: 'settings-globalSnackbar-customerUpdateSuccess',
    snackType: 'success',
  },
  CUSTOMER_UPDATE_SUCCEEDED_WITH_ERRORS: {
    i18nId: 'settings-globalSnackbar-customerUpdateSuccessWithErrors',
    snackType: 'warning',
  },
  CUSTOMER_UPDATE_FAILED: {
    i18nId: 'settings-globalSnackbar-customerUpdateFailure',
    snackType: 'error',
  },
  OUTBOUND_FAX_FAILED: {
    i18nId: 'settings-globalSnackbar-faxFailure',
    snackType: 'error',
  },
  OUTBOUND_FAX_SUCCEEDED: {
    i18nId: 'settings-globalSnackbar-faxSucceed',
    snackType: 'success',
  },
};

export default snackbarEventConfigs;
