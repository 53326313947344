import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import i18n from 'i18n-js';
import { Checkbox } from '../../../../../elements';
import THEMES from '../../../../../styles/themes/app';

const OverviewItemInnerContentWrapper = styled.div`
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  width: 100%;
  padding: ${(props) => (props.multiline ? '12px 16px' : '12px 20px')};
  border-top: 1px solid ${THEMES.BORDER_COLOR};
  box-shadow: ${THEMES.BOX_SHADOW};
  display: flex;
  text-align: left;
  align-items: center;
  box-sizing: border-box;
`;

const AnnouncementPermissionCheckContainer = styled.div`
  width: 100%;
`;

const AnnouncementPermissionCheck = ({
  hasPermission,
  dueAt,
  togglePermission,
  hideCheckbox,
}) => {
  return (
    <AnnouncementPermissionCheckContainer>
      {hideCheckbox ? (
        <div />
      ) : (
        <OverviewItemInnerContentWrapper>
          <Checkbox
            label={i18n.t('broadcasts-GroupMessageOverview-permissionCredits')}
            checked={hasPermission || !!dueAt}
            onCheck={() => togglePermission(!hasPermission)}
            customLabelStyle={() => 'width: 100%;'}
            customContainerStyle={() => 'display: flex; align-items: center'}
          />
        </OverviewItemInnerContentWrapper>
      )}
      <div style={{ height: 30 }} />
    </AnnouncementPermissionCheckContainer>
  );
};

AnnouncementPermissionCheck.propTypes = {
  hasPermission: PropTypes.bool.isRequired,
  dueAt: PropTypes.object,
  togglePermission: PropTypes.func.isRequired,
  hideCheckbox: PropTypes.bool,
};

AnnouncementPermissionCheck.defaultProps = {
  dueAt: null,
  hideCheckbox: true,
};

export default AnnouncementPermissionCheck;
