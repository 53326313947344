import styled, { keyframes } from 'styled-components';
import THEMES from '../../../styles/themes/app';
import COLORS from '../../../styles/colors';

export const FooterWrapper = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
  position: relative;
  z-index: 10;
`;

export const ButtonContainer = styled.div`
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  border-top: 1px solid ${THEMES.BORDER_COLOR};
  display: flex;
  flex-direction: row-reverse;
  gap: 8px;
  max-height: 60px;
  padding: 10px;
`;

const slideIn = keyframes`
  0% { transform: translateY(100%); }
  100% { transform: translateY(0%); }
`;

export const ErrorContainer = styled.div`
  align-items: center;
  background-color: ${COLORS.PK.RED_80};
  border-radius: 4px 4px 0px 0px;
  color: ${COLORS.PK.WHITE};
  display: flex;
  padding: 14px 16px;
  position: absolute;
  z-index: 1;
  bottom: 60px;
  animation: ${slideIn} 0.35s ease-out 0s 1;
  width: calc(100% - 32px);
  i {
    margin-right: 8px;
  }
`;

export const ErrorText = `
  color: ${COLORS.PK.WHITE};
`;
