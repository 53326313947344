import { useQuery } from "@apollo/client";
import ACCOUNT_QUERY from "../../../graphql/query/AccountQuery.graphql";

function useHasFaxFeatureFlag({ currentUser, client }) {
  const faxFeatureFlag = useQuery(ACCOUNT_QUERY, {
    client,
    variables: {
      id: currentUser?.accountId,
    },
  }).data?.account?.ff_fax;

  return { faxFeatureFlag };
}

export default useHasFaxFeatureFlag;
