import React from 'react';
import PropTypes from 'prop-types';
import { slugify } from 'client-lib/src/lib/utils/helpers';
import styled from 'styled-components';
import i18n from 'i18n-js';
import { EntityCard, SquareBadge } from '../../..';
import THEMES from '../../../../styles/themes/library/textInput';
import { entityCardSuggestionCustomStyles } from '../UniversalSuggestion';

const GroupNameAndBadgeWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
  position: relative;

  && div {
    position: absolute;
    top: 11px;
    right: 0;
  }
`;

const GroupSuggestion = ({
  suggestion,
  isHighlighted,
  includeGroupIndicator,
}) => {
  const groupIndicatorJsx = (
    <GroupNameAndBadgeWrapper>
      {suggestion.name}
      <SquareBadge>
        {i18n.t('threads-ThreadsListColumnHeader-group')}
      </SquareBadge>
    </GroupNameAndBadgeWrapper>
  );

  const subtext =
    suggestion?.member_count > 1
      ? i18n.t('slideouts-GroupMessageRecipients-members', {
          number: suggestion?.member_count,
        })
      : i18n.t('slideouts-GroupMessageRecipients-member', {
          number: suggestion?.member_count,
        });

  return (
    <EntityCard
      avatarChildren={suggestion.name}
      maintext={includeGroupIndicator ? groupIndicatorJsx : suggestion.name}
      subtext={subtext}
      avatarProps={{
        size: 'md',
        type: 'internal',
        icon: 'group',
      }}
      backgroundColor={
        isHighlighted
          ? THEMES.OPTION_BACKGROUND_HOVER
          : THEMES.OPTION_BACKGROUND
      }
      maintextColor={
        isHighlighted ? THEMES.OPTION_TEXT_HOVER : THEMES.OPTION_TEXT
      }
      customContainerStyle={entityCardSuggestionCustomStyles}
      dataTestId={`infinite-group-suggestion-${slugify(suggestion.name, {
        lower: true,
      })}`}
    />
  );
};

GroupSuggestion.propTypes = {
  suggestion: PropTypes.object.isRequired,
  isHighlighted: PropTypes.bool,
  includeGroupIndicator: PropTypes.bool,
};

GroupSuggestion.defaultProps = {
  isHighlighted: false,
  includeGroupIndicator: false,
};

export default GroupSuggestion;
