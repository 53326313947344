/**
 * @typedef {string} SMSStatus
 * @enum {SMSStatus}
 */
export const SMS_STATUS = {
  landline: 'SUSPECTED_LANDLINE',
  not_in_service: 'NOT_IN_SERVICE',
};

/**
 * Contact that has at least these values to determine thier SMS status
 * @typedef {object} ContactSMSSettings
 * @property {boolean} announcementsOptOut
 * @property {boolean} promotionalBroadcastOptOut
 * @property {SMSStatus[]} smsStatus
 */

/**
 * Group object for user
 * @typedef {object} Group
 * @property {string} id
 * @property {string} name
 * @property {string} accountId
 * @property {string} timezone
 * @property {boolean} awayMessageEnabled
 * @property {string} awayMessageTemplate
 * @property {string} awayMessageText
 * @property {number} autoReturnThreadsDelayMins
 * @property {boolean} autoReturnThreadsEnabled
 */
