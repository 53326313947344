/* eslint-disable */
 export const gmailRegex = /[\n]*On [a-zA-Z]{3},\s[a-zA-Z]{3}\s(0?[1-9]|[12][0-9]|3[01]),\s[0-9]+\sat [0-9]+:[0-9]+\s?[a-zA-Z]M \s?[a-zA-Z]*\n?\s?[a-zA-Z\s]*\n?\s?<\n?\s*[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?(?:<*\n?\s*mailto:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?>)?>\s?[\n]?wrote:/i;
 export const frenchGmailRegex = /[\n]*Le [a-zA-Z\u00C0-\u00FF]+\.\s(0?[1-9]|[12][0-9]|3[01])\s[a-zA-Z\u00C0-\u00FF]+\.\s[0-9]+\sà\s(0?[0-9]|1[0-9]|2[0-3]):[0-9]+,\s[a-zA-Z\u00C0-\u00FF]*\n?\s?[a-zA-Z\u00C0-\u00FF]*\n?\s?<\n?\s*[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?(?:<*\n?\s*mailto:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?>)?>\sa\sécrit\s:/i;
 export const spanishGmailRegex = /[\n]*El [a-zA-Z\u00C0-\u00FF]+, (0?[1-9]|[12][0-9]|3[01])\s[a-zA-Z\u00C0-\u00FF]+\s[0-9]+\sa\s[a-zA-Z]+\s(0?[0-9]|1[0-9]|2[0-3]):[0-9]+,*(\s*([a-zA-Z\u00C0-\u00FF]*\s*)*)\(<\n?\s*[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?(?:<*\n?\s*mailto:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?>)?>\)\sescribió:/i;
 export const outlookRegex = /[\n]*From: [a-zA-Z]*\s?[a-zA-Z]*\s?<[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?>\s?[\n]*Sent:/i;
 export const urlRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=\*]*)$/;
 export const urlWithoutHttpRegex = /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/
 // https://uibakery.io/regex-library/url
 /* eslint-enable */

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
export const blockquoteRegex =
  /<\s*blockquote\b[^>]*>(.*?)<\s*\/\s*blockquote\s*>/i;
export const divAppendOnSendRegex = /<\s*div\s+[^>]*?\s*id="appendonsend".*?/i;
export const boldFromSentRegex =
  /<p[^>]*?>[^>]*?<b[^>]*?>(From:+?)<\/b>(.*?)Sent:/s;
