import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import THEMES from '../../styles/themes/app';
import IconButton from '../Button/IconButton';

const TopEyeCon = styled.i`
  color: ${(props) =>
    props.sortType === 'ASC' && props.orderBy === props.orderKey
      ? THEMES.THEME_YELLOW(props)
      : THEMES.COLORED_FOREGROUND_HIGH(props)};
  height: 7px;
  font-size: 28px;
`;

const BottomEyeCon = styled.i`
  color: ${(props) =>
    props.sortType === 'DESC' && props.orderBy === props.orderKey
      ? THEMES.THEME_YELLOW(props)
      : THEMES.COLORED_FOREGROUND_HIGH(props)};
  font-size: 28px;
`;

const iconButtonCustomStyle = () => `
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
  &:hover {
    cursor: pointer;
  }
`;

const SortButton = ({ onClick, sortType, orderBy, orderKey }) => {
  return (
    <IconButton onClick={onClick} customStyle={iconButtonCustomStyle}>
      <TopEyeCon
        sortType={sortType}
        orderBy={orderBy}
        orderKey={orderKey}
        className="ri-arrow-up-s-fill"
      />
      <BottomEyeCon
        sortType={sortType}
        orderBy={orderBy}
        orderKey={orderKey}
        className="ri-arrow-down-s-fill"
      />
    </IconButton>
  );
};

SortButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  sortType: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  orderKey: PropTypes.string.isRequired,
};

export default SortButton;
