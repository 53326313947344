import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import usePaginated from 'client-lib/src/lib/api/query/usePaginated';
import i18n from 'i18n-js';
import { useQuery, useApolloClient } from '@apollo/client';
import {
  TEMPLATES_QUERY,
  useUserGroups,
  FAVORITE_TEMPLATES_QUERY,
} from 'client-lib';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import theme from 'styled-theming';
import InfiniteScroll from '../InfiniteScroll/InfiniteScroll';
import THEMES from '../../styles/themes/app';
import { Text, Heading5, EmphasisText, Loading, Button } from '../../elements';

const ROW_HEIGHT = theme('fontSize', {
  default: 57,
  large: 77,
});

const LINE_HEIGHT = theme('fontSize', {
  default: '16px',
  large: '22px',
});

const ModalWrapper = styled.div`
  z-index: 5;
  position: ${(props) => (props?.isFixed ? 'fixed' : 'absolute')};
  background: rgb(255, 255, 255);
  border: 0px;
  width: 600px;
  max-width: 600px;
  ${(props) => props?.isFixed && `top: ${props?.top ? props.top : '0'};`}
  ${(props) =>
    !props?.isFixed && `bottom: ${props?.bottom ? props.bottom : '45px'};`}
  box-shadow: ${THEMES.BOX_SHADOW};
  border-radius: 4px;
  overflow: hidden;
  background-color: ${THEMES.BACKGROUND_PRIMARY};
`;

const Header = styled.div`
  height: 24px;
  background-color: ${THEMES.BACKGROUND_SECONDARY};
  display: flex;
  align-items: center;
`;

const TemplateEntity = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${THEMES.BORDER_COLOR};
  height: ${ROW_HEIGHT}px;
  cursor: pointer;
`;

const TemplateEntityContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 0 12px 16px;
  line-height: ${LINE_HEIGHT};
  justify-content: center;
`;

const TemplateEntityButton = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 0 12px 0px;
  line-height: ${LINE_HEIGHT};
  justify-content: center;
`;

const HeadingWrapper = styled.div`
  margin-left: 16px;
`;
const OverflowWrapper = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 538px;
`;

const AttachmentIcon = styled.i`
  font-size: 16px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 16px;
`;

const Center = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TemplateEntityTitle = styled(EmphasisText)`
  display: inline;
`;

const TemplateEntityCard = ({
  template,
  selectTemplate,
  onRequestClose,
  addAttachment,
  addLink,
  removeAttachment,
}) => {
  const templateName = template?.name;
  const message = template?.message;

  const handleSelectTemplate = () => {
    if (template?.attachments?.length) {
      addAttachment(template?.attachments[0]);
    } else if (removeAttachment) {
      addAttachment(null);
    }

    if (template?.link && addLink) {
      addLink(template?.link);
    }

    selectTemplate(message);
    onRequestClose();
  };

  return (
    <TemplateEntity onClick={handleSelectTemplate}>
      <TemplateEntityContent>
        <OverflowWrapper>
          <TemplateEntityTitle>{templateName}</TemplateEntityTitle>
        </OverflowWrapper>
        <OverflowWrapper>
          <Text customStyle={() => 'display: contents;'}>{message}</Text>
        </OverflowWrapper>
      </TemplateEntityContent>
      <IconWrapper>
        {template?.attachments?.length > 0 && (
          <AttachmentIcon className="ri-attachment-2" />
        )}
      </IconWrapper>
    </TemplateEntity>
  );
};

TemplateEntityCard.propTypes = {
  template: PropTypes.object.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  addAttachment: PropTypes.func.isRequired,
  addLink: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,
  removeAttachment: PropTypes.bool,
};

TemplateEntityCard.defaultProps = {
  addLink: null,
  removeAttachment: false,
};

const FavoriteTemplates = ({
  favoriteTemplates,
  handleSelectTemplate,
  addAttachment,
  addLink,
  onRequestClose,
  setShowFavoriteTemplates,
}) => {
  return (
    <>
      {favoriteTemplates.map((favTemplate, index) => (
        <TemplateEntityCard
          key={favTemplate?.id || index}
          template={favTemplate}
          selectTemplate={handleSelectTemplate}
          addAttachment={addAttachment}
          addLink={addLink}
          onRequestClose={onRequestClose}
        />
      ))}
      <TemplateEntity>
        <TemplateEntityButton>
          <Button
            type="link"
            onClick={() => setShowFavoriteTemplates(false)}
            noOutline
          >
            {i18n.t('modals-TemplateModal-showAll', {
              defaultValue: 'Show All Templates',
            })}
          </Button>
        </TemplateEntityButton>
      </TemplateEntity>
    </>
  );
};

FavoriteTemplates.propTypes = {
  favoriteTemplates: PropTypes.array.isRequired,
  handleSelectTemplate: PropTypes.func.isRequired,
  addAttachment: PropTypes.func.isRequired,
  addLink: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,
  setShowFavoriteTemplates: PropTypes.func.isRequired,
};

FavoriteTemplates.defaultProps = {
  addLink: null,
};

const TemplateModal = ({
  isOpen,
  onRequestClose,
  top,
  bottom,
  contactId,
  senderGroupId,
  selectTemplate,
  selectTemplateWithKeys,
  channelType,
  addAttachment,
  addLink,
  titleOverride,
  isFixed,
  bodyHeight,
  removeAttachment,
}) => {
  const modalRef = useRef();
  const client = useApolloClient();
  const [showFavoriteTemplates, setShowFavoriteTemplates] = useState(true);
  const allGroups = useSelector((state) => state?.accountData?.allGroups);
  const currentUser = useSelector((state) => state?.session?.currentUser);
  const accountName = useSelector((state) => state?.accountData?.account?.name);

  const { groups } = useUserGroups(currentUser, allGroups);
  const groupIds = groups?.map((group) => group.id);
  const senderGroupName =
    groups?.find((group) => group.id === senderGroupId)?.name || '';

  const styledTheme = useContext(ThemeContext);

  const {
    data: favoriteTemplatesData,
    loading: favQueryLoading,
    refetch,
  } = useQuery(FAVORITE_TEMPLATES_QUERY, {
    client,
    variables: {
      userId: currentUser?.userId,
      tokenInfo: {
        contactId: contactId || null,
        senderGroupId,
        defaultTokenValues: '{}',
      },
    },
  });

  const { handleFetchMore, pageInfo, templates, loading } = usePaginated({
    client,
    query: TEMPLATES_QUERY,
    queryVariables: {
      groupIds,
      contactId: contactId || null,
      senderGroupId,
      channelTypeFilter: [channelType],
      relayStylePagination: true,
      // defaultTokenValues,
    },
    resultsNumber: 10,
    key: 'templates',
    policy: 'network-only',
  });

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (isOpen) {
      setShowFavoriteTemplates(true);
    }
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = ({ target }) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(target) &&
        !target.classList.contains('ri-file-list-3-line')
      ) {
        onRequestClose();
        setShowFavoriteTemplates(true);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalRef]);

  const favoriteTemplates =
    favoriteTemplatesData?.favoriteTemplates?.favoriteTemplates;

  const handleSelectTemplate = (message) => {
    if (selectTemplateWithKeys) return selectTemplate(message);

    return selectTemplate(
      message
        .replaceAll('{FIRST_NAME}', '')
        .replaceAll('{LAST_NAME}', '')
        .replaceAll('{COMPANY_NAME}', '')
        .replaceAll('{ACCOUNT_NAME}', accountName)
        .replaceAll('{GROUP_NAME}', senderGroupName)
        .replaceAll('{AMOUNT_DUE}', '')
        .replaceAll('{INVOICE_NUMBER}', '')
    );
  };

  const handleClose = () => {
    onRequestClose();
    setShowFavoriteTemplates(true);
  };

  const title = useMemo(() => {
    if (titleOverride) {
      return titleOverride;
    }

    return showFavoriteTemplates && favoriteTemplates?.length
      ? i18n.t('modals-TemplateModal-favoriteTemplates', {
          defaultValue: 'Favorite Templates',
        })
      : i18n.t('modals-TemplateModal-templates', {
          defaultValue: 'All Templates',
        });
  }, [titleOverride, showFavoriteTemplates, favoriteTemplates?.length]);

  return isOpen ? (
    <ModalWrapper ref={modalRef} top={top} bottom={bottom} isFixed={isFixed}>
      <Header>
        <HeadingWrapper>
          <Heading5>{title}</Heading5>
        </HeadingWrapper>
      </Header>
      {loading && favQueryLoading && !templates?.length ? (
        <Center>
          <Loading />
        </Center>
      ) : showFavoriteTemplates && favoriteTemplates?.length ? (
        <FavoriteTemplates
          favoriteTemplates={favoriteTemplates}
          handleSelectTemplate={handleSelectTemplate}
          addAttachment={addAttachment}
          addLink={addLink}
          onRequestClose={handleClose}
          setShowFavoriteTemplates={setShowFavoriteTemplates}
        />
      ) : (
        <InfiniteScroll
          bidirectionalScroll={false}
          scrollableList={templates}
          height={bodyHeight}
          noRowsElement={<div />}
          renderRow={({ list, index }) => (
            <TemplateEntityCard
              template={list[index]}
              selectTemplate={handleSelectTemplate}
              addAttachment={addAttachment}
              addLink={addLink}
              onRequestClose={handleClose}
              removeAttachment={removeAttachment}
            />
          )}
          loading={false}
          hasNextPage={pageInfo?.hasNextPage}
          loadingBorderBottom={false}
          loadingHeight={ROW_HEIGHT({ theme: styledTheme })}
          listItemHeight={ROW_HEIGHT({ theme: styledTheme })}
          loadMoreRows={handleFetchMore}
        />
      )}
    </ModalWrapper>
  ) : null;
};

TemplateModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  top: PropTypes.string,
  bottom: PropTypes.string,
  contactId: PropTypes.string,
  senderGroupId: PropTypes.string.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectTemplateWithKeys: PropTypes.bool,
  channelType: PropTypes.string.isRequired,
  addAttachment: PropTypes.func,
  addLink: PropTypes.func,
  titleOverride: PropTypes.string,
  isFixed: PropTypes.bool,
  bodyHeight: PropTypes.string,
  removeAttachment: PropTypes.bool,
};

TemplateModal.defaultProps = {
  top: '',
  bottom: '',
  contactId: '',
  selectTemplateWithKeys: false,
  addAttachment: () => {},
  addLink: null,
  titleOverride: '',
  isFixed: false,
  bodyHeight: '356px',
  removeAttachment: false,
};

export default TemplateModal;
