import React, { type ReactNode } from 'react';
import i18n from 'i18n-js';
import styled, { css } from 'styled-components';

import THEMES from '../../../styles/themes/app';
import { Heading2 } from '../../../elements';

const commonStylesRelative = css`
  position: relative;
  width: 100%;
`;

const commonStylesFixed = css`
  position: fixed;
  width: calc(100% - 72px);
`;

const CommonLayout = styled.div`
  display: flex;
  z-index: 1;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  overflow: auto;
  background-color: ${THEMES.BACKGROUND_SECONDARY};

  &,
  * {
    box-sizing: border-box;
  }
`;

const Header = styled(CommonLayout)<{ isFixed: boolean }>`
  ${({ isFixed }) => (isFixed ? commonStylesFixed : commonStylesRelative)}
  justify-content: space-between;
  align-items: center;
  top: 0;
  min-height: 80px;
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  border-bottom: 1px solid ${THEMES.BORDER_COLOR};
`;

const Body = styled(CommonLayout)<{ isFixed: boolean }>`
  ${({ isFixed }) => (isFixed ? commonStylesFixed : commonStylesRelative)}
  flex-direction: column;
  align-items: center;
  flex: 1;
  top: ${({ isFixed }) => (isFixed ? '80px' : '0')};
  bottom: ${({ isFixed }) => (isFixed ? '80px' : '0')};
  padding: 2rem;
  ${({ isFixed }) => isFixed && 'overflow: auto;'}
`;

const Footer = styled(CommonLayout)<{ isFixed: boolean }>`
  ${({ isFixed }) => (isFixed ? commonStylesFixed : commonStylesRelative)}
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  min-height: 80px;
  padding: 0 24px;
  border-top: 1px solid ${THEMES.BORDER_COLOR};
  background-color: ${THEMES.SIDEBAR_BACKGROUND};
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  overflow: hidden;
`;

export interface PageComposeProps {
  children: ReactNode;
  translationKey: string;
  translationFallback?: string;
  customHeader?: ReactNode;
  customFooter?: ReactNode;
  footerContent?: ReactNode;
  isFixed?: boolean;
}

const PageCompose = ({
  children,
  translationKey,
  translationFallback,
  customHeader,
  customFooter,
  footerContent,
  isFixed = false,
}: PageComposeProps): JSX.Element => {
  return (
    <Container>
      <Header isFixed={isFixed}>
        <Title>
          {customHeader || (
            <span data-testid="page-compose-title">
              <Heading2>
                {i18n.t(
                  translationKey,
                  translationFallback
                    ? { defaultValue: translationFallback }
                    : undefined
                )}
              </Heading2>
            </span>
          )}
        </Title>
      </Header>
      <Body isFixed={isFixed}>{children}</Body>
      {customFooter || null}
      {footerContent && !customFooter ? (
        <Footer isFixed={isFixed}>{footerContent}</Footer>
      ) : null}
    </Container>
  );
};

export default PageCompose;
