import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import styled from 'styled-components';
import { useCustomerEmailEdit } from 'client-lib';
import { contactName } from 'client-lib/src/lib/utils/helpers';
import { useApolloClient } from '@apollo/client';
import { Modal, Text, TextInput } from '../../elements';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Subtitle = styled.div`
  margin-top: -24px;
  margin-bottom: 16px;
`;

const AddEmailAddress = ({ open, onClose, contact, onSave, onError }) => {
  const client = useApolloClient();

  const [email, onSubmit] = useCustomerEmailEdit({
    client,
    onSave,
    onError,
    customer: contact,
    i18n,
  });

  return (
    <Modal
      isOpen={open}
      size="sm"
      onRequestClose={onClose}
      primaryButtonOnClick={onSubmit}
      modalTitle={i18n.t('modals-AddEmailAddress-addEmail', {
        defaultValue: 'Add Email Address',
      })}
      primaryButtonText={i18n.t('modals-AddMember-save')}
      secondaryButtonText={i18n.t('modals-AddMember-cancel')}
      primaryButtonProps={{ disabled: !email.value || email.error }}
      secondaryButtonOnClick={onClose}
    >
      <ModalContent>
        <Subtitle>
          <Text>{contactName(contact)}</Text>
        </Subtitle>
        <TextInput
          label={i18n.t('slideouts-CustomerInfo-email')}
          placeholder={i18n.t('modals-AddEmailAddress-enterEmail', {
            defaultValue: 'Enter Email Address',
          })}
          value={email.value}
          onChange={(e) => email.setValue(e.target.value)}
          error={email?.error}
        />
      </ModalContent>
    </Modal>
  );
};

AddEmailAddress.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  contact: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default AddEmailAddress;
