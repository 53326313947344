import { useBannersQuery } from 'client-lib';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { setBanners } from '../../actions/general';

const useOnLoadQueryBannersData = () => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const currentUserId = useSelector(
    (state) => state.session.currentUser?.userId
  );

  // grab any banners that we need to display for user, send to redux
  const { data: bannersData } = useBannersQuery({
    client,
    skip: !currentUserId,
  });
  useEffect(() => {
    if (bannersData?.listBanners?.length) {
      dispatch(setBanners(bannersData.listBanners));
    }
  }, [bannersData?.listBanners?.length]);
};

export default useOnLoadQueryBannersData;
