import { useEffect, useState } from "react";
import debounce from "lodash/debounce";
import { useQuery } from "@apollo/client";
import useStartThreadMutation from "../../../api/mutations/useStartThreadMutation";
import useUploadAttachment from "../attachment/useUploadAttachment";
import CHANNELS_QUERY from "../../../graphql/query/ChannelsQuery.graphql";

/*

DOCUMENTATION
  this hook is created to be used with CreateThread

  args:
    client: (obj) required. Apollo Client instance.
    threadsActiveGroupIds: (arr) required. the id of the users active group (from where the text/thread will be sent).
    onMutationStart: (func) optional. will be called before mutation fires.
    onMutationSuccess: (func) optional. will be called on mutation success.
    onMutationError: (func) optional. will be called on mutation error.
    groups: (arr) required. Groups array that comes from the state manager.

  returns:
    handleMutationStartThread: (func) mutation to start new thread.
    contact: (obj) state. holds currently selected contact (who the new thread will be sent to)
    setContact: (func) updates contact state.
    contactPhoneNumber: (str) state. used in the case of texting a number directly, as opposed to selecting a pre-known contact.
    setContactPhoneNumber: (func) updates contactPhoneNumber.
    senderGroup: (obj) state. this controlls who the "from" group will be, if the user has more than one group as active.
    setSenderGroup: (func) updates senderGroup.
    groupOptions: (obj) these are the groups that the user has selected as active, they have been restructured to { value, label }. Meant to be used with "select" elements.
    messageInputValue: (str) state. The message that will start the thread.
    setMessageInputValue: (func) updates messageInputValue state.
    attachments: (arr) state. holds any attachment that will be sent with the new thread.
    setAttachments: (func) updates attachment state.

*/

const useCreateThread = ({
  client,
  currentUser,
  threadsActiveGroupIds,
  onMutationStart = () => {},
  onMutationSuccess = () => {},
  onMutationError = () => {},
  initialThread = null,
  onError = () => {}, // more generic, meant to communicate with user.
  allGroups,
}) => {
  const [handleStartThread] = useStartThreadMutation({ client });

  const { data: channelsData } = useQuery(CHANNELS_QUERY, {
    client,
    variables: {
      accountId: currentUser?.accountId,
    },
  });

  const groupOptions = allGroups
    ? allGroups
        .filter((group) =>
          threadsActiveGroupIds.some((activeId) => activeId === group.id)
        )
        .filter((group) => {
          return channelsData?.channels?.some((channel) => {
            if (channel.group.id === group.id && channel.smsConfig !== null) {
              return true;
            }
            return false;
          });
        })
        ?.map((group) => ({ value: group.id, label: group.name }))
    : [];

  const bandwidthSmsChannels = channelsData?.channels?.filter(
    (channel) =>
      channel?.smsConfig?.smsCarrier === "BANDWIDTH" &&
      channel?.type === "conversations"
  );

  const groupOptionsWithBandwidth = groupOptions?.filter((option) =>
    bandwidthSmsChannels?.some((channel) => {
      if (channel.group.id === option.value) {
        return true;
      }
      return false;
    })
  );

  // Id value of contact that new thread is being sent to
  const [contact, setContact] = useState(
    initialThread ? initialThread.contact : null
  );

  const [contactPhoneNumber, setContactPhoneNumber] = useState(
    initialThread ? initialThread.contactPhoneNumber : ""
  );

  const [additionalRecipients, setAdditionalRecipients] = useState(
    initialThread ? initialThread.additionalRecipients : []
  );

  const defaultSenderGroup = groupOptions[0];

  const [senderGroup, setSenderGroup] = useState(
    initialThread?.senderGroup ? initialThread.senderGroup : defaultSenderGroup
  );

  const [tags, setTags] = useState([]);

  // The first message sent with new thread
  const [messageInputValue, setMessageInputValue] = useState(
    initialThread ? initialThread.messageInputValue : ""
  );

  const [attachments, setAttachments] = useState(
    initialThread ? initialThread.attachments : []
  );
  const [sendAttachmentAsLink, setSendAttachmentAsLink] = useState(false);

  const deleteAttachment = (id) => {
    const newAttachments = [...attachments].filter(
      (attachment) => attachment?.id !== id
    );
    setAttachments(newAttachments);
  };

  const uploadAttachment = useUploadAttachment({ client });

  useEffect(() => {
    if (!initialThread?.senderGroup) setSenderGroup(defaultSenderGroup);
  }, [groupOptions.length]);

  useEffect(() => {
    if (contact?.__typename === "User" || contact?.__typename === "Group") {
      setSenderGroup({ value: "", label: "" });
    } else if (senderGroup?.value === "") {
      setSenderGroup(defaultSenderGroup);
    }
  }, [contact?.__typename]);

  const handleMutationStartThread = debounce(
    async () => {
      onMutationStart();

      let messageAttachments = [];

      if (attachments.length) {
        const settledResponse = await Promise.allSettled(
          attachments.map((attachment) => uploadAttachment(attachment))
        );

        settledResponse.forEach((resp) => {
          if (resp.status === "rejected") onError(resp.reason);
        });

        messageAttachments = settledResponse
          .filter((resp) => resp.status === "fulfilled")
          .map((resp) => ({
            url: resp.value.url,
            method: sendAttachmentAsLink ? "LINK" : "EMBED",
          }));
      }

      const startThreadArgs = {
        message: {
          text: messageInputValue,
          attachments: messageAttachments,
        },
        tags: tags || null,
        onSuccess: onMutationSuccess,
        onError: onMutationError,
        groupTextRecipients: {},
      };

      if (tags.length !== 0) {
        startThreadArgs.tags = tags.map((tag) => tag.referenceNumber);
      }

      const additionalRecipientIds = [];
      const unknownExternalRecipients = [];
      if (additionalRecipients?.length >= 1) {
        additionalRecipients.forEach((recipient) => {
          if (recipient.id) {
            additionalRecipientIds.push(recipient.id);
          }
          if (!recipient.id && recipient.phoneNumber) {
            unknownExternalRecipients.push(
              recipient.phoneNumber.replace(/[^0-9]/g, "")
            );
          }
        });
      }

      if (contact?.__typename === "CustomerContact") {
        startThreadArgs.senderGroupId =
          threadsActiveGroupIds.length > 1
            ? senderGroup?.value
            : defaultSenderGroup?.value;
        startThreadArgs.externalContactId =
          contact && contact.id ? contact.id : null;
        startThreadArgs.externalPhoneNumber = contactPhoneNumber;
        startThreadArgs.groupTextRecipients.externalContactIds =
          additionalRecipientIds;
        startThreadArgs.groupTextRecipients.unknownExternalRecipients =
          unknownExternalRecipients;
      } else if (contact?.__typename === "User") {
        startThreadArgs.userShareIds = [contact.id, currentUser.userId];
      } else if (contact?.__typename === "Group") {
        startThreadArgs.groupShareIds = [contact.id];
        startThreadArgs.userShareIds = [currentUser.userId];
      } else if (!contact && contactPhoneNumber) {
        startThreadArgs.senderGroupId =
          threadsActiveGroupIds.length > 1
            ? senderGroup?.value
            : defaultSenderGroup?.value;
        startThreadArgs.externalPhoneNumber = contactPhoneNumber.replace(
          /[^0-9]/g,
          ""
        );
      }

      handleStartThread(startThreadArgs);
    },
    1000,
    { leading: true }
  );

  return {
    handleMutationStartThread,
    contact,
    setContact,
    contactPhoneNumber,
    setContactPhoneNumber,
    senderGroup,
    setSenderGroup,
    groupOptions,
    tags,
    setTags,
    messageInputValue,
    setMessageInputValue,
    attachments,
    setAttachments,
    deleteAttachment,
    sendAttachmentAsLink,
    setSendAttachmentAsLink,
    additionalRecipients,
    setAdditionalRecipients,
    groupOptionsWithBandwidth,
  };
};

export default useCreateThread;
