import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';
import { useSelector } from 'react-redux';
import {
  AVAILABLE_PERMISSIONS,
  checkIfCurrentUserHasPermission,
  REGIONS_AND_GROUPS,
} from 'client-lib';
import i18n from 'i18n-js';
import useLazyPaginated from 'client-lib/src/lib/api/query/useLazyPaginated';
import CHIP_THEMES from '../../styles/themes/library/chip';
import APP_THEMES from '../../styles/themes/app';
import GreenChipAsyncSelect from '../../elements/Select/GreenChipAsyncSelect';
import useGetUserAccountPolicies from '../../hooks/customer/useGetUserAccountPolicies';

const GroupRegionAsyncSelect = ({
  value,
  setValue,
  error,
  omitAll,
  permissionFilter = AVAILABLE_PERMISSIONS.MANAGE_TEAM,
  ...props
}) => {
  const client = useApolloClient();

  const [greenIndexes, setGreenIndexes] = useState([]);

  useEffect(() => {
    const regionIndexes = [];
    value?.forEach((val, index) => {
      if (val.typename === 'Region') {
        regionIndexes.push(index);
      }
    });
    if (greenIndexes !== regionIndexes) {
      setGreenIndexes(regionIndexes);
    }
  }, [value]);

  const { triggerQuery, handleFetchMore, pageInfo } = useLazyPaginated({
    client,
    query: REGIONS_AND_GROUPS,
    key: 'regionsAndGroups',
    resultsNumber: 25,
    queryVariables: {
      filter: value,
    },
  });

  const initialQuery = (inputVal) => {
    return triggerQuery({
      variables: {
        filter: inputVal,
        first: 25,
      },
    });
  };

  const renderGreenRegionBadge = (greenIndexes, props) => {
    let css = '';
    greenIndexes?.forEach((ind) => {
      css += `& .Select__multi-value:nth-of-type(${ind + 1}) {
      background-color: ${CHIP_THEMES.REGION_BACKGROUND(props)};
      color: ${APP_THEMES.FOREGROUND_MED(props)};
    }`;
    });
    return css;
  };
  const [queryError, setQueryError] = useState('');

  const currentUser = useSelector((state) => state?.session?.currentUser);

  useGetUserAccountPolicies({
    actionList: [permissionFilter],
    userId: currentUser?.userId,
  });

  // only give options based on permissions :)
  const generateOptions = (groupsAndRegions) => {
    let groupsAndRegionsAvailable = [];

    const canManageTeam = checkIfCurrentUserHasPermission(
      permissionFilter,
      currentUser.accountPolicies,
      currentUser.groupPolicies
    );

    if (canManageTeam) {
      const generatedAvailableGroupsAndRegions = groupsAndRegions
        .map(({ id, name, __typename }) => {
          return {
            label: name,
            value: id,
            typename: __typename,
          };
        })
        .sort((a, b) => ('' + a.label).localeCompare(b.label));

      groupsAndRegionsAvailable = [...generatedAvailableGroupsAndRegions];
    }

    return groupsAndRegionsAvailable;
  };

  const fetchMore = () => {
    return handleFetchMore();
  };

  const loadOptions = async (inputVal, prevOptions, additional) => {
    let response;
    if (prevOptions?.length && additional?.prevInputVal === inputVal) {
      response = await fetchMore(inputVal, prevOptions);
    } else {
      response = await initialQuery(inputVal);
    }

    const { data, error } = response;

    if (error) {
      setQueryError('error');
      return { options: [], hasMore: false };
    }

    const hasMore = data?.regionsAndGroups?.pageInfo?.hasNextPage;

    const allGroupsAndRegions = data?.regionsAndGroups.edges.map(
      (regionAndGroup) => {
        return regionAndGroup.node;
      }
    );

    const permissionGroups = generateOptions(allGroupsAndRegions);

    return {
      options: permissionGroups,
      hasMore,
      additional: { prevInputVal: inputVal },
    };
  };

  return (
    <GreenChipAsyncSelect
      options={[
        {
          label: i18n.t('settings-manageRules-allGroups', {
            defaultValue: 'All',
          }),
          value: 'all',
        },
      ]}
      value={value}
      onChange={(selectedOption) => setValue(selectedOption)}
      loadOptions={loadOptions}
      error={queryError || error}
      dataTestId="group-async-select"
      hasMore={pageInfo.hasNextPage}
      customSelectedStyle={(props) =>
        renderGreenRegionBadge(greenIndexes, props)
      }
      {...props}
    />
  );
};

GroupRegionAsyncSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  setValue: PropTypes.func.isRequired,
  error: PropTypes.string,
  omitAll: PropTypes.bool,
  permissionFilter: PropTypes.string,
};

GroupRegionAsyncSelect.defaultProps = {
  error: '',
  omitAll: false,
  permissionFilter: AVAILABLE_PERMISSIONS.MANAGE_TEAM,
};

export default GroupRegionAsyncSelect;
