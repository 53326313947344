import { thread_utils } from 'client-lib';
import { useEffect } from 'react';
import i18n from 'i18n-js';
import { useDispatch, useSelector } from 'react-redux';
import {
  contactName,
  internalThreadExternalUserName,
} from 'client-lib/src/lib/utils/helpers';
import { resetActiveThread } from '../../actions';
import { openSnackbar } from '../../actions/general';

const useWatchClosingThreads = () => {
  const dispatch = useDispatch();
  const activeThreadId = useSelector(
    (state) => state.threadInfo.activeThreadId
  );

  const currentUser = useSelector(
    (state) => state.session.currentUser,
    (currentUser, prev) => currentUser?.userId === prev?.userId
  );

  const latestClosedThread = useSelector(
    (state) => state.threadInfo.latestClosedThread,
    (latestClosedThread, prev) => latestClosedThread?.id === prev?.id
  );

  useEffect(() => {
    // if there's been a closed thread that's equal to the storedActivThreadId, clear that value
    // so that the user doesn't return to a "closed" thread if they click on the "open" tab.
    if (latestClosedThread?.id && latestClosedThread?.id === activeThreadId) {
      dispatch(resetActiveThread());
    }

    if (
      latestClosedThread?.id &&
      thread_utils.isHeldByCurrentUser(latestClosedThread, currentUser)
    ) {
      if (thread_utils.isTypeInternal(latestClosedThread)) {
        const externalUsersName = internalThreadExternalUserName(
          latestClosedThread?.userShares,
          currentUser?.userId
        );

        // in the case that an internal thread shared with inbox is closed before somebody claims it,
        // we won't have a name to display in the snack message so we just say "Conversation has ended"
        const snackMessage = externalUsersName
          ? i18n.t('threads-GlobalHandlers-internalClosedMessage', {
              name: externalUsersName,
            })
          : i18n.t('threads-GlobalHandlers-internalClosedMessageNoName');

        dispatch(openSnackbar(snackMessage));
      } else if (
        thread_utils.isTypeFax(latestClosedThread) &&
        !latestClosedThread.archivedAt === null
      ) {
        dispatch(
          openSnackbar(
            i18n.t('threads-GlobalHandlers-externalFaxClosedMessage', {
              name: contactName(latestClosedThread?.externalContact),
              defaultValue:
                'Thread with %{name} has been acknowledged and closed.',
            })
          )
        );
      } else if (thread_utils.isTypeWebchat(latestClosedThread)) {
        const closedThreadRoute = `/threads/closed/${latestClosedThread.id}`;
        const snackMessage = i18n.t(
          'threads-GlobalHandlers-internalClosedThreadNoName'
        );
        openSnackbar(
          dispatch(
            openSnackbar(snackMessage, 'success', true, closedThreadRoute)
          )
        );
      } else {
        dispatch(
          openSnackbar(
            i18n.t('threads-GlobalHandlers-externalClosedMessage', {
              name: contactName(latestClosedThread?.externalContact),
            })
          )
        );
      }
    }
  }, [latestClosedThread?.id]);
};

export default useWatchClosingThreads;
