import {
  SET_ACCOUNT,
  SET_GROUPS,
  SET_ACTIVE_EMAIL_CHANNELS,
  SET_GROUPS_REFETCH_KEY,
  REFETCH_SETTINGS_USERS,
} from '../actions/accountData';

const DEFAULT_STATE = {
  account: {},
  allGroups: [],
  activeEmailChannels: [],
  groupsRefetchKey: '',
  refetchSettingsUsers: false,
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_ACCOUNT:
      return {
        ...state,
        account: action.account,
      };

    case SET_GROUPS:
      return {
        ...state,
        allGroups: action.allGroups,
      };

    case SET_ACTIVE_EMAIL_CHANNELS:
      return {
        ...state,
        activeEmailChannels: action.activeEmailChannels,
      };

    case SET_GROUPS_REFETCH_KEY:
      return {
        ...state,
        groupsRefetchKey: action.groupsRefetchKey,
      };
    case REFETCH_SETTINGS_USERS:
      return {
        ...state,
        refetchSettingsUsers: action.refetchSettingsUsers,
      };

    default:
      return state;
  }
}
