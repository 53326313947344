import { type LexicalEditor } from 'lexical';
import { InsertType } from './constants';
import lexicalDeserialization from './lexicalDeserialization';

const containsNewLine = (text: string) => /\n/.test(text);
const isTextOnly = (htmlString: string) =>
  !/<\/?[a-z][\s\S]*>/i.test(htmlString);

/**
 * Sanitizes text content to HTML format, converting line breaks to <br> tags
 * and wrapping in <p> tags when needed.
 * If a text only string contains new lines, convert it to html.
 * This prevents Lexical from removing line breaks.
 *
 * @param text Text or text with html tags
 * @returns Text with <br> tags if it contains new lines and is text only or returns the text as it is
 */
const sanitizeToHtml = (text: string) => {
  if (containsNewLine(text) && isTextOnly(text)) {
    // lexicalDeserialization will accept raw text, but when using the lexicalDeserialization,
    // we end up removing line breaks (because html).
    // To account for this, we replace line breaks with <br> tags.
    // Lexical needs <br> to be contained by another tag, so we use <p> around everything.
    return `<p>${text.replace(/\n/g, '<br/>')}</p>`;
  }
  return text;
};

/**
 * Appends an email signature to the editor.
 * (Convience function that uses lexicalDeserialization to append the signature.)
 *
 * @param {LexicalEditor} editor - The LexicalEditor instance.
 * @param {string} emailSignature - The email signature to append.
 */

const appendEmailSignature = (
  editor: LexicalEditor,
  emailSignature: string,
  insertType: InsertType = InsertType.appendNodes
) => {
  const htmlSignature = sanitizeToHtml(emailSignature);

  lexicalDeserialization({
    editor,
    htmlString: htmlSignature,
    insertType,
  });
};

export default appendEmailSignature;
