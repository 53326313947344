import { stringContains } from "../../../utils/helpers";

/*

DOCUMENTATION
  this component was designed to retrieve associated groups of the current user, based on group and account policies.

args:
  client: (obj) required. instance of apolloClient

return:
  allGroups (array): state that holds the current groups.
  getGroups (func): gets new groups, meant to be used as user types.
  setGroups (func): handles onSelect when suggestion is selected.
*/

const useCustomerGroups = ({ threadsActiveGroupIds, allGroups, groupIds }) => {
  let returnGroups = [];

  if (allGroups.length) {
    const populatedGroups = allGroups
      .map((group) => ({
        text: group.name,
        value: group.id,
      }))
      .filter((group) => groupIds?.includes(group.value));
    returnGroups = populatedGroups;
  }

  const getActiveGroup = () =>
    returnGroups.find((group) => group.value === threadsActiveGroupIds?.[0]);

  const getGroupsByValue = (value) => {
    if (allGroups.length) {
      returnGroups = allGroups.filter((group) =>
        stringContains(group.text, value)
      );
    }
  };

  return {
    getGroupsByValue,
    getActiveGroup,
  };
};

export default useCustomerGroups;
