import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import i18n from 'i18n-js';
import { setActiveSidebar } from '../../actions/general';
import useHideSidebarForRoute from '../../utils/hooks/useHideSidebarForRoute';
import THEMES from '../../styles/themes/app';
import { Heading1, Text, EmphasisText, Heading5 } from '../../elements';
import ExternalScreenTemplate from '../../containers/Login/ExternalScreenTemplate';

const NotFoundInfoContainer = styled.div`
  width: 66%;
  max-width: 360px;
`;

const HeaderWrapper = styled.div`
  margin-bottom: 1.5em;
`;

const DroneContainer = styled.div`
  position: absolute;
  left: calc(40vw - 125px);
  width: 300px;
  bottom: 40%;
  z-index: 1;
  @media (max-width: 1200px) {
    left: calc(40vw - 85px);
    width: 200px;
  }
  & img {
    max-height: 100%;
    max-width: 100%;
  }
`;

const ExampleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.5em;
`;

const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2em;
`;

const ContactCard = styled.div`
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  display: flex;
  margin: 0.4em 0;
  height: 40px;
  border-radius: 4px;
  box-shadow: ${THEMES.BOX_SHADOW};
`;
const ContactIcon = styled.div`
  background-color: ${THEMES.THEME_YELLOW};
  flex: 1;
  width: 100%;
  height: 100%;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;

const ContactDetails = styled.div`
  display: flex;
  align-items: center;
  padding-left: 0.5em;
  flex: 8;
`;

const Wrapper = styled.div`
  margin-bottom: 1em;
`;

const NotFound = () => {
  const dispatch = useDispatch();
  const dispatchActiveSidebar = (input) => dispatch(setActiveSidebar(input));
  useHideSidebarForRoute({ dispatchActiveSidebar });
  return (
    <>
      <DroneContainer>
        <img src="./pk-svgs/illustration-drone.svg" alt="drone illustration" />
      </DroneContainer>
      <ExternalScreenTemplate screenType="notFound">
        <NotFoundInfoContainer>
          <HeaderWrapper>
            <Heading1 contrast="colorHigh">
              {i18n.t('signin-NotFound-noPage')}
            </Heading1>
          </HeaderWrapper>
          <Text contrast="colorHigh">
            {i18n.t('signin-NotFound-retypeUrl')}
          </Text>
          <Text contrast="colorHigh">
            {' '}
            {i18n.t('signin-NotFound-reminder')}
          </Text>
          <ExampleWrapper>
            <EmphasisText
              customStyle={(props) =>
                `color: ${THEMES.THEME_YELLOW(
                  props
                )}; text-decoration: underline;`
              }
            >
              {i18n.t('signin-NotFound-companyName')}
            </EmphasisText>
            <Text contrast="colorHigh">.prokeep.com</Text>
          </ExampleWrapper>
          <ExampleWrapper>
            <Text contrast="colorHigh">
              {i18n.t('signin-NotFound-example')}
            </Text>
            <EmphasisText
              customStyle={(props) =>
                `color: ${THEMES.THEME_YELLOW(
                  props
                )}; text-decoration: underline;`
              }
            >
              abcsupply
            </EmphasisText>
            <Text contrast="colorHigh">.prokeep.com</Text>
          </ExampleWrapper>
          <ContactWrapper>
            <Wrapper>
              <EmphasisText contrast="colorHigh">
                {i18n.t('signin-NotFound-help')}
              </EmphasisText>
            </Wrapper>
            <ContactCard>
              <ContactIcon>
                <i className="ri-phone-fill" />
              </ContactIcon>
              <ContactDetails>
                <Heading5>{i18n.t('signin-NotFound-call')}</Heading5>
                <Text>504.226.7756</Text>
              </ContactDetails>
            </ContactCard>
            <ContactCard>
              <ContactIcon>
                <i className="ri-mail-send-fill" />
              </ContactIcon>
              <ContactDetails>
                <Heading5>{i18n.t('signin-NotFound-email')}</Heading5>
                <Text>support@prokeep.com</Text>
              </ContactDetails>
            </ContactCard>
          </ContactWrapper>
        </NotFoundInfoContainer>
      </ExternalScreenTemplate>
    </>
  );
};

export default NotFound;
