import { useQuery } from "@apollo/client";
import CustomerAccountQuery from "../../graphql/query/CustomerAccountQuery.graphql";
import CustomerContactQuery from "../../graphql/query/CustomerContactQuery.graphql";

/*
DOCUMENTATION

this hook is meant to be used for a generic customer query

args:
  client: (obj) required. apolloClient instance
  customerAccountId: (str) optional. customerAccount id of customerAccount that you would like to query.
  customerContactId: (str) optional. customerContact id of customerContact that you would like to query.

returns:
  customerAccountQueryData: (obj) returned data on customerAccount if customerAccountId provided
  customerContactQueryData: (obj) returned data on customerContact if customerContactId provided
  loading: (bool) indicates if loading query data
  error: (Error) will give error if either query error's out
*/

const useCustomerQuery = ({ customerAccountId, customerContactId, client }) => {
  const {
    loading: custAccountLoading,
    error: custAccoutError,
    data: customerAccountQueryData,
  } = useQuery(CustomerAccountQuery, {
    client,
    skip: !customerAccountId,
    variables: { id: customerAccountId },
  });

  const {
    loading: custContactLoading,
    error: custContactError,
    data: customerContactQueryData,
  } = useQuery(CustomerContactQuery, {
    client,
    skip: !customerContactId,
    variables: { id: customerContactId },
  });

  const loading = custAccountLoading || custContactLoading;
  const error = custAccoutError || custContactError;

  return {
    customerAccountQueryData,
    customerContactQueryData,
    loading,
    error,
  };
};

export default useCustomerQuery;
