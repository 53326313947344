import styled from 'styled-components';
import COLORS from '../../../styles/colors';
import THEMES from '../../../styles/themes/library/textInput';

// eslint-disable-next-line import/prefer-default-export
export const EditorWrapper = styled.div<{
  minHeight?: string;
  maxHeight?: string;
}>`
  --button-background-color-hover: ${COLORS.PK.GOLD_40};

  .other {
    h2 {
      font-size: 18px;
      color: #444;
      margin-bottom: 7px;
    }

    a {
      color: #777;
      text-decoration: underline;
      font-size: 14px;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }
  }

  h1 {
    font-size: 24px;
    color: #333;
  }

  .ltr {
    text-align: left;
  }

  .rtl {
    text-align: right;
  }

  .editor-inner {
    background: #fff;
    position: relative;
  }

  .editor-input {
    min-height: ${(props) => props.minHeight || '150px'};
    max-height: ${(props) => props.maxHeight || '400px'};
    resize: none;
    overflow-y: auto;
    caret-color: #444;
    position: relative;
    tab-size: 1;
    outline: 0;
    padding: 15px 10px;
    > p {
      margin-top: 0;
    }
  }

  .editor-placeholder {
    color: #999;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    top: 15px;
    left: 10px;
    color: ${THEMES.PLACEHOLDER};
    user-select: none;
    display: inline-block;
    pointer-events: none;
  }

  .editor-text {
    &-bold {
      font-weight: bold;
    }

    &-italic {
      font-style: italic;
    }

    &-underline {
      text-decoration: underline;
    }

    &-strikethrough {
      text-decoration: line-through;
    }

    &-underlineStrikethrough {
      text-decoration: underline line-through;
    }

    &-code {
      background-color: rgb(240, 242, 245);
      padding: 1px 0.25rem;
      font-family: Menlo, Consolas, Monaco, monospace;
      font-size: 94%;
    }

    &-link {
      color: rgb(33, 111, 219);
      text-decoration: none;
    }
  }

  .editor-code {
    background-color: rgb(240, 242, 245);
    font-family: Menlo, Consolas, Monaco, monospace;
    display: block;
    padding: 8px 8px 8px 52px;
    line-height: 1.53;
    font-size: 13px;
    margin: 0 0 8px 0;
    tab-size: 2;
    overflow-x: auto;
    position: relative;

    &:before {
      content: attr(data-gutter);
      position: absolute;
      background-color: #eee;
      left: 0;
      top: 0;
      border-right: 1px solid #ccc;
      padding: 8px;
      color: #777;
      white-space: pre-wrap;
      text-align: right;
      min-width: 25px;
    }

    &:after {
      content: attr(data-highlight-language);
      top: 0;
      right: 3px;
      padding: 3px;
      font-size: 10px;
      text-transform: uppercase;
      position: absolute;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .editor-token {
    &-comment {
      color: slategray;
    }

    &-punctuation {
      color: #999;
    }

    &-property {
      color: #905;
    }

    &-selector {
      color: #690;
    }

    &-operator {
      color: #9a6e3a;
    }

    &-attr {
      color: #07a;
    }

    &-variable {
      color: #e90;
    }

    &-function {
      color: #dd4a68;
    }
  }

  .editor-paragraph {
    margin: 0;
    margin-bottom: 8px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .editor-heading {
    &-h1 {
      font-size: 24px;
      color: rgb(5, 5, 5);
      font-weight: 400;
      margin: 0 0 12px 0;
      padding: 0;
    }

    &-h2 {
      font-size: 15px;
      color: rgb(101, 103, 107);
      font-weight: 700;
      margin: 0 0 10px 0;
      padding: 0;
      text-transform: uppercase;
    }

    &-h3 {
      font-size: 12px;
      margin: 0;
      text-transform: uppercase;
    }
  }

  .editor-quote {
    margin: 0;
    margin-left: 20px;
    font-size: 15px;
    color: rgb(101, 103, 107);
    border-left: 4px solid rgb(206, 208, 212);
    padding-left: 16px;
  }

  .editor-list {
    &-ol,
    &-ul {
      padding: 0;
      margin: 0;
      margin-left: 16px;
    }

    .editor-listitem {
      margin: 8px 32px;
    }

    .editor-nested-listitem {
      list-style-type: none;
    }
  }

  .tree-view-output {
    display: block;
    background: #222;
    color: #fff;
    padding: 5px;
    font-size: 12px;
    white-space: pre-wrap;
    margin: 1px auto 10px auto;
    max-height: 250px;
    position: relative;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: auto;
    line-height: 14px;
  }

  pre::-webkit-scrollbar {
    background: transparent;
    width: 10px;
  }

  pre::-webkit-scrollbar-thumb {
    background: #999;
  }

  .debug-timetravel-panel {
    overflow: hidden;
    padding: 0 0 10px 0;
    margin: auto;
    display: flex;

    &-slider {
      padding: 0;
      flex: 8;
    }

    &-button {
      padding: 0;
      border: 0;
      background: none;
      flex: 1;
      color: #fff;
      font-size: 12px;

      &:hover {
        text-decoration: underline;
      }
    }

    &-button {
      border: 0;
      padding: 0;
      font-size: 12px;
      top: 10px;
      right: 15px;
      position: absolute;
      background: none;
      color: #fff;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .toolbar {
    display: flex;
    margin-bottom: 1px;
    background: #fff;
    padding: 4px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    vertical-align: middle;
    border-bottom: 1px solid #e6e2dc;

    button.toolbar-item {
      border: 0;
      display: flex;
      background: none;
      border-radius: 10px;
      padding: 8px;
      cursor: pointer;
      vertical-align: middle;

      &.block-controls .ri-arrow-drop-down-line {
        font-size: 32px;
        display: inline-block;
        width: 18px;
        height: 18px;
        position: relative;
        left: -8px;
        top: -5px;
      }

      &:disabled {
        cursor: not-allowed;
      }

      &.spaced {
        margin-right: 2px;
      }

      i.format {
        background-size: contain;
        display: inline-block;
        height: 18px;
        width: 18px;
        margin-top: 2px;
        font-size: 18px;

        &:disabled {
          opacity: 0.2;
        }
      }

      &.active {
        background-color: rgba(223, 232, 250, 0.3);

        i {
          opacity: 1;
        }
      }

      &:hover:not([disabled]) {
        background-color: var(--button-background-color-hover, #ffedd1);
      }
    }

    .divider {
      width: 1px;
      background-color: #eee;
      margin: 0 4px;
    }

    .toolbar-item .text {
      display: flex;
      line-height: 20px;
      width: 78px;
      vertical-align: middle;
      font-size: 14px;
      color: #000;
      text-overflow: ellipsis;
      overflow: hidden;
      height: 20px;
      text-align: left;
    }

    .toolbar-item .icon {
      display: flex;
      width: 20px;
      height: 20px;
      user-select: none;
      margin-right: 8px;
      line-height: 16px;
      background-size: contain;

      &.block-type {
        font-size: 16px;
        align-items: center;
      }
    }

    select.toolbar-item {
      border: 0;
      display: flex;
      background: none;
      border-radius: 10px;
      padding: 8px;
      vertical-align: middle;
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 70px;
      font-size: 14px;
      color: #777;
      text-overflow: ellipsis;

      &.code-language {
        text-transform: capitalize;
        width: 130px;
      }
    }

    i.chevron-down {
      margin-top: 3px;
      width: 16px;
      height: 16px;
      display: flex;
      user-select: none;

      &.inside {
        margin-left: -25px;
        margin-top: 11px;
        margin-right: 10px;
        pointer-events: none;
      }
    }
  }

  #block-controls {
    button:hover {
      background-color: #efefef;
    }

    button:focus-visible {
      border-color: blue;
    }

    span.block-type {
      background-size: contain;
      display: block;
      width: 18px;
      height: 18px;
      margin: 2px;
    }
  }

  button[disabled] {
    color: #000 !important;
    border-width: 1px;
  }
`;
