import React from 'react';
import { InlineTextButton } from '../../elements';

export interface LinkWrapperProps {
  children: React.ReactNode;
  href?: string;
  target?: string;
  dataTestId?: string;
}

const LinkWrapper = ({
  children,
  href = '',
  target = '',
  dataTestId = '',
}: LinkWrapperProps) => {
  const clickHandler = () => {
    if (!href) return;
    window.open(href, target);
  };

  return (
    <InlineTextButton onClick={clickHandler} dataTestId={dataTestId} noOutline>
      {children}
    </InlineTextButton>
  );
};

export default LinkWrapper;
