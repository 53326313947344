import {
  SET_WHITE_LABEL,
  SET_HAS_QUERIED_WHITE_LABEL,
} from '../actions/whitelabel';

const DEFAULT_STATE = {
  whitelabel: false,
  whitelabelLogo: '',
  whitelabelTabTitle: '',
  hasQueriedWhitelabel: false,
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_WHITE_LABEL:
      return {
        ...state,
        whitelabel: action.data.whitelabel,
        whitelabelLogo: action.data.whitelabelLogo,
        whitelabelTabTitle: action.data.whitelabelTabTitle,
        hasQueriedWhitelabel: true,
      };
    case SET_HAS_QUERIED_WHITE_LABEL:
      return {
        ...state,
        hasQueriedWhitelabel: true,
      };
    default:
      return state;
  }
}
