export const PK_TYPENAMES = {
  USER: 'User',
  CUSTOMER_CONTACT: 'CustomerContact',
  CUSTOMER_ACCOUNT: 'CustomerAccount',
  GROUP: 'Group',
  WORK_ORDER: 'WorkOrder',
  LABEL: 'Label',
  COMPANY: 'Company',
};

export const URL_ARG_ENTITY_TYPES = {
  CUSTOMER_CONTACT: 'customercontact',
  CUSTOMER_ACCOUNT: 'customeraccount',
  CONTACT: 'contact',
  COMPANY: 'company',
  USER: 'user',
  GROUP: 'group',
  LABEL: 'label',
  LIST: 'tag',
};
