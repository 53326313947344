import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { canPreviewBasedOnFileExtension } from 'client-lib/src/lib/utils/helpers';
import {
  EmphasisText,
  Heading2,
  Text,
  Loading,
  TabList,
  Tooltip,
} from '../../../../elements';
import THEMES from '../../../../styles/themes/app';
import AttachmentView from '../../../FileUpload/AttachmentView';
import { TEXT_COLOR } from '../../../../elements/Anchor/Anchor';

const MessageContainer = styled.div`
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  width: 100%;
  min-width: 200px;
  border-radius: 23px;
  min-height: 60px;
  padding: 24px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: ${(props) => (props.loading ? 'flex' : 'inline-block')};
  justify-content: center;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  white-space: pre-line;

  & :before {
    content: '';
    position: absolute;
    z-index: 0;
    bottom: 0;
    right: -8px;
    height: 20px;
    width: 20px;
    background-color: ${THEMES.BACKGROUND_PRIMARY};
    background-attachment: fixed;
    border-bottom-left-radius: 15px;
  }
  & :after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: -10px;
    width: 10px;
    height: 20px;
    background: ${THEMES.BACKGROUND_TERTIARY};
    border-bottom-left-radius: 10px;
  }
`;

const Container = styled.div`
  width: 100%;
  position: relative;
  margin: auto;
  padding: 40px;
  box-sizing: border-box;
`;

const HeaderWrapper = styled.div`
  margin-bottom: 8px;
  min-width: 250px;
  display: flex;
  align-items: center;
`;

const SubheaderWrapper = styled.div`
  margin-bottom: 24px;
  min-width: 250px;
`;

const LoadingWrapper = styled.div`
  height: 80%;
  display: flex;
  align-items: center;
  width: fit-content;
`;

const AttachmentContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

const TextWrapper = styled.div`
  padding-bottom: 4px;
  word-break: break-word;
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const LabelIcon = styled.i`
  padding-right: 4px;
`;

const InfoWrapper = styled.div`
  margin-left: 4px;
  font-size: 16px;
  display: flex;
`;

const TooltipContent = styled.div`
  overflow-wrap: break-word;
  max-width: 250px;
  overflow: hidden;
  color: ${THEMES.FOREGROUND_HIGH};
`;

const options = [
  {
    label: (
      <LabelContainer>
        <LabelIcon className="ri-question-answer-line" />
        {i18n.t('reports-ChannelButtonSelector-text', { defaultValue: 'Text' })}
      </LabelContainer>
    ),
    value: 'text',
    disabled: false,
  },
  {
    label: (
      <LabelContainer>
        <LabelIcon className="ri-mail-line" />
        {i18n.t('settings-ProfileForm-email', { defaultValue: 'Email' })}
      </LabelContainer>
    ),
    value: 'email',
    disabled: false,
  },
];

const CreatePaymentMessagePreview = ({
  messageText,
  loading,
  attachment,
  accountName,
  emailSubject,
  sendAttachmentAsLink,
  messageType,
}) => {
  const [currentTab, setCurrentTab] = useState(
    messageType.value === 'SMS' || messageType.value === 'ALL'
      ? options[0].value
      : options[1].value
  );
  const activeOptions = options.map((option) => ({
    ...option,
    active: currentTab === option.value,
  }));
  const handleTabSelect = ({ value }) => setCurrentTab(value);

  const canPreviewFile =
    attachment && canPreviewBasedOnFileExtension(attachment?.originalFilename);

  const tooltipContent = (
    <TooltipContent>
      {i18n.t('slideouts-PaymentForm-placeholderDisclaimer', {
        defaultValue:
          'This includes a placeholder link to show what your link will look like in the message. Your live link will automatically generate and replace the placeholder link upon sending the message.',
      })}
    </TooltipContent>
  );

  useEffect(() => {
    if (messageType.value === 'SMS') {
      options[1].disabled = true;
      options[0].disabled = false;
    }
    if (messageType.value === 'EMAIL') {
      options[0].disabled = true;
      options[1].disabled = false;
    }
    if (messageType.value === 'ALL') {
      options[0].disabled = false;
      options[1].disabled = false;
    }
  }, [messageType.value]);

  return (
    <Container>
      <HeaderWrapper>
        <Heading2>{i18n.t('slideouts-GroupMessageContent-preview')}</Heading2>
        <Tooltip place="bottom" elementsContent={tooltipContent}>
          <InfoWrapper data-tip>
            <i className="ri-information-line" />
          </InfoWrapper>
        </Tooltip>
      </HeaderWrapper>
      <SubheaderWrapper>
        <Text>{i18n.t('slideouts-GroupMessageContent-willSee')}</Text>
      </SubheaderWrapper>
      <TabList
        options={activeOptions}
        onClick={handleTabSelect}
        type="primary"
      />
      <MessageContainer loading={loading ? 1 : 0}>
        {loading ? (
          <LoadingWrapper>
            <Loading size="xs" />
          </LoadingWrapper>
        ) : (
          <>
            {currentTab === 'text' ? (
              <TextWrapper>
                <Text>{`${accountName}:`}</Text>
              </TextWrapper>
            ) : (
              <TextWrapper>
                <EmphasisText>{`${emailSubject}`}</EmphasisText>
              </TextWrapper>
            )}
            <TextWrapper>
              <Text>{messageText}</Text>
            </TextWrapper>
            <TextWrapper>
              <Text customStyle={(props) => `color: ${TEXT_COLOR(props)}`}>
                Prokeep.com/pay
              </Text>
            </TextWrapper>
            {attachment &&
              (sendAttachmentAsLink || !canPreviewFile ? (
                <div>
                  <br />
                  <Text>{'https://<link-will-go-here>'}</Text>
                </div>
              ) : (
                <AttachmentContainer>
                  <AttachmentView attachment={attachment} />
                </AttachmentContainer>
              ))}
          </>
        )}
      </MessageContainer>
    </Container>
  );
};

CreatePaymentMessagePreview.propTypes = {
  messageText: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  attachment: PropTypes.object,
  accountName: PropTypes.string,
  emailSubject: PropTypes.string,
  sendAttachmentAsLink: PropTypes.bool,
  messageType: PropTypes.object.isRequired,
};

CreatePaymentMessagePreview.defaultProps = {
  loading: false,
  attachment: null,
  accountName: '',
  emailSubject: '',
  sendAttachmentAsLink: false,
};

export default CreatePaymentMessagePreview;
