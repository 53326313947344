import { useMutation } from "@apollo/client";
import START_EMAIL_THREAD_MUTATION from "../../graphql/mutation/StartEmailThreadMutation.graphql";

/*

DOCUMENTATION
  generic hook for using start email thread mutation

  args:
    client: (obj) required. apollo client instance

  returns:
    [0] handleStartThread: func that will call poseMessageMutation.
      args:
        senderGroupId: (str) required. group id of user group.
        externalContactId: (str) required (empty string if giving externalPhoneNumber). contact id of who user is messaging.
        externalPhoneNumber: (str) required (empty string if giving externalContactId). phone num user is messaging.
        message: (obj) required. consists of { text (str), attachments (arr) }
        onSuccess: (func) optional, will call on mutation success
        onError: (func) optional, will call on mutation failure

*/

const useStartThreadMutation = ({ client }) => {
  const [startEmailThread] = useMutation(START_EMAIL_THREAD_MUTATION, {
    client,
  });

  const handleEmailStartThread = async ({
    senderGroupId = null,
    externalContactId = null,
    externalEmailAddress = null,
    message = "",
    tags = null,
    emailMetadata = null,
    onSuccess = () => {},
    onError = () => {},
  }) => {
    const { data } = await startEmailThread({
      variables: {
        input: {
          senderGroupId,
          externalContactId,
          externalEmailAddress,
          message,
          tags,
          emailMetadata,
        },
      },
    }).catch((err) => {
      onError(err);
    });

    if (data && data.startEmailThread && data.startEmailThread.errors) {
      onError(data.startEmailThread.errors[0]);
    } else {
      onSuccess(data);
    }
  };

  return [handleEmailStartThread];
};

export default useStartThreadMutation;
