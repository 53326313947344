import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Heading2, TextInput, IconButton } from '../../../../../elements';

const MessageNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;
`;

const EditMessageName = ({ messageName, onBlur, onEdit, loading }) => {
  if (loading) return null;
  const [editMode, setEditMode] = useState(false);
  const [messageNameValue, setMessageNameValue] = useState(messageName);

  const handleOnBlur = () => {
    onBlur(messageNameValue);
    setEditMode(false);
  };

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      handleOnBlur();
    }
  };

  useEffect(() => setMessageNameValue(messageName), [messageName]);

  return editMode ? (
    <div style={{ marginBottom: '-40px' }}>
      <TextInput
        value={messageNameValue}
        onChange={(e) => setMessageNameValue(e.target.value)}
        onBlur={handleOnBlur}
        onKeyPress={handleEnter}
        autoFocus
      />
    </div>
  ) : (
    <MessageNameWrapper>
      <Heading2
        customStyle={() => 'overflow: hidden; text-overflow: ellipsis;'}
      >
        {messageName}
      </Heading2>
      <IconButton
        noOutline
        contrast="highColor"
        customStyle={() => 'margin-top: 2px; margin-left: 8px;'}
        size="md"
        onClick={() => {
          onEdit();
          setEditMode(true);
        }}
      >
        <i className="ri-pencil-fill" />
      </IconButton>
    </MessageNameWrapper>
  );
};

EditMessageName.propTypes = {
  messageName: PropTypes.string,
  onBlur: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
EditMessageName.defaultProps = {
  messageName: '',
};

export default EditMessageName;
