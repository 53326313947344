import { useState } from "react";
import { useMutation } from "@apollo/client";
import useForm from "../../../../utils/hooks/useForm";
import { normalizePhone } from "../../../../utils/validation";
import updateCustomerContactMutation from "../../../../graphql/mutation/UpdateCustomerContactMutation.graphql";
import updateCustomerAccountMutation from "../../../../graphql/mutation/UpdateCustomerAccountMutation.graphql";

function useCustomerFaxEdit({ client, onSave, onError, customer, i18n }) {
  const [updateCustomer] = useMutation(
    customer.__typename === "CustomerAccount"
      ? updateCustomerAccountMutation
      : updateCustomerContactMutation,
    { client }
  );

  const inputFieldsConfig = {
    number: {
      value: customer.faxNumber || "",
      validations: [
        {
          type: "phoneFormat",
          errorMessage: i18n.t("slideouts-validation-invalidFax"),
        },
      ],
      optional: true,
    },
  };

  const sendMutation = async () => {
    const { data } = await updateCustomer({
      variables: {
        input: {
          id: customer.id,
          faxNumber: number.value,
        },
      },
    }).catch((err) => {
      onError?.();
      console.warn(err);
    });

    if (data?.updateCustomerContact?.errors) {
      onError?.();
    } else {
      onSave(number.value);
    }
  };

  const { fields, onSubmit } = useForm(inputFieldsConfig, sendMutation);
  const { number } = fields;

  const [formattedNumber, updateFormattedNumber] = useState(
    normalizePhone(number.value)
  );

  const onChange = (e) => {
    const { value, selectionStart } = e.target;

    const charCountAfterSelection = value.slice(selectionStart).length;

    const strippedValue = value.replace(/[^0-9]/g, "");
    const formattedNumber = normalizePhone(strippedValue);

    number.setValue(strippedValue); // updates form state
    updateFormattedNumber(formattedNumber); // updates formatted state

    // from here on is logic to place the cursor in the correct spot

    // indicates that 2 chars were lost instead of one (backspacing until hyphen gets removed with char due to auto-formatting)
    const charInsertionOffset = value.length - formattedNumber.length;

    // compensates for character insertion for autoformatting. Will assume if cursor is at the end that user would like to stay at the end.
    let correctCursorPos =
      formattedNumber.length -
      charCountAfterSelection +
      (charCountAfterSelection === 0 ? 0 : charInsertionOffset);

    // if typing, jump hyphen, if backspacing, don't.
    const jumpHyphen =
      formattedNumber[correctCursorPos - 1] === "-" &&
      value[selectionStart - 1] !== "-";
    if (jumpHyphen) correctCursorPos += 1;

    // set timeout b/c setting state is async.
    setTimeout(() => {
      if (number?.ref?.current)
        number.ref.current.setSelectionRange(
          correctCursorPos,
          correctCursorPos
        );
    }, 0);
  };

  return [number, formattedNumber, onChange, onSubmit];
}

export default useCustomerFaxEdit;
