import React from 'react';
import i18n from 'i18n-js';
import styled from 'styled-components';
import { Heading2 } from '../../../../../elements';

const AllSelctedContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 146px;
`;

const AllSelectedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeadingWrapper = styled.div`
  margin-bottom: 20px;
`;

const ToggleAllMessage = () => (
  <AllSelctedContainer>
    <AllSelectedWrapper>
      <HeadingWrapper>
        <Heading2>
          {i18n.t('slideouts-GroupMessageRecipients-allContacts', {
            defaultValue: 'All Contacts Selected',
          })}
        </Heading2>
      </HeadingWrapper>
      <Heading2>
        {i18n.t('broadcasts-broadcast-selectedAll', {
          defaultValue:
            'Broadcast will be sent to your entire reachable audience',
        })}
      </Heading2>
    </AllSelectedWrapper>
  </AllSelctedContainer>
);

export default ToggleAllMessage;
