import styled from 'styled-components';
import THEMES from '../../styles/themes/app';

export const createButtonStyle = (props) => `
  width: 50px;
  height: 50px;
  border-radius: 4px;
  background-color: ${THEMES.SIDEBAR_CREATE_BUTTON(props)};
  color: ${THEMES.COLORED_FOREGROUND_HIGH(props)};

  :hover {
    background-color: ${THEMES.SIDEBAR_CREATE_BUTTON_HOVER(props)};
    color: ${THEMES.COLORED_FOREGROUND_HIGH(props)};
  }

  && i {
    font-size: 24px;
    line-height: 24px;
  }
`;

export const iconButtonStyle = (props) => `
  color: ${
    props.active
      ? THEMES.SIDEBAR_ICON_ACTIVE(props)
      : THEMES.SIDEBAR_ICON(props)
  };

  :hover {
    color: ${THEMES.SIDEBAR_ICON_ACTIVE(props)};
  }

  && i {
    font-size: 26px;
    line-height: 26px;
  }
`;

export const createIconButtonStyle = (props) => `
  color: ${
    !props.active
      ? THEMES.SIDEBAR_ICON(props)
      : THEMES.CREATE_SIDEBAR_ICON_ACTIVE(props)
  };

  :hover {
    color: ${THEMES.CREATE_SIDEBAR_ICON_ACTIVE(props)};
  }

  :disabled {
    color: ${THEMES.SIDEBAR_ICON_DISABLED(props)} !important;
  }

  && i {
    font-size: 26px;
    line-height: 26px;
  }
`;

export const WhitelabelLogoWrap = styled.div`
  max-width: 40px;
  max-height: 40px;
  height: 100%;
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
`;

export const WhitelabelImg = styled.img`
  max-width: 40px;
  max-height: 40px;
  &:hover {
    cursor: pointer;
  }
`;

export const SidebarWrap = styled.div`
  height: calc(100% + 1px);
  z-index: 11;
`;
export const AvatarWrap = styled.div`
  margin: 1rem;
`;
export const CreateButtonWrap = styled.div`
  margin: 10px 0 10px 0;
`;

export const Wrapper = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background: ${({ createSideBarOpen }) =>
    createSideBarOpen ? THEMES.CREATE_SIDEBAR : THEMES.SIDEBAR_BACKGROUND};
  border-right: 1px solid rgba(175, 175, 175, 0.1);

  & a {
    position: relative;
  }
  & a svg {
    display: flex;
    position: relative;
    align-self: flex-end;
  }
  & span svg {
    display: flex;
    position: relative;
    align-self: flex-end;
  }
`;

const activeBackgroundColor = ({ active, isCreate }) => {
  const activeSidebarColor = isCreate
    ? THEMES.CREATE_SIDEBAR_ICON_ACTIVE_BACKGROUND
    : THEMES.SIDEBAR_ICON_ACTIVE_BACKGROUND;
  return active ? activeSidebarColor : 'transparent';
};

export const IconWrap = styled.div`
  background-color: ${activeBackgroundColor};
  width: 50px;
  height: 50px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${({ isTop }) => (isTop ? '0 0 10px 0' : '10px 0')};
`;

export const BadgeWrap = styled.div`
  position: absolute;
  top: -4px;
  right: -8px;
  @media print {
    display: none;
  }
`;

export const CreateTitleWrapper = styled.div`
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  margin: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CreateTitle = styled.span`
  padding: 1em;
  color: ${THEMES.COLORED_FOREGROUND_HIGH};
`;

export const NoPrintIcon = styled.i`
  @media print {
    display: none;
  }
`;
