import theme from 'styled-theming';
import COLORS from '../../colors';

// primary button values
const PRIMARY_BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_BLUE,
  light: COLORS.PK.BLUE,
  dark: COLORS.PK.BLUE_60,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_SECONDARY,
});
const PRIMARY_BORDER = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_BLUE,
  light: COLORS.PK.BLUE_80,
  dark: COLORS.PK.BLUE_60,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_PRIMARY,
});
const PRIMARY_TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.WHITE,
  dark: COLORS.PK.WHITE,
  holiday: COLORS.PK.WHITE,
});
const PRIMARY_BACKGROUND_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.LIGHT_BLUE,
  light: COLORS.PK.BLUE_80,
  dark: COLORS.PK.BLUE_80,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_PRIMARY,
});
const PRIMARY_BORDER_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.LIGHT_BLUE,
  light: COLORS.PK.BLUE_80,
  dark: COLORS.PK.BLUE_80,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_PRIMARY,
});
const PRIMARY_TEXT_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.WHITE,
  dark: COLORS.PK.WHITE,
  holiday: COLORS.PK.WHITE,
});

// secondary button
const SECONDARY_BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.TRANSPARENT,
  dark: COLORS.PK.TRANSPARENT,
  holiday: COLORS.PK.TRANSPARENT,
});
const SECONDARY_BORDER = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_BLUE,
  light: COLORS.PK.GREY_60,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_MED,
  holiday: COLORS.PK.GREY_60,
});
const SECONDARY_TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_BLUE,
  light: COLORS.PK.GREY_60,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_MED,
  holiday: COLORS.PK.GREY_60,
});
const SECONDARY_BACKGROUND_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.TRANSPARENT_DARK,
  dark: COLORS.PK.TRANSPARENT_LIGHT,
  holiday: COLORS.PK.TRANSPARENT_DARK,
});
const SECONDARY_BORDER_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.LIGHT_BLUE,
  light: COLORS.PK.GREY_60,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_MED,
  holiday: COLORS.PK.GREY_60,
});
const SECONDARY_TEXT_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.LIGHT_BLUE,
  light: COLORS.PK.GREY_60,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_MED,
  holiday: COLORS.PK.GREY_60,
});

// secondaryLight button
const SECONDARY_LIGHT_BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.TRANSPARENT,
  dark: COLORS.PK.TRANSPARENT,
  holiday: COLORS.PK.TRANSPARENT,
});
const SECONDARY_LIGHT_BORDER = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_BLUE,
  light: COLORS.PK.BLUE,
  dark: COLORS.PK.BLUE_60,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_SECONDARY,
});
const SECONDARY_LIGHT_TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_BLUE,
  light: COLORS.PK.BLUE,
  dark: COLORS.PK.BLUE_60,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_SECONDARY,
});
const SECONDARY_LIGHT_BACKGROUND_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.TRANSPARENT_DARK,
  dark: COLORS.PK.TRANSPARENT_LIGHT,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_TERTIARY,
});
const SECONDARY_LIGHT_BORDER_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.LIGHT_BLUE,
  light: COLORS.PK.BLUE,
  dark: COLORS.PK.BLUE_60,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_SECONDARY,
});
const SECONDARY_LIGHT_TEXT_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.LIGHT_BLUE,
  light: COLORS.PK.BLUE,
  dark: COLORS.PK.BLUE_60,
  holiday: COLORS.PK.WHITE,
});

// tertiary button values
const TERTIARY_BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_BLUE,
  light: COLORS.PK.GREY_60,
  dark: COLORS.PK_DARK.GREY_3,
  holiday: COLORS.PK.GREY_60,
});
const TERTIARY_BORDER = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_BLUE,
  light: COLORS.PK.GREY_70,
  dark: COLORS.PK_DARK.GREY_3,
  holiday: COLORS.PK.GREY_70,
});
const TERTIARY_TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.WHITE,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_HIGH,
  holiday: COLORS.PK.WHITE,
});
const TERTIARY_BACKGROUND_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.LIGHT_BLUE,
  light: COLORS.PK.GREY_70,
  dark: COLORS.PK_DARK.GREY_2,
  holiday: COLORS.PK.GREY_70,
});
const TERTIARY_BORDER_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.LIGHT_BLUE,
  light: COLORS.PK.GREY_70,
  dark: COLORS.PK_DARK.GREY_2,
  holiday: COLORS.PK.GREY_70,
});
const TERTIARY_TEXT_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.WHITE,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_HIGH,
  holiday: COLORS.PK.WHITE,
});

// "destructive" button values
const DESTRUCTIVE_BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_RED,
  light: COLORS.PK.RED_80,
  dark: COLORS.PK.RED_80,
  holiday: COLORS.PK.RED_80,
});
const DESTRUCTIVE_BORDER = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_RED,
  light: COLORS.PK.RED_100,
  dark: COLORS.PK.RED_100,
  holiday: COLORS.PK.RED_100,
});
const DESTRUCTIVE_TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.WHITE,
  dark: COLORS.PK.WHITE,
  holiday: COLORS.PK.WHITE,
});
const DESTRUCTIVE_BACKGROUND_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_RED_HOVER,
  light: COLORS.PK.RED_100,
  dark: COLORS.PK.RED_100,
  holiday: COLORS.PK.RED_100,
});
const DESTRUCTIVE_BORDER_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_RED_HOVER,
  light: COLORS.PK.RED_100,
  dark: COLORS.PK.RED_100,
  holiday: COLORS.PK.RED_100,
});
const DESTRUCTIVE_TEXT_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.WHITE,
  dark: COLORS.PK.WHITE,
  holiday: COLORS.PK.WHITE,
});

// "destructiveSecondary" button values
const DESTRUCTIVE_SECONDARY_BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.TRANSPARENT,
  dark: COLORS.PK.TRANSPARENT,
  holiday: COLORS.PK.TRANSPARENT,
});
const DESTRUCTIVE_SECONDARY_BORDER = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_RED,
  light: COLORS.PK.RED_100,
  dark: COLORS.PK.RED_100,
  holiday: COLORS.PK.RED_100,
});
const DESTRUCTIVE_SECONDARY_TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_RED,
  light: COLORS.PK.RED_80,
  dark: COLORS.PK.RED_80,
  holiday: COLORS.PK.RED_80,
});
const DESTRUCTIVE_SECONDARY_BACKGROUND_HOVER = theme('mode', {
  classic: COLORS.PK.RED_20,
  light: COLORS.PK.RED_20,
  dark: COLORS.PK.RED_100,
  holiday: COLORS.PK.RED_20,
});
const DESTRUCTIVE_SECONDARY_BORDER_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_RED_HOVER,
  light: COLORS.PK.RED_100,
  dark: COLORS.PK.RED_100,
  holiday: COLORS.PK.RED_100,
});
const DESTRUCTIVE_SECONDARY_TEXT_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_RED,
  light: COLORS.PK.RED_80,
  dark: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  holiday: COLORS.PK.RED_80,
});

// "buttonSelector" button values
const SELECTOR_BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_BLUE,
  light: COLORS.PK.GREEN_80,
  dark: COLORS.PK.BLUE_60,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_SECONDARY,
});
const SELECTOR_BORDER = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_BLUE,
  light: COLORS.PK.GREEN_80,
  dark: COLORS.PK.BLUE_60,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_PRIMARY,
});
const SELECTOR_TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.WHITE,
  dark: COLORS.PK.WHITE,
  holiday: COLORS.PK.WHITE,
});
const SELECTOR_BACKGROUND_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.LIGHT_BLUE,
  light: COLORS.PK.GREEN_100,
  dark: COLORS.PK.BLUE_80,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_PRIMARY,
});
const SELECTOR_BORDER_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.LIGHT_BLUE,
  light: COLORS.PK.GREEN_80,
  dark: COLORS.PK.BLUE_80,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_PRIMARY,
});
const SELECTOR_TEXT_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.WHITE,
  dark: COLORS.PK.WHITE,
  holiday: COLORS.PK.WHITE,
});

// link button values
const LINK_BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.TRANSPARENT,
  light: COLORS.PK.TRANSPARENT,
  dark: COLORS.PK.TRANSPARENT,
  holiday: COLORS.PK.TRANSPARENT,
});
const LINK_BORDER = theme('mode', {
  classic: COLORS.PK_CLASSIC.TRANSPARENT,
  light: COLORS.PK.TRANSPARENT,
  dark: COLORS.PK.TRANSPARENT,
  holiday: COLORS.PK.TRANSPARENT,
});
const LINK_TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.LINK_BLUE,
  light: COLORS.PK.BLUE,
  dark: COLORS.PK.BLUE_60,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_SECONDARY,
});
const LINK_BACKGROUND_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.TRANSPARENT,
  light: COLORS.PK.TRANSPARENT,
  dark: COLORS.PK.TRANSPARENT,
  holiday: COLORS.PK.TRANSPARENT,
});
const LINK_BORDER_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.TRANSPARENT,
  light: COLORS.PK.TRANSPARENT,
  dark: COLORS.PK.TRANSPARENT,
  holiday: COLORS.PK.TRANSPARENT,
});
const LINK_TEXT_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.LINK_BLUE,
  light: COLORS.PK.BLUE_60,
  dark: COLORS.PK.BLUE_40,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_TERTIARY,
});

// destructive link button values
// uses link styles for everything but text
const DESTRUCTIVE_LINK_TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_RED,
  light: COLORS.PK.RED_80,
  dark: COLORS.PK.RED_80,
  holiday: COLORS.PK.RED_80,
});
const DESTRUCTIVE_LINK_TEXT_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_RED_HOVER,
  light: COLORS.PK.RED_100,
  dark: COLORS.PK.RED_100,
  holiday: COLORS.PK.RED_100,
});

// noStyle button values
const NO_STYLE_BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.TRANSPARENT,
  light: COLORS.PK.TRANSPARENT,
  dark: COLORS.PK.TRANSPARENT,
  holiday: COLORS.PK.TRANSPARENT,
});
const NO_STYLE_BORDER = theme('mode', {
  classic: COLORS.PK_CLASSIC.TRANSPARENT,
  light: COLORS.PK.TRANSPARENT,
  dark: COLORS.PK.TRANSPARENT,
  holiday: COLORS.PK.TRANSPARENT,
});
const NO_STYLE_TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_BLACK,
  light: COLORS.PK.BLACK,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_HIGH,
  holiday: COLORS.PK.BLACK,
});
const NO_STYLE_BACKGROUND_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.TRANSPARENT,
  light: COLORS.PK.TRANSPARENT,
  dark: COLORS.PK.TRANSPARENT,
  holiday: COLORS.PK.TRANSPARENT,
});
const NO_STYLE_BORDER_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.TRANSPARENT,
  light: COLORS.PK.TRANSPARENT,
  dark: COLORS.PK.TRANSPARENT,
  holiday: COLORS.PK.TRANSPARENT,
});
const NO_STYLE_TEXT_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_BLACK,
  light: COLORS.PK.BLACK,
  dark: COLORS.PK.BLUE_40,
  holiday: COLORS.PK.BLACK,
});

const DISABLED_TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.GREY_30,
  dark: COLORS.PK_DARK.GREY_3,
  holiday: COLORS.PK.GREY_30,
});
const DISABLED_BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.DARK_GRAY,
  light: COLORS.PK.GREY_20,
  dark: COLORS.PK_DARK.GREY_4,
  holiday: COLORS.PK.GREY_20,
});
const DISABLED_BORDER = theme('mode', {
  classic: COLORS.PK_CLASSIC.DARK_GRAY,
  light: COLORS.PK.GREY_30,
  dark: COLORS.PK_DARK.GREY_3,
  holiday: COLORS.PK.GREY_30,
});
const DISABLED_LINK_TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.DARK_GRAY,
  light: COLORS.PK.GREY_30,
  dark: COLORS.PK.GREY_70,
  holiday: COLORS.PK.GREY_30,
});
const DISABLED_LINK_BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.TRANSPARENT,
  light: COLORS.PK.TRANSPARENT,
  dark: COLORS.PK.TRANSPARENT,
  holiday: COLORS.PK.TRANSPARENT,
});
const DISABLED_LINK_BORDER = theme('mode', {
  classic: COLORS.PK_CLASSIC.TRANSPARENT,
  light: COLORS.PK.TRANSPARENT,
  dark: COLORS.PK.TRANSPARENT,
  holiday: COLORS.PK.TRANSPARENT,
});

const BUTTON_OUTLINE = theme('mode', {
  classic: COLORS.PK_CLASSIC.SKY_BLUE,
  light: COLORS.PK_CLASSIC.SKY_BLUE,
  dark: COLORS.PK_CLASSIC.WHITE,
  holiday: COLORS.PK_CLASSIC.SKY_BLUE,
});

const THEMES = {
  PRIMARY_BACKGROUND,
  PRIMARY_BORDER,
  PRIMARY_TEXT,
  PRIMARY_BACKGROUND_HOVER,
  PRIMARY_BORDER_HOVER,
  PRIMARY_TEXT_HOVER,

  SECONDARY_BACKGROUND,
  SECONDARY_BORDER,
  SECONDARY_TEXT,
  SECONDARY_BACKGROUND_HOVER,
  SECONDARY_BORDER_HOVER,
  SECONDARY_TEXT_HOVER,

  SECONDARY_LIGHT_BACKGROUND,
  SECONDARY_LIGHT_BORDER,
  SECONDARY_LIGHT_TEXT,
  SECONDARY_LIGHT_BACKGROUND_HOVER,
  SECONDARY_LIGHT_BORDER_HOVER,
  SECONDARY_LIGHT_TEXT_HOVER,

  TERTIARY_BACKGROUND,
  TERTIARY_BORDER,
  TERTIARY_TEXT,
  TERTIARY_BACKGROUND_HOVER,
  TERTIARY_BORDER_HOVER,
  TERTIARY_TEXT_HOVER,

  DESTRUCTIVE_BACKGROUND,
  DESTRUCTIVE_BORDER,
  DESTRUCTIVE_TEXT,
  DESTRUCTIVE_BACKGROUND_HOVER,
  DESTRUCTIVE_BORDER_HOVER,
  DESTRUCTIVE_TEXT_HOVER,

  DESTRUCTIVE_SECONDARY_BACKGROUND,
  DESTRUCTIVE_SECONDARY_BORDER,
  DESTRUCTIVE_SECONDARY_TEXT,
  DESTRUCTIVE_SECONDARY_BACKGROUND_HOVER,
  DESTRUCTIVE_SECONDARY_BORDER_HOVER,
  DESTRUCTIVE_SECONDARY_TEXT_HOVER,

  DESTRUCTIVE_LINK_TEXT,
  DESTRUCTIVE_LINK_TEXT_HOVER,

  LINK_BACKGROUND,
  LINK_BORDER,
  LINK_TEXT,
  LINK_BACKGROUND_HOVER,
  LINK_BORDER_HOVER,
  LINK_TEXT_HOVER,

  NO_STYLE_BACKGROUND,
  NO_STYLE_BORDER,
  NO_STYLE_TEXT,
  NO_STYLE_BACKGROUND_HOVER,
  NO_STYLE_BORDER_HOVER,
  NO_STYLE_TEXT_HOVER,

  DISABLED_TEXT,
  DISABLED_BACKGROUND,
  DISABLED_BORDER,
  DISABLED_LINK_TEXT,
  DISABLED_LINK_BACKGROUND,
  DISABLED_LINK_BORDER,

  SELECTOR_BACKGROUND,
  SELECTOR_BORDER,
  SELECTOR_TEXT,
  SELECTOR_BACKGROUND_HOVER,
  SELECTOR_BORDER_HOVER,
  SELECTOR_TEXT_HOVER,

  BUTTON_OUTLINE,
};

export default THEMES;
