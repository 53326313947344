import React from 'react';
import theme from 'styled-theming';
import styled from 'styled-components';
import THEMES from '../../styles/themes/app';
import { ItalicText } from '../Text/Text';

/**
 * Font size for the message text.
 * @type {string}
 */
const MESSAGE_TEXT_FONTSIZE = theme('fontSize', {
  default: '14px',
  large: '18px',
});

const AlertMessageBoxWrap = styled.div`
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${THEMES.WARNING_BACKGROUND};
  color: ${THEMES.WARNING_FOREGROUND_HIGH};
  border-radius: 4px;
  padding: 16px;
  gap: 8px;
  font-size: ${MESSAGE_TEXT_FONTSIZE};

  aside {
    padding-top: 2px;
  }
`;

/**
 * Renders an error message component that includes an icon and italicized text.
 *
 * @param {Object} props - The props for the component.
 * @returns {JSX.Element} The rendered error message component.
 */

const AlertMessageBox = ({ ...props }) => (
  <AlertMessageBoxWrap>
    <aside>
      <i className="ri-error-warning-fill" />
    </aside>
    <ItalicText
      customStyle={(props) => `
      white-space: pre-wrap;
      color: ${THEMES.WARNING_FOREGROUND_HIGH(props)};
      font-size: ${MESSAGE_TEXT_FONTSIZE(props)};
    `}
      {...props}
    />
  </AlertMessageBoxWrap>
);

export default AlertMessageBox;
