import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import i18n from 'i18n-js';

import { SORT_DIRECTION } from 'client-lib';
import THEMES from '../../styles/themes/app';

import FilterBy from '../../elements/FilterBy/FilterBy';
import { Option, SortBy } from '../../elements';

const GroupOption = styled.div`
  border-bottom: solid 1px ${THEMES.BORDER_COLOR};
`;
const FilterByContainer = styled(GroupOption)`
  padding: 10px 16px;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  flex-direction: row;
  width: 100%;
`;

/**
 * GroupRegionSelectFlyoutFilterBy
 *
 * @category Components
 *
 * This component is a filter by region and filter by selected toggle for the GroupRegionSelectFlyout
 *
 * @param {object} props
 * @param {Object} props.appliedFilters - The current state of the filters
 * @param {boolean} props.appliedFilters.filterByRegions - The current state of the filter by regions toggle
 * @param {boolean} props.appliedFilters.filterBySelected - The current state of the filter by selected toggle
 * @param {function} props.setAppliedFilters - Function to set the filter state, must update filterByRegions and filterBySelected
 * @param {function} props.sortDirection - String representing the current sort direction
 * @param {function} props.setSortDirection - Function to set the sort direction
 * @param {boolean} props.allowRegions - Enable or disable the region filter
 *
 */

const GroupRegionSelectFlyoutFilterBy = ({
  appliedFilters,
  setAppliedFilters,
  allowRegions,
  sortDirection,
  setSortDirection,
}) => {
  const [filterByOpen, setFilterByOpen] = useState(false);
  const [activeFiltersNum, setActiveFiltersNum] = useState(0);
  const [filterBySelectedCheckedInternal, setFilterBySelectedCheckedInternal] =
    useState(false);
  const [filterByRegionsCheckedInternal, setFilterByRegionsCheckedInternal] =
    useState(false);

  const SORT_OPTIONS = [
    {
      label: i18n.t('dashboards-GroupSelect-Ascending', {
        defaultValue: 'Ascending (A-Z)',
      }),
      value: SORT_DIRECTION.ascending,
    },
    {
      label: i18n.t('dashboards-GroupSelect-Descending', {
        defaultValue: 'Descending (Z-A)',
      }),
      value: SORT_DIRECTION.descending,
    },
  ];
  const [sortOpen, setSortOpen] = useState(false);
  const sortSelection = SORT_OPTIONS.find(
    (option) => option.value === sortDirection
  );

  const handleSort = (selection) => {
    setSortDirection(selection?.value);
  };

  const { filterByRegions, filterBySelected } = appliedFilters;

  const caclulateActiveFilters = (filterByRegion, filterBySelected) =>
    filterByRegion || filterBySelected ? 1 : 0;

  useEffect(() => {
    setFilterByRegionsCheckedInternal(filterByRegions);
    setFilterBySelectedCheckedInternal(filterBySelected);
    setActiveFiltersNum(
      caclulateActiveFilters(filterByRegions, filterBySelected)
    );
  }, []);

  const handleSubmitFilter = () => {
    const countActiveFilters = caclulateActiveFilters(
      filterByRegionsCheckedInternal,
      filterBySelectedCheckedInternal
    );
    setFilterByOpen(false);
    setActiveFiltersNum(countActiveFilters);

    setAppliedFilters({
      filterBySelected: filterBySelectedCheckedInternal,
      filterByRegions: filterByRegionsCheckedInternal,
    });
  };

  const handleCancelFilter = () => {
    setFilterByOpen(false);
  };

  const handleClearAllFilter = () => {
    // clear all state inside filterBy component
    setFilterByRegionsCheckedInternal(false);
    setFilterBySelectedCheckedInternal(false);
  };

  return (
    <FilterByContainer>
      <FilterBy
        onSubmit={handleSubmitFilter}
        clearAllFilters={handleClearAllFilter}
        onCancel={handleCancelFilter}
        isOpen={filterByOpen}
        setIsOpen={setFilterByOpen}
        activeFiltersNum={activeFiltersNum}
        dataTestId="GroupRegionSelectFlyoutFilterBy"
      >
        {!allowRegions ? null : (
          <Option
            name={i18n.t('dashboards-GroupSelect-FilterByRegion', {
              defaultValue: 'FILTER BY REGION',
            })}
            checked={filterByRegionsCheckedInternal}
            customStyle={() => `
                      border: 0;
                      padding-bottom: 25px;
                    `}
            customTextWrapStyle={() => `
              h3 {
                font-size: 12px;
              }
            `}
            cardProps={{
              noPadding: true,
            }}
            onCheck={() => {
              setFilterByRegionsCheckedInternal(
                !filterByRegionsCheckedInternal
              );
              setFilterBySelectedCheckedInternal(false);
            }}
          />
        )}
        <Option
          name={i18n.t('dashboards-GroupSelect-FilterBySelected', {
            defaultValue: 'FILTER BY SELECTED',
          })}
          checked={filterBySelectedCheckedInternal}
          customStyle={() => `
                      border: 0;
                      padding-bottom: 25px;
                    `}
          customTextWrapStyle={() => `
              h3 {
                font-size: 12px;
              }
            `}
          cardProps={{
            noPadding: true,
          }}
          onCheck={() => {
            setFilterByRegionsCheckedInternal(false);
            setFilterBySelectedCheckedInternal(
              !filterBySelectedCheckedInternal
            );
          }}
        />
      </FilterBy>

      <div>
        <SortBy
          options={SORT_OPTIONS}
          selection={sortSelection}
          onSelect={(v) => handleSort(v)}
          isOpen={sortOpen}
          setIsOpen={setSortOpen}
          dataTestId="GroupRegionSelectFlyoutFilterBy"
          isFirstOption={sortSelection?.value === 'ASC'}
        />
      </div>
    </FilterByContainer>
  );
};

GroupRegionSelectFlyoutFilterBy.propTypes = {
  setAppliedFilters: PropTypes.func.isRequired,
  appliedFilters: PropTypes.object.isRequired,
  sortDirection: PropTypes.string.isRequired,
  setSortDirection: PropTypes.func.isRequired,
  allowRegions: PropTypes.bool,
};

GroupRegionSelectFlyoutFilterBy.defaultProps = {
  allowRegions: false,
};

export default GroupRegionSelectFlyoutFilterBy;
