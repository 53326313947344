import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import i18n from 'i18n-js';
import theme from 'styled-theming';
import THEMES from '../../styles/themes/library/badge';
import APP_THEMES from '../../styles/themes/app';

const PASSWORD_REQUIREMENT_FONTSIZE = theme('fontSize', {
  default: '15px',
  large: '19px',
});

const CHECKICON_FONTSIZE = theme('fontSize', {
  default: '18px',
  large: '22px',
});

const PasswordRequirementList = styled.ul`
  padding: 0;
  list-style: none;
`;

const CheckIcon = styled.i`
  font-size: ${CHECKICON_FONTSIZE};
  margin-right: 12px;
  color: ${(props) =>
    props.isValid
      ? THEMES.BACKGROUND_GREEN(props)
      : THEMES.BACKGROUND_GREY(props)};
`;

const PasswordRequirement = styled.li`
  display: flex;
  align-items: center;
  font-size: ${PASSWORD_REQUIREMENT_FONTSIZE};
  color: ${APP_THEMES.FOREGROUND_HIGH};
  line-height: 30px;
`;

export const checkLength = (password = '') => {
  if (password.length < 8) {
    return false;
  }
  return true;
};

export const checkCase = (password = '') => {
  if (
    password.toUpperCase() === password ||
    password.toLowerCase() === password
  ) {
    return false;
  }
  return true;
};

export const checkNumber = (password = '') => /\d/.test(password);

export const checkMatch = (password = '', passwordConfirm = '') =>
  !!(password === passwordConfirm && password !== '');

const PasswordRequirements = ({ password, passwordConfirm, onValidate }) => {
  const lengthValidation = checkLength(password);

  const caseValidation = checkCase(password);

  const numberValidation = checkNumber(password);

  const matchValidation = checkMatch(password, passwordConfirm);

  const passesAllValidation =
    lengthValidation && caseValidation && numberValidation && matchValidation;

  useEffect(() => {
    onValidate(passesAllValidation);
  }, [passesAllValidation]);

  return (
    <PasswordRequirementList>
      <PasswordRequirement>
        <CheckIcon
          isValid={lengthValidation}
          data-testid="8-characters-val"
          className="ri-checkbox-circle-line"
        />
        {i18n.t('signin-PasswordRequirements-passwordLength')}
      </PasswordRequirement>

      <PasswordRequirement>
        <CheckIcon
          isValid={caseValidation}
          data-testid="lower-and-upper-val"
          className="ri-checkbox-circle-line"
        />
        {i18n.t('signin-PasswordRequirements-upperCharLowerChar')}
      </PasswordRequirement>

      <PasswordRequirement>
        <CheckIcon
          isValid={numberValidation}
          data-testid="number-val"
          className="ri-checkbox-circle-line"
        />
        {i18n.t('signin-PasswordRequirements-number')}
      </PasswordRequirement>

      <PasswordRequirement>
        <CheckIcon
          isValid={matchValidation}
          data-testid="match-val"
          className="ri-checkbox-circle-line"
        />
        {i18n.t('signin-PasswordRequirements-passwordMatch')}
      </PasswordRequirement>
    </PasswordRequirementList>
  );
};

PasswordRequirements.propTypes = {
  password: PropTypes.string,
  passwordConfirm: PropTypes.string,
  onValidate: PropTypes.func,
};

PasswordRequirements.defaultProps = {
  password: '',
  passwordConfirm: '',
  onValidate: () => {},
};

export default PasswordRequirements;
