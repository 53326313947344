import type { TaskForm } from "../types";

const REQUIRED_FIELDS: (keyof TaskForm)[] = [
  "dueDate",
  "group",
  "name",
  "recipient",
];

export default REQUIRED_FIELDS;
