import type { Label } from '../../../utils/helpers/types';

export enum ANNOUNCEMENT_TYPES {
  PROMOTIONAL = 'PROMOTIONAL',
  INFORMATIONAL = 'INFORMATIONAL',
}

export enum BROADCAST_LIST {
  LIST = 'LIST',
}

export type BROADCAST_AUDIENCE_BUILDER_TYPES =
  | ANNOUNCEMENT_TYPES
  | BROADCAST_LIST;

type AudienceData = {
  announcementsOptOut: boolean;
  color: string;
  companyName: string;
  groupIds: string[];
  id: string;
  labels: Label[];
  name: string;
  promotionalBroadcastOptOut: boolean;
  type: string;
};

export type ListTagAudienceData = AudienceData & {
  __typename: 'ListTagAudienceData';
};

export type SelectedAudience = AudienceData & {
  __typename: 'SelectedAudience';
};
