import { SET_SEARCH_STATE } from '../actions/search';

const DEFAULT_STATE = {
  searchVal: '',
  resultTypeValue: 'all',
  hasSearchExecuted: false,
  viewAllCustomers: false,
  viewAllThreads: false,
  reasonForNullResults: '',
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_SEARCH_STATE:
      return {
        ...state,
        ...action.searchState,
      };

    default:
      return state;
  }
}
