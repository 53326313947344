export const SHOW_UPLOAD_PROGRESS_BAR = 'SHOW_UPLOAD_PROGRESS_BAR';
export const HIDE_UPLOAD_PROGRESS_BAR = 'HIDE_UPLOAD_PROGRESS_BAR';
export const SHOW_UPLOAD_FAILURE_BAR = 'SHOW_UPLOAD_FAILURE_BAR';
export const OPEN_UPLOAD_MODAL = 'OPEN_UPLOAD_MODAL';
export const CLOSE_UPLOAD_MODAL = 'CLOSE_UPLOAD_MODAL';
export const OPEN_ANNOTATIONS_MODAL = 'OPEN_ANNOTATIONS_MODAL';
export const CLOSE_ANNOTATIONS_MODAL = 'CLOSE_ANNOTATIONS_MODAL';
export const SET_ATTACHMENT = 'SET_ATTACHMENT';
export const UNSET_ATTACHMENT = 'UNSET_ATTACHMENT';
export const CLOSE_THREAD = 'CLOSE_THREAD';
export const CHANGE_ORIGINAL_NAME = 'CHANGE_ORIGINAL_NAME';
export const SET_DEFAULT_CONTACT = 'SET_DEFAULT_CONTACT';
export const PUSH_ATTACHMENT = 'PUSH_ATTACHMENT';
export const DELETE_ATTACHMENT = 'DELETE_ATTACHMENT';
export const CLEAR_ATTACHMENTS = 'CLEAR_ATTACHMENTS';
export const SET_ANNOTATION_ATTACHMENT_INDEX =
  'SET_ANNOTATION_ATTACHMENT_INDEX';

export const pushAttachment = (attachment) => ({
  type: PUSH_ATTACHMENT,
  attachment,
});

export const clearAttachments = () => ({
  type: CLEAR_ATTACHMENTS,
});

export const deleteAttachment = (id) => ({
  type: DELETE_ATTACHMENT,
  id,
});

export const setAnnotationAttachmentIndex = (index) => ({
  type: SET_ANNOTATION_ATTACHMENT_INDEX,
  index,
});

export const showUploadProgressBar = () => ({
  type: SHOW_UPLOAD_PROGRESS_BAR,
});
export const hideUploadProgressBar = () => ({
  type: HIDE_UPLOAD_PROGRESS_BAR,
});
export const showUploadFailureBar = () => ({
  type: SHOW_UPLOAD_FAILURE_BAR,
});
export const openUploadModal = () => ({
  type: OPEN_UPLOAD_MODAL,
});
export const closeUploadModal = () => ({
  type: CLOSE_UPLOAD_MODAL,
});
export const openAnnotationsModal = () => ({
  type: OPEN_ANNOTATIONS_MODAL,
});
export const closeAnnotationsModal = () => ({
  type: CLOSE_ANNOTATIONS_MODAL,
});
export const setAttachment = (attachment) => ({
  type: SET_ATTACHMENT,
  attachment,
});
export const unsetAttachment = () => ({
  type: UNSET_ATTACHMENT,
});
export const closeUpThread = () => ({
  type: CLOSE_THREAD,
});
export const changeFileName = (fileName) => ({
  type: CHANGE_ORIGINAL_NAME,
  fileName,
});
export const setDefaultContact = (contact) => ({
  type: SET_DEFAULT_CONTACT,
  defaultContact: contact,
});
