import { SET_ACTIVE_DELETE_MESSAGE } from '../actions/globalModals.ts';

const DEFAULT_STATE = {
  activeDeleteMessageId: '', // when an id is set, the modal will open.
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_ACTIVE_DELETE_MESSAGE:
      return {
        ...state,
        activeDeleteMessageId: action.activeDeleteMessageId,
      };

    default:
      return state;
  }
}
