import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { TextInput } from '../../elements';

const SearchBar = ({ value, setValue, placeholder, ...otherProps }) => {
  const timmy = useRef();
  const [debouncedValue, setDebouncedValue] = useState(value);

  const debounceSearch = (e) => {
    if (timmy.current) {
      clearTimeout(timmy.current);
    }

    setDebouncedValue(e.target.value);

    timmy.current = setTimeout(() => {
      setValue(e.target.value);
    }, 500);
  };

  useEffect(() => {
    setDebouncedValue(value);
  }, [value]);

  return (
    <TextInput
      value={debouncedValue}
      onChange={debounceSearch}
      placeholder={placeholder}
      iconRight
      icon={<i className="ri-search-line" />}
      {...otherProps}
    />
  );
};

SearchBar.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

SearchBar.defaultProps = {
  placeholder: '',
};

export default SearchBar;
