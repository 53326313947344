import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useMeasure from '../../../Animations/useMeasure';
import { SquareBadge } from '../../../../elements';

/**
 * MeasuredBadge is used for displaying a square badge within a MeasurableContainer
 * @param {string} dataTestId
 * @param {string} color
 * @param {string} label
 * @param {number} widthCallback - useWidthLimiter custom hook
 * @returns React component
 */
export const MeasuredBadge = ({ dataTestId, color, label, widthCallback }) => {
  const [bind, measure] = useMeasure();
  const [nullRender, setNullRender] = useState(false);

  useLayoutEffect(() => {
    if (measure?.width) {
      const widthLimit = widthCallback?.(measure?.width);
      if (widthLimit < 0) setNullRender(true);
    }
  }, [measure?.width]);

  if (nullRender) return null;

  return (
    <div {...bind}>
      {label?.customBadge ? (
        label?.customBadge
      ) : (
        <SquareBadge dataTestId={dataTestId} color={color}>
          {label}
        </SquareBadge>
      )}
    </div>
  );
};

MeasuredBadge.propTypes = {
  dataTestId: PropTypes.string.isRequired,
  color: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  widthCallback: PropTypes.func.isRequired,
};

MeasuredBadge.defaultProps = {
  color: null,
};
