import React, { useState } from 'react';
import { useHistory } from 'react-router';
import i18n from 'i18n-js';
import { useGetFeedbackStatus } from 'client-lib';
import { useApolloClient } from '@apollo/client';
import { useSelector } from 'react-redux';
import FeedbackTable from './FeedbackTable';
import { Button, Card, Heading2, Loading } from '../../elements';
import FeedbackActionBar from '../DashboardAndFeedback/ActionBar/FeedbackActionBar';
import type { AppState } from '../../utils/helpers/types';
import {
  CardContainer,
  FlexWrapper,
  Wrapper,
  ContentContainer,
  ButtonContainer,
  FeedbackOffContainer,
  HeaderStyle,
} from './styles';

const Feedback = () => {
  const client = useApolloClient();
  const history = useHistory();

  const feedbackActiveGroupIds = useSelector(
    (state: AppState) => state.session?.feedbackActiveGroupIds
  );
  const accountId = useSelector(
    (state: AppState) => state?.session?.currentUser?.accountId
  );

  const { data, loading } = useGetFeedbackStatus({ client, accountId });
  const { enabled } = data?.accountTransactionalFeedbackConfig || false;

  const [isRefetching, setIsRefetching] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(new Date().toISOString());

  const handleRefetch = () => {
    setLastUpdated(new Date().toISOString());
    setIsRefetching(true);
  };

  if (loading)
    return (
      <FlexWrapper>
        <Loading />
      </FlexWrapper>
    );

  return (
    <Wrapper>
      {enabled ? (
        <>
          <FeedbackActionBar
            activeGroupIds={feedbackActiveGroupIds}
            handleRefetch={handleRefetch}
            loading={false}
            completedDate={lastUpdated}
          />
          <FeedbackTable
            activeGroupIds={feedbackActiveGroupIds}
            isRefetching={isRefetching}
            setIsRefetching={setIsRefetching}
          />
        </>
      ) : (
        <div data-testid="feedback-empty-container">
          <Card customStyle={FeedbackOffContainer}>
            <CardContainer>
              <ContentContainer>
                <Heading2 customStyle={HeaderStyle}>
                  {i18n.t('feedback-feedbackTable-turnedOff', {
                    defaultValue:
                      'Gain actionable insights from real-time feedback to strengthen relationships and enhance customer satisfaction.',
                  })}
                </Heading2>
                <ButtonContainer>
                  <Button
                    onClick={() => {
                      history.push('/settings/feedback');
                    }}
                    dataTestId="GoTo-Settings-Button"
                  >
                    {i18n.t('settings-feedback-goToSettings', {
                      defaultValue: 'Go To Settings',
                    })}
                  </Button>
                </ButtonContainer>
              </ContentContainer>
            </CardContainer>
          </Card>
        </div>
      )}
    </Wrapper>
  );
};

export default Feedback;
