import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import i18n from 'i18n-js';
import { useDispatch, useSelector } from 'react-redux';
import {
  AVAILABLE_PERMISSIONS,
  checkIfCurrentUserHasPermission,
  useExportGroupTableData,
} from 'client-lib';
import { Modal, Select } from '../../elements';
import GroupRegionAsyncSelect from '../AsyncSelects/GroupRegionAsyncSelect';
import GroupAsyncSelect from '../AsyncSelects/GroupAsyncSelect';
import useGetUserAccountPolicies from '../../hooks/customer/useGetUserAccountPolicies';
import { openSnackbar } from '../../actions/general';

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SelectGroupsWrapper = styled.div`
  padding-top: 8px;
`;

export const ExportGroupTableDataModal = ({
  openModal,
  setOpenModal,
  activeGroupIds,
  timeFrame,
  timeFrameOptions,
  setTimeFrame,
  client,
}) => {
  const dispatch = useDispatch();

  const allGroups = useSelector((state) => state.accountData.allGroups);

  const accountPolicies = useSelector(
    (state) => state?.session?.currentUser?.accountPolicies
  );
  const groupPolicies = useSelector(
    (state) => state?.session?.currentUser?.groupPolicies
  );
  const userId = useSelector((state) => state?.session?.currentUser?.userId);

  const [groupValue, setGroupValue] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const handleGroupSelect = (e) => {
    setGroupValue(e);
  };

  const validate = () => {
    /**
     * if there is no group(s) selected ALWAYS disable submission
     * else go to the date validation
     */
    if (!groupValue.length) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  };

  useEffect(() => {
    validate();
  }, [groupValue]);

  useEffect(() => {
    if (activeGroupIds && allGroups?.length) {
      if (activeGroupIds === 'allGroups') {
        setGroupValue([]);
      } else {
        const activeGroupTabs = allGroups
          .filter((group) => {
            return activeGroupIds.includes(group.id);
          })
          .map((activeGroupTab) => {
            return {
              value: activeGroupTab?.id,
              label: activeGroupTab?.name,
              typename: activeGroupTab?.__typename,
              groups: activeGroupTab?.groups || [],
            };
          });

        setGroupValue(activeGroupTabs);
      }
    }
  }, [activeGroupIds, allGroups, openModal]);

  const excelSnackbarMessage = i18n.t(
    'dashboards-ExportGroupTableDataModal-snackbar'
  );

  const excelSnackbarErrorMessage = i18n.t(
    'reports-ReportsContainer-downloadExcel-error'
  );

  const handleDownloadExcelSuccess = () => {
    dispatch(openSnackbar(excelSnackbarMessage));
  };

  const handleDownloadExcelError = () => {
    dispatch(openSnackbar(excelSnackbarErrorMessage, 'error'));
  };

  const { handleDownloadExcelClick } = useExportGroupTableData(client);

  const handleSubmit = () => {
    setOpenModal(false);
    handleDownloadExcelClick(
      timeFrame.value,
      groupValue,
      handleDownloadExcelSuccess,
      handleDownloadExcelError,
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
  };

  useGetUserAccountPolicies({
    actionList: [AVAILABLE_PERMISSIONS.READ_REGIONS],
    userId,
  });

  const hasRegionPermission = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.READ_REGIONS,
    accountPolicies,
    groupPolicies
  );

  return (
    <Modal
      size="md"
      modalTitle={i18n.t('reports-Reports-export')}
      isOpen={openModal}
      primaryButtonText={i18n.t('modals-DocumentForm-submit')}
      primaryButtonOnClick={handleSubmit}
      secondaryButtonText={i18n.t('slideouts-GroupMessageOverview-cancel')}
      secondaryButtonOnClick={() => setOpenModal(false)}
      onRequestClose={() => setOpenModal(false)}
      primaryButtonProps={{
        disabled: submitDisabled,
        dataTestId: 'dashboards-ExportGroupTableDataModal-submit',
      }}
      secondaryButtonProps={{
        dataTestId: 'dashboards-ExportGroupTableDataModal-cancel',
      }}
    >
      <ModalContentWrapper>
        <Select
          dataTestId="dashboard-ExportGroupTableDataModal-dropdown"
          options={timeFrameOptions}
          value={timeFrame}
          onChange={(option) => setTimeFrame(option)}
          hideBottomSpace
        />

        <SelectGroupsWrapper>
          {hasRegionPermission ? (
            <GroupRegionAsyncSelect
              dataTestId="dashboard-multiGroupTableData-modal-region-group-selector"
              value={groupValue || []}
              setValue={handleGroupSelect}
              label={i18n.t('settings-GroupsOrRegions-label', {
                defaultValue: 'Groups Or Regions',
              })}
              placeholder={i18n.t(
                'settings-GroupsAndRegions-searchPlaceholder'
              )}
              isMulti
            />
          ) : (
            <GroupAsyncSelect
              dataTestId="dashboard-multiGroupTableData-modal-group-selector"
              value={groupValue || []}
              setValue={handleGroupSelect}
              label={i18n.t('settings-TeamMemberForm-groups', {
                defaultValue: 'Groups',
              })}
              placeholder={i18n.t(
                'settings-GroupDropDown-groupDropDownPlaceholder'
              )}
              isMulti
            />
          )}
        </SelectGroupsWrapper>
      </ModalContentWrapper>
    </Modal>
  );
};

ExportGroupTableDataModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  activeGroupIds: PropTypes.array.isRequired,
  timeFrame: PropTypes.object.isRequired,
  timeFrameOptions: PropTypes.array.isRequired,
  setTimeFrame: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
};

export default ExportGroupTableDataModal;
