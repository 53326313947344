import React, { useState, useEffect, type SetStateAction } from 'react';
import styled from 'styled-components';
import { TableBody } from '@mui/material';
import i18n from 'i18n-js';
import {
  SORT_DIRECTION,
  usePaginated,
  FEEDBACK_QUERY,
  type ApolloClientLib,
} from 'client-lib';
import { useApolloClient } from '@apollo/client';
import { useRouteMatch } from 'react-router-dom';
import {
  Card,
  Heading2,
  Loading,
  SortBy,
  Table,
  TableHead,
} from '../../elements';
import FeedbackDetailsCard from './FeedbackDetails/FeedbackDetailsCard';
import FeedbackTableRow from './FeedbackTableRow';
import PaginationButtonsWrapper from '../Common/PaginationButtonsWrapper.js';
import type { Feedback } from './types';

const getTableWrapper = () => `
  margin-top: 16px;
  max-height: calc(100vh - 272px);
  overflow: hidden;

  thead, tbody, tr, th, td {
    box-sizing: border-box;
  }
`;

const SortByWrapper = styled.div`
  margin-bottom: 16px;
`;

const TableBodyWrapper = styled.div`
  height: calc(100vh - 366px);
  overflow: auto;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const CardContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
`;

const SortByWrapperContainer = styled.div`
  flex: 7;
`;

const getPaginationStyles = () => `
  border-top: none;
  & > div:first-child {
    padding: 14px;
  }
`;

const getNoResultStyling = () => `
  text-align: center;
  font-weight: 500;
  padding: 54px 10px;
`;

const StyledTH = styled.th`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PAGE_ROW_LIMIT = 20;

interface FeedbackTableProps {
  activeGroupIds: string[];
  isRefetching: boolean;
  setIsRefetching: React.Dispatch<SetStateAction<boolean>>;
}

const FeedbackTable = ({
  activeGroupIds,
  isRefetching,
  setIsRefetching,
}: FeedbackTableProps) => {
  const match = useRouteMatch();
  const client = useApolloClient() as unknown as ApolloClientLib;
  const [page, setPage] = useState(1);
  const [sortOpen, setSortOpen] = useState(false);
  const [sortDirection, setSortDirection] = useState(SORT_DIRECTION.descending);

  const SORT_OPTIONS = [
    {
      label: i18n.t('customers-ActivitiesHeader-newestFirst', {
        defaultValue: 'Newest First',
      }),
      value: SORT_DIRECTION.descending,
    },
    {
      label: i18n.t('customers-ActivitiesHeader-oldestFirst', {
        defaultValue: 'Oldest First',
      }),
      value: SORT_DIRECTION.ascending,
    },
  ];
  const sortSelection = SORT_OPTIONS.find(
    (option) => option.value === sortDirection
  );

  const {
    feedbackResponses,
    handleFetchMore,
    handlePrevious,
    pageInfo,
    loading,
    refetch,
  } = usePaginated({
    client,
    query: FEEDBACK_QUERY,
    key: 'feedbackResponses',
    queryVariables: {
      sortDirection,
      groupIds: activeGroupIds,
      isComplete: true,
    },
    resultsNumber: PAGE_ROW_LIMIT,
  });

  const handleSort = (selection: { value: string }) => {
    setSortDirection(selection.value);
  };

  const handleNextPage = async () => {
    const newPage = page + 1;
    setPage(newPage);
    handleFetchMore();
  };

  const handlePrevPage = async () => {
    const newPage = page - 1;
    setPage(newPage);
    handlePrevious({});
  };

  useEffect(() => {
    if (isRefetching && refetch) {
      refetch();
      setIsRefetching(false);
      setPage(1);
    }
  }, [isRefetching, refetch, setIsRefetching]);

  const matchSelected = (feedbackResponses as Feedback[]).find(
    ({ id }) => id === (match?.params as { feedbackId?: string })?.feedbackId
  );

  return (
    <>
      <Card customStyle={getTableWrapper}>
        <CardContentContainer>
          <SortByWrapperContainer>
            <SortByWrapper>
              <SortBy
                bubbleProps={{ moveBubbleRightVal: 100 }}
                options={SORT_OPTIONS}
                selection={sortSelection}
                onSelect={(val: { value: string }) => handleSort(val)}
                isOpen={sortOpen}
                setIsOpen={setSortOpen}
                dataTestId="feedback-sort"
                isFirstOption={sortSelection?.value === 'ASC'}
              />
            </SortByWrapper>
            <Table>
              <TableHead>
                <tr>
                  <StyledTH>
                    {i18n.t('settings-FinalMessage-customer', {
                      defaultValue: 'Contact',
                    })}
                  </StyledTH>
                  <StyledTH>
                    {i18n.t('settings-Support-feedback', {
                      defaultValue: 'Feedback',
                    })}
                  </StyledTH>
                </tr>
              </TableHead>
            </Table>
            {loading ? (
              <LoadingWrapper>
                <Loading />
              </LoadingWrapper>
            ) : (
              <TableBodyWrapper>
                <Table>
                  <TableBody>
                    {feedbackResponses?.length ? (
                      feedbackResponses.map((row: Feedback) => (
                        <FeedbackTableRow
                          key={row.id}
                          row={row}
                          selected={matchSelected}
                        />
                      ))
                    ) : (
                      <Heading2 contrast="low" customStyle={getNoResultStyling}>
                        {i18n.t('customers-InfiniteScroll-noResultsFound', {
                          defaultValue: 'No results found',
                        })}
                      </Heading2>
                    )}
                  </TableBody>
                </Table>
              </TableBodyWrapper>
            )}
          </SortByWrapperContainer>
          {matchSelected?.id ? (
            <FeedbackDetailsCard dataTestId="feedback-details-card" />
          ) : null}
        </CardContentContainer>
      </Card>

      <Card customStyle={getPaginationStyles}>
        <PaginationButtonsWrapper
          handlePrevious={handlePrevPage}
          handleNext={handleNextPage}
          pageInfo={pageInfo}
          showPageCount
          page={page}
          totalPages={Math.ceil(pageInfo.totalCount / PAGE_ROW_LIMIT)}
        />
      </Card>
    </>
  );
};

export default FeedbackTable;
