import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Avatar, Button, Heading3, IconButton } from '../../../../../elements';
import THEMES from '../../../../../styles/themes/app';

const OverviewItemContainer = styled.div`
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  box-shadow: ${THEMES.BOX_SHADOW};
  width: 100%;
  display: flex;
  padding: 16px 16px;
  box-sizing: border-box;
`;

const IconTitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const TitleContainer = styled.div`
  flex: 1;
`;

const IconContainer = styled.div`
  margin-right: 20px;
`;

const CheckmarkWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  color: ${THEMES.THEME_GREEN};
  margin-right: 8px;
  font-size: 24px;
`;

const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

const ArrowIcon = styled.i`
  font-size: 24px;
`;

const getTitleContainer = (title, subTitle) => (
  <TitleContainer>
    <Heading3>{title}</Heading3>
    <div>{subTitle}</div>
  </TitleContainer>
);

const OverviewItem = ({
  title,
  subTitle,
  completed,
  onClickArrow,
  children,
  allowNextStep,
  sentAnnouncement,
  useButton,
  buttonText,
  hasIcon,
  iconName,
}) => (
  <>
    <OverviewItemContainer>
      {!hasIcon && getTitleContainer(title, subTitle)}
      {!!hasIcon && (
        <IconTitleContainer>
          <IconContainer>
            <Avatar
              icon={iconName}
              type={completed ? undefined : 'threadRead'}
            />
          </IconContainer>
          {getTitleContainer(title, subTitle)}
        </IconTitleContainer>
      )}

      {allowNextStep ? (
        (!useButton && completed) || sentAnnouncement ? (
          <CheckmarkWrapper>
            <i className="ri-checkbox-circle-fill" />
          </CheckmarkWrapper>
        ) : (
          <ArrowWrapper>
            {!!useButton && (
              <Button type="secondaryLight" onClick={onClickArrow}>
                {buttonText}
              </Button>
            )}
            {!useButton && (
              <IconButton
                noOutline
                onClick={onClickArrow}
                dataTestId="gmo-goto-arrow"
                contrast="highColor"
              >
                <ArrowIcon className="ri-arrow-right-line" />
              </IconButton>
            )}
          </ArrowWrapper>
        )
      ) : null}
    </OverviewItemContainer>
    {children}
    <div style={{ height: '30px', minHeight: '30px', minWidth: '30px' }} />
  </>
);

OverviewItem.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  completed: PropTypes.bool.isRequired,
  onClickArrow: PropTypes.func.isRequired,
  allowNextStep: PropTypes.bool.isRequired,
  children: PropTypes.element,
  sentAnnouncement: PropTypes.bool,
  useButton: PropTypes.bool,
  buttonText: PropTypes.string,
  hasIcon: PropTypes.bool,
  iconName: PropTypes.string,
};

OverviewItem.defaultProps = {
  children: null,
  sentAnnouncement: false,
  useButton: false,
  buttonText: '',
  hasIcon: false,
  iconName: '',
};

export default OverviewItem;
