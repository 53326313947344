import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import i18n from 'i18n-js';
import styled from 'styled-components';
import { urlRegex } from 'client-lib/src/lib/utils/regex';
import { TextInput, Modal } from '../../elements';

const AreaContainer = styled(Grid)`
  min-width: 426px;
`;

const InputWrapper = styled(Grid)`
  width: 100%;
`;

const InsertLink = ({ open, handleOnClose, handleOnConfirm, value = '' }) => {
  const [text, setText] = useState(value);
  const [error, setError] = useState('');

  useEffect(() => {
    setText(value);
  }, [value]);

  const validateIsURL = (url) => {
    const pattern = new RegExp(urlRegex); // fragment locator
    return pattern.test(url);
  };

  const saveChanges = () => {
    if (!validateIsURL(text)) {
      setError(
        i18n.t('slideouts-GroupMessageContent-link-error') +
          ' https://www.example.com'
      );
      return;
    }
    handleOnConfirm(text);
    handleOnClose();
  };

  const handleInputTagChange = (e) => {
    setText(e.target.value);
  };

  return (
    <Modal
      size="sm"
      isOpen={open}
      onRequestClose={handleOnClose}
      modalTitle={i18n.t('slideouts-PaymentForm-insertLink')}
      primaryButtonText={i18n.t('slideouts-GroupMessageContent-insert')}
      primaryButtonOnClick={saveChanges}
      secondaryButtonText={i18n.t('slideouts-GroupMessageOverview-cancel')}
      secondaryButtonOnClick={handleOnClose}
      primaryButtonProps={{ disabled: false }}
    >
      <Grid container>
        <AreaContainer data-testid="modal-shortner-text">
          {i18n.t('slideouts-GroupMessageContent-shortner')}
        </AreaContainer>
        <InputWrapper>
          <TextInput
            type="text"
            value={text}
            label={i18n.t('slideouts-PaymentForm-insertLink')}
            error={error}
            onChange={handleInputTagChange}
            placeholder={i18n.t('slideouts-GroupMessageContent-pasteLinkHere')}
            autoFocus
            dataTestId="modal-shortner-link-text"
          />
        </InputWrapper>
      </Grid>
    </Modal>
  );
};

export default InsertLink;

InsertLink.propTypes = {
  open: PropTypes.bool,
  handleOnClose: PropTypes.func,
  handleOnConfirm: PropTypes.func,
  value: PropTypes.string,
};

InsertLink.defaultProps = {
  open: false,
  handleOnClose: () => {},
  handleOnConfirm: () => {},
  value: '',
};
