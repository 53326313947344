import React from 'react';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TextArea, TextInput } from '../../../../elements';

const EditListFlyoutContaienr = styled.div`
  height: 220px;
  width: 100%;
`;

const EditBroadcastListFlyout = ({
  name,
  setName,
  description,
  setDescription,
  nameError,
  descriptionError,
}) => {
  return (
    <EditListFlyoutContaienr>
      <TextInput
        label={i18n.t('modals-AddOrEditList-listName')}
        type="text"
        value={name}
        autoComplete="off"
        onChange={(ev) => setName(ev)}
        dataTestId="modal-editName-listFlyout"
        error={nameError}
      />
      <TextArea
        label={i18n.t('broadcasts-list-descriptionOptional', {
          defaultValue: 'description(optional)',
        })}
        value={description}
        rows={4}
        onChange={(ev) => setDescription(ev)}
        dataTestId="modal-editDescription-listFlyout"
        error={descriptionError}
      />
    </EditListFlyoutContaienr>
  );
};

EditBroadcastListFlyout.propTypes = {
  name: PropTypes.string,
  setName: PropTypes.func,
  description: PropTypes.string,
  setDescription: PropTypes.func,
  nameError: PropTypes.string,
  descriptionError: PropTypes.string,
};

EditBroadcastListFlyout.defaultProps = {
  name: '',
  description: '',
  setDescription: () => {},
  setName: () => {},
  nameError: '',
  descriptionError: '',
};

export default EditBroadcastListFlyout;
