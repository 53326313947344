import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import OPEN_THREAD_COUNT_FOR_USER_TOTAL from "../../../graphql/query/OpenThreadCountForUserTotalQuery.graphql";
import { OPEN_THREAD_COUNT_FOR_USER_BY_USER } from "../../..";
import {
  DEFAULT_DASHBOARD_WIDGET_ROW_COUNT,
  getOpenThreadCountUpperBound,
} from "../../../utils/helpers";

function useOpenThreadsByUserWidget({
  client,
  groupId,
  TOP_N = DEFAULT_DASHBOARD_WIDGET_ROW_COUNT,
}) {
  const {
    data: openThreadCountTotalData,
    loading: openThreadCountTotalLoading,
    refetch: refetchOpenThreadCountTotal,
  } = useQuery(OPEN_THREAD_COUNT_FOR_USER_TOTAL, {
    client,
    variables: { groupIds: [groupId] },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: openThreadCountByUserData,
    loading: openThreadCountByUserLoading,
    refetch: refetchOpenThreadCountByUser,
  } = useQuery(OPEN_THREAD_COUNT_FOR_USER_BY_USER, {
    client,
    variables: { topN: TOP_N, groupIds: [groupId] },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const openThreadCountTotal =
    openThreadCountTotalData?.openThreadCountForUserTotal?.count;
  const openThreadCountByUser =
    openThreadCountByUserData?.openThreadCountForUserByUser;
  const widgetsLoading =
    openThreadCountTotalLoading || openThreadCountByUserLoading;

  // PRKP-4842 - Open threads by user widget now uses the highest open thread count
  // as the total open thread count for the group
  // Counts are return in descending order of open threads
  const openThreadCountUpperBound = useMemo(
    () => getOpenThreadCountUpperBound(openThreadCountByUser),
    [openThreadCountByUser]
  );

  const refetchOpenThreadsByUser = () => {
    refetchOpenThreadCountTotal();
    refetchOpenThreadCountByUser();
  };

  return {
    openThreadCountTotal,
    openThreadCountUpperBound,
    openThreadCountByUser,
    widgetsLoading,
    refetchOpenThreadsByUser,
  };
}

export default useOpenThreadsByUserWidget;
