import { compressToBase64, decompressFromBase64 } from 'lz-string';

const LS_KEY_NAME = 'distributor_session';

export const loadState = () => {
  try {
    const compressedState = localStorage.getItem(LS_KEY_NAME);
    const serializedState = decompressFromBase64(compressedState);
    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    const compressedState = compressToBase64(serializedState);
    localStorage.setItem(LS_KEY_NAME, compressedState);
  } catch (err) {
    // Ignore write errors.
  }
};

export const clearState = () => {
  try {
    localStorage.removeItem(LS_KEY_NAME);
  } catch (err) {
    // Ignore
  }
};

/**
 * Store a value in local storage with an optional TTL
 * @param {string} keyname Name of the value to set
 * @param {string|object<t>} value value to store
 * @param {number} secondsToLive (optional) seconds to let the value live when provided, old values will not be returned
 */
export const saveLocalValue = (keyname, value, secondsToLive = null) => {
  const ttl = secondsToLive ? Date.now() + secondsToLive * 1000 : null;
  const stringifiedValue = JSON.stringify({ value, ttl });
  localStorage.setItem(keyname, stringifiedValue);
};
/**
 * Get a value from local storage; if TTL is set and expired, remove the value
 * and return null
 * @param {string} keyname Keyname to get from local storage
 * @returns {string|object<t>|null} value from local storage or null if not found or expired
 */
export const getLocalValue = (keyname) => {
  try {
    const stringifiedValue = localStorage.getItem(keyname);
    if (!stringifiedValue) return null;
    const parsedValue = JSON.parse(stringifiedValue);

    // If the value has a TTL and it has expired, remove it from local storage
    const ttl = parsedValue?.ttl;
    if (ttl && ttl < Date.now()) {
      removeLocalValue(keyname);
      return null;
    }

    return parsedValue.value;
  } catch (e) {
    console.error('Error getting value from local storage', e);
    return null;
  }
};
/**
 * Remove a value from local storage
 * @param {string} keyname Keyname to remove from local storage
 */
export const removeLocalValue = (keyname) => {
  try {
    localStorage.removeItem(keyname);
  } catch (err) {
    console.error('unable to remove local value: ', keyname, err);
  }
};
