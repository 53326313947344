import { useQuery } from "@apollo/client";
import BANNERS_QUERY from "../../../graphql/query/ListBannersQuery.graphql";

/*
DOCUMENTATION

this hook is meant to be used for banners query

args:
  client: (obj) required. apolloClient instance
  ...options: other apollo options dev wants

returns:
  apolloQuery: (obj) make of loading, error and data

*/

const useBannersQuery = ({ client, ...options }) =>
  useQuery(BANNERS_QUERY, { client, ...options });

export default useBannersQuery;
