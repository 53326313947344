import useAccountQuery from "../api/query/useAccountQuery";

const useWhitelabelFeature = ({ client, currentUser }) => {
  const { data: accountData } = useAccountQuery({
    client,
    variables: {
      id: currentUser?.accountId,
    },
    skip: !currentUser,
  });

  const whitelabel = accountData?.account?.ff_whitelabel;
  const whitelabelLogo = accountData?.account?.ff_whitelabel_logo;
  const whitelabelTabTitle = accountData?.account?.ff_whitelabel_tab_title;

  return {
    whitelabel,
    whitelabelLogo,
    whitelabelTabTitle,
  };
};

export default useWhitelabelFeature;
