import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import OPEN_THREAD_COUNT_FOR_USER_BY_GROUP from "../../../graphql/query/OpenThreadCountForUserByGroup.graphql";
import OPEN_THREAD_COUNT_FOR_USER_TOTAL from "../../../graphql/query/OpenThreadCountForUserTotalQuery.graphql";
import {
  DEFAULT_DASHBOARD_WIDGET_ROW_COUNT,
  getOpenThreadCountUpperBound,
} from "../../../utils/helpers";

const TOP_N = DEFAULT_DASHBOARD_WIDGET_ROW_COUNT;

function useOpenThreadsWidget({ client, topN, groupIds }) {
  const {
    data: openThreadCountTotalData,
    loading: openThreadCountTotalLoading,
    refetch: refetchOpenThreadCountTotal,
  } = useQuery(OPEN_THREAD_COUNT_FOR_USER_TOTAL, {
    client,
    variables: { groupIds },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: openThreadCountByGroupData,
    loading: openThreadCountByGroupLoading,
    refetch: refetchOpenThreadCountByGroup,
  } = useQuery(OPEN_THREAD_COUNT_FOR_USER_BY_GROUP, {
    client,
    variables: { topN: topN || TOP_N, groupIds },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const openThreadCountTotal =
    openThreadCountTotalData?.openThreadCountForUserTotal?.count;
  const openThreadCountByGroup =
    openThreadCountByGroupData?.openThreadCountForUserByGroup;
  const widgetsLoading =
    openThreadCountTotalLoading || openThreadCountByGroupLoading;
  const openThreadCountGroupIds = openThreadCountByGroup?.counts?.map(
    (count) => count.groupId
  );

  const openThreadCountUpperBound = useMemo(
    () => getOpenThreadCountUpperBound(openThreadCountByGroup),
    [openThreadCountByGroup]
  );

  const refetchOpenThreads = () => {
    refetchOpenThreadCountTotal();
    refetchOpenThreadCountByGroup();
  };

  return {
    openThreadCountUpperBound,
    openThreadCountTotal,
    openThreadCountByGroup,
    widgetsLoading,
    openThreadCountGroupIds,
    refetchOpenThreads,
  };
}

export default useOpenThreadsWidget;
