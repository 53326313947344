import React, { useContext } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { useSpring, animated } from 'react-spring';
import Card from '../../elements/Card/Card';
import IconButton from '../../elements/Button/IconButton';
import THEMES from '../../styles/themes/app';

const InnerContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
`;

const Flyout = ({
  children,
  isOpen,
  direction,
  onRequestClose,
  modalTitle,
  modalTitleIcon,
  noPadding,
  disableOverlay,
  customFlyoutStyle,
  customHeaderContainerStyle,
  overflowScroll,
  subText,
  ...props
}) => {
  const styledTheme = useContext(ThemeContext);

  const animation = useSpring({
    position: 'absolute',
    top: 0,
    [direction]: isOpen ? 0 : -300,
    backgroundColor: `${THEMES.BACKGROUND_PRIMARY(styledTheme)}`,
    width: 'calc(100vw / 3)',
    height: '100vh',
    boxShadow: '-4px 0px 4px rgba(0, 0, 0, 0.25)',
    ...customFlyoutStyle,
  });

  const closeButton = (
    { onClick } // eslint-disable-line react/prop-types
  ) => (
    <IconButton size="lg" onClick={onClick}>
      <i className="ri-close-line" />
    </IconButton>
  );

  return (
    <ReactModal
      style={{
        overlay: {
          backgroundColor: disableOverlay
            ? 'transparent'
            : 'rgba(0, 0, 0, 0.75)',
          zIndex: '11',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        },
      }}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      css={(props) => props?.customStyle?.(props)} // eslint-disable-line react/prop-types
      {...props}
    >
      <animated.div style={animation}>
        <Card
          headerText={modalTitle}
          headerIcon={modalTitleIcon}
          customHeaderButton={closeButton}
          headerButtonOnClick={onRequestClose}
          subText={subText}
          noPadding={noPadding}
          customStyle={(props) => `
            border-radius: 4px;
            border-width: 0;
            height: 100vh;
            box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.25);
            background-color: ${THEMES.BACKGROUND_PRIMARY(props)};
            overflow-y: ${overflowScroll ? 'auto' : ''};
          `}
          customHeaderContainerStyle={(props) => `
            background-color: ${THEMES.BACKGROUND_SECONDARY(props)};
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
            height: 50px;
            align-items: center;
            padding-left: 24px;
            padding-right: 24px;
            ${customHeaderContainerStyle?.(props)}
            `}
        >
          <InnerContainer>{children}</InnerContainer>
        </Card>
      </animated.div>
    </ReactModal>
  );
};
Flyout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  direction: PropTypes.string,
  onRequestClose: PropTypes.func.isRequired,
  modalTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  modalTitleIcon: PropTypes.func,
  disableOverlay: PropTypes.bool,
  customFlyoutStyle: PropTypes.object,
  customHeaderContainerStyle: PropTypes.func,
  overflowScroll: PropTypes.bool,
  subText: PropTypes.string,
  noPadding: PropTypes.bool,
};
Flyout.defaultProps = {
  direction: 'right',
  modalTitle: 'Flyout Title',
  modalTitleIcon: null,
  disableOverlay: false,
  customFlyoutStyle: null,
  customHeaderContainerStyle: () => {},
  overflowScroll: false,
  subText: '',
  noPadding: false,
};
export default Flyout;
