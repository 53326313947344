import React from 'react';
import PropTypes from 'prop-types';
import { contactName, slugify } from 'client-lib/src/lib/utils/helpers';
import { EntityCard } from '../../..';
import THEMES from '../../../../styles/themes/library/textInput';
import { entityCardSuggestionCustomStyles } from '../UniversalSuggestion';

const UserSuggestion = ({ suggestion, isHighlighted, includePresence }) => (
  <EntityCard
    avatarChildren={contactName(suggestion)}
    maintext={contactName(suggestion)}
    subtext={suggestion?.title}
    avatarProps={{
      avatarUrl: suggestion.avatarUrl,
      size: 'md',
      type: 'internal',
      displayPresence: includePresence,
      active: suggestion.online,
      icon: 'user',
    }}
    backgroundColor={
      isHighlighted ? THEMES.OPTION_BACKGROUND_HOVER : THEMES.OPTION_BACKGROUND
    }
    maintextColor={
      isHighlighted ? THEMES.OPTION_TEXT_HOVER : THEMES.OPTION_TEXT
    }
    customContainerStyle={entityCardSuggestionCustomStyles}
    dataTestId={`infinite-user-suggestion-${slugify(contactName(suggestion), {
      lower: true,
    })}`}
  />
);

UserSuggestion.propTypes = {
  suggestion: PropTypes.object.isRequired,
  isHighlighted: PropTypes.bool,
  includePresence: PropTypes.bool,
};

UserSuggestion.defaultProps = {
  isHighlighted: false,
  includePresence: false,
};

export default UserSuggestion;
