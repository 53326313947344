import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import THEMES from '../../styles/themes/app';
import BADGE_THEMES from '../../styles/themes/library/badge';
import FONTSIZE_THEMES from '../../styles/themes/fontSize/fontSize';

const calcWidthHeight = (props) => (props.children ? '16px' : '12px');

const BadgeStyle = styled.div`
  min-width: ${calcWidthHeight};
  max-width: ${calcWidthHeight};
  height: ${calcWidthHeight};
  background-color: ${BADGE_THEMES.BACKGROUND};
  border: solid 1px ${(props) => props.badgeBorderColor};
  border-radius: 10px;
  color: ${BADGE_THEMES.TEXT};
  font-family: Sans-serif;
  font-size: ${FONTSIZE_THEMES.BADGETEXT};
  font-weight: 400;
  line-height: ${calcWidthHeight};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
`;

// for ease of access, the values to make Badge appear in correct placement for Sidebar Icons are:
// {
//   position: absolute;
//   top: 12px;
//   right: -6px;
// }
// place these vals in a wrapper, not in Badge itself.

const Badge = ({ children, badgeBorderColor, dataTestId, ...otherProps }) => (
  <BadgeStyle
    badgeBorderColor={badgeBorderColor}
    css={(props) => props?.customStyle?.(props)} // eslint-disable-line react/prop-types
    data-testid={dataTestId}
    {...otherProps}
  >
    {children}
  </BadgeStyle>
);

Badge.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  badgeBorderColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  dataTestId: PropTypes.string,
};

Badge.defaultProps = {
  children: null,
  badgeBorderColor: THEMES.BACKGROUND_PRIMARY,
  dataTestId: null,
};

export default Badge;
