import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import {
  WidgetLoadingContainer,
  WidgetWrapper,
  DoubleColumnWrapper,
  SingleColumnWrapper,
} from './styles';
import { Loading } from '../../elements';
import UnclaimedSingleGroupWidgetContainer from './UnclaimedSingleGroupWidgetContainer';
import OpenSingleGroupWidgetContainer from './OpenSingleGroupWidgetContainer';
import DoughnutWidget from './DoughnutWidget';
import SingleChannelWidget from './SingleChannelWidget';
import SingleGroupFlyout from './SingleGroupFlyout';

const SingleGroupThreadWidgetsContainer = ({
  openTotal,
  openUsers,
  openUpperBound,
  openWidgetLoading,
  unclaimedTotal,
  unclaimedThreads,
  unclaimedWidgetLoading,
  activeTotal,
  activeData,
  activeWidgetLoading,
  openConvoFlyoutOpen,
  setOpenConvoFlyoutOpen,
}) => {
  return (
    <WidgetWrapper>
      <SingleColumnWrapper>
        {unclaimedWidgetLoading ? (
          <WidgetLoadingContainer>
            <Loading />
          </WidgetLoadingContainer>
        ) : (
          <UnclaimedSingleGroupWidgetContainer
            unclaimedThreads={unclaimedThreads}
            unclaimedTotal={unclaimedTotal}
            breakpointWidth="100%"
          />
        )}
      </SingleColumnWrapper>
      <DoubleColumnWrapper>
        {openWidgetLoading ? (
          <WidgetLoadingContainer>
            <Loading />
          </WidgetLoadingContainer>
        ) : (
          <OpenSingleGroupWidgetContainer
            openTotal={openTotal}
            openUsers={openUsers}
            openUpperBound={openUpperBound}
            breakpointWidth="100%"
            setOpenConvoFlyoutOpen={setOpenConvoFlyoutOpen}
          />
        )}
        {activeWidgetLoading ? (
          <WidgetLoadingContainer>
            <Loading />
          </WidgetLoadingContainer>
        ) : activeData.length === 1 ? (
          <SingleChannelWidget
            total={activeTotal}
            singleChannelWidgetData={activeData}
          />
        ) : (
          <DoughnutWidget
            title={i18n.t('threads-DougnutWidget-activeThreadHeader', {
              defaultValue: 'Active conversations across your channels',
            })}
            total={activeTotal}
            doughnutWidgetData={activeData}
            breakpointWidth="100%"
            emptyDoughnutMessage={i18n.t(
              'threads-DougnutWidget-noActiveThreads',
              {
                defaultValue: 'No active conversations!',
              }
            )}
          />
        )}
      </DoubleColumnWrapper>
      {openConvoFlyoutOpen ? (
        <SingleGroupFlyout
          openConvoFlyoutOpen={openConvoFlyoutOpen}
          setOpenConvoFlyoutOpen={setOpenConvoFlyoutOpen}
        />
      ) : null}
    </WidgetWrapper>
  );
};

SingleGroupThreadWidgetsContainer.propTypes = {
  openTotal: PropTypes.number,
  openUsers: PropTypes.object,
  openUpperBound: PropTypes.number,
  openWidgetLoading: PropTypes.bool.isRequired,
  unclaimedTotal: PropTypes.number,
  unclaimedThreads: PropTypes.object,
  unclaimedWidgetLoading: PropTypes.bool.isRequired,
  activeTotal: PropTypes.number,
  activeData: PropTypes.array,
  activeWidgetLoading: PropTypes.bool,
  openConvoFlyoutOpen: PropTypes.bool.isRequired,
  setOpenConvoFlyoutOpen: PropTypes.func.isRequired,
};

SingleGroupThreadWidgetsContainer.defaultProps = {
  openTotal: undefined,
  openUsers: null,
  openUpperBound: undefined,
  unclaimedTotal: undefined,
  unclaimedThreads: null,
  activeTotal: undefined,
  activeData: null,
  activeWidgetLoading: false,
};

export default SingleGroupThreadWidgetsContainer;
