import styled from 'styled-components';
import { Link } from 'react-router-dom';
import THEMES from '../../styles/themes/app';
import { Text } from '../../elements';

export const LoadingWrapper = styled.div`
  min-width: 400px;
  max-width: 960px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  min-width: 400px;
  max-width: 960px;
`;

export const WideWrapper = styled.div`
  min-width: 400px;
  max-width: 1080px;
`;

export const HeaderWrapper = styled.div`
  margin-bottom: 24px;
`;

export const Header = styled.div`
  max-width: ${(props) => (props.noMaxWidth ? 'none' : '960px')};
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    min-height: 5rem;
  }
  text-transform: capitalize;
  justify-content: space-between;
  align-items: center;
  line-height: 0;
`;

export const Instructions = styled.p`
  color: ${THEMES.FOREGROUND_HIGH};
  font-size: 15px;
`;

export const OrderedInstructions = styled.ol`
  padding-left: 0;
  list-style-position: inside;

  & li {
    margin-bottom: 10px;
  }
`;

export const MessageContainer = styled.div`
  max-width: 960px;
  width: fit-content;
  min-height: 48px;
  margin-bottom: 20px;
  position: fixed;
  top: 0;
  bottom: auto;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
`;

// Message defaults to Error styling unless passed 'success' as props
export const Message = styled(Text)`
  width: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  padding: 15px;
  color: ${(props) =>
    props.success
      ? THEMES.COLORED_FOREGROUND_HIGH(props)
      : THEMES.FOREGROUND_HIGH(props)};
  background-color: ${(props) =>
    props.success ? THEMES.THEME_GREEN(props) : THEMES.THEME_YELLOW(props)};
  border: solid 1px
    ${(props) =>
      props.success ? THEMES.THEME_GREEN(props) : THEMES.THEME_YELLOW(props)};
  border-radius: 4px;
  opacity: 0;
  transition:
    visibility 0s 0.5s,
    opacity 0.5s linear;
  &.active {
    opacity: 1;
    width: 100%;
    box-shadow:
      0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 6px 10px 0px rgba(0, 0, 0, 0.14),
      0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  }
`;

export const SuccessMessage = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0;
  transition:
    visibility 0s 0.5s,
    opacity 0.5s linear;
  box-sizing: border-box;
  padding: 15px;
  color: ${THEMES.FOREGROUND_MED};
  font-size: 14px;
  font-weight: 700;
  background-color: ${THEMES.THEME_GREEN};
  border: solid 1px ${THEMES.THEME_GREEN};
  border-radius: 4px;
  &.active {
    opacity: 1;
    transition:
      visibility 0s,
      opacity 0s linear;
  }
`;

export const NotifyBlock = styled.div`
  max-width: 960px;
  width: 100%;
  min-height: 48px;
  margin-bottom: 20px;
  position: relative;
`;

export const ErrorMessage = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0;
  transition:
    visibility 0s 0.5s,
    opacity 0.5s linear;
  box-sizing: border-box;
  padding: 15px;
  color: ${THEMES.FOREGROUND_HIGH};
  background-color: ${THEMES.THEME_YELLOW};
  border: solid 1px ${THEMES.THEME_YELLOW};
  border-radius: 4px;
  &.active {
    height: auto;
    opacity: 1;
    transition:
      visibility 0s,
      opacity 0s linear;
  }
`;

export const CardWrapper = styled.div`
  margin-bottom: 28px;
  max-width: 960px;
  width: 100%;
`;

export const SettingBlock = styled.div`
  max-width: 960px;
  width: 100%;
  background-color: ${THEMES.BACKGROUND_SECONDARY};
  margin-bottom: 28px;
  padding: 28px 26px;
  box-sizing: border-box;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);

  & h3 {
    font-size: 22px;
    font-weight: bold;
    margin-top: 0;
  }
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 6px;
  text-transform: uppercase;
  color: ${THEMES.FOREGROUND_HIGH};
  font-weight: bold;
`;

export const SettingsBlockWithHeader = styled(SettingBlock)`
  padding: 0px;
`;

export const RedirectHeader = styled(Link)`
  font-size: 1.5em;
  font-weight: bold;
`;
export const NavigationHeaderSpanWrapper = styled.span`
  color: ${THEMES.FOREGROUND_HIGH_COLOR};
  margin-right: 11px;
`;

export const BreadCrumbWrapper = styled.span`
  display: inline-block;
`;

export const ChevronIcon = styled.i`
  position: relative;
  top: 4px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 500px;
`;

export const SubNavLabelContainer = styled.div`
  margin-right: 20px;
  cursor: pointer;
`;

export const MenuContainer = styled.div`
  margin-bottom: 24px;
  margin-top: 24px;
  font-size: 14px;
  display: flex;
`;

export const SpanContainer = styled.span`
  color: ${THEMES.COLORED_FOREGROUND_HIGH};
  text-transform: capitalize;
`;

export const CardSubHeader = styled.div`
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  height: 50px;
  max-width: 960px;
  display: flex;
  align-items: center;
  border-bottom: solid 1px ${THEMES.BORDER_COLOR};
`;

export const CardSubHeaderTitle = styled.div`
  padding-left: 26px;
  font-size: 1.3em;
  font-weight: bold;
  font-family: 'Barlow', sans-serif;
  color: ${THEMES.FOREGROUND_HIGH};
`;
