import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useDeleteMessage } from 'client-lib';
import { useApolloClient } from '@apollo/client';
import i18n from 'i18n-js';
import { openSnackbar } from '../../actions/general';
import { Modal, Select, TextInput } from '../../elements';

const DeleteMessageModal = ({ isOpen, onRequestClose, messageId }) => {
  const client = useApolloClient();

  const [deleteMessage, deleteMessageOptions] = useDeleteMessage({
    client,
    i18n,
  });

  const [deletionReason, setDeletionReason] = useState('');
  const [deletionDescription, setDeletionDescription] = useState('');

  const dispatch = useDispatch();
  const onDeletionSuccess = () => {
    const msg = i18n.t('threads-DeleteMessageModal-successfullyDeleted');
    dispatch(openSnackbar(msg));
  };
  const onDeletionError = () => {
    const msg = i18n.t('threads-DeleteMessageModal-deletionFailed');
    dispatch(openSnackbar(msg, 'error'));
  };

  const handleDeleteMessage = () => {
    deleteMessage({
      deletionDescription,
      deletionReason: deletionReason.value,
      messageId,
      onSuccess: onDeletionSuccess,
      onError: onDeletionError,
    });

    handleClose();
  };

  const handleClose = () => {
    onRequestClose();
    setDeletionReason('');
    setDeletionDescription('');
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      size="sm"
      modalTitle={i18n.t('threads-DeleteMessageModal-deleteThisMessage')}
      primaryButtonText={i18n.t('slideouts-CustomerInfoOptions-deleteButton')}
      primaryButtonOnClick={handleDeleteMessage}
      secondaryButtonText={i18n.t('slideouts-CustomerInfoOptions-cancel')}
      secondaryButtonOnClick={handleClose}
      primaryButtonProps={{
        type: 'destructive',
        disabled: !deletionReason,
        dataTestId: 'delete-msg-btn',
      }}
      secondaryButtonProps={{ dataTestId: 'cancel-delete-btn' }}
    >
      <div style={{ flex: 1 }}>
        <Select
          options={deleteMessageOptions}
          value={deletionReason}
          onChange={(selectedOption) => setDeletionReason(selectedOption)}
          label={i18n.t('threads-DeleteMessageModal-chooseReason')}
          helperText={i18n.t('threads-DeleteMessageModal-teammatesKnow')}
          dataTestId="reason-select-input"
        />
        <div style={{ height: 12 }} />
        <TextInput
          value={deletionDescription}
          onChange={(e) => setDeletionDescription(e.target.value)}
          label={i18n.t('threads-DeleteMessageModal-note')}
          helperText={i18n.t('threads-DeleteMessageModal-anyExtraContext')}
          placeholder={i18n.t('threads-DeleteMessageModal-egCreditCard')}
          maxLength={120}
          dataTestId="delete-note-input"
        />
        <div style={{ height: 12 }} />
      </div>
    </Modal>
  );
};

DeleteMessageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  messageId: PropTypes.string.isRequired,
};

export default DeleteMessageModal;
