import React from 'react';
import { formatDataTestName } from 'client-lib/src/lib/utils/helpers';
import { Button, Text } from '../../../elements';
import {
  ButtonContainer,
  ErrorContainer,
  FooterWrapper,
  ErrorText,
} from './styles';
import type {
  ButtonActionTypes,
  ButtonLabelTypes,
  ButtonPropTypes,
} from '../types';

export interface FooterProps {
  buttonActions: ButtonActionTypes;
  buttonLabels: ButtonLabelTypes;
  buttonProps: ButtonPropTypes;
  error: string;
  title: string;
}

const Footer = ({
  buttonActions,
  buttonLabels,
  buttonProps,
  error,
  title,
}: FooterProps) => {
  return (
    <>
      {error && (
        <ErrorContainer>
          <i className="ri-error-warning-line" style={{ fontSize: '14px' }} />
          <Text
            css={ErrorText}
            dataTestId={`${formatDataTestName(title)}-error`}
          >
            {error}
          </Text>
        </ErrorContainer>
      )}
      <FooterWrapper>
        <ButtonContainer>
          {buttonLabels.primary && (
            <Button
              type="primary"
              dataTestId={`${formatDataTestName(title)}-${formatDataTestName(
                buttonLabels.primary
              )}-primary-button`}
              onClick={buttonActions.primary}
              {...buttonProps?.primary}
            >
              {buttonLabels.primary}
            </Button>
          )}
          {buttonLabels.destructive && (
            <Button
              type="destructive"
              dataTestId={`${formatDataTestName(title)}-${formatDataTestName(
                buttonLabels.destructive
              )}-destructive-button`}
              onClick={buttonActions.destructive}
              {...buttonProps?.destructive}
            >
              {buttonLabels.destructive}
            </Button>
          )}
          {buttonLabels.secondary && (
            <Button
              type="secondary"
              dataTestId={`${formatDataTestName(title)}-${formatDataTestName(
                buttonLabels.secondary
              )}-secondary-button`}
              onClick={buttonActions.secondary}
              {...buttonProps?.secondary}
            >
              {buttonLabels.secondary}
            </Button>
          )}
        </ButtonContainer>
      </FooterWrapper>
    </>
  );
};

export default Footer;
