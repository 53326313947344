import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";

const DEFAULT_NUMBER = 25;
const useLazyPaginated = ({
  client,
  queryVariables,
  filter,
  query,
  resultsNumber = DEFAULT_NUMBER,
  key,
  skip = false,
  loadAllPages = false,
  queryOptions = {},
  policy = "cache-and-network",
  nextPolicy = undefined,
  customMapNode = null,
  excludeInitVarsInHook = false,
}) => {
  const otherQueryVariables = queryVariables || {};
  const initialVariables = {
    ...otherQueryVariables,
    first: resultsNumber,
    filter: filter || "",
  };

  const lazyQueryOptions = {
    client,
    fetchPolicy: policy,
    nextFetchPolicy: nextPolicy,
    skip,
    ...queryOptions,
  };

  if (!excludeInitVarsInHook) {
    lazyQueryOptions.variables = initialVariables;
  }

  const [triggerQuery, { loading, data: originalData, fetchMore, refetch }] =
    useLazyQuery(query, lazyQueryOptions);

  const defaultMapNode = (edgeNode) => edgeNode.node;
  const results = originalData?.[key]?.edges?.length
    ? originalData[key].edges.map(customMapNode || defaultMapNode)
    : [];
  const pageInfo = originalData?.[key]?.pageInfo
    ? originalData[key].pageInfo
    : {};

  const handleFetchMore = async (args) => {
    const edges = originalData?.[key].edges;
    const variables = {
      ...initialVariables,
      ...args?.variables,
      after: edges[edges.length - 1].cursor,
    };
    const response = await fetchMore({ ...args, variables });
    if (response.data.errors) {
      console.error(response.data.errors);
    }

    return response;
  };

  const handlePrevious = () => {
    const edges = originalData[key].edges;
    const variables = {
      ...initialVariables,
      before: edges[0].cursor,
      first: null,
      last: resultsNumber || DEFAULT_NUMBER,
    };
    fetchMore({ variables });
  };

  useEffect(() => {
    if (loadAllPages && pageInfo?.hasNextPage) {
      handleFetchMore();
    }
  }, [results?.length]);

  return {
    triggerQuery,
    handleFetchMore,
    handlePrevious,
    pageInfo,
    [key]: results,
    loading,
    refetch,
    originalData,
  };
};
export default useLazyPaginated;
