import React from 'react';
import styled from 'styled-components';
import FONTSIZE_THEMES from '../../styles/themes/fontSize/fontSize';
import THEMES from '../../styles/themes/library/table';

const TableHeadStyled = styled.thead`
  background-color: ${THEMES.HEAD_BACKGROUND};
  color: ${THEMES.HEAD_TEXT};
  font-size: ${FONTSIZE_THEMES.TABLE_TEXT};
  font-weight: 700;
  text-transform: uppercase;

  tr {
    border: 1px solid ${THEMES.HEAD_BORDER};
  }

  th {
    height: 38px;
    padding-right: 16px;
  }

  th:first-child {
    padding-left: 16px;
  }
  ${(props) => props?.customStyle?.()}
`;

const TableHead = ({ ...props }) => <TableHeadStyled {...props} />;

export default TableHead;
