import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useApolloClient, useQuery } from '@apollo/client';
import { CHANNELS_QUERY } from 'client-lib';
import { getSubdomain } from 'client-lib/src/lib/utils/helpers';

const groupIdsToNames = (groupIds, allGroups) => {
  if (!allGroups.length) return null;

  return groupIds.map((groupId) => {
    return allGroups.reduce((acc, groupObj) => {
      if (acc) return acc;
      return groupObj.id === groupId ? groupObj.name : null;
    }, null);
  });
};

const findFirstPhoneOrEmailChannel = (activeGroupIds, channels) => {
  if (!channels?.length || !activeGroupIds?.length) return null;
  const sortChannels = (a, b) =>
    ('' + a.group.name).localeCompare(b.group.name);

  // Sort channels alphabetically and filter only activeGroups
  const activeChannels = channels
    ?.filter((channel) => activeGroupIds?.includes(channel.group.id))
    ?.sort(sortChannels);

  // Find first sms channel and if it exists return the phoneNumber
  const firstPhoneNumber = activeChannels?.find(
    (channel) => channel?.smsConfig?.phoneNumber
  )?.smsConfig?.phoneNumber;
  if (firstPhoneNumber) {
    return firstPhoneNumber;
  }

  // Find first email channel and if it exists return the emailAddress
  const firstEmailAddress = activeChannels?.find(
    (channel) => channel?.emailConfig?.emailAddress
  )?.emailConfig?.emailAddress;
  if (firstEmailAddress) {
    return firstEmailAddress;
  }

  // If there aren't any active sms or email channels, return nothing
  return null;
};

const enablePendoEnvs = ['prod', 'canada', 'int', 'qa']; // intentionally leaves out staging
// removes these subdomains from pendo init per useEffect
const disabledSubdomains = [
  'testcompany',
  'testcompanytwo',
  'testcompanythree',
  'prodwebchattest',
];

const usePendoInit = () => {
  const client = useApolloClient();
  const href = window.location.href;
  const subdomain = getSubdomain(href);

  const customCurrentUserEqual = (oldValue, newValue) =>
    oldValue?.userId === newValue?.userId;
  const {
    userId,
    accountId,
    announcementsAccess,
    emailNotificationDelayMins,
    emailNotificationsEnabled,
    firstName,
    groupIds,
    language,
    lastName,
    notificationInterval,
    role,
    title,
    theme,
    unclaimedThreadEmailNotificationsEnabled,
    unclaimedThreadMobileNotificationsEnabled,
    unreadThreadEmailNotificationsEnabled,
    username,
    sloUri,
    sso,
    ssoUri,
  } = useSelector((state) => state.session.currentUser, customCurrentUserEqual);

  const threadsActiveGroupIds = useSelector(
    (state) => state.session.threadsActiveGroupIds
  );
  const allGroups = useSelector((state) => state.accountData.allGroups);
  const groupNames = groupIdsToNames(groupIds, allGroups);

  const { data } = useQuery(CHANNELS_QUERY, {
    client,
    variables: { accountId },
  });

  const firstPhoneOrEmailChannel = findFirstPhoneOrEmailChannel(
    threadsActiveGroupIds,
    data?.channels
  );

  // account data
  const customAccountDataEqual = (oldValue, newValue) =>
    oldValue?.id === newValue?.id;

  const accountData = useSelector(
    (state) => state.accountData.account,
    customAccountDataEqual
  );

  const {
    accountName,
    singleUserRouting,
    presenceTracking,
    announcements,
    crossGroupThreadTransfers,
    whiteLabel,
    whiteLabelLogo,
    whiteLabelTabTitle,
    multiInboxView,
    email,
    emailNotifications,
    fax,
    api,
    upgradeButton,
    internalThreads,
    autoReturnThreads,
    ff_single_user_routing,
    ff_presence_tracking,
    ff_cross_group_thread_transfers,
    ff_transfer_threads_to_groups,
    ff_announcements,
    ff_whitelabel,
    ff_whitelabel_logo,
    ff_whitelabel_tab_title,
    ff_multi_inbox_view,
    ff_email_notifications,
    ff_api,
    ff_auto_return_threads,
    ff_internal_threads,
    ff_upgrade_button,
    ff_fax,
    ff_email,
  } = accountData;

  const initPendo = () => {
    /* eslint-disable */
    (function (apiKey) {
      (function (p, e, n, d, o) {
        let v;
        let w;
        let x;
        let y;
        let z;
        o = p[d] = p[d] || {};
        o._q = o._q || [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
        for (w = 0, x = v.length; w < x; ++w) {
          (function (m) {
            o[m] =
              o[m] ||
              function () {
                o._q[m === v[0] ? 'unshift' : 'push'](
                  [m].concat([].slice.call(arguments, 0))
                );
              };
          })(v[w]);
        }
        y = e.createElement(n);
        y.async = !0;
        y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
        z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
      })(window, document, 'script', 'pendo');

      // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
      // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
      // Call this function after users are authenticated in your app and your visitor and account id values are available
      // Please use Strings, Numbers, or Bools for value types.
      pendo.initialize({
        visitor: {
          id: userId,
          // Required if user is logged in, default creates anonymous ID
          // email:        // Recommended if using Pendo Feedback, or NPS Email
          // full_name:    // Recommended if using Pendo Feedback
          // role:         // Optional

          // You can add any additional visitor level key-values here,
          // as long as it's not one of the above reserved names.
          announcementsAccess,
          emailNotificationDelayMins,
          emailNotificationsEnabled,
          firstName,
          firstPhoneOrEmailChannel,
          groupIds,
          groupNames,
          language,
          lastName,
          notificationInterval,
          title,
          role,
          theme,
          unclaimedThreadEmailNotificationsEnabled,
          unclaimedThreadMobileNotificationsEnabled,
          unreadThreadEmailNotificationsEnabled,
          username,
          sloUri,
          sso,
          ssoUri,
        },

        account: {
          id: accountId, // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
          // name:         // Optional
          // is_paying:    // Recommended if using Pendo Feedback
          // monthly_value:// Recommended if using Pendo Feedback
          // planLevel:    // Optional
          // planPrice:    // Optional
          // creationDate: // Optional

          // You can add any additional account level key-values here,
          // as long as it's not one of the above reserved names.
          accountName,
          singleUserRouting,
          fax,
          email,
          internalThreads,
          upgradeButton,
          api,
          emailNotifications,
          autoReturnThreads,
          multiInboxView,
          whiteLabel,
          whiteLabelLogo,
          whiteLabelTabTitle,
          presenceTracking,
          announcements,
          crossGroupThreadTransfers,
          ff_single_user_routing,
          ff_presence_tracking,
          ff_cross_group_thread_transfers,
          ff_transfer_threads_to_groups,
          ff_announcements,
          ff_whitelabel,
          ff_whitelabel_logo,
          ff_whitelabel_tab_title,
          ff_multi_inbox_view,
          ff_email_notifications,
          ff_api,
          ff_auto_return_threads,
          ff_internal_threads,
          ff_upgrade_button,
          ff_fax,
          ff_email,
          env: process.env.PK_ENV,
        },
      });
      console.log('pendo running');
    })('c22a69e7-1e40-4f24-6b76-3f2bb8fbe867');
    /* eslint-enable */
  };

  useEffect(() => {
    if (
      !isEmpty(accountData) &&
      !isEmpty(allGroups) &&
      data?.channels?.length &&
      userId &&
      enablePendoEnvs.includes(process.env.PK_ENV) &&
      !disabledSubdomains.includes(subdomain)
    ) {
      initPendo();
    }
  }, [
    userId,
    isEmpty(accountData),
    isEmpty(allGroups),
    data?.channels?.length,
    subdomain,
  ]);
};

export default usePendoInit;
