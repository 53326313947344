import { useVerifyClientVersion } from 'client-lib';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const RELOAD_MAX = 3;

const useVerifyWebClientVersionInterval = () => {
  let clientVersionInterval;
  const currentUserId = useSelector(
    (state) => state.session.currentUser?.userId
  );

  // we need to reload the page if version out-of-sync with distributor.
  const onUpgradeCallback = () => {
    const lsReloadData = JSON.parse(
      localStorage.getItem('prokeep_version_reloads')
    );

    const date = new Date();

    const strDate = `${date.getDate()}/${
      date.getMonth() + 1
    }/${date.getFullYear()}`;

    // if there is no reload data
    const noReloadData = !lsReloadData;
    // if there is, but the count is less than three
    const lessThanThreeReloads = lsReloadData?.attemptNumber < RELOAD_MAX;
    // if there is three, but the date is different than today
    const threeButDifferentDate =
      lsReloadData?.attemptNumber >= RELOAD_MAX &&
      lsReloadData?.date !== strDate;

    if (noReloadData || lessThanThreeReloads || threeButDifferentDate) {
      const reloadData = {
        attemptNumber:
          threeButDifferentDate || noReloadData
            ? 1
            : (lsReloadData?.attemptNumber ?? 0) + 1,
        date: strDate,
      };

      localStorage.setItem(
        'prokeep_version_reloads',
        JSON.stringify(reloadData)
      );
      window.location.reload(true);
    }
  };
  const [verifyClientVersion] = useVerifyClientVersion({ onUpgradeCallback });
  // check client version every minute, if out of sync will reload page from `onUpgradeCallback`
  useEffect(() => {
    if (currentUserId) {
      verifyClientVersion();
      clientVersionInterval = setInterval(verifyClientVersion, 60000);
    }
    return () => clearInterval(clientVersionInterval);
  }, [currentUserId]);
};

export default useVerifyWebClientVersionInterval;
