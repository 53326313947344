import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MultiTagDisplay from './MultiTagDisplay';

const CreateMultiTag = ({ tags, setTags }) => {
  const [tagInputValue, setTagInputValue] = useState('');
  const [editingRefNum, setEditingRefNum] = useState(false);

  const handleAddOrRemoveThreadFromWorkOrder = (input, remove) => {
    const existingTagIndex = tags.findIndex(
      (tag) => tag.referenceNumber === input
    );
    if (remove) {
      setTags((prev) => [
        ...prev.filter((tag) => tag.referenceNumber !== input),
      ]);
    } else if (existingTagIndex === -1) {
      setTags((prev) => [...prev, { referenceNumber: input }]);
    }

    setEditingRefNum(false);
  };

  return (
    <MultiTagDisplay
      tags={tags}
      handleAddOrRemoveThreadFromWorkOrder={
        handleAddOrRemoveThreadFromWorkOrder
      }
      tagInputValue={tagInputValue}
      setTagInputValue={setTagInputValue}
      editingRefNum={editingRefNum}
      setEditingRefNum={setEditingRefNum}
    />
  );
};

CreateMultiTag.propTypes = {
  tags: PropTypes.array.isRequired,
  setTags: PropTypes.func.isRequired,
};

export default CreateMultiTag;
