import React, { useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import Flyout from '../../../Flyout2/Flyout.tsx';
import ListAudienceContactList from './ListAudienceContactList';
import { RoundedTabList, TextInput } from '../../../../elements';

export const TABS = {
  REACHABLE: 'reachable audience',
  OPTED_OUT: 'opted-out',
};

const ListAudienceFlyout = ({
  handleOnPencilClick,
  listFlyout,
  openFlyout,
  setListFlyout,
}) => {
  const [search, setSearch] = useState('');
  const [flyoutTab, setFlyoutTab] = useState(TABS.REACHABLE);

  const handleOnClickTab = ({ value }) => {
    setFlyoutTab(value);
  };

  const handleChangeSearch = (ev) => {
    const value = ev.target.value;
    setSearch(value);
  };

  const options = [
    {
      label: `${i18n.t('broadcasts-list-audience', {
        number: listFlyout?.audienceInfo?.reachableAudienceCount,
        defaultValue: `Reachable Audience (${listFlyout?.audienceInfo?.reachableAudienceCount})`,
      })}`,
      value: TABS.REACHABLE,
      active: flyoutTab === TABS.REACHABLE,
    },
    {
      label: `${i18n.t('broadcasts-reachableAudience-optedOut', {
        number: listFlyout?.audienceInfo?.optedOutAudienceCount,
        defaultValue: `opted-out (${listFlyout?.audienceInfo?.optedOutAudienceCount}`,
      })}`,
      value: TABS.OPTED_OUT,
      active: flyoutTab === TABS.OPTED_OUT,
    },
  ];

  return (
    <Flyout
      dataTestId="list-audience-flyout"
      title={listFlyout?.name}
      isOpen={openFlyout}
      subtitle={listFlyout?.description}
      customStyles={{ content: () => `width: unset; padding: unset;` }}
      subheader={
        <>
          <TextInput
            value={search}
            onChange={handleChangeSearch}
            placeholder={i18n.t('slideouts-Contacts-search')}
            dataTestId="broadcast-list-search"
            hideBottomSpace
          />
          <RoundedTabList
            onClick={handleOnClickTab}
            options={options}
            customContainerStyle={() => `margin-bottom: unset;`}
          />
        </>
      }
      buttonLabels={{
        title: 'ri-pencil-fill',
        secondary: i18n.t('broadcasts-ReachableAudienceModal-close', {
          defaultValue: 'close',
        }),
      }}
      buttonActions={{
        title: () => {
          handleOnPencilClick(listFlyout);
        },
        secondary: () => {
          setListFlyout({});
        },
      }}
      buttonProps={{
        title: {
          contrast: 'highColor',
          size: 'md',
        },
      }}
      onRequestClose={() => setListFlyout({})}
    >
      <ListAudienceContactList
        listFlyout={listFlyout}
        flyoutTab={flyoutTab}
        search={search}
      />
    </Flyout>
  );
};

ListAudienceFlyout.propTypes = {
  handleOnPencilClick: PropTypes.func.isRequired,
  listFlyout: PropTypes.object,
  openFlyout: PropTypes.bool,
  setListFlyout: PropTypes.func.isRequired,
};

ListAudienceFlyout.defaultProps = {
  listFlyout: {},
  openFlyout: false,
};

export default ListAudienceFlyout;
