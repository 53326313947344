import { useQuery } from "@apollo/client";
import UNCLAIMED_THREAD_COUNT_FOR_USER_BY_GROUP from "../../../graphql/query/UnclaimedThreadCountForUserByGroup.graphql";
import { DEFAULT_DASHBOARD_FLYOUT_ROW_COUNT } from "../../../utils/helpers";

const TOP_N = DEFAULT_DASHBOARD_FLYOUT_ROW_COUNT;

function useUnclaimedThreadsWidget({ client, groupIds }) {
  const {
    data: unclaimedThreadCountByGroupData,
    loading: unclaimedThreadCountByGroupLoading,
  } = useQuery(UNCLAIMED_THREAD_COUNT_FOR_USER_BY_GROUP, {
    client,
    variables: { topN: TOP_N, groupIds },
    fetchPolicy: "network-only",
  });

  const unclaimedThreadCountByGroup =
    unclaimedThreadCountByGroupData?.unclaimedThreadCountForUserByGroup;
  const unclaimedThreadCountGroupIds = unclaimedThreadCountByGroup?.counts?.map(
    (count) => count.groupId
  );
  const widgetsLoading = unclaimedThreadCountByGroupLoading;

  return {
    unclaimedThreadCountByGroup,
    unclaimedThreadCountGroupIds,
    widgetsLoading,
  };
}

export default useUnclaimedThreadsWidget;
