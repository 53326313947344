import { useQuery } from "@apollo/client";
import CHANNELS_QUERY from "../../../graphql/query/ChannelsQuery.graphql";

function useHasSmsChannel({ currentUser, threadsActiveGroupIds, client }) {
  const { data: channelsData } = useQuery(CHANNELS_QUERY, {
    client,
    variables: {
      accountId: currentUser?.accountId,
    },
  });

  const hasSmsChannel = !!channelsData?.channels
    ?.filter((channel) => channel.smsConfig !== null)
    ?.filter((channel) =>
      threadsActiveGroupIds?.some((activeId) => activeId === channel.group.id)
    ).length;

  return { hasSmsChannel };
}

export default useHasSmsChannel;
