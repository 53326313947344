import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/client';
import { Redirect } from 'react-router-dom';
import { Socket as PhoenixSocket } from 'phoenix';
import { useDiagnosticSignIn } from 'client-lib';
import i18n from 'i18n-js';
import { MessageContainer, Message } from '../../components/Settings/styles';
import { Heading1, Text, Loading, TextInput, Button } from '../../elements';
import THEMES from '../../styles/themes/app';
import { Container } from './styles';

const InputGridWrapper = styled.div`
  width: 80%;
`;

const DiagnosticInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 300px;
  overflow: auto;
  margin: auto;
  width: 100%;
  max-height: 300px;
  overflow-wrap: break-word;
  padding: 10px;
`;
const HeaderWrapper = styled.div`
  margin: 8px 0;
`;
const PasswordIcon = styled.i`
  color: ${THEMES.FOREGROUND_HIGH_COLOR} !important;
  :hover,
  :focus,
  :active {
    cursor: pointer;
  }
`;

const WS_PROTOCOL = window.location.protocol === 'http:' ? 'ws:' : 'wss:';
const WS_URL = `${WS_PROTOCOL}//${window.location.host}/socket`;

const Diagnostics = () => {
  const [passwordIconType, setPasswordIconType] = useState('hidden');
  const [status, setStatus] = useState(null);
  const [signin_uri, setSignInUri] = useState('');
  const [signin_response_data, setSignInResponseData] = useState('');
  const [signin_error_message, setSignInErrorMessage] = useState('');
  const [socket_data, setSocketData] = useState('');
  const [socket_error_data, setSocketErrorData] = useState('No Socket Errors');
  const [socket_open_data, setSocketOpenData] = useState(
    'Socket Has Not Opened'
  );
  const [socket_close_data, setSocketCloseData] = useState(
    'Socket Has Not Closed'
  );

  const onDisconnect = () => {
    setSocketCloseData('Socket Closed Successfully');
  };

  useEffect(() => {
    verifySubdomain();
  }, []);

  const client = useApolloClient();

  const handleDiagnosticSignInResponse = (response) => {
    setSignInResponseData(JSON.stringify(response, null, 2));
    const token = response.data.diagnosticSignIn.diagnostic_info.token_output;
    if (response.data.diagnosticSignIn.diagnostic_info.session_output) {
      setSignInErrorMessage(
        response.data.diagnosticSignIn.diagnostic_info.session_output
      );
    }

    const socket = new PhoenixSocket(WS_URL, {
      params: {
        device_id: Date.now(),
        token,
        client_version: process.env.VERSION,
      }, // eslint-disable-line no-undef
    });
    socket.connect();

    socket.onError((error) => {
      setSocketErrorData('Socket error has occured, check console for details');
      console.error(error);
    });
    socket.onOpen(() => {
      setSocketOpenData('Socket Opened Successfully');
      socket.disconnect(onDisconnect);
    });

    setSocketData(JSON.stringify(socket, null, 2));
  };

  const verifySubdomain = async () => {
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];

    // dont double tap this api call if the page sites on sso
    if (subdomain && subdomain !== 'sso') {
      const json = await fetch(
        `${window.location.origin}/status/subdomain/${subdomain}`
      )
        .then((response) => response.json())
        .catch((e) => {
          console.warn(e.message);
        });
      // 404
      if (json.status === 'not_found') {
        setStatus('not_found');
      } else if (json.status === 'sso') {
        setStatus('sso');
        setSignInUri(json.signin_uri);
      } else if (json.status === 'moved_permanently' && json.group) {
        window.location.href = json.group;
      } else if (json.status === 'ok') {
        setStatus('ok');
      }
    }
  };

  const handleSSOSigninRedirect = async () => {
    window.location.href = signin_uri;
  };

  const {
    fields,
    onSubmit,
    updateField,
    isVisitedForm,
    handlePasswordToggle,
    passwordFieldType,
    signInError,
    isSubmitting,
    handleOnKeyPress,
  } = useDiagnosticSignIn({
    client,
    handleDiagnosticSignInResponse,
  });

  const { username, password } = fields;

  const handlePasswordIconToggle = () => {
    setPasswordIconType(passwordIconType === 'hidden' ? 'visible' : 'hidden');
    handlePasswordToggle();
  };

  if (status === 'not_found') {
    return <Redirect to="/notfound" />;
  }
  if (status === 'sso') {
    return (
      <div>
        <Button onClick={handleSSOSigninRedirect}>
          {i18n.t('signin-SignIn-signIn')}
        </Button>
      </div>
    );
  }
  if (status === 'ok') {
    return (
      <Container>
        <div style={{ width: '100%' }} data-testid="diagnostic-sign-in">
          <DiagnosticInfoWrapper>
            <Text>
              {window.WebSocket
                ? 'Your Browser Supports WebSockets'
                : 'Your Browser Does Not Support Web Sockets - ERROR'}
            </Text>
            <Text>==================</Text>
            <Text>{`User Agent: ${navigator.userAgent}`}</Text>
            <Text>==================</Text>
            <Text>
              {signin_error_message
                ? 'Sign in Error Message: ' + signin_error_message
                : ''}
            </Text>
            <Text>
              {signin_response_data
                ? 'Sign in Response Data: ' + signin_response_data
                : 'Sign in response data has not been retrieved'}
            </Text>
            <Text>==================</Text>
            <Text>
              {socket_data
                ? 'Socket response Data: ' + socket_data
                : 'Socket data has not been retrieved'}
            </Text>
            <Text>==================</Text>
            <Text>{socket_open_data}</Text>
            <Text>==================</Text>
            <Text>{socket_close_data}</Text>
            <Text>==================</Text>
            <Text>{socket_error_data}</Text>
          </DiagnosticInfoWrapper>
          <HeaderWrapper>
            <Heading1>
              {i18n.t('signin-Diagnostics-retrieveDiagnosticSignIn')}
            </Heading1>
          </HeaderWrapper>
          {signInError && (
            <MessageContainer>
              <Message className="active">{signInError}</Message>
            </MessageContainer>
          )}
          <div>
            <InputGridWrapper>
              <TextInput
                label={i18n.t('signin-SignIn-emailAddress')}
                error={username.error}
                dataTestId="diagnostic-username-input"
                value={username.value}
                onChange={(e) =>
                  updateField({ value: e.target.value, name: 'username' })
                }
                onKeyPress={handleOnKeyPress}
                onFocus={() => username.setFocus(true)}
                onBlur={() => username.setFocus(false)}
                name="username"
                id="username"
              />
            </InputGridWrapper>
          </div>
          <div>
            <InputGridWrapper>
              <TextInput
                label={i18n.t('signin-SignIn-password')}
                type={passwordFieldType}
                error={password.error}
                dataTestId="diagnostic-password-input"
                value={password.value}
                onChange={(e) =>
                  updateField({ value: e.target.value, name: 'password' })
                }
                onKeyPress={handleOnKeyPress}
                onFocus={() => password.setFocus(true)}
                onBlur={() => password.setFocus(false)}
                name="password"
                id="password"
                iconRight
                icon={
                  <PasswordIcon
                    onClick={handlePasswordIconToggle}
                    className={
                      passwordIconType === 'hidden'
                        ? 'ri-eye-off-line'
                        : 'ri-eye-line'
                    }
                  />
                }
              />
            </InputGridWrapper>
          </div>
          <div>
            <div>
              <Button
                dataTestId="retrieve-diagnostic-sign-in-button"
                onClick={onSubmit}
                disabled={isVisitedForm && isSubmitting}
              >
                {i18n.t('signin-Diagnostics-retrieve')}
              </Button>
            </div>
          </div>
        </div>
      </Container>
    );
  }

  return (
    <div>
      <Loading size="sm" />
    </div>
  );
};

export default Diagnostics;
