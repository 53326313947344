import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useActiveEmailChannels as useGetActiveEmailChannels } from 'client-lib';
import { useApolloClient } from '@apollo/client';
import { setActiveEmailChannels } from '../../actions/accountData';

const useActiveEmailChannels = () => {
  const dispatch = useDispatch();
  const client = useApolloClient();
  const accountId = useSelector((state) => state.session.currentUser.accountId);

  const { activeEmailChannels } = useGetActiveEmailChannels({
    accountId,
    client,
  });

  useEffect(() => {
    if (activeEmailChannels) {
      dispatch(setActiveEmailChannels(activeEmailChannels));
    }
  }, [activeEmailChannels?.length]);
};

export default useActiveEmailChannels;
