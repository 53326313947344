import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/client';
import { usePaginated } from 'client-lib';
import InfiniteSuggestions from './Suggestions/InfiniteSuggestions';
import TextInput from '../TextInput/TextInput';
import MultiSelectDeletableOptions from './Suggestions/MultiSelectDeletableOtptions';

const INFINITE_SUGGEST_ROWS = 5;

const SuggestionWrapper = styled.div`
  position: relative;
`;

const InfiniteSuggest = ({
  inputProps,
  query,
  queryKey,
  queryVariables,
  rightAlignedText,
  onSelect,
  onUnhighlightedEnter,
  includePresence,
  includeGroupIndicator,
  suggestionRowAmount,
  multiSelectedSuggestions,
  multiSelectedChipProps,
  onMultiSelectionDelete,
  isDisabled,
  isTabbed,
  tabLabels,
  activeTabIndex,
  setActiveTabIndex,
  customFilter,
  flipOptions,
  openOnFocus,
  displayStickyButton,
  stickyButtonText,
  onStickyButtonClick,
  displayAddPhoneNumberButton,
}) => {
  const client = useApolloClient();
  const defaultInputRef = useRef();
  const inputRef = inputProps?.ref || defaultInputRef;

  const suggestionWrapperRef = useRef();
  const [focusedSuggestionKey, setFocusedSuggestionKey] = useState(null);
  const [hasSelected, setHasSelected] = useState(!!inputProps.value?.length); // if mounts with value inside of input, don't show drop down
  const [bypassSelectedUpdate, setBypassSelectedUpdate] = useState(
    !!inputProps.value?.length
  ); // if mounts with value inside of input, don't show drop down
  const [inputFocused, setInputFocused] = useState(false);

  // If input is disabled, also disable the ability to _remove_ groups
  const controlDisabled = inputProps.disabled || false;

  const res = usePaginated({
    client,
    query,
    key: queryKey,
    queryVariables: {
      relayStylePagination: true,
      ...queryVariables,
    },
    filter: queryVariables?.filter,
    resultsNumber: 10,
    skip: !inputProps.value && !openOnFocus,
  });

  const loading = res?.loading;
  const suggestions = res[queryKey]?.filter(customFilter);
  const pageInfo = res?.pageInfo;

  const openSuggestions =
    (!!(inputProps?.value?.length || loading) && !hasSelected) ||
    (openOnFocus && inputFocused);

  useEffect(() => {
    if (hasSelected) {
      if (bypassSelectedUpdate) {
        setBypassSelectedUpdate(false);
      } else {
        setHasSelected(false);
      }
    }
  }, [inputProps?.value, hasSelected]);

  const handleSelect = (...props) => {
    setHasSelected(true);
    setInputFocused(false);
    setBypassSelectedUpdate(true);

    onSelect(...props);
  };

  const handleStickyClick = (...props) => {
    setHasSelected(true);
    setBypassSelectedUpdate(true);

    onStickyButtonClick?.(...props);
  };

  const hideBottomSpace = !!(
    inputProps?.hideBottomSpace || multiSelectedSuggestions?.length
  );

  // input needs a way to not run onBlur if user clicks on suggestion, because onBlur runs before onClick
  // and we do not want to run onBlur unless the user completely clicks outside of the InfiniteSuggest.
  const onBlurOverride = (e, ...props) => {
    if (
      inputProps?.onBlur &&
      !suggestionWrapperRef.current.contains(e.relatedTarget)
    ) {
      inputProps.onBlur(e, ...props);
      if (openOnFocus) {
        setInputFocused(false);
      }
    }

    // if they lose focus from clicking on a suggestion tab, give the focus back to the input.
    // Also, reset the focusedSuggestionKey.
    if (e?.relatedTarget?.classList?.contains?.('suggestion-tab')) {
      inputRef.current.focus();
      setFocusedSuggestionKey(null);
    }
  };

  const onFocusOverride = () => {
    handleInputClick();
    inputProps?.onFocus?.();
  };

  // this controls the state of the arrow keys when focused on input
  const onKeyDownHandler = (e) => {
    if (
      e.keyCode === 38 ||
      e.keyCode === 40 ||
      e.keyCode === 37 ||
      e.keyCode === 39
    ) {
      // arrow keys go through here. Do stuff for up and down, ignore left and right.
      e.preventDefault();

      if (e.keyCode === 38) {
        let nextFocusedSuggestionKey;

        if (focusedSuggestionKey === 0) {
          nextFocusedSuggestionKey = suggestions.length - 1;
        } else if (focusedSuggestionKey !== null) {
          nextFocusedSuggestionKey = focusedSuggestionKey - 1;
        }

        setFocusedSuggestionKey(nextFocusedSuggestionKey);
      } else if (e.keyCode === 40) {
        let nextFocusedSuggestionKey;

        if (
          focusedSuggestionKey === null ||
          focusedSuggestionKey >= (suggestions?.length ?? 0) - 1
        ) {
          nextFocusedSuggestionKey = 0;
        } else {
          nextFocusedSuggestionKey = focusedSuggestionKey + 1;
        }

        setFocusedSuggestionKey(nextFocusedSuggestionKey);
      }
    } else if (e.keyCode === 13) {
      e.preventDefault();
      // if user presses enter while a suggestion has been focused, bypass the need to click on the suggestion.
      // double check to make sure the focused target isn't disabled first.
      if (
        Number.isInteger(focusedSuggestionKey) &&
        !isDisabled?.(suggestions[focusedSuggestionKey])
      ) {
        handleSelect(e, { suggestion: suggestions[focusedSuggestionKey] });
      } else if (inputRef?.current) {
        if (onUnhighlightedEnter) {
          // in some cases we will not want to handle an "unfocused enter" the same way as we do input blur. If that's
          // the case, the dev should use this onUnhighlightedEnter prop
          onUnhighlightedEnter(e, {
            suggestion: suggestions[focusedSuggestionKey],
          });
        } else {
          // if press enter but not focused on a suggestion, trigger blur on the input. This will allow for the
          // use case when a user manually enters a phone number, they can press enter and it will populate the number properly.
          inputRef.current.blur();
        }
      }
    } else if (e.keyCode === 9) {
      // if tabbing, realease focus from input
      inputRef.current.blur();
    } else {
      // if a user types anything while the input still has cursor focus that ISN'T arrows or enter,
      // get rid of the suggestion focus, because suggestions will likely change (query will update)
      setFocusedSuggestionKey(null);
    }
  };

  const handleInputClick = () => {
    if (openOnFocus) {
      res.refetch();
      setInputFocused(true);
    }
  };

  return (
    <>
      <TextInput
        {...inputProps}
        hideBottomSpace={hideBottomSpace}
        onBlur={onBlurOverride}
        onFocus={onFocusOverride}
        onClick={handleInputClick}
        onKeyDown={onKeyDownHandler}
        ref={inputRef}
        dataTestId="infinite-suggest-text-input"
      />
      <SuggestionWrapper ref={suggestionWrapperRef}>
        <InfiniteSuggestions
          open={openSuggestions}
          loading={loading}
          suggestionRowAmount={suggestionRowAmount}
          suggestions={suggestions}
          pageInfo={pageInfo}
          loadMoreRows={res?.handleFetchMore}
          onSelect={handleSelect}
          includePresence={includePresence}
          includeGroupIndicator={includeGroupIndicator}
          focusedSuggestionKey={focusedSuggestionKey}
          isDisabled={isDisabled}
          isTabbed={isTabbed}
          tabLabels={tabLabels}
          activeTabIndex={activeTabIndex}
          setActiveTabIndex={setActiveTabIndex}
          hiddenBottomSpace={hideBottomSpace}
          displayStickyButton={displayStickyButton}
          stickyButtonText={stickyButtonText}
          onStickyButtonClick={handleStickyClick}
          rightAlignedText={rightAlignedText}
          flipOptions={flipOptions}
          displayAddPhoneNumberButton={displayAddPhoneNumberButton}
        />
      </SuggestionWrapper>
      <MultiSelectDeletableOptions
        multiSelectedChipProps={multiSelectedChipProps}
        options={multiSelectedSuggestions}
        onDelete={onMultiSelectionDelete}
        disabled={controlDisabled}
      />
    </>
  );
};

InfiniteSuggest.propTypes = {
  inputProps: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,
  queryKey: PropTypes.string.isRequired,
  queryVariables: PropTypes.object,
  onSelect: PropTypes.func,
  includePresence: PropTypes.bool,
  includeGroupIndicator: PropTypes.bool,
  suggestionRowAmount: PropTypes.number,
  multiSelectedSuggestions: PropTypes.array,
  multiSelectedChipProps: PropTypes.object,
  onMultiSelectionDelete: PropTypes.func,
  isDisabled: PropTypes.func,
  isTabbed: PropTypes.bool,
  tabLabels: PropTypes.array,
  activeTabIndex: PropTypes.number,
  setActiveTabIndex: PropTypes.func,
  onUnhighlightedEnter: PropTypes.func,
  customFilter: PropTypes.func,
  flipOptions: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  displayStickyButton: PropTypes.func,
  stickyButtonText: PropTypes.string,
  onStickyButtonClick: PropTypes.func,
  rightAlignedText: PropTypes.func,
  displayAddPhoneNumberButton: PropTypes.bool,
};

InfiniteSuggest.defaultProps = {
  queryVariables: {},
  onSelect: () => {},
  includePresence: false,
  openOnFocus: false,
  includeGroupIndicator: false,
  suggestionRowAmount: INFINITE_SUGGEST_ROWS,
  multiSelectedSuggestions: null,
  multiSelectedChipProps: null,
  onMultiSelectionDelete: null,
  isDisabled: undefined,
  isTabbed: false,
  tabLabels: undefined,
  activeTabIndex: undefined,
  setActiveTabIndex: undefined,
  onUnhighlightedEnter: undefined,
  customFilter: () => true,
  flipOptions: false,
  displayStickyButton: () => false,
  stickyButtonText: '',
  onStickyButtonClick: () => {},
  rightAlignedText: () => {},
  displayAddPhoneNumberButton: true,
};

export default InfiniteSuggest;
