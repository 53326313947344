import React, { useEffect, useRef, useState } from 'react';
import ReactSelect, {
  type ActionMeta,
  type MultiValue,
  type RemoveValueActionMeta,
} from 'react-select';
import styled from 'styled-components';
import i18n from 'i18n-js';
import THEMES from '../../../styles/themes/library/textInput';
import LABEL_THEMES from '../../../styles/themes/library/label';
import InputLabel from '../../../elements/inputCommonElements/InputLabel';
import InputError from '../../../elements/inputCommonElements/InputError';
import Option, { centeredText } from './Option';
import DropdownIndicator from './DropdownIndicator';
import FONTSIZE_THEMES from '../../../styles/themes/fontSize/fontSize';
import type {
  CreateDropdownComponentFunction,
  CreateOptionComponentFunction,
  MultiOnChange,
  OptionType,
  RSOptionRef,
  CreateMultiValueChipComponentFunction,
  NoOptionsMessageComponentFunction,
} from './types';
import { getLabelThemeColor } from '../../../styles/themes/library/utils/getLabelThemeColor';
import { ChipLabel } from '../../../elements';
import { copyKeyInArray } from '../../../utils/helpers/objectUtils';

const Container = styled.div<{
  error?: string;
  disabled?: boolean;
}>`
  width: 100%;
  color: ${THEMES.TEXT};
  font-family: 'Barlow', sans-serif;
  .ChipSelect__control {
    border-radius: 4px;
    min-height: 40px;
    font-size: ${FONTSIZE_THEMES.SELECT_TEXT};
    font-weight: 500;
    font-family: 'Barlow', sans-serif;

    border-color: ${(props) => (props.error ? THEMES.ERROR : THEMES.BORDER)};

    background-color: ${(props) =>
      props.disabled ? THEMES.DISABLED_BACKGROUND : THEMES.BACKGROUND};
    cursor: ${(props) => (props.disabled ? 'default' : 'auto')};
  }

  .ChipSelect__control:hover {
    border-color: ${(props) => (props.error ? THEMES.ERROR : THEMES.BORDER)};
  }

  .ChipSelect__control--is-disabled {
    background-color: ${THEMES.DISABLED_BACKGROUND};
  }

  .ChipSelect__control--is-focused {
    border-color: ${(props) => (props.error ? THEMES.ERROR : THEMES.FOCUS)};
    box-shadow: 0 0 0 1px
      ${(props) => (props.error ? THEMES.ERROR : THEMES.FOCUS)};
  }

  .ChipSelect__control--is-focused:hover {
    border-color: ${(props) => (props.error ? THEMES.ERROR : THEMES.FOCUS)};
    box-shadow: 0 0 0 1px
      ${(props) => (props.error ? THEMES.ERROR : THEMES.FOCUS)};
  }

  .ChipSelect__indicator-separator {
    display: none;
  }

  .ChipSelect__menu {
    margin-top: 1px;
    border-radius: 5px;
    background-color: ${THEMES.BACKGROUND};
    color: ${THEMES.TEXT};
  }

  .ChipSelect__menu-list {
    padding: 0;
    border: 1px solid ${THEMES.BORDER};
    border-radius: 5px;
  }

  .ChipSelect__option {
    height: 40px;
    font-weight: 500;
    font-family: 'Barlow', sans-serif;
    color: ${THEMES.OPTION_TEXT};
    background-color: ${THEMES.OPTION_BACKGROUND};
    display: flex;
    align-items: center;
    padding: 0 8px;
    font-size: ${FONTSIZE_THEMES.SELECT_TEXT};
    border: 1px solid ${THEMES.BORDER};
  }

  .ChipSelect__option:nth-of-type(1) {
    border-radius: 4px 4px 0 0;
  }

  .ChipSelect__option:nth-last-of-type(1) {
    border-radius: 0 0 4px 4px;
  }

  .ChipSelect__option--is-focused,
  .ChipSelect__option--is-focused:active {
    color: ${THEMES.OPTION_TEXT_HOVER};
    background-color: ${THEMES.OPTION_BACKGROUND_HOVER};
  }

  .ChipSelect__option--is-selected {
    color: ${THEMES.OPTION_TEXT_ACTIVE};
    background-color: ${THEMES.OPTION_BACKGROUND_ACTIVE};
  }

  .ChipSelect__indicators {
    display: ${(props) => (props.disabled ? 'none!important' : 'flex')};
  }

  .ChipSelect__value-container--has-value {
    gap: 8px;
  }

  .ChipSelect__input-container {
    color: ${THEMES.TEXT};
  }
`;

export interface ChipSelectProps {
  dataTestId: string;
  disabled?: boolean;
  error?: string;
  helperText?: string;
  hideBottomSpace?: boolean;
  label: string;
  loading?: boolean;
  onChange: MultiOnChange;
  openOnInit?: boolean;
  options: OptionType[];
  placeholder?: string;
  value: OptionType[];
}

const CHIP_LABEL_TESTID = 'selected-chip';
const CHIP_SELECT_CLASS = 'ChipSelect';

const ChipSelect = React.forwardRef(
  (
    {
      dataTestId,
      disabled = false,
      error = '',
      helperText = '',
      hideBottomSpace = false,
      label,
      loading = false,
      onChange,
      openOnInit = false,
      options,
      placeholder = '',
      value,
    }: ChipSelectProps,
    ref
  ) => {
    const [selected, setSelected] = useState<MultiValue<OptionType>>(value);
    const [lastClicked, setLastClicked] = useState('');
    const selectRef = useRef<HTMLDivElement>(null);

    /**
     * Handles adding and removing items (chips) from the react-select menu and input textbar
     * @param val all selected data or single data item to remove
     * @param actionMeta react-select ActionMeta object
     */
    const handleChange = (
      val: MultiValue<OptionType>,
      actionMeta: ActionMeta<OptionType> | RemoveValueActionMeta<OptionType>
    ) => {
      // Overriding react-select's chip means defining the remove functionality
      if (actionMeta.action.includes('remove')) {
        const newSelection = [...selected];
        newSelection.splice(
          selected.findIndex(
            (option) => option.value === actionMeta.option?.value
          ),
          1
        );
        setSelected(newSelection);
        onChange(newSelection);
        // Otherwise, utilize react-select's addition functionality
      } else {
        setSelected(val);
        onChange(val);
      }
    };

    /** Creates dropdown toggle component */
    const createDropdownIndicator: CreateDropdownComponentFunction = (
      props
    ) => (
      <DropdownIndicator
        dataTestId={dataTestId}
        selectProps={props.selectProps}
      />
    );

    /** Creates list menu components */
    const createOption: CreateOptionComponentFunction = (props) => {
      return (
        <Option
          dataTestId={dataTestId}
          isCheckable
          applyLabelColor={getLabelThemeColor(props.data.color || '')}
          {...props}
          innerRef={ref as RSOptionRef}
        >
          {props.data.text}
        </Option>
      );
    };

    /** Creates Selected Chip components in input textbar */
    const createMultiValueChip: CreateMultiValueChipComponentFunction = (
      props
    ) => {
      const { data } = props;
      return (
        <ChipLabel
          {...props}
          dataTestId={CHIP_LABEL_TESTID}
          onDelete={() =>
            handleChange(data, {
              action: 'remove-value',
              option: data,
              name: dataTestId,
            } as RemoveValueActionMeta<OptionType>)
          }
          backgroundColor={getLabelThemeColor(data.color || '')}
          textColor={
            data.color ? LABEL_THEMES.LABEL_TEXT : LABEL_THEMES.LABEL_DARK_TEXT
          }
          size="sm"
        >
          {data.text}
        </ChipLabel>
      );
    };

    const createNoOptionComponent: NoOptionsMessageComponentFunction = (
      props
    ) => (
      <Option
        dataTestId={dataTestId}
        {...props}
        innerRef={ref as RSOptionRef}
        isSelected={false}
        customOptionStyle={centeredText}
      >
        {i18n.t('customers-InfiniteScroll-noResultsFound')}
      </Option>
    );

    /** Overriding native click behavior */
    useEffect(() => {
      const handleClick = (ev: MouseEvent) => {
        if (selectRef?.current) {
          const target = (ev.target as Element).closest('div');
          // If user clicks on a selected chip, keep menu closed
          if (target?.dataset?.testid?.includes(CHIP_LABEL_TESTID)) {
            selectRef.current?.focus();
            setLastClicked(`${target.dataset.testid} close`);
            // If user clicks on input...
          } else if (target?.className.includes(CHIP_SELECT_CLASS)) {
            // set initial focus state based on menu being open on init or not
            if (openOnInit) {
              selectRef.current?.blur();
              setLastClicked(`${target.className} close`);
            } else {
              selectRef.current?.focus();
              setLastClicked(`${target.className} open`);
            }

            // after clicking a chip, open menu
            if (lastClicked.includes(CHIP_LABEL_TESTID)) {
              selectRef.current?.blur();
              selectRef.current?.focus();
              setLastClicked(`${target.className} open`);
              // after previously opening the menu, close the menu
            } else if (lastClicked.includes('open')) {
              selectRef.current?.blur();
              setLastClicked(`${target.className} close`);
              // after previously closing the menu, open the menu
            } else if (lastClicked.includes('close')) {
              selectRef.current?.focus();
              setLastClicked(`${target.className} open`);
            }
          } else {
            // reset last clicked
            setLastClicked('');
          }
        }
      };

      window.removeEventListener('click', handleClick);
      window.addEventListener('click', handleClick);
      return () => window.removeEventListener('click', handleClick);
    }, [lastClicked, openOnInit]);

    /** Opens dropdown on init when boolean is present */
    useEffect(() => {
      if (openOnInit && selectRef?.current) {
        selectRef.current?.focus();
      }
    }, [openOnInit]);

    return (
      <Container error={error} disabled={disabled}>
        {label && (
          <InputLabel error={error} disabled={disabled}>
            {label}
          </InputLabel>
        )}
        <ReactSelect
          name={dataTestId}
          // @ts-expect-error overwriting to use native react ref
          ref={selectRef}
          options={
            // need to include label key to allow native react-select searching
            copyKeyInArray(options, 'text', 'label') as MultiValue<OptionType>
          }
          value={selected}
          onChange={(val, actionMeta) => handleChange(val, actionMeta)}
          isMulti
          isDisabled={disabled}
          placeholder={placeholder}
          isSearchable
          menuPlacement="auto"
          isLoading={loading}
          classNamePrefix={CHIP_SELECT_CLASS}
          components={{
            DropdownIndicator: createDropdownIndicator,
            Option: createOption,
            MultiValueContainer: createMultiValueChip,
            NoOptionsMessage: createNoOptionComponent,
          }}
          openMenuOnClick={false}
          openMenuOnFocus
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          data-testid={dataTestId}
        />
        {!hideBottomSpace && (
          <InputError
            error={error}
            helperText={helperText}
            dataTestId={`input-error-${dataTestId}`}
          />
        )}
      </Container>
    );
  }
);

export default ChipSelect;
