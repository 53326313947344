import i18n from 'i18n-js';
import type { ThemeSet } from 'styled-theming';
import LABEL_THEMES from '../../styles/themes/library/label';
import { RATING_TYPES, type Response } from './types';

/**
 * Returns theme colorset for rating label
 */
export const CONTACT_LABEL_COLORS: {
  [key in RATING_TYPES]: ThemeSet;
} = {
  DISSATISFIED: LABEL_THEMES.DARK_RED_LABEL,
  SATISFIED: LABEL_THEMES.DARK_GREEN_LABEL,
  NEUTRAL: LABEL_THEMES.DARK_GRAY_LABEL,
};

/**
 * Returns i18n text for rating label
 * @param rating
 * @returns string
 */
export const getRatingChipLabel = (rating: RATING_TYPES | undefined) => {
  switch (rating) {
    case RATING_TYPES.SATISFIED:
      return i18n.t('feedback-feedback-satisfied', {
        defaultValue: 'Satisfied',
      });
    case RATING_TYPES.DISSATISFIED:
      return i18n.t('feedback-feedback-dissatisfied', {
        defaultValue: 'Dissatisfied',
      });
    case RATING_TYPES.NEUTRAL:
      return i18n.t('feedback-feedback-neutral', {
        defaultValue: 'Neutral',
      });
    default:
      return '';
  }
};

/** Converts 'user-friendly' rating text to our rating labels text */
const RATING_CONVERSION_MAP: { [key: string]: RATING_TYPES } = {
  Dissatisfied: RATING_TYPES.DISSATISFIED,
  Satisfied: RATING_TYPES.SATISFIED,
  'Neither satisfied nor dissatisfied': RATING_TYPES.NEUTRAL,
};

/**
 * Finds the survey response that best matches the rating labels
 * @param responses Survey Responses array
 * @returns Rating Label
 */
export const getResponseRating = (
  responses: Response[]
): RATING_TYPES | undefined => {
  const rating = responses.find(
    (r) => r.response in RATING_CONVERSION_MAP
  )?.response;
  return rating ? RATING_CONVERSION_MAP[rating] : undefined;
};

/**
 * Finds the survey response that best matches a message
 * @param responses Survey Responses array
 * @returns message string
 */
export const getResponseMessage = (
  responses: Response[]
): string | undefined => {
  return responses.find((r) => r.type === 'text' && r.response !== '')
    ?.response;
};
