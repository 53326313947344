import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import PAYMENT_GROUPS_QUERY from "../../../graphql/query/PaymentGroupsQuery.graphql";
import { contactName } from "../../../utils/helpers";
import useHasEmailChannel from "../channels/useHasEmailChannel";
import useHasSmsChannel from "../channels/useHasSmsChannel";

/*

DOCUMENTATION
  this hook is created to be used with CreatePaymentRecipient

  args:
    client: (obj) required. Apollo Client instance.
    i18n: (func) required: library for internationalization.
    currentUser: (obj) required. Object that contains the data related to the logged user.
    allGroups: (arr) required. Groups array that comes from the state manager.
    threadsActiveGroupIds: (arr) required. the id of the users active group (from where the text/thread will be sent).
    updateField: (func) callback function returned by the useForm hook.
    fields: (obj) fields object returned by the useForm hook.

  returns:
    groupOptions: (obj) these are the groups that the user has selected as active, they have been restructured to { value, label }. Meant to be used with "select" elements.
    fromContact: (obj) state from wizard. group message is being sent from, extracted from the fields obj. Used for displaying group/mutation in payment form 2.
    toContact: (obj) state from wizard. contact message is being sent to, extracted from the fields obj. Used for displaying Entity card/submitting on mutation in payment form 2.
    messageType: (obj) state from wizard. hold value and text for selected message type. Used in CreatePaymentRecipient to display selected message type.
    contactPhoneNumber: (str) holds value for selected toContact phoneNumber if one exists. Used to set messageTypeError and control what UI is available depending on contactPhoneNumber exisitng. 
    contactEmailAddress: (str) holds value for selected toContact emailAddress if one exists. Used to set messageTypeError and control what UI is available depending on contactEmailAddress exisitng. 
    contactText: (str) message value for selected fromContact or toContact errors
    setContactText: (func) sets state for messageTypeError
    messageTypeError: (str) message value for selected fromContact or toContact errors
    setMessageTypeError: (func) sets state for messageTypeError
    handleUpdateToContact: (func) callback for setting selected toContact state with proper [value,otherProps] properties.
    handleMessageTypeChange: (func) callback for setting selected messageType with proper [value,text] properties.
    disableTextOption: (bool) boolean to disable SMS option in ButtonSelector when requirements not met. I.e. the selected fromContact or toContact does not have SMS available.
    disableEmailOption: (bool) boolean to disable SMS option in ButtonSelector when requirements not met. I.e. the selected fromContact or toContact does not have EMAIL available.
    disableContinueButton: (bool) boolean to disable the continue button is all inputs do not meet requirements. I.e. sending channel available on both the selected fromContact AND toContact.

*/

const useCreatePaymentRecipient = ({
  client,
  i18n,
  currentUser,
  allGroups,
  threadsActiveGroupIds,
  updateField,
  fields,
}) => {
  const [contactText, setContactText] = useState("");
  const [messageTypeError, setMessageTypeError] = useState(null);
  const { fromContact, toContact, messageType } = fields;

  // Used to simplify the toContact phone number and email checks
  const contactPhoneNumber = toContact?.otherProps?.phoneNumber;
  const contactEmailAddress = toContact?.otherProps?.emailAddress;

  const { data: paymentGroupsData } = useQuery(PAYMENT_GROUPS_QUERY, {
    client,
  });

  const paymentGroupsPopulated = paymentGroupsData?.paymentGroups?.map(
    (paymentGroupId) => {
      return allGroups.find((group) => group.id === paymentGroupId);
    }
  );

  const groupOptions = paymentGroupsPopulated
    ? paymentGroupsPopulated
        .filter(
          (group) =>
            group &&
            threadsActiveGroupIds.some((activeId) => activeId === group.id)
        )
        ?.map((group) => ({ value: group.id, label: group.name }))
    : [];

  const defaultSenderGroup =
    groupOptions?.length === 1
      ? groupOptions[0]
      : groupOptions?.find(
          (option) => option.value === threadsActiveGroupIds[0]
        );

  // Set default sender group if one exists
  useEffect(() => {
    if (!fromContact?.value) {
      updateField({
        name: "fromContact",
        otherProps: { text: defaultSenderGroup?.label },
        value: defaultSenderGroup?.value,
      });
    }
  }, [defaultSenderGroup?.value, fromContact?.value]);

  const handleTextProp = (contact) => {
    if (contact?.firstName && contact?.lastName) return contactName(contact);
    // In case of manual entry contact
    if (contact?.phoneNumber || contact?.emailAddress)
      return contact?.phoneNumber || contact?.emailAddress;
    return "";
  };

  const handleUpdateToContact = (contact) =>
    updateField({
      name: "toContact",
      otherProps: {
        text: handleTextProp(contact),
        accountName: contact?.account?.name || "",
        phoneNumber: contact?.phoneNumber || "",
        emailAddress: contact?.emailAddress || "",
        conversationsOptOut: contact?.conversationsOptOut || false,
        emailsOptOut: contact?.emailsOptOut || false,
      },
      value: contact?.id,
    });

  const handleMessageTypeChange = (channelVal) => {
    // clear input if messageType selected is the current messageType
    if (messageType?.otherProps?.text === channelVal) {
      updateField({ name: "messageType", value: "", otherProps: { text: "" } });
    } else {
      let value = "";
      if (channelVal === "all") {
        value = "ALL";
      } else if (channelVal === "sms") {
        value = "SMS";
      } else {
        value = "EMAIL";
      }
      updateField({
        name: "messageType",
        value,
        otherProps: { text: channelVal },
      });
    }
  };

  // Check selected fromContact group for an active email and SMS channel
  const { hasEmailChannel } = useHasEmailChannel({
    currentUser,
    threadsActiveGroupIds: [fromContact?.value],
    client,
  });
  const { hasSmsChannel } = useHasSmsChannel({
    currentUser,
    threadsActiveGroupIds: [fromContact?.value],
    client,
  });

  // Disable ButtonSelector options based on selected fromContact and toContact available channels
  const disableTextOption = !!(
    (fromContact?.value && !hasSmsChannel) ||
    (toContact?.otherProps?.text &&
      (!contactPhoneNumber || toContact?.otherProps?.conversationsOptOut))
  );
  const disableEmailOption = !!(
    (fromContact?.value && !hasEmailChannel) ||
    (toContact?.otherProps?.text &&
      (!contactEmailAddress || toContact?.otherProps?.emailsOptOut))
  );
  const disableContinueButton = !!(
    (!contactPhoneNumber && !contactEmailAddress) ||
    !fromContact?.value ||
    !messageType?.value ||
    (disableEmailOption && disableTextOption)
  );

  useEffect(() => {
    if (fromContact?.value) {
      // When fromContact exists, but toContact doesn't, sets messageTypeError based on fromContact available channels
      if (!hasEmailChannel) {
        setMessageTypeError(
          i18n.t("slideouts-CreatePaymentRecipient-groupPhone", {
            defaultValue: "This group only has a registered phone number",
          })
        );
      } else if (!hasSmsChannel) {
        setMessageTypeError(
          i18n.t("slideouts-CreatePaymentRecipient-groupEmail", {
            defaultValue: "This group only has a registered email address",
          })
        );
      } else {
        setMessageTypeError("");
      }

      // If the fromContact selected does not have access to the current messageType selected, clear it out
      if (
        (!hasSmsChannel && messageType?.value === ("SMS" || "ALL")) ||
        (!hasEmailChannel && messageType?.value === ("EMAIL" || "ALL"))
      ) {
        updateField({
          name: "messageType",
          value: "",
          otherProps: { text: "" },
        });
      }
    }

    if (toContact?.otherProps?.text) {
      if (!contactEmailAddress || toContact?.otherProps?.emailsOptOut) {
        // sets messageTypeError if toContact missing emailAddress OR opted out of emails
        if (toContact?.otherProps?.emailsOptOut) {
          setMessageTypeError(
            i18n.t("slideouts-CreatePaymentRecipient-optOutEmail", {
              defaultValue:
                "The contact you've selected has opted out of email.",
            })
          );
        } else if (toContact?.value) {
          setMessageTypeError(
            i18n.t("slideouts-CreatePaymentRecipient-contactPhone", {
              defaultValue: "This contact only has an associated phone number",
            })
          );
        }
      } else if (
        !contactPhoneNumber ||
        toContact?.otherProps?.conversationsOptOut
      ) {
        // sets messageTypeError if toContact missing phoneNumber OR opted out of SMS
        if (toContact?.otherProps?.conversationsOptOut) {
          setMessageTypeError(i18n.t("slideouts-CreateThread-optedOutError"));
        } else if (toContact?.value) {
          setMessageTypeError(
            i18n.t("slideouts-CreatePaymentRecipient-contactEmail", {
              defaultValue: "This contact only has an associated email",
            })
          );
        }
      }

      // If the toContact selected does not have access to the current messageType selected, clear it out
      if (
        ((!contactPhoneNumber || toContact?.otherProps?.conversationsOptOut) &&
          messageType?.value === ("SMS" || "ALL")) ||
        ((!contactEmailAddress || toContact?.otherProps?.emailsOptOut) &&
          messageType?.value === ("EMAIL" || "ALL"))
      ) {
        updateField({
          name: "messageType",
          value: "",
          otherProps: { text: "" },
        });
      }
    }
  }, [
    hasEmailChannel,
    hasSmsChannel,
    fromContact?.value,
    toContact?.value,
    contactPhoneNumber,
    contactEmailAddress,
  ]);

  return {
    groupOptions,
    fromContact,
    toContact,
    messageType,
    contactPhoneNumber,
    contactEmailAddress,
    contactText,
    setContactText,
    messageTypeError,
    setMessageTypeError,
    handleUpdateToContact,
    handleMessageTypeChange,
    disableTextOption,
    disableEmailOption,
    disableContinueButton,
  };
};

export default useCreatePaymentRecipient;
