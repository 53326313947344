import { useQuery } from "@apollo/client";
import GROUP_QUERY from "../../graphql/query/GroupQuery.graphql";

/*
DOCUMENTATION

this hook is meant to be used for a generic group query for the client.

args:
  client: (obj) required. apolloClient instance
  ...options: other apollo options dev wants

returns:
  apolloQuery: (obj) make of loading, error and data

*/

const useGroupQuery = ({ client, ...options }) =>
  useQuery(GROUP_QUERY, { client, ...options });

export default useGroupQuery;
