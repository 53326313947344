/*

DOCUMENTATION
  this hook was designed to be used on user sign in/page load (if already signed in). It handles some backup measures taken to ensure that the client is always up to date
  with the latest version of distributor. It recieves a callback that will be fired if the client is out of date

  args:
    onUpgradeCallback: (func) required. Will call callback when app is not up to date

  returns:
    verifyClientVersion: (func). Each platform can call this at their leisure. web app will call every 30 mins and mobile will call
    when app is set to foreground.

*/

const splitSemanticVersion = (versionStr) => versionStr.split(".");
const convertVersionsToNum = (versionArr) =>
  versionArr.map((version) => Number(version));

const shouldClientUpdate = (clientVersion, latestVersion) => {
  try {
    const [majorClient, minorClient, patchClient] = convertVersionsToNum(
      splitSemanticVersion(clientVersion)
    );
    const [majorLatest, minorLatest, patchLatest] = convertVersionsToNum(
      splitSemanticVersion(latestVersion)
    );

    const majorUpdate = majorClient < majorLatest;
    const minorUpdate = minorClient < minorLatest && majorClient <= majorLatest;
    const patchUpdate =
      patchClient < patchLatest &&
      minorClient <= minorLatest &&
      majorClient <= majorLatest;

    return majorUpdate || minorUpdate || patchUpdate;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const useVerifyClientVersion = ({ onUpgradeCallback }) => {
  const verifyClientVersion = async () => {
    const env = process.env.PK_ENV;

    if (env in ["prod", "qa", "staging"]) {
      let endpoint;
      switch (env) {
        case "prod":
          endpoint = "https://public.prokeep.com/public/v1/client-version";
          break;
        case "qa":
          endpoint = "https://public.qa.prokeep.com/public/v1/client-version";
          break;
        case "staging":
          endpoint =
            "https://public.staging.prokeep.com/public/v1/client-version";
          break;
        default:
          endpoint = "https://public.prokeep.com/public/v1/client-version";
      }

      // eslint-disable-next-line no-undef
      const data = await fetch(endpoint)
        .then((response) => response.json())
        .catch(console.warn);

      if (data?.web && shouldClientUpdate(process.env.VERSION, data.web)) {
        onUpgradeCallback?.();
      }
    }
  };

  return [verifyClientVersion];
};

export default useVerifyClientVersion;
