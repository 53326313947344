import { useMutation } from "@apollo/client";
import START_FAX_THREAD_MUTATION from "../../graphql/mutation/StartFaxThreadMutation.graphql";

/*

DOCUMENTATION
  generic hook for using start fax thread mutation

  args:
    client: (obj) required. apollo client instance

  returns:
    [0] handleStartThread: func that will call poseMessageMutation.
      args:
        senderGroupId: (str) required. group id of user group.
        externalContactId: (str) required (empty string if giving externalPhoneNumber). contact id of who user is messaging.
        externalFaxNumber: (str) required (empty string if giving externalContactId). phone num user is messaging.
        message: (obj) required. consists of { text (str), attachments (arr) }
        onSuccess: (func) optional, will call on mutation success
        onError: (func) optional, will call on mutation failure

*/

const useStartFaxThreadMutation = ({ client }) => {
  const [startFaxThread] = useMutation(START_FAX_THREAD_MUTATION, { client });

  const handleStartFaxThread = async ({
    senderGroupId = null,
    externalContactId = null,
    externalFaxNumber = null,
    message = "",
    tags = null,
    onSuccess = () => {},
    onError = () => {},
  }) => {
    const { data } = await startFaxThread({
      variables: {
        input: {
          senderGroupId,
          externalContactId,
          externalFaxNumber,
          message,
          tags,
        },
      },
    }).catch((err) => {
      onError(err);
    });

    if (data?.startFaxThread?.errors) {
      onError(data.startFaxThread.errors[0]);
    } else {
      onSuccess(data);
    }
  };

  return [handleStartFaxThread];
};

export default useStartFaxThreadMutation;
