import React, { useState, useRef } from 'react';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { contactName } from 'client-lib/src/lib/utils/helpers';
import THEMES from '../../styles/themes/app';
import {
  Button,
  TabList,
  Checkbox,
  Avatar,
  Text,
  EmphasisText,
  Loading,
} from '../../elements';
import TargetedAudienceFilter from './TargetedAudienceFilter';
import InfiniteScroll from '../InfiniteScroll/InfiniteScroll';
import AudienceCustomerGroupBadges from '../Announcements/Lists/AudienceCustomerGroupBadges';
import TextWithHighlights from '../Common/TextWithHighlights';

export const NoResults = styled(Grid)`
  padding-left: 1rem;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 10px;
`;

export const AnnouncementColumns = styled.section`
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  align-items: center;
  border-right: solid 1px ${THEMES.BORDER_COLOR};
  background-color: ${THEMES.BACKGROUND_TERTIARY};
  height: 100%;
  max-width: 200px;
  min-width: 180px;
  padding-top: 20px;
`;

const LoadingWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  height: 70%;
`;

const LeftAligned = styled(Grid)`
  width: 50%;
`;

const RightAligned = styled(Grid)`
  width: 50%;
  justify-content: flex-end;
  display: flex;
`;

const SearchWrapper = styled(Grid)`
  padding-left: 16px;
  margin-top: 10px;
`;

const TableWrapper = styled(Grid)`
  margin-top: 16px;
  padding-left: 16px;
`;

const AvatarContainer = styled.span`
  margin-right: 15px;
`;

const FullNameContainer = styled.span`
  max-width: 650px;
  overflow: hidden;
`;

const CheckboxContainer = styled.span`
  margin-right: 15px;
  width: 15px;
`;

const RowLeftAligned = styled(Grid)`
  display: flex;
  align-items: center;
`;

const RowRightAligned = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const InfiniteScrollHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${THEMES.BACKGROUND_TERTIARY};
  height: 32px;
  padding: 4px;
`;

const AudienceMemberRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  padding: 8px 16px;
  border-bottom: 1px solid ${THEMES.BORDER_COLOR};
  background-color: ${THEMES.BACKGROUND_PRIMARY};
`;

const SelectedIcon = styled.i`
  font-size: 24px !important;
`;

const LargeRowContainer = styled.div`
  width: 50%;
`;

const BadgeRowContainer = styled.div`
  width: 30%;
`;

const SmallRowContainer = styled.div`
  width: 20%;
`;

const Wrapper = styled.div`
  & .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
  }
`;

const BadgeRowContainerWithRef = React.forwardRef((props, ref) => (
  <BadgeRowContainer {...props} ref={ref}>
    {props.children}
  </BadgeRowContainer>
));

BadgeRowContainerWithRef.propTypes = {
  children: PropTypes.element.isRequired,
};

BadgeRowContainerWithRef.displayName = 'BadgeRowContainerWithRef';

const AudienceMemberRow = ({
  audience,
  handleRemove,
  handleAdd,
  isSelectedAudience,
  isCheckedAudience,
  handleOnCheckAudience,
  buildName,
  buildDescription,
  disableAddRemove,
  groups,
  firstAudienceRow,
  targetedAudienceFilter,
  containerRef,
}) => (
  <AudienceMemberRowContainer key={audience.id}>
    <LargeRowContainer>
      <RowLeftAligned>
        <CheckboxContainer>
          {isSelectedAudience(audience) || disableAddRemove ? (
            <div />
          ) : (
            <Checkbox
              checked={isCheckedAudience(audience)}
              onCheck={() => handleOnCheckAudience(audience)}
            />
          )}
        </CheckboxContainer>
        <AvatarContainer>
          {audience.__typename === 'CustomerAccount' ? (
            <Avatar icon="company" size="lg">
              {buildName(audience)}
            </Avatar>
          ) : (
            <Avatar
              icon={
                audience.__typename === 'Tag' || audience.__typename === 'Group'
                  ? 'tag'
                  : null
              }
              size="lg"
            >
              {buildName(audience)}
            </Avatar>
          )}
        </AvatarContainer>

        <FullNameContainer>
          <EmphasisText
            contrast="med"
            customStyle={() => `
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          `}
          >
            <TextWithHighlights
              text={buildName(audience)}
              highlightTarget={targetedAudienceFilter}
            />
          </EmphasisText>
          <Text contrast="med">
            <TextWithHighlights
              text={buildDescription(audience)}
              highlightTarget={targetedAudienceFilter}
            />
          </Text>
        </FullNameContainer>
      </RowLeftAligned>
    </LargeRowContainer>
    <BadgeRowContainerWithRef ref={containerRef}>
      <AudienceCustomerGroupBadges
        audience={audience}
        groups={groups}
        firstAudienceRow={firstAudienceRow}
        containerRef={containerRef}
      />
    </BadgeRowContainerWithRef>
    <SmallRowContainer>
      {disableAddRemove ? null : (
        <RowRightAligned>
          {isSelectedAudience(audience) ? (
            <Button
              onClick={() => handleRemove(audience)}
              size="md"
              type="secondaryLight"
            >
              {i18n.t('slideouts-GroupMessageRecipients-remove')}
            </Button>
          ) : (
            <Button
              onClick={() => handleAdd(audience)}
              size="md"
              type="secondaryLight"
            >
              {i18n.t('slideouts-GroupMessageRecipients-add')}
            </Button>
          )}
        </RowRightAligned>
      )}
    </SmallRowContainer>
  </AudienceMemberRowContainer>
);

AudienceMemberRow.propTypes = {
  isSelectedAudience: PropTypes.func.isRequired,
  isCheckedAudience: PropTypes.func.isRequired,
  audience: PropTypes.object.isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleOnCheckAudience: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired,
  buildDescription: PropTypes.func.isRequired,
  buildName: PropTypes.func.isRequired,
  disableAddRemove: PropTypes.bool.isRequired,
  groups: PropTypes.object.isRequired,
  firstAudienceRow: PropTypes.object.isRequired,
  targetedAudienceFilter: PropTypes.string,
  containerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    .isRequired,
};

AudienceMemberRow.defaultProps = {
  targetedAudienceFilter: '',
};

const TargetedAudience = ({
  handleSelectedAudience,
  loading,
  tabOptions,
  audience,
  audiencePageInfo,
  audienceFetchMore,
  selectedAudienceCount,
  selectedAudience = [],
  setSelectedAudience,
  scrollHeight,
  futureRemovals,
  setFutureRemovals,
  showSelectedAudience,
  handleTabChange,
  handleOnAdd,
  handleOnRemove,
  extraButton,
  disableAddRemove,
  filterInputPlaceholder,
  nullValueMessage,
  groups,
  targetedAudienceFilter,
  groupsPaginated,
}) => {
  const containerRef = useRef();

  const [checkedAudience, setCheckedAudience] = useState([]);
  const [activeTab, setActiveTab] = useState(tabOptions[0].value);

  const isCustomerAccount = (customer) =>
    customer.__typename === 'CustomerAccount';

  const buildDescription = (audience) => {
    let description = '';
    if (
      audience.__typename === 'CustomerAccount' ||
      audience.__typename === 'CustomerContact'
    ) {
      if (!isCustomerAccount(audience) && audience.account !== null) {
        const accountName = audience?.account?.name;
        const accountNumber = audience?.account?.accountNumber
          ? `| ACCT# ${audience?.account?.accountNumber}`
          : '';

        description = `${accountName} ${accountNumber}`;
      }
    } else {
      // Refactor this, once it's clear where i can get the description field
      description = '';
    }
    return description;
  };

  const buildName = (audience) => {
    let name = '';
    if (
      audience.__typename === 'CustomerAccount' ||
      audience.__typename === 'CustomerContact'
    ) {
      if (isCustomerAccount(audience)) {
        name = audience.name;
      } else {
        name = contactName(audience);
      }
    } else {
      name = audience.name;
    }

    return name;
  };

  const onTabChange = (tabValue) => {
    setActiveTab(tabValue);
    setCheckedAudience([]);
    handleTabChange();
  };

  const activeOptions = tabOptions.map((option) => ({
    ...option,
    active: activeTab === option.value && !showSelectedAudience,
  }));

  const handleAdd = (audience) => {
    if (futureRemovals.some((element) => element.id === audience.id)) {
      const filteredSelections = futureRemovals.filter(
        (element) => element.id !== audience.id
      );
      setFutureRemovals([...filteredSelections]);
    } else {
      setSelectedAudience([...selectedAudience, audience]);
    }
    setCheckedAudience(
      checkedAudience.filter((element) => element.id !== audience.id)
    );
    handleOnAdd();
  };

  const handleAddMultiple = () => {
    const removeFromRemovalList = checkedAudience.filter((audience) =>
      futureRemovals.find((element) => element.id === audience.id)
    );
    if (removeFromRemovalList.length > 0) {
      const addToAddList = checkedAudience.filter(
        (audience) =>
          !futureRemovals.find((element) => element.id === audience.id)
      );
      const filteredRemovalList = futureRemovals.filter(
        (removal) =>
          !removeFromRemovalList.find((element) => element.id === removal.id)
      );
      setSelectedAudience([...selectedAudience, ...addToAddList]);
      setFutureRemovals(filteredRemovalList);
    } else {
      setSelectedAudience([...selectedAudience, ...checkedAudience]);
    }

    setCheckedAudience([]);
    handleOnAdd();
  };

  // If the Remove button is enabled, means that is a selected element. First i check in my local state to find it
  const handleRemove = (audience) => {
    if (selectedAudience.some((element) => element.id === audience.id)) {
      const filteredSelections = selectedAudience.filter(
        (element) => element.id !== audience.id
      );
      setSelectedAudience([...filteredSelections]);
    } else {
      setFutureRemovals([...futureRemovals, audience]);
    }
    setCheckedAudience(
      checkedAudience.filter((element) => element.id !== audience.id)
    );
    handleOnRemove();
  };

  const handleOnCheckAudience = (audience) => {
    if (isCheckedAudience(audience)) {
      const filteredSelections = checkedAudience.filter(
        (element) => element.id !== audience.id
      );
      setCheckedAudience([...filteredSelections]);
    } else {
      setCheckedAudience([...checkedAudience, audience]);
    }
  };

  const isCheckedAudience = (audience) =>
    checkedAudience.some((element) => element.id === audience.id);

  // Checks if it's inside my internal state and if not future removal
  const isSelectedAudience = (audience) =>
    selectedAudience.some((element) => element.id === audience.id) ||
    (audience?.selected &&
      !futureRemovals.some((element) => element.id === audience.id)) ||
    (showSelectedAudience &&
      !futureRemovals.some((element) => element.id === audience.id)) ||
    (groupsPaginated &&
      groupsPaginated.some((group) => group.id === audience.id) &&
      !futureRemovals.some((element) => element.id === audience.id));

  const activeAudience = loading
    ? []
    : showSelectedAudience
      ? audience
      : audience[activeTab];
  const activePageInfo = showSelectedAudience
    ? audiencePageInfo
    : audiencePageInfo[activeTab];
  const activeFetchMore = showSelectedAudience
    ? audienceFetchMore
    : audienceFetchMore[activeTab];

  return (
    <>
      <Grid container>
        <LeftAligned>
          <Button
            onClick={handleSelectedAudience}
            type="noStyle"
            noOutline
            iconAndText
            customStyle={(props) => `
              margin-left: 15px;
              background-color: ${
                showSelectedAudience ? THEMES.BACKGROUND_TERTIARY(props) : ''
              };
              :hover { background-color: ${
                showSelectedAudience ? THEMES.BACKGROUND_TERTIARY(props) : ''
              } }
            `}
          >
            <SelectedIcon className="ri-menu-add-line" />
            {i18n.t('slideouts-GroupMessageRecipients-selectedAudience')} (
            {selectedAudienceCount})
          </Button>
        </LeftAligned>
        <RightAligned>
          <TabList
            options={activeOptions}
            onClick={({ value }) => onTabChange(value)}
            type="secondary"
          />
        </RightAligned>
      </Grid>
      <SearchWrapper>
        <TargetedAudienceFilter
          placeholder={
            filterInputPlaceholder ||
            i18n.t('slideouts-TargetedAudience-search')
          }
        />
      </SearchWrapper>

      {loading ? (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      ) : (
        <TableWrapper>
          <InfiniteScrollHead>
            {extraButton || <div />}
            <RowRightAligned>
              {checkedAudience.length > 0 ? (
                <Button onClick={handleAddMultiple} size="md">
                  {i18n.t('slideouts-GroupMessageRecipients-addSelected', {
                    number: checkedAudience.length,
                  })}
                </Button>
              ) : (
                ''
              )}
            </RowRightAligned>
          </InfiniteScrollHead>

          {activeAudience.length === 0 ? (
            <NoResults>
              <Text>
                {nullValueMessage ||
                  i18n.t('slideouts-TargetedAudience-noResults')}
              </Text>
            </NoResults>
          ) : (
            <Wrapper>
              <InfiniteScroll
                bidirectionalScroll={false}
                scrollableList={activeAudience}
                height={scrollHeight}
                noRowsElement={<div />}
                loading={false}
                renderRow={({ list, index }) => (
                  <AudienceMemberRow
                    audience={list[index]}
                    handleAdd={handleAdd}
                    handleRemove={handleRemove}
                    isSelectedAudience={isSelectedAudience}
                    isCheckedAudience={isCheckedAudience}
                    handleOnCheckAudience={handleOnCheckAudience}
                    buildName={buildName}
                    buildDescription={buildDescription}
                    disableAddRemove={disableAddRemove}
                    groups={groups}
                    firstAudienceRow={list[0]}
                    targetedAudienceFilter={targetedAudienceFilter}
                    containerRef={containerRef}
                  />
                )}
                hasNextPage={activePageInfo?.hasNextPage}
                loadingBorderBottom={false}
                loadingHeight={72}
                listItemHeight={72}
                loadMoreRows={activeFetchMore}
                // scrollToIndex={scrollToIndex}
              />
            </Wrapper>
          )}
        </TableWrapper>
      )}
    </>
  );
};

TargetedAudience.propTypes = {
  loading: PropTypes.bool,
  selectedAudienceCount: PropTypes.number,
  handleSelectedAudience: PropTypes.func.isRequired,
  tabOptions: PropTypes.arrayOf(Object).isRequired,
  audience: PropTypes.object.isRequired,
  selectedAudience: PropTypes.arrayOf(Object).isRequired,
  futureRemovals: PropTypes.arrayOf(Object).isRequired,
  setFutureRemovals: PropTypes.func.isRequired,
  audienceFetchMore: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
    .isRequired,
  audiencePageInfo: PropTypes.object.isRequired,
  setSelectedAudience: PropTypes.func.isRequired,
  scrollHeight: PropTypes.string,
  showSelectedAudience: PropTypes.bool.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  handleOnAdd: PropTypes.func,
  handleOnRemove: PropTypes.func,
  extraButton: PropTypes.element,
  disableAddRemove: PropTypes.bool,
  filterInputPlaceholder: PropTypes.string,
  nullValueMessage: PropTypes.string,
  groups: PropTypes.object.isRequired,
  targetedAudienceFilter: PropTypes.string,
  groupsPaginated: PropTypes.array,
};

TargetedAudience.defaultProps = {
  loading: false,
  selectedAudienceCount: 0,
  scrollHeight: '500px',
  handleOnAdd: () => {},
  handleOnRemove: () => {},
  extraButton: null,
  disableAddRemove: false,
  filterInputPlaceholder: null,
  nullValueMessage: null,
  targetedAudienceFilter: '',
  groupsPaginated: [],
};

export default TargetedAudience;
