export const SET_ACCOUNT = 'SET_ACCOUNT';
export const SET_GROUPS = 'SET_GROUPS';
export const SET_ACTIVE_EMAIL_CHANNELS = 'SET_ACTIVE_EMAIL_CHANNELS';
export const SET_GROUPS_REFETCH_KEY = 'SET_GROUPS_REFETCH_KEY';
export const REFETCH_SETTINGS_USERS = 'REFETCH_SETTINGS_USERS';

export const setAccount = (account) => ({
  type: SET_ACCOUNT,
  account,
});

export const setGroups = (allGroups) => ({
  type: SET_GROUPS,
  allGroups,
});

export const setActiveEmailChannels = (activeEmailChannels) => ({
  type: SET_ACTIVE_EMAIL_CHANNELS,
  activeEmailChannels,
});

export const setGroupsRefetchKey = (groupsRefetchKey) => ({
  type: SET_GROUPS_REFETCH_KEY,
  groupsRefetchKey,
});

export const setRefetchSettingsUsers = (refetchSettingsUsers) => ({
  type: REFETCH_SETTINGS_USERS,
  refetchSettingsUsers,
});
