import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Clipboard from 'clipboard';
import i18n from 'i18n-js';
import { Heading1, Heading4, Text, Button, Anchor } from '../../elements';
import { PROKEEP_SUPPORT_LINK } from '../../utils/helpers/constants/index.ts';

const HeadingWrapper = styled.div`
  margin-bottom: 24px;
`;

const AnchorWrapper = styled.div`
  margin-top: 8px;
`;

class ForgotPasswordConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successButtonValue: this.props.i18n.t(
        'signin-ForgotPasswordConfirmation-copyToClipboard'
      ),
      selectionSuccessful: false,
    };
  }

  componentDidMount() {
    this.clipboard = new Clipboard(this.buttonRef, {
      target: () => this.emailRef,
    });
  }

  componentWillUnmount() {
    // remove clipboard package
    this.clipboard.destroy();
    // Clear any pending UI updates
    if (this.timeout !== null) {
      clearTimeout(this.timeout);
    }
  }

  handleOnClick = (e) => {
    e.preventDefault();
    if (this.timeout !== null) {
      clearTimeout(this.timeout);
    }
    this.setState(
      () => ({
        successButtonValue: this.props.i18n.t(
          'signin-ForgotPasswordConfirmation-copied'
        ),
        selectionSuccessful: true,
      }),
      () => {
        this.timeout = setTimeout(
          () =>
            this.setState({
              successButtonValue: this.props.i18n.t(
                'signin-ForgotPasswordConfirmation-copyToClipboard'
              ),
              selectionSuccessful: false,
            }),
          3000
        );
      }
    );
  };

  render() {
    const { selectionSuccessful, successButtonValue } = this.state;
    return (
      <div data-testid="forgot-password-confirmation">
        <HeadingWrapper>
          <Heading1>
            {i18n.t('signin-ForgotPasswordConfirmation-helpOnTheWay')}
          </Heading1>
        </HeadingWrapper>
        <Text style={{ marginBottom: '28px' }}>
          {i18n.t('signin-ForgotPasswordConfirmation-headToEmail')}
        </Text>
        <Text>
          {i18n.t('signin-ForgotPasswordConfirmation-contactSupport')}
        </Text>
        <div style={{ margin: '20px 0' }}>
          <Heading4>
            {i18n.t('signin-ForgotPasswordConfirmation-phone')}
          </Heading4>
          <AnchorWrapper>
            <Anchor href="tel:1-504-226-7756">1-504-226-7756</Anchor>
          </AnchorWrapper>
        </div>
        <div>
          <Heading4>
            {' '}
            {i18n.t('signin-ForgotPasswordConfirmation-email')}
          </Heading4>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Anchor
              ref={(node) => {
                this.emailRef = node;
              }}
              href="mailto:support@prokeep.com?Subject=Support%20Request"
            >
              support@prokeep.com
            </Anchor>
            <Button
              type="link"
              ref={(node) => {
                this.buttonRef = node;
              }}
              onClick={this.handleOnClick}
              selectionSuccessful={selectionSuccessful}
            >
              {successButtonValue}
            </Button>
          </div>
        </div>
        <div
          style={{
            margin: '28px auto',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Heading4>
            {i18n.t('signin-ForgotPasswordConfirmation-needHelp')}{' '}
            <Anchor href={PROKEEP_SUPPORT_LINK} target="_blank">
              {i18n.t('signin-ForgotPasswordConfirmation-clickHere')}
            </Anchor>
          </Heading4>
          <Text>
            <Anchor href="/login">
              {i18n.t('signin-ForgotPasswordConfirmation-back')}
            </Anchor>
          </Text>
        </div>
      </div>
    );
  }
}

ForgotPasswordConfirmation.propTypes = {
  i18n: PropTypes.object.isRequired,
};

export default ForgotPasswordConfirmation;
