import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import THEMES from '../../styles/themes/library/table';
import APP_THEMES from '../../styles/themes/app';

const TableStyled = styled.table`
  width: 100%;
  border-spacing: 0;
  font-family: 'Barlow', sans-serif;
  border-radius: 4px 4px 4px 4px;
  text-align: left;
  table-layout: fixed;

  th:first-child {
    border-radius: 4px 0 0 0;
  }
  th:last-child {
    border-radius: 0 4px 0 0;
  }
  th:last-child:first-child {
    border-radius: 4px 4px 0 0;
  }
  tr:nth-last-child(1) td:first-child {
    border-radius: 0 0 0 4px;
  }
  tr:nth-last-child(1) td:last-child {
    border-radius: 0 0 4px 0;
  }
  tr:nth-last-child(1) td:last-child:first-child {
    border-radius: 0 0 4px 4px;
  }

  tr:nth-child(n + 1) td {
    border-bottom: 1px solid ${THEMES.BODY_BORDER};
  }
  td:nth-child(1) {
    border-left: 1px solid ${THEMES.BODY_BORDER};
  }
  td:nth-last-child(1) {
    border-right: 1px solid ${THEMES.BODY_BORDER};
  }

  ${(props) =>
    props.type === 'secondary'
      ? `
      tr:nth-child(even) {
        background-color: ${APP_THEMES.BACKGROUND_QUATERNARY(props)};
      }
    `
      : null}
  ${(props) => props?.customStyle?.()}
`;

const Table = ({ type, ...props }) => <TableStyled type={type} {...props} />;

Table.propTypes = {
  type: PropTypes.string,
};

Table.defaultProps = {
  type: 'primary',
};

export default Table;
