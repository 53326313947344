import theme from 'styled-theming';
import { calcLargeSize } from './fontSize';

const DEFAULTFONTSIZES = {
  AVATAR_SM: 11,
  AVATAR_MD: 14,
  AVATAR_LG: 16,
  ICON_AVATAR_SM: 14,
  ICON_AVATAR_MD: 18,
  ICON_AVATAR_LG: 22,
};

const LARGEFONTSIZES = {
  AVATAR_SM: calcLargeSize(DEFAULTFONTSIZES.AVATAR_SM),
  AVATAR_MD: calcLargeSize(DEFAULTFONTSIZES.AVATAR_MD),
  AVATAR_LG: calcLargeSize(DEFAULTFONTSIZES.AVATAR_LG),
  ICON_AVATAR_SM: calcLargeSize(DEFAULTFONTSIZES.ICON_AVATAR_SM),
  ICON_AVATAR_MD: calcLargeSize(DEFAULTFONTSIZES.ICON_AVATAR_MD),
  ICON_AVATAR_LG: calcLargeSize(DEFAULTFONTSIZES.ICON_AVATAR_LG),
};

const AVATAR_SM = theme('fontSize', {
  default: DEFAULTFONTSIZES.AVATAR_SM,
  large: LARGEFONTSIZES.AVATAR_SM,
});

const AVATAR_MD = theme('fontSize', {
  default: DEFAULTFONTSIZES.AVATAR_MD,
  large: LARGEFONTSIZES.AVATAR_MD,
});

const AVATAR_LG = theme('fontSize', {
  default: DEFAULTFONTSIZES.AVATAR_LG,
  large: LARGEFONTSIZES.AVATAR_LG,
});

const ICON_AVATAR_SM = theme('fontSize', {
  default: DEFAULTFONTSIZES.ICON_AVATAR_SM,
  large: LARGEFONTSIZES.ICON_AVATAR_SM,
});

const ICON_AVATAR_MD = theme('fontSize', {
  default: DEFAULTFONTSIZES.ICON_AVATAR_MD,
  large: LARGEFONTSIZES.ICON_AVATAR_MD,
});

const ICON_AVATAR_LG = theme('fontSize', {
  default: DEFAULTFONTSIZES.ICON_AVATAR_LG,
  large: LARGEFONTSIZES.ICON_AVATAR_LG,
});

const FONTSIZE_THEMES = {
  AVATAR_SM,
  AVATAR_MD,
  AVATAR_LG,
  ICON_AVATAR_SM,
  ICON_AVATAR_MD,
  ICON_AVATAR_LG,
};

export default FONTSIZE_THEMES;
