import {
  AUTOSUGGEST_ONCHANGE,
  CLOSE_EDIT_CUSTOMER_MODAL,
  CLEAR_SUGGESTIONS,
  OPEN_EDIT_CUSTOMER_MODAL,
  POPULATE_COMPANY_FIELDS,
  POPULATE_CONTACT_FIELDS,
  POPULATE_SUGGESTIONS,
  SELECT_SUGGESTION,
  CLEAR_SUGGESTION,
  SET_CREATING_ACCOUNT,
  SET_CREATING_ACCOUNT_FALSE,
  PREPARE_NEW_ACCOUNT,
  TOGGLE_DELETE_CUSTOMER_CONFIRMATION,
  TOGGLE_DELETE_CUSTOMER_PROMPT,
  TRIGGER_EDIT_CONTACT_COMPANY,
  TRIGGER_EDIT_CONTACT_JOB_TITLE,
  TRIGGER_EDIT_CONTACT_LABELS,
  TRIGGER_EDIT_COMPANY_ACCOUNT_NUM,
  SET_DEFAULT_CONTACT_TAB,
  SET_DEFAULT_ORGANIZATION_TAB,
  OPTIMISTIC_DELETED_COMPANY_IDS,
} from '../actions/editCustomer';
import { TOGGLE_BLOCK_CHANNEL_PROMPT_EDIT_CUSTOMER } from '../actions';
import { normalizePhone } from '../components/Inputs/validation';

const DEFAULT_STATE = {
  // Array to hold suggestion values from autosuggest
  suggestions: [],

  // If true, show fields for company primary phone and email
  creatingAccount: false,

  // Boolean value that turns off create account option if
  // component is passed accountId.
  allowCreateAccount: true,

  // New account ID associated with new company created during add new
  // createCustomerAccountMutation. Different than the ID passed in to EditCustomer.
  newAccountId: '',

  // All account values placeholder from customerAccountQuery
  account: null,

  // All contact values placeholder from customerContactQuery
  contact: null,
  accountName: '',
  accountPhoneNumber: '',
  accountEmailAddress: '',
  accountNumber: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  emailAddress: '',
  activeModal: '',
  showDeletePrompt: false,
  showDeleteConfirmation: false,
  showBlockChannelConfirmation: false,
  channelToBeBlocked: '',
  triggerEditContactJobTitle: false,
  triggerEditContactCompany: false,
  triggerEditContactLabels: false,
  triggerEditCompanyAccountNum: false,
  setDefaultContactTab: '',
  setDefaultOrganizationTab: '',
  optimisticDeletedCompanyIds: [],
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case OPEN_EDIT_CUSTOMER_MODAL:
      return {
        ...state,
        [action.name]: true,
        activeModal: action.name,
      };
    case CLOSE_EDIT_CUSTOMER_MODAL:
      return {
        ...state,
        suggestions: [],
        account: null,
        accountId: '',
        creatingAccount: false,
        allowCreateAccount: true,
        accountName: '',
        accountPhoneNumber: '',
        accountEmailAddress: '',
        accountNumber: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        emailAddress: '',
        contact: null,
        [action.name]: false,
        activeModal: '',
        showDeletePrompt: false,
        showDeleteConfirmation: false,
      };
    case POPULATE_SUGGESTIONS:
      return {
        ...state,
        suggestions: action.suggestions,
      };
    case CLEAR_SUGGESTIONS:
      return {
        ...state,
        suggestions: [],
      };
    case SELECT_SUGGESTION:
      return {
        ...state,
        accountName: '',
        account: action.account,
      };
    case CLEAR_SUGGESTION:
      return {
        ...state,
        account: null,
        accountName: '',
        creatingAccount: false,
      };
    case SET_CREATING_ACCOUNT:
      return {
        ...state,
        creatingAccount: true,
      };
    case SET_CREATING_ACCOUNT_FALSE:
      return {
        ...state,
        creatingAccount: false,
      };
    case POPULATE_COMPANY_FIELDS:
      return {
        ...state,
        allowCreateAccount: action.fields.allowCreateAccount,
        creatingAccount: action.fields.creatingAccount,
        accountName: action.fields.accountName,
        accountPhoneNumber: normalizePhone(action.fields.accountPhoneNumber),
        accountEmailAddress: action.fields.accountEmailAddress,
        accountNumber: action.fields.accountNumber,
        account: action.fields.account,
      };
    case POPULATE_CONTACT_FIELDS:
      return {
        ...state,
        firstName: action.fields.firstName,
        lastName: action.fields.lastName,
        phoneNumber: normalizePhone(action.fields.phoneNumber),
        emailAddress: action.fields.emailAddress,
        contact: action.fields.contact,
      };
    case AUTOSUGGEST_ONCHANGE:
      return {
        ...state,
        account:
          action.values.account !== null
            ? action.values.account
            : state.account,
        accountName:
          action.values.accountName !== null
            ? action.values.accountName
            : state.accountName,
        allowCreateAccount:
          action.values.allowCreateAccount !== null
            ? action.values.allowCreateAccount
            : state.allowCreateAccount,
      };
    case PREPARE_NEW_ACCOUNT:
      return {
        ...state,
        newAccountId: action.values.newAccountId,
        account: action.values.account,
        creatingAccount: action.values.creatingAccount,
        firstName: action.values.firstName,
        lastName: action.values.lastName,
        phoneNumber: action.values.phoneNumber,
        emailAddress: action.values.emailAddress,
        accountNumber: action.values.accountNumber,
      };
    case TOGGLE_DELETE_CUSTOMER_CONFIRMATION:
      return {
        ...state,
        showDeleteConfirmation: action.toggleValue,
      };
    case TOGGLE_DELETE_CUSTOMER_PROMPT:
      return {
        ...state,
        showDeletePrompt: action.toggleValue,
      };
    case TOGGLE_BLOCK_CHANNEL_PROMPT_EDIT_CUSTOMER:
      return {
        ...state,
        showBlockChannelConfirmation: action.toggleValue,
        channelToBeBlocked: action.channelToBeBlocked,
      };
    case TRIGGER_EDIT_CONTACT_JOB_TITLE:
      return {
        ...state,
        triggerEditContactJobTitle: action.value,
      };
    case TRIGGER_EDIT_CONTACT_COMPANY:
      return {
        ...state,
        triggerEditContactCompany: action.value,
      };
    case TRIGGER_EDIT_CONTACT_LABELS:
      return {
        ...state,
        triggerEditContactLabels: action.value,
      };
    case TRIGGER_EDIT_COMPANY_ACCOUNT_NUM:
      return {
        ...state,
        triggerEditCompanyAccountNum: action.value,
      };
    case SET_DEFAULT_CONTACT_TAB:
      return {
        ...state,
        setDefaultContactTab: action.value,
      };
    case SET_DEFAULT_ORGANIZATION_TAB: {
      return {
        ...state,
        setDefaultOrganizationTab: action.value,
      };
    }
    case OPTIMISTIC_DELETED_COMPANY_IDS: {
      return {
        ...state,
        optimisticDeletedCompanyIds: [
          ...state.optimisticDeletedCompanyIds,
          action.value,
        ],
      };
    }
    default:
      return state;
  }
}
