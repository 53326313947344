import theme from 'styled-theming';
import COLORS from '../../colors';

// primary button values
const BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.WHITE,
  dark: COLORS.PK_DARK.BLACK,
  holiday: COLORS.PK.WHITE,
});
const BORDER = theme('mode', {
  classic: COLORS.PK_CLASSIC.SECONDARY_GRAY,
  light: COLORS.PK.GREY_20,
  dark: COLORS.PK_DARK.GREY_3,
  holiday: COLORS.PK.GREY_20,
});
const TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.SECONDARY_BLACK,
  light: COLORS.PK.BLACK,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_HIGH,
  holiday: COLORS.PK.BLACK,
});
const LABEL = theme('mode', {
  classic: COLORS.PK_CLASSIC.TERTIARY_BLACK,
  light: COLORS.PK.GREY_90,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_MED,
  holiday: COLORS.PK.GREY_90,
});
const PLACEHOLDER = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_GRAY,
  light: COLORS.PK.GREY_40,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_LOW,
  holiday: COLORS.PK.GREY_40,
});
const ERROR = theme('mode', {
  classic: COLORS.PK_CLASSIC.PURE_RED,
  light: COLORS.PK.RED_80,
  dark: COLORS.PK.RED_80,
  holiday: COLORS.PK.RED_80,
});
const DISABLED_BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.LIGHT_GRAY,
  light: COLORS.PK.GREY_20,
  dark: COLORS.PK.GREY_60,
  holiday: COLORS.PK.GREY_20,
});
const ICON = theme('mode', {
  classic: COLORS.PK_CLASSIC.DARK_GRAY,
  light: COLORS.PK.GREY_40,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_LOW,
  holiday: COLORS.PK.GREY_40,
});
const FOCUS = theme('mode', {
  classic: COLORS.PK_CLASSIC.SKY_BLUE,
  light: COLORS.PK.BLUE_60,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_HIGH,
  holiday: COLORS.PK.BLUE_60,
});
const HELPER_TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_GRAY,
  light: COLORS.PK.GREY_70,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_MED,
  holiday: COLORS.PK.GREY_70,
});

const OPTION_BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.WHITE,
  dark: COLORS.PK_DARK.BLACK,
  holiday: COLORS.PK.WHITE,
});

const OPTION_BACKGROUND_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.LIGHT_BLUE,
  light: COLORS.PK.GOLD_40,
  dark: COLORS.PK_DARK.GREY_5,
  holiday: COLORS.PK.GOLD_40,
});

const OPTION_BACKGROUND_ACTIVE = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_BLUE,
  light: COLORS.PK.GREY_10,
  dark: COLORS.PK_DARK.GREY_4,
  holiday: COLORS.PK.GREY_10,
});

const OPTION_TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.SECONDARY_BLACK,
  light: COLORS.PK.BLACK,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_HIGH,
  holiday: COLORS.PK.BLACK,
});

const OPTION_TEXT_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.BLACK,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_HIGH,
  holiday: COLORS.PK.BLACK,
});

const OPTION_TEXT_ACTIVE = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.BLACK,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_HIGH,
  holiday: COLORS.PK.BLACK,
});

const THEMES = {
  BACKGROUND,
  BORDER,
  TEXT,
  LABEL,
  PLACEHOLDER,
  ERROR,
  DISABLED_BACKGROUND,
  ICON,
  FOCUS,
  HELPER_TEXT,

  OPTION_BACKGROUND,
  OPTION_BACKGROUND_HOVER,
  OPTION_BACKGROUND_ACTIVE,
  OPTION_TEXT,
  OPTION_TEXT_HOVER,
  OPTION_TEXT_ACTIVE,
};

export default THEMES;
