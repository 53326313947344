import React from 'react';
import styled from 'styled-components';
import { useHistory, type RouteComponentProps } from 'react-router-dom';
import { contactName } from 'client-lib/src/lib/utils/helpers';
import { Avatar, Heading4, IconButton, Text, ChipLabel } from '../../elements';
import FormattedDateTime from '../Common/FormattedDateTime';
import LABEL_THEMES from '../../styles/themes/library/label';
import {
  CONTACT_LABEL_COLORS,
  getRatingChipLabel,
  getResponseMessage,
  getResponseRating,
} from './shared';
import THEMES from '../../styles/themes/app';
import { type Feedback } from './types';

const getLightText = () => `font-weight: 400;`;

const FlexData = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  overflow: hidden;
`;

const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContactTD = styled.td`
  padding: 16px 0 16px 16px;
`;

const FeedbackTD = styled.td`
  padding: 16px 16px 16px 0;
`;

const OverflowWrapper = styled.div`
  text-wrap: nowrap;
  display: block;
  width: 100%;
  overflow: hidden;
  p {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const RowContainer = styled.tr<{ isSelected: boolean }>`
  cursor: pointer;
  background: ${(props) =>
    props.isSelected ? THEMES.BACKGROUND_QUATERNARY : ''};
`;

const getTimestampText = () => `
  font-weight: 400;
  font-size: 12px;
`;

// handles what to render when row is clicked
const handleOnClickRow = (
  history: RouteComponentProps['history'],
  selected: Feedback | undefined,
  row: Feedback,
  isSelected: boolean
) => {
  if (!selected?.id) {
    history.push(`/feedback/${row?.id}`);
  } else if (isSelected) {
    history.push(`/feedback`);
  } else {
    history.push(`/feedback/${row?.id}`);
  }
};

interface FeedbackTableRowProps {
  row: Feedback;
  selected: Feedback | undefined;
}

const FeedbackTableRow = ({
  row,
  selected = undefined,
}: FeedbackTableRowProps) => {
  const history = useHistory();
  const { contact, completedAt, responses } = row;
  const name = contactName(contact);
  const isSelected = selected?.id === row?.id;
  const rating = getResponseRating(responses);
  const message = getResponseMessage(responses);

  return (
    <RowContainer
      isSelected={isSelected}
      onClick={() => handleOnClickRow(history, selected, row, isSelected)}
    >
      <ContactTD>
        <FlexData>
          <Avatar size="lg" dataTestId="feedback-avatar">
            {name}
          </Avatar>
          <FlexData>
            <Heading4>{name}</Heading4>
            <Text customStyle={getTimestampText}>
              <FormattedDateTime value={completedAt} />
            </Text>
          </FlexData>
        </FlexData>
      </ContactTD>
      <FeedbackTD>
        <FlexBetween>
          <FlexData>
            {rating && (
              <ChipLabel
                dataTestId="feedback-chip-label"
                backgroundColor={CONTACT_LABEL_COLORS[rating]}
                textColor={LABEL_THEMES.LABEL_TEXT}
                size="sm"
                noOutline
              >
                {getRatingChipLabel(rating)}
              </ChipLabel>
            )}
            <OverflowWrapper>
              <Text customStyle={getLightText}>{message}</Text>
            </OverflowWrapper>
          </FlexData>
          <IconButton noOutline dataTestId={row?.id}>
            {isSelected ? (
              <i className="ri-arrow-left-s-line" />
            ) : (
              <i className="ri-arrow-right-s-line" />
            )}
          </IconButton>
        </FlexBetween>
      </FeedbackTD>
    </RowContainer>
  );
};

export default FeedbackTableRow;
