import styled from 'styled-components';
import THEMES from '../../../styles/themes/app';

export const Container = styled.div`
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  box-shadow: 0 4px 4px -2px ${THEMES.BORDER_COLOR};
  display: flex;
  min-height: 48px;
  padding: 16px;
  width: calc(100% - 32px);
  flex-grow: 0;
  flex-shrink: 0;
  z-index: 1;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  overflow-wrap: break-word;
`;

export const TilteButtonContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  padding-left: 8px;
`;

export const SecondaryTitleButtonContainer = styled.div`
  max-width: 180px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-right: 16px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AvatarContainer = styled.div`
  margin-right: 8px;
`;

export const getSubtitleTextStyles = () => `
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
`;

export const getHeaderTextStyles = () => `overflow-wrap: anywhere;`;

export const FlexBox = styled.div`
  align-items: center;
  display: flex;
`;

export const FlexBoxFill = styled(FlexBox)`
  flex: 1;
`;

export const OptionsBubbleContainer = styled.div`
  position: relative;
  width: 0;
  padding-top: 20px;
  z-index: 2;
`;

export const OptionsButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0px;
  width: fit-content;

  button:first-of-type {
    /* background-color: red !important; */
    border-radius: 4px 4px 0 0;
  }
  button:last-of-type {
    /* background-color: green !important; */
    border-radius: 0 0 4px 4px;
  }
  button:only-of-type {
    border-radius: 4px;
  }
`;

export const bubbleButtonHoverStyle = (props: object) => `
  @media print {display: none;}
  :hover {
    background-color: ${THEMES.COLORED_BACKGROUND_SECONDARY(props)};
    color: ${THEMES.COLORED_FOREGROUND_HIGH(props)};
  }
`;
