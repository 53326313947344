import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prokeepLogoSvg } from '../../../svgs/index.tsx';
import THEMES from '../../styles/themes/app';

const contrastStyles = {
  logo: {
    primary: THEMES.LOGO_PRIMARY, // add these hex's to color file
    secondary: THEMES.LOGO_SECONDARY,
  },
  high: {
    primary: THEMES.FOREGROUND_MED,
    secondary: THEMES.FOREGROUND_LOW,
  },
  colorHigh: {
    primary: THEMES.COLORED_FOREGROUND_MED,
    secondary: THEMES.COLORED_FOREGROUND_LOW,
  },
};

const sizeStyles = {
  xxs: {
    height: 40,
    width: 48,
  },
  xs: {
    height: 60,
    width: 72,
  },
  sm: {
    height: 80,
    width: 96,
  },
  md: {
    height: 100,
    width: 120,
  },
  lg: {
    height: 120,
    width: 144,
  },
};

const LoadingStyled = styled.div`
  @keyframes move-left {
    0% {
      transform: translateX(0);
    }
    30% {
      transform: translateX(-12%);
    }
    75% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes move-right {
    0% {
      transform: translateX(0);
    }
    30% {
      transform: translateX(12%);
    }
    75% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(0);
    }
  }

  & svg {
    height: ${(props) => sizeStyles[props.size].height}px;
    width: ${(props) => sizeStyles[props.size].width}px;
    display: flex;
  }

  path.primary-color {
    fill: ${(props) => contrastStyles[props.contrast].primary};
  }
  path.secondary-color {
    fill: ${(props) => contrastStyles[props.contrast].secondary};
  }

  path.secondary-color.top {
    transform: translateX(0);

    animation-name: move-left;
    animation-duration: 1.3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
  }

  path.primary-color.top {
    transform: translateX(0);

    animation-name: move-right;
    animation-duration: 1.3s;
    animation-iteration-count: infinite;
    animation-delay: 0.1s;
    animation-timing-function: ease-out;
  }

  path.secondary-color.bottom {
    transform: translateX(0);

    animation-name: move-left;
    animation-duration: 1.3s;
    animation-iteration-count: infinite;
    animation-delay: 0.2s;
    animation-timing-function: ease-out;
  }

  path.primary-color.bottom {
    transform: translateX(0);

    animation-name: move-right;
    animation-duration: 1.3s;
    animation-iteration-count: infinite;
    animation-delay: 0.3s;
    animation-timing-function: ease-out;
  }
`;

const Loading = ({ contrast, size }) => (
  <LoadingStyled contrast={contrast} size={size}>
    {prokeepLogoSvg}
  </LoadingStyled>
);

Loading.propTypes = {
  contrast: PropTypes.string,
  size: PropTypes.string,
};

Loading.defaultProps = {
  contrast: 'logo',
  size: 'md',
};

export default Loading;
