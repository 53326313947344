import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';
import { USERS_QUERY } from 'client-lib';
import useLazyPaginated from 'client-lib/src/lib/api/query/useLazyPaginated';
import { AsyncSelectPaginate } from '../../elements';

const UserAsyncSelect = ({
  value,
  setValue,
  error,
  queryVars,
  onClear,
  ...props
}) => {
  const client = useApolloClient();
  const [queryError, setQueryError] = useState('');

  const { triggerQuery, handleFetchMore, pageInfo } = useLazyPaginated({
    client,
    query: USERS_QUERY,
    key: 'users',
    resultsNumber: 25,
    queryVariables: {
      filter: value,
      ...queryVars,
    },
  });

  const formatUsersToInputOptions = (data) => {
    return data?.users?.edges.map((user) => ({
      label: `${user.node?.firstName} ${user.node?.lastName}`,
      value: user.node?.id,
      role: user.node?.role,
      avatarContent: user.node?.avatarUrl,
    }));
  };

  const initialQuery = (inputVal) => {
    return triggerQuery({
      variables: {
        filter: inputVal,
        first: 25,
        ...queryVars,
      },
    });
  };

  const fetchMore = () => {
    return handleFetchMore();
  };

  const loadOptions = async (inputVal, prevOptions, additional) => {
    let response;
    if (prevOptions?.length > 0 && additional.prevInputVal === inputVal) {
      response = await fetchMore(inputVal, prevOptions);
    } else {
      response = await initialQuery(inputVal);
    }

    const { data, error } = response;

    const hasMore = data?.users?.pageInfo?.hasNextPage;

    if (error) {
      setQueryError('error');
      return { options: [], hasMore: false };
    }

    const formattedUsers = formatUsersToInputOptions(data);

    return {
      options: formattedUsers,
      hasMore,
      additional: { prevInputVal: inputVal },
    };
  };

  return (
    <AsyncSelectPaginate
      value={value}
      styledSelectKey={queryVars?.groupIds?.[0] ?? ''}
      onChange={(selectedOption) => {
        setValue(selectedOption);
      }}
      onClear={onClear}
      loadOptions={loadOptions}
      hasMore={pageInfo.hasNextPage}
      customOptionStyle={() =>
        'padding: 8px; border-bottom: 1px solid #e0e0e0;'
      }
      error={queryError || error}
      dataTestId="user-async-select"
      {...props}
    />
  );
};

UserAsyncSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  setValue: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  error: PropTypes.string,
  queryVars: PropTypes.object,
};

UserAsyncSelect.defaultProps = {
  value: null,
  error: '',
  queryVars: undefined,
  onClear: () => {},
};

export default UserAsyncSelect;
