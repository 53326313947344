import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { slugify } from 'client-lib/src/lib/utils/helpers';
import THEMES from '../../../../styles/themes/app';
import BUTTON_THEMES from '../../../../styles/themes/library/button';
import {
  SELECTION_INDICATOR,
  SELECTION_INDICATOR_HOVER,
  SELECTION_INDICATOR_UNSELECTED,
} from '../../../../components/ScopeTabBar/ScopeTabBar';
import { EmphasisText } from '../../..';

const tabColumnFormatter = (tabAmount) => {
  switch (tabAmount) {
    case 1:
      return '100%';

    case 2:
      return '50% 50%';

    case 3:
      return '33% 34% 33%';

    case 4:
      return '25% 25% 25% 25%';

    default:
      return '100%';
  }
};

const Container = styled.div`
  display: grid;
  grid-gap: 0;
  grid-template-columns: ${(props) => tabColumnFormatter(props.tabAmount)};
  height: 35px;
  width: 100%;
`;

const Tab = styled.div`
  display: inline-grid;
  height: 100%;
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  justify-items: center;
  align-items: flex-end;
  color: ${THEMES.FOREGROUND_HIGH};
  font-weight: bold;
  text-transform: capitalize;
  cursor: pointer;

  &:hover div.selection-indicator {
    background-color: ${SELECTION_INDICATOR_HOVER} !important;
  }
  &:focus {
    z-index: 3;
    outline-offset: -1px;
    outline-color: ${BUTTON_THEMES.BUTTON_OUTLINE};
  }
`;

const SelectionIndicator = styled.div`
  height: 5px;
  width: 100%;
  background-color: ${(props) =>
    props.isSelected ? SELECTION_INDICATOR : SELECTION_INDICATOR_UNSELECTED};
`;

const SuggestionTabs = ({ tabLabels, activeTabIndex, setActiveTabIndex }) => (
  <Container tabAmount={tabLabels.length}>
    {tabLabels.map((tabLabel, index) => (
      <Tab
        key={slugify(tabLabel)}
        className="suggestion-tab"
        onClick={() => setActiveTabIndex(index)}
        tabIndex="0"
      >
        <EmphasisText>{tabLabel}</EmphasisText>
        <SelectionIndicator
          className="selection-indicator"
          isSelected={activeTabIndex === index}
        />
      </Tab>
    ))}
  </Container>
);

SuggestionTabs.propTypes = {
  tabLabels: PropTypes.array.isRequired,
  activeTabIndex: PropTypes.number.isRequired,
  setActiveTabIndex: PropTypes.func.isRequired,
};

export default SuggestionTabs;
