import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { slugify } from 'client-lib/src/lib/utils/helpers';
import Button from '../Button/Button';
import THEMES from '../../styles/themes/app';

const Container = styled.div`
  display: flex;
  margin-bottom: 8px;

  & button {
    margin: 0 4px;
    border-radius: 36px;
  }

  & button:nth-child(1) {
    margin-left: 0;
  }

  & button:nth-last-child(1) {
    margin-right: 0;
  }
`;

const typeStyles = {
  primary: {
    background: THEMES.COLORED_BACKGROUND_SECONDARY,
    activeColor: THEMES.COLORED_FOREGROUND_HIGH,
    inactiveColor: THEMES.FOREGROUND_HIGH,
  },
  secondary: {
    background: THEMES.BACKGROUND_TERTIARY,
    activeColor: THEMES.FOREGROUND_HIGH,
    inactiveColor: THEMES.FOREGROUND_HIGH,
  },
};

const findTextColor = (props) =>
  props?.active
    ? typeStyles[props.tabListType].activeColor(props)
    : typeStyles[props.tabListType].inactiveColor(props);
const findBackgroundColor = (props) =>
  props?.active
    ? typeStyles[props.tabListType].background(props)
    : 'transparent';

const buttonStyle = (props) => `
  background-color: ${findBackgroundColor(props)};
  color: ${findTextColor(props)};
  height: 32px;
  :hover {
    background-color: ${findBackgroundColor(props)};
    color: ${findTextColor(props)};
  }
`;

const RoundedTabList = ({ options, onClick, customContainerStyle, type }) => (
  <Container
    customContainerStyle={customContainerStyle}
    css={(props) => props?.customContainerStyle?.(props)}
  >
    {options.map((option) => (
      <Button
        type="noStyle"
        onClick={() => onClick(option)}
        active={option?.active}
        customStyle={buttonStyle}
        key={option.value}
        disabled={option?.disabled}
        tabListType={type}
        dataTestId={`tab-list-option-${slugify(option.label, { lower: true })}`}
      >
        {option.label}
      </Button>
    ))}
  </Container>
);

RoundedTabList.propTypes = {
  type: PropTypes.string,
  options: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  customContainerStyle: PropTypes.func,
};

RoundedTabList.defaultProps = {
  type: 'primary',
  customContainerStyle: null,
};

export default RoundedTabList;
