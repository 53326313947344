import MY_INBOX_THREADS from "../../../graphql/query/MyInboxThreadsQuery.graphql";
import INBOX_SPAM_THREADS from "../../../graphql/query/InboxSpamThreadsQuery.graphql";
import MY_OPEN_THREADS from "../../../graphql/query/MyOpenThreadsQuery.graphql";
import ALL_OPEN_THREADS from "../../../graphql/query/AllOpenThreadsQuery.graphql";
import MY_CLOSED_THREADS from "../../../graphql/query/MyClosedThreadsQuery.graphql";
import ALL_CLOSED_THREADS from "../../../graphql/query/AllClosedThreadsQuery.graphql";
import threadsQueryVars from "../../../utils/threadsQueryVars";
import { useApolloSyncId } from "../../../configs/apollo/ApolloSyncContext";

const useReadThreadsFromApolloCache = ({
  client,
  threadsActiveGroupIds,
  contactId,
  includeInternal = false,
}) => {
  useApolloSyncId();

  const {
    genMyInboxThreadsVars,
    genInboxSpamThreadsVars,
    genMyOpenThreadsVars,
    genAllOpenThreadsVars,
    genMyClosedThreadVars,
    genAllClosedThreadVars,
  } = threadsQueryVars();

  const myInboxQueryVariables = genMyInboxThreadsVars({
    threadsActiveGroupIds,
    includeInternal,
  });

  let myInboxThreads = [];
  let myInboxThreadsPageInfo = null;

  try {
    const results = client.readQuery({
      query: MY_INBOX_THREADS,
      variables: myInboxQueryVariables,
    })?.myInboxThreads;

    myInboxThreads = results?.edges?.map((threadEdge) => threadEdge.node) || [];
    myInboxThreadsPageInfo = results?.pageInfo;
  } catch (e) {
    // it's expected that this cached query will not exist and error out at times
  }

  let inboxSpamThreads = [];
  let inboxSpamThreadsPageInfo = null;

  const inboxSpamThreadsQueryVariables = genInboxSpamThreadsVars({
    threadsActiveGroupIds,
    contactId,
    includeInternal,
  });

  try {
    const results = client.readQuery({
      query: INBOX_SPAM_THREADS,
      variables: inboxSpamThreadsQueryVariables,
    })?.inboxSpamThreads;

    inboxSpamThreads =
      results?.edges?.map((threadEdge) => threadEdge.node) || [];
    inboxSpamThreadsPageInfo = results?.pageInfo;
  } catch (e) {
    // it's expected that this cached query will not exist and error out at times
  }

  const myOpenThreadsQueryVariables = genMyOpenThreadsVars({
    threadsActiveGroupIds,
    contactId,
    includeInternal,
  });

  let myOpenThreads = [];
  let myOpenThreadsPageInfo = null;
  try {
    const results = client.readQuery({
      query: MY_OPEN_THREADS,
      variables: myOpenThreadsQueryVariables,
    })?.myOpenThreads;

    myOpenThreads = results?.edges?.map((threadEdge) => threadEdge.node) || [];
    myOpenThreadsPageInfo = results?.pageInfo;
  } catch (e) {
    // it's expected that this cached query will not exist and error out at times
  }

  const allOpenThreadsQueryVariables = genAllOpenThreadsVars({
    threadsActiveGroupIds,
    contactId,
    includeInternal,
  });

  let allOpenThreads = [];
  let allOpenThreadsPageInfo = null;
  try {
    const results = client.readQuery({
      query: ALL_OPEN_THREADS,
      variables: allOpenThreadsQueryVariables,
    })?.allOpenThreads;

    allOpenThreads = results?.edges?.map((threadEdge) => threadEdge.node) || [];
    allOpenThreadsPageInfo = results?.pageInfo;
  } catch (e) {
    // it's expected that this cached query will not exist and error out at times
  }

  const myClosedThreadsQueryVariables = genMyClosedThreadVars({
    threadsActiveGroupIds,
    contactId,
    includeInternal,
  });

  let myClosedThreads = [];
  let myClosedThreadsPageInfo = null;
  try {
    const results = client.readQuery({
      query: MY_CLOSED_THREADS,
      variables: myClosedThreadsQueryVariables,
    })?.myClosedThreads;

    myClosedThreads =
      results?.edges?.map((threadEdge) => threadEdge.node) || [];
    myClosedThreadsPageInfo = results?.pageInfo;
  } catch (e) {
    // it's expected that this cached query will not exist and error out at times
  }

  const allClosedThreadsQueryVariables = genAllClosedThreadVars({
    threadsActiveGroupIds,
    contactId,
    includeInternal,
  });

  let allClosedThreads = [];
  let allClosedThreadsPageInfo = null;
  try {
    const results = client.readQuery({
      query: ALL_CLOSED_THREADS,
      variables: allClosedThreadsQueryVariables,
    })?.allClosedThreads;

    allClosedThreads =
      results?.edges?.map((threadEdge) => threadEdge.node) || [];
    allClosedThreadsPageInfo = results?.pageInfo;
  } catch (e) {
    // it's expected that this cached query will not exist and error out at times
  }

  return {
    inboxThreads: myInboxThreads,
    inboxSpamThreads,
    myOpenThreads,
    allOpenThreads,
    allClosedThreads,
    myClosedThreads,

    inboxThreadsPageInfo: myInboxThreadsPageInfo,
    inboxSpamThreadsPageInfo,
    myOpenThreadsPageInfo,
    allOpenThreadsPageInfo,
    allClosedThreadsPageInfo,
    myClosedThreadsPageInfo,
  };
};

export default useReadThreadsFromApolloCache;
