import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import packageInfo from '../../../../package.json';
import THEMES from '../../styles/themes/app';
import { Heading3, Text } from '../../elements';

const getLeftBackground = (screenType) => {
  switch (screenType) {
    case 'notFound':
      return THEMES.COLORED_BACKGROUND_TERTIARY;

    case 'maintenance':
      return THEMES.THEME_YELLOW;

    default:
      return THEMES.COLORED_BACKGROUND_TERTIARY;
  }
};

const getRightBackground = (screenType) => {
  switch (screenType) {
    case 'notFound':
      return THEMES.COLORED_BACKGROUND_PRIMARY;

    case 'maintenance':
      return THEMES.BACKGROUND_PRIMARY;

    default:
      return THEMES.BACKGROUND_PRIMARY;
  }
};

const LeftColumn = styled.div`
  width: 40vw;
  height: 100%;
  background-color: ${(props) => getLeftBackground(props.screenType)(props)};
  overflow: hidden;
  position: relative;
`;

const RightColumn = styled.div`
  width: 60vw;
  height: 100%;
  background-color: ${(props) => getRightBackground(props.screenType)(props)};
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LeftContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: space-between;
`;

const WhitelabelImage = styled.img`
  max-width: 100%;
  max-height: 90%;
`;

const WhitelabelImgWrap = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PoweredByWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 16px;
`;

const LogoSpace = styled.div`
  height: 40%;
  max-height: 40%;
  width: 100%;
  display: flex;
  justify-content: center;

  & img {
    width: 50%;
  }
`;

const VersionSpace = styled.div`
  height: 20%;
  max-height: 20%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ArtworkSpace = styled.div`
  display: flex;
  justify-content: center;
  height: 40%;
  width: 90%;
  & svg {
    max-height: 90%;
  }
  & img.rocket {
    width: 90%;
    height: 100%;
  }
  & img.storage {
    width: 80%;
    height: 100%;
  }
  & img.office {
  }
`;

const Artwork = ({ screenType }) => {
  switch (screenType) {
    case 'notFound':
      return (
        <img
          className="office"
          src="/pk-svgs/illustration-office.svg"
          alt="prokeep illustration"
        />
      );

    case 'maintenance':
      return (
        <img
          className="storage"
          src="/pk-svgs/illustration-storage.svg"
          alt="prokeep illustration"
        />
      );

    default:
      return (
        <img
          className="rocket"
          src="/pk-svgs/work-station.svg"
          alt="prokeep illustration"
        />
      );
  }
};

Artwork.propTypes = {
  screenType: PropTypes.string,
};

Artwork.defaultProps = {
  screenType: 'default',
};

const Logo = ({ screenType }) => {
  switch (screenType) {
    case 'notFound':
      return <img src="/pk-svgs/pk-logo-mark-blue.svg" alt="prokeep logo" />;

    case 'maintenance':
      return <img src="/pk-svgs/pk-logo-white-mark.svg" alt="prokeep logo" />;

    default:
      return <img src="/pk-svgs/prokeep-logo-login.svg" alt="prokeep logo" />;
  }
};

Logo.propTypes = {
  screenType: PropTypes.string,
};

Logo.defaultProps = {
  screenType: 'default',
};

const ExternalScreenTemplate = ({
  screenType,
  children,
  waitForWhiteLabelQuery,
}) => {
  const whitelabelLogo = useSelector(
    (state) => state?.whitelabel?.whitelabelLogo
  );
  const hasQueriedWhitelabel = useSelector(
    (state) => state?.whitelabel?.hasQueriedWhitelabel
  );
  const logo = whitelabelLogo ? (
    <WhitelabelImgWrap>
      <WhitelabelImage src={whitelabelLogo} alt="company logo" />
    </WhitelabelImgWrap>
  ) : (
    <Logo screenType={screenType} />
  );

  const contrast = screenType !== 'maintenance' ? 'colorHigh' : 'high';

  return (
    <>
      <LeftColumn screenType={screenType}>
        <LeftContentContainer>
          <LogoSpace>
            {hasQueriedWhitelabel || !waitForWhiteLabelQuery ? logo : null}
          </LogoSpace>
          <ArtworkSpace>
            <Artwork screenType={screenType} />
          </ArtworkSpace>
          <VersionSpace>
            <div id="client-version">
              <Text contrast={contrast}>
                Client version: {packageInfo.version}
              </Text>
            </div>
            <div id="client-client-lib-version">
              <Text contrast={contrast}>
                Client-lib version: {packageInfo.version}
              </Text>
            </div>
            <div id="client-env">
              <Text contrast={contrast}>Build: {process.env.PK_ENV}</Text>
            </div>
          </VersionSpace>
        </LeftContentContainer>
        {whitelabelLogo ? (
          <PoweredByWrapper>
            <Heading3>POWERED BY PROKEEP</Heading3>
          </PoweredByWrapper>
        ) : null}
      </LeftColumn>
      <RightColumn screenType={screenType}>{children}</RightColumn>
    </>
  );
};

ExternalScreenTemplate.propTypes = {
  screenType: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.object,
  ]).isRequired,
  waitForWhiteLabelQuery: PropTypes.bool,
};

ExternalScreenTemplate.defaultProps = {
  screenType: 'default',
  waitForWhiteLabelQuery: false,
};

export default ExternalScreenTemplate;
