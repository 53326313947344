export const SET_CREATE_LABEL_FLYOUT = 'SET_CREATE_LABEL_FLYOUT';
export const SET_EDIT_LABEL_FLYOUT = 'SET_EDIT_LABEL_FLYOUT';

export const setCreateLabelFlyout = (value) => ({
  type: SET_CREATE_LABEL_FLYOUT,
  value,
});

export const setEditLabelFlyout = (value) => ({
  type: SET_EDIT_LABEL_FLYOUT,
  value,
});
