import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, IconLabel } from '..';
import THEMES from '../../styles/themes/app';
import BUTTON_THEMES from '../../styles/themes/library/button';

const TextWrapper = styled.div`
  text-align: left;
  padding-right: 8px;
`;

const customButtonStyles = (props) => `
  display: flex;
  justify-content: space-between;
  min-height: 56px;
  box-sizing: border-box;
  padding: 8px 12px;
  border-radius: 4px;
  border: 2px solid ${THEMES.BORDER_COLOR(props)};
  box-shadow: ${THEMES.BOX_SHADOW(props)};
  background-color: ${THEMES.BACKGROUND_PRIMARY(props)};
  color: ${THEMES.FOREGROUND_HIGH(props)};
  &:hover {
    color: ${THEMES.FOREGROUND_HIGH(props)};
    border: 2px solid ${THEMES.BORDER_COLOR(props)};
    box-shadow: ${THEMES.BOX_SHADOW(props)};
    background: ${THEMES.BACKGROUND_QUATERNARY(props)};
  };
  ${props?.customButtonStyle?.(props)}
`;

const customIconStyles = (props) => `
  border-radius: 0;
  border-left: 1px solid ${BUTTON_THEMES.DISABLED_TEXT(props)};
  height: 40px;
  padding-left: 8px;
  ${props?.customIconStyle?.(props)}
`;

const FlyoutButton = ({
  onClick,
  customButtonStyle,
  customTextStyle,
  customIconStyle,
  children,
  ...props
}) => {
  return (
    <Button
      iconAndText
      iconRight
      onClick={onClick}
      customButtonStyle={customButtonStyle}
      customStyle={(props) => customButtonStyles(props)}
      {...props}
    >
      <TextWrapper
        customTextStyle={customTextStyle}
        css={(props) => props?.customTextStyle?.(props)}
      >
        {children}
      </TextWrapper>
      <IconLabel
        noOutline
        customIconStyle={customIconStyle}
        customStyle={(props) => customIconStyles(props)}
        className="ri-arrow-right-s-line"
      />
    </Button>
  );
};

FlyoutButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  customButtonStyle: PropTypes.func,
  customTextStyle: PropTypes.func,
  customIconStyle: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]).isRequired,
};

FlyoutButton.defaultProps = {
  customButtonStyle: () => {},
  customTextStyle: () => {},
  customIconStyle: () => {},
};

export default FlyoutButton;
