import React, { useContext, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import theme from 'styled-theming';
import { Chip } from '../../elements';
import Bubble from '../Common/Bubble';
import AddTag from './AddTag';
import TextWithHighlights from '../Common/TextWithHighlights';

const BUBBLE_WIDTH = theme('fontSize', {
  default: 166,
  large: 206,
});

const BUBBLE_HEIGHT = theme('fontSize', {
  default: 160,
  large: 206,
});

const TAG_MAX_WIDTH = theme('fontSize', {
  default: 134,
  large: 174,
});

const TAG_ICON_FONTSIZE = theme('fontSize', {
  default: '13px',
  large: '17px',
});

const MultiTagDisplayWrapper = styled.div`
  display: flex;
  align-items: end; // baseline? Then would need to delete the lower wrapper
  white-space: nowrap;
  text-overflow: ellipsis;
  gap: 12px;
`;

const OptionsButtonContainer = styled.div`
  max-height: ${BUBBLE_HEIGHT}px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: flex-start;
  row-gap: 16px;
`;

const MultiTagDisplay = ({
  id,
  tags,
  handleAddOrRemoveThreadFromWorkOrder,
  tagInputValue,
  setTagInputValue,
  editingRefNum,
  setEditingRefNum,
  flipTagBubbleCallback,
  highlightTarget,
  flipWorkOrderOptions,
}) => {
  const [optionBubbleOpen, setOptionBubbleOpen] = useState(false);

  const styledTheme = useContext(ThemeContext);

  const wrapperRef = useRef();
  const flipTagBubbleYAxis = flipTagBubbleCallback(wrapperRef);

  const highlightTags = !!tags?.filter((tag) =>
    tag?.referenceNumber?.toLowerCase().includes(highlightTarget)
  ).length;

  return (
    <MultiTagDisplayWrapper ref={wrapperRef}>
      {tags?.length > 1 ? (
        <div
          onClick={() => setOptionBubbleOpen(true)}
          onKeyDown={() => setOptionBubbleOpen(true)}
          onKeyUp={() => setOptionBubbleOpen(true)}
          role="menuitem"
          tabIndex={0}
        >
          <div style={{ cursor: 'pointer' }}>
            <Chip
              size="sm"
              icon={
                <i
                  className="ri-price-tag-3-fill"
                  style={{
                    fontSize: TAG_ICON_FONTSIZE({ theme: styledTheme }),
                  }}
                />
              }
              highlight={highlightTags}
            >
              {tags?.length?.toString()}
            </Chip>
          </div>
          <Bubble
            isOpen={optionBubbleOpen}
            onClickOutside={() => setOptionBubbleOpen(false)}
            moveBubbleRightVal={-43}
            moveLeftVal={30}
            bubbleMinWidth={BUBBLE_WIDTH({ theme: styledTheme })}
            bubbleMaxWidth={BUBBLE_WIDTH({ theme: styledTheme })}
            top={flipTagBubbleYAxis ? '36px' : '12px'}
            zIndex={200}
            flipYAxis={flipTagBubbleYAxis}
            id={`options_bubble_${id || 0}`}
          >
            <OptionsButtonContainer>
              {tags?.map((tag, index) => (
                <Chip
                  key={tag.id || index}
                  size="sm"
                  onDelete={
                    !highlightTarget
                      ? () =>
                          handleAddOrRemoveThreadFromWorkOrder(
                            tag.referenceNumber,
                            true
                          )
                      : null
                  }
                  maxWidth={TAG_MAX_WIDTH({ theme: styledTheme })}
                >
                  {highlightTarget ? (
                    <TextWithHighlights
                      highlightTarget={highlightTarget}
                      text={tag.referenceNumber}
                      contrast="low"
                    />
                  ) : (
                    tag.referenceNumber
                  )}
                </Chip>
              ))}
            </OptionsButtonContainer>
          </Bubble>
        </div>
      ) : (
        tags?.map((tag, index) => (
          <Chip
            dataTestId="thread-tag-chip"
            key={tag.id || index}
            size="sm"
            onDelete={
              !highlightTarget
                ? () =>
                    handleAddOrRemoveThreadFromWorkOrder(
                      tag.referenceNumber,
                      true
                    )
                : null
            }
          >
            {highlightTarget ? (
              <TextWithHighlights
                highlightTarget={highlightTarget}
                text={tag.referenceNumber}
                contrast="low"
              />
            ) : (
              tag.referenceNumber
            )}
          </Chip>
        ))
      )}
      {tags.length < 10 && !highlightTarget ? (
        <AddTag
          tagInputValue={tagInputValue}
          setTagInputValue={setTagInputValue}
          editingRefNum={editingRefNum}
          setEditingRefNum={setEditingRefNum}
          handleAddThreadToWorkOrder={handleAddOrRemoveThreadFromWorkOrder}
          flipWorkOrderOptions={flipWorkOrderOptions}
        />
      ) : null}
    </MultiTagDisplayWrapper>
  );
};

MultiTagDisplay.propTypes = {
  id: PropTypes.string,
  tags: PropTypes.array.isRequired,
  handleAddOrRemoveThreadFromWorkOrder: PropTypes.func.isRequired,
  tagInputValue: PropTypes.string,
  setTagInputValue: PropTypes.func,
  editingRefNum: PropTypes.bool.isRequired,
  setEditingRefNum: PropTypes.func.isRequired,
  flipTagBubbleCallback: PropTypes.func,
  highlightTarget: PropTypes.string,
  flipWorkOrderOptions: PropTypes.bool,
};

MultiTagDisplay.defaultProps = {
  id: '',
  tagInputValue: '',
  setTagInputValue: () => {},
  flipTagBubbleCallback: () => false,
  highlightTarget: null,
  flipWorkOrderOptions: false,
};

export default MultiTagDisplay;
