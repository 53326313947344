import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from 'styled-theming';
import { slugify } from 'client-lib/src/lib/utils/helpers';
import Button from '../Button/Button';
import COLORS from '../../styles/colors';

const BACKGROUND_PRIMARY = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_NAVY,
  light: COLORS.PK.BLUE_100,
  dark: COLORS.PK_DARK.GREY_4,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_PRIMARY,
});
const ACTIVE_PRIMARY = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.WHITE,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_HIGH,
  holiday: COLORS.PK.WHITE,
});
const INACTIVE_PRIMARY = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_BLACK,
  light: COLORS.PK.GREY_90,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_HIGH,
  holiday: COLORS.PK.GREY_90,
});
const BACKGROUND_SECONDARY = theme('mode', {
  classic: COLORS.PK_CLASSIC.SECONDARY_GRAY,
  light: COLORS.PK.GREY_20,
  dark: COLORS.PK_DARK.GREY_3,
  holiday: COLORS.PK.GREY_20,
});

const Container = styled.div`
  display: flex;

  & button {
    margin: 0 4px;
  }

  & button:nth-child(1) {
    margin-left: 0;
  }

  & button:nth-last-child(1) {
    margin-right: 0;
  }
`;

const typeStyles = {
  primary: {
    background: BACKGROUND_PRIMARY,
    activeColor: ACTIVE_PRIMARY,
    inactiveColor: INACTIVE_PRIMARY,
  },
  secondary: {
    background: BACKGROUND_SECONDARY,
    activeColor: INACTIVE_PRIMARY,
    inactiveColor: INACTIVE_PRIMARY,
  },
};

const findTextColor = (props) =>
  props?.active
    ? typeStyles[props.tabListType].activeColor(props)
    : typeStyles[props.tabListType].inactiveColor(props);
const findBackgroundColor = (props) =>
  props?.active
    ? typeStyles[props.tabListType].background(props)
    : 'transparent';

const buttonStyle = (props) => `
  background-color: ${findBackgroundColor(props)};
  color: ${findTextColor(props)};

  :hover {
    background-color: ${findBackgroundColor(props)};
    color: ${findTextColor(props)};
  }
`;

const TabList = ({ options, onClick, customContainerStyle, type }) => (
  <Container
    customContainerStyle={customContainerStyle}
    css={(props) => props?.customContainerStyle?.(props)}
  >
    {options.map((option) => (
      <Button
        type="noStyle"
        onClick={() => onClick(option)}
        active={option?.active}
        customStyle={buttonStyle}
        key={option.value}
        disabled={option?.disabled}
        tabListType={type}
        dataTestId={`tab-list-option-${slugify(option.label, { lower: true })}`}
      >
        {option.label}
      </Button>
    ))}
  </Container>
);

TabList.propTypes = {
  type: PropTypes.string,
  options: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  customContainerStyle: PropTypes.func,
};

TabList.defaultProps = {
  type: 'primary',
  customContainerStyle: null,
};

export default TabList;
