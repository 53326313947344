import styled from 'styled-components';
import THEMES from '../../styles/themes/app';
import COLORS from '../../styles/colors';

type StyleProps = {
  customStyle: string;
};

export const SubheaderWrapper = styled.div<StyleProps>`
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  box-shadow: 0 4px 4px -2px ${THEMES.BORDER_COLOR};
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 16px 16px 16px;
  flex-grow: 0;
  flex-shrink: 0;
  z-index: 1;
  ${(props) => props.customStyle}
`;

export const ChildrenWrapper = styled.div<StyleProps>`
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
  padding: 0 16px 24px 16px;
  flex-grow: 1;
  width: calc(100% - 32px);
  ${(props) => props.customStyle}
`;

export const getOverlayStyles = (disableOverlay: boolean) => ({
  backgroundColor: disableOverlay
    ? COLORS.PK.TRANSPARENT
    : 'rgba(0, 0, 0, 0.75)',
  zIndex: '11',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
});

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;
