import styled from 'styled-components';
import theme from 'styled-theming';
import COLORS from '../../styles/colors';
import FONTSIZE_THEMES from '../../styles/themes/fontSize/fontSize';

export const TEXT_COLOR = theme('mode', {
  classic: COLORS.PK_CLASSIC.LINK_BLUE,
  light: COLORS.PK.BLUE,
  dark: COLORS.PK.BLUE,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_PRIMARY,
});

const Anchor = styled.a`
  font-family: 'Barlow', sans-serif;
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: ${FONTSIZE_THEMES.TEXT};
  color: ${TEXT_COLOR} !important;

  :hover,
  :visited,
  :active,
  :link {
    color: ${TEXT_COLOR} !important;
  }

  :hover,
  :visited,
  :active {
    text-decoration: underline;
  }
  ${(props) => props.customStyle}
`;

export default Anchor;
