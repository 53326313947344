// import React from 'react';
// import styled from 'styled-components';
// import { Link } from 'react-router-dom';
// import { SEARCH_CUSTOMERS_QUERY } from 'client-lib';
// import { closeModal } from '../../actions/editCustomer';
// import THEMES from '../../styles/themes/app';

// const LinkURL = styled.span`
//   color: ${THEMES.THEME_RED};
//   text-decoration: underline;
// `;

// NOTE: seems that we now only use these three items in the app, leaving the rest commented out for ease of access in case I'm wrong and make a breaking change.

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

export const normalizePhone = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');

  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(
    6,
    onlyNums.length
  )}`;
};

// export const blurPhone = event => normalizePhone(event.target.value);

// export const asyncValidation = (values, dispatch, props) => {
//   const variables = {
//     typeFilter: 'CUSTOMER_CONTACT',
//     query: values.phoneNumber.replace(/[^\d]/g, ''),
//     first: 1,
//   };

//   return props.client
//     .query({
//       query: SEARCH_CUSTOMERS_QUERY,
//       fetchPolicy: 'network-only',
//       variables,
//     })
//     .then((res) => {
//       if (values.phoneNumber === '') {
//         return;
//       }

//       const currentState = props.editCustomer;
//       const matches = res.data.searchCustomers.edges;

//       if (currentState.contact === null && matches.length > 0) {
//         const contact = matches[0].node;
//         throw {
//           phoneNumber: (
//             <Link
//               to={`/contacts/all/${contact.customerId}`}
//               onClick={() => props.dispatch(closeModal(currentState.activeModal))
//               }
//             >
//               <LinkURL>
//                 This number is already in your contacts list. Click here to go
//                 to that contact.
//               </LinkURL>
//             </Link>
//           ),
//         };
//       } else if (
//         currentState.contact !== null
//         && matches.filter(m => m.node.customerId !== currentState.contact.id)
//           .length > 0
//       ) {
//         const contact = matches[0].node;
//         throw {
//           phoneNumber: (
//             <Link
//               to={`/contacts/all/${contact.customerId}`}
//               onClick={() => props.dispatch(closeModal(currentState.activeModal))
//               }
//             >
//               <LinkURL>
//                 This number is already in your contacts list. Click here to go
//                 to that contact.
//               </LinkURL>
//             </Link>
//           ),
//         };
//       }
//     });
// };

// export const validation = (values) => {
//   const errors = {};
//   const {
//     firstName,
//     accountPhoneNumber,
//     accountEmailAddress,
//     emailAddress,
//     lastName,
//     creatingAccount,
//     accountId,
//     phoneNumber,
//     sendWelcomeMessage,
//     accountName,
//   } = values;

//   // === First name ===
//   if (firstName) {
//     if (firstName !== '') {
//       if (firstName.length < 2) {
//         errors.firstName = 'Name must be more than one character';
//       }
//     }
//   }

//   // === Account phone number ===
//   if (accountPhoneNumber) {
//     if (accountPhoneNumber !== '') {
//       if (!phoneRegex.test(accountPhoneNumber)) {
//         errors.accountPhoneNumber = 'Invalid phone number';
//       }
//     }
//   }

//   // === Account email address ===
//   if (accountEmailAddress) {
//     if (accountEmailAddress !== '') {
//       if (!emailRegex.test(accountEmailAddress)) {
//         errors.accountEmailAddress = 'Invalid email address';
//       }
//     }
//   }

//   // === Email validation ===
//   if (emailAddress) {
//     // Allow blank submission since email address isn't required
//     if (emailAddress !== '') {
//       if (!emailRegex.test(emailAddress)) {
//         errors.emailAddress = 'Invalid email address';
//       }
//     }
//   }

//   // === Phone number validation ===
//   if (phoneNumber) {
//     // Allow blank submission since phone number isn't required
//     if (phoneNumber !== '') {
//       if (!phoneRegex.test(phoneNumber)) {
//         errors.phoneNumber = 'Invalid phone number';
//       }
//     }
//   }

//   if (sendWelcomeMessage) {
//     if (sendWelcomeMessage === true && phoneNumber === '') {
//       errors.sendWelcomeMessage = 'Must include a phone number';
//     }
//   }

//   if (
//     accountName
//     && accountPhoneNumber
//     && accountEmailAddress
//     && firstName
//     && lastName
//     && emailAddress
//     && phoneNumber
//   ) {
//     if (
//       (accountName === ''
//         || (accountName.length > 0
//           && creatingAccount === false
//           && (accountId === null
//             || accountId === undefined
//             || accountId === '')))
//       && accountPhoneNumber === ''
//       && accountEmailAddress === ''
//       && firstName === ''
//       && lastName === ''
//       && emailAddress === ''
//       && phoneNumber === ''
//     ) {
//       errors.accountName = 'Must enter a value';
//     }
//   }

//   return errors;
// };
