import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import React, { useState, useRef, useEffect, useCallback } from 'react';

import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection';
import {
  $createParagraphNode,
  $getNodeByKey,
  $getSelection,
  $isNodeSelection,
  CLICK_COMMAND,
  COMMAND_PRIORITY_LOW,
  DRAGEND_COMMAND,
  KEY_BACKSPACE_COMMAND,
  KEY_DELETE_COMMAND,
  KEY_ENTER_COMMAND,
  SELECTION_CHANGE_COMMAND,
  type BaseSelection,
  type LexicalEditor,
  type NodeKey,
} from 'lexical';
import { $isImageNode } from './ImageNode';
import {
  DEFAULT_IMAGE_WIDTH,
  mergeRegister,
  MIN_LEXICAL_IMAGE_WIDTH,
} from '../utils/constants';
import { IconButton } from '../..';
import type { Attachment } from '../../../utils/helpers/types';

interface ImageComponentProps {
  src: string;
  altText: string;
  initialWidth?: number | 'inherit';
  initialHeight: number | 'inherit';
  nodeKey: NodeKey;
  attachment: Attachment;
}

const ImageComponent: React.FC<ImageComponentProps> = ({
  src,
  altText,
  initialHeight,
  nodeKey,
  initialWidth = DEFAULT_IMAGE_WIDTH,
  attachment,
}) => {
  const imageRef = useRef<null | HTMLImageElement>(null);
  const [isSelected, setSelected, clearSelection] =
    useLexicalNodeSelection(nodeKey);
  const [isResizing, setIsResizing] = useState<boolean>(false);
  const [editor] = useLexicalComposerContext();
  const [selection, setSelection] = useState<BaseSelection | null>(null);
  const activeEditorRef = useRef<LexicalEditor | null>(null);
  const [width, setWidth] = useState<number>(
    typeof initialWidth === 'number' ? initialWidth : DEFAULT_IMAGE_WIDTH
  ); // Initial width

  const draggable = isSelected && $isNodeSelection(selection) && !isResizing;
  const isFocused = isSelected || isResizing;

  const handleResize = (e: MouseEvent) => {
    setIsResizing(true);
    if (imageRef.current) {
      const newWidth =
        e.clientX - imageRef.current.getBoundingClientRect().left;
      setWidth(
        newWidth > MIN_LEXICAL_IMAGE_WIDTH ? newWidth : MIN_LEXICAL_IMAGE_WIDTH
      ); // minimum width of 50px
      editor.update(() => {
        const node = $getNodeByKey(nodeKey);
        if ($isImageNode(node)) {
          node.setWidthAndHeight(newWidth, initialHeight);
        }
      });
    }
    setIsResizing(false);
  };

  const $onDelete = useCallback(
    (payload: KeyboardEvent) => {
      const deleteSelection = $getSelection();
      if (isSelected && $isNodeSelection(deleteSelection)) {
        const event: KeyboardEvent = payload;
        event.preventDefault();
        editor.update(() => {
          deleteSelection.getNodes().forEach((node) => {
            if ($isImageNode(node)) {
              node.remove();
            }
          });
        });
      }
      return false;
    },
    [editor, isSelected]
  );

  const onClick = useCallback(
    (payload: MouseEvent) => {
      const event = payload;

      if (isResizing) {
        return true;
      }
      if (event.target === imageRef.current) {
        if (event.shiftKey) {
          setSelected(!isSelected);
        } else {
          clearSelection();
          setSelected(true);
        }
        return true;
      }

      return false;
    },
    [isResizing, isSelected, setSelected, clearSelection]
  );

  useEffect(() => {
    let isMounted = true;
    const unregister = mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        if (isMounted) {
          setSelection(editorState.read(() => $getSelection()));
        }
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_, activeEditor) => {
          activeEditorRef.current = activeEditor;
          return false;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand<MouseEvent>(
        CLICK_COMMAND,
        onClick,
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand<MouseEvent>(
        KEY_ENTER_COMMAND,
        () => {
          if (isFocused) {
            editor.update(() => {
              const node = $getNodeByKey(nodeKey);
              if ($isImageNode(node)) {
                const paragraphNode = $createParagraphNode();
                node.insertAfter(paragraphNode);
                paragraphNode.select();
              }
            });
            return true;
          }
          return false;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand<MouseEvent>(
        DRAGEND_COMMAND,
        (event) => {
          if (event.target === imageRef.current) {
            editor.update(() => {
              const node = $getNodeByKey(nodeKey);
              if ($isImageNode(node)) {
                // This works too well
                node.remove();
              }
            });
            return true;
          }
          return false;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        KEY_DELETE_COMMAND,
        $onDelete,
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        KEY_BACKSPACE_COMMAND,
        $onDelete,
        COMMAND_PRIORITY_LOW
      )
    );

    return () => {
      isMounted = false;
      unregister();
    };
  }, [
    clearSelection,
    editor,
    isResizing,
    isSelected,
    nodeKey,
    $onDelete,
    onClick,
    setSelected,
  ]);

  return (
    <div
      style={{
        display: 'inline-block',
        position: 'relative',
        width: `${width}px`,
      }}
      draggable="true"
    >
      <img
        ref={imageRef}
        src={src}
        height={initialHeight}
        width={width}
        style={{ cursor: draggable ? 'move' : 'default' }}
        alt={altText}
        key={nodeKey}
        data-attachment-type={attachment.type}
        data-attachment-size={attachment.size}
        data-attachment-data={attachment.data}
        data-attachment-id={attachment.id}
        data-attachment-original-filename={attachment.originalFilename}
      />
      {/* Resize Handle */}
      <IconButton
        dataTestId="resize-handle"
        style={{
          width: '15px',
          height: '15px',
          backgroundColor: 'black',
          color: 'white',
          position: 'absolute',
          right: 0,
          bottom: 4,
          cursor: 'nwse-resize',
          display: isFocused ? 'initial' : 'none',
        }}
        size="xs"
        onMouseDown={(e: React.MouseEvent) => {
          e.preventDefault();
          e.stopPropagation(); // Stop the event from propagating to the draggable div
          window.addEventListener('mousemove', handleResize);
          window.addEventListener('mouseup', () => {
            window.removeEventListener('mousemove', handleResize);
          });
        }}
      >
        <i className="ri-arrow-right-down-line" />
      </IconButton>
    </div>
  );
};

export default ImageComponent;
