import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Select from './Select';
import { Heading3, Text } from '../Text/Text';
import THEMES from '../../styles/themes/app';
import InputLabel from '../inputCommonElements/InputLabel';

const hourOptions = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
  { label: '11', value: 11 },
  { label: '12', value: 0 },
];

const twentyFourHourOptions = [
  { label: '0', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
  { label: '11', value: 11 },
  { label: '12', value: 12 },
  { label: '13', value: 13 },
  { label: '14', value: 14 },
  { label: '15', value: 15 },
  { label: '16', value: 16 },
  { label: '17', value: 17 },
  { label: '18', value: 18 },
  { label: '19', value: 19 },
  { label: '20', value: 20 },
  { label: '21', value: 21 },
  { label: '22', value: 22 },
  { label: '23', value: 23 },
];

const minuteOptions = [
  { label: '00', value: 0 },
  { label: '15', value: 15 },
  { label: '30', value: 30 },
  { label: '45', value: 45 },
];

const meridianOptions = [
  { label: 'AM', value: 'am' },
  { label: 'PM', value: 'pm' },
];
const Wrapper = styled.div`
  width: 80%;
  height: 100px;
`;

const LabelWrapper = styled.div`
  margin-bottom: 4px;
`;

const SelectTimeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
`;
const SelectWrapper = styled.div`
  margin-right: 8px;
  width: 100%;
`;

const StyledSelect = styled(Select)`
  position: relative;
  & .Select__menu {
    position: absolute;
  }
`;

const ErrorWrapper = styled.div`
  margin-top: -22px;
`;

const SelectTime = ({
  label,
  onChange,
  error,
  hourValue,
  minValue,
  isMilitaryTime,
}) => {
  const [meridianVal, setMeridianVal] = useState(hourValue >= 12 ? 'pm' : 'am');
  const timezone = moment.tz.zone(moment.tz.guess()).abbr(moment());

  useEffect(() => {
    if (!isMilitaryTime) {
      if (meridianVal === 'pm' && hourValue <= 11) {
        onChange(hourValue + 12, 'hour');
      } else if (meridianVal === 'am' && hourValue > 11) {
        onChange(hourValue - 12, 'hour');
      }
    }
  }, [meridianVal]);

  const handleHourUpdate = ({ value }) => {
    if (isMilitaryTime) {
      onChange(value, 'hour');
    } else {
      const timeOffset = meridianVal === 'am' ? 0 : 12;
      onChange(value + timeOffset, 'hour');
    }
  };

  return (
    <Wrapper id="select-container">
      {label && (
        <LabelWrapper>
          <InputLabel>{label}</InputLabel>
        </LabelWrapper>
      )}
      <SelectTimeContainer>
        <SelectWrapper>
          <StyledSelect
            options={isMilitaryTime ? twentyFourHourOptions : hourOptions}
            value={
              !isMilitaryTime
                ? hourValue >= 12
                  ? hourValue - 12
                  : hourValue
                : hourValue
            }
            onChange={(value) => handleHourUpdate(value)}
          />
        </SelectWrapper>
        <SelectWrapper>
          <StyledSelect
            options={minuteOptions}
            value={minValue}
            onChange={(value) => onChange(value.value, 'minute')}
          />
        </SelectWrapper>
        {!isMilitaryTime ? (
          <SelectWrapper>
            <StyledSelect
              options={meridianOptions}
              value={meridianVal}
              onChange={(value) => setMeridianVal(value.value)}
            />
          </SelectWrapper>
        ) : null}
        <Heading3 customStyle={() => 'margin-bottom: 30px;'}>
          {timezone}
        </Heading3>
      </SelectTimeContainer>
      {error && (
        <ErrorWrapper>
          <Text customStyle={(props) => `color: ${THEMES.THEME_RED(props)}`}>
            {error}
          </Text>
        </ErrorWrapper>
      )}
    </Wrapper>
  );
};

SelectTime.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  hourValue: PropTypes.number.isRequired,
  minValue: PropTypes.number.isRequired,
  isMilitaryTime: PropTypes.bool,
};

SelectTime.defaultProps = {
  error: '',
  isMilitaryTime: false,
  label: '',
};

export default SelectTime;
