import React from 'react';
import { formatDataTestName } from 'client-lib/src/lib/utils/helpers';
import { InlineTextButton, Text } from '../../../elements';
import {
  BarDataContainer,
  CountWrapper,
  NameText,
  Wrapper,
  SecondaryTextContainer,
  StackedBarContainer,
  StackedBarBackground,
  StackedBarValue,
} from './styles';
import MultiColoredBar from './MultiColoredBar';

interface HorizontalBarProps {
  name: string;
  count: number;
  total: number;
  barColor: string;
  barColors?: string[] | undefined;
  secondaryText?: string;
  overridePercentage?: number | undefined;
  nameOnClick: null | (() => void);
  formatMultiColorDataCallback?: (otherProps: unknown) => object[] | undefined;
  otherProps?: unknown;
}

const HorizontalBar = ({
  name,
  count,
  total,
  barColor,
  barColors,
  secondaryText = '',
  overridePercentage,
  nameOnClick = null,
  formatMultiColorDataCallback,
  ...otherProps
}: HorizontalBarProps) => {
  let fillPercentage = 0;
  if (count !== null && count > 0) {
    fillPercentage = (count / total) * 100;
  }
  const data = formatMultiColorDataCallback
    ? (formatMultiColorDataCallback(otherProps) as {
        [key: string]: string | number;
      }[])
    : [];

  return (
    <BarDataContainer>
      {nameOnClick ? (
        <InlineTextButton
          dataTestId={`horizontal-bar-${formatDataTestName(name)}`}
          onClick={nameOnClick}
        >
          {name}
        </InlineTextButton>
      ) : (
        <NameText dataTestId={`horizontal-bar-${formatDataTestName(name)}`}>
          {name}
        </NameText>
      )}

      {secondaryText ? (
        <SecondaryTextContainer>
          <Text
            dataTestId={`horizontal-bar-secondary-${formatDataTestName(name)}`}
          >
            {secondaryText}
          </Text>
        </SecondaryTextContainer>
      ) : null}
      <Wrapper>
        <StackedBarContainer>
          <StackedBarBackground>
            {formatMultiColorDataCallback ? (
              <MultiColoredBar
                name={name}
                total={total}
                data={data}
                barColors={barColors}
              />
            ) : (
              <StackedBarValue
                fillpercentage={overridePercentage ?? fillPercentage}
                barColor={barColor}
                data-testid={`horizontal-bar-stacked-bar-${formatDataTestName(
                  name
                )}`}
              />
            )}
          </StackedBarBackground>
        </StackedBarContainer>
        <CountWrapper>
          <Text dataTestId={`horizontal-bar-count-${formatDataTestName(name)}`}>
            {count}
          </Text>
        </CountWrapper>
      </Wrapper>
    </BarDataContainer>
  );
};

export default HorizontalBar;
