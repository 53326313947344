import React from 'react';
import PropTypes from 'prop-types';
import { MeasuredBadge } from './shared/MeasuredBadge';
import { TemplateGroupsWrapper } from './shared/styles';
import { CutoffBadge } from './shared/CutoffBadge';
import { useWidthLimiter } from './shared/useWidthLimiter';

/**
 * MeasurableLabelContainer is used for displaying a contact's contact labels in an inline badge format,
 * with a final +{{number}} badge to indicate how many badges were hidden for not fitting inside the container
 * @param {string} dataTestIds
 * @param {array} labels - contact labels
 * @param {number} maxWidth - width of container, discovered by using useMeasure hook
 * @returns React component
 */
const MeasurableLabelContainer = ({ dataTestIds, labels, maxWidth }) => {
  const { badgeCutoffNum, calculatingWidth, widthCallback } = useWidthLimiter(
    maxWidth,
    labels
  );

  let sortedLabels = [...labels];
  sortedLabels = sortedLabels
    .sort((a, b) => a.color - b.color)
    .sort((a, b) => {
      if (a.color !== b.color) return 0;
      const aLower = a.name.toLowerCase();
      const bLower = b.name.toLowerCase();
      if (aLower < bLower) {
        return -1;
      }
      if (aLower > bLower) {
        return 1;
      }
      return 0;
    });

  return (
    <TemplateGroupsWrapper
      style={{ visibility: calculatingWidth ? 'hidden' : 'initial' }}
    >
      {sortedLabels.map((label, i) => (
        <MeasuredBadge
          key={i}
          dataTestId={dataTestIds}
          label={label.name}
          color={label.color || 'grey'}
          widthCallback={(badgeWidth) => widthCallback(badgeWidth, i)}
        />
      ))}
      {!!badgeCutoffNum && (
        <CutoffBadge
          displayNumber={(sortedLabels?.length ?? 0) - badgeCutoffNum}
        />
      )}
    </TemplateGroupsWrapper>
  );
};

MeasurableLabelContainer.propTypes = {
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  dataTestIds: PropTypes.string,
  maxWidth: PropTypes.number.isRequired,
};

MeasurableLabelContainer.defaultProps = {
  dataTestIds: 'ts-contact-label-badge',
  labels: [],
};

export default MeasurableLabelContainer;
