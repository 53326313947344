import React from 'react';
import PropTypes from 'prop-types';
import { slugify } from 'client-lib/src/lib/utils/helpers';
import styled from 'styled-components';
import i18n from 'i18n-js';
import { EmphasisText, EntityCard } from '../../..';
import THEMES from '../../../../styles/themes/library/textInput';
import { entityCardSuggestionCustomStyles } from '../UniversalSuggestion';

const NewCompanySuggestion = styled.div`
  background-color: ${(props) =>
    props.isHighlighted
      ? THEMES.OPTION_BACKGROUND_HOVER(props)
      : THEMES.OPTION_BACKGROUND(props)};
  padding: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const CompanySuggestion = ({ suggestion, isHighlighted }) => {
  if (suggestion.addCompanySuggestion) {
    return (
      <NewCompanySuggestion
        isHighlighted={isHighlighted}
        data-testid={`infinite-company-suggestion-new-${slugify(
          suggestion.name
        )}`}
      >
        <EmphasisText contrast={isHighlighted ? 'high' : 'medColor'}>
          {i18n.t('slideouts-CompanyDropDown-createNew', {
            companyName: suggestion.name,
          })}
        </EmphasisText>
      </NewCompanySuggestion>
    );
  }

  return (
    <EntityCard
      avatarChildren={suggestion?.name}
      maintext={suggestion?.name}
      avatarProps={{
        size: 'md',
        type: 'external',
        icon: 'company',
      }}
      backgroundColor={
        isHighlighted
          ? THEMES.OPTION_BACKGROUND_HOVER
          : THEMES.OPTION_BACKGROUND
      }
      maintextColor={
        isHighlighted ? THEMES.OPTION_TEXT_HOVER : THEMES.OPTION_TEXT
      }
      customContainerStyle={entityCardSuggestionCustomStyles}
      dataTestId={`infinite-company-suggestion-${slugify(suggestion.name, {
        lower: true,
      })}`}
    />
  );
};

CompanySuggestion.propTypes = {
  suggestion: PropTypes.object.isRequired,
  isHighlighted: PropTypes.bool,
};

CompanySuggestion.defaultProps = {
  isHighlighted: false,
};

export default CompanySuggestion;
