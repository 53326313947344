import { useQuery } from "@apollo/client";
import UNCLAIMED_THREAD_COUNT_FOR_USER_TOTAL from "../../../graphql/query/UnclaimedThreadCountForUserTotal.graphql";
import UNCLAIMED_THREAD_COUNT_FOR_USER_BY_GROUP from "../../../graphql/query/UnclaimedThreadCountForUserByGroup.graphql";
import { DEFAULT_DASHBOARD_UNCLAIMED_BY_TIME_COUNT } from "../../../utils/helpers";

const TOP_N = DEFAULT_DASHBOARD_UNCLAIMED_BY_TIME_COUNT;

function useUnclaimedThreadsByTimeWidget({ client, groupId }) {
  const {
    data: unclaimedThreadCountTotalData,
    loading: unclaimedThreadCountTotalLoading,
    refetch: refetchUnclaimedThreadCountTotal,
  } = useQuery(UNCLAIMED_THREAD_COUNT_FOR_USER_TOTAL, {
    client,
    variables: { groupIds: [groupId] },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: unclaimedThreadCountByGroupData,
    loading: unclaimedThreadCountByGroupLoading,
    refetch: refetchUnclaimedThreadCountByGroup,
  } = useQuery(UNCLAIMED_THREAD_COUNT_FOR_USER_BY_GROUP, {
    client,
    variables: { topN: TOP_N, groupIds: [groupId] },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const unclaimedThreadCountTotal =
    unclaimedThreadCountTotalData?.unclaimedThreadCountForUserTotal?.count;
  const unclaimedThreadCountByGroup =
    unclaimedThreadCountByGroupData?.unclaimedThreadCountForUserByGroup;
  const widgetsLoading =
    unclaimedThreadCountTotalLoading || unclaimedThreadCountByGroupLoading;

  const refetchUnclaimedThreadsByTime = () => {
    refetchUnclaimedThreadCountTotal();
    refetchUnclaimedThreadCountByGroup();
  };

  return {
    unclaimedThreadCountTotal,
    unclaimedThreadCountByGroup,
    widgetsLoading,
    refetchUnclaimedThreadsByTime,
  };
}

export default useUnclaimedThreadsByTimeWidget;
