import React from 'react';
import { AsyncPaginate } from 'react-select-async-paginate'; // eslint-disable-line import/no-named-default
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InputLabel from '../inputCommonElements/InputLabel';
import InputError from '../inputCommonElements/InputError';
import { baseSelectStyles } from './AsyncSelect/AsyncSelect';
import Option from './AsyncSelect/Option.tsx';
import DropdownIndicator from './AsyncSelect/DropdownIndicator.tsx';
import Avatar from '../Avatar/Avatar';
import { EmphasisText, MetaText } from '..';

const Container = styled.div`
  width: 100%;
`;

const OptionWrapper = styled.div`
  display: flex;
`;

const ChildrenWrapper = styled.div`
  flex: 2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const OptionColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ChildrenColumnWrapper = styled.div`
  flex-direction: column;
  text-overflow: ellipsis;
  justify-content: center;
  padding-left: 10px;
`;

const RightSideOptionWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 3;
`;

const StyledSelect = styled(AsyncPaginate)`
  ${baseSelectStyles}
`;

const AsyncSelectPaginate = React.forwardRef(
  (
    {
      styledSelectKey,
      label,
      loadOptions,
      onChange,
      onClear,
      placeholder,
      error,
      helperText,
      value,
      disabled,
      customContainerStyle,
      customLabelStyle,
      customInputStyle,
      customOptionStyle,
      dataTestId,
      hideBottomSpace,
      isCheckable,
      isMulti,
      isClearable,
      ...otherProps
    },
    ref
  ) => {
    const createDropdownIndicator = (props) => (
      <DropdownIndicator dataTestId={dataTestId} {...props} />
    );

    // If you want to insert right side content
    // pass a key value pair with "rightSideOptionContent"
    // into the options object for the "loadOptions" prop
    // see TemplateAsyncSelect for an example.
    const createOption = (props) => {
      if (props?.data?.avatarContent) {
        const role = props?.data?.role ? props?.data?.role.toLowerCase() : null;
        return (
          <Option
            customOptionStyle={customOptionStyle}
            dataTestId={dataTestId}
            applyLabelColor={props?.data?.applyLabelColor}
            {...props}
          >
            <OptionColumnWrapper>
              <Avatar size="md" avatarUrl={props?.data?.avatarContent} />
              <ChildrenColumnWrapper>
                <EmphasisText>{props?.data?.label}</EmphasisText>
                {role ?? <MetaText>{role}</MetaText>}
              </ChildrenColumnWrapper>
            </OptionColumnWrapper>
          </Option>
        );
      }
      if (props?.data?.rightSideOptionContent)
        return (
          <Option
            customOptionStyle={customOptionStyle}
            dataTestId={dataTestId}
            applyLabelColor={props?.data?.applyLabelColor}
            {...props}
          >
            <OptionWrapper>
              <ChildrenWrapper>{props.children}</ChildrenWrapper>
              <RightSideOptionWrapper>
                {props?.data?.rightSideOptionContent}
              </RightSideOptionWrapper>
            </OptionWrapper>
          </Option>
        );
      return (
        <Option
          customOptionStyle={customOptionStyle}
          dataTestId={dataTestId}
          applyLabelColor={props?.data?.applyLabelColor}
          {...props}
        />
      );
    };

    const handleChange = (value, actionType) => {
      if (actionType?.action === 'clear') {
        onClear(value, actionType);
      } else {
        onChange(value, actionType);
      }
    };

    return (
      <Container
        error={error}
        disabled={disabled}
        customContainerStyle={customContainerStyle}
        css={(props) => props?.customContainerStyle?.(props)}
      >
        {label ? (
          <InputLabel
            error={error}
            disabled={disabled}
            customLabelStyle={customLabelStyle}
            css={(props) => props?.customLabelStyle?.(props)}
          >
            {label}
          </InputLabel>
        ) : null}
        <StyledSelect
          key={styledSelectKey}
          loadOptions={loadOptions}
          value={value}
          onChange={handleChange}
          error={error}
          disabled={disabled}
          isDisabled={disabled}
          placeholder={placeholder}
          isClearable={isClearable}
          menuPlacement="auto"
          classNamePrefix="Select"
          components={{
            DropdownIndicator: createDropdownIndicator,
            Option: createOption,
          }}
          customInputStyle={customInputStyle}
          customOptionStyle={customOptionStyle}
          isMulti={isMulti}
          isCheckable={isCheckable}
          defaultOptions
          cacheOptions
          classNames={{
            multiValue: (props) =>
              props.data.color
                ? `color-label bg-color-${props.data.color}`
                : '',
          }}
          {...otherProps}
          selectRef={ref}
        />
        {!hideBottomSpace && (
          <InputError error={error} helperText={helperText} />
        )}
      </Container>
    );
  }
);

AsyncSelectPaginate.propTypes = {
  loadOptions: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  styledSelectKey: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  helperText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  disabled: PropTypes.bool,
  customContainerStyle: PropTypes.func,
  customLabelStyle: PropTypes.func,
  customInputStyle: PropTypes.func,
  customOptionStyle: PropTypes.func,
  dataTestId: PropTypes.string,
  hideBottomSpace: PropTypes.bool,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isCheckable: PropTypes.bool,
  data: PropTypes.object,
  children: PropTypes.string,
};

AsyncSelectPaginate.defaultProps = {
  onClear: () => {},
  label: null,
  placeholder: null,
  error: null,
  helperText: '',
  isCheckable: false,
  styledSelectKey: '',
  value: null,
  disabled: false,
  customContainerStyle: null,
  customLabelStyle: null,
  customInputStyle: null,
  customOptionStyle: null,
  dataTestId: 'asyncselect',
  hideBottomSpace: false,
  isClearable: false,
  isMulti: false,
  data: null,
  children: '',
};

AsyncSelectPaginate.displayName = 'AsyncSelectPaginate';

export default AsyncSelectPaginate;
