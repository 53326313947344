import { useEffect } from "react";
import { startOpenThreadForUserSubscription } from "../setupSubscriptions";
import usePrevious from "../../../utils/hooks/usePrevious";

const useStartOpenThreadForUserSubscription = (userId, store, client) => {
  const socketConnectionTimestamp =
    store?.getState?.()?.session?.socketConnectionTimestamp;

  const prevUserId = usePrevious(userId);
  const prevSocConTs = usePrevious(socketConnectionTimestamp);

  useEffect(() => {
    // We want to start/restart the subscription if
    // a) there is a current userId (logged in)
    // b) AND socketConnectionTimestamp is available (set when other websockets are connected, indicates when a disconnect/reconnect happens)
    // c) AND
    //    i) userId !== prevUserId (allowing for socket update if the user Id changes)
    //    ii) OR socketConnectionTimestamp !== prevSocConTs (allowing for socket update if there was a disconnection/reconnection)
    if (
      userId &&
      socketConnectionTimestamp &&
      (userId !== prevUserId || socketConnectionTimestamp !== prevSocConTs)
    ) {
      startOpenThreadForUserSubscription(store, client);
    }
  }, [userId, prevUserId, socketConnectionTimestamp, prevSocConTs]);
};

export default useStartOpenThreadForUserSubscription;
