import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import COLORS from '../../styles/colors';
import THEMES from '../../styles/themes/app';
import { Text } from '../../elements';

const GraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowContainer = styled.div`
  margin: 16px 0;
  width: 100%;
`;

const StackedBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 8px;
  max-width: 500px;
  flex: auto;
`;

const StackedBarBackground = styled.div`
  height: 8px;
  background-color: ${THEMES.BORDER_COLOR};
  border-radius: 4px;
  width: 100%;
`;

const StackedBarValue = styled.div`
  border-radius: 4px;
  width: ${(props) => props.fillpercentage}%;
  max-width: 100%;
  height: 100%;
  background-color: ${(props) => props.barColor};
`;

const BarDataContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const CountWrapper = styled.div`
  min-width: 24px; // attempt to stop the graphs from jumping
`;

const NameText = styled(Text)`
  width: 150px;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Wrapper = styled.div`
  display: flex;
  flex: auto;
`;
/**
 * Similar to the HorizontalBar in HorizontalBarWidget
 * but the styles for the widgets need to be different enough
 * that it was easier to make a new version for flyouts
 * than try to force 1 to work for both instances
 */
export const FlyoutHorizontalBar = ({ name, count, total, barColor }) => {
  let fillPercentage = 0;
  if (count !== null && count > 0) {
    fillPercentage = (count / total) * 100;
  }

  return (
    <BarDataContainer>
      <NameText>{name}</NameText>
      <Wrapper>
        <StackedBarContainer>
          <StackedBarBackground>
            <StackedBarValue
              fillpercentage={fillPercentage}
              barColor={barColor}
            />
          </StackedBarBackground>
        </StackedBarContainer>
        <CountWrapper>
          <Text>{count}</Text>
        </CountWrapper>
      </Wrapper>
    </BarDataContainer>
  );
};

FlyoutHorizontalBar.propTypes = {
  name: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  barColor: PropTypes.string.isRequired,
};

const OpenFlyoutUsers = ({ users, total }) => {
  return (
    <GraphWrapper>
      {users?.counts?.map((user, i) => (
        <RowContainer key={i}>
          <FlyoutHorizontalBar
            total={total}
            count={user.numOpenThreads}
            name={user.userName}
            barColor={COLORS.PK.GOLD_100}
          />
        </RowContainer>
      ))}
    </GraphWrapper>
  );
};

OpenFlyoutUsers.propTypes = {
  users: PropTypes.object,
  total: PropTypes.number,
};

OpenFlyoutUsers.defaultProps = {
  users: null,
  total: undefined,
};

export default OpenFlyoutUsers;
