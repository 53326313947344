import { useMutation } from "@apollo/client";
import UPDATE_DASHBOARD_SELECTED_GROUPS_MUTATION from "../../../graphql/mutation/UpdateDashboardSelectedGroupsMutation.graphql";

const useUpdateSelectedGroups = ({ client }) => {
  const [updateDashboardSelectedGroups] = useMutation(
    UPDATE_DASHBOARD_SELECTED_GROUPS_MUTATION,
    {
      client,
    }
  );

  const updateDashboardSelectedGroupsHandler = async ({
    groupIds,
    onSuccess,
    onError,
  }) => {
    const response = await updateDashboardSelectedGroups({
      variables: {
        groupIds,
      },
    }).catch((err) => onError(err));

    if (response?.data?.updateDashboardSelectedGroups?.errors) {
      onError(response.data.updateDashboardSelectedGroups.errors);
    } else {
      onSuccess(response?.data?.updateDashboardSelectedGroups?.groupIds);
    }
  };

  return {
    updateDashboardSelectedGroupsHandler,
  };
};

export default useUpdateSelectedGroups;
