import {
  DecoratorNode,
  type DOMConversionMap,
  type DOMExportOutput,
  type LexicalNode,
  type NodeKey,
  type SerializedLexicalNode,
  type Spread,
} from 'lexical';
import React from 'react';
import ImageComponent from './ImageComponent';
import { $convertImageElement, $createImageNode } from '../utils/constants';
import type { Attachment } from '../../../utils/helpers/types';

export type OriginalFile = {
  lastModified: number;
  lastModifiedDate: string;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
};

export type SerializedImageNode = Spread<
  {
    src: string;
    name: string;
    size: number;
    altText: string;
    height?: number;
    width?: number;
    attachment: Attachment;
  },
  SerializedLexicalNode
>;

// The structure of the ImageNode comes from Lexical's DecoratorNode see (https://lexical.dev/docs/concepts/nodes#extending-decoratornode)
export class ImageNode extends DecoratorNode<JSX.Element> {
  __src: string;

  __altText: string;

  __width: 'inherit' | number;

  __height: 'inherit' | number;

  __attachment: Attachment;

  __has_been_uploaded: boolean;

  constructor({
    src,
    altText,
    width,
    height,
    attachment,
    has_been_uploaded,
    key,
  }: {
    src: string;
    altText: string;
    width?: 'inherit' | number;
    height?: 'inherit' | number;
    attachment: Attachment;
    has_been_uploaded?: boolean;
    key?: NodeKey;
  }) {
    super(key);
    this.__altText = altText;
    this.__src = src;
    this.__width = width || 'inherit';
    this.__height = height || 'inherit';
    this.__attachment = attachment;
    this.__has_been_uploaded = has_been_uploaded || false;
  }

  static override getType(): string {
    return 'image';
  }

  setSrc(src: string): void {
    const writable = this.getWritable();
    writable.__src = src;
  }

  getAttachment(): Attachment {
    return this.__attachment;
  }

  setHasUploaded(has_been_uploaded: boolean): void {
    this.getWritable().__has_been_uploaded = has_been_uploaded;
  }

  setWidthAndHeight(
    width: 'inherit' | number,
    height: 'inherit' | number
  ): void {
    const writable = this.getWritable();
    writable.__width = width;
    writable.__height = height;
  }

  static override clone(node: ImageNode): ImageNode {
    return new ImageNode({
      src: node.__src,
      altText: node.__altText,
      width: node.__width,
      height: node.__height,
      attachment: node.__attachment,
      key: node.__key,
    });
  }

  static override importJSON(serializedNode: SerializedImageNode): ImageNode {
    return $createImageNode({
      src: serializedNode.src,
      altText: serializedNode.altText,
      width: serializedNode.width,
      height: serializedNode.height,
      attachment: serializedNode.attachment,
    });
  }

  override exportJSON(): SerializedImageNode {
    return {
      src: this.__src,
      name: this.__attachment.originalFilename,
      size: this.__attachment.size,
      altText: this.__altText,
      width: this.__width === 'inherit' ? undefined : this.__width,
      height: this.__height === 'inherit' ? undefined : this.__height,
      attachment: this.__attachment,
      type: 'image',
      version: 1,
    };
  }

  override decorate(): JSX.Element {
    return (
      <ImageComponent
        nodeKey={this.getKey()}
        src={this.__src}
        altText={this.__altText}
        initialWidth={this.__width}
        initialHeight={this.__height}
        attachment={this.__attachment}
      />
    );
  }

  // eslint-disable-next-line class-methods-use-this
  override createDOM(): HTMLElement {
    return document.createElement('div');
  }

  // eslint-disable-next-line class-methods-use-this
  override updateDOM(): false {
    return false;
  }

  override exportDOM(): DOMExportOutput {
    const image = document.createElement('img');
    image.setAttribute('src', this.__src);
    image.setAttribute('alt', this.__altText);
    image.style.width = `${this.__width}px`;

    // Add attachment metadata as data attributes
    if (this.__attachment) {
      image.setAttribute('data-attachment-type', this.__attachment.type);
      image.setAttribute(
        'data-attachment-size',
        this.__attachment.size.toString()
      );
      image.setAttribute('data-attachment-id', this.__attachment.id);
      image.setAttribute(
        'data-attachment-original-filename',
        this.__attachment.originalFilename
      );
      image.setAttribute(
        'data-has-been-uploaded',
        this.__has_been_uploaded.toString()
      );
      if (!this.__has_been_uploaded) {
        image.setAttribute('data-attachment-data', this.__attachment.data);
      }
    }

    return { element: image };
  }

  static override importDOM(): DOMConversionMap | null {
    return {
      img: () => ({
        conversion: (domNode: HTMLElement) => {
          const conversion = $convertImageElement(domNode);
          if (conversion) {
            return {
              ...conversion,
              node: conversion.node || null,
            };
          }
          return null;
        },
        priority: 0,
      }),
    };
  }
}

export function $isImageNode(
  node: LexicalNode | null | undefined
): node is ImageNode {
  return node instanceof ImageNode;
}
