/**
 * Decides which view to return home to based on permissions
 * @param {boolean} dashboardPagePermission - does the user have permission to view the dashboard page?
 * @param {boolean} feedbackPagePermission - does the user have permission to view the feedback page?
 * @param {History} history, optional
 * @returns {string} route (and if history is provided, pushes new route)
 */
export const navigateHome = (
  dashboardPagePermission,
  feedbackPagePermission,
  history = null
) => {
  let route = '';
  if (dashboardPagePermission) route = '/dashboard';
  else if (!dashboardPagePermission && feedbackPagePermission)
    route = '/feedback';
  else route = '/threads/inbox';

  if (history) history.push(route);
  return route;
};
