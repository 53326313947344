import type { Dispatch } from 'redux';
import { openSnackbar } from '../../../actions/general';
import {
  type CustomerContact,
  type I18NType,
  SMS_STATUS,
} from '../../../utils/helpers/types';
import type {
  ListTagAudienceData,
  SelectedAudience,
} from '../groupMessage/types';

// Table sorting options for SortBy component
export const getSortOptions = (i18n: I18NType) => [
  {
    label: i18n.t('dashboards-GroupSelect-Ascending', {
      defaultValue: 'Ascending (A-Z)',
    }),
    value: 'ASC',
  },
  {
    label: i18n.t('dashboards-GroupSelect-Descending', {
      defaultValue: 'Descending (Z-A)',
    }),
    value: 'DESC',
  },
];

// Handles displaying green snackbar on API call success
export const onSuccess = (dispatch: Dispatch, message: string) => {
  dispatch(openSnackbar(message));
};

// Handles displaying red snackbar on API call failure/error
export const onError = (dispatch: Dispatch, message: string) => {
  dispatch(openSnackbar(message, 'error'));
};

/**
 * Gets one line of text to display in the status column.
 * Possible to have multiple states for why a contact can't be selected.
 * @returns One line of text to display in the status column.
 */
export const getOptInStatusText = (
  contact: ListTagAudienceData | CustomerContact | SelectedAudience,
  i18n: I18NType
): string => {
  if (!contact) return '';
  if (
    (contact.__typename === 'ListTagAudienceData' ||
      contact.__typename === 'SelectedAudience') &&
    contact.type !== 'contact'
  )
    return '';
  if (contact.__typename === 'CustomerContact') {
    if (contact.smsStatus?.includes(SMS_STATUS.not_in_service)) {
      return i18n.t('broadcasts-list-outOfService', {
        defaultValue:
          'This number has been disconnected or is no longer in service.',
      });
    }
    if (contact.smsStatus?.includes(SMS_STATUS.landline)) {
      return i18n.t('broadcasts-list-landline', {
        defaultValue: 'Number is not text compatible',
      });
    }
  }
  if (
    contact.promotionalBroadcastOptOut === false &&
    contact.announcementsOptOut === false
  ) {
    return i18n.t('broadcasts-broadcast-repliedStart', {
      defaultValue: 'Replied "START"',
    });
  }
  if (
    contact.promotionalBroadcastOptOut &&
    contact.announcementsOptOut === false
  ) {
    return i18n.t('broadcasts-list-noPromo', {
      defaultValue: 'Replied "NO PROMO"',
    });
  }
  if (contact.promotionalBroadcastOptOut && contact.announcementsOptOut) {
    return i18n.t('broadcasts-broadcast-repliedStop', {
      defaultValue: 'Replied "STOP"',
    });
  }
  if (contact.announcementsOptOut === null) {
    return i18n.t('settings-manageRules-noRecordedOptedStatus');
  }

  return '';
};
