import React from 'react';
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin';
import richTextEditorLinkAttributes from '../shared/constants';
/**
 * A wrapper component for the Lexical AutoLinkPlugin.
 *
 * This component provides URL matching functionality to automatically convert URLs in the text into clickable links. Code was copied from https://lexical.dev/docs/react/plugins#lexicalautolinkplugin
 *
 * @returns <AutoLinkPluginWrap />
 */

// Regex pattern matches URLs that start with http://, https://, or www.
const URL_MATCHER =
  /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

const MATCHERS = [
  (text: string) => {
    const match = URL_MATCHER.exec(text);

    if (match === null) {
      return null;
    }
    const fullMatch = match[0];
    return {
      index: match.index,
      length: fullMatch.length,
      text: fullMatch,
      url: fullMatch.startsWith('http') ? fullMatch : `https://${fullMatch}`,
      attributes: richTextEditorLinkAttributes,
    };
  },
];

const AutoLinkPluginWrap: React.FC = () => (
  <AutoLinkPlugin matchers={MATCHERS} />
);

export default AutoLinkPluginWrap;
