import { useQuery } from "@apollo/client";
import CHANNELS_QUERY from "../../../graphql/query/ChannelsQuery.graphql";

const useActiveEmailChannels = ({ accountId, client }) => {
  const { data: channelsData } = useQuery(CHANNELS_QUERY, {
    client,
    variables: {
      accountId,
      type: "email",
    },
  });

  const sortChannels = (a, b) =>
    ("" + a.group.name).localeCompare(b.group.name);

  const activeEmailChannels = channelsData?.channels.slice().sort(sortChannels);

  return { activeEmailChannels };
};

export default useActiveEmailChannels;
