import useAccountQuery from "../api/query/useAccountQuery";

const useMultiGroupFeature = ({ client, currentUser }) => {
  const { data: accountData } = useAccountQuery({
    client,
    variables: {
      id: currentUser?.accountId,
    },
  });
  const multiGroup = accountData?.account?.ff_multi_inbox_view;

  return {
    multiGroup,
  };
};

export default useMultiGroupFeature;
