import { createStore, combineReducers, compose } from 'redux';
import throttle from 'lodash/throttle';
import session from './reducers/session';
import customerInfo from './reducers/customerInfo';
import search from './reducers/search';
import reports from './reducers/reports';
import threadInfo from './reducers/threadInfo';
import editCustomer from './reducers/editCustomer';
import editCompany from './reducers/editCompany';
import uploadModal from './reducers/uploadModal';
import general from './reducers/general';
import whitelabel from './reducers/whitelabel';
import globalModals from './reducers/globalModals';
import accountData from './reducers/accountData';
import createSection from './reducers/createSection';
import releaseReady from './reducers/releaseReady';
import label from './reducers/label';
import print from './reducers/print';
import applicationState from './reducers/applicationState';

import { saveState, loadState, clearState } from './localStorage';

function lastAction(state, action) {
  return action;
}

const appReducer = combineReducers({
  session,
  customerInfo,
  search,
  threadInfo,
  reports,
  editCustomer,
  editCompany,
  uploadModal,
  general,
  whitelabel,
  globalModals,
  accountData,
  createSection,
  lastAction,
  releaseReady,
  label,
  print,
  applicationState,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_STORE') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const enhancers = compose(
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const loadSessionState = () => {
  const data = loadState();

  let sessionData = {};

  if (data?.currentUser) {
    sessionData = {
      ...data,
      socketConnectionTimestamp: undefined,
    };
  }

  return { session: sessionData };
};

const store = createStore(rootReducer, loadSessionState(), enhancers);

store.subscribe(
  throttle(() => {
    const storeState = store.getState();
    const lastActionType = storeState.lastAction?.type;
    const session = storeState.session;

    if (lastActionType !== 'AUTH_SIGNOUT' && session?.currentUser) {
      saveState(session);
    } else {
      clearState();
    }
  }, 1000)
);

export default store;
