import { useMutation } from "@apollo/client";
import DELETE_MESSAGE_MUTATION from "../../../graphql/mutation/DeleteMessageMutation.graphql";

/*

DOCUMENTATION
  generic hook for using deleteMessageMutation

  args:
    client: (obj) required. apollo client instance

  returns:
    [0] handleDelete\Message: func that will call poseMessageMutation.
      args:

        onSuccess: (func) optional, will call on mutation success
        onError: (func) optional, will call on mutation failure

*/

const useDeleteMessage = ({ client, i18n }) => {
  const [deleteMessage] = useMutation(DELETE_MESSAGE_MUTATION, { client });

  const deleteMessageOptions = [
    {
      value: "SENSITIVE_INFORMATION",
      label: i18n.t("threads-Message-sensitiveInfo"),
    },
    {
      value: "ACCIDENTALLY_SENT",
      label: i18n.t("threads-Message-accidentallySent"),
    },
    {
      value: "INAPPROPRIATE_CONTENT",
      label: i18n.t("threads-Message-inappropriateContent"),
    },
  ];

  const handleDeleteMessage = async ({
    deletionDescription,
    deletionReason,
    messageId,
    onSuccess,
    onError,
  }) => {
    const { data } = await deleteMessage({
      variables: {
        input: {
          deletionDescription,
          deletionReason,
          messageId,
        },
      },
    }).catch((err) => {
      if (onError) onError(err);
    });

    if (data && data.deleteMessage && data.deleteMessage.errors) {
      if (onError) onError(data.deleteMessage.errors[0]);
    } else if (onSuccess) onSuccess();
  };

  return [handleDeleteMessage, deleteMessageOptions];
};

export default useDeleteMessage;
