import { useMutation } from "@apollo/client";
import { UPDATE_FEEDBACK_SELECTED_GROUPS_MUTATION } from "../../..";

const useUpdateFeedbackSelectedGroups = ({ client }) => {
  const [updateFeedbackSelectedGroups] = useMutation(
    UPDATE_FEEDBACK_SELECTED_GROUPS_MUTATION,
    {
      client,
    }
  );

  const updateFeedbackSelectedGroupsHandler = async ({
    groupIds,
    onSuccess,
    onError,
  }) => {
    const response = await updateFeedbackSelectedGroups({
      variables: {
        groupIds,
      },
    }).catch((err) => onError(err));

    if (response?.data?.updateFeedbackSelectedGroups?.errors) {
      onError(response.data.updateFeedbackSelectedGroups.errors);
    } else {
      onSuccess(response?.data?.updateFeedbackSelectedGroups?.groupIds);
    }
  };

  return {
    updateFeedbackSelectedGroupsHandler,
  };
};

export default useUpdateFeedbackSelectedGroups;
