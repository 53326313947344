import React from 'react';
import styled from 'styled-components';
import { slugify } from 'client-lib/src/lib/utils/helpers';
import type { ThemeSet } from 'styled-theming';
import THEMES from '../../../styles/themes/library/textInput';
import FONTSIZE_THEMES from '../../../styles/themes/fontSize/fontSize';
import { Checkbox } from '../../index';
import type { RSOptionRef } from './types';

const StyledOption = styled.div<{
  isSelected: boolean;
  customOptionStyle?: (props: unknown) => string;
}>`
  height: 40px;
  font-size: ${FONTSIZE_THEMES.SELECT_TEXT};
  font-weight: 500;
  font-family: 'Barlow', sans-serif;
  color: ${(props) =>
    props.isSelected ? THEMES.OPTION_TEXT_ACTIVE : THEMES.OPTION_TEXT};
  background-color: ${(props) =>
    props.isSelected
      ? THEMES.OPTION_BACKGROUND_ACTIVE
      : THEMES.OPTION_BACKGROUND};
  display: flex;
  text-transform: capitalize;
  align-items: center;
  padding: 0 8px;
  gap: 8px;
  ${(props) => props?.customOptionStyle?.(props)};

  :hover {
    color: ${(props) => (props.isSelected ? null : THEMES.OPTION_TEXT_HOVER)};
    background-color: ${(props) =>
      props.isSelected ? null : THEMES.OPTION_BACKGROUND_HOVER};
  }

  :nth-of-type(1) {
    border-radius: 4px 4px 0 0;
  }

  :nth-last-of-type(1) {
    border-radius: 0 0 4px 4px;
  }
`;

const EllipsisOverflow = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  text-overflow: ellipsis;
`;

const ColorSquare = styled.div<{ labelColor: ThemeSet }>`
  flex: none;
  background: ${(props) => props.labelColor};
  width: 16px;
  height: 16px;
  border-radius: 2px;
`;

interface OptionProps {
  innerRef?: RSOptionRef;
  innerProps: object;
  children: React.ReactNode;
  isSelected: boolean;
  isCheckable?: boolean;
  customOptionStyle?: (props: unknown) => string;
  dataTestId: string;
  applyLabelColor?: ThemeSet;
}

const Option = ({
  innerRef = undefined,
  innerProps,
  children,
  isSelected,
  isCheckable = false,
  customOptionStyle,
  dataTestId,
  applyLabelColor,
}: OptionProps) => {
  return (
    <StyledOption
      ref={innerRef as RSOptionRef}
      isSelected={isSelected}
      customOptionStyle={customOptionStyle}
      data-testid={`${dataTestId}-option-${slugify(children)}`}
      {...innerProps}
    >
      {isCheckable && <Checkbox checked={isSelected} />}
      <EllipsisOverflow>{children}</EllipsisOverflow>
      {applyLabelColor && <ColorSquare labelColor={applyLabelColor} />}
    </StyledOption>
  );
};

export default Option;
