import COLORS from '../../styles/colors';

export const getColorTimeLabelObj = (i18n) => [
  {
    key: 'COLORS.PK.GREEN_60',
    color: COLORS.PK.GREEN_60,
    label: `< 5 ${i18n
      .t('settings-manageRules-minutes', { defaultValue: 'minutes' })
      .toLowerCase()}`,
  },
  {
    key: 'COLORS.PK_CLASSIC.WIDGET_YELLOW',
    color: COLORS.PK_CLASSIC.WIDGET_YELLOW,
    label: `< 1 ${i18n
      .t('settings-manageRules-hour', { defaultValue: 'hour' })
      .toLowerCase()}`,
  },
  {
    key: 'COLORS.PK_CLASSIC.WIDGET_ORANGE',
    color: COLORS.PK_CLASSIC.WIDGET_ORANGE,
    label: `< 4 ${i18n
      .t('settings-manageRules-hours', { defaultValue: 'hours' })
      .toLowerCase()}`,
  },
  {
    key: 'COLORS.PK.RED',
    color: COLORS.PK.RED,
    label: `4+ ${i18n
      .t('settings-manageRules-hours', { defaultValue: 'hours' })
      .toLowerCase()}`,
  },
  {
    key: 'COLORS.PK_CLASSIC.WIDGET_BROWN',
    color: COLORS.PK_CLASSIC.WIDGET_BROWN,
    label: `8+ ${i18n
      .t('settings-manageRules-hours', { defaultValue: 'hours' })
      .toLowerCase()}`,
  },
];

export const getColorCodes = (i18n) => {
  const colorTimeLabelObj = getColorTimeLabelObj(i18n);
  return colorTimeLabelObj.map((colorKey) => colorKey.color);
};
