import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import THEMES from '../../styles/themes/app';
import FONTSIZE_THEMES from '../../styles/themes/fontSize/fontSize';

const contrastStyles = {
  high: THEMES.FOREGROUND_HIGH,
  med: THEMES.FOREGROUND_MED,
  low: THEMES.FOREGROUND_LOW,
  colorHigh: THEMES.COLORED_FOREGROUND_HIGH,
  colorMed: THEMES.COLORED_FOREGROUND_MED,
  colorLow: THEMES.COLORED_FOREGROUND_LOW,
  highColor: THEMES.FOREGROUND_HIGH_COLOR,
  medColor: THEMES.FOREGROUND_MED_COLOR,
  red: THEMES.THEME_RED,
  primaryRed: THEMES.RED_PRIMARY,
  red80: THEMES.PCI_BREACH_THEME,
};

const BaseStyle = styled.div`
  color: ${(props) => contrastStyles[props.contrast]};
  font-family: 'Barlow', sans-serif;
  padding: 0;
  margin: 0;

  ${(props) =>
    props?.maxWidth
      ? `
    max-width: ${props?.maxWidth};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `
      : ''}
`;

export const h1Style = (props) => `
  font-weight: 700;
  font-size: ${FONTSIZE_THEMES.HEADING1(props)};
`;

const StyledHeading1 = styled(BaseStyle).attrs({
  as: 'h1',
})((props) => h1Style(props));

export const Heading1 = ({ contrast, customStyle, dataTestId, ...props }) => (
  <StyledHeading1
    contrast={contrast}
    customStyle={customStyle}
    css={(props) => props?.customStyle?.(props)}
    data-testid={dataTestId}
    {...props}
  />
);

Heading1.propTypes = {
  contrast: PropTypes.string,
  customStyle: PropTypes.func,
  dataTestId: PropTypes.string,
};

Heading1.defaultProps = {
  contrast: 'high',
  customStyle: null,
  dataTestId: null,
};

export const h2Style = (props) => `
  font-weight: 600;
  font-size: ${FONTSIZE_THEMES.HEADING2(props)};
`;

const StyledHeading2 = styled(BaseStyle).attrs({
  as: 'h2',
})((props) => h2Style(props));

export const Heading2 = ({ contrast, customStyle, dataTestId, ...props }) => (
  <StyledHeading2
    contrast={contrast}
    customStyle={customStyle}
    css={(props) => props?.customStyle?.(props)}
    data-testid={dataTestId}
    {...props}
  />
);

Heading2.propTypes = {
  contrast: PropTypes.string,
  customStyle: PropTypes.func,
  dataTestId: PropTypes.string,
};

Heading2.defaultProps = {
  contrast: 'high',
  customStyle: null,
  dataTestId: null,
};

export const h3Style = (props) => `
  font-weight: 700;
  font-size: ${FONTSIZE_THEMES.HEADING3(props)};
`;

const StyledHeading3 = styled(BaseStyle).attrs({
  as: 'h3',
})((props) => h3Style(props));

export const Heading3 = ({ contrast, customStyle, dataTestId, ...props }) => (
  <StyledHeading3
    contrast={contrast}
    customStyle={customStyle}
    css={(props) => props?.customStyle?.(props)}
    data-testid={dataTestId}
    {...props}
  />
);

Heading3.propTypes = {
  contrast: PropTypes.string,
  customStyle: PropTypes.func,
  dataTestId: PropTypes.string,
};

Heading3.defaultProps = {
  contrast: 'high',
  customStyle: null,
  dataTestId: null,
};

export const h4Style = (props) => `
  font-weight: 600;
  font-size: ${FONTSIZE_THEMES.HEADING4(props)};
`;

const StyledHeading4 = styled(BaseStyle).attrs({
  as: 'h4',
})((props) => h4Style(props));

export const Heading4 = ({ contrast, customStyle, dataTestId, ...props }) => (
  <StyledHeading4
    contrast={contrast}
    customStyle={customStyle}
    css={(props) => props?.customStyle?.(props)}
    data-testid={dataTestId}
    {...props}
  />
);

Heading4.propTypes = {
  contrast: PropTypes.string,
  customStyle: PropTypes.func,
  dataTestId: PropTypes.string,
};

Heading4.defaultProps = {
  contrast: 'high',
  customStyle: null,
  dataTestId: null,
};

export const h5Style = (props) => `
  font-weight: 700;
  font-size: ${FONTSIZE_THEMES.HEADING5(props)};
  text-transform: uppercase;
  display: inline-block;
`;

const StyledHeading5 = styled(BaseStyle).attrs({
  as: 'h5',
})((props) => h5Style(props));

export const Heading5 = ({ contrast, customStyle, dataTestId, ...props }) => (
  <StyledHeading5
    contrast={contrast}
    customStyle={customStyle}
    css={(props) => props?.customStyle?.(props)}
    data-testid={dataTestId}
    {...props}
  />
);

Heading5.propTypes = {
  contrast: PropTypes.string,
  customStyle: PropTypes.func,
  dataTestId: PropTypes.string,
};

Heading5.defaultProps = {
  contrast: 'high',
  customStyle: null,
  dataTestId: null,
};

export const pStyle = (props) => `
  font-weight: 500;
  font-size: ${FONTSIZE_THEMES.TEXT(props)};
  opacity: ${props.disabled ? '0.4' : 1};
`;

const StyledText = styled(BaseStyle).attrs({
  as: 'p',
})((props) => pStyle(props));

export const Text = ({ contrast, customStyle, dataTestId, ...props }) => (
  <StyledText
    contrast={contrast}
    customStyle={customStyle}
    css={(props) => props?.customStyle?.(props)}
    data-testid={dataTestId}
    {...props}
  />
);

Text.propTypes = {
  contrast: PropTypes.string,
  customStyle: PropTypes.func,
  dataTestId: PropTypes.string,
};

Text.defaultProps = {
  contrast: 'high',
  customStyle: null,
  dataTestId: null,
};

export const metaStyle = (props) => `
  font-weight: 500;
  font-size: ${FONTSIZE_THEMES.METATEXT(props)};
`;

const StyledMetaText = styled(BaseStyle).attrs({
  as: 'p',
})((props) => metaStyle(props));

export const MetaText = ({ contrast, customStyle, dataTestId, ...props }) => (
  <StyledMetaText
    contrast={contrast}
    customStyle={customStyle}
    css={(props) => props?.customStyle?.(props)}
    data-testid={dataTestId}
    {...props}
  />
);

MetaText.propTypes = {
  contrast: PropTypes.string,
  customStyle: PropTypes.func,
  dataTestId: PropTypes.string,
};

MetaText.defaultProps = {
  contrast: 'high',
  customStyle: null,
  dataTestId: null,
};

const StyledSpan = styled(BaseStyle).attrs({
  as: 'span',
})``;

export const Span = ({ contrast, customStyle, dataTestId, ...props }) => (
  <StyledSpan
    contrast={contrast}
    customStyle={customStyle}
    css={(props) => props?.customStyle?.(props)}
    data-testid={dataTestId}
    {...props}
  />
);

Span.propTypes = {
  contrast: PropTypes.string,
  customStyle: PropTypes.func,
  dataTestId: PropTypes.string,
};

Span.defaultProps = {
  contrast: 'high',
  customStyle: null,
  dataTestId: null,
};

export const emphasisStyle = (props) => `
  font-weight: 700;
  font-size: ${FONTSIZE_THEMES.EMPHASISTEXT(props)};
  opacity: ${props.disabled ? '0.4' : 1};
`;

const StyledEmphasisText = styled(BaseStyle).attrs({
  as: 'p',
})((props) => emphasisStyle(props));

export const EmphasisText = ({
  contrast,
  customStyle,
  dataTestId,
  ...props
}) => (
  <StyledEmphasisText
    contrast={contrast}
    customStyle={customStyle}
    css={(props) => props?.customStyle?.(props)}
    data-testid={dataTestId}
    {...props}
  />
);

EmphasisText.propTypes = {
  contrast: PropTypes.string,
  customStyle: PropTypes.func,
  dataTestId: PropTypes.string,
};

EmphasisText.defaultProps = {
  contrast: 'high',
  customStyle: null,
  dataTestId: null,
};

const StyledItalicText = styled(BaseStyle).attrs({
  as: 'em',
})`
  ${(props) => props.customStyle && props.customStyle(props)}
`;

export const ItalicText = ({ contrast, customStyle, dataTestId, ...props }) => (
  <StyledItalicText
    contrast={contrast}
    customStyle={customStyle}
    data-testid={dataTestId}
    {...props}
  />
);

ItalicText.propTypes = {
  contrast: PropTypes.string,
  customStyle: PropTypes.func,
  dataTestId: PropTypes.string,
};

ItalicText.defaultProps = {
  contrast: 'high',
  customStyle: null,
  dataTestId: null,
};
