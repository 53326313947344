import {
  SET_CUSTOMERS_FILTER,
  SET_CUSTOMERS_FILTER_TEXT,
  SET_ACTIVE_CUSTOMER,
  SET_ACTIVE_CUSTOMER_ALL,
  SET_ACTIVE_CUSTOMER_RECENT,
  SET_ACTIVE_CUSTOMER_TEAM,
  ACTIVE_CUSTOMER_SELECTED,
} from '../actions';

const DEFAULT_STATE = {
  activeCustomerId: '',
  activeCustomerAll: {
    id: '',
    entityType: '',
  },
  activeCustomerRecent: {
    id: '',
    entityType: '',
  },
  activeCustomerTeam: {
    id: '',
    entityType: '',
  },
  filter: 'all',
  filterText: '',
  lastActiveCustomer: '',
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_CUSTOMERS_FILTER:
      return {
        ...state,
        filter: action.filter,
      };
    case SET_CUSTOMERS_FILTER_TEXT:
      return {
        ...state,
        filterText: action.filterText,
      };
    case SET_ACTIVE_CUSTOMER:
      return {
        ...state,
        activeCustomerId: action.activeCustomerId,
      };
    case SET_ACTIVE_CUSTOMER_ALL:
      return {
        ...state,
        activeCustomerAll: {
          id: action.id,
          entityType: action.entityType,
        },
      };
    case SET_ACTIVE_CUSTOMER_RECENT:
      return {
        ...state,
        activeCustomerRecent: {
          id: action.id,
          entityType: action.entityType,
        },
      };
    case SET_ACTIVE_CUSTOMER_TEAM:
      return {
        ...state,
        activeCustomerTeam: {
          id: action.id,
          entityType: action.entityType,
        },
      };
    case ACTIVE_CUSTOMER_SELECTED:
      return {
        ...state,
        lastActiveCustomer: action.lastActiveCustomer,
      };
    default:
      return state;
  }
}
