import theme from 'styled-theming';
import COLORS from '../../colors';

const HEAD_BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_BLUE,
  light: COLORS.PK.BLUE_100,
  dark: COLORS.PK_DARK.GREY_4,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_PRIMARY,
});

const HEAD_TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.WHITE,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_HIGH,
  holiday: COLORS.PK.WHITE,
});

const HEAD_BORDER = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_BLUE,
  light: COLORS.PK.BLUE_100,
  dark: COLORS.PK_DARK.GREY_4,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_PRIMARY,
});

const BODY_BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.WHITE,
  dark: COLORS.PK_DARK.BLACK,
  holiday: COLORS.PK.WHITE,
});

const BODY_TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_BLACK,
  light: COLORS.PK.BLACK,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_HIGH,
  holiday: COLORS.PK.BLACK,
});

const BODY_BORDER = theme('mode', {
  classic: COLORS.PK_CLASSIC.SECONDARY_GRAY,
  light: COLORS.PK.GREY_20,
  dark: COLORS.PK_DARK.GREY_4,
  holiday: COLORS.PK.GREY_20,
});

const THEMES = {
  HEAD_BACKGROUND,
  HEAD_TEXT,
  HEAD_BORDER,
  BODY_BACKGROUND,
  BODY_TEXT,
  BODY_BORDER,
};

export default THEMES;
