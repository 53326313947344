import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Text } from '../../..';
import THEMES from '../../../../styles/themes/library/textInput';

const ReferenceNumberWrapper = styled.div`
  background-color: ${(props) =>
    props.isHighlighted
      ? THEMES.OPTION_BACKGROUND_HOVER(props)
      : THEMES.OPTION_BACKGROUND(props)};
  border-bottom: 1px solid ${THEMES.BORDER};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
`;

const WorkOrderSuggestion = ({ suggestion, isHighlighted }) => (
  <ReferenceNumberWrapper isHighlighted={isHighlighted}>
    <Text>{suggestion.referenceNumber}</Text>
  </ReferenceNumberWrapper>
);

WorkOrderSuggestion.propTypes = {
  suggestion: PropTypes.object.isRequired,
  isHighlighted: PropTypes.bool.isRequired,
};

export default WorkOrderSuggestion;
