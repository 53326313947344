const environmentOverride =
  process.env?.PK_ENV !== 'prod' && process.env?.PK_ENV !== 'canada';

const DEFAULT_STATE = {
  contactPageEnhancements: false || environmentOverride,
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    default:
      return state;
  }
}
