import { useEffect } from 'react';
import i18n from 'i18n-js';
import useMultiSelectItems from './useMultiSelectItems';
import { MULTI_SELECT_FILTER } from './helpers';

/*
DOCUMENTATION
  this hook was designed to use with the MultiSelectTableItems component. 

  args:
    items: (arr) required. An array of objects representing the elements we want to select/unselect all.
    queryFilter: (str) optional. represents the selected filter.
    paginated: (obj) optional. Object with only one key 'totalContacts' that represents the total number of contacts.
  returns:
    disabled: (bool) to marks the main checkbox as disabled or not.
    indeterminate: (bool) to marks the main checkbox as indeterminate or not.
    allItemsSelected (bool) to marks the main checkbox as checked or not.
    shouldCheckItem: (func) callback function to pass to the Row element to determine if we should check it or not. This is needed for the 'checked' prop of the Checkbox component.
    multiSelectOptions: (arr) representing the available options of the MultiSelectTableItems component..
    handleCheckItem: (func) Callback function to handle the event whenever any Row checkbox is checked..
*/

const useMultiSelectTableItems = ({
  items,
  paginated = null,
  queryFilter = '',
}) => {
  const {
    handleCheckItem,
    shouldCheckItem,
    indeterminate,
    allItemsSelected,
    handleSelectAll: handleSelectAllMultiItems,
    handleSelectNone,
    setFilterSelect,
    selectedItems,
    setSelectItems,
    deselectedItems,
    setDeselectedItems,
    disabled,
  } = useMultiSelectItems({ items });

  // Reseting the array when the query includes a filter
  useEffect(() => {
    handleSelectNone();
  }, [queryFilter]);

  const handleSelectPage = () => {
    setFilterSelect(MULTI_SELECT_FILTER.PAGE);

    const actualItemsPage = items.map((item) => item.id);
    const mergedArr = [
      ...selectedItems,
      ...actualItemsPage.filter(
        (actualItem) => !selectedItems.includes(actualItem)
      ),
    ];

    setSelectItems(mergedArr);
    setDeselectedItems([]);
  };

  const handleSelectAll = () => {
    if (paginated) {
      setFilterSelect(MULTI_SELECT_FILTER.ALL);
      setSelectItems([]);
      setDeselectedItems([]);
    } else {
      handleSelectAllMultiItems();
    }
  };

  const multiSelectOptions = [
    {
      label: i18n.t('settings-multiSelectTableItems-options-none', {
        number: 0,
      }),
      value: MULTI_SELECT_FILTER.NONE,
      handleSelect: handleSelectNone,
    },
    {
      label: i18n.t('settings-multiSelectTableItems-options-all', {
        number: paginated ? paginated?.totalContacts : items.length,
      }),
      value: MULTI_SELECT_FILTER.ALL,
      handleSelect: handleSelectAll,
    },
  ];

  if (paginated) {
    multiSelectOptions.splice(1, 0, {
      label: i18n.t('settings-multiSelectTableItems-options-page', {
        number: items.length,
      }),
      value: MULTI_SELECT_FILTER.PAGE,
      handleSelect: handleSelectPage,
    });
  }
  return {
    allItemsSelected:
      allItemsSelected ||
      (paginated && paginated?.totalContacts === selectedItems.length),
    disabled,
    handleCheckItem,
    handleSelectNone,
    indeterminate,
    multiSelectOptions,
    selectedItems,
    deselectedItems,
    shouldCheckItem,
    setSelectItems,
  };
};

export default useMultiSelectTableItems;
