import { useQuery } from "@apollo/client";
import { GET_FEEDBACK_QUERY } from "../../../index";

const useGetFeedback = ({ client, id, fetchPolicy = "cache-and-network" }) =>
  useQuery(GET_FEEDBACK_QUERY, {
    client,
    variables: { id },
    fetchPolicy,
  });

export default useGetFeedback;
