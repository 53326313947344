import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useRouteMatch } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { useResetPassword } from 'client-lib';
import i18n from 'i18n-js';
import { Message, MessageContainer } from '../../components/Settings/styles';
import PasswordRequirements from './PasswordRequirements';
import { Heading1, Button, TextInput } from '../../elements';
import { Container } from './styles';

const HeadingWrapper = styled.div`
  margin-bottom: 24px;
`;

const ResetPassword = () => {
  const client = useApolloClient();
  const match = useRouteMatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onResetPasswordSucceed = (resetPassword) => {
    const sessionUser = resetPassword.session.user;
    const absintheLink = client.connectSocket(
      sessionUser.id,
      sessionUser.accountId
    );

    absintheLink.onPresenceJoin(() => {
      setIsSubmitting(false);
    });
  };

  const { onSubmit, updateField, fields, isVisitedForm, error } =
    useResetPassword({
      client,
      onResetPasswordSucceed,
      resetToken: match.params.token,
      i18n,
    });

  const { password, passwordConfirm } = fields;

  const [passesValidations, setPassesValidations] = useState(false);

  const isSubmitButtonDisabled = !(
    isVisitedForm &&
    passesValidations &&
    !isSubmitting
  );

  const handleKeyPress = (e) => {
    if (
      (e.keyCode === 13 || e.key === 'Enter' || e.charCode === 13) &&
      isVisitedForm
    ) {
      handleSubmitForm();
    }
  };

  const handleSubmitForm = () => {
    setIsSubmitting(true);
    onSubmit();
  };

  useEffect(() => {
    if (error) setIsSubmitting(false);
  }, [error]);

  return (
    <Container data-testid="reset-password">
      <HeadingWrapper data-testid="reset-password-form">
        <Heading1>{i18n.t('signin-ResetPassword-createPassword')}</Heading1>
      </HeadingWrapper>
      {error && (
        <MessageContainer>
          <Message className="active">{error}</Message>
        </MessageContainer>
      )}
      <div data-testid="username">
        <TextInput
          type="password"
          label={i18n.t('signin-ResetPassword-password')}
          dataTestId="password-input"
          error={password.error}
          value={password.value}
          onChange={(e) =>
            updateField({ value: e.target.value, name: 'password' })
          }
          onFocus={() => password.setFocus(true)}
          onBlur={() => password.setFocus(false)}
          onKeyPress={handleKeyPress}
        />
        <TextInput
          type="password"
          label={i18n.t('settings-PasswordForm-passwordMatch')}
          dataTestId="new-password-confirm"
          value={passwordConfirm?.value}
          onChange={(ev) =>
            updateField({
              name: 'passwordConfirm',
              value: ev.target.value,
            })
          }
          onFocus={() => passwordConfirm.setFocus(true)}
          onBlur={() => passwordConfirm.setFocus(false)}
          error={passwordConfirm.error}
        />
        <PasswordRequirements
          password={password.value}
          passwordConfirm={passwordConfirm.value}
          onValidate={setPassesValidations}
        />
      </div>
      <div>
        <Button
          onClick={handleSubmitForm}
          disabled={isSubmitButtonDisabled}
          loadingSpinner={isSubmitting}
        >
          {i18n.t('signin-ResetPassword-resetPassword')}
        </Button>
      </div>
    </Container>
  );
};

export default ResetPassword;
