import React from 'react';
import PropTypes from 'prop-types';
import TemplateForm from '../../../../Settings/Templates/TemplateForm';

const CreateNewTemplateFlyout = ({
  onClose,
  isOpen,
  messageText,
  attachments,
  link,
}) => {
  return (
    <TemplateForm
      isSettingsTemplate={false}
      flyoutProps={{ onClose, isOpen, isEditForm: false }}
      templateData={{
        message: messageText,
        channelTypes: ['BROADCAST_TEXT_MESSAGE'],
        attachments,
        link,
      }}
    />
  );
};

CreateNewTemplateFlyout.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  messageText: PropTypes.string.isRequired,
  attachments: PropTypes.shape({}),
  link: PropTypes.string,
};

CreateNewTemplateFlyout.defaultProps = {
  attachments: null,
  link: null,
};

export default CreateNewTemplateFlyout;
