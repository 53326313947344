import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import theme, { type ThemeMap } from 'styled-theming';

import Bubble from '../../Common/Bubble';
import { IconLabel, SquareBadge } from '../../../elements';

const BUBBLE_HEIGHT = theme('fontSize', {
  default: 222,
  large: 250,
} as unknown as ThemeMap);

const BUBBLE_RIGHT_VAL = theme('fontSize', {
  default: 70,
  large: 110,
} as unknown as ThemeMap);

const BUBBLE_WIDTH = theme('fontSize', {
  default: 166,
  large: 206,
} as unknown as ThemeMap);

const TOKEN_FONTSIZE = theme('fontSize', {
  default: 11,
  large: 15,
} as unknown as ThemeMap);

const squareBadgeCustomStyle = (props: object) => `
  font-size: ${TOKEN_FONTSIZE(props)}px;
  border-radius: 4px;
  cursor: pointer;
`;

const OptionsButtonContainer = styled.div`
  max-height: ${BUBBLE_HEIGHT}px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 16px;
  align-items: flex-start;
  row-gap: 16px;
`;

interface TokenButtonProps {
  optionBubbleOpen: boolean;
  tokens: string[];
  labelStyle: () => string;
  handleTokenSelection: (token: string) => void;
  setOptionBubbleOrToggle: (optionalState?: boolean) => void;
  templateDataId?: string;
  fixedPosition?: boolean;
}

const TokenButton = ({
  optionBubbleOpen,
  tokens: TOKENS,
  labelStyle,
  handleTokenSelection,
  setOptionBubbleOrToggle,
  templateDataId = 'generic-template-data-id',
  fixedPosition = false,
}: TokenButtonProps): JSX.Element => {
  const styledTheme = useContext(ThemeContext);

  return (
    <>
      <IconLabel
        onClick={() => setOptionBubbleOrToggle(!optionBubbleOpen)}
        htmlFor="tokenInput"
        customStyle={labelStyle}
        contrast="medColor"
        {...(optionBubbleOpen && { style: { pointerEvents: 'none' } })}
      >
        <i className="ri-braces-line" />
      </IconLabel>
      {optionBubbleOpen && (
        <Bubble
          isOpen={optionBubbleOpen}
          onClickOutside={() => setOptionBubbleOrToggle(false)}
          moveBubbleRightVal={
            BUBBLE_RIGHT_VAL({
              theme: styledTheme,
            }) as unknown as number
          }
          moveLeftVal={fixedPosition ? -3 : 31}
          bubbleMinWidth={
            BUBBLE_WIDTH({
              theme: styledTheme,
            }) as unknown as number
          }
          bubbleMaxWidth={
            BUBBLE_WIDTH({
              theme: styledTheme,
            }) as unknown as number
          }
          top={fixedPosition ? '11px' : '36px'}
          zIndex={200}
          flipYAxis
          id={`options_bubble_${templateDataId}`}
          fixedPosition={fixedPosition}
        >
          <OptionsButtonContainer>
            {TOKENS.map((token) => (
              <SquareBadge
                color="charcoal"
                key={token}
                size="sm"
                customStyle={squareBadgeCustomStyle}
                onClick={() => handleTokenSelection(token)}
                style={{ userSelect: 'none' }}
              >
                {token}
              </SquareBadge>
            ))}
          </OptionsButtonContainer>
        </Bubble>
      )}
    </>
  );
};

export default TokenButton;
