import React from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import {
  useThreadNotificationQueue,
  MARK_THREAD_READ_MUTATION,
} from 'client-lib';
import { useHistory } from 'react-router-dom';
import Notification from '../components/Notification/index';

const ThreadNotificationContainer = () => {
  const history = useHistory();
  const client = useApolloClient();
  const session = useSelector((state) => state?.session);
  const {
    threadsActiveGroupIds,
    currentUser: { contactId, userId, groupInboxNotificationsEnabled },
  } = session;

  const includeInternal = useSelector(
    (state) => state.accountData.account?.ff_internal_threads
  );

  const { notificationQueue, queryPagesLoaded } = useThreadNotificationQueue({
    client,
    contactId,
    userId,
    threadsActiveGroupIds,
    groupInboxNotificationsEnabled,
    includeInternal,
  });

  const [markThreadReadMutation] = useMutation(MARK_THREAD_READ_MUTATION, {
    client,
  });

  return (
    <Notification
      notificationQueue={notificationQueue}
      markThreadReadMutation={markThreadReadMutation}
      queryPagesLoaded={queryPagesLoaded}
      history={history}
      session={session}
    />
  );
};

export default ThreadNotificationContainer;
