import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import THEMES from '../../styles/themes/library/textInput';
import InputLabel from '../inputCommonElements/InputLabel';
import InputError from '../inputCommonElements/InputError';
import FONTSIZE_THEMES from '../../styles/themes/fontSize/fontSize';

const Container = styled.div`
  width: 100%;
`;

const TextAreaStyled = styled.textarea`
  width: 100%;
  border: 1px solid;
  box-sizing: border-box;
  padding: 8px;
  border-radius: 4px;
  font-size: ${FONTSIZE_THEMES.TEXTAREA_TEXT};
  font-weight: 500;
  font-family: 'Barlow', sans-serif;
  resize: none;
  color: ${THEMES.TEXT};
  outline: none;

  border-color: ${(props) => (props.error ? THEMES.ERROR : THEMES.BORDER)};

  background-color: ${(props) =>
    props.disabled ? THEMES.DISABLED_BACKGROUND : THEMES.BACKGROUND};
  cursor: ${(props) => (props.disabled ? 'default' : 'auto')};

  :focus {
    outline: ${(props) => (props.error ? THEMES.ERROR : THEMES.FOCUS)} auto 1px;
  }

  ::placeholder {
    color: ${THEMES.PLACEHOLDER};
  }
`;

const RightSideLabelWrapper = styled.div`
  margin: 8px 0;
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const TextArea = React.forwardRef(
  (
    {
      label,
      rightSideLabelContent,
      placeholder,
      value,
      onChange,
      error,
      helperText,
      disabled,
      customContainerStyle,
      customLabelStyle,
      customTextAreaStyle,
      customLabelWrapperStyle,
      dataTestId,
      hideBottomSpace,
      ...otherProps
    },
    ref
  ) => (
    <Container
      error={error}
      disbled={disabled}
      customContainerStyle={customContainerStyle}
      css={(props) => props?.customContainerStyle?.(props)}
    >
      <LabelWrapper
        customLabelWrapperStyle={customLabelWrapperStyle}
        css={(props) => props?.customLabelWrapperStyle?.(props)}
      >
        {label && (
          <InputLabel
            error={error}
            disbled={disabled}
            customLabelStyle={customLabelStyle}
            css={(props) => props?.customLabelStyle?.(props)}
          >
            {label}
          </InputLabel>
        )}
        {rightSideLabelContent && (
          <RightSideLabelWrapper>{rightSideLabelContent}</RightSideLabelWrapper>
        )}
      </LabelWrapper>
      <TextAreaStyled
        placeholder={placeholder}
        value={value}
        error={error}
        onChange={onChange}
        disabled={disabled}
        css={(props) => props?.customTextAreaStyle?.(props)}
        customTextAreaStyle={customTextAreaStyle}
        {...otherProps}
        ref={ref}
        data-testid={dataTestId}
      />
      {!hideBottomSpace ? (
        <InputError error={error} helperText={helperText} />
      ) : null}
    </Container>
  )
);

TextArea.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  customContainerStyle: PropTypes.func,
  customLabelStyle: PropTypes.func,
  customTextAreaStyle: PropTypes.func,
  customLabelWrapperStyle: PropTypes.func,
  hideBottomSpace: PropTypes.bool,
  dataTestId: PropTypes.string,
  rightSideLabelContent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.bool,
  ]),
};

TextArea.defaultProps = {
  label: null,
  placeholder: '',
  value: '',
  onChange: null,
  error: false,
  helperText: '',
  disabled: false,
  customContainerStyle: null,
  customLabelStyle: null,
  customTextAreaStyle: null,
  customLabelWrapperStyle: null,
  hideBottomSpace: false,
  dataTestId: null,
  rightSideLabelContent: '',
};

TextArea.displayName = 'TextArea';

export default TextArea;
