import { UPDATE_APPLICATION_STATE } from '../actions';

const DEFAULT_STATE = {
  userLoadedFromDistributor: false,
  userLoadTime: null,
  applicationLoaded: false,
  applicationLoadTime: null,
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case UPDATE_APPLICATION_STATE:
      return {
        ...state,
        ...action.applicationState,
      };

    default:
      return state;
  }
}
