import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import THEMES from '../../styles/themes/app';

const FileIconBox = styled.svg`
  & path {
    fill: ${THEMES.FOREGROUND_LOW} !important;
    fill-rule: nonzero;
    stroke: none;
  }

  & rect {
    width: 76px;
    height: 41px;
    transform: translate(28px, 41px);
    fill: ${THEMES.FOREGROUND_LOW} !important;
    fill-rule: nonzero;
    stroke: none;
  }

  & text {
    transform: translate(65px, 70px);
    text-anchor: middle;
    text-transform: uppercase;
    fill: ${THEMES.COLORED_FOREGROUND_HIGH}!important;
    font-size: 24px;
  }
`;

const FileIcon = ({ width, height, extension }) => (
  <FileIconBox viewBox="0 0 104 100" width={width} height={height}>
    <g>
      <rect rx="7" />
      <text>{extension}</text>
    </g>
    <path d="M46.585,0 L46.585,22.293 C46.585,24.352 48.2,26.019 50.135,26.016 L68.086,25.988 L46.585,0 Z" />
    <path d="M68.085,85.467 L68.085,92.911 C68.085,97.028 64.882,100.34 60.929,100.347 L7.156,100.434 C3.189,100.439 0,97.102 0,92.983 L0.001,7.539 C0.001,3.416 3.223,0.072 7.2,0.066 L43.004,0.01 L43.004,22.322 C43.004,26.452 46.221,29.744 50.193,29.737 L68.09,29.71 L68.09,37.144 L35.861,37.196 C29.909,37.206 25.085,42.201 25.085,48.36 L25.085,74.39 C25.085,80.546 29.873,85.531 35.861,85.522 L68.088,85.471 L68.085,85.467 Z" />
  </FileIconBox>
);

FileIcon.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  extension: PropTypes.string.isRequired,
};

export default FileIcon;
