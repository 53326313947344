import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { contactName } from 'client-lib/src/lib/utils/helpers';
import Sound from '../Sound/Sound';

class Notifications extends Component {
  state = {
    hasNotifiedOnLoad: false,
  };

  componentDidMount() {
    if ('Notification' in window && Notification.requestPermission)
      Notification.requestPermission();

    this.notificationInterval = setInterval(
      this.generateNotificationFromQueue,
      this.props.session.currentUser.notificationInterval * 1000
    );
    this.visibleNotification = null;
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const { notificationQueue } = nextProps;
    const { notificationQueue: oldNotificationQueue, queryPagesLoaded } =
      this.props;
    const { hasNotifiedOnLoad } = this.state;

    if (queryPagesLoaded || !hasNotifiedOnLoad) {
      if (notificationQueue.length === oldNotificationQueue.length) {
        for (let i = 0; i < notificationQueue.length; i += 1) {
          for (let j = 0; j < oldNotificationQueue.length; j += 1) {
            const nextUpdatedAt =
              notificationQueue[i].latestMessage !== null
                ? notificationQueue[i].latestMessage.updatedAt
                : notificationQueue[i].insertedAt;
            const updatedAt =
              oldNotificationQueue[i].latestMessage !== null
                ? oldNotificationQueue[i].latestMessage.updatedAt
                : oldNotificationQueue[i].insertedAt;

            if (
              notificationQueue[i].id === oldNotificationQueue[j].id &&
              nextUpdatedAt !== updatedAt
            ) {
              if (!document.hasFocus()) {
                this.generateNotification(notificationQueue);
              } else {
                this.generateSound();
              }
              this.createNotificationInterval();
            }
          }
        }
      } else if (notificationQueue.length > oldNotificationQueue.length) {
        if (!document.hasFocus()) {
          this.generateNotification(notificationQueue);
        } else {
          this.generateSound();
        }
        this.createNotificationInterval();
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { notificationSound, notificationInterval } =
      this.props.session.currentUser;
    if (notificationSound !== prevProps.session.currentUser.notificationSound) {
      this.sound.loadAudio();
    }
    if (
      notificationInterval !==
      prevProps.session.currentUser.notificationInterval
    ) {
      this.createNotificationInterval();
    }
  }

  componentWillUnmount() {
    clearInterval(this.notificationInterval);

    if (this.visibleNotification) {
      this.visibleNotification.close();
    }
  }

  createNotificationInterval = () => {
    if (this.notificationInterval) {
      clearInterval(this.notificationInterval);
    }

    this.notificationInterval = setInterval(
      this.generateNotificationFromQueue,
      this.props.session.currentUser.notificationInterval * 1000
    );
  };

  generateNotificationFromQueue = () => {
    const { notificationQueue } = this.props;
    if (notificationQueue.length > 0) {
      if (!document.hasFocus()) {
        this.generateNotification(notificationQueue);
      } else {
        this.generateSound();
      }
    }
  };

  generateNotification = (notificationQueue) => {
    // in ios browsers, the Notification global variable is non-existent.
    if (!('Notification' in window)) return;

    const currentThread = notificationQueue[0];
    const from = contactName(currentThread?.externalContact);

    let notificationURL = '';
    let notificationTitle = '';
    const body = `Message from ${from}`;
    const tag = `${currentThread.id}`;
    const icon = '/images/notification-icon.png';

    const options = {
      tag,
      body,
      icon,
      lang: 'en',
      dir: 'ltr',
    };

    if (!currentThread.ownerContact && currentThread.type !== 'internal') {
      notificationURL = `/threads/inbox/${currentThread.id}`;
    } else {
      notificationURL = `/threads/open/${currentThread.id}`;
    }
    if (notificationQueue.length === 1) {
      notificationTitle = 'You have 1 message waiting.';
    } else {
      notificationTitle = `You have ${notificationQueue.length} messages waiting.`;
    }

    // If existing notication, make sure we close it so we can show
    // another one without messing up the reference to this current window.
    if (this.visibleNotification) {
      this.visibleNotification.close();
    }

    const notification = new Notification(notificationTitle, options);

    notification.onclick = (e) => {
      window.focus();
      e.target.close();

      if (currentThread.ownerContact !== null) {
        this.props.markThreadReadMutation({
          variables: {
            input: {
              readAt: new Date().toISOString(),
              threadId: currentThread.id,
            },
          },
        });
      }
      this.props.history.push(notificationURL);
    };

    notification.onclose = (e) => {
      if (this.visibleNotification === e.target) {
        this.visibleNotification = null;
      }
    };

    notification.onshow = () => {
      this.generateSound();
    };

    // Keep track of the notification that we just created.
    this.visibleNotification = notification;
  };

  generateSound = () => {
    this.sound.playSound();

    // we only want one chime/notification on load, turn off ability to chime multiple times on load, until all threads pages are loaded.
    if (!this.state.hasNotifiedOnLoad) {
      this.setState({ hasNotifiedOnLoad: true });
    }
  };

  render() {
    const { notificationSound } = this.props.session.currentUser;
    return (
      <Sound
        routeSound={notificationSound}
        ref={(node) => {
          this.sound = node;
        }}
      />
    );
  }
}

Notifications.propTypes = {
  notificationQueue: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  markThreadReadMutation: PropTypes.func.isRequired,
  session: PropTypes.object.isRequired,
  queryPagesLoaded: PropTypes.bool.isRequired,
};

export default Notifications;
