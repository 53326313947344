import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import i18n from 'i18n-js';
import THEMES from '../../../styles/themes/app';
import InfiniteScroll from '../../../components/InfiniteScroll/InfiniteScroll';
import UniversalSuggestion, {
  INFINITE_SUGGEST_ROW_HEIGHT,
} from './UniversalSuggestion';
import { EmphasisText, Loading, Button } from '../..';
import SuggestionTabs from './SuggestionTabs/SuggestionTabs';

const calcSuggestionWrapperHeight = (props) =>
  props.heightMultiplier * INFINITE_SUGGEST_ROW_HEIGHT +
  (props.isTabbed ? 35 : 0) +
  (props.placeholderElementRendering ? INFINITE_SUGGEST_ROW_HEIGHT * 3 : 0);

const calcHiddenBottomSpace = (num, hiddenBottomSpace) =>
  hiddenBottomSpace ? num : num - 32;

const calcHiddenBottomSpaceFlipped = (num, hiddenBottomSpace) =>
  hiddenBottomSpace ? num : num + 32;

const calcBottomForOptionsOnTop = (num, hiddenBottomSpace, displaySticky) => {
  let bottom = calcHiddenBottomSpaceFlipped(num, hiddenBottomSpace);

  if (displaySticky) {
    bottom += INFINITE_SUGGEST_ROW_HEIGHT;
  }

  return bottom;
};

const SuggestionsWrapper = styled.div`
  position: absolute;
  top: ${(props) =>
    props.flipOptions
      ? 'auto'
      : `${calcHiddenBottomSpace(0, props.hiddenBottomSpace)}px`};
  bottom: ${(props) =>
    props.flipOptions
      ? `${calcBottomForOptionsOnTop(
          40,
          props.hiddenBottomSpace,
          props.displaySticky
        )}px`
      : 'auto'};
  left: 0;
  width: 100%;
  height: ${(props) => props.wrapperHeight}px;
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  display: ${(props) => (props.open ? 'block' : 'none')};
  border: 1px solid ${THEMES.BORDER_COLOR};
  z-index: 1;
`;

const LoadingWrapper = styled.div`
  height: ${INFINITE_SUGGEST_ROW_HEIGHT * 3}px;
  width: 100%;
  display: flex;
  gap: 0;
  align-items: center;
  justify-content: center;
  background-color: ${THEMES.BACKGROUND_PRIMARY};
`;

const StickyButtonWrapper = styled.div`
  height: ${INFINITE_SUGGEST_ROW_HEIGHT}px;
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  position: absolute;
  top: ${(props) =>
    props.flipOptions
      ? 'auto'
      : `${calcHiddenBottomSpace(
          props.wrapperHeight,
          props.hiddenBottomSpace
        )}px`};

  bottom: ${(props) =>
    props.flipOptions
      ? `${calcHiddenBottomSpaceFlipped(40, props.hiddenBottomSpace)}px`
      : 'auto'};
  width: 100%;
  border: 1px solid ${THEMES.BORDER_COLOR};
  z-index: 1;
  display: flex;
  align-items: center;
`;

const customStickyButtonStyles = (props) => `
  background-color: ${THEMES.BACKGROUND_PRIMARY(props)};
  width: 100%;
  height: 100%;
  justify-content: flex-start;

  :hover {
    background-color: ${THEMES.BACKGROUND_PRIMARY(props)};
  }
`;

const ButtonTextSpan = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 22px;
`;

const InfiniteSuggestions = ({
  open,
  loading,
  suggestionRowAmount,
  suggestions,
  pageInfo,
  loadMoreRows,
  onSelect,
  includePresence,
  includeGroupIndicator,
  focusedSuggestionKey,
  isDisabled,
  isTabbed,
  tabLabels,
  activeTabIndex,
  setActiveTabIndex,
  hiddenBottomSpace,
  rightAlignedText,
  flipOptions,
  displayStickyButton,
  stickyButtonText,
  onStickyButtonClick,
  displayAddPhoneNumberButton,
}) => {
  const wrapperHeightMultiplier =
    suggestions?.length >= suggestionRowAmount
      ? suggestionRowAmount
      : suggestions?.length;

  const wrapperHeight = calcSuggestionWrapperHeight({
    heightMultiplier: wrapperHeightMultiplier,
    isTabbed,
    placeholderElementRendering: !suggestions.length,
  });

  const displaySticky = displayStickyButton?.(suggestions);

  return (
    <>
      <SuggestionsWrapper
        open={open}
        wrapperHeight={wrapperHeight}
        hiddenBottomSpace={hiddenBottomSpace}
        displaySticky={displaySticky}
        flipOptions={flipOptions}
      >
        {isTabbed ? (
          <SuggestionTabs
            tabLabels={tabLabels}
            activeTabIndex={activeTabIndex}
            setActiveTabIndex={setActiveTabIndex}
          />
        ) : null}
        {loading && !suggestions.length ? (
          <LoadingWrapper>
            <Loading size="xs" />
          </LoadingWrapper>
        ) : null}
        {!loading && !suggestions.length ? (
          <LoadingWrapper>
            <EmphasisText>
              {i18n.t('customers-InfiniteScroll-noResultsFound')}
            </EmphasisText>
          </LoadingWrapper>
        ) : null}
        <InfiniteScroll
          bidirectionalScroll={false}
          scrollableList={suggestions}
          height={INFINITE_SUGGEST_ROW_HEIGHT * wrapperHeightMultiplier}
          noRowsElement={<div />}
          loading={false}
          renderRow={({ list, index }) => (
            <UniversalSuggestion
              key={list[index].id}
              suggestion={list[index]}
              onSelect={onSelect}
              rightAlignedText={rightAlignedText?.(list[index])}
              includePresence={includePresence}
              includeGroupIndicator={includeGroupIndicator}
              isFocused={index === focusedSuggestionKey}
              disabled={isDisabled?.(list[index])}
              displayAddPhoneNumberButton={displayAddPhoneNumberButton}
            />
          )}
          hasNextPage={pageInfo?.hasNextPage}
          loadingBorderBottom={false}
          loadingHeight={INFINITE_SUGGEST_ROW_HEIGHT} // plus one for border
          listItemHeight={INFINITE_SUGGEST_ROW_HEIGHT} // plus one for border
          loadMoreRows={loadMoreRows}
          scrollToIndex={focusedSuggestionKey}
          scrollToAlignment="auto"
        />
      </SuggestionsWrapper>
      {open && displaySticky ? (
        <StickyButtonWrapper
          wrapperHeight={wrapperHeight}
          hiddenBottomSpace={hiddenBottomSpace}
          onClick={onStickyButtonClick}
          flipOptions={flipOptions}
        >
          <Button type="link" noOutline customStyle={customStickyButtonStyles}>
            <ButtonTextSpan>{stickyButtonText}</ButtonTextSpan>
          </Button>
        </StickyButtonWrapper>
      ) : null}
    </>
  );
};

InfiniteSuggestions.propTypes = {
  open: PropTypes.bool,
  suggestionRowAmount: PropTypes.number.isRequired,
  suggestions: PropTypes.array,
  pageInfo: PropTypes.object,
  loadMoreRows: PropTypes.func,
  onSelect: PropTypes.func,
  includePresence: PropTypes.bool,
  includeGroupIndicator: PropTypes.bool,
  focusedSuggestionKey: PropTypes.number,
  isDisabled: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  isTabbed: PropTypes.bool,
  tabLabels: PropTypes.array,
  activeTabIndex: PropTypes.number,
  setActiveTabIndex: PropTypes.func,
  hiddenBottomSpace: PropTypes.bool,
  flipOptions: PropTypes.bool,
  displayStickyButton: PropTypes.func,
  stickyButtonText: PropTypes.string,
  onStickyButtonClick: PropTypes.func,
  rightAlignedText: PropTypes.func,
  displayAddPhoneNumberButton: PropTypes.bool,
};

InfiniteSuggestions.defaultProps = {
  open: false,
  suggestions: [],
  pageInfo: {},
  loadMoreRows: () => {},
  onSelect: () => {},
  includePresence: false,
  includeGroupIndicator: false,
  focusedSuggestionKey: null,
  isDisabled: undefined,
  isTabbed: false,
  tabLabels: undefined,
  activeTabIndex: undefined,
  setActiveTabIndex: undefined,
  hiddenBottomSpace: false,
  flipOptions: false,
  displayStickyButton: () => false,
  stickyButtonText: '',
  onStickyButtonClick: () => {},
  rightAlignedText: () => {},
  displayAddPhoneNumberButton: true,
};

export default InfiniteSuggestions;
