import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import THEMES from '../../styles/themes/library/button';
import COLORS from '../../styles/colors';
import FONTSIZE_THEMES from '../../styles/themes/fontSize/button';
import ButtonLoadingSpinner from '../Loading/ButtonLoadingSpinner';

const InvisibilityToggleSpan = styled.span`
  visibility: ${(props) => (props.loadingSpinner ? 'hidden' : 'visible')};
  display: contents;
`;

const LoadingWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const getTypeStyles = ({ disabled, ...props }) => ({
  primary: {
    color: THEMES.PRIMARY_TEXT,
    backgroundColor: THEMES.PRIMARY_BACKGROUND,
    borderColor: THEMES.PRIMARY_BORDER,
    hover: {
      color: THEMES.PRIMARY_TEXT_HOVER,
      backgroundColor: THEMES.PRIMARY_BACKGROUND_HOVER,
      borderColor: THEMES.PRIMARY_BORDER_HOVER,
    },
    borderWidth: '1px',
  },
  secondary: {
    backgroundColor: THEMES.SECONDARY_BACKGROUND,
    color: THEMES.SECONDARY_TEXT,
    borderColor: THEMES.SECONDARY_BORDER,
    hover: {
      backgroundColor: THEMES.SECONDARY_BACKGROUND_HOVER,
      borderColor: THEMES.SECONDARY_BORDER_HOVER,
      color: THEMES.SECONDARY_TEXT_HOVER,
    },
    borderWidth: '2px',
  },
  secondaryLight: {
    backgroundColor: THEMES.SECONDARY_LIGHT_BACKGROUND,
    color: THEMES.SECONDARY_LIGHT_TEXT,
    borderColor: THEMES.SECONDARY_LIGHT_BORDER,
    hover: {
      backgroundColor: THEMES.SECONDARY_LIGHT_BACKGROUND_HOVER,
      borderColor: THEMES.SECONDARY_LIGHT_BORDER_HOVER,
      color: THEMES.SECONDARY_LIGHT_TEXT_HOVER,
    },
    borderWidth: '2px',
  },
  tertiary: {
    color: THEMES.TERTIARY_TEXT,
    backgroundColor: THEMES.TERTIARY_BACKGROUND,
    borderColor: THEMES.TERTIARY_BORDER,
    hover: {
      color: THEMES.TERTIARY_TEXT_HOVER,
      backgroundColor: THEMES.TERTIARY_BACKGROUND_HOVER,
      borderColor: THEMES.TERTIARY_BORDER_HOVER,
    },
    borderWidth: '1px',
  },
  destructive: {
    color: THEMES.DESTRUCTIVE_TEXT,
    backgroundColor: THEMES.DESTRUCTIVE_BACKGROUND,
    borderColor: THEMES.DESTRUCTIVE_BORDER,
    hover: {
      color: THEMES.DESTRUCTIVE_TEXT_HOVER,
      backgroundColor: THEMES.DESTRUCTIVE_BACKGROUND_HOVER,
      borderColor: THEMES.DESTRUCTIVE_BORDER_HOVER,
    },
    borderWidth: '1px',
  },
  destructiveSecondary: {
    color: THEMES.DESTRUCTIVE_SECONDARY_TEXT,
    backgroundColor: THEMES.DESTRUCTIVE_SECONDARY_BACKGROUND,
    borderColor: THEMES.DESTRUCTIVE_SECONDARY_BORDER,
    hover: {
      color: THEMES.DESTRUCTIVE_SECONDARY_TEXT_HOVER,
      backgroundColor: THEMES.DESTRUCTIVE_SECONDARY_BACKGROUND_HOVER,
      borderColor: THEMES.DESTRUCTIVE_SECONDARY_BORDER_HOVER,
    },
    borderWidth: '2px',
  },
  buttonSelector: {
    color: THEMES.SELECTOR_TEXT,
    backgroundColor: THEMES.SELECTOR_BACKGROUND,
    borderColor: THEMES.SELECTOR_BORDER,
    hover: {
      color: THEMES.SELECTOR_TEXT_HOVER,
      backgroundColor: THEMES.SELECTOR_BACKGROUND_HOVER,
      borderColor: THEMES.SELECTOR_BORDER_HOVER,
    },
    borderWidth: '1px',
  },
  destructiveLink: {
    color: disabled
      ? `${THEMES.DISABLED_LINK_TEXT(props)} !important`
      : THEMES.DESTRUCTIVE_LINK_TEXT,
    backgroundColor: disabled
      ? `${THEMES.DISABLED_LINK_BACKGROUND(props)} !important`
      : THEMES.LINK_BACKGROUND,
    borderColor: disabled
      ? `${THEMES.DISABLED_LINK_BORDER(props)} !important`
      : THEMES.LINK_BORDER,
    hover: {
      color: disabled
        ? `${THEMES.DISABLED_LINK_TEXT(props)} !important`
        : THEMES.DESTRUCTIVE_LINK_TEXT_HOVER,
      backgroundColor: disabled
        ? `${THEMES.DISABLED_LINK_BACKGROUND(props)} !important`
        : THEMES.LINK_BACKGROUND_HOVER,
      borderColor: disabled
        ? `${THEMES.DISABLED_LINK_BORDER(props)} !important`
        : THEMES.LINK_BORDER_HOVER,
      textDecoration: disabled ? 'none' : 'underline',
    },
    borderWidth: '0',
  },
  link: {
    color: disabled
      ? `${THEMES.DISABLED_LINK_TEXT(props)} !important`
      : THEMES.SECONDARY_LIGHT_TEXT,
    backgroundColor: disabled
      ? `${THEMES.DISABLED_LINK_BACKGROUND(props)} !important`
      : THEMES.LINK_BACKGROUND,
    borderColor: disabled
      ? `${THEMES.DISABLED_LINK_BORDER(props)} !important`
      : THEMES.LINK_BORDER,
    hover: {
      color: disabled
        ? `${THEMES.DISABLED_LINK_TEXT(props)} !important`
        : THEMES.LINK_TEXT_HOVER,
      backgroundColor: disabled
        ? `${THEMES.DISABLED_LINK_BACKGROUND(props)} !important`
        : THEMES.LINK_BACKGROUND_HOVER,
      borderColor: disabled
        ? `${THEMES.DISABLED_LINK_BORDER(props)} !important`
        : THEMES.LINK_BORDER_HOVER,
      textDecoration: disabled ? 'none' : 'underline',
    },
    borderWidth: '0',
  },
  noStyle: {
    color: disabled
      ? `${THEMES.DISABLED_LINK_TEXT(props)} !important`
      : THEMES.NO_STYLE_TEXT,
    backgroundColor: disabled
      ? `${THEMES.DISABLED_LINK_BACKGROUND(props)} !important`
      : THEMES.NO_STYLE_BACKGROUND,
    borderColor: disabled
      ? `${THEMES.DISABLED_LINK_BORDER(props)} !important`
      : THEMES.NO_STYLE_BORDER,
    hover: {
      color: disabled
        ? `${THEMES.DISABLED_LINK_TEXT(props)} !important`
        : THEMES.NO_STYLE_TEXT_HOVER,
      backgroundColor: disabled
        ? `${THEMES.DISABLED_LINK_BACKGROUND(props)} !important`
        : THEMES.NO_STYLE_BACKGROUND_HOVER,
      borderColor: disabled
        ? `${THEMES.DISABLED_LINK_BORDER(props)} !important`
        : THEMES.NO_STYLE_BORDER_HOVER,
    },
    borderWidth: '0',
  },
});

const sizeStyles = {
  zero: {
    padding: '0',
    fontSize: FONTSIZE_THEMES.BUTTON_ZERO,
    borderRadius: '1px',
    weight: '600',
    height: null,
    iconSize: '14px',
  },
  sm: {
    padding: '0 8px',
    fontSize: FONTSIZE_THEMES.BUTTON_SM,
    borderRadius: '2px',
    weight: '600',
    height: '24px',
    iconSize: '14px',
  },
  md: {
    padding: '0 16px',
    fontSize: FONTSIZE_THEMES.BUTTON_MD,
    borderRadius: '2px',
    weight: '700',
    height: '32px',
    iconSize: '15px',
  },
  lg: {
    padding: '0 16px',
    fontSize: FONTSIZE_THEMES.BUTTON_LG,
    borderRadius: '4px',
    weight: '700',
    height: '40px',
    iconSize: '16px',
  },
};

// I took out width: 100%; because I felt that css property should be left to props passed by parents (it is very situational).
export const ButtonStyled = styled.button`
  text-align: center;
  border-style: solid;
  white-space: nowrap;
  font-family: 'Barlow', sans-serif;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  color: ${(props) => getTypeStyles(props)[props.type].color};
  background-color: ${(props) =>
    getTypeStyles(props)[props.type].backgroundColor};
  border-color: ${(props) => getTypeStyles(props)[props.type].borderColor};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  padding: ${(props) => sizeStyles[props.size].padding};
  font-size: ${(props) => sizeStyles[props.size].fontSize};
  border-width: ${(props) => getTypeStyles(props)[props.type].borderWidth};
  border-radius: ${(props) => sizeStyles[props.size].borderRadius};
  font-weight: ${(props) => sizeStyles[props.size].weight};
  height: ${(props) => sizeStyles[props.size].height};
  line-height: ${(props) => sizeStyles[props.size].fontSize};

  :hover {
    text-decoration: ${(props) =>
      getTypeStyles(props)[props.type]?.hover?.textDecoration};
    color: ${(props) => getTypeStyles(props)[props.type]?.hover?.color};
    background-color: ${(props) =>
      getTypeStyles(props)[props.type]?.hover?.backgroundColor};
    border-color: ${(props) =>
      getTypeStyles(props)[props.type]?.hover?.borderColor};
  }
  :focus {
    outline: none;
    box-shadow: 0 0 0 3px ${(props) =>
      props.noOutline ? COLORS.PK_CLASSIC.TRANSPARENT : THEMES.BUTTON_OUTLINE};
  }
  :disabled {
    color: ${THEMES.DISABLED_TEXT};
    background-color: ${THEMES.DISABLED_BACKGROUND};
    border-color: ${THEMES.DISABLED_BORDER};
    border-width: 1px;
  }

  & i {
    font-size: ${(props) => sizeStyles[props.size].iconSize};
    line-height: ${(props) => sizeStyles[props.size].iconSize};
    font-weight: normal;
    margin-${(props) => (props.iconRight ? 'left' : 'right')}: ${(props) =>
      props.iconAndText ? '8px' : 'none'};
  }
`;

const Button = React.forwardRef(
  (
    {
      type,
      size,
      disabled,
      noOutline,
      iconAndText,
      iconRight,
      dataTestId,
      loadingSpinner,
      children,
      ...otherProps
    },
    ref
  ) => (
    <ButtonStyled
      type={type}
      size={size}
      disabled={disabled}
      noOutline={noOutline}
      iconAndText={iconAndText}
      iconRight={iconRight}
      css={(props) => props?.customStyle?.(props)} // eslint-disable-line react/prop-types
      {...otherProps}
      data-testid={dataTestId}
      ref={ref}
    >
      {loadingSpinner && (
        <LoadingWrapper>
          <ButtonLoadingSpinner />
        </LoadingWrapper>
      )}
      <InvisibilityToggleSpan loadingSpinner={loadingSpinner}>
        {children}
      </InvisibilityToggleSpan>
    </ButtonStyled>
  )
);

Button.propTypes = {
  type: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  noOutline: PropTypes.bool,
  iconAndText: PropTypes.bool,
  iconRight: PropTypes.bool,
  dataTestId: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]),
  loadingSpinner: PropTypes.bool,
};

Button.defaultProps = {
  type: 'primary',
  size: 'lg',
  disabled: false,
  noOutline: false,
  iconAndText: false,
  iconRight: false,
  dataTestId: null,
  loadingSpinner: false,
  children: '',
};

Button.displayName = 'Button';

// export default React.forwardRef(Button);
export default Button;
