import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Chip from '../../Chip/Chip';

const MultiSelectOptionsContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 8px;
  flex-wrap: wrap;
`;

const ChipContainer = styled.div`
  margin-right: 8px;
  margin-bottom: 8px;
`;

const MultiSelectDeletableOptions = ({
  options,
  onDelete,
  multiSelectedChipProps,
  disabled,
}) =>
  options?.length ? (
    <MultiSelectOptionsContainer>
      {options.map((option) => (
        <ChipContainer key={option?.value || option?.id}>
          <Chip
            {...multiSelectedChipProps}
            onDelete={
              disabled ? null : (e) => onDelete?.(e, { suggestion: option })
            }
          >
            {option.text || option.name}
          </Chip>
        </ChipContainer>
      ))}
    </MultiSelectOptionsContainer>
  ) : null;

MultiSelectDeletableOptions.propTypes = {
  options: PropTypes.array,
  onDelete: PropTypes.func,
  multiSelectedChipProps: PropTypes.object,
  disabled: PropTypes.bool,
};

MultiSelectDeletableOptions.defaultProps = {
  options: [],
  onDelete: () => {},
  multiSelectedChipProps: null,
  disabled: false,
};

export default MultiSelectDeletableOptions;
