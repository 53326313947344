export const UPDATE_SESSION = 'UPDATE_SESSION';
export const UPDATE_STOCK_MESSAGES = 'UPDATE_STOCK_MESSAGES';
export const UPDATE_DESKTOP_NOTIFICATION_SETTINGS =
  'UPDATE_DESKTOP_NOTIFICATION_SETTINGS';
export const UPDATE_EMAIL_NOTIFICATION_SETTINGS =
  'UPDATE_EMAIL_NOTIFICATION_SETTINGS';
export const CHANGE_THREADS_ACTIVE_GROUP = 'CHANGE_THREADS_ACTIVE_GROUP';
export const SET_THREADS_ACTIVE_GROUP_IDS = 'SET_THREADS_ACTIVE_GROUP_IDS';
export const SET_DASHBOARDS_ACTIVE_GROUP_IDS =
  'SET_DASHBOARDS_ACTIVE_GROUP_IDS';
export const SET_FEEDBACK_ACTIVE_GROUP_IDS = 'SET_FEEDBACK_ACTIVE_GROUP_IDS';
export const CHANGE_SOUND_INTERVAL = 'CHANGE_SOUND_INTERVAL';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS';
export const SET_USER_ACCOUNT_POLICIES = 'SET_USER_ACCOUNT_POLICIES';
export const SET_USER_ACCOUNT_POLICIES_QUERIED =
  'SET_USER_ACCOUNT_POLICIES_QUERIED';
export const UPDATE_THREAD_NOTIFY_PREF = 'UPDATE_THREAD_NOTIFY_PREF';

export const updateSession = (payload) => ({
  type: UPDATE_SESSION,
  payload,
});

export const setUserAccountPolicies = (userAccountPolicies) => ({
  type: SET_USER_ACCOUNT_POLICIES,
  userAccountPolicies,
});

export const setUserAccountPoliciesQueried = (policiesQueried) => ({
  type: SET_USER_ACCOUNT_POLICIES_QUERIED,
  policiesQueried,
});

export const updateStockMessages = (payload) => ({
  type: UPDATE_STOCK_MESSAGES,
  payload,
});

export const updateDesktopNotificationSettings = (payload) => ({
  type: UPDATE_DESKTOP_NOTIFICATION_SETTINGS,
  payload,
});

export const updateEmailNotificationSettings = (payload) => ({
  type: UPDATE_EMAIL_NOTIFICATION_SETTINGS,
  payload,
});

export const changeThreadsActiveGroup = (groupId) => ({
  type: CHANGE_THREADS_ACTIVE_GROUP,
  groupId,
});

export const setThreadsActiveGroupIds = (groupIds) => ({
  type: SET_THREADS_ACTIVE_GROUP_IDS,
  groupIds,
});

export const setDashboardsActiveGroupIds = (groupIds) => ({
  type: SET_DASHBOARDS_ACTIVE_GROUP_IDS,
  groupIds,
});

export const setFeedbackActiveGroupIds = (groupIds) => ({
  type: SET_FEEDBACK_ACTIVE_GROUP_IDS,
  groupIds,
});

export const setUserSettings = (payload) => ({
  type: SET_USER_SETTINGS,
  payload,
});

export const changePasswordSuccess = (payload) => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload,
});

export const changePasswordFailure = (payload) => ({
  type: CHANGE_PASSWORD_FAILURE,
  payload,
});

export const updateThreadNotifyPref = (payload) => ({
  type: UPDATE_THREAD_NOTIFY_PREF,
  payload,
});
