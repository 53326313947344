import { useLazyQuery } from '@apollo/client';
import { FEEDBACK_SELECTED_GROUPS_QUERY } from 'client-lib';
import { diff } from 'deep-diff';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFeedbackActiveGroupIds } from '../../actions/session';

const useOnLoadQueryFeedbackSelectedGroups = () => {
  const currentUserId = useSelector(
    (state) => state.session?.currentUser?.userId
  );
  const feedbackActiveGroupIds = useSelector(
    (state) => state.session?.feedbackActiveGroupIds
  );
  const dispatch = useDispatch();

  const onCompleted = (data) => {
    const selectedGroups = data?.feedbackSelectedGroups?.groupIds;
    let groupsDiff;

    if (selectedGroups) {
      groupsDiff = diff(selectedGroups, feedbackActiveGroupIds);
    }

    if (groupsDiff && selectedGroups?.length) {
      dispatch(setFeedbackActiveGroupIds(selectedGroups));
    }
  };

  const [selectedGroupsQuery] = useLazyQuery(FEEDBACK_SELECTED_GROUPS_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onCompleted,
  });

  // only query on mount, let the group selector worry about updates.
  useEffect(() => {
    if (currentUserId) selectedGroupsQuery();
  }, [currentUserId]);
};

export default useOnLoadQueryFeedbackSelectedGroups;
