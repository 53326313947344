import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import OPEN_THREAD_COUNT_FOR_USER_BY_GROUP from "../../../graphql/query/OpenThreadCountForUserByGroup.graphql";
import {
  DEFAULT_DASHBOARD_FLYOUT_ROW_COUNT,
  getOpenThreadCountUpperBound,
} from "../../../utils/helpers";

const TOP_N = DEFAULT_DASHBOARD_FLYOUT_ROW_COUNT;

function useOpenThreadsFlyoutWidget({ client, topN, groupIds }) {
  const {
    data: openThreadCountByGroupData,
    loading: openThreadCountByGroupLoading,
  } = useQuery(OPEN_THREAD_COUNT_FOR_USER_BY_GROUP, {
    client,
    variables: { topN: topN || TOP_N, groupIds },
    fetchPolicy: "network-only",
  });

  const openThreadCountByGroup =
    openThreadCountByGroupData?.openThreadCountForUserByGroup;
  const widgetsLoading = openThreadCountByGroupLoading;

  const openThreadCountUpperBound = useMemo(
    () => getOpenThreadCountUpperBound(openThreadCountByGroup),
    [openThreadCountByGroup]
  );

  return {
    openThreadCountUpperBound,
    openThreadCountByGroup,
    widgetsLoading,
  };
}

export default useOpenThreadsFlyoutWidget;
