import React from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from 'styled-theming';
import COLORS from '../../styles/colors';
import THEMES from '../../styles/themes/app';
import INPUT_THEMES from '../../styles/themes/library/textInput';

const TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.SECONDARY_BLACK,
  light: COLORS.PK.BLACK,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_HIGH,
  holiday: COLORS.PK.BLACK,
});

const StyledToolTip = styled(ReactTooltip)`
  &.__react_component_tooltip {
    background-color: ${THEMES.BACKGROUND_PRIMARY};
    color: ${TEXT};
    border-radius: 4px;
    opacity: 1 !important;
    padding-bottom: 10px;

    &.border,
    &:after {
      border-color: ${INPUT_THEMES.BORDER} !important;
      box-shadow: ${THEMES.BOX_SHADOW};
    }
  }
  ${(props) => props?.customStyle?.(props)};
`;

const Tooltip = ({
  children,
  place,
  elementsContent,
  dataFor,
  customStyle,
  disabled,
  ...props
}) => (
  <>
    {children}
    {!disabled && (
      <StyledToolTip
        place={place}
        id={dataFor}
        effect="solid"
        border
        type="light"
        customStyle={customStyle}
        multiline
        {...props}
      >
        {elementsContent}
      </StyledToolTip>
    )}
  </>
);

Tooltip.propTypes = {
  place: PropTypes.string,
  elementsContent: PropTypes.element.isRequired,
  children: PropTypes.node.isRequired,
  dataFor: PropTypes.string,
  customStyle: PropTypes.func,
  disabled: PropTypes.bool,
};
Tooltip.defaultProps = {
  place: 'top',
  dataFor: null,
  disabled: false,
  customStyle: () => {},
};

export default Tooltip;
