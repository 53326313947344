import GENERATE_UPLOAD_URL from "../../../graphql/mutation/GenerateUploadUrl.graphql";

/*

DOCUMENTATION
  this is a reusable hook that encapsulates our asynchronous announcement flow.

  args: {obj}
    client: (obj) required. Apollo Client instance.

  returns: uploadAttachment promise. will return the uploaded attachment details if successful.

*/

const useUploadAttachment = ({ client }) => {
  const uploadAttachment = (attachment) =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve, reject) => {
      // handle the shit out of the errors
      const handleAsyncAttachError = (err) => {
        reject(err);
      };

      const { data } = await client
        .mutate({
          mutation: GENERATE_UPLOAD_URL,
          variables: {
            input: {
              filename: attachment.originalFilename,
            },
          },
        })
        .catch((err) => {
          handleAsyncAttachError(err);
        });

      // if BE gives thumbs up, keep going
      if (data?.generateUploadUrl?.url) {
        const { url } = data.generateUploadUrl;

        let attachmentUri;

        // Android had an issue fetching the attachment data,
        // since mobile does not currently support annotations
        // this fix works for now.
        if (process.env.PLATFORM === "web") {
          attachmentUri =
            attachment.data ||
            `/attachments/${attachment.id}/original/${attachment.originalFilename}`;
        } else {
          attachmentUri = attachment.uri;
        }

        // convert attachment to blob
        const blob = await fetch(`${attachmentUri}`)
          .then((res) => res.blob())
          .catch((err) => handleAsyncAttachError(err));

        // send attachment to s3 bucket
        const s3Response = await fetch(url, {
          method: "PUT",
          body: blob,
        }).catch((err) => {
          handleAsyncAttachError(err);
        });

        if (s3Response?.status === 200) {
          resolve({ url });
        } else {
          handleAsyncAttachError(s3Response);
        }
      }
    });

  return uploadAttachment;
};

export default useUploadAttachment;
