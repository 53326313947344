import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  contactName,
  accountName,
  accountNumber,
} from 'client-lib/src/lib/utils/helpers';
import { EntityCard } from '../../../elements';
import InfiniteScroll from '../../InfiniteScroll/InfiniteScroll';
import THEMES from '../../../styles/themes/app';

const customTextStyle = (hasError) => `
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${hasError ? '200px' : '360px'};
`;

const customContainerStyle = () => `
  border-bottom: 0px;
`;

const InfiniteScrollContainer = styled.div`
  flex: 1;
  width: 100%;
`;

const BroadcastContactContainer = styled.div`
  align-items: center;
  border-bottom: 1px solid ${THEMES.BORDER_COLOR};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 76px;
  width: 100%;
`;

const EntityCardContainer = styled.div`
  div {
    padding: unset;
  }
`;

/**
 * Helper function for displaying subtext for contact entitycard
 * @param {object} entity - contact object
 * @returns string - account name and/or number
 */
const parseCorrectAccountInfo = (entity) => {
  const name = accountName(entity);
  const number = accountNumber(entity);

  if (name && number) return `${name}\u00A0-\u00A0# ${number}`;
  if (name) return name;
  if (number) return number;

  return '';
};

const BroadcastContactList = ({
  recipientData,
  loadMoreRows,
  setContactSearch,
  RightSideText,
  clientKey,
}) => {
  const hasNextPage =
    recipientData?.[clientKey]?.pageInfo?.hasNextPage || false;
  const hasPreviousPage = recipientData?.[clientKey]?.pageInfo?.hasPreviousPage;
  useEffect(() => {
    return () => {
      setContactSearch('');
    };
  }, []);

  return (
    <InfiniteScrollContainer>
      <InfiniteScroll
        dataTestId="broadcast-recipient-list"
        bidirectionalScroll={false}
        height="100%"
        scrollableList={recipientData?.[clientKey]?.edges || []}
        renderRow={({ list, index }) => {
          const recipient = list[index];
          return (
            <BroadcastContactContainer>
              <EntityCardContainer>
                <EntityCard
                  avatarChildren={contactName(recipient?.node?.contact)}
                  maintext={contactName(recipient?.node?.contact)}
                  subtext={parseCorrectAccountInfo(recipient?.node?.contact)}
                  customSubtextStyle={() => customTextStyle(!!RightSideText)}
                  customContainerStyle={customContainerStyle}
                  customMaintextStyle={() => customTextStyle(!!RightSideText)}
                />
              </EntityCardContainer>
              {RightSideText ? <RightSideText recipient={recipient} /> : null}
            </BroadcastContactContainer>
          );
        }}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        listItemHeight={77}
        loadingHeight={77}
        loadMoreRows={loadMoreRows}
        loading={hasNextPage}
      />
    </InfiniteScrollContainer>
  );
};

BroadcastContactList.propTypes = {
  recipientData: PropTypes.object,
  loadMoreRows: PropTypes.func,
  setContactSearch: PropTypes.func,
  parseCorrectAccountInfo: PropTypes.func,
  RightSideText: PropTypes.element,
  clientKey: PropTypes.string,
};

BroadcastContactList.defaultProps = {
  recipientData: {},
  loadMoreRows: () => {},
  setContactSearch: () => {},
  parseCorrectAccountInfo: () => {},
  RightSideText: null,
  clientKey: 'announcementRecipients',
};

export default BroadcastContactList;
