import React from 'react';
import { useForgotPassword } from 'client-lib';
import { useApolloClient } from '@apollo/client';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import i18n from 'i18n-js';
import ForgotPasswordConfirmation from './ForgotPasswordConfirmation';
import { MessageContainer, Message } from '../../components/Settings/styles';
import { Heading1, Text, Button, TextInput } from '../../elements';
import { Container } from './styles';

const TextWrapper = styled.div`
  margin-bottom: ${(props) => (props.tall ? '24px' : '16px')};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ForgotPassword = () => {
  const client = useApolloClient();
  const history = useHistory();
  const {
    username,
    setUsername,
    usernameRef,
    validationError,
    error,
    complete,
    clearError,
    handleSubmit,
  } = useForgotPassword({ client, i18n });

  const handleKeyPress = (e) => {
    if (e.keyCode === 13 || e.charCode === 13) {
      handleSubmit();
    }
  };

  return (
    <Container data-testid="forgot-password">
      {!complete && (
        <div data-testid="forgot-password-form">
          <TextWrapper>
            <Heading1>
              {i18n.t('signin-ForgotPassword-passwordRecovery')}
            </Heading1>
          </TextWrapper>
          <TextWrapper tall>
            <Text>{i18n.t('signin-ForgotPassword-enterEmail')}</Text>
          </TextWrapper>

          {error && (
            <MessageContainer>
              <Message className="active">{error}</Message>
            </MessageContainer>
          )}
          <div>
            <TextInput
              error={validationError}
              label={i18n.t('signin-SignIn-emailAddress')}
              dataTestId="username"
              value={username}
              ref={usernameRef}
              onKeyPress={handleKeyPress}
              onChange={(e) => {
                clearError();
                setUsername(e.target.value);
              }}
            />
          </div>

          <ButtonWrapper>
            <Button onClick={handleSubmit}>
              {i18n.t('signin-ForgotPassword-submit')}
            </Button>
            <Button type="link" onClick={() => history.push('/login')}>
              {i18n.t('signin-ForgotPassword-back')}
            </Button>
          </ButtonWrapper>
        </div>
      )}
      {complete && <ForgotPasswordConfirmation i18n={i18n} />}
    </Container>
  );
};

export default ForgotPassword;
