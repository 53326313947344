import React, { useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from 'styled-theming';
import Avatar from '../Avatar/Avatar';
import THEMES from '../../styles/themes/app';
import IconButton from '../Button/IconButton';
import COLORS from '../../styles/colors';
import FONTSIZE_THEMES from '../../styles/themes/fontSize/fontSize';

const DELETE_ICON = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_BLUE,
  light: COLORS.PK.GREY_40,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_LOW,
  holiday: COLORS.PK.GREY_40,
});

const TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.SECONDARY_BLACK,
  light: COLORS.PK.GREY_80,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_HIGH,
  holiday: COLORS.PK.GREY_80,
});

const SUB_TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.DARK_GRAY,
  light: COLORS.PK.GREY_80,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_MED,
  holiday: COLORS.PK.GREY_80,
});

const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${THEMES.BORDER_COLOR};
  background-color: ${(props) =>
    props.backgroundColor || THEMES.BACKGROUND_PRIMARY};
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  padding: 16px;
  overflow: hidden;
`;

const Text = styled.div`
  margin: ${(props) => (props.marginBottom ? '0 0 4px 0' : '0')};
  font-size: ${FONTSIZE_THEMES.ENTITYCARD_TEXT};
  font-family: 'Barlow', sans-serif;
  font-weight: 700;
  color: ${(props) => props.textColor || props?.textColor?.(props) || TEXT};
  cursor: ${(props) => (props?.onClick ? 'pointer' : 'default')};
`;

const ENTITY_CARD_SUBTEXT_LINEHEIGHT = theme('fontSize', {
  default: '17px',
  large: '19px',
});

const Subtext = styled.span`
  display: block;
  margin: 0;
  color: ${(props) => props.textColor || props?.textColor?.(props) || SUB_TEXT};
  font-size: ${FONTSIZE_THEMES.ENTITYCARD_SUBTEXT};
  font-family: 'Barlow', sans-serif;
  line-height: ${ENTITY_CARD_SUBTEXT_LINEHEIGHT};
  font-weight: 500;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  cursor: ${(props) => (props?.onClick ? 'pointer' : 'default')};
`;

const TextContainer = styled.div`
  margin-left: 12px;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DeleteIcon = styled.i`
  && {
    font-size: 24px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    color: ${DELETE_ICON};
  }
`;

const AvatarsContainer = styled.div`
  position: relative;
`;

const SmallAvatarContainer = styled.div`
  position: absolute;
  top: 25px;
  left: 20px;
`;

const EntityCard = React.forwardRef(
  (
    {
      // avatar props
      avatarChildren,
      avatarProps,
      overlapAvatarProps,

      // normal props
      maintext,
      subtext,
      backgroundColor,
      maintextColor,
      subtextColor,
      onClick,
      onDelete,
      hideAvatar,
      disabled,
      showOverlapAvatar,
      smallAvatarChildren,
      maintextProps,
      subtextProps,

      // custom styles
      customContainerStyle,
      customMaintextStyle,
      customSubtextStyle,
      customTextContainerStyle,
      customDeleteButtonStyle,
      customDeleteButtonProps,

      // data-testid
      dataTestId,
    },
    ref
  ) => {
    const deleteButton = useRef();

    const handleOnClick = (e) => {
      if (deleteButton?.current?.contains?.(e.target)) return;
      onClick?.(e);
    };

    const protectedAvatarChildren = avatarChildren || '?';

    return (
      <CardWrapper
        ref={ref}
        onClick={onClick && handleOnClick}
        backgroundColor={backgroundColor}
        customContainerStyle={customContainerStyle}
        css={(props) => props?.customContainerStyle?.(props)}
        data-testid={dataTestId}
      >
        {!hideAvatar &&
          (showOverlapAvatar ? (
            <AvatarsContainer>
              <Avatar disabled={disabled} {...avatarProps}>
                {protectedAvatarChildren}
              </Avatar>
              <SmallAvatarContainer>
                <Avatar size="sm" {...overlapAvatarProps}>
                  {smallAvatarChildren}
                </Avatar>
              </SmallAvatarContainer>
            </AvatarsContainer>
          ) : (
            <Avatar disabled={disabled} {...avatarProps}>
              {protectedAvatarChildren}
            </Avatar>
          ))}
        <TextContainer
          customTextContainerStyle={customTextContainerStyle}
          css={(props) => props?.customTextContainerStyle?.(props)}
        >
          <Text
            marginBottom={subtext}
            textColor={disabled ? THEMES.FOREGROUND_LOW : maintextColor}
            customMaintextStyle={customMaintextStyle}
            css={(props) => props?.customMaintextStyle?.(props)}
            {...maintextProps}
          >
            {maintext}
          </Text>
          {subtext && (
            <Subtext
              textColor={disabled ? THEMES.FOREGROUND_LOW : subtextColor}
              customSubtextStyle={customSubtextStyle}
              css={(props) => props?.customSubtextStyle?.(props)}
              {...subtextProps}
            >
              {subtext}
            </Subtext>
          )}
        </TextContainer>

        {onDelete && (
          <IconButton
            size="sm"
            onClick={onDelete}
            ref={deleteButton}
            customStyle={customDeleteButtonStyle}
            {...customDeleteButtonProps}
          >
            <DeleteIcon className="ri-close-fill" />
          </IconButton>
        )}
      </CardWrapper>
    );
  }
);

EntityCard.propTypes = {
  avatarChildren: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  avatarProps: PropTypes.object,
  maintext: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  subtext: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  backgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  maintextColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  subtextColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  hideAvatar: PropTypes.bool,
  customContainerStyle: PropTypes.func,
  customMaintextStyle: PropTypes.func,
  customSubtextStyle: PropTypes.func,
  customTextContainerStyle: PropTypes.func,
  customDeleteButtonStyle: PropTypes.func,
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  showOverlapAvatar: PropTypes.bool,
  overlapAvatarProps: PropTypes.object,
  smallAvatarChildren: PropTypes.string,
  maintextProps: PropTypes.object,
  subtextProps: PropTypes.object,
  customDeleteButtonProps: PropTypes.object,
};

EntityCard.defaultProps = {
  avatarChildren: null,
  avatarProps: null,
  subtext: null,
  backgroundColor: null,
  maintextColor: null,
  subtextColor: null,
  onClick: null,
  onDelete: null,
  hideAvatar: false,
  customContainerStyle: null,
  customMaintextStyle: null,
  customSubtextStyle: null,
  customTextContainerStyle: null,
  customDeleteButtonStyle: null,
  dataTestId: null,
  disabled: false,
  showOverlapAvatar: false,
  overlapAvatarProps: null,
  smallAvatarChildren: '',
  maintextProps: {},
  subtextProps: {},
  customDeleteButtonProps: null,
};

EntityCard.displayName = 'EntityCard';

export default EntityCard;
