import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import UNCLAIMED_THREAD_COUNT_FOR_USER_BY_GROUP from "../../../graphql/query/UnclaimedThreadCountForUserByGroup.graphql";
import UNCLAIMED_THREAD_COUNT_FOR_USER_TOTAL from "../../../graphql/query/UnclaimedThreadCountForUserTotal.graphql";
import {
  DEFAULT_DASHBOARD_WIDGET_ROW_COUNT,
  getUnclaimedThreadCountUpperBound,
} from "../../../utils/helpers";

const TOP_N = DEFAULT_DASHBOARD_WIDGET_ROW_COUNT;

function useUnclaimedThreadsWidget({ client, groupIds }) {
  const {
    data: unclaimedThreadCountTotalData,
    loading: unclaimedThreadCountTotalLoading,
    refetch: refetchUnclaimedThreadCountTotal,
  } = useQuery(UNCLAIMED_THREAD_COUNT_FOR_USER_TOTAL, {
    client,
    variables: {
      groupIds,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: unclaimedThreadCountByGroupData,
    loading: unclaimedThreadCountByGroupLoading,
    refetch: refetchUnclaimedThreadCountByGroup,
  } = useQuery(UNCLAIMED_THREAD_COUNT_FOR_USER_BY_GROUP, {
    client,
    variables: { topN: TOP_N, groupIds },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const unclaimedThreadCountTotal =
    unclaimedThreadCountTotalData?.unclaimedThreadCountForUserTotal?.count;
  const unclaimedThreadCountByGroup =
    unclaimedThreadCountByGroupData?.unclaimedThreadCountForUserByGroup;
  const widgetsLoading =
    unclaimedThreadCountTotalLoading || unclaimedThreadCountByGroupLoading;
  const unclaimedThreadCountGroupIds = unclaimedThreadCountByGroup?.counts?.map(
    (count) => count.groupId
  );

  const unclaimedThreadCountUpperBound = useMemo(
    () => getUnclaimedThreadCountUpperBound(unclaimedThreadCountByGroup),
    [unclaimedThreadCountByGroup]
  );

  const refetchUnclaimedThreads = () => {
    refetchUnclaimedThreadCountTotal();
    refetchUnclaimedThreadCountByGroup();
  };

  return {
    unclaimedThreadCountTotal,
    unclaimedThreadCountUpperBound,
    unclaimedThreadCountByGroup,
    widgetsLoading,
    unclaimedThreadCountGroupIds,
    refetchUnclaimedThreads,
  };
}

export default useUnclaimedThreadsWidget;
