import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import i18n from 'i18n-js';
import Annotations from '.';
import { Modal } from '../../elements';

const AnnotationContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const AnnotationsModal = ({ open, setOpen, attachment, handleSave }) => {
  const getAnnotationSave = useRef();
  const [saved, setSaved] = useState(false);
  const imgSrc =
    attachment?.data ||
    `/attachments/${attachment?.id}/original/${attachment?.originalFilename}`;

  const handleOnSave = (uri) => {
    setSaved(false);
    handleSave(uri);
  };
  return (
    <Modal
      isOpen={open}
      onRequestClose={() => setOpen(false)}
      modalTitle={attachment?.originalFilename}
      primaryButtonText={i18n.t('modals-AddOrEditList-save', {
        defaultValue: 'Save',
      })}
      primaryButtonProps={{
        dataTestId: 'modal-save-btn',
        disabled: saved,
      }}
      primaryButtonOnClick={() => {
        setSaved(true);
        getAnnotationSave.current?.();
      }}
    >
      <AnnotationContainer>
        <Annotations
          getAnnotationSave={getAnnotationSave}
          onSave={handleOnSave}
          imgSrc={imgSrc}
          maxWidth="48vw"
          paletteStyle={{
            top: '65px',
            right: '78px',
            zIndex: 3,
          }}
        />
      </AnnotationContainer>
    </Modal>
  );
};

AnnotationsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  attachment: PropTypes.object,
  handleSave: PropTypes.func.isRequired,
};

AnnotationsModal.defaultProps = {
  attachment: null,
};

export default AnnotationsModal;
