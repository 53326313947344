export const AUTOSUGGEST_ONCHANGE = 'AUTOSUGGEST_ONCHANGE';
export const CLEAR_SUGGESTIONS = 'CLEAR_SUGGESTIONS';
export const CLEAR_SUGGESTION = 'CLEAR_SUGGESTION';
export const CLOSE_EDIT_CUSTOMER_MODAL = 'CLOSE_EDIT_CUSTOMER_MODAL';
export const OPEN_EDIT_CUSTOMER_MODAL = 'OPEN_EDIT_CUSTOMER_MODAL';
export const POPULATE_COMPANY_FIELDS = 'POPULATE_COMPANY_FIELDS';
export const POPULATE_CONTACT_FIELDS = 'POPULATE_CONTACT_FIELDS';
export const POPULATE_SUGGESTIONS = 'POPULATE_SUGGESTIONS';
export const PREPARE_NEW_ACCOUNT = 'PREPARE_NEW_ACCOUNT';
export const SELECT_SUGGESTION = 'SELECT_SUGGESTION';
export const SET_CREATING_ACCOUNT = 'SET_CREATING_ACCOUNT';
export const SET_CREATING_ACCOUNT_FALSE = 'SET_CREATING_ACCOUNT_FALSE';
export const TOGGLE_DELETE_CUSTOMER_CONFIRMATION =
  'TOGGLE_DELETE_CUSTOMER_CONFIRMATION';
export const TOGGLE_DELETE_CUSTOMER_PROMPT = 'TOGGLE_DELETE_CUSTOMER_PROMPT';
export const TRIGGER_EDIT_CONTACT_JOB_TITLE = 'TRIGGER_EDIT_CONTACT_JOB_TITLE';
export const TRIGGER_EDIT_CONTACT_COMPANY = 'TRIGGER_EDIT_CONTACT_COMPANY';
export const TRIGGER_EDIT_COMPANY_ACCOUNT_NUM =
  'TRIGGER_EDIT_COMPANY_ACCOUNT_NUM';
export const TRIGGER_EDIT_CONTACT_LABELS = 'TRIGGER_EDIT_CONTACT_LABELS';
export const SET_DEFAULT_CONTACT_TAB = 'SET_DEFAULT_CONTACT_TAB';
export const SET_DEFAULT_ORGANIZATION_TAB = 'SET_DEFAULT_ORGANIZATION_TAB';
export const OPTIMISTIC_DELETED_COMPANY_IDS = 'OPTIMISTIC_DELETED_COMPANY_IDS';

export const optimisticDeletedCompanyIds = (value) => ({
  type: OPTIMISTIC_DELETED_COMPANY_IDS,
  value,
});

export const triggerEditContactJobTitle = (value) => ({
  type: TRIGGER_EDIT_CONTACT_JOB_TITLE,
  value,
});

export const setDefaultContactTab = (value) => ({
  type: SET_DEFAULT_CONTACT_TAB,
  value,
});

export const setDefaultOrganizationTab = (value) => ({
  type: SET_DEFAULT_ORGANIZATION_TAB,
  value,
});

export const triggerEditContactCompany = (value) => ({
  type: TRIGGER_EDIT_CONTACT_COMPANY,
  value,
});

export const triggerEditContactLabels = (value) => ({
  type: TRIGGER_EDIT_CONTACT_LABELS,
  value,
});

export const triggerEditCompanyAccountNum = (value) => ({
  type: TRIGGER_EDIT_COMPANY_ACCOUNT_NUM,
  value,
});

export const autosuggestOnChange = (values) => ({
  type: AUTOSUGGEST_ONCHANGE,
  values,
});
export const clearSuggestions = () => ({
  type: CLEAR_SUGGESTIONS,
});
export const clearSuggestion = () => ({
  type: CLEAR_SUGGESTION,
});
export const closeModal = (name) => ({
  type: CLOSE_EDIT_CUSTOMER_MODAL,
  name,
});
export const openModal = (name) => ({
  type: OPEN_EDIT_CUSTOMER_MODAL,
  name,
});
export const populateCompanyFields = (fields) => ({
  type: POPULATE_COMPANY_FIELDS,
  fields,
});
export const populateContactFields = (fields) => ({
  type: POPULATE_CONTACT_FIELDS,
  fields,
});
export const populateSuggestions = (suggestions) => ({
  type: POPULATE_SUGGESTIONS,
  suggestions,
});
export const prepareNewAccount = (values) => ({
  type: PREPARE_NEW_ACCOUNT,
  values,
});
export const selectSuggestion = (account) => ({
  type: SELECT_SUGGESTION,
  account,
});
export const setCreatingAccount = () => ({
  type: SET_CREATING_ACCOUNT,
});
export const setCreatingAccountFalse = () => ({
  type: SET_CREATING_ACCOUNT_FALSE,
});
export const toggleDeleteConfirmation = (toggleValue) => ({
  type: TOGGLE_DELETE_CUSTOMER_CONFIRMATION,
  toggleValue,
});
export const toggleDeletePrompt = (toggleValue) => ({
  type: TOGGLE_DELETE_CUSTOMER_PROMPT,
  toggleValue,
});
