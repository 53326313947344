import {
  INITIALIZE_COMPANY_INPUTS,
  TOGGLE_DELETE_CONFIRMATION,
  TOGGLE_DELETE_PROMPT,
  TOGGLE_EDIT_ACCOUNT_MODAL,
} from '../actions/editCompany';
import { normalizePhone } from '../components/Inputs/validation';

const DEFAULT_STATE = {
  name: '',
  phoneNumber: '',
  emailAddress: '',
  account: {},
  open: false,
  showDeletePrompt: false,
  showDeleteConfirmation: false,
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case INITIALIZE_COMPANY_INPUTS:
      return {
        ...state,
        name: action.fields.name !== null ? action.fields.name : state.name,
        phoneNumber:
          action.fields.phoneNumber !== null
            ? normalizePhone(action.fields.phoneNumber)
            : normalizePhone(state.phoneNumber),
        emailAddress:
          action.fields.emailAddress !== null
            ? action.fields.emailAddress
            : state.emailAddress,
        account:
          action.fields.account !== null
            ? action.fields.account
            : state.account,
      };
    case TOGGLE_DELETE_CONFIRMATION:
      return {
        ...state,
        showDeleteConfirmation: action.toggleValue,
      };
    case TOGGLE_DELETE_PROMPT:
      return {
        ...state,
        showDeletePrompt: action.toggleValue,
      };

    case TOGGLE_EDIT_ACCOUNT_MODAL:
      return {
        ...state,
        open: action.toggleValue,
      };
    default:
      return state;
  }
}
