/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GithubPicker from '@uiw/react-color-github';
import ImgMarkup from 'react-img-markup';
import i18n from 'i18n-js';
import theme from 'styled-theming';
import DetectClickOutside from '../Common/DetectClickOutside';
import { Button } from '../../elements';
import useMeasure from '../Animations/useMeasure';
import THEMES from '../../styles/themes/app';
import COLORS from '../../styles/colors';

const ANNOTATIONS_TOOLS_BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.WHITE,
  dark: COLORS.PK_DARK.GREY_5,
  holiday: COLORS.PK.WHITE,
});

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  background-color: ${ANNOTATIONS_TOOLS_BACKGROUND};
  width: 100%;
  padding: 16px 0 8px 0;
  box-sizing: border-box;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  z-index: 5;
`;

const ButtonWrapper = styled.div`
  margin-left: 8px;
  margin-right: 8px;
`;

const PaletteColorWrapper = styled.div`
  position: absolute;
`;

const MarkupWrap = styled.div`
  background-color: ${THEMES.BACKGROUND_SECONDARY};
  width: ${(props) => props.maxWidth || '100%'};
  height: 464px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;

  & svg {
    margin-bottom: auto;
    margin-top: auto;
  }
`;

const LayoutHelperContainer = styled.div`
  position: absolute;
  visibility: hidden;
  top: -1000px;
`;
const LayoutHelperImg = styled.img`
  max-width: ${(props) => props.maxWidth || '100%'};
  max-height: 400px;
`;

const Annotations = ({
  imgSrc,
  onSave,
  getAnnotationSave,
  maxWidth,
  paletteStyle,
}) => {
  const [showPaletteColor, setShowPaletteColor] = useState(false);

  const markupDefaultValues = {
    color: '#0af',
    fontSize: 40,
    strokeWidth: 4,
    type: 'arrow',
    text: 'insert text!',
  };

  const [bind, measure] = useMeasure();

  return (
    <>
      <LayoutHelperContainer {...bind}>
        <LayoutHelperImg maxWidth={maxWidth} src={imgSrc} />
      </LayoutHelperContainer>
      <MarkupWrap maxWidth={maxWidth}>
        <ImgMarkup
          imgSrc={imgSrc}
          defaultValues={markupDefaultValues}
          onSave={onSave}
          imgStyles={{
            height: measure?.height,
            width: measure?.width,
          }}
        >
          {({
            setActiveColor,
            setActiveType,
            undo,
            activeType,
            save,
            imgMarkupModifiers,
          }) => {
            getAnnotationSave.current = save;
            return (
              <Wrapper ref={imgMarkupModifiers}>
                <ButtonsWrapper>
                  <ButtonWrapper>
                    <Button
                      title={i18n.t('modals-Annotations-arrow')}
                      noOutline
                      onClick={() => setActiveType('arrow')}
                      customStyle={() => 'border-width: 2px;'}
                      type={activeType === 'arrow' ? 'primary' : 'secondary'}
                    >
                      <i className="ri-arrow-right-line" />
                    </Button>
                  </ButtonWrapper>
                  <ButtonWrapper>
                    <Button
                      title={i18n.t('modals-Annotations-rectangle')}
                      noOutline
                      onClick={() => setActiveType('rect')}
                      customStyle={() => 'border-width: 2px;'}
                      type={activeType === 'rect' ? 'primary' : 'secondary'}
                    >
                      <i className="ri-checkbox-blank-line" />
                    </Button>
                  </ButtonWrapper>
                  <ButtonWrapper>
                    <Button
                      title={i18n.t('modals-Annotations-line')}
                      noOutline
                      onClick={() => setActiveType('line')}
                      customStyle={() => 'border-width: 2px;'}
                      type={activeType === 'line' ? 'primary' : 'secondary'}
                    >
                      <i className="ri-subtract-line" />
                    </Button>
                  </ButtonWrapper>
                  <ButtonWrapper>
                    <Button
                      title={i18n.t('modals-Annotations-ellipsis')}
                      noOutline
                      onClick={() => setActiveType('ellipse')}
                      customStyle={() => 'border-width: 2px;'}
                      type={activeType === 'ellipse' ? 'primary' : 'secondary'}
                    >
                      <i className="ri-checkbox-blank-circle-line" />
                    </Button>
                  </ButtonWrapper>
                  <ButtonWrapper>
                    <Button
                      title={i18n.t('modals-Annotations-text')}
                      noOutline
                      onClick={() => setActiveType('text')}
                      customStyle={() => 'border-width: 2px;'}
                      type={activeType === 'text' ? 'primary' : 'secondary'}
                    >
                      <i className="ri-text" />
                    </Button>
                  </ButtonWrapper>
                  <ButtonWrapper>
                    <Button
                      title={i18n.t('modals-Annotations-undo')}
                      noOutline
                      onClick={undo}
                      type="secondary"
                    >
                      <i className="ri-eraser-line" />
                    </Button>
                  </ButtonWrapper>
                  <ButtonWrapper>
                    <Button
                      title={i18n.t('modals-Annotations-showColors')}
                      noOutline
                      onClick={() => setShowPaletteColor(true)}
                      type="secondary"
                    >
                      <i className="ri-font-color" />
                    </Button>
                  </ButtonWrapper>
                </ButtonsWrapper>
                {showPaletteColor ? (
                  <PaletteColorWrapper style={paletteStyle}>
                    <DetectClickOutside
                      onClickOutside={() => setShowPaletteColor(false)}
                    >
                      <GithubPicker
                        onChange={(color) => setActiveColor(color.hex)}
                        triangle="hide"
                      />
                    </DetectClickOutside>
                  </PaletteColorWrapper>
                ) : (
                  ''
                )}
              </Wrapper>
            );
          }}
        </ImgMarkup>
      </MarkupWrap>
    </>
  );
};

Annotations.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  paletteStyle: PropTypes.object.isRequired,
  maxWidth: PropTypes.string,
  getAnnotationSave: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
    .isRequired,
};

Annotations.defaultProps = {
  maxWidth: '',
};

export default Annotations;
