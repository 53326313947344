import { useLayoutEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { UNDO_COMMAND } from 'lexical';
import { KeyCombo } from '../utils/constants';

export interface IWatchCombo {
  keyCombo: KeyCombo;
  onComboFn: (event: KeyboardEvent) => void;
}

interface IOnKeyDownPlugin {
  watchCombos: IWatchCombo[];
}

const OnKeyDownPlugin = ({ watchCombos }: IOnKeyDownPlugin): null => {
  const [editor] = useLexicalComposerContext();

  useLayoutEffect(() => {
    const onKeyPress = (event: KeyboardEvent) => {
      watchCombos.forEach(({ keyCombo, onComboFn }: IWatchCombo) => {
        if (
          keyCombo === KeyCombo.SHIFT_ENTER &&
          event.shiftKey &&
          event.key === 'Enter'
        ) {
          // Command is still carried out _before_ plugin can handle it.
          // Undo, then process the requested combination function.
          editor.dispatchCommand(UNDO_COMMAND, undefined);
          onComboFn(event);
        }
      });
    };
    return editor.registerRootListener(
      (
        rootElement: null | HTMLElement,
        prevRootElement: null | HTMLElement
      ) => {
        if (prevRootElement !== null) {
          prevRootElement.removeEventListener('keydown', onKeyPress);
        }
        if (rootElement !== null) {
          rootElement.addEventListener('keydown', onKeyPress);
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor]);

  return null;
};

export default OnKeyDownPlugin;
