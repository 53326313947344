import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { usePaymentWizard } from 'client-lib';
import i18n from 'i18n-js';
import Wizard from '../index.tsx';
import CreatePaymentRecipient from './pages/CreatePaymentRecipient';
import CreatePaymentMessage from './pages/CreatePaymentMessage';
import { openSnackbar } from '../../../actions/general';

const PaymentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const createPages = () => ({
  CreatePaymentRecipient: {
    start: true,
    component: CreatePaymentRecipient,
  },
  CreatePaymentMessage: {
    start: false,
    component: CreatePaymentMessage,
  },
});

const PaymentWizard = () => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.session.currentUser);
  const [overrideWizardPage, setOverrideWizardPage] = useState('');
  const [mutationLoading, setMutationLoading] = useState(false);
  const [selectedToContact, setSelectedToContact] = useState(null);
  const [senderGroupId, setSenderGroupId] = useState(null);

  const onMutationStart = () => {
    setMutationLoading(true);
  };

  const onMutationSuccess = (__, clearFields) => {
    dispatch(
      openSnackbar(
        i18n.t('slideouts-PaymentForm-success', {
          defaultValue: 'Message sent successfully',
        })
      )
    );
    setOverrideWizardPage('CreatePaymentRecipient'); // send user to first page of wizard
    setMutationLoading(false);
    clearFields(); // clear whole form
  };

  const onMutationError = () => {
    dispatch(
      openSnackbar(
        i18n.t('slideouts-CollectionsWizard-threadFailed', {
          defaultValue: 'Collections message failed to send',
        }),
        'error'
      )
    );
    setMutationLoading(false);
  };

  const {
    fields,
    onSubmit,
    updateField,
    attachment,
    setAttachment,
    sendAttachmentAsLink,
    setSendAttachmentAsLink,
    tags,
    setTags,
    requiredPaymentRequestFields,
  } = usePaymentWizard({
    initialFormValues: {},
    client,
    i18n,
    onMutationSuccess,
    onMutationStart,
    onMutationError,
    senderGroupId,
  });

  return (
    <PaymentContainer>
      <Wizard
        pages={createPages()}
        wizardState={{
          fields,
          attachment,
          sendAttachmentAsLink,
          tags,
          currentUser,
          selectedToContact,
          onSubmit,
          mutationLoading,
          requiredPaymentRequestFields,
        }}
        wizardStateSetters={{
          updateField,
          setAttachment,
          setSendAttachmentAsLink,
          setTags,
          setSelectedToContact,
          setSenderGroupId,
        }}
        wizardPageOverride={overrideWizardPage}
        setWizardPageOverride={setOverrideWizardPage}
      />
    </PaymentContainer>
  );
};

export default PaymentWizard;
