import { useMutation } from "@apollo/client";
import EXPORT_GROUP_TABLE_DATA_QUERY from "../../../graphql/mutation/ExportGroupTableData.graphql";

const useExportGroupTableData = (client) => {
  const [exportGroupTableDataMutation] = useMutation(
    EXPORT_GROUP_TABLE_DATA_QUERY,
    {
      client,
    }
  );

  const handleDownloadExcelClick = async (
    timeFrame,
    selectedGroups,
    handleDownloadExcelError,
    handleDownloadExcelSuccess,
    timeZone
  ) => {
    const groupIds = [];
    const regionIds = [];
    let selectedAllGroups = false;
    selectedGroups?.forEach((group) => {
      if (group.typename === "Region") {
        regionIds.push(group.value);
      } else {
        groupIds.push(group.value);
      }
    });

    if (selectedGroups?.findIndex(({ value }) => value === "all") !== -1) {
      selectedAllGroups = true;
    }

    const mutationVariables = {
      timeFrame,
      allGroups: selectedAllGroups,
      timezone: timeZone,
    };
    if (groupIds.length && !selectedAllGroups) {
      mutationVariables.groupIds = [...groupIds];
    }
    if (regionIds.length && !selectedAllGroups) {
      mutationVariables.regionIds = [...regionIds];
    }

    const exportExcelResponse = await exportGroupTableDataMutation({
      variables: {
        input: mutationVariables,
      },
    });

    if (
      exportExcelResponse &&
      exportExcelResponse.data.exportGroupTableData.errors !== null
    ) {
      handleDownloadExcelError();
    } else {
      handleDownloadExcelSuccess();
    }
  };

  return {
    handleDownloadExcelClick,
  };
};

export default useExportGroupTableData;
