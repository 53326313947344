import { useQuery } from "@apollo/client";
import { THREADS_QUERY } from "../../..";

/*

DOCUMENTATION
  this hook is created to be used with ThreadHistory

  args:
    threadId: id of the thread you want
    client: (obj) required. Apollo Client instance.

  returns:
    threads: (arr) array of thread instances, stored in local state

*/

const useThreadsQuery = ({
  client,
  key,
  id,
  fetchPolicy = "cache-and-network",
  skip = false,
}) => {
  const { data } = useQuery(THREADS_QUERY, {
    client,
    variables: {
      [key]: id,
    },
    fetchPolicy,
    skip,
  });

  return data;
};

export default useThreadsQuery;
