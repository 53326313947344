import { useQuery } from "@apollo/client";
import CHANNELS_QUERY from "../../../graphql/query/ChannelsQuery.graphql";

function useHasEmailChannel({ currentUser, threadsActiveGroupIds, client }) {
  const { data: channelsData } = useQuery(CHANNELS_QUERY, {
    client,
    variables: {
      accountId: currentUser?.accountId,
      type: "email",
    },
  });

  const hasEmailChannel = !!channelsData?.channels
    ?.filter((channel) => channel.emailConfig !== null)
    ?.filter((channel) =>
      threadsActiveGroupIds?.some((activeId) => activeId === channel.group.id)
    ).length;

  return { hasEmailChannel };
}

export default useHasEmailChannel;
