import { useQuery } from "@apollo/client";
import ACTIVE_THREAD_COUNT_QUERY from "../../../graphql/query/ActiveThreadCountQuery.graphql";

function useActiveThreadCountWidget({ client, i18n, groupIds }) {
  const { data, loading, refetch } = useQuery(ACTIVE_THREAD_COUNT_QUERY, {
    client,
    variables: {
      groupIds,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const activeGroups = [];
  let activeTotal;

  if (!loading) {
    const {
      emailInbound = null,
      emailOutbound = null,
      smsInbound = null,
      smsOutbound = null,
      webchatInbound = null,
      faxInbound = null,
    } = data.activeThreadCount;

    activeTotal =
      emailInbound +
      emailOutbound +
      smsInbound +
      smsOutbound +
      webchatInbound +
      faxInbound;

    if ((smsInbound || smsOutbound) !== null) {
      activeGroups.push({
        label: i18n.t("modals-BlockOrRemove-channelOption-sms", {
          defaultValue: "Text",
        }),
        value: smsInbound + smsOutbound,
        otherProps: {
          toolTip: `${smsInbound} ${i18n.t("threads-DougnutWidget-inbound", {
            defaultValue: "Inbound",
          })}, ${smsOutbound} ${i18n.t("threads-DougnutWidget-outbound", {
            defaultValue: "Outbound",
          })}`,
          inboundCount: smsInbound,
          outboundCount: smsOutbound,
        },
        key: "text",
      });
    }

    if ((emailInbound || emailOutbound) !== null) {
      activeGroups.push({
        label: i18n.t("settings-Support-email", {
          defaultValue: "Email",
        }),
        value: emailInbound + emailOutbound,
        otherProps: {
          toolTip: `${emailInbound} ${i18n.t("threads-DougnutWidget-inbound", {
            defaultValue: "Inbound",
          })}, ${emailOutbound} ${i18n.t("threads-DougnutWidget-outbound", {
            defaultValue: "Outbound",
          })}`,
          inboundCount: emailInbound,
          outboundCount: emailOutbound,
        },
        key: "email",
      });
    }

    if (webchatInbound !== null) {
      activeGroups.push({
        label: i18n.t("settings-WebConnect-webChat", {
          defaultValue: "Web Chat",
        }),
        value: webchatInbound,
        otherProps: {
          toolTip: `${webchatInbound} ${i18n.t(
            "threads-DougnutWidget-inbound",
            {
              defaultValue: "Inbound",
            }
          )}`,
          inboundCount: webchatInbound,
        },
        key: "webchat",
      });
    }

    if (faxInbound !== null) {
      activeGroups.push({
        label: i18n.t("modals-BlockOrRemove-channelOption-fax", {
          defaultValue: "Fax",
        }),
        value: faxInbound,
        otherProps: {
          toolTip: `${faxInbound} ${i18n.t("threads-DougnutWidget-inbound", {
            defaultValue: "Inbound",
          })}`,
          inboundCount: faxInbound,
        },
        key: "fax",
      });
    }
  }

  return {
    activeGroups,
    activeTotal,
    activeLoading: loading,
    refetchActiveThreadCount: refetch,
  };
}

export default useActiveThreadCountWidget;
