import { useSelector, useStore } from 'react-redux';
import { useStartOpenThreadForUserSubscription } from 'client-lib';
import { useApolloClient } from '@apollo/client';
import useConnectApolloSocket from './signedIn/useConnectApolloSocket';
import useVerifyWebClientVersionInterval from './signedIn/useVerifyWebClientVersionInterval';
import useOnLoadQueryAccountData from './signedIn/useOnLoadQueryAccountData';
import useOnLoadQueryGroupsData from './signedIn/useOnLoadQueryGroupsData';
import useOnLoadQuerySelectedGroups from './signedIn/useOnLoadQuerySelectedGroups';
import useOnLoadQueryBannersData from './signedIn/useOnLoadQueryBannersData';
import useWatchClosingThreads from './signedIn/useWatchClosingThreads';
import useOnLoadQueryUpgradeBlurbData from './signedIn/useOnLoadQueryUpgradeBlurbData';
import useWatchRtiGlobal from './signedIn/useWatchRtiGlobal';
import useActiveEmailChannels from './signedIn/useActiveEmailChannels';
import usePendoInit from './signedIn/usePendoInit';
import useCacheCommonQueries from './signedIn/useCacheCommonQueries';
import useOnLoadQueryDashboardSelectedGroups from './signedIn/useOnLoadQueryDashboardSelectedGroups';
import useOnLoadQueryFeedbackSelectedGroups from './signedIn/useOnLoadQueryFeedbackSelectedGroups';

// this hook is to be used for stuff related to when a user signs into the web app, including listening for events
// and pushing snackbar messages app-wide. We can also do some setup here, for instance when a user logs in, we do
// an account query and place it in the redux store.
const useSignedInWebApp = ({ history }) => {
  const userId = useSelector((state) => state.session.currentUser?.userId);
  const store = useStore();
  const client = useApolloClient();

  // connects client-side socket on reception of user data
  useConnectApolloSocket();

  // sets version check on an interval of 30 mins
  // if out of sync will reload page from `onUpgradeCallback`
  useVerifyWebClientVersionInterval();

  // sending the account data to the redux store on sign in, this prevents us having to query
  // it later for things like feature flags.
  useOnLoadQueryAccountData();

  // sending all groups data to the redux store so we don't need to cotinually query it.
  // we also watch to see if all "active" groups are enabled, and if they aren't we switch the user's active group.
  useOnLoadQueryGroupsData({ history });

  // load various group selectors
  useOnLoadQueryDashboardSelectedGroups();
  useOnLoadQuerySelectedGroups();
  useOnLoadQueryFeedbackSelectedGroups();

  // grab any banners that we need to display for user, send to redux
  useOnLoadQueryBannersData();

  // grab a random blurb for the upgrade box in settings, sends it to the redux store
  useOnLoadQueryUpgradeBlurbData();

  // watch for threads being closed, we need to update the active thread id to be empty
  // we also deploy the "thread closed" snackbar from here.
  useWatchClosingThreads();

  // watch ReturnToInbox globally, overwrite active thread id if it matches the thread that was returned to inbox.
  useWatchRtiGlobal();

  // sets the email channels a user has in the redux store inside account
  useActiveEmailChannels();

  // subscribes to internal threads. this breaks the traditional architecture of the other subscriptions, but
  // we need to more closely watch and update this sub in particular since it's so dependant upon userId.
  useStartOpenThreadForUserSubscription(userId, store, client);

  // product team is using a tool called Pendo in QA env, which helps with analytics tracking as well as tutorial-like popup situations.
  usePendoInit();

  // this is mostly a UI helper, any queries that can be done in advance and cached should go here, this will help for cases with laggy UI.
  useCacheCommonQueries();
};

export default useSignedInWebApp;
