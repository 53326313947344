import theme from 'styled-theming';
import COLORS from '../../colors';

const BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.LIGHT_GRAY,
  light: COLORS.PK.GREY_20,
  dark: COLORS.PK_DARK.GREY_4,
  holiday: COLORS.PK.GREY_20,
});

const HIGHLIGHTED_TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_BLACK,
  light: COLORS.PK.GREY_80,
  dark: COLORS.PK.GREY_80,
  holiday: COLORS.PK.GREY_80,
});

const REGION_BACKGROUND = theme('mode', {
  classic: COLORS.PK.GREEN_20,
  light: COLORS.PK.GREEN_20,
  dark: COLORS.PK.GREEN_60,
  holiday: COLORS.PK.GREEN_20,
});

const THEMES = {
  BACKGROUND,
  HIGHLIGHTED_TEXT,
  REGION_BACKGROUND,
};

export default THEMES;
