import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import ListFlyoutRightText from './ListFlyoutRightText';

const ListAudienceContactStatus = ({
  promotionalBroadcastOptOut,
  announcementsOptOut,
  smsStatus,
}) => {
  if (smsStatus?.includes('NOT_IN_SERVICE'))
    return (
      <ListFlyoutRightText
        dataTestId="contact-status-not-in-service"
        text={i18n.t('broadcasts-broadcast-unreachable', {
          defaultValue: 'Not in service',
        })}
      />
    );

  if (smsStatus?.includes('SUSPECTED_LANDLINE'))
    return (
      <ListFlyoutRightText
        dataTestId="contact-status-suspected-landline"
        text={i18n.t('sidebar-CreateSms-notCompatible', {
          defaultValue: 'Not text compatible',
        })}
      />
    );

  if (promotionalBroadcastOptOut === false && announcementsOptOut === false)
    return (
      <ListFlyoutRightText
        dataTestId="contact-status-opted-in"
        text={i18n.t('broadcasts-broadcast-repliedStart', {
          defaultValue: 'Replied "START"',
        })}
      />
    );

  if (promotionalBroadcastOptOut === null && announcementsOptOut === null)
    return (
      <ListFlyoutRightText
        dataTestId="contact-status-no-recorded-opted-status"
        text={i18n.t('settings-manageRules-noRecordedOptedStatus', {
          defaultValue: 'No Recorded Opt In or Out',
        })}
      />
    );

  if (promotionalBroadcastOptOut && announcementsOptOut === false)
    return (
      <ListFlyoutRightText
        dataTestId="contact-status-replied-no-promo"
        text={i18n.t('broadcasts-broadcast-repliedNoPromo', {
          defaultValue: 'Replied "NO PROMO"',
        })}
      />
    );

  if (promotionalBroadcastOptOut && announcementsOptOut)
    return (
      <ListFlyoutRightText
        dataTestId="contact-status-replied-stop"
        text={i18n.t('broadcasts-broadcast-repliedStop', {
          defaultValue: 'Replied "STOP"',
        })}
      />
    );

  return null;
};

ListAudienceContactStatus.propTypes = {
  promotionalBroadcastOptOut: PropTypes.bool,
  announcementsOptOut: PropTypes.bool,
  smsStatus: PropTypes.string,
};

ListAudienceContactStatus.defaultProps = {
  promotionalBroadcastOptOut: null,
  announcementsOptOut: null,
  smsStatus: null,
};

export default ListAudienceContactStatus;
