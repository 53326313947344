import { useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { usePrevious, useReadThreadsFromApolloCache } from 'client-lib';

const useWatchForReturnToInbox = ({
  threadsActiveGroupIds,
  contactId,
  onReturnToInbox,
}) => {
  const client = useApolloClient();

  const { inboxThreads, myOpenThreads, allOpenThreads } =
    useReadThreadsFromApolloCache({
      client,
      threadsActiveGroupIds,
      contactId,
      includeInternal: true,
    });

  // this useEffect is designed to watch for a "return to inbox" event. In which case,
  // if the user has the thread open, we will re-route them to show a blank screen inside of /open
  // instead of showing the old thread that has been returned to inbox.
  const prevInboxThreadsLength = usePrevious(inboxThreads?.length);
  const prevMyOpenThreads = usePrevious(myOpenThreads);
  useEffect(() => {
    if (prevInboxThreadsLength < inboxThreads?.length) {
      const hasMatchingThreadIds = (threadsGroupOne, threadsGroupTwo) =>
        threadsGroupOne.find((thread) =>
          threadsGroupTwo.map((thread) => thread.id).includes(thread?.id)
        );

      const returnedToInboxEvent =
        hasMatchingThreadIds(inboxThreads, myOpenThreads) ||
        hasMatchingThreadIds(inboxThreads, prevMyOpenThreads) ||
        hasMatchingThreadIds(inboxThreads, allOpenThreads);

      if (returnedToInboxEvent) {
        onReturnToInbox(returnedToInboxEvent);
      }
    }
  }, [inboxThreads?.length, myOpenThreads?.length, allOpenThreads?.length]);
};

export default useWatchForReturnToInbox;
