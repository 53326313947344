import React from 'react';
import { useApolloClient } from '@apollo/client';
import { useUpdateDashboardSelectedGroups } from 'client-lib';
import { diff } from 'deep-diff';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { setDashboardsActiveGroupIds } from '../../../actions/session';
import ActionBar from './ActionBar';

const DashboardActionBar = ({
  activeGroupIds,
  handleRefetch,
  loading,
  completedDate,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();

  const { updateDashboardSelectedGroupsHandler } =
    useUpdateDashboardSelectedGroups({
      client,
    });

  const handleGroupsUpdate = (selectedGroupIds) => {
    if (selectedGroupIds.length && diff(selectedGroupIds, activeGroupIds)) {
      const onSuccess = (groupIds) => {
        dispatch(setDashboardsActiveGroupIds(groupIds));
      };

      const onError = (err) => {
        console.error('An error occurred', err);
      };

      updateDashboardSelectedGroupsHandler({
        groupIds: selectedGroupIds,
        onSuccess,
        onError,
      });
    }
  };

  return (
    <ActionBar
      activeGroupIds={activeGroupIds}
      handleGroupsUpdate={handleGroupsUpdate}
      handleRefetch={handleRefetch}
      loading={loading}
      completedDate={completedDate}
    />
  );
};

DashboardActionBar.propTypes = {
  activeGroupIds: PropTypes.array,
  handleRefetch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  completedDate: PropTypes.string,
};

DashboardActionBar.defaultProps = {
  activeGroupIds: [],
  completedDate: new Date().toISOString(),
};

export default DashboardActionBar;
