import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { InlineTextButton, Button, Text } from '../index';
import Bubble from '../../components/Common/Bubble';
import THEMES from '../../styles/themes/app';
import BUBBLE_THEMES from '../../styles/themes/library/bubble';

const SortWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 16px;
`;

const Eyecon = styled.i`
  font-size: 20px !important;
  padding-right: 4px;
`;

const OptionsButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const borderRadiusViaPosition = (position) => {
  switch (position) {
    case 'top':
      return '4px 4px 0 0';

    case 'single':
      return '4px';

    case 'middle':
      return '0px';

    default:
      return '0px 0px 4px 4px';
  }
};

const bubbleButtonHoverStyle = (props) =>
  `
  @media print {display: none;}
  ${
    props?.isSelected
      ? `
  background-color: ${BUBBLE_THEMES.SELECTED_STATE(props)};
    border-radius: ${borderRadiusViaPosition(props?.bubbleButtonPosition)};
  :hover {
    background-color: ${BUBBLE_THEMES.SELECTED_STATE(props)};
  }
  `
      : `
  :hover {
    background-color: ${BUBBLE_THEMES.HOVER_STATE(props)};
    border-radius: ${borderRadiusViaPosition(props?.bubbleButtonPosition)};
  }`
  }
`;

// Options is a list of {label, value} where value is enum and label is i18n string
// A unique dataTestId is suggested for each SortBy component (use Parent Component name)
const SortBy = ({
  options,
  selection,
  onSelect,
  isOpen,
  setIsOpen,
  bubbleProps,
  dataTestId,
  isFirstOption,
}) => {
  return (
    <>
      <SortWrapper>
        <InlineTextButton
          noOutline
          customStyle={(props) =>
            `color: ${
              isOpen
                ? THEMES.FOREGROUND_HIGH_COLOR(props)
                : THEMES.FOREGROUND_HIGH(props)
            };
            &:hover{
              color: ${
                isOpen
                  ? THEMES.FOREGROUND_MED_COLOR(props)
                  : THEMES.FOREGROUND_LOW(props)
              }
            }`
          }
          onClick={() => setIsOpen((prev) => !prev)}
          dataTestId={`${dataTestId}-sort-by-button`}
        >
          <Eyecon className="ri-arrow-up-down-line" />
          {i18n.t('customers-SortBy-SortBy', { defaultValue: 'Sort By' })}
        </InlineTextButton>
        <Text
          customStyle={(props) =>
            `padding-left: 8px; color: ${THEMES.FOREGROUND_MED(props)}`
          }
        >
          {selection?.label}
        </Text>
      </SortWrapper>
      <Bubble
        isOpen={isOpen}
        onClickOutside={() => setIsOpen(false)}
        moveBubbleRightVal={35}
        moveLeftVal={-20}
        bubbleMinWidth={190}
        top="10px"
        zIndex={2}
        fixedPosition
        dataTestId={`${dataTestId}-sort-by-bubble`}
        highlightCarrot={isFirstOption}
        {...bubbleProps}
      >
        <OptionsButtonContainer>
          {options?.map((option, i) => (
            <Button
              key={option.value + i}
              type="noStyle"
              noOutline
              iconAndText
              isSelected={option?.value === selection?.value}
              customStyle={bubbleButtonHoverStyle}
              borderRadiusViaPosition={i === 0 ? 'top' : 'bottom'}
              onClick={() => {
                onSelect(option, i);
                setIsOpen(false);
              }}
              dataTestId={`${dataTestId}-sort-by-option-${option.value}`}
            >
              {option?.label}
            </Button>
          ))}
        </OptionsButtonContainer>
      </Bubble>
    </>
  );
};

SortBy.propTypes = {
  options: PropTypes.array.isRequired,
  selection: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  bubbleProps: PropTypes.object,
  dataTestId: PropTypes.string,
  isFirstOption: PropTypes.bool,
};

SortBy.defaultProps = {
  bubbleProps: null,
  dataTestId: 'SortBy',
  isFirstOption: false,
};

export default SortBy;
