import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery, useApolloClient } from '@apollo/client';
import {
  UPDATE_ANNOUNCEMENT_MUTATION,
  CREATE_ANNOUNCEMENT_MUTATION,
  SEND_ANNOUNCEMENT_MUTATION,
  SIMPLE_ANNOUNCEMENT_QUERY,
  useAccountQuery,
  GET_ACCOUNT_AUDIENCE_INFO_QUERY,
  CHANNELS_QUERY,
  DIRECT_ANNOUNCEMENT_CUSTOMERS_QUERY,
} from 'client-lib';
import usePaginated from 'client-lib/src/lib/api/query/usePaginated';
import Wizard from '../index.tsx';
import GroupMessageAudience from './pages/GroupMessageAudience';
import GroupMessageContent from './pages/GroupMessageContent';
import {
  closeCreateSectionModal,
  setActiveLoseProgressState,
  setActiveSidebar,
  triggerRefetchBroadcast,
} from '../../../actions/general';
import GroupMessageType from './pages/GroupMessageType';
import GroupMessageOverviewCombined from './pages/GroupMessageOverviewCombined';
import GroupMessageName from './pages/GroupMessageName';
import GroupMessageOverview from './pages/GroupMessageOverview';
import GroupMessageAudienceBuilder from './pages/GroupMessageAudienceBuilder';

const GroupMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const ANNOUNCEMENT_TYPES = {
  PROMOTIONAL: 'PROMOTIONAL',
  INFORMATIONAL: 'INFORMATIONAL',
};

const createPages = (isNew = true, promoAccess = false) => ({
  GroupMessageType: {
    start: isNew,
    component: promoAccess ? GroupMessageType : GroupMessageName,
  },
  GroupMessageOverviewCombined: {
    start: !isNew,
    component: promoAccess
      ? GroupMessageOverviewCombined
      : GroupMessageOverview,
  },
  GroupMessageAudience: {
    start: false,
    component: promoAccess ? GroupMessageAudienceBuilder : GroupMessageAudience,
  },
  GroupMessageContent: {
    start: false,
    component: GroupMessageContent,
  },
});

// all wizard state will be contained here
const GroupMessageWizard = () => {
  const dispatch = useDispatch();
  const promoAccess = useSelector(
    (state) => state.accountData.account?.ff_broadcast_list_improvements
  );
  const announcementInitialId = useSelector(
    (state) => state?.general?.announcementInitialId
  );
  const currentUser = useSelector((state) => state?.session?.currentUser);
  const refetchAnnouncement = useSelector(
    (state) => state.general.triggerRefetchAnnouncement
  );
  const [announcementId, setAnnouncementId] = useState(announcementInitialId);
  const [announcementType, setAnnouncementType] = useState(
    ANNOUNCEMENT_TYPES.INFORMATIONAL
  );

  const handleOnClose = (state) => {
    if (state === 'saved') {
      dispatch(setActiveLoseProgressState(false));
      dispatch(closeCreateSectionModal());
      dispatch(triggerRefetchBroadcast(true));
      dispatch(setActiveSidebar('default'));
    } else if (state === 'nosave') {
      dispatch(setActiveLoseProgressState(false));
      dispatch(closeCreateSectionModal());
      dispatch(setActiveSidebar('default'));
    } else dispatch(setActiveLoseProgressState(true));
  };

  const client = useApolloClient();
  const [createAnnouncementMutation] = useMutation(
    CREATE_ANNOUNCEMENT_MUTATION,
    { client }
  );
  const [updateAnnouncementMutation] = useMutation(
    UPDATE_ANNOUNCEMENT_MUTATION,
    { client }
  );
  const [sendAnnouncementMutation, { loading: sendLoading }] = useMutation(
    SEND_ANNOUNCEMENT_MUTATION,
    {
      client,
    }
  );

  const {
    data,
    loading,
    error,
    refetch: refetchSimpleAnnouncement,
  } = useQuery(SIMPLE_ANNOUNCEMENT_QUERY, {
    client,
    variables: { id: announcementId },
    skip: !announcementId,
  });

  const { data: accountData } = useAccountQuery({
    client,
    variables: {
      id: currentUser?.accountId,
    },
    skip: !currentUser,
  });

  const { data: audienceInfoData } = useQuery(GET_ACCOUNT_AUDIENCE_INFO_QUERY, {
    client,
    variables: {
      accountId: currentUser?.accountId,
    },
  });

  const { data: channelsData, loading: channelsLoading } = useQuery(
    CHANNELS_QUERY,
    {
      client,
      variables: {
        accountId: currentUser?.accountId,
        type: 'announcements',
      },
    }
  );

  const { directAnnouncementCustomers } = usePaginated({
    client,
    query: DIRECT_ANNOUNCEMENT_CUSTOMERS_QUERY,
    queryVariables: {
      announcementId,
    },
    resultsNumber: 30,
    key: 'directAnnouncementCustomers',
  });

  useEffect(() => {
    if (refetchAnnouncement) {
      refetchSimpleAnnouncement();
    }
    return () => {
      if (refetchAnnouncement) {
        dispatch(triggerRefetchBroadcast(false));
      }
    };
  }, [dispatch, refetchAnnouncement, refetchSimpleAnnouncement]);

  return (
    <GroupMessageContainer data-testid="group-message-wizard">
      <Wizard
        pages={createPages(!announcementInitialId, promoAccess)}
        wizardState={{ announcementId, announcementType }}
        wizardStateSetters={{ setAnnouncementId, setAnnouncementType }}
        mutations={{
          updateAnnouncementMutation,
          createAnnouncementMutation,
          sendAnnouncementMutation,
        }}
        wizardGlobalProps={{
          sendAnnouncement: { sendLoading },
          announcementQuery: { data, loading, error },
          accountData,
          audienceInfo: {
            audienceInfoData: audienceInfoData?.getAccountAudienceInfo,
          },
          channelsData,
          channelsLoading,
          customerIds: directAnnouncementCustomers?.map(({ id }) => id),
        }}
        closeWizard={handleOnClose}
      />
    </GroupMessageContainer>
  );
};

export default GroupMessageWizard;
