import React from 'react';

interface MultiColoredBarProps {
  name: string;
  data: { [key: string]: string | number }[];
  total: number;
  barColors?: string[] | undefined;
  dataTestId?: string;
}

const MultiColoredBar = ({
  name,
  data = [],
  total,
  barColors = undefined,
  dataTestId = '',
}: MultiColoredBarProps) => {
  let totalCount = 0;
  const calculateFillPercentage = (count: number) => {
    totalCount += count;
    return (totalCount / total) * 100;
  };

  return (
    <div
      style={{
        width: '100%',
        height: '8px', // Set your desired height
        position: 'relative',
      }}
    >
      {data.map((datum, index) => {
        if (datum.count && barColors && barColors[index])
          return (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
                height: '100%',
                width: `${calculateFillPercentage(+datum.count)}%`,
                backgroundColor: barColors[index],
                borderRadius: '4px', // Optional: Add border radius for rounded corners
                zIndex: data.length - index, // Use z-index to control the layering
              }}
              data-testid={dataTestId || `${name}-${barColors[index]}-bar`}
            />
          );
        return null;
      })}
    </div>
  );
};

export default MultiColoredBar;
