import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import i18n from 'i18n-js';
import theme from 'styled-theming';
import { GROUPS_BY_REGION_QUERY, useVerifyGroupLimit } from 'client-lib';
import {
  SORT_DIRECTION,
  debounce,
  sortArray,
} from 'client-lib/src/lib/utils/helpers';
import { useApolloClient, useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import {
  Heading3,
  Checkbox,
  Loading,
  Heading4,
  IconLabel,
  TextInput,
  Button,
  Text,
} from '../../elements';
import THEMES from '../../styles/themes/app';
import Flyout from '../Flyout/Flyout';
import FlyoutButton from '../../elements/Button/FlyoutButton';
import FONTSIZE_THEMES from '../../styles/themes/fontSize/button';
import { openSnackbar } from '../../actions/general';
import GroupRegionSelectFlyoutFilterBy from './GroupRegionSelectFlyoutFilterBy';
import NestedCheckboxList from '../../elements/InfiniteScroll/NestedCheckboxList';

const CHECKBOX_FONTSIZE = theme('fontSize', {
  default: '16px',
  large: '20px',
});

const Footer = styled.footer`
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 56px;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  border-top: 2px solid ${THEMES.BORDER_COLOR};
`;

const ErrorPopUp = styled.div`
  background-color: ${THEMES.THEME_RED};
  color: ${THEMES.COLORED_FOREGROUND_HIGH};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 48px;
  position: absolute;
  width: 100%;
  bottom: 56px;
  left: 0;
  border-radius: 4px 4px 0 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 12px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex: 1;
`;

const OptionsInterior = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
`;

const GroupOption = styled.div`
  border-bottom: solid 1px ${THEMES.BORDER_COLOR};
`;

const SelectAllCheckboxContainer = styled(GroupOption)`
  padding: 10px 16px;
  background-color: ${THEMES.COLORED_BACKGROUND_PRIMARY};
  cursor: pointer;
  &:hover {
    div {
      text-decoration: underline !important;
    }
  }
`;

const MaxSelectionWarningContainer = styled(GroupOption)`
  padding: 10px 16px;
  background-color: ${THEMES.BACKGROUND_TERTIARY};
  color: ${THEMES.FOREGROUND_LOW};
  span {
    color: ${THEMES.FOREGROUND_HIGH};
    font-weight: 700;
  }
`;

const CheckboxContainer = styled.div`
  padding: 25px 16px;
  cursor: pointer;
  &:hover {
    background-color: ${THEMES.BACKGROUND_SECONDARY};
  }
`;

const LoadingContainer = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const SearchBar = styled.div`
  display: flex;
  padding: 8px 16px;
  border-bottom: solid 1px ${THEMES.BORDER_COLOR};
  background-color: ${THEMES.BACKGROUND_PRIMARY};
`;

const FilterTypesWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const ScrollableArea = styled.div`
  overflow-y: auto;
`;

const NoResultsWrap = styled.div`
  height: 112px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`;

const CustomFlyoutButton = styled(FlyoutButton)`
  width: 264px;
`;

const headerIcon = () => (
  <IconLabel
    size="md"
    noOutline
    customStyle={(props) => `
      background-color: #B2B0AB;
      color: ${THEMES.OVERLAY_AVATAR_PRIMARY(props)};
      padding: 4px;
      border-radius: 50%;
         margin-right: 16px;
         pointer-events: none;
         cursor: default;
          &:hover {
            background: transparent;
            box-shadow: none;
          }
     `}
  >
    <i className="ri-map-pin-line" />
  </IconLabel>
);

const GroupRegionSelectFlyout = ({
  activeGroupIds,
  onSelectionFinal,
  allowRegions,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  // Search Text is debounced search text
  const [searchText, setSearchText] = useState('');
  // Filter Text is raw input text
  const [filterText, setFilterText] = useState('');
  const [searchActive, setSearchActive] = useState(false);
  const [flyoutOpen, setFlyoutOpen] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({
    filterBySelected: false,
    filterByRegions: false,
  });
  const [sortDirection, setSortDirection] = useState(SORT_DIRECTION.ascending);

  const { filterByRegions, filterBySelected } = appliedFilters;

  // TextInput needs an external state to track it's value,
  // the input box is tightly bound to that.  To allow the user
  // to type and have the value show up, keep a separate state (filterText)
  // Debounce that input and then set the searchText state to perform search actions.
  // This prevents network requests for EVERY ENTRY in the input box
  // as well as un-needed re-rendering.
  const executeSearch = debounce((handleChange) => {
    handleChange();
    // Allows for loading to show when searching
    // Since the list is rerendered for every key entry
    // this is important to wait until the user is done typing
    setSearchActive(false);
  }, 500);

  const { data: groupsByRegionData, loading: groupsByRegionLoading } = useQuery(
    GROUPS_BY_REGION_QUERY,
    {
      client,
      fetchPolicy: 'network-only',
    }
  );

  const flattenRegionGroups = (groupsByRegion) =>
    groupsByRegion
      .map((region) => region.groups)
      .flat()
      .filter(Boolean);

  /**
   * Filters the regions by name
   * @param {Object[]} regions List of regions to filter {id: String, name: String, groups: Object[]}
   * @param {String} filterText text to match group.name against
   * @param {Boolean} searchCondition optional flag to determine if the filter should be applied
   * @returns new instance of regions with regions filtered by name
   */
  const optionallyFilterByRegion = (
    regions,
    filterText,
    searchCondition = true
  ) => {
    if (!filterText || !searchCondition) return regions;
    return regions.filter((region) =>
      region.name.toLowerCase().includes(filterText.toLowerCase())
    );
  };

  /**
   * Filter the groups by name
   * @param {Object[]} regions List of regions with groups to filter {id: Number, name: String, groups: Object[{id:string, name:string}]}
   * @param {String} filterText text to match {region:{group.name}} against
   * @param {Boolean} searchCondition optional flag to determine if the filter should be applied
   * @returns new instance of regions with groups filtered by name
   */
  const optionallyFilterByGroup = (
    regions,
    filterText,
    searchCondition = true
  ) => {
    if (!filterText || !searchCondition) return regions;
    const filteredGroupsByRegion = regions.map((region) => ({
      ...region,
      groups: region.groups.filter((group) =>
        group.name.toLowerCase().includes(filterText.toLowerCase())
      ),
    }));
    return filteredGroupsByRegion;
  };

  const optionallyAddUnGroupedSet = (unGrouped, existingGroups) => {
    const newGroups = [...existingGroups];
    if (unGrouped?.length > 0) {
      const unGroupedRegion = {
        id: 0,
        name: i18n.t('dashboards-GroupSelect-UnaffiliatedGroups', {
          defaultValue: 'Unaffiliated Groups',
        }),
        groups: [...unGrouped],
      };
      newGroups.push(unGroupedRegion);
    }
    return newGroups;
  };

  const { groupsByRegion, flatGroups, allGroups } = useMemo(() => {
    const groupsByRegion = [
      ...sortArray(
        groupsByRegionData?.regionsWithNestedGroups?.regions || [],
        sortDirection
      ),
    ];

    const regionsWithUngrouped = optionallyAddUnGroupedSet(
      groupsByRegionData?.regionsWithNestedGroups?.nonRegionGroups,
      groupsByRegion
    );

    const allGroups = sortArray(
      flattenRegionGroups(regionsWithUngrouped),
      sortDirection
    );

    const filteredRegions = optionallyFilterByRegion(
      regionsWithUngrouped,
      searchText,
      filterByRegions
    );
    const filteredGroups = optionallyFilterByGroup(
      filteredRegions,
      searchText,
      !filterByRegions
    );

    const filteredFlatGroups = sortArray(
      flattenRegionGroups(filteredGroups),
      sortDirection
    );

    return {
      groupsByRegion: filteredGroups,
      flatGroups: filteredFlatGroups,
      allGroups,
    };
  }, [groupsByRegionData, searchText, filterByRegions, sortDirection]);

  const { selectionLimit, loading: selectionLimitLoading } =
    useVerifyGroupLimit();

  // Groups is used to display the list in the group control
  const groups = filterByRegions ? groupsByRegion : flatGroups;

  // new multi-group logic
  const [selectedGroupIdsState, setSelectedGroupIdsState] =
    useState(activeGroupIds);

  const toggleSelectedGroupId = (selectedGroupId) => {
    const newSelectedGroupIdsState = [...selectedGroupIdsState];

    const threadActiveIndex = newSelectedGroupIdsState.findIndex(
      (groupId) => groupId === selectedGroupId
    );

    if (threadActiveIndex >= 0)
      newSelectedGroupIdsState.splice(threadActiveIndex, 1);
    else newSelectedGroupIdsState.push(selectedGroupId);

    setSelectedGroupIdsState(newSelectedGroupIdsState);
    setHasUnsavedChanges(true);
  };

  const selectedGroups = groups.filter((g) =>
    selectedGroupIdsState.includes(g.id)
  );

  const groupIds = (filterBySelected ? selectedGroups : flatGroups).map(
    (group) => group.id
  );

  const allAreActive = groupIds.reduce((bool, groupId) => {
    if (!bool) return bool;
    return selectedGroupIdsState.includes(groupId);
  }, true);

  const toggleFilteredGroupIds = (remove) => {
    if (remove) {
      setSelectedGroupIdsState((prev) =>
        prev.filter((id) => !groupIds.includes(id))
      );
    } else {
      setSelectedGroupIdsState((prev) => [...new Set([...prev, ...groupIds])]);
    }
  };

  const handleSelectAll = () => {
    if (searchText) {
      toggleFilteredGroupIds(allAreActive);
    } else {
      setSelectedGroupIdsState(allAreActive ? [] : groupIds);
    }
    setHasUnsavedChanges(true);
  };

  // Writing these functions out to clarify some new behavior for this flyout. We are now treating outside clicks of flyout of X button as NOT saving the changes (`handleRequestCloseFlyout`) and clicking the Close button as clearing changes (`handleCloseButton`). Save button is only way to save changes (`handleSave`).
  const handleRequestCloseFlyout = () => {
    if (hasUnsavedChanges) {
      dispatch(
        openSnackbar(
          i18n.t('dashboards-GroupSelect-warning', {
            defaultValue:
              'Please save the view to see selected groups, otherwise no changes will be made.',
          }),
          'error'
        )
      );
    }
    setFlyoutOpen(false);
  };

  const handleCloseButton = () => {
    setSelectedGroupIdsState(activeGroupIds);
    setFlyoutOpen(false);
  };

  const handleSave = () => {
    onSelectionFinal(selectedGroupIdsState);
    setFlyoutOpen(false);
    setHasUnsavedChanges(false);
  };

  const showSelectionLimitWarning = allGroups?.length > selectionLimit;
  const showSelectionLimitError = selectedGroupIdsState.length > selectionLimit;

  const calculateInfiniteScrollHeight = () => {
    let heightToSubtract = 280;
    if (showSelectionLimitError) {
      heightToSubtract += 47;
    }
    if (showSelectionLimitWarning) {
      heightToSubtract += 38;
    }
    if (allowRegions) {
      heightToSubtract += 35;
    }
    return `calc(100vh - ${heightToSubtract}px)`;
  };

  const renderRow = (row) =>
    filterByRegions ? renderRegionRow(row) : renderSingleRow(row);

  const renderSingleRow = ({ list: group, index }) => {
    const isActive = selectedGroupIdsState.includes(group.id);
    return (
      <GroupOption key={group.id}>
        <CheckboxContainer onClick={() => toggleSelectedGroupId(group.id)}>
          <Checkbox
            dataTestId={`group-select-btn-${index}`}
            customContainerStyle={() =>
              'display: block; div {justify-content: unset !important;}'
            }
            checked={isActive}
            onCheck={() => {}}
            label={group.name}
            labelRight
            customLabelStyle={(props) => `
              font-size: ${CHECKBOX_FONTSIZE(props)};
              white-space: nowrap;
              text-overflow: ellipsis;
              display: block;
              overflow: hidden;
              max-width: 100%;
            `}
          />
        </CheckboxContainer>
      </GroupOption>
    );
  };

  /**
   * Updates the state and sets the hasUnsavedChanges flag to true
   * @param {Number[]} ids to push into selectedGroupIdsState
   */
  const handleCheckboxChange = (ids) => {
    setSelectedGroupIdsState(ids);
    setHasUnsavedChanges(true);
  };
  const renderRegionRow = ({ list: region }) => {
    return (
      <NestedCheckboxList
        key={region.id}
        data={region}
        nestedKey="groups"
        selectedItemIds={selectedGroupIdsState}
        setSelectedItemIds={handleCheckboxChange}
      />
    );
  };

  const noResults =
    (filterBySelected && selectedGroups.length === 0) || groups.length === 0;

  const renderScrollableArea = () => {
    return (
      <ScrollableArea
        dataTestId="ScrollableArea"
        style={{ height: calculateInfiniteScrollHeight() }}
      >
        {noResults ? (
          <NoResultsWrap>
            {groups.length === 0
              ? i18n.t('dashboards-GroupSelect-noResultsFound', {
                  defaultValue: 'No results found',
                })
              : i18n.t('dashboards-GroupSelect-noSelection', {
                  defaultValue: 'No selections made',
                })}
          </NoResultsWrap>
        ) : (
          (filterBySelected ? selectedGroups : groups).map((group, index) => {
            return renderRow({ list: group, index });
          })
        )}
      </ScrollableArea>
    );
  };

  const searchPlaceholder = filterByRegions
    ? i18n.t('settings-GroupListFilter-regions-placeholder', {
        defaultValue: 'Search Regions',
      })
    : i18n.t('settings-GroupListFilter-placeholder', {
        defaultValue: 'Search Groups',
      });

  return (
    <>
      <CustomFlyoutButton
        onClick={() => setFlyoutOpen((prev) => !prev)}
        dataTestId="dashboards-SelectFlyout-button"
      >
        <Heading4 contrast="highColor">
          {i18n.t('settings-TeamMemberForm-groups')}
        </Heading4>
        <Heading3
          customStyle={(props) =>
            `line-height: ${FONTSIZE_THEMES.ICON_BUTTON_MD(props)}px;`
          }
        >
          {i18n.t('threads-Dashboard-selected', {
            defaultValue: '%{number} Selected',
            number: activeGroupIds.length,
          })}
        </Heading3>
      </CustomFlyoutButton>
      <Flyout
        modalTitle={i18n.t('threads-Dashboard-selectGroups', {
          defaultValue: 'Select Groups',
        })}
        modalTitleIcon={headerIcon}
        isOpen={flyoutOpen}
        direction="left"
        onRequestClose={handleRequestCloseFlyout}
        disableOverlay
        noPadding
        customFlyoutStyle={{
          boxShadow: '4px 0px 8px 0px rgba(0, 0, 0, 0.25)',
          width: '438px',
        }}
        customHeaderContainerStyle={(props) =>
          `background: ${THEMES.BACKGROUND_PRIMARY(
            props
          )}; box-shadow: initial; padding: 16px;`
        }
      >
        <Wrapper>
          <SearchBar>
            <FilterTypesWrapper>
              <TextInput
                dataTestId="dashboards-FilterGroups-search"
                type="text"
                placeholder={searchPlaceholder}
                autoComplete="off"
                value={filterText}
                onChange={(ev) => {
                  setFilterText(ev.target.value);
                  setSearchActive(true);
                  executeSearch(() => {
                    setSearchText(ev.target.value);
                  });
                }}
                iconRight
                icon={<i className="ri-search-line" />}
                hideBottomSpace
              />
            </FilterTypesWrapper>
          </SearchBar>
          <OptionsWrapper>
            <OptionsInterior>
              <GroupRegionSelectFlyoutFilterBy
                allowRegions={allowRegions}
                appliedFilters={appliedFilters}
                setAppliedFilters={setAppliedFilters}
                sortDirection={sortDirection}
                setSortDirection={setSortDirection}
              />

              <SelectAllCheckboxContainer onClick={handleSelectAll}>
                <Checkbox
                  checked={allAreActive}
                  onCheck={handleSelectAll}
                  dataTestId="select-all-btn"
                  label={i18n.t('threads-ThreadsListColumnHeader-selectAll')}
                  labelRight
                  customLabelStyle={(
                    props
                  ) => `color: ${THEMES.COLORED_FOREGROUND_HIGH(props)};
                      `}
                  disabled={noResults}
                />
              </SelectAllCheckboxContainer>
              {!selectionLimitLoading && showSelectionLimitWarning && (
                <MaxSelectionWarningContainer>
                  <Text>
                    <span>{selectedGroupIdsState.length}</span>
                    {i18n.t('dashboards-GroupSelect-selected', {
                      defaultValue: ' out of %{groupLimit} groups selected',
                      groupLimit: selectionLimit,
                    })}
                  </Text>
                </MaxSelectionWarningContainer>
              )}
              {selectionLimitLoading ||
              groupsByRegionLoading ||
              searchActive ? (
                <LoadingContainer dataTestId="dashboards-GroupSelect-Loading">
                  <Loading size="sm" />
                </LoadingContainer>
              ) : (
                renderScrollableArea()
              )}
            </OptionsInterior>
          </OptionsWrapper>
        </Wrapper>
        {showSelectionLimitError && (
          <ErrorPopUp dataTestId="dashboards-GroupSelect-errorMessage">
            <IconLabel
              size="sm"
              noOutline
              customStyle={(props) => `
                color: ${THEMES.COLORED_FOREGROUND_HIGH(props)};
                pointer-events: none;
                margin-left: 16px;
                cursor: default;
                  &:hover {
                    background: transparent;
                    box-shadow: none;
                  }
              `}
            >
              <i className="ri-information-line" />
            </IconLabel>
            <Text contrast="colorHigh" customStyle={() => `padding-left: 8px;`}>
              {i18n.t('dashboards-GroupSelect-error', {
                defaultValue: 'Too many groups selected. Please revise search',
              })}
            </Text>
          </ErrorPopUp>
        )}
        <Footer>
          <ButtonContainer>
            <Button
              type="secondary"
              dataTestId="dashboards-GroupSelect-close"
              onClick={handleCloseButton}
            >
              {i18n.t('settings-BusinessHours-close')}
            </Button>
            <Button
              type="primary"
              dataTestId="dashboards-GroupSelect-save"
              disabled={
                selectedGroupIdsState.length === 0 ||
                selectedGroupIdsState.length > selectionLimit ||
                !hasUnsavedChanges
              }
              onClick={handleSave}
            >
              {i18n.t('dashboards-GroupSelect-save', {
                defaultValue: 'Save View',
              })}
            </Button>
          </ButtonContainer>
        </Footer>
      </Flyout>
    </>
  );
};

GroupRegionSelectFlyout.propTypes = {
  activeGroupIds: PropTypes.array,
  onSelectionFinal: PropTypes.func.isRequired,
  // Enable or disable the region filter
  allowRegions: PropTypes.bool,
};

GroupRegionSelectFlyout.defaultProps = {
  activeGroupIds: [],
  allowRegions: false,
};

export default GroupRegionSelectFlyout;
