import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { IconButtonStyled } from './IconButton';

const IconLabelStyled = styled(IconButtonStyled).attrs({
  as: 'label',
})``;

const IconLabel = React.forwardRef(
  ({ contrast, size, disabled, noOutline, dataTestId, ...otherProps }, ref) => (
    <IconLabelStyled
      contrast={contrast}
      size={size}
      disabled={disabled}
      noOutline={noOutline}
      css={(props) => props?.customStyle?.(props)} // eslint-disable-line react/prop-types
      {...otherProps}
      data-testid={dataTestId}
      ref={ref}
    />
  )
);

IconLabel.propTypes = {
  contrast: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  noOutline: PropTypes.bool,
  dataTestId: PropTypes.string,
};

IconLabel.defaultProps = {
  contrast: 'high',
  size: 'md',
  disabled: false,
  noOutline: false,
  dataTestId: null,
};

IconLabel.displayName = 'IconLabel';

export default IconLabel;
