import { useLazyQuery } from '@apollo/client';
import { SELECTED_GROUPS_QUERY } from 'client-lib';
import { diff } from 'deep-diff';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setThreadsActiveGroupIds } from '../../actions/session';

const useOnLoadQuerySelectedGroups = () => {
  const currentUserId = useSelector(
    (state) => state.session?.currentUser?.userId
  );
  const threadsActiveGroupIds = useSelector(
    (state) => state.session?.threadsActiveGroupIds
  );
  const dispatch = useDispatch();

  const onCompleted = (data) => {
    const selectedGroups = data?.selectedGroups?.groupIds;
    let groupsDiff;

    if (selectedGroups) {
      groupsDiff = diff(selectedGroups, threadsActiveGroupIds);
    }

    if (groupsDiff && selectedGroups?.length) {
      dispatch(setThreadsActiveGroupIds(selectedGroups));
    }
  };

  const [selectedGroupsQuery] = useLazyQuery(SELECTED_GROUPS_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onCompleted,
  });

  // only query on mount, let the group selector worry about updates.
  useEffect(() => {
    if (currentUserId) selectedGroupsQuery();
  }, [currentUserId]);
};

export default useOnLoadQuerySelectedGroups;
