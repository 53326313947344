import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import { Picker } from 'emoji-mart/';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data/sets/14/apple.json';
import Popover from '@mui/material/Popover';
// import '/emoji-mart/css/emoji-mart.css';
import DetectClickOutside from '../Common/DetectClickOutside';

const EmojiMenu = ({
  children,
  handleEmojiSelection,
  open,
  onClickOutside,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleTouchTap = (e) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleEmojiClick = (e) => {
    handleEmojiSelection(e);
  };

  return (
    <div style={{ display: 'flex' }} data-testid="emoji-menu">
      {children({ emojiTouchTap: handleTouchTap })}
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <DetectClickOutside onClickOutside={onClickOutside}>
          <Picker
            onEmojiSelect={handleEmojiClick}
            data={data}
            noCountryFlags
            set="apple"
            emojiVersion="14"
            title="Insert an Emoji"
            emojiSize={24}
            sheetSize={32}
            enableFrequentEmojiSort
          />
        </DetectClickOutside>
      </Popover>
    </div>
  );
};

EmojiMenu.propTypes = {
  handleEmojiSelection: PropTypes.func,
  children: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClickOutside: PropTypes.func.isRequired,
};

EmojiMenu.defaultProps = {
  handleEmojiSelection: () => {},
};

export default EmojiMenu;
