export const SET_WHITE_LABEL = 'SET_WHITE_LABEL';
export const SET_HAS_QUERIED_WHITE_LABEL = 'SET_HAS_QUERIED_WHITE_LABEL';

export const setWhitelabel = (data) => ({
  type: SET_WHITE_LABEL,
  data,
});

export const setHasQueriedWhitelabel = () => ({
  type: SET_HAS_QUERIED_WHITE_LABEL,
});
