import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import i18n from 'i18n-js';
import { useDispatch } from 'react-redux';
import GroupMessageTemplate from '../GroupMessageTemplate';
import { PageError } from './GroupMessageContent';
import { IconButton, Loading, TextInput } from '../../../../elements';
import AnnouncementSenderField from './subComponents/AnnouncementSenderField';
import { LoadingWrapper } from './GroupMessageOverview';
import { triggerRefetchBroadcast } from '../../../../actions/general';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 550px;
  max-width: 550px;
  position: relative;
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => (props.noAnnouncementChannel ? '20px' : '0')};
`;

const GroupMessageName = ({
  closeWizard,
  setWizardPage,
  wizardStateSetters,
  wizardGlobalProps,
  mutations,
}) => {
  const dispatch = useDispatch();
  const { setAnnouncementId } = wizardStateSetters;
  const { createAnnouncementMutation } = mutations;
  const [messageName, setMessageName] = useState('');
  const [messageError, setMessageError] = useState('');
  const [outboundChannelId, setOutboundChannelId] = useState(
    wizardGlobalProps?.channelsData?.channels[0]?.id
  );
  const [pageError, setPageError] = useState('');
  const [loading, setIsLoading] = useState(false);

  const errorMsg = i18n.t('slideouts-GroupMessageName-genericError');
  const channelsLoading = wizardGlobalProps.channelsLoading;

  useEffect(() => {
    if (!outboundChannelId) {
      setPageError(
        i18n.t('slideouts-GroupMessageOverview-noBroadcastLine', {
          defaultValue:
            'You do not have access to an Announcement Line, please contact a manager or admin to obtain access to an announcement line.',
        })
      );
    }
  }, [outboundChannelId]);

  // this form will load before channels data gets queried at times.
  useEffect(() => {
    if (
      !outboundChannelId &&
      wizardGlobalProps?.channelsData?.channels[0]?.id
    ) {
      setOutboundChannelId(wizardGlobalProps?.channelsData?.channels[0]?.id);
    }
  }, [wizardGlobalProps?.channelsData?.channels[0]?.id]);

  const valid = messageName.length > 0 && outboundChannelId;

  const nameInput = useRef();

  useEffect(() => {
    nameInput?.current?.focus?.(); // eslint-disable-line no-unused-expressions
  }, []);

  useEffect(() => {
    if (pageError) setPageError('');
  }, [messageName, outboundChannelId]);

  const nextStep = () => setWizardPage('GroupMessageOverviewCombined');

  const saveAndNextStep = async () => {
    setIsLoading(true);

    const { data } = await createAnnouncementMutation({
      variables: {
        input: {
          subject: messageName,
          outboundChannelId,
        },
      },
    }).catch((e) => {
      console.error(e);
      setPageError(errorMsg);
      setIsLoading(false);
    });

    setIsLoading(false);

    if (data?.createAnnouncement?.errors) {
      setPageError(errorMsg);
    } else if (data?.createAnnouncement?.announcement?.id) {
      setAnnouncementId(data.createAnnouncement.announcement.id);
      nextStep();
    }
  };

  useEffect(() => {
    dispatch(triggerRefetchBroadcast(false));
  }, []);

  useEffect(() => {
    if (messageName.length > 255) {
      setMessageError(
        i18n.t('broadcasts-maximum-characterError', {
          defaultValue: "You've exceeded the 255 maximum character limit.",
        })
      );
    } else {
      setMessageError('');
    }
  }, [messageName]);

  return (
    <GroupMessageTemplate
      title={i18n.t('slideouts-GroupMessageName-createGroupBroadcast')}
      headerRightElement={
        <IconButton size="lg" onClick={closeWizard}>
          <i className="ri-close-line" />
        </IconButton>
      }
      showFooter
      continueButtonText={i18n.t('slideouts-GroupMessageName-saveContinue')}
      continueButtonAction={saveAndNextStep}
      continueButtonDisabled={!valid || messageError}
      backButtonAction={closeWizard}
    >
      <Container>
        {loading || channelsLoading ? (
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>
        ) : (
          <>
            {pageError && <PageError>{pageError}</PageError>}
            <InputContainer noAnnouncementChannel={!outboundChannelId}>
              <AnnouncementSenderField
                channelsData={wizardGlobalProps?.channelsData}
                outboundChannelId={outboundChannelId}
                onError={() => setPageError(errorMsg)}
                exteriorMutationHandling
                onChange={(channel) => setOutboundChannelId(channel?.value)}
              />
              <TextInput
                label={i18n.t('slideouts-GroupMessageName-messageName')}
                type="text"
                value={messageName}
                disabled={!outboundChannelId}
                onChange={(e) => setMessageName(e.target.value)}
                placeholder={i18n.t(
                  'slideouts-GroupMessageOverview-specialPromotion'
                )}
                error={messageError}
                ref={nameInput}
                dataTestId="group-message-name-input"
                helperText={i18n.t(
                  'slideouts-GroupMessageOverview-descriptiveName'
                )}
              />
            </InputContainer>
          </>
        )}
      </Container>
    </GroupMessageTemplate>
  );
};

GroupMessageName.propTypes = {
  closeWizard: PropTypes.func.isRequired,
  setWizardPage: PropTypes.func.isRequired,
  wizardState: PropTypes.object.isRequired,
  wizardStateSetters: PropTypes.object.isRequired,
  wizardGlobalProps: PropTypes.object.isRequired,
  mutations: PropTypes.object.isRequired,
};

export default GroupMessageName;
