import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import theme from 'styled-theming';
import styled from 'styled-components';
import Card from '../Card/Card';
import IconButton from '../Button/IconButton';
import COLORS from '../../styles/colors';

const BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.WHITE,
  dark: COLORS.PK_DARK.GREY_5,
  holiday: COLORS.PK.WHITE,
});

const FOOTER_BACKGOUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.LIGHT_GRAY,
  light: COLORS.PK.GREY_20,
  dark: COLORS.PK_DARK.GREY_4,
  holiday: COLORS.PK.GREY_20,
});

const sizeStyles = {
  sm: {
    width: '460px',
  },
  md: {
    width: '50vw',
  },
  lg: {
    width: '70vw',
  },
};
const createModalStyle = ({ size }) => ({
  top: 'auto',
  left: 'auto',
  right: 'auto',
  bottom: 'auto',
  background: 'rgb(255, 255, 255)',
  border: '0px',
  outline: 'none',
  // overflow: 'hidden',
  padding: '0',
  borderRadius: 4,
  width: sizeStyles[size].width,
  maxWidth: sizeStyles[size].width,
});

const InnerContainer = styled.div`
  min-height: 64px;
  display: flex;
  align-items: center;
`;

// note about custom styles, I'm not going to put any into this for now, since all modals should always match. But if the need arises lmk and we'll figure something out
const Modal = ({
  children,
  isOpen,
  onRequestClose,
  size,
  modalTitle,
  primaryButtonText,
  primaryButtonOnClick,
  secondaryButtonText,
  secondaryButtonOnClick,
  primaryButtonProps,
  secondaryButtonProps,
  disableOverlay,
  customCardProps,
  headerIcon,
  closeButtonProps,
  ...props
}) => {
  const closeButton = (
    { onClick } // eslint-disable-line react/prop-types
  ) => (
    <IconButton size="lg" onClick={onClick} {...closeButtonProps}>
      <i className="ri-close-line" />
    </IconButton>
  );

  return (
    <ReactModal
      style={{
        overlay: {
          backgroundColor: disableOverlay
            ? 'transparent'
            : 'rgba(0, 0, 0, 0.75)',
          zIndex: '11',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        content: createModalStyle({ size }),
      }}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      css={(props) => props?.customStyle?.(props)} // eslint-disable-line react/prop-types
      {...props}
    >
      <Card
        headerText={modalTitle}
        customHeaderButton={closeButton}
        headerButtonOnClick={onRequestClose}
        primaryFooterButtonText={primaryButtonText}
        primaryFooterButtonOnClick={primaryButtonOnClick}
        secondaryFooterButtonText={secondaryButtonText}
        secondaryFooterButtonOnClick={secondaryButtonOnClick}
        primaryFooterButtonProps={primaryButtonProps}
        secondaryFooterButtonProps={secondaryButtonProps}
        headerIcon={headerIcon}
        customStyle={(props) => `
        border-radius: 4px; 
        border-width: 0; 
        background-color: ${BACKGROUND(props)};
      `}
        customFooterStyle={(props) =>
          `background-color: ${FOOTER_BACKGOUND(props)};`
        }
        {...customCardProps}
      >
        <InnerContainer>{children}</InnerContainer>
      </Card>
    </ReactModal>
  );
};
Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  size: PropTypes.string,
  modalTitle: PropTypes.string,
  primaryButtonText: PropTypes.string,
  primaryButtonOnClick: PropTypes.func,
  secondaryButtonText: PropTypes.string,
  secondaryButtonOnClick: PropTypes.func,
  headerIcon: PropTypes.func,
  primaryButtonProps: PropTypes.object,
  secondaryButtonProps: PropTypes.object,
  disableOverlay: PropTypes.bool,
  customCardProps: PropTypes.object,
  closeButtonProps: PropTypes.object,
};
Modal.defaultProps = {
  size: 'md',
  modalTitle: 'Modal Title',
  primaryButtonText: '',
  primaryButtonOnClick: null,
  secondaryButtonText: '',
  secondaryButtonOnClick: null,
  headerIcon: null,
  closeButtonProps: {},
  primaryButtonProps: {},
  secondaryButtonProps: {},
  disableOverlay: false,
  customCardProps: {},
};
export default Modal;
