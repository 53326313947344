import styled, { keyframes, createGlobalStyle } from 'styled-components';
import THEMES from '../../styles/themes/app';

// ========================================================
//  Global Scrollbar Styles
// ========================================================
export const GlobalScrollbarStyle = createGlobalStyle`
  ${(props) =>
    props.theme.mode === 'dark'
      ? `
  *::-webkit-scrollbar {
    background: ${THEMES.BACKGROUND_PRIMARY(props)};
    width: 10px;
    height: 3px;
  }
  *::-webkit-scrollbar-thumb {
    background: ${THEMES.FOREGROUND_MED(props)};
    border-radius: 8px;
    border: 2px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }
  * {
    scrollbar-color: ${THEMES.FOREGROUND_MED(props) + ' transparent'};
  }
        `
      : ''}`;

// ========================================================
//  Grid Classes
// ========================================================
export const Body = styled.div`
  display: flex;
  flex: 1 1 auto;
  font-family: 'Barlow', sans-serif;
  overflow: hidden;

  @media print {
    visibility: hidden;
  }
`;
export const Column = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  border-right: solid 1px ${THEMES.BORDER_COLOR};
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  height: 100%;
  max-width: ${(props) => props.maxWidth || '100%'};
`;
export const TwoThirdsColumn = styled(Column)`
  max-width: 66.6%;
  flex: 2 1 0%;
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  @media print {
    visibility: visible;
    position: absolute;
    border: none;
    left: 0;
    & p,
    h1,
    h2,
    h3,
    h4,
    h5,
    span,
    div,
    button {
      color: black !important;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: ${(props) => (props.fullWidth ? '100%' : 'inherit')};
`;
export const OverflowYWrap = styled.section`
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  @media print {
    overflow: visible;
    height: 100%;
    background-color: ${THEMES.BACKGROUND_PRIMARY};
    & p,
    h1,
    h2,
    h3,
    h4,
    h5,
    span,
    div,
    button {
      color: black !important;
    }
  }
  height: 100%;
  box-shadow: ${(props) => props.boxShadow || null};
`;

const slideIn = keyframes`
  0% { transform: translateX(100%); }
  100% { transform: translateX(0%); }
`;
export const SlideInColumn = styled(Column)`
  animation: ${slideIn} 0.35s ease-out 0s 1;
  border-left: 1px solid ${THEMES.BORDER_COLOR};
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  z-index: 1;
  position: sticky;
  height: calc(
    100vh - 201px
  ); /* 201px = height of SubmmitMessageInput & ThreadDetailsHeader*/
  top: 0;
`;
export const CustomerSlideInColumn = styled(Column)`
  animation: ${slideIn} 0.35s ease-out 0s 1;
  border-left: 1px solid ${THEMES.BORDER_COLOR};
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  z-index: 1;
  position: sticky;
  height: calc(100vh - 75px); /* 75px = height of ThreadDetailsHeader*/
  top: 0;
  max-width: 30vw;
  @media print {
    display: none;
  }
`;
