import { useState } from 'react';

const useTargetedAudience = (defaultShowSelectedAudience = false) => {
  const [selectedAudience, setSelectedAudience] = useState([]);
  const [showSelectedAudience, setShowSelectedAudience] = useState(
    defaultShowSelectedAudience
  );
  const [futureRemovals, setFutureRemovals] = useState([]);

  const getFilteredAudience = (audience) => ({
    contactIds: audience
      .filter((audience) => audience.__typename === 'CustomerContact')
      .map((result) => result.id),
    companyIds: audience
      .filter((audience) => audience.__typename === 'CustomerAccount')
      .map((result) => result.id),
    groupIds: audience
      .filter((audience) => audience.__typename === 'Group')
      .map((result) => result.id),
    tagIds: audience
      .filter((audience) => audience.__typename === 'Tag')
      .map((result) => result.id),
  });

  return {
    selectedAudience,
    setSelectedAudience,
    getFilteredAudience,
    futureRemovals,
    setFutureRemovals,
    showSelectedAudience,
    setShowSelectedAudience,
  };
};

export default useTargetedAudience;
