import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  padding: 0 24px 0 24px;
`;

export const CardContainer = styled.div`
  padding-top: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 24;
`;

export const FeedbackOffContainer = () => `
  height: calc(100vh - 152px);
  margin-top: 16px;
`;

export const HeaderStyle = () => `
  width: 500px;
  text-align: center;
  padding-bottom: 24px;
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 100px);
`;
