import React from 'react';
import PropTypes from 'prop-types';
import { contactName, slugify } from 'client-lib/src/lib/utils/helpers';
import styled from 'styled-components';
import { EntityCard, EmphasisText, Button } from '../../..';
import THEMES from '../../../../styles/themes/library/textInput';
import APP_THEMES from '../../../../styles/themes/app';
import { entityCardSuggestionCustomStyles } from '../UniversalSuggestion';

const RightAlignedContainer = styled.div`
  padding-left: 40px;
  padding-right: 8px;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  background-color: ${(props) =>
    props.isHighlighted && !props.disabled
      ? THEMES.OPTION_BACKGROUND_HOVER(props)
      : APP_THEMES.BACKGROUND_PRIMARY(props)};
  border-bottom: 1px solid ${APP_THEMES.BORDER_COLOR};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: ${(props) => (props.isHighlighted ? 'pointer' : 'auto')};
`;

const rightAlignedTextStyle = (props) => `
  color: ${APP_THEMES.FOREGROUND_LOW(props)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ContactSuggestion = ({
  suggestion,
  isHighlighted,
  disabled,
  rightAlignedText,
  displayAddPhoneNumberButton,
}) => (
  <>
    <EntityCard
      avatarChildren={contactName(suggestion)}
      maintext={contactName(suggestion)}
      subtext={suggestion?.account?.name}
      disabled={!!disabled}
      avatarProps={{
        size: 'md',
        type: 'external',
      }}
      backgroundColor={
        isHighlighted && !disabled
          ? THEMES.OPTION_BACKGROUND_HOVER
          : THEMES.OPTION_BACKGROUND
      }
      maintextColor={
        isHighlighted && !disabled
          ? THEMES.OPTION_TEXT_HOVER
          : THEMES.OPTION_TEXT
      }
      customContainerStyle={entityCardSuggestionCustomStyles}
      dataTestId={`infinite-contact-suggestion-${slugify(
        contactName(suggestion),
        { lower: true }
      )}`}
    />
    {rightAlignedText && (
      <RightAlignedContainer isHighlighted={isHighlighted} disabled={disabled}>
        {displayAddPhoneNumberButton && !suggestion.phoneNumber ? (
          <Button customStyle={() => 'padding-right: 0'} type="link">
            {rightAlignedText}
          </Button>
        ) : (
          <EmphasisText customStyle={rightAlignedTextStyle}>
            {rightAlignedText}
          </EmphasisText>
        )}
      </RightAlignedContainer>
    )}
  </>
);

ContactSuggestion.propTypes = {
  suggestion: PropTypes.object.isRequired,
  isHighlighted: PropTypes.bool,
  disabled: PropTypes.bool,
  rightAlignedText: PropTypes.string,
  displayAddPhoneNumberButton: PropTypes.bool,
};

ContactSuggestion.defaultProps = {
  isHighlighted: false,
  disabled: false,
  rightAlignedText: '',
  displayAddPhoneNumberButton: true,
};

export default ContactSuggestion;
