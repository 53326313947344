import { useQuery } from "@apollo/client";
import { TEMPLATE_QUERY } from "../../../../index";

const useTemplateQuery = ({ client, templateId }) => {
  const { loading: templateLoading, data } = useQuery(TEMPLATE_QUERY, {
    client,
    fetchPolicy: "no-cache",
    variables: { templateId },
    skip: !templateId,
  });

  return {
    templateData: data?.template?.template,
    templateLoading,
  };
};

export default useTemplateQuery;
