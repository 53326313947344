import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { OPEN_THREAD_COUNT_FOR_USER_BY_USER } from "../../..";
import { getOpenThreadCountUpperBound } from "../../../utils/helpers";

const TOP_N = 100;

function useOpenThreadsByUserFlyoutWidget({ client, groupId }) {
  const {
    data: openThreadCountByUserData,
    loading: openThreadCountByUserLoading,
  } = useQuery(OPEN_THREAD_COUNT_FOR_USER_BY_USER, {
    client,
    variables: { topN: TOP_N, groupIds: [groupId] },
    fetchPolicy: "network-only",
  });

  const openThreadCountByUser =
    openThreadCountByUserData?.openThreadCountForUserByUser;
  const widgetsLoading = openThreadCountByUserLoading;

  // PRKP-4842 - Open threads by user widget now uses the highest open thread count
  // as the total open thread count for the group
  // Counts are return in descending order of open threads
  const openThreadCountUpperBound = useMemo(
    () => getOpenThreadCountUpperBound(openThreadCountByUser),
    [openThreadCountByUser]
  );

  return {
    openThreadCountUpperBound,
    openThreadCountByUser,
    widgetsLoading,
  };
}

export default useOpenThreadsByUserFlyoutWidget;
