import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  CellMeasurer,
  CellMeasurerCache,
  List,
  InfiniteLoader,
  AutoSizer,
} from 'react-virtualized';
import i18n from 'i18n-js';
import { Loading } from '../../elements';

const defaultRowHeight = 112;

const LoadingRowStyle = styled.div`
  height: ${(props) => props.height || defaultRowHeight}px;
  border-bottom: ${(props) =>
    props.borderBottom ? '1px solid rgb(224, 224, 224)' : 'none'};
  background-color: ${(props) => props.backgroundColor || 'none'};
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoResultsWrap = styled.div`
  height: ${defaultRowHeight}px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`;

const LoadingRow = ({ height, borderBottom, backgroundColor }) => (
  <LoadingRowStyle
    height={height}
    borderBottom={borderBottom}
    backgroundColor={backgroundColor}
  >
    <LoadingWrapper>
      <Loading size="xs" />
    </LoadingWrapper>
  </LoadingRowStyle>
);

LoadingRow.propTypes = {
  height: PropTypes.number.isRequired,
  borderBottom: PropTypes.bool,
  backgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

LoadingRow.defaultProps = {
  borderBottom: false,
  backgroundColor: '',
};

const cache = new CellMeasurerCache({
  defaultHeight: 60,
  minHeight: 30,
  fixedWidth: true,
});

class InfiniteScroll extends Component {
  state = {
    loadingBottom: false,
    loadingTop: false,
    topLoadJumpDown: '',
  };

  componentDidMount() {
    this._ismounted = true;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      scrollableList,
      bidirectionalScroll,
      dynamicHeight,
      rerenderDynamicHeight,
    } = this.props;

    if (
      (!prevProps.loading &&
        prevProps.scrollableList &&
        this.props.scrollableList &&
        prevProps.scrollableList.length !== this.props.scrollableList.length) ||
      prevState.loadingTop ||
      prevState.loadingBottom
    ) {
      this.setState({ loadingBottom: false, loadingTop: false }); // eslint-disable-line react/no-did-update-set-state
    }

    // these two if statements are to help with a smooth scrolling UI. when users are loaded and added to the top
    // of the list, this will make sure that the starting point for the new customers is 50 down from the top
    // making it look like a smooth scrolling UI.
    if (
      bidirectionalScroll &&
      scrollableList &&
      scrollableList.length &&
      prevProps.scrollableList.length &&
      scrollableList[0].id &&
      prevProps.scrollableList[0].id &&
      scrollableList.length !== prevProps.scrollableList.length &&
      scrollableList[0].id !== prevProps.scrollableList[0].id &&
      prevProps.hasPreviousPage
    ) {
      this.setState({ topLoadJumpDown: prevProps.scrollableList[0].id }); // eslint-disable-line react/no-did-update-set-state
    }

    if (
      // recompute height when passed prop rerenderDynamicHeight
      dynamicHeight &&
      rerenderDynamicHeight
    ) {
      this.recomputeDynamicHeight();
    }

    if (
      // this is to recompute dynamic height when switching customers & loading is done, also calls onInitialLoad prop.
      prevProps.loading &&
      !this.props.loading &&
      this.list &&
      this.props.dynamicHeight &&
      prevProps.scrollableList &&
      prevProps.scrollableList.length === 0
    ) {
      this.recomputeDynamicHeight(() => this.props.onInitialLoad());
    }

    if (
      this.list &&
      this.props.scrollToAlignment === 'end' &&
      !this.state.loadingTop &&
      this.props.dynamicHeight
    ) {
      setTimeout(() => {
        if (this._ismounted) {
          let i = 0;

          const scrollToBottom = () => {
            if (
              this.list &&
              this.props.rerenderHeightId === prevProps.rerenderHeightId // checks if user opens email message and doesn't trigger scroll to bottom if they have
            ) {
              const topOffsetToBottom = this.list.getOffsetForRow(
                this.getScrollableListTrueLength()
              );
              this.list.scrollToPosition(topOffsetToBottom);
              return topOffsetToBottom;
            }
            return null;
          };

          const recursiveCallScrollToBottom = () => {
            const currentOffset = scrollToBottom();
            if (
              currentOffset !==
                this.list.getOffsetForRow(this.getScrollableListTrueLength()) &&
              i < 5
            ) {
              i += 1;
              recursiveCallScrollToBottom();
            }
          };

          recursiveCallScrollToBottom();
        }
      }, 100);
    }
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  // eslint-disable-next-line react/sort-comp
  recomputeDynamicHeight = (cb) => {
    setTimeout(() => {
      if (this._ismounted) {
        cache.clearAll();

        if (this.list) this.list.recomputeRowHeights();
        if (cb) cb();
      }
    }, 100);
  };

  isRowLoaded = ({ index }) => {
    const { hasPreviousPage, hasNextPage, scrollableList } = this.props;
    const indexLoadingAccounted = hasPreviousPage ? index - 1 : index;

    if (indexLoadingAccounted === -1 && hasPreviousPage) return true;
    if (hasNextPage && indexLoadingAccounted === scrollableList.length)
      return true;

    return !!scrollableList[indexLoadingAccounted];
  };

  renderRow = ({ index, key, style, parent }) => {
    const {
      renderRow,
      scrollableList: list,
      hasPreviousPage,
      hasNextPage,
      dynamicHeight,
      loadingHeight,
      loadingBackgroundColor,
      loadingBorderBottom,
    } = this.props;
    let indexLoadingAccounted = index;
    let styles = style;

    if (hasPreviousPage) indexLoadingAccounted -= 1;

    if (
      indexLoadingAccounted === -1 ||
      (hasNextPage && indexLoadingAccounted === list.length)
    ) {
      return dynamicHeight ? (
        <CellMeasurer
          key={key}
          cache={cache}
          columnIndex={0}
          parent={parent}
          rowIndex={index}
        >
          <div key={key} style={styles}>
            <LoadingRow
              backgroundColor={loadingBackgroundColor}
              borderBottom={loadingBorderBottom}
              height={loadingHeight}
            />
          </div>
        </CellMeasurer>
      ) : (
        <div key={key} style={styles}>
          <LoadingRow
            backgroundColor={loadingBackgroundColor}
            borderBottom={loadingBorderBottom}
            height={loadingHeight}
          />
        </div>
      );
    }

    // this is specifically here for images inside of the threads list
    if (dynamicHeight) {
      const imgAttachmentCount =
        list[index] &&
        list[index].node &&
        list[index].node.attachments &&
        list[index].node.attachments.reduce(
          (count, attachment) =>
            attachment.mimeType === 'image/jpeg' ? count + 1 : count,
          0
        );

      if (imgAttachmentCount)
        styles = { ...styles, minHeight: 60 + imgAttachmentCount * 250 };
    }

    return dynamicHeight ? (
      <CellMeasurer
        key={key}
        cache={cache}
        columnIndex={0}
        parent={parent}
        rowIndex={index}
      >
        <div key={key} style={styles}>
          {renderRow({ list, index, style: styles })}
        </div>
      </CellMeasurer>
    ) : (
      <div key={key} style={styles}>
        {renderRow({ list, index: indexLoadingAccounted })}
      </div>
    );
  };

  loadMoreRows = (scrolling) => {
    const { loadMoreRows, hasPreviousPage, hasNextPage } = this.props;
    if (scrolling === 'top' && hasPreviousPage) {
      this.setState({ loadingTop: true, loadingBottom: false }, () =>
        loadMoreRows('top')
      );
    } else if (scrolling !== 'top' && hasNextPage) {
      this.setState({ loadingTop: false, loadingBottom: true }, () =>
        loadMoreRows('bottom')
      );
    }
  };

  onScroll = (ev) => {
    const { scrollTop } = ev;
    const { loadingBottom } = this.state;
    const { loading, bidirectionalScroll } = this.props;
    if (bidirectionalScroll && !loading && scrollTop === 0 && !loadingBottom) {
      this.loadMoreRows('top');
    }
  };

  noRowsRenderer = () =>
    this.props.noRowsElement || (
      <NoResultsWrap>
        {i18n.t('customers-InfiniteScroll-noResultsFound', {
          defaultValue: 'No results found',
        })}
      </NoResultsWrap>
    );

  loadingRowRender = () => <LoadingRow height={this.props.loadingHeight} />;

  getScrollableListTrueLength = () => {
    const { hasPreviousPage, hasNextPage, scrollableList } = this.props;
    let scrollableLength = scrollableList.length;

    if (hasPreviousPage) scrollableLength += 1;
    if (hasNextPage) scrollableLength += 1;

    return scrollableLength;
  };

  render() {
    const {
      loading,
      scrollToIndex,
      scrollToAlignment,
      onRowsRendered: additionalOnRowsRendered,
      hasPreviousPage,
      height,
      scrollableList,
      dynamicHeight,
      rerenderDynamicHeight,
      loadingHeight,
      listItemHeight,
    } = this.props;

    if (!scrollableList) return null;

    const { topLoadJumpDown } = this.state;

    const rowCount = this.getScrollableListTrueLength();

    let listProps = {
      rowCount,
      rowHeight: listItemHeight,
      onScroll: this.onScroll,
      rowRenderer: this.renderRow,
      noRowsRenderer: loading ? this.loadingRowRender : this.noRowsRenderer,
      style: { outline: 'none' },
      scrollToAlignment: scrollToAlignment || 'start',
    };

    if (scrollToIndex || scrollToIndex === 0) {
      listProps = {
        ...listProps,
        scrollToIndex: hasPreviousPage ? scrollToIndex + 1 : scrollToIndex,
      };
    } else if (!scrollToIndex && topLoadJumpDown) {
      listProps = {
        ...listProps,
        scrollToIndex: scrollableList.findIndex(
          (li) => li.id === topLoadJumpDown
        ),
      };
    }

    if (dynamicHeight) {
      cache.clearAll();
      listProps = {
        ...listProps,
        deferredMeasurementCache: cache,
        rowHeight: cache.rowHeight,
      };
    }

    return (
      <div style={{ flex: '1 1 auto', height: height || '100%' }}>
        <InfiniteLoader
          isRowLoaded={this.isRowLoaded}
          loadMoreRows={this.loadMoreRows}
          rowCount={rowCount + 1}
        >
          {({ onRowsRendered, registerChild }) => (
            <AutoSizer>
              {({ width, height: autoSizerHeight }) => (
                <List
                  {...listProps}
                  width={width}
                  height={autoSizerHeight}
                  ref={(node) => {
                    this.list = node;
                    registerChild(node);
                  }}
                  onRowsRendered={(renderData) => {
                    onRowsRendered(renderData);

                    if (
                      this.list &&
                      scrollToIndex &&
                      dynamicHeight &&
                      rerenderDynamicHeight
                    ) {
                      const currentOffset =
                        this.list.getOffsetForRow(scrollToIndex);
                      const howMuchScroll =
                        renderData.overscanStartIndex === scrollToIndex
                          ? currentOffset - loadingHeight
                          : currentOffset - 10;
                      this.list.scrollToPosition(howMuchScroll);
                    }

                    if (topLoadJumpDown) this.setState({ topLoadJumpDown: '' });
                    if (additionalOnRowsRendered)
                      additionalOnRowsRendered(renderData, this.props);
                  }}
                />
              )}
            </AutoSizer>
          )}
        </InfiniteLoader>
      </div>
    );
  }
}

InfiniteScroll.propTypes = {
  scrollableList: PropTypes.array.isRequired,
  bidirectionalScroll: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  renderRow: PropTypes.func.isRequired,
  onInitialLoad: PropTypes.func,
  loadMoreRows: PropTypes.func,
  hasNextPage: PropTypes.bool,
  hasPreviousPage: PropTypes.bool,
  scrollToIndex: PropTypes.number,
  scrollToAlignment: PropTypes.string,
  onRowsRendered: PropTypes.func,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  noRowsElement: PropTypes.element,
  dynamicHeight: PropTypes.bool,
  loadingHeight: PropTypes.number,
  listItemHeight: PropTypes.number,
  loadingBackgroundColor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
  loadingBorderBottom: PropTypes.bool,
  resetCellMeasurerCache: PropTypes.func,
  rerenderDynamicHeight: PropTypes.bool,
  rerenderHeightId: PropTypes.string,
};

InfiniteScroll.defaultProps = {
  bidirectionalScroll: false,
  rerenderDynamicHeight: false,
  hasNextPage: false,
  hasPreviousPage: false,
  scrollToIndex: null,
  scrollToAlignment: null,
  onRowsRendered: () => {},
  onInitialLoad: () => {},
  loadMoreRows: () => {},
  height: '',
  noRowsElement: null,
  dynamicHeight: false,
  loadingHeight: defaultRowHeight,
  listItemHeight: defaultRowHeight,
  loadingBackgroundColor: '',
  loadingBorderBottom: false,
  resetCellMeasurerCache: () => {},
  rerenderHeightId: '',
};

export default InfiniteScroll;
