import { calcCombinedAttachmentSize } from 'client-lib/src/lib/utils/helpers';
import type { ChangeEvent } from 'react';
import validateAttachment from './validateAttachment';
import {
  MAX_COMBINED_FILE_SIZE,
  I18N_ERROR_MESSAGES,
  I18nError,
} from './constants';
import type { Attachment } from './types';

const handleFileReaderInputChange = (
  event: ChangeEvent<HTMLInputElement>,
  onFileError: (error: I18nError) => void,
  handleSetAttachment: (originalFile: File, originalFilename?: string) => void,
  attachments?: Attachment[],
  maxFileSize?: number
) => {
  const files = event.target.files
    ? Array.from(event.target.files).map((file) => file)
    : [];

  // If only one file is uploaded and maxFileSize is provided, we don't need to check the combined file size as this will be handled in validateAttachment with a more specific error message
  const ignoreMultiFileValidation = files.length === 1 && maxFileSize;

  if (
    !ignoreMultiFileValidation &&
    calcCombinedAttachmentSize(files, attachments || []) >
      MAX_COMBINED_FILE_SIZE
  ) {
    onFileError(
      new I18nError(I18N_ERROR_MESSAGES.FILE_SIZE_LIMIT, {
        fileSizeLimit: MAX_COMBINED_FILE_SIZE / 1000000,
      })
    );
    return;
  }

  if (attachments && files.length + attachments.length > 10) {
    onFileError(new I18nError(I18N_ERROR_MESSAGES.LIMIT_10));
    return;
  }

  files.forEach((file) =>
    validateAttachment(
      file as File,
      onFileError,
      handleSetAttachment,
      attachments || [],
      maxFileSize
    )
  );

  // event.target.value = '';
};

export default handleFileReaderInputChange;
