/* eslint-disable import/no-cycle */
import React, { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Fade from '@mui/material/Fade';
import SnackbarContent from '@mui/material/SnackbarContent';
import i18n from 'i18n-js';
import MUISnackbar from '@mui/material/Snackbar';
import { ThemeContext } from 'styled-components';
import { closeSnackbar } from '../../actions/general';
import { Button, Text } from '..';
import THEMES from '../../styles/themes/app';
import type { AppState } from '../../utils/helpers/types';
import {
  CheckIcon,
  getButtonLinkStyle,
  getSnackbarContentStyle,
  getSnackbarStyle,
  LinkButton,
  SnackbarContentsWrapper,
  TruncateOverflowWrapper,
} from './styles';

const Snackbar = () => {
  const general = useSelector((state: AppState) => state?.general);
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    snackType,
    snackAutoHide,
    snackbarOpen,
    snackMessage,
    snackButtonLink,
  } = general;
  const autoHideDuration = snackAutoHide ? 4000 : null;
  const styledTheme = useContext(ThemeContext);

  const getSnackColor = useMemo(() => {
    if (snackType === 'warning') {
      return THEMES.THEME_YELLOW({ theme: styledTheme });
    }
    if (snackType === 'error') {
      return THEMES.THEME_RED({ theme: styledTheme });
    }

    return THEMES.THEME_GREEN({ theme: styledTheme });
  }, [snackType, styledTheme]);

  const getSnackIcon = useMemo(() => {
    if (snackType === 'warning') {
      return 'ri-indeterminate-circle-line';
    }
    if (snackType === 'error') {
      return 'ri-error-warning-line';
    }
    return 'ri-checkbox-circle-line';
  }, [snackType]);

  return (
    <MUISnackbar
      style={getSnackbarStyle(snackbarOpen)}
      open={snackbarOpen}
      autoHideDuration={autoHideDuration}
      TransitionComponent={Fade}
      onClose={() => dispatch(closeSnackbar())}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <SnackbarContent
        style={getSnackbarContentStyle(getSnackColor)}
        message={
          <SnackbarContentsWrapper>
            <CheckIcon className={getSnackIcon} />
            <Text contrast="colorHigh">
              <TruncateOverflowWrapper>{snackMessage}</TruncateOverflowWrapper>
            </Text>
            {snackButtonLink ? (
              <LinkButton>
                <Button
                  noOutline
                  customStyle={getButtonLinkStyle}
                  type="link"
                  onClick={() => {
                    history.push({
                      pathname: snackButtonLink,
                    });
                    dispatch(closeSnackbar());
                  }}
                >
                  {i18n.t('threads-GlobalHandlers-viewNowLink')}
                </Button>
              </LinkButton>
            ) : null}
          </SnackbarContentsWrapper>
        }
      />
    </MUISnackbar>
  );
};

export default Snackbar;
