import React from 'react';
import { useDispatch } from 'react-redux';
import useHideSidebarForRoute from '../../utils/hooks/useHideSidebarForRoute';
import { setActiveSidebar } from '../../actions/general';
// import { Link } from 'react-router-dom';

const SsoAuthFail = () => {
  const dispatch = useDispatch();
  const dispatchActiveSidebar = (input) => dispatch(setActiveSidebar(input));
  useHideSidebarForRoute({ dispatchActiveSidebar });
  return (
    <div style={{ margin: '2rem auto', textAlign: 'center' }}>
      <p>SSO authentication failure</p>
    </div>
  );
};

export default SsoAuthFail;
