import { useQuery } from "@apollo/client";
import CHANNELS_QUERY from "../../../graphql/query/ChannelsQuery.graphql";

function useHasFaxChannel({ currentUser, threadsActiveGroupIds, client }) {
  const { data: channelsData } = useQuery(CHANNELS_QUERY, {
    client,
    variables: {
      accountId: currentUser?.accountId,
    },
  });

  const hasFaxChannel = !!channelsData?.channels
    ?.filter((channel) => channel.faxConfig !== null)
    ?.filter((channel) =>
      threadsActiveGroupIds?.some((activeId) => activeId === channel.group.id)
    ).length;

  return { hasFaxChannel };
}

export default useHasFaxChannel;
