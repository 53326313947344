import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setTargetedAudienceFilter } from '../../actions/general';
import SearchBar from '../SearchBar/SearchBar';

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 4px 0 8px 0;
`;

const RightSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
`;

const TargetedAudienceFilter = ({ placeholder }) => {
  const dipsatch = useDispatch();
  const targetedAudienceFilter = useSelector(
    (state) => state?.general?.targetedAudienceFilter
  );

  // Clean up whenever unmounts
  useEffect(
    () => () => {
      dipsatch(setTargetedAudienceFilter(''));
    },
    []
  );

  return (
    <Wrapper>
      <RightSection>
        <SearchBar
          value={targetedAudienceFilter}
          setValue={(filter) => dipsatch(setTargetedAudienceFilter(filter))}
          placeholder={placeholder}
          hideBottomSpace
        />
      </RightSection>
    </Wrapper>
  );
};

TargetedAudienceFilter.propTypes = {
  placeholder: PropTypes.string.isRequired,
};

export default TargetedAudienceFilter;
