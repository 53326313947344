import { $getRoot, type EditorState } from 'lexical';

export enum MutationMethod {
  text = 1,
}

export type RichTextChangeListener = (editorState: EditorState) => void;

const onTextChange = (
  editorState: EditorState,
  onChange: <T>(text: T) => void
) => {
  editorState.read(() => {
    const text = $getRoot().getTextContent();
    onChange<string>(text);
  });
};

const MutationMap = {
  [MutationMethod.text]: onTextChange,
  // html: fn,
};

const buildRichTextOnChange = (
  MutationType: MutationMethod,
  onChangeFn: <T>(changedValue: T) => void
): RichTextChangeListener => {
  return (editorState: EditorState) => {
    MutationMap[MutationType](editorState, onChangeFn);
  };
};

export default buildRichTextOnChange;
