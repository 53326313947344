import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Span } from '../../elements';
import THEMES from '../../styles/themes/app';

const Highlight = styled.span`
  padding: 0 1px;
  background-color: ${THEMES.THEME_YELLOW};
  color: #111;
`;

const TextWithHighlights = ({ highlightTarget, text, contrast }) => {
  if (
    highlightTarget === undefined ||
    highlightTarget === null ||
    highlightTarget === ''
  ) {
    return <Span contrast={contrast}>{text}</Span>;
  }

  if (text === undefined || text === null || text === '') {
    return <Span contrast={contrast} />;
  }

  let fragmentCount = 0;
  let textToFormat = text;
  let termIndex = textToFormat.toLowerCase().indexOf(highlightTarget);
  let fragment = '';
  let span = '';
  const textSpans = [];

  while (termIndex >= 0) {
    if (termIndex > 0) {
      fragmentCount += 1;
      fragment = textToFormat.substring(0, termIndex);
      span = <span key={fragmentCount}>{fragment}</span>;

      textSpans.push(span);
    }

    fragmentCount += 1;
    fragment = textToFormat.substring(
      termIndex,
      termIndex + highlightTarget.length
    );
    span = <Highlight key={fragmentCount}>{fragment}</Highlight>;
    textSpans.push(span);

    textToFormat = textToFormat.substring(termIndex + highlightTarget.length);
    termIndex = textToFormat.toLowerCase().indexOf(highlightTarget);
  }

  fragmentCount += 1;
  span = <span key={fragmentCount}>{textToFormat}</span>;
  textSpans.push(span);

  return <Span contrast={contrast}>{textSpans}</Span>;
};

TextWithHighlights.propTypes = {
  highlightTarget: PropTypes.string,
  text: PropTypes.string,
  contrast: PropTypes.string,
};

TextWithHighlights.defaultProps = {
  highlightTarget: '',
  text: '',
  contrast: 'high',
};

export default TextWithHighlights;
