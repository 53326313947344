import { isThreadUnread } from 'client-lib';
import { useEffect } from 'react';

/**
 * Custom hook that sets the thread counts based on the provided parameters.
 *
 * @param {Object} options - The options object.
 * @param {string} options.contactId - The ID of the contact.
 * @param {Array} options.inboxThreads - The array of inbox threads.
 * @param {Array} options.myOpenThreads - The array of open threads.
 * @param {Function} options.setThreadCounts - The function to set the thread counts.
 * @returns {useSetThreadReturn} The thread counts.

 * @typedef {Object} useSetThreadReturn
 * @property {number} unclaimedThreadCount - The number of unclaimed threads.
 * @property {number} unreadThreadCount - The number of unread threads.
 */
const useSetThreadCounts = ({
  contactId,
  inboxThreads,
  myOpenThreads,
  setThreadCounts,
}) => {
  const unclaimedThreadCount = inboxThreads.length;
  const unreadThreadCount = myOpenThreads.filter((thread) =>
    isThreadUnread(thread, contactId)
  ).length;

  useEffect(() => {
    setThreadCounts({ unclaimedThreadCount, unreadThreadCount });
  }, [unclaimedThreadCount, unreadThreadCount]);

  return {
    unclaimedThreadCount,
    unreadThreadCount,
  };
};

export default useSetThreadCounts;
