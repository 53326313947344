/**
 * Returns true when both arrays have the same string values.
 * Does not care about order of values in the arrays.
 * @param sourceArray Array of values to check against
 * @param checkIncludes Array of values to check if they are included in sourceArray
 * @returns boolean
 */
const includesAll = (sourceArray: string[], checkIncludes: string[]) =>
  checkIncludes.every((v) => sourceArray.includes(v));

export default includesAll;
