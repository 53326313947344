import React, { type ReactNode } from 'react';
import i18n from 'i18n-js';
import styled from 'styled-components';

import THEMES from '../../../styles/themes/app';
import { Avatar, Heading3, Text } from '../../../elements';
import { supportedIcons } from '../../../elements/Avatar/Avatar';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${THEMES.BORDER_COLOR};
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  margin-bottom: 16px;
  width: 100%;

  &,
  * {
    box-sizing: border-box;
  }
`;

const ContentHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 16px;
  outline: 1px solid ${THEMES.BORDER_COLOR};
`;

const ContentBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
`;

const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 16px;
  width: 100%;
`;

const HeaderText = styled(Heading3)`
  margin-bottom: 4px;
`;

export interface SectionFormCardProps {
  avatarIconKey: keyof typeof supportedIcons;
  headerKey: string;
  subHeaderKey: string;
  headerKeyFallback?: string;
  subHeaderKeyFallback?: string;
  children?: ReactNode;
}

const SectionFormCard = ({
  avatarIconKey,
  headerKey,
  subHeaderKey,
  headerKeyFallback,
  subHeaderKeyFallback,
  children,
}: SectionFormCardProps): JSX.Element => {
  return (
    <Container>
      <ContentHeaderContainer>
        <Avatar icon={avatarIconKey} />
        <ContentHeader>
          <HeaderText>
            {i18n.t(
              headerKey,
              headerKeyFallback
                ? { defaultValue: headerKeyFallback }
                : undefined
            )}
          </HeaderText>
          <Text>
            {i18n.t(
              subHeaderKey,
              subHeaderKeyFallback
                ? { defaultValue: subHeaderKeyFallback }
                : undefined
            )}
          </Text>
        </ContentHeader>
      </ContentHeaderContainer>
      {children ? (
        <ContentBodyContainer>{children}</ContentBodyContainer>
      ) : null}
    </Container>
  );
};

export default SectionFormCard;
