import { SET_ACTIVE_GROUP } from '../actions/reports.ts';

const DEFAULT_STATE = {
  activeGroupId: '',
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_ACTIVE_GROUP:
      return {
        ...state,
        activeGroupId: action.groupId,
      };

    default:
      return state;
  }
}
