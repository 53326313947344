import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import {
  canPreviewBasedOnFileExtension,
  fileExtension,
} from 'client-lib/src/lib/utils/helpers';
import FileIcon from '../Common/FileIcon';
import THEMES from '../../styles/themes/app';
import { IconButton } from '../../elements';

const PhotoFrame = styled.div`
  display: inline-flex;
  justify-content: left;
  align-items: left;
  border: solid 1px ${THEMES.BORDER_COLOR};
  background: ${THEMES.BACKGROUND_PRIMARY};
  padding-top: 10px;
  padding-bottom: ${(props) => (props.expandOptions ? '5px' : '10px')};
  padding-right: ${(props) => (props.expandOptions ? '0px' : '10px')};
  padding-left: ${(props) => (props.expandOptions ? '0px' : '10px')};
  border-radius: 4px;
  padding: 10px;
  box-shadow: ${THEMES.BOX_SHADOW};
  margin-bottom: 10px;
  flex-wrap: wrap;
  justify-content: center;
`;

const PDFFrame = styled.div`
  background-color: ${THEMES.BACKGROUND_SECONDARY};
  border: solid 1px ${THEMES.BACKGROUND_TERTIARY};
  border-radius: 4px;
  padding: 7px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  width: 66px;
  max-width: 100px;
  height: 80px;
  box-shadow: ${THEMES.BOX_SHADOW};
  margin-bottom: 10px;
`;

const Photo = styled.img`
  max-width: 200px;
  max-height: 150px;
`;

const ExpandIconContainer = styled(Grid)`
  width: 92% !important;
  margin-top: 5px;
  font-size: 20px;
  cursor: pointer;
`;

const AttachmentView = ({
  attachment,
  expandHandler,
  displayExpandOptions,
}) => {
  const photoSrc =
    attachment.data ||
    `/attachments/${attachment.id}/original/${attachment.originalFilename}`;

  return canPreviewBasedOnFileExtension(attachment.originalFilename) ? (
    <PhotoFrame
      expandOptions={displayExpandOptions}
      key={attachment.originalFilename}
    >
      <Grid>
        <Photo
          src={photoSrc}
          alt={`preview of ${attachment.originalFilename}`}
        />
      </Grid>
      {displayExpandOptions ? (
        <ExpandIconContainer container justifyContent="flex-end">
          <IconButton onClick={expandHandler}>
            <i className="ri-aspect-ratio-line" />
          </IconButton>
        </ExpandIconContainer>
      ) : (
        ''
      )}
    </PhotoFrame>
  ) : (
    <PDFFrame key={attachment.originalFilename}>
      <FileIcon
        width="50px"
        height="50px"
        extension={fileExtension(attachment.originalFilename)}
      />
    </PDFFrame>
  );
};

AttachmentView.propTypes = {
  attachment: PropTypes.object.isRequired,
  expandHandler: PropTypes.func,
  displayExpandOptions: PropTypes.bool,
};

AttachmentView.defaultProps = {
  expandHandler: null,
  displayExpandOptions: false,
};

const memoComparison = (prevProps, nextProps) => {
  const { attachment: prevAttachment } = prevProps;
  const { attachment: nextAttachment } = nextProps;

  return prevAttachment.id === nextAttachment.id;
};

export default React.memo(AttachmentView, memoComparison);
