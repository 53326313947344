import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import THEMES from '../../styles/themes/app';
import { EmphasisText, Text, Button } from '../../elements';
import Bubble from './Bubble';

const TitleQuestion = styled.div`
  padding: 12px;
  text-align: center;
  white-space: normal;
  word-break: break-word;
`;

const SubText = styled.div`
  padding: 12px;
  padding-top: 0;
  text-align: center;
  white-space: normal;
`;

const HorizontalRule = styled.div`
  height: 1px;
  background-color: ${THEMES.BORDER_COLOR};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

/*

  INSTRUCTIONS FOR ALIGNMENT OF BubbleConfirmation COMPONENT:

  1. use prop moveLeftVal to align the carrot horizontally where you want it (usually in the center of a button/element when active)
  2. adjust the bubble position from there using prop moveBubbleRight.

  note: I know the vals say "right" and "left", but the engineer should know that the user can pass negative values to these props as well, thus turning
  "right" into "left" and vice versa

*/

const BubbleConfirmation = ({
  isOpen,
  onClickOutside,
  cancelAction,
  cancelButtonText,
  confirmationAction,
  confirmationButtonText,
  title,
  subText,
  moveBubbleRightVal,
  moveLeftVal,
  bubbleWidth,
  top,
  redButtonText,
  fixedPosition,
  zIndex,
}) => (
  <Bubble
    isOpen={isOpen}
    onClickOutside={onClickOutside}
    moveBubbleRightVal={moveBubbleRightVal}
    moveLeftVal={moveLeftVal}
    bubbleMinWidth={bubbleWidth}
    bubbleMaxWidth={bubbleWidth}
    top={top}
    fixedPosition={fixedPosition}
    zIndex={zIndex}
    dataTestId="BubbleConfirmation-bubble"
  >
    <>
      <TitleQuestion>
        <EmphasisText customStyle={() => 'white-space: normal!important;'}>
          {title}
        </EmphasisText>
      </TitleQuestion>
      {subText && (
        <SubText>
          <Text customStyle={() => 'white-space: normal!important;'}>
            {subText}
          </Text>
        </SubText>
      )}
      <HorizontalRule />
      <ButtonWrapper>
        <Button
          type="noStyle"
          customStyle={(props) => `flex: 1;
            border-right-color: ${THEMES.BORDER_COLOR(props)};
            border-right-width: 1px;
            color ${
              redButtonText
                ? THEMES.THEME_RED(props)
                : THEMES.FOREGROUND_HIGH_COLOR(props)
            };
            :hover{
              border-right-color: ${THEMES.BORDER_COLOR(props)};
              border-right-width: 1px;
              color: ${
                redButtonText
                  ? THEMES.THEME_RED(props)
                  : THEMES.FOREGROUND_HIGH_COLOR(props)
              };
            }`}
          onClick={confirmationAction}
          dataTestId="BubbleConfirmation-confirmation"
        >
          {confirmationButtonText}
        </Button>
        <Button
          type="noStyle"
          customStyle={() => 'flex: 1;'}
          onClick={cancelAction}
          dataTestId="BubbleConfirmation-cancel"
        >
          {cancelButtonText || i18n.t('settings-BubbleConfirmation-cancel')}
        </Button>
      </ButtonWrapper>
    </>
  </Bubble>
);

BubbleConfirmation.propTypes = {
  title: PropTypes.string.isRequired,
  subText: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClickOutside: PropTypes.func.isRequired,
  cancelAction: PropTypes.func.isRequired,
  cancelButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  confirmationAction: PropTypes.func.isRequired,
  confirmationButtonText: PropTypes.string.isRequired,
  moveBubbleRightVal: PropTypes.number,
  moveLeftVal: PropTypes.number,
  bubbleWidth: PropTypes.number,
  top: PropTypes.string,
  redButtonText: PropTypes.bool,
  fixedPosition: PropTypes.bool,
  zIndex: PropTypes.number,
};

BubbleConfirmation.defaultProps = {
  subText: '',
  cancelButtonText: '',
  moveBubbleRightVal: 0,
  moveLeftVal: 0,
  bubbleWidth: null,
  top: null,
  redButtonText: false,
  fixedPosition: false,
  zIndex: 0,
};

export default BubbleConfirmation;
