import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import THEMES from '../../styles/themes/app';
import BADGE_THEMES from '../../styles/themes/library/badge';
import FONTSIZE_THEMES from '../../styles/themes/fontSize/fontSize';
import COLORS from '../../styles/colors';

const determineBadgeBackgroundColor = (props) => {
  switch (props.color) {
    case 'grey':
      return BADGE_THEMES.BACKGROUND_GREY;

    case 'yellow':
      return BADGE_THEMES.BACKGROUND_YELLOW;

    case 'green':
      return BADGE_THEMES.BACKGROUND_GREEN;

    case 'blue':
      return BADGE_THEMES.BACKGROUND_BLUE;

    case 'charcoal':
      return BADGE_THEMES.BACKGROUND_CHARCOAL;

    case 'black':
      return BADGE_THEMES.BACKGROUND_BLACK;

    case 'red':
      return COLORS.PK_CLASSIC.RED_LABEL;

    case 'orange':
      return COLORS.PK_CLASSIC.ORANGE_LABEL;

    case 'violet':
      return COLORS.PK_CLASSIC.PURPLE_LABEL;

    default:
      return BADGE_THEMES.BACKGROUND_GREY;
  }
};

const determineTextColor = (props) => {
  switch (props.color) {
    case 'grey':
      return BADGE_THEMES.TEXT_GREY;

    case 'yellow':
      return BADGE_THEMES.TEXT_DARK;

    case 'green':
      return BADGE_THEMES.TEXT;

    case 'blue':
      return BADGE_THEMES.TEXT;

    case 'charcoal':
      return BADGE_THEMES.TEXT;

    case 'black':
      return BADGE_THEMES.TEXT;

    case 'red':
      return BADGE_THEMES.TEXT;

    case 'orange':
      return BADGE_THEMES.TEXT;

    case 'violet':
      return BADGE_THEMES.TEXT;

    default:
      return BADGE_THEMES.TEXT_DARK;
  }
};

const SquareBadgeStyle = styled.div`
  padding: 4px 8px;
  text-transform: uppercase;
  background-color: ${determineBadgeBackgroundColor};
  border: solid 1px ${(props) => props.badgeBorderColor};
  border-radius: 2px;
  color: ${determineTextColor};
  font-family: 'Barlow', sans-serif;
  font-size: ${FONTSIZE_THEMES.SQUAREBADGETEXT};
  font-weight: 600;
  line-height: ${FONTSIZE_THEMES.SQUAREBADGETEXT};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  white-space: nowrap;
`;

const SquareBadge = ({
  children,
  color,
  badgeBorderColor,
  dataTestId,
  ...otherProps
}) => (
  <SquareBadgeStyle
    badgeBorderColor={badgeBorderColor}
    color={color}
    css={(props) => props?.customStyle?.(props)} // eslint-disable-line react/prop-types
    {...otherProps}
    data-testid={dataTestId}
  >
    {children}
  </SquareBadgeStyle>
);

SquareBadge.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]),
  color: PropTypes.string,
  badgeBorderColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  dataTestId: PropTypes.string,
};

SquareBadge.defaultProps = {
  children: null,
  color: 'grey',
  badgeBorderColor: THEMES.BACKGROUND_PRIMARY,
  dataTestId: null,
};

export default SquareBadge;
