import styled from 'styled-components';
import THEMES from '../../styles/themes/app';
import FONTSIZE_THEMES from '../../styles/themes/fontSize/fontSize';

export const SnackbarContentsWrapper = styled.div`
  align-items: center;
  display: flex;
  padding: 0px !important;
`;

export const CheckIcon = styled.i`
  font-size: ${FONTSIZE_THEMES.CARDTEXT};
  padding-right: 8px;
`;

export const LinkButton = styled.div`
  padding-left: 8px;
`;

export const TruncateOverflowWrapper = styled.span`
  display: block;
  line-height: 24px;
  max-width: 50vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const getButtonLinkStyle = () => `
  color: ${THEMES.BACKGROUND_PRIMARY};
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const getSnackbarStyle = (isOpen: boolean) => ({
  bottom: 'auto',
  left: '50%',
  maxWidth: 'none',
  padding: '0px',
  top: '0',
  transform: isOpen ? 'translate3d(-50%, 0, 0)' : 'translate3d(-50, -5px, 0)',
  zIndex: '1000',
});

export const getSnackbarContentStyle = (color: string) => ({
  backgroundColor: color,
  display: 'flex',
  minWidth: 'initial',
  padding: '4px 16px',
  width: '100%',
});
