import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Card from '../Card/Card';
import Switch from '../Switch/Switch';
import { Heading3, Text } from '../Text/Text';

const mainCardCustomStyle = (props) => `
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${props?.customStyle?.()}
`;

const TextWrap = styled.div``;

const Option = ({
  name,
  subtext,
  cardProps,
  checked,
  onCheck,
  switchProps,
  children,
  customStyle,
  customTextWrapStyle,
  customChildrenStyle,
  childCardProps,
}) => (
  <>
    <Card
      {...cardProps}
      customChildrenStyle={mainCardCustomStyle}
      customStyle={customStyle}
    >
      <>
        <TextWrap
          customTextWrapStyle={customTextWrapStyle}
          css={(props) => props?.customTextWrapStyle?.()}
        >
          <Heading3>{name}</Heading3>
          {subtext && <Text>{subtext}</Text>}
        </TextWrap>
        <Switch checked={checked} onCheck={onCheck} {...switchProps} />
      </>
    </Card>
    {children && (
      <Card
        customChildrenStyle={customChildrenStyle}
        customStyle={() => 'border-width: 0 1px 1px 1px;'}
        {...childCardProps}
      >
        {children}
      </Card>
    )}
  </>
);

Option.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  subtext: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  checked: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired,
  switchProps: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
    PropTypes.object,
  ]),
  customStyle: PropTypes.func,
  customTextWrapStyle: PropTypes.func,

  customChildrenStyle: PropTypes.func,
  childCardProps: PropTypes.object,

  cardProps: PropTypes.object,
};

Option.defaultProps = {
  subtext: null,
  switchProps: null,
  children: null,
  customStyle: null,
  customTextWrapStyle: null,
  customChildrenStyle: null,
  childCardProps: {},
  cardProps: {},
};

export default Option;
