import React, { useEffect, type SetStateAction } from 'react';
import i18n from 'i18n-js';
import { useHistory } from 'react-router-dom';
import { useThreadsQuery } from 'client-lib';
import { useApolloClient } from '@apollo/client';

import { AlertMessageBox, InlineTextButton } from '../../../elements';
import { FlexData, ReplyWrapper } from './styles';

interface FeedbackDetailsCardProps {
  id: string;
  setHasOpenThread: React.Dispatch<SetStateAction<boolean>>;
}

const FeedbackDetailsWarning = ({
  id,
  setHasOpenThread,
}: FeedbackDetailsCardProps) => {
  const client = useApolloClient();

  const threadData = useThreadsQuery({
    client,
    key: 'customerContactId',
    id,
    fetchPolicy: 'network-only',
  });

  const threadId = threadData?.threads[0]?.id;
  useEffect(() => {
    setHasOpenThread(!!threadId);
  }, [threadId]);

  const history = useHistory();

  return threadId ? (
    <>
      <FlexData>
        <AlertMessageBox data-testid="feedback-details-warning">
          {i18n.t('feedback-details-warning', {
            defaultValue:
              'This contact currently has an active open thread. Replying now will interrupt the current conversation.',
          })}
        </AlertMessageBox>
      </FlexData>
      <ReplyWrapper>
        <InlineTextButton
          onClick={() => {
            history.push(`/threads/open/${threadId}`);
          }}
        >
          {i18n.t('feedback-openThread-button', {
            defaultValue: 'View Open Thread',
          })}
        </InlineTextButton>
      </ReplyWrapper>
    </>
  ) : null;
};

export default FeedbackDetailsWarning;
