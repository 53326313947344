import en_announcements from "./english/announcements.json";
import en_broadcast from "./english/broadcast.json";
import en_broadcasts from "./english/broadcasts.json";
import en_contacts from "./english/contacts.json";
import en_customers from "./english/customers.json";
import en_dashboards from "./english/dashboards.json";
import en_feedback from "./english/feedback.json";
import en_general from "./english/general.json";
import en_modals from "./english/modals.json";
import en_reports from "./english/reports.json";
import en_search from "./english/search.json";
import en_settings from "./english/settings.json";
import en_sidebar from "./english/sidebar.json";
import en_signin from "./english/signin.json";
import en_slideouts from "./english/slideouts.json";
import en_threads from "./english/threads.json";
import en_tasks from "./english/tasks.json";
import fr_announcements from "./french/announcements.json";
import fr_tasks from "./french/tasks.json";
import fr_broadcast from "./french/broadcast.json";
import fr_broadcasts from "./french/broadcasts.json";
import fr_contacts from "./french/contacts.json";
import fr_customers from "./french/customers.json";
import fr_dashboards from "./french/dashboards.json";
import fr_feedback from "./french/feedback.json";
import fr_general from "./french/general.json";
import fr_modals from "./french/modals.json";
import fr_reports from "./french/reports.json";
import fr_search from "./french/search.json";
import fr_settings from "./french/settings.json";
import fr_sidebar from "./french/sidebar.json";
import fr_signin from "./french/signin.json";
import fr_slideouts from "./french/slideouts.json";
import fr_threads from "./french/threads.json";
import es_announcements from "./spanish/announcements.json";
import es_tasks from "./spanish/tasks.json";
import es_broadcast from "./spanish/broadcast.json";
import es_broadcasts from "./spanish/broadcasts.json";
import es_contacts from "./spanish/contacts.json";
import es_customers from "./spanish/customers.json";
import es_dashboards from "./spanish/dashboards.json";
import es_feedback from "./spanish/feedback.json";
import es_general from "./spanish/general.json";
import es_modals from "./spanish/modals.json";
import es_reports from "./spanish/reports.json";
import es_search from "./spanish/search.json";
import es_settings from "./spanish/settings.json";
import es_sidebar from "./spanish/sidebar.json";
import es_signin from "./spanish/signin.json";
import es_slideouts from "./spanish/slideouts.json";
import es_threads from "./spanish/threads.json";

export default {
  EN_US: {
    date: {
      abbr_day_names: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      abbr_month_names: [
        null,
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      day_names: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      formats: {
        default: "%m-%d-%Y",
        long: "%B %d, %Y",
        short: "%b %d",
      },
      month_names: [
        null,
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      order: ["month", "day", "year"],
    },
    ...en_announcements,
    ...en_broadcasts,
    ...en_broadcast,
    ...en_contacts,
    ...en_customers,
    ...en_dashboards,
    ...en_feedback,
    ...en_general,
    ...en_modals,
    ...en_reports,
    ...en_search,
    ...en_settings,
    ...en_sidebar,
    ...en_signin,
    ...en_slideouts,
    ...en_threads,
    ...en_tasks,
  },
  FR_CA: {
    date: {
      abbr_day_names: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
      abbr_month_names: [
        null,
        "Jan.",
        "Fév.",
        "Mar.",
        "Avr.",
        "Mai",
        "Juin",
        "Juil.",
        "Août",
        "Sept.",
        "Oct.",
        "Nov.",
        "Déc.",
      ],
      day_names: [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
      ],
      formats: {
        default: "%Y-%m-%d",
        long: "%d %B %Y",
        short: "%y-%m-%d",
      },
      month_names: [
        null,
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ],
      order: ["year", "month", "day"],
    },
    ...fr_announcements,
    ...fr_broadcasts,
    ...fr_broadcast,
    ...fr_contacts,
    ...fr_customers,
    ...fr_dashboards,
    ...fr_feedback,
    ...fr_general,
    ...fr_modals,
    ...fr_reports,
    ...fr_search,
    ...fr_settings,
    ...fr_sidebar,
    ...fr_signin,
    ...fr_slideouts,
    ...fr_threads,
    ...fr_tasks,
  },
  ES_MX: {
    date: {
      abbr_day_names: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
      abbr_month_names: [
        null,
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
      ],
      day_names: [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
      ],
      formats: {
        default: "%d/%m/%Y",
        long: "%A, %d de %B de %Y",
        short: "%d de %b",
      },
      month_names: [
        null,
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      order: ["day", "month", "year"],
    },
    ...es_announcements,
    ...es_broadcasts,
    ...es_broadcast,
    ...es_contacts,
    ...es_customers,
    ...es_dashboards,
    ...es_feedback,
    ...es_general,
    ...es_modals,
    ...es_reports,
    ...es_search,
    ...es_settings,
    ...es_sidebar,
    ...es_signin,
    ...es_slideouts,
    ...es_threads,
    ...es_tasks,
  },
};
