import { useEffect, useState } from "react";

/*

DOCUMENTATION
  this hook was designed to be used on dashboards (if already signed in). It makes a request to our public api to get the maximum number of groups a user can have on their dashboard. This is to ensure that the user does not select too many groups for viewing on the dashboard.

  args:
    none

  returns:
    selectionLimit: (int) the maximum number of groups a user can have on their dashboard
    loading: (bool) true if the request is still pending
    error: (obj) if the request fails, this will be an object with the error message

*/

const GROUP_LIMIT_FALLBACK = 100;

const useVerifyGroupLimit = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const publicApiUrl = process.env.PK_PUBLIC_API;

  const endpoint = publicApiUrl + "/public/v1/maximum-dashboard-groups";

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      try {
        const response = await fetch(endpoint, { signal });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        setData(result);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // Clean-up function
    return () => {
      // Abort the ongoing fetch request if the component unmounts
      controller.abort();
    };
  }, [endpoint]);

  return {
    selectionLimit: data?.max_groups || GROUP_LIMIT_FALLBACK,
    loading,
    error,
  };
};

export default useVerifyGroupLimit;
