import { useQuery } from "@apollo/client";
import groupsQuery from "../../graphql/query/GroupsQuery.graphql";

/*
DOCUMENTATION

this hook is meant to be used for a generic groups query for the client.

args:
  client: (obj) required. apolloClient instance
  ...options: other apollo options dev wants

returns:
  apolloQuery: (obj) make of loading, error and data

*/

const useGroupsQuery = ({ client, ...options }) =>
  useQuery(groupsQuery, { client, ...options });

export default useGroupsQuery;
