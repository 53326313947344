import React, { useState } from 'react';
import { formatDataTestName } from 'client-lib/src/lib/utils/helpers';
import { IconButton, Heading2, Text, Avatar, Button } from '../../../elements';
import {
  AvatarContainer,
  Container,
  InnerContainer,
  TitleWrapper,
  getSubtitleTextStyles,
  getHeaderTextStyles,
  TilteButtonContainer,
  FlexBoxFill,
  SecondaryTitleButtonContainer,
  OptionsBubbleContainer,
  OptionsButtonContainer,
  bubbleButtonHoverStyle,
} from './styles';
import Bubble from '../../Common/Bubble';
import type { ButtonOption } from '../types';

export interface HeaderProps {
  onRequestClose: () => void;
  subtitle?: string;
  title?: string;
  titleButton?: string;
  titleButtonAction?: () => void;
  titleButtonProps?: { [key: string]: string | boolean };
  titleIcon?: string;
  showMoreOptions?: Array<ButtonOption>;
}

const Header = ({
  onRequestClose,
  subtitle = '',
  title = '',
  titleButton = '',
  titleButtonAction = () => {},
  titleButtonProps = {},
  titleIcon = '',
  showMoreOptions,
}: HeaderProps) => {
  const [optionsOpen, setOptionsOpen] = useState(false);
  return (
    <Container>
      <InnerContainer>
        <TitleWrapper>
          <FlexBoxFill>
            {titleIcon && (
              <AvatarContainer>
                <Avatar
                  role="img"
                  icon={titleIcon}
                  size={subtitle ? 'sm' : 'lg'}
                />
              </AvatarContainer>
            )}
            <Heading2 customStyle={getHeaderTextStyles}>{title}</Heading2>
            <TilteButtonContainer>
              {titleButton && (
                <IconButton
                  size={titleButtonProps?.size}
                  onClick={() => titleButtonAction()}
                  contrast={titleButtonProps?.contrast}
                  className={titleButton}
                  disabled={titleButtonProps?.disabled}
                />
              )}
            </TilteButtonContainer>
          </FlexBoxFill>
          <SecondaryTitleButtonContainer>
            {showMoreOptions?.length ? (
              <IconButton
                size="lg"
                onClick={() => setOptionsOpen(!optionsOpen)}
                className="ri-more-fill"
              />
            ) : null}
            {showMoreOptions?.length ? (
              <OptionsBubbleContainer>
                <Bubble
                  isOpen={optionsOpen}
                  onClickOutside={() => setOptionsOpen(!optionsOpen)}
                  moveLeftVal={23}
                  moveBubbleRightVal={-35}
                  bubbleMinWidth={100}
                  bubbleMaxWidth={150}
                  top="20px"
                  fixedPosition
                >
                  <OptionsButtonContainer>
                    {showMoreOptions.map((option: ButtonOption) => (
                      <Button
                        key={option.label}
                        type="noStyle"
                        noOutline
                        onClick={() => option.onClick()}
                        customStyle={bubbleButtonHoverStyle}
                        iconAndText
                        dataTestId="threadPrintButton"
                      >
                        <i className={option.icon} />
                        {option.label}
                      </Button>
                    ))}
                  </OptionsButtonContainer>
                </Bubble>
              </OptionsBubbleContainer>
            ) : null}
          </SecondaryTitleButtonContainer>

          <IconButton
            size="lg"
            onClick={onRequestClose}
            dataTestId={`close-${formatDataTestName(title)}-icon-button`}
            className="ri-close-line"
          />
        </TitleWrapper>
        <Text customStyle={getSubtitleTextStyles}>{subtitle}</Text>
      </InnerContainer>
    </Container>
  );
};

export default Header;
