import { useRef, useState, useLayoutEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export default function useMeasure() {
  const ref = useRef();
  const [bounds, set] = useState({
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  });
  const [ro] = useState(
    () =>
      new ResizeObserver(([entry]) => {
        const { top, bottom, height, width, left, right, x, y } =
          entry.contentRect;

        if (!top && !bottom && !height && !width && !left && !right && !x && !y)
          return;
        set(entry.contentRect);
      })
  );
  useLayoutEffect(() => {
    if (ref.current) ro.observe(ref.current);
  }, [!!ref.current]);
  return [{ ref }, bounds];
}
