const thread_utils = {
  // checks for type sms
  isTypeSms(thread) {
    return thread?.type === 'sms';
  },

  // checks for type fax
  isTypeFax(thread) {
    return thread?.type === 'fax';
  },

  // checks for type email
  isTypeEmail(thread) {
    return thread?.type === 'email';
  },

  // checks for type webchat
  isTypeWebchat(thread) {
    return thread?.type === 'webchat';
  },

  // checks for type internal
  isTypeInternal(thread) {
    return thread?.type === 'internal';
  },

  // checks for group SMS
  isTypeFsm(thread) {
    return thread?.type === 'fsm';
  },

  // checks for spam message intent
  isMarkedAsSpam(thread) {
    return thread?.messageIntent?.includes('SPAM');
  },

  isTypeGroupSms(thread) {
    return (
      this.isTypeSms(thread) && thread?.otherExternalContactIds?.length >= 1
    );
  },

  // checks for type internal and that it's been sent to a group. Does not check _what_ group, just that it's
  // been sent to any generic group. NOTE: this method of checking .group may actually be deprecated on the BE.
  isInternalAndGroup(thread) {
    return (
      !!this.isTypeInternal(thread) &&
      !!thread?.group &&
      !thread.externalContact
    );
  },

  // checks for type internal and that it HASN'T been sent to a group.
  // NOTE: this method of checking .group may actually be deprecated on the BE.
  isInternalAndNotGroup(thread) {
    return (
      !!this.isTypeInternal(thread) && !thread?.group && !thread.externalContact
    );
  },

  // checking for if it's active or archived
  isActive(thread) {
    return thread?.archivedAt === null;
  },

  // checking for ownership of thread, whether internal or sms
  isOwned(thread) {
    return !!(
      thread?.ownerContact ||
      (this.isTypeInternal(thread) && thread?.userShares?.length)
    );
  },

  // checking specifically for currentUser is ownerContact of thread. Will not yeild true for internal threads.
  isOwnedByCurrentUser(thread, currentUser) {
    return !!(thread?.ownerContact?.id === currentUser?.contactId);
  },

  // checking specifically for owner of DM
  isSharedWithCurrentUser(thread, currentUser) {
    return thread?.userShares?.some(
      (userShare) => userShare.userId === currentUser?.userId,
    );
  },

  // checking specifically for owner of DM (general users, NOT current user)
  isSharedWithUser(thread, user) {
    return thread?.userShares?.some(
      (userShare) => userShare.userId === user?.id,
    );
  },

  // checking for both internal thread and ownership of that thread
  isInternalAndHeldByCurrentUser(thread, currentUser) {
    return (
      this.isTypeInternal(thread) &&
      this.isSharedWithCurrentUser(thread, currentUser)
    );
  },

  // using the term "held" as encompassing both versions of ownership, as not all owned threads have a ownerContact.
  // this should work for all threads, whether owned through ownerContactId or userShares (sms, internal, DMs, etc)
  isHeldByCurrentUser(thread, currentUser) {
    return (
      this.isOwnedByCurrentUser(thread, currentUser) ||
      this.isInternalAndHeldByCurrentUser(thread, currentUser)
    );
  },

  // checking if a particular customer contact is the recipient of a thread.
  isCustomerContactRecipient(thread, customerContact) {
    return thread?.externalContact?.id === customerContact?.id;
  },

  // checking if a particular user and the current user are the recipients of a specific internal thread.
  isUserRecipient(thread, currentUser, otherUser) {
    return !!(
      this.isTypeInternal(thread) &&
      this.isSharedWithCurrentUser(thread, currentUser) &&
      this.isSharedWithUser(thread, otherUser)
    );
  },

  // will check if an entity (user or customer contact) is the recipient of a thread.
  isEntityRecipient(thread, currentUser, entity) {
    return !!(
      this.isCustomerContactRecipient(thread, entity) ||
      this.isUserRecipient(thread, currentUser, entity)
    );
  },

  // checking if thread is assigned to any of the user's active groups
  isInActiveGroups(thread, threadsActiveGroupIds, currentUser) {
    return this.isInboxThreadsForGroups(
      thread,
      threadsActiveGroupIds,
      currentUser,
    );
  },

  // checking if thread is assigned to any of the user's active groups through group sharing
  isSharedWithGroup(thread, threadsActiveGroupIds) {
    return thread?.groupShares?.some((groupShare) =>
      threadsActiveGroupIds.some((groupId) => groupId === groupShare.groupId),
    );
  },

  // both isInActiveGroups and isSharedWithGroup, will check for either. A safe bet to use this one if you're not sure which one
  // to use.
  isHeldByGroups(thread, threadsActiveGroupIds) {
    return !!(
      this.isInActiveGroups(thread, threadsActiveGroupIds) ||
      this.isSharedWithGroup(thread, threadsActiveGroupIds)
    );
  },

  // let us know if the thread is an announcement.
  isAnnouncement(thread) {
    return !!(thread?.channel?.type === 'announcements');
  },

  // let us know if the thread is an announcement reply
  isAnnouncementReply(thread) {
    return !!(
      this.isAnnouncement(thread) &&
      thread?.externalContact?.id === thread?.insertedByContactId
    );
  },

  // since internal threads just have an array of userShares that the current user is a part of,
  // we need to filter down to what is the main recipient of this thread. we filter the current user, then
  // grab the first one in the array of user shares after that. if thread is a group thread we return the group.
  determinMainInternalContact(thread, currentUser) {
    if (this.isInternalAndGroup(thread)) {
      return thread.group;
    }

    const externalUsers = thread.userShares.filter(
      (userShare) => userShare.userId !== currentUser.userId,
    );
    return externalUsers?.[0]?.contact?.user;
  },

  // allows us to grab the entity that is the main contact of the thread, no matter the entity type.
  // if thread is an internal thread, it will return a user entity, etc.
  determineMainContact(thread, currentUser) {
    if (this.isTypeInternal(thread)) {
      const internalUser = this.determinMainInternalContact(
        thread,
        currentUser,
      );
      return internalUser;
    }
    return thread?.externalContact;
  },

  // this section is built for easily checking for matches on properties given to the thread.
  // The properties of threads were refactored Feb 2022, these are what the BE uses to route
  // the threads correctly. On the FE, we shouldn't have to use these directly,
  // rather use the next section that explicitly asks "isInInbox", etc.
  // Section consists of isInboxThreadsForGroups through isAllClosedThread.
  isInboxThreadsForGroups(thread, threadsActiveGroupIds, currentUser) {
    return (
      threadsActiveGroupIds?.includes(
        thread?.inboxThreadsForGroups?.[0]?.groupId,
      ) && !this.isOpenThreadForUser(thread, currentUser)
    );
  },

  isInboxThreadsForUser(thread, currentUser) {
    return thread?.inboxThreadsForUsers.some(
      (inboxThreadForUser) =>
        inboxThreadForUser?.userId === currentUser?.userId,
    );
  },

  isOpenThreadForUser(thread, currentUser) {
    return thread?.openThreadsForUsers?.some(
      (openThreadForUser) => openThreadForUser?.userId === currentUser?.userId,
    );
  },

  isAllOpenThread(thread) {
    return (
      !thread?.inboxThreadsForGroups?.length &&
      !thread?.inboxThreadsForUsers?.length &&
      this.isActive(thread)
    );
  },

  isClosedThreadForUser(thread, currentUser) {
    return (
      !this.isActive(thread) &&
      (this.isOwnedByCurrentUser(thread, currentUser) ||
        this.isSharedWithCurrentUser(thread, currentUser))
    );
  },

  isAllClosedThread(thread, currentUser) {
    return (
      !this.isActive(thread) && !this.isOwnedByCurrentUser(thread, currentUser)
    );
  },

  // This section is used to correctly route the thread. Feel free to use these directly.
  // Section consists of isInInbox through isInAllClosed.

  // verifies whether the thread belongs in user inbox
  isInInbox(thread, currentUser, threadsActiveGroupIds) {
    const getIsInActiveGroups = this.isInActiveGroups(
      thread,
      threadsActiveGroupIds,
      currentUser,
    );
    const isInboxThread =
      this.isActive(thread) || this.isInboxThreadsForUser(thread, currentUser);

    return getIsInActiveGroups ? isInboxThread : false;
  },

  // verifies whether the thread belongs in user spam inbox AND that message scanning FF is enabled
  isInSpam(thread, currentUser, threadsActiveGroupIds, spamFilteringFFEnabled) {
    return (
      this.isInInbox(thread, currentUser, threadsActiveGroupIds) &&
      this.isMarkedAsSpam(thread) &&
      spamFilteringFFEnabled
    );
  },

  // verifies whether the thread belongs in the user's my open tab
  isInMyOpen(thread, currentUser) {
    return (
      this.isActive(thread) && this.isOpenThreadForUser(thread, currentUser)
    );
  },

  // verifies whether the thread belongs in all open (and by extension NOT in my open)
  isInAllOpen(thread, currentUser) {
    return (
      this.isAllOpenThread(thread) && !this.isInMyOpen(thread, currentUser)
    );
  },

  // verifies whether the thread belongs in my closed tab
  isInMyClosed(thread, currentUser) {
    return this.isClosedThreadForUser(thread, currentUser);
  },

  // verifies whether the thread belongs in all closed tab
  isInAllClosed(thread, currentUser) {
    return (
      this.isAllClosedThread(thread, currentUser) &&
      !this.isInMyClosed(thread, currentUser)
    );
  },

  // an all encompassing method that handles the logic of which section the thread should be in, and
  // returns a simple string. "inbox", "myOpen", "allOpen", "myClosed", "allClosed".
  whereDoesThisThreadGo(
    thread,
    currentUser,
    threadsActiveGroupIds,
    spamFilteringFFEnabled,
  ) {
    if (
      this.isInSpam(
        thread,
        currentUser,
        threadsActiveGroupIds,
        spamFilteringFFEnabled,
      )
    ) {
      return 'spam';
    }
    if (this.isInInbox(thread, currentUser, threadsActiveGroupIds)) {
      return 'inbox';
    }
    if (this.isInMyOpen(thread, currentUser)) {
      return 'myOpen';
    }
    if (this.isInAllOpen(thread, currentUser)) {
      return 'allOpen';
    }
    if (this.isInMyClosed(thread, currentUser)) {
      return 'myClosed';
    }
    if (this.isInAllClosed(thread, currentUser)) {
      return 'allClosed';
    }
    return null;
  },
};

export default thread_utils;
