import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  GROUPS_TABLE_DATA_QUERY,
  useUnclaimedThreadsWidget,
  useOpenThreadsWidget,
  useActiveThreadCountWidget,
} from 'client-lib';
import { DEFAULT_DASHBOARD_TABLE_ROW_COUNT } from 'client-lib/src/lib/utils/helpers';
import { useApolloClient, useQuery } from '@apollo/client';
import i18n from 'i18n-js';
import { Loading } from '../../elements';
import { LoadingContainer } from '../Settings/styles';
import MultiGroupTable from './MultiGroupTable';
import ThreadWidgets from './ThreadWidgets';
import {
  ORDER_BY_OPTIONS,
  SORT_BY_TYPES,
  getTimeFrameOptions,
} from './constants';
import DashboardActionBar from '../DashboardAndFeedback/ActionBar/DashboardActionBar';

const TOP_N = DEFAULT_DASHBOARD_TABLE_ROW_COUNT;

const WidgetsContainer = ({ groupIds }) => {
  const client = useApolloClient();

  /**
   * setting the flyout state at this level because the subscriptions
   * need to know if the fly outs are open
   */
  const [openConvoFlyoutOpen, setOpenConvoFlyoutOpen] = useState(false);
  const [unclaimedConvoFlyoutOpen, setUnclaimedConvoFlyoutOpen] =
    useState(false);
  const [sortType, setSortType] = useState(SORT_BY_TYPES.asc);
  const [orderBy, setOrderBy] = useState(ORDER_BY_OPTIONS.groupName);
  const [lastUpdatedWidgets, setLastUpdatedWidgets] = useState(
    new Date().toISOString()
  );
  const [lastUpdatedTable, setLastUpdatedTable] = useState(
    new Date().toISOString()
  );
  const [timeFrame, setTimeFrame] = useState(getTimeFrameOptions(i18n)[0]);

  const { activeGroups, activeTotal, activeLoading, refetchActiveThreadCount } =
    useActiveThreadCountWidget({ client, i18n, groupIds });

  const {
    unclaimedThreadCountTotal,
    unclaimedThreadCountByGroup,
    widgetsLoading,
    unclaimedThreadCountUpperBound,
    refetchUnclaimedThreads,
  } = useUnclaimedThreadsWidget({ client, groupIds });

  const {
    openThreadCountTotal,
    openThreadCountUpperBound,
    openThreadCountByGroup,
    widgetsLoading: openWidgetsLoading,
    refetchOpenThreads,
  } = useOpenThreadsWidget({ client, groupIds });

  const variables = {
    sortType,
    orderBy,
    timeFrame: timeFrame?.value,
    topN: TOP_N,
    groupIds,
  };
  const {
    data,
    loading: tableLoading,
    refetch: tableRefetch,
  } = useQuery(GROUPS_TABLE_DATA_QUERY, {
    client,
    variables,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: () => setLastUpdatedTable(new Date().toISOString()),
  });

  const dataIsLoading =
    widgetsLoading || openWidgetsLoading || activeLoading || tableLoading;

  const handleWidgetRefresh = () => {
    refetchActiveThreadCount();
    refetchUnclaimedThreads();
    refetchOpenThreads();
    setLastUpdatedWidgets(new Date().toISOString());
  };

  const handleTableRefetch = async () => {
    await tableRefetch({ fetchPolicy: 'network-only' });
  };

  const refreshData = () => {
    handleWidgetRefresh();
    handleTableRefetch();
  };

  const isLoading = widgetsLoading || openWidgetsLoading || activeLoading;

  return isLoading ? (
    <LoadingContainer>
      <Loading />
    </LoadingContainer>
  ) : (
    <>
      <DashboardActionBar
        activeGroupIds={groupIds}
        handleRefetch={refreshData}
        loading={dataIsLoading}
        completedDate={lastUpdatedWidgets}
      />
      <ThreadWidgets
        unclaimedTotal={unclaimedThreadCountTotal}
        unclaimedGroups={unclaimedThreadCountByGroup}
        unclaimedUpperBound={unclaimedThreadCountUpperBound}
        openTotal={openThreadCountTotal}
        openUpperBound={openThreadCountUpperBound}
        openGroups={openThreadCountByGroup}
        activeTotal={activeTotal}
        activeGroups={activeGroups}
        openConvoFlyoutOpen={openConvoFlyoutOpen}
        setOpenConvoFlyoutOpen={setOpenConvoFlyoutOpen}
        unclaimedConvoFlyoutOpen={unclaimedConvoFlyoutOpen}
        setUnclaimedConvoFlyoutOpen={setUnclaimedConvoFlyoutOpen}
      />
      <MultiGroupTable
        handleRefetch={handleTableRefetch}
        loading={tableLoading}
        data={data}
        sortType={sortType}
        orderBy={orderBy}
        timeFrame={timeFrame}
        setTimeFrame={setTimeFrame}
        setOrderBy={setOrderBy}
        setSortType={setSortType}
        groupIds={groupIds}
        lastUpdated={lastUpdatedTable}
      />
    </>
  );
};

WidgetsContainer.propTypes = {
  groupIds: PropTypes.array.isRequired,
};

export default WidgetsContainer;
