import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  AVAILABLE_PERMISSIONS,
  checkIfCurrentUserHasPermission,
  useBackupQueryThreads,
  useQueryThreads,
} from 'client-lib';
import { useSelector, useStore } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import CreateSection from '../CreateSection/CreateSection';
import Sidebar from '../Sidebar';
import GlobalModalsContainer from '../GlobalModalsContainer/GlobalModalsContainer';
import ThreadNotificationContainer from '../../containers/ThreadNotificationContainer';
import useSignedInWebApp from '../../hooks/useSignedInWebApp';
import Routes from '../../Routes';
import LazyFallback from '../LazyFallback/LazyFallback';
import useGetAllAccountPolicies from '../../hooks/signedIn/useGetAllAccountPolicies';
import useCheckUserLoaded, {
  initialLoadPermissionsComplete,
} from '../../hooks/signedIn/useCheckUserLoaded.ts';
import useDirectUserToIntendedUriAfterLogin from '../../hooks/signedIn/useDirectUserToIntendedUriAfterLogin';
import { Snackbar } from '../../elements/index.ts';

const SignedInApp = () => {
  const history = useHistory();
  useSignedInWebApp({ history });
  const client = useApolloClient();
  const store = useStore();

  // PRKP-4829 Temporary pull of all account policies.
  // To be removed after JIT permissions are replaced
  // throughout the app.
  // Only runs when FF is enabled.
  const getPoliciesComplete = useGetAllAccountPolicies();

  const currentUser = useSelector(
    (state) => state.session.currentUser,
    (currentUser, prev) =>
      currentUser?.userId === prev?.userId &&
      initialLoadPermissionsComplete(currentUser.accountPoliciesQueried) ===
        initialLoadPermissionsComplete(prev.accountPoliciesQueried)
  );
  useCheckUserLoaded(currentUser, getPoliciesComplete);
  useDirectUserToIntendedUriAfterLogin();

  const includeInternal = useSelector(
    (state) => state.accountData.account?.ff_internal_threads
  );

  const threadsActiveGroupIds = useSelector(
    (state) => state?.session?.threadsActiveGroupIds
  );

  const socketConnectionTimestamp = useSelector(
    (state) => state?.session?.socketConnectionTimestamp
  );

  const applicationLoaded = useSelector(
    (state) => state.applicationState?.applicationLoaded
  );

  const ff_spam_filtering = useSelector(
    (state) => state?.accountData?.account.ff_spam_filtering
  );

  const announcementsPagePermission = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.EDIT_ANNOUNCEMENT,
    currentUser?.accountPolicies || [],
    currentUser?.groupPolicies || []
  );

  const reportsPagePermission = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.VIEW_THREAD_REPORT,
    currentUser?.accountPolicies || [],
    currentUser?.groupPolicies || []
  );

  const dashboardPagePermission = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.VIEW_DASHBOARD,
    currentUser?.accountPolicies || [],
    currentUser?.groupPolicies || []
  );

  const feedbackPagePermission = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.VIEW_FEEDBACK,
    currentUser?.accountPolicies || [],
    currentUser?.groupPolicies || []
  );

  const taskPagePermission = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.VIEW_TASKS,
    currentUser?.accountPolicies || [],
    currentUser?.groupPolicies || []
  );

  const {
    allClosedThreadsQueryHandling,
    allOpenThreadsQueryHandling,
    myClosedThreadsQueryHandling,
    myOpenThreadsQueryHandling,
    myInboxThreadsQueryHandling,
    inboxSpamThreadsQueryHandling,
  } = useQueryThreads({
    client,
    currentUser,
    threadsActiveGroupIds,
    store,
    socketConnectionTimestamp,
    includeInternal,
    includeSpam: ff_spam_filtering,
  });

  useBackupQueryThreads({
    client,
    currentUser,
    threadsActiveGroupIds,
    includeInternal,
  });

  return applicationLoaded ? (
    <>
      <Sidebar
        announcementsPagePermission={announcementsPagePermission}
        reportsPagePermission={reportsPagePermission}
        dashboardPagePermission={dashboardPagePermission}
        feedbackPagePermission={feedbackPagePermission}
        taskPagePermission={taskPagePermission}
      />
      <ThreadNotificationContainer />
      <CreateSection />
      <GlobalModalsContainer />

      <Routes
        allClosedThreadsQueryHandling={allClosedThreadsQueryHandling}
        allOpenThreadsQueryHandling={allOpenThreadsQueryHandling}
        announcementsPagePermission={announcementsPagePermission}
        dashboardPagePermission={dashboardPagePermission}
        feedbackPagePermission={feedbackPagePermission}
        includeInternal={includeInternal}
        myClosedThreadsQueryHandling={myClosedThreadsQueryHandling}
        myInboxThreadsQueryHandling={myInboxThreadsQueryHandling}
        inboxSpamThreadsQueryHandling={inboxSpamThreadsQueryHandling}
        myOpenThreadsQueryHandling={myOpenThreadsQueryHandling}
        reportsPagePermission={reportsPagePermission}
        taskPagePermission={taskPagePermission}
      />

      <Snackbar />
    </>
  ) : (
    <LazyFallback />
  );
};

export default SignedInApp;
