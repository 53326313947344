import React, { useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import styled from 'styled-components';
import { useCustomerPhoneEdit } from 'client-lib';
import { contactName } from 'client-lib/src/lib/utils/helpers';
import { useApolloClient } from '@apollo/client';
import { Modal, Text, TextInput } from '../../elements';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Subtitle = styled.div`
  margin-top: -24px;
  margin-bottom: 16px;
`;

const AddPhoneNumber = ({ open, onClose, contact, onSave, onError }) => {
  const client = useApolloClient();
  const [phoneNumberError, setPhoneNumberError] = useState();

  const [number, formattedNumber, onChange, onSubmit] = useCustomerPhoneEdit({
    client,
    onSave,
    onError,
    setPhoneNumberError,
    customer: contact,
    i18n,
  });

  return (
    <Modal
      isOpen={open}
      size="sm"
      onRequestClose={onClose}
      primaryButtonOnClick={onSubmit}
      modalTitle={i18n.t('modals-AddPhoneNumber-addPhone', {
        defaultValue: 'Add Phone Number',
      })}
      primaryButtonText={i18n.t('modals-AddMember-save')}
      secondaryButtonText={i18n.t('modals-AddMember-cancel')}
      primaryButtonProps={{
        disabled: !number.value || number.error || phoneNumberError,
      }}
      secondaryButtonOnClick={onClose}
    >
      <ModalContent>
        <Subtitle>
          <Text>{contactName(contact)}</Text>
        </Subtitle>
        <TextInput
          label={i18n.t('slideouts-CustomerInfo-number', {
            defaultValue: 'Phone Number',
          })}
          placeholder={i18n.t('modals-AddPhoneNumber-enterPhoneNumber', {
            defaultValue: 'Enter Phone Number',
          })}
          value={formattedNumber}
          onChange={onChange}
          error={phoneNumberError || number?.error}
        />
      </ModalContent>
    </Modal>
  );
};

AddPhoneNumber.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  contact: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default AddPhoneNumber;
