import styled from 'styled-components';
import THEMES from '../../../styles/themes/app';
import { Text } from '../../../elements';
import { BREAKPOINT_LG } from '../../../utils/helpers/constants';

export const GraphContainer = styled.section`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  gap: 12px;
`;

export const StackedBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 8px;
  max-width: 250px;
  @media screen and (max-width: ${BREAKPOINT_LG}) {
    max-width: 1044px;
    flex: auto;
  }
`;

export const StackedBarBackground = styled.div`
  height: 8px;
  width: 250px;
  background-color: ${THEMES.BORDER_COLOR};
  border-radius: 4px;
  overflow: hidden; // creates border-radius for first color
  @media screen and (max-width: ${BREAKPOINT_LG}) {
    width: 100%;
  }
`;

export const StackedBarValue = styled.div<{
  fillpercentage: number;
  barColor: string;
}>`
  border-radius: 4px;
  width: ${(props) => props.fillpercentage}%;
  max-width: 100%;
  height: 100%;
  background-color: ${(props) => props.barColor};
`;

export const BarDataContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const CountWrapper = styled.div`
  min-width: 24px; // attempt to stop the graphs from jumping
`;

export const NameText = styled(Text)`
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (max-width: ${BREAKPOINT_LG}) {
    width: 150px;
    max-width: 150px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  @media screen and (max-width: ${BREAKPOINT_LG}) {
    flex: auto;
  }
`;

export const SecondaryTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
