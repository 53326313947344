import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from 'styled-theming';
import COLORS from '../../styles/colors';
import Button from '../Button/Button';
import { Heading2, Text } from '../Text/Text';
import FONTSIZE_THEMES from '../../styles/themes/fontSize/fontSize';
import THEMES from '../../styles/themes/app';

const BORDER = theme('mode', {
  classic: COLORS.PK_CLASSIC.SECONDARY_GRAY,
  light: COLORS.PK.GREY_20,
  dark: COLORS.PK_DARK.GREY_4,
  holiday: COLORS.PK.GREY_20,
});

const TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.SECONDARY_BLACK,
  light: COLORS.PK.BLACK,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_HIGH,
  holiday: COLORS.PK.BLACK,
});

const Container = styled.div`
  border-style: solid;
  border-color: ${BORDER};
  border-width: 1px;
  background-color: ${(props) => {
    switch (props.type) {
      case 'secondary':
        return THEMES.BACKGROUND_SECONDARY;
      case 'quaternary':
        return THEMES.BACKGROUND_QUATERNARY;
      default:
        return THEMES.BACKGROUND_PRIMARY;
    }
  }};
  box-shadow: ${(props) =>
    props.raised ? '0 1px 4px 0 rgba(0,0,0,0.15)' : 'none'};
`;

const CardStyle = styled.div`
  padding: ${(props) => (props.noPadding ? '0' : '16px')};
  font-size: ${FONTSIZE_THEMES.CARDTEXT};
  font-family: 'Barlow', sans-serif;
  color: ${TEXT};
`;

const Head = styled.div`
  padding: 24px 16px 8px 16px;
  display: flex;
  justify-content: space-between;
`;

const Footer = styled.div`
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

const SecondaryButtonWrap = styled.div``;

const HeaderIconContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Card = ({
  children,
  type,
  raised,
  noPadding,
  headerText,
  headerButtonText,
  headerButtonOnClick,
  primaryFooterButtonProps,
  primaryFooterButtonText,
  primaryFooterButtonOnClick,
  secondaryFooterButtonText,
  secondaryFooterButtonProps,
  secondaryFooterButtonOnClick,
  customStyle,
  customHeaderContainerStyle,
  customHeaderStyle,
  customHeaderButtonStyle,
  customChildrenStyle,
  customFooterStyle,
  customPrimaryFooterButtonStyle,
  customSecondaryFooterButtonStyle,
  customFooterButtonsText,
  customHeaderButton,
  dataTestId,
  headerIcon,
  headingContainerMaxWidth,
  subText,
  ...otherProps
}) => (
  <Container
    type={type}
    raised={raised}
    customStyle={customStyle}
    css={(props) => props?.customStyle?.(props)}
  >
    {headerText && (
      <Head
        customHeaderContainerStyle={customHeaderContainerStyle}
        css={(props) => props?.customHeaderContainerStyle?.(props)}
      >
        <HeadingContainer style={{ maxWidth: headingContainerMaxWidth }}>
          <Heading2
            customHeaderStyle={customHeaderStyle}
            css={(props) => props?.customHeaderStyle?.(props)}
          >
            {headerIcon && (
              <HeaderIconContainer>
                {headerIcon()}
                {headerText}
              </HeaderIconContainer>
            )}
            {!headerIcon && headerText}
          </Heading2>
          <Text
            customStyle={() => `
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
          `}
          >
            {subText}
          </Text>
        </HeadingContainer>
        {customHeaderButton?.({ onClick: headerButtonOnClick })}
        {headerButtonText && !customHeaderButton && (
          <Button
            type="secondary"
            size="md"
            onClick={headerButtonOnClick}
            customStyle={customHeaderButtonStyle}
          >
            {headerButtonText}
          </Button>
        )}
      </Head>
    )}
    <CardStyle
      data-testid={dataTestId}
      noPadding={noPadding}
      customChildrenStyle={customChildrenStyle}
      css={(props) => props?.customChildrenStyle?.(props)}
      {...otherProps}
    >
      {children}
    </CardStyle>
    {primaryFooterButtonText || secondaryFooterButtonText ? (
      <Footer
        customFooterStyle={customFooterStyle}
        css={(props) => props?.customFooterStyle?.(props)}
      >
        {customFooterButtonsText}
        {secondaryFooterButtonText && (
          <SecondaryButtonWrap>
            <Button
              type="secondary"
              onClick={secondaryFooterButtonOnClick}
              customStyle={customSecondaryFooterButtonStyle}
              {...secondaryFooterButtonProps}
            >
              {secondaryFooterButtonText}
            </Button>
          </SecondaryButtonWrap>
        )}
        {primaryFooterButtonText && (
          <Button
            onClick={primaryFooterButtonOnClick}
            customStyle={customPrimaryFooterButtonStyle}
            {...primaryFooterButtonProps}
          >
            {primaryFooterButtonText}
          </Button>
        )}
      </Footer>
    ) : (
      ''
    )}
  </Container>
);

Card.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.object,
    PropTypes.node,
  ]),
  type: PropTypes.string,
  raised: PropTypes.bool,
  noPadding: PropTypes.bool,
  headerText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  headerButtonText: PropTypes.string,
  headerButtonOnClick: PropTypes.func,
  primaryFooterButtonText: PropTypes.string,
  primaryFooterButtonOnClick: PropTypes.func,
  primaryFooterButtonProps: PropTypes.object,
  secondaryFooterButtonText: PropTypes.string,
  secondaryFooterButtonOnClick: PropTypes.func,
  secondaryFooterButtonProps: PropTypes.object,
  customStyle: PropTypes.func,
  customHeaderContainerStyle: PropTypes.func,
  customHeaderStyle: PropTypes.func,
  customHeaderButtonStyle: PropTypes.func,
  customChildrenStyle: PropTypes.func,
  customFooterStyle: PropTypes.func,
  customPrimaryFooterButtonStyle: PropTypes.func,
  customSecondaryFooterButtonStyle: PropTypes.func,
  customFooterButtonsText: PropTypes.string,
  headerIcon: PropTypes.func,
  customHeaderButton: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  dataTestId: PropTypes.string,
  headingContainerMaxWidth: PropTypes.string,
  subText: PropTypes.string,
};

Card.defaultProps = {
  children: null,
  type: 'primary',
  raised: false,
  noPadding: false,
  headerText: null,
  headerButtonText: null,
  headerButtonOnClick: null,
  primaryFooterButtonText: null,
  primaryFooterButtonProps: {},
  primaryFooterButtonOnClick: null,
  secondaryFooterButtonText: null,
  secondaryFooterButtonOnClick: null,
  secondaryFooterButtonProps: {},
  customStyle: null,
  customHeaderContainerStyle: null,
  customHeaderStyle: null,
  customHeaderButtonStyle: null,
  customChildrenStyle: null,
  customFooterStyle: null,
  customPrimaryFooterButtonStyle: null,
  customSecondaryFooterButtonStyle: null,
  customFooterButtonsText: '',
  customHeaderButton: null,
  headerIcon: null,
  headingContainerMaxWidth: null,
  dataTestId: '',
  subText: '',
};

export default Card;
