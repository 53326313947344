import theme from 'styled-theming';
import { calcLargeSize } from './fontSize';

const DEFAULTFONTSIZES = {
  BUTTON_ZERO: 12.8, // 0.8rem
  BUTTON_SM: 11,
  BUTTON_MD: 12,
  BUTTON_LG: 14,
  ICON_BUTTON_XS: 10,
  ICON_BUTTON_SM: 16,
  ICON_BUTTON_MD: 22,
  ICON_BUTTON_LG: 28,
};

const LARGEFONTSIZES = {
  BUTTON_ZERO: calcLargeSize(DEFAULTFONTSIZES.BUTTON_ZERO),
  BUTTON_SM: calcLargeSize(DEFAULTFONTSIZES.BUTTON_SM),
  BUTTON_MD: calcLargeSize(DEFAULTFONTSIZES.BUTTON_MD),
  BUTTON_LG: calcLargeSize(DEFAULTFONTSIZES.BUTTON_LG),
  ICON_BUTTON_XS: calcLargeSize(DEFAULTFONTSIZES.ICON_BUTTON_XS),
  ICON_BUTTON_SM: calcLargeSize(DEFAULTFONTSIZES.ICON_BUTTON_SM),
  ICON_BUTTON_MD: calcLargeSize(DEFAULTFONTSIZES.ICON_BUTTON_MD),
  ICON_BUTTON_LG: calcLargeSize(DEFAULTFONTSIZES.ICON_BUTTON_LG),
};

const BUTTON_ZERO = theme('fontSize', {
  default: `${DEFAULTFONTSIZES.BUTTON_ZERO}px`,
  large: `${LARGEFONTSIZES.BUTTON_ZERO}px`,
});

const BUTTON_SM = theme('fontSize', {
  default: `${DEFAULTFONTSIZES.BUTTON_SM}px`,
  large: `${LARGEFONTSIZES.BUTTON_SM}px`,
});

const BUTTON_MD = theme('fontSize', {
  default: `${DEFAULTFONTSIZES.BUTTON_MD}px`,
  large: `${LARGEFONTSIZES.BUTTON_MD}px`,
});

const BUTTON_LG = theme('fontSize', {
  default: `${DEFAULTFONTSIZES.BUTTON_LG}px`,
  large: `${LARGEFONTSIZES.BUTTON_LG}px`,
});

const ICON_BUTTON_XS = theme('fontSize', {
  default: DEFAULTFONTSIZES.ICON_BUTTON_XS,
  large: LARGEFONTSIZES.ICON_BUTTON_XS,
});

const ICON_BUTTON_SM = theme('fontSize', {
  default: DEFAULTFONTSIZES.ICON_BUTTON_SM,
  large: LARGEFONTSIZES.ICON_BUTTON_SM,
});

const ICON_BUTTON_MD = theme('fontSize', {
  default: DEFAULTFONTSIZES.ICON_BUTTON_MD,
  large: LARGEFONTSIZES.ICON_BUTTON_MD,
});

const ICON_BUTTON_LG = theme('fontSize', {
  default: DEFAULTFONTSIZES.ICON_BUTTON_LG,
  large: LARGEFONTSIZES.ICON_BUTTON_LG,
});

const FONTSIZE_THEMES = {
  BUTTON_ZERO,
  BUTTON_SM,
  BUTTON_MD,
  BUTTON_LG,
  ICON_BUTTON_XS,
  ICON_BUTTON_SM,
  ICON_BUTTON_MD,
  ICON_BUTTON_LG,
};

export default FONTSIZE_THEMES;
