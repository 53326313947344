import {
  SET_CREATE_LABEL_FLYOUT,
  SET_EDIT_LABEL_FLYOUT,
} from '../actions/label';

const DEFAULT_STATE = {
  createLabelState: false,
  editLabelState: false,
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_CREATE_LABEL_FLYOUT:
      return {
        ...state,
        editLabelState: false,
        createLabelState: action.value,
      };
    case SET_EDIT_LABEL_FLYOUT:
      return {
        ...state,
        createLabelState: false,
        editLabelState: action.value,
      };
    default:
      return state;
  }
}
