import useHasFaxChannel from "./useHasFaxChannel";
import useHasFaxFeatureFlag from "./useHasFaxFeatureFlag";

function useIsFaxAvailable({ currentUser, threadsActiveGroupIds, client }) {
  const { hasFaxChannel } = useHasFaxChannel({
    currentUser,
    threadsActiveGroupIds,
    client,
  });

  const { faxFeatureFlag } = useHasFaxFeatureFlag({ currentUser, client });

  const faxFeatureAvailable = !!(faxFeatureFlag && hasFaxChannel);
  return { faxFeatureAvailable };
}

export default useIsFaxAvailable;
