import { useRef, useState } from 'react';

/**
 * Custom hook for MeasurableContainers, used to determine the cutoff width and when to display
 * badges after loading
 * @param {number} maxWidth - width of its container
 * @param {array} badges
 * @returns object
 */
export const useWidthLimiter = (maxWidth, badges) => {
  const [badgeCutoffNum, setBadgeCutoffNum] = useState(undefined);
  const [calculatingWidth, setCalculatingWidth] = useState(true);
  const widthLimiter = useRef(maxWidth - 30);

  const widthCallback = (badgeWidth, i) => {
    let newWidthLimiter;

    if (widthLimiter?.current) {
      const oldWidthLimiter = widthLimiter?.current;
      newWidthLimiter = oldWidthLimiter - badgeWidth;
      widthLimiter.current = newWidthLimiter;

      if (oldWidthLimiter > 0 && newWidthLimiter <= 0) {
        setBadgeCutoffNum(i);
      }
    }
    // Waits for last badge to be rendered to make this element visible
    if (i === badges.length - 1) setCalculatingWidth(false);
    return newWidthLimiter;
  };

  return { badgeCutoffNum, calculatingWidth, widthCallback };
};
