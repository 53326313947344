import theme from 'styled-theming';
import COLORS from '../../colors';

const BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.ORANGE,
  light: COLORS.PK.RED,
  dark: COLORS.PK.RED,
  holiday: COLORS.PK.RED,
});

const TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.WHITE,
  dark: COLORS.PK.WHITE,
  holiday: COLORS.PK.WHITE,
});

const TEXT_DARK = theme('mode', {
  classic: COLORS.PK_CLASSIC.SECONDARY_BLACK,
  light: COLORS.PK.BLACK,
  dark: COLORS.PK.BLACK,
  holiday: COLORS.PK.BLACK,
});

// -- squarebadge --

const BACKGROUND_YELLOW = theme('mode', {
  classic: COLORS.PK_CLASSIC.WARNING_YELLOW,
  light: COLORS.PK.GOLD_100,
  dark: COLORS.PK.GOLD_100,
  holiday: COLORS.PK.GOLD_100,
});

const BACKGROUND_GREY = theme('mode', {
  classic: COLORS.PK_CLASSIC.SECONDARY_GRAY,
  light: COLORS.PK.GREY_20,
  dark: COLORS.PK_DARK.GREY_3,
  holiday: COLORS.PK.GREY_20,
});

const TEXT_GREY = theme('mode', {
  classic: COLORS.PK_CLASSIC.SECONDARY_BLACK,
  light: COLORS.PK.BLACK,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_HIGH,
  holiday: COLORS.PK.BLACK,
});

const BACKGROUND_BLUE = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_BLUE,
  light: COLORS.PK.BLUE,
  dark: COLORS.PK.BLUE,
  holiday: COLORS.PK.BLUE,
});

const BACKGROUND_GREEN = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_GREEN,
  light: COLORS.PK.GREEN_80,
  dark: COLORS.PK.GREEN_80,
  holiday: COLORS.PK.GREEN_80,
});

const BACKGROUND_CHARCOAL = theme('mode', {
  classic: COLORS.PK_CLASSIC.SECONDARY_BLACK,
  light: COLORS.PK.GREY_80,
  dark: COLORS.PK.GREY_80,
  holiday: COLORS.PK.GREY_80,
});

const BACKGROUND_BLACK = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_BLACK,
  light: COLORS.PK.BLACK,
  dark: COLORS.PK.BLACK,
  holiday: COLORS.PK.BLACK,
});

const THEMES = {
  BACKGROUND,
  TEXT,
  TEXT_DARK,
  TEXT_GREY,
  BACKGROUND_YELLOW,
  BACKGROUND_GREY,
  BACKGROUND_BLUE,
  BACKGROUND_GREEN,
  BACKGROUND_CHARCOAL,
  BACKGROUND_BLACK,
};

export default THEMES;
