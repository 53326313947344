import isEmpty from "lodash/isEmpty";

/*
DOCUMENTATION:
  useUserGroups is designed to return an accurate list of groups
  that the current active user has general access to.

  args:
    currentUser: (obj) required. current user object found in client side redux store -> session.currentUser
    allGroups: (arr) required. Groups array that comes from the state manager.

  returns:
    groups: (arr) alphabetically sorted array of groups the user is currently a part of (or has access to in the admin's case)

*/

const useUserGroups = (currentUser, allGroups) => {
  // allGroups is already sorted in redux
  // however I am scared to eliminate this
  // sort function
  const sortGroupList = (groups) => {
    if (!groups) {
      return [];
    }
    const listCopy = groups.slice();
    return listCopy.sort((a, b) => ("" + a.name).localeCompare(b.name));
  };

  let sortedGroups;
  if (allGroups?.length) {
    sortedGroups = sortGroupList(allGroups);
  }

  let groups = [];

  if (isEmpty(currentUser)) {
    return {};
  }

  if (sortedGroups !== undefined) {
    groups = sortedGroups.filter((group) =>
      currentUser.groupIds.includes(group.id)
    );
  }

  return {
    groups,
  };
};

export default useUserGroups;
