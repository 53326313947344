import React, { type MutableRefObject } from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { EditorRefPlugin } from '@lexical/react/LexicalEditorRefPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { ListItemNode, ListNode } from '@lexical/list';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { type EditorState, type LexicalEditor } from 'lexical';
import styled from 'styled-components';
import Toolbar from './plugins/Toolbar';
import AutoLink from './plugins/AutoLink';
import OnKeyDownPlugin, { type IWatchCombo } from './plugins/OnKeyDownPlugin';
import DefaultTheme from './shared/DefaultTheme';
import { EditorWrapper } from './styles';
import { type RichTextChangeListener } from './utils/onChangeFactory';
import InputLabel from '../inputCommonElements/InputLabel';
import FONTSIZE_THEMES from '../../styles/themes/fontSize/fontSize';
import THEMES from '../../styles/themes/library/textInput';
import { ImageNode } from './nodes/ImageNode';
import ImagePlugin from './plugins/ImagePlugin';
import type { Attachment } from '../../utils/helpers/types';
import BUTTON_THEMES from '../../styles/themes/library/button';
import { InputError } from './externalDependencies';

const Container = styled.div`
  width: 100%;
`;

const Spacer = styled.div`
  padding-bottom: 32px;
`;

const EditorContainer = styled.div<{ border?: string; error?: boolean }>`
  width: 100%;
  border: ${(props) => props.border || '1px solid'};
  box-sizing: border-box;
  border-radius: 4px;
  font-size: ${FONTSIZE_THEMES.TEXTAREA_TEXT};
  font-weight: 500;
  font-family: 'Barlow', sans-serif;
  resize: none;
  outline: none;

  border-color: ${(props) => (props.error ? THEMES.ERROR : THEMES.BORDER)};

  background-color: ${THEMES.BACKGROUND};
  cursor: auto;

  :focus {
    outline: ${THEMES.FOCUS} auto 1px;
  }

  ::placeholder {
    color: ${THEMES.PLACEHOLDER};
  }

  a {
    color: ${(props) => BUTTON_THEMES.LINK_TEXT(props)};

    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
    }
  }
`;

const RightSideLabelWrapper = styled.div`
  margin: 8px 0;
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

interface RichTextEditorStyleOverrides {
  minHeight?: string;
  maxHeight?: string;
  // Set border style
  border?: string;
  // When true, overrides boarder color and displays error color
  error?: boolean;
}

interface RichTextEditorProps {
  defaultText: string;
  onError: (error: Error) => void;
  onChangeListeners: [RichTextChangeListener];
  editorRef: MutableRefObject<LexicalEditor | null>;
  label?: string;
  rightSideLabelContent?: React.ReactNode;
  autoSetFocusOnMount?: boolean;
  onEditorKeyDown?: IWatchCombo | [IWatchCombo];
  styleOverrides?: RichTextEditorStyleOverrides;
  testId?: string;
  attachments?: Attachment[];
  hideBottomSpace?: boolean;
  errorToDisplay?: string;
}

const normalizeKeyDownEvents = (
  onEditorKeyDown: IWatchCombo | [IWatchCombo]
): [IWatchCombo] => {
  if (Array.isArray(onEditorKeyDown)) {
    return onEditorKeyDown;
  }
  return [onEditorKeyDown];
};

const BottomArea = ({
  hideBottomSpace,
  errorToDisplay,
  testId,
}: {
  hideBottomSpace: boolean;
  errorToDisplay?: string;
  testId: string;
}) => {
  if (errorToDisplay) {
    return (
      <InputError error={errorToDisplay} dataTestId={`${testId}-input-error`} />
    );
  }
  if (hideBottomSpace) return null;
  return <Spacer data-testid={`${testId}-spacer`} />;
};

/**
 * RichTextEditor is a component that allows users to input rich text content.
 *
 * @param {object} props - The component props.
 * @param {string} props.defaultText - The default text to display in the editor.
 * @param {function} props.onError - A callback function that is called when an error occurs.
 * @param {Ref} props.editorRef - A ref to the editor instance. This is required to pass the editor instance to the parent component. See the `EditorRefPlugin` in the component for more details.
 * @param {[RichTextChangeListener]} props.onChangeListeners - An array of change listeners that are called when the editor content changes.
 * @param {[Attachment]} props.attachments - An array of attachments to be displayed in the toolbar.
 * @param {string} [props.label] - The label to display above the editor.
 * @param {ReactNode} [props.rightSideLabelContent] - The content to display to the right of the label.
 * @param {boolean} [props.autoSetFocusOnMount] - Whether to automatically set focus on the editor when the component mounts.
 * @param {IWatchCombo | [IWatchCombo]} [props.onEditorKeyDown] - A keydown event listener that is called when a key is pressed in the editor.
 * @param {RichTextEditorStyleOverrides} [props.styleOverrides] - Style overrides for the editor.
 * @param {string} [props.testId] - The test ID for the component.
 * @param {boolean} [props.hideBottomSpace] - Whether to hide the bottom space. Backwards compatabilty with TextEditor
 */
const RichTextEditor = ({
  defaultText,
  onError,
  onChangeListeners,
  editorRef,
  label,
  rightSideLabelContent,
  autoSetFocusOnMount = false,
  onEditorKeyDown,
  styleOverrides,
  errorToDisplay,
  hideBottomSpace = false,
  testId = 'rich-text-editor',
  attachments,
}: RichTextEditorProps) => {
  const editorConfig = {
    namespace: 'RichTextEditor',
    nodes: [
      HeadingNode,
      QuoteNode,
      ListNode,
      ListItemNode,
      AutoLinkNode,
      LinkNode,
      ImageNode,
    ],
    theme: DefaultTheme,
    onError,
  };

  const onChange = (editorState: EditorState) =>
    onChangeListeners.forEach((listener) => listener(editorState));

  const handleEditorDragStart = (event: React.DragEvent) => {
    event.stopPropagation();
    // Check if it's an ImageNode drag
    if (event.target instanceof HTMLImageElement) {
      // Add a custom data type to mark Lexical drags
      event.dataTransfer.setData('text/lexical-image', 'true');
    }
  };

  const handleEditorDragHover = (event: React.DragEvent) => {
    event.stopPropagation();
    // Handle Lexical-specific drag hover logic here if needed
  };

  // This is required to allow lexical functionality to handle the drop event
  const handleEditorDrop = (event: React.DragEvent) => {
    event.stopPropagation();
    // Handle Lexical-specific drop logic here if needed
  };

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <Container
        data-testid={testId}
        onDragStart={handleEditorDragStart}
        onDragOver={handleEditorDragHover}
        onDragLeave={handleEditorDragHover}
        onDrop={handleEditorDrop}
      >
        <LabelWrapper>
          {label && (
            <InputLabel data-testid={`${testId}-label`}>{label}</InputLabel>
          )}
          {rightSideLabelContent && (
            <RightSideLabelWrapper data-testid={`${testId}-right-of-label`}>
              {rightSideLabelContent}
            </RightSideLabelWrapper>
          )}
        </LabelWrapper>
        <EditorWrapper {...styleOverrides}>
          <EditorContainer {...styleOverrides}>
            <Toolbar attachments={attachments} onError={onError} />
            <div className="editor-inner">
              <RichTextPlugin
                contentEditable={
                  <ContentEditable
                    className="editor-input"
                    data-testid="editor-input"
                    aria-placeholder={defaultText}
                    placeholder={
                      <div className="editor-placeholder">{defaultText}</div>
                    }
                  />
                }
                ErrorBoundary={LexicalErrorBoundary}
              />
              <HistoryPlugin />
              <ListPlugin />
              <ImagePlugin />
              <LinkPlugin />
              <AutoLink />
            </div>
            <EditorRefPlugin editorRef={editorRef} />
            {onChangeListeners && <OnChangePlugin onChange={onChange} />}
            {autoSetFocusOnMount && <AutoFocusPlugin />}
            {onEditorKeyDown && (
              <OnKeyDownPlugin
                watchCombos={normalizeKeyDownEvents(onEditorKeyDown)}
              />
            )}
          </EditorContainer>
        </EditorWrapper>
      </Container>
      {/* This accounts for the InputError at the bottom of the TextArea
        which this control replaces. 
      */}
      <BottomArea
        hideBottomSpace={hideBottomSpace}
        errorToDisplay={errorToDisplay}
        testId={testId}
      />
    </LexicalComposer>
  );
};

RichTextEditor.defaultProps = {
  label: null,
  rightSideLabelContent: null,
  testId: 'rich-text-editor',
};

export default RichTextEditor;
