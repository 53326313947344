import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  canPreviewBasedOnFileExtension,
  fileExtension,
  isAnimatedGif,
} from 'client-lib/src/lib/utils/helpers';
import { Text } from '../Text/Text';
import COLORS from '../../styles/colors';
import THEMES from '../../styles/themes/app';
import ICON_BUTTON_THEMES from '../../styles/themes/library/iconButton';
import IconButton from '../Button/IconButton';
import Anchor from '../Anchor/Anchor';

const TILE_WIDTH = 248;

const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${TILE_WIDTH}px;
  max-width: ${TILE_WIDTH}px;
`;

const AttachmentTileContainer = styled.div`
  background: ${THEMES.BACKGROUND_PRIMARY};
  border: 1px solid ${THEMES.BORDER_COLOR};
  border-radius: 3px;
  display: flex;
  width: ${TILE_WIDTH}px;
  max-width: ${TILE_WIDTH}px;
  box-sizing: border-box;
  align-items: stretch;
  height: 56px;
  max-height: 56px;
  min-height: 56px;
  position: relative;
  padding-right: 4px;
  box-shadow: ${(props) =>
    props.boxShadow ? THEMES.BOX_SHADOW(props) : 'none'};
  ${(props) => props.isDownloadablePDF && 'cursor: pointer;'};
`;

const IconDisplay = styled.div`
  background: ${THEMES.BACKGROUND_SECONDARY};
  width: 56px;
  max-width: 56px;
  min-width: 56px;
  overflow: hidden;
  border-radius: 2px 0 0 2px;
`;

const FilenameDisplay = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  overflow: hidden;
  padding-left: 12px;
  flex: 1;
`;

const IconWrap = styled.div`
  color: ${(props) => props.iconColor};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
`;

const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ActionButtonWrapper = styled.div`
  padding-left: 8px;
  padding-right: 8px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const deleteButtonCustomStyle = () => `
  position: absolute;
  right: -12px;
  top: -12px;
  border-radius: 50%;
`;

const customStyleText = () => `
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const anchorCustomStyle = (props) => `
  font-size: 18px !important;
  text-decoration: none !important;
  line-height: 16px;
  i {
    color: ${ICON_BUTTON_THEMES.FOREGROUND_LOW(props)} !important;
  }
`;

const FILETYPES = {
  PDF: 'PDF',
  XLS: 'XLS',
  XLSX: 'XLSX',
  DOC: 'DOC',
  DOCX: 'DOCX',
  PPT: 'PPT',
  PPTX: 'PPTX',
  JPG: 'JPG',
  PNG: 'PNG',
  GIF: 'GIF',
};

const attachmentFiletypeShortener = (filetype) => {
  switch (filetype) {
    case 'pdf':
      return FILETYPES.PDF;

    case 'xls':
      return FILETYPES.XLS;

    case 'xlsx':
      return FILETYPES.XLSX;

    case 'doc':
      return FILETYPES.DOC;

    case 'docx':
      return FILETYPES.DOCX;

    case 'ppt':
      return FILETYPES.PPT;

    case 'pptx':
      return FILETYPES.PPTX;

    case 'jpg':
      return FILETYPES.JPG;

    case 'jpeg':
      return FILETYPES.JPG;

    case 'png':
      return FILETYPES.PNG;

    case 'gif':
      return FILETYPES.GIF;

    default:
      return filetype;
  }
};

const fileIconAttributeGenerator = (filetype) => {
  switch (filetype) {
    case FILETYPES.PDF:
      return {
        remixId: 'ri-file-pdf-line',
        color: '#FA0F00',
      };

    case FILETYPES.XLS:
      return {
        remixId: 'ri-file-excel-line',
        color: '#0D6F37',
      };

    case FILETYPES.DOCX:
      return {
        remixId: 'ri-file-word-line',
        color: '#1858BA',
      };

    case FILETYPES.PPT:
      return {
        remixId: 'ri-file-ppt-line',
        color: '#D14324',
      };

    default:
      return {
        remixId: 'ri-file-3-line',
        color: COLORS.PK.GREY_60,
      };
  }
};

const AttachmentTile = ({
  attachment,
  onDelete,
  downloadable,
  allowAnnotation,
  onAnnotate,
  boxShadow,
}) => {
  const [hovering, setHovering] = useState(false);
  const [pngFallback, setPngFallback] = useState(false);
  const [displayAnnotateButton, setDisplayAnnotateButton] = useState(false);

  const shortenedFiletype = attachmentFiletypeShortener(
    fileExtension(attachment?.originalFilename)
  );
  const iconAtributes = fileIconAttributeGenerator(shortenedFiletype);

  const canPreviewAsImage = canPreviewBasedOnFileExtension(
    attachment?.originalFilename
  );

  let photoSrc;
  if (canPreviewAsImage) {
    photoSrc =
      attachment.data ||
      `/attachments/${attachment.id}/original/${attachment.originalFilename}`;
  }

  const openInNewTab = () =>
    window.open(
      `/attachments/${attachment.id}/original/${attachment.originalFilename}`,
      '_blank'
    );

  useEffect(() => {
    if (
      allowAnnotation &&
      canPreviewBasedOnFileExtension(attachment?.originalFilename)
    ) {
      isAnimatedGif(photoSrc, (val) => {
        setDisplayAnnotateButton(!val);
      });
    }
  }, [attachment.id]);

  const isDownloadablePDF = shortenedFiletype === FILETYPES.PDF && downloadable;

  return (
    <OuterContainer>
      <AttachmentTileContainer
        boxShadow={boxShadow}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        onClick={isDownloadablePDF ? openInNewTab : null}
        isDownloadablePDF={isDownloadablePDF}
      >
        {onDelete && hovering && (
          <IconButton
            className="ri-close-circle-fill"
            size="md"
            customStyle={deleteButtonCustomStyle}
            onClick={onDelete}
          />
        )}
        <IconDisplay>
          {canPreviewAsImage ? (
            <PreviewImage
              src={photoSrc}
              alt={`preview of ${attachment.originalFilename}`}
            />
          ) : (
            <IconWrap>
              {shortenedFiletype === FILETYPES.PDF && !pngFallback ? (
                <img
                  src="/pk-svgs/adobe-icon.svg"
                  alt="adobe icon"
                  onError={() => {
                    setPngFallback(true);
                  }}
                />
              ) : (
                <IconWrap iconColor={iconAtributes.color}>
                  <i className={iconAtributes.remixId} />
                </IconWrap>
              )}
            </IconWrap>
          )}
        </IconDisplay>
        <FilenameDisplay>
          <Text customStyle={customStyleText}>
            {attachment?.originalFilename}
          </Text>
          <Text customStyle={customStyleText} contrast="low">
            {shortenedFiletype}
          </Text>
        </FilenameDisplay>
        {downloadable && attachment?.id && hovering && !isDownloadablePDF && (
          <ActionButtonWrapper>
            <Anchor
              target="_blank"
              href={`/attachments/${attachment.id}/original/${attachment.originalFilename}`}
              download={attachment.originalFilename}
              customStyle={anchorCustomStyle}
            >
              <i className="ri-download-cloud-fill" />
            </Anchor>
          </ActionButtonWrapper>
        )}
        {allowAnnotation &&
          displayAnnotateButton &&
          !downloadable &&
          hovering && (
            <ActionButtonWrapper>
              <IconButton
                onClick={onAnnotate}
                contrast="med"
                size="md"
                noOutline
              >
                <i className="ri-markup-line" />
              </IconButton>
            </ActionButtonWrapper>
          )}
      </AttachmentTileContainer>
    </OuterContainer>
  );
};

AttachmentTile.propTypes = {
  attachment: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
  downloadable: PropTypes.bool,
  allowAnnotation: PropTypes.bool,
  onAnnotate: PropTypes.func,
  boxShadow: PropTypes.bool,
};

AttachmentTile.defaultProps = {
  onDelete: null,
  downloadable: false,
  allowAnnotation: false,
  onAnnotate: null,
  boxShadow: false,
};

export default AttachmentTile;
