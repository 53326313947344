export const INITIALIZE_COMPANY_INPUTS = 'INITIALIZE_COMPANY_INPUTS';
export const TOGGLE_DELETE_CONFIRMATION = 'TOGGLE_DELETE_CONFIRMATION';
export const TOGGLE_DELETE_PROMPT = 'TOGGLE_DELETE_PROMPT';
export const TOGGLE_EDIT_ACCOUNT_MODAL = 'TOGGLE_EDIT_ACCOUNT_MODAL';

export const initialCompanyInputs = (fields) => ({
  type: INITIALIZE_COMPANY_INPUTS,
  fields,
});
export const toggleDeleteConfirmation = (toggleValue) => ({
  type: TOGGLE_DELETE_CONFIRMATION,
  toggleValue,
});
export const toggleDeletePrompt = (toggleValue) => ({
  type: TOGGLE_DELETE_PROMPT,
  toggleValue,
});
export const toggleEditAccountModal = (toggleValue) => ({
  type: TOGGLE_EDIT_ACCOUNT_MODAL,
  toggleValue,
});
