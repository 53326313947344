import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import i18n from 'i18n-js';
import { useSelector } from 'react-redux';
import { TextInput, Modal, TextArea } from '../../elements';
import GroupAsyncSelect from '../AsyncSelects/GroupAsyncSelect';
import { limitedAnncListView } from '../Announcements/Lists/ListsTable';
import { MAX_LIST_LENGTH } from '../../utils/helpers/constants/index.ts';

const AreaContainer = styled(Grid)`
  min-width: 426px;
`;

const InputWrapper = styled(Grid)`
  width: 100%;
`;

const CreateEditList = ({
  open,
  handleOnClose,
  handleOnConfirm,
  list,
  isEdit,
}) => {
  const [listError, setListError] = useState('');
  const [listName, setListName] = useState(list.name);
  const [listDescription, setListDescription] = useState(list.description);
  const [descriptionError, setDescriptionError] = useState('');
  const [listGroups, setListGroups] = useState(
    list.tagGroupShares.map((group) => ({
      label: group.name,
      value: group.id,
    }))
  );

  const currentUser = useSelector((state) => state?.session?.currentUser);

  const limitedAnncListEnabled = limitedAnncListView(currentUser);

  const clearState = () => {
    // Create empty modal
    if (!isEdit) {
      setListName('');
      setListDescription('');
      setListGroups([]);
      // Reset to default conditions
    } else {
      setListName(list.name);
      setListDescription(list.description);
      setListGroups(
        list.tagGroupShares.map((group) => ({
          label: group.name,
          value: group.id,
        }))
      );
    }
    setDescriptionError('');
    setListError('');
  };

  const handleOnConfirmClick = () => {
    if (!listError && !descriptionError) {
      handleOnConfirm(
        listName,
        listDescription,
        listGroups,
        setListError,
        handleOnClose,
        clearState
      );
    }
  };

  const onRequestClose = () => {
    handleOnClose();
    clearState();
  };

  const modalTitle = isEdit
    ? i18n.t('settings-AddOrEditList-editList')
    : i18n.t('settings-AddOrEditList-createNewList');
  const primaryModalButtonText = i18n.t('settings-AddOrEditList-create');

  const handleInputTagChange = (e) => {
    if (e.target.value === '') {
      setListError(i18n.t('slideouts-GroupMessageOverview-listNameRequired'));
    } else {
      setListError('');
    }
    setListName(e.target.value);
  };

  const handleInputListDescriptionChange = (e) => {
    if (e.target.value?.length > 255) {
      setDescriptionError(
        i18n.t('broadcasts-maximum-characterError', {
          defaultValue: "You've exceeded the 255 maximum character limit.",
        })
      );
    } else if (e.target.value.length > MAX_LIST_LENGTH) {
      setListError(
        i18n.t('broadcasts-maximum-characterError', {
          defaultValue: "You've exceeded the 255 maximum character limit.",
        })
      );
    } else {
      setDescriptionError('');
    }
    setListDescription(e.target.value);
  };

  const disabledPrimary = limitedAnncListEnabled
    ? !listName || !listGroups?.length
    : !listName;

  const invalid = listError || descriptionError;

  return (
    <Modal
      size="sm"
      isOpen={open}
      onRequestClose={onRequestClose}
      modalTitle={modalTitle}
      primaryButtonText={primaryModalButtonText}
      primaryButtonOnClick={handleOnConfirmClick}
      secondaryButtonText={i18n.t('slideouts-GroupMessageOverview-cancel')}
      secondaryButtonOnClick={onRequestClose}
      primaryButtonProps={{ disabled: disabledPrimary || invalid }}
    >
      <Grid container>
        <InputWrapper>
          <TextInput
            type="text"
            value={listName}
            label={i18n.t('settings-AddOrEditList-listName')}
            error={listError}
            onChange={handleInputTagChange}
            autoFocus
          />
        </InputWrapper>

        <AreaContainer>
          <TextArea
            value={listDescription}
            onChange={handleInputListDescriptionChange}
            error={descriptionError}
            rows={5}
            type="text"
            label={i18n.t('slideouts-GroupMessageOverview-description')}
            dataTestId="cei-textarea"
          />
        </AreaContainer>

        {limitedAnncListEnabled ? (
          <GroupAsyncSelect
            value={listGroups}
            setValue={setListGroups}
            label={i18n.t('settings-TeamMemberForm-groups')}
            placeholder={i18n.t(
              'settings-GroupDropDown-groupDropDownPlaceholder'
            )}
            permissionFilter="VIEW_GROUP"
            isMulti
            omitAll
            queryVars={{
              broadcastEnabled: true,
            }}
          />
        ) : null}
      </Grid>
    </Modal>
  );
};

CreateEditList.propTypes = {
  open: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool,
  handleOnClose: PropTypes.func.isRequired,
  handleOnConfirm: PropTypes.func.isRequired,
  listError: PropTypes.string,
  setListError: PropTypes.func,
  list: PropTypes.object,
};

CreateEditList.defaultProps = {
  listError: '',
  isEdit: false,
  setListError: () => {},
  list: {
    name: '',
    description: '',
    tagGroupShares: [],
  },
};

export default CreateEditList;
