import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';
// import { updateApplicationState } from '../../actions';
// import { SignInContext } from '../../containers/App';

const useConnectApolloSocket = () => {
  const client = useApolloClient();
  // const dispatch = useDispatch();

  // const { setSessionLoading } = useContext(SignInContext);
  const currentUserId = useSelector(
    (state) => state.session.currentUser?.userId
  );
  const currentUserAccountId = useSelector(
    (state) => state.session.currentUser?.accountId
  );

  // connects client-side socket on reception of user data
  useEffect(() => {
    if (currentUserId) {
      client.connectSocket(currentUserId, currentUserAccountId);
    }
  }, [currentUserId]);
};

export default useConnectApolloSocket;
