import theme from 'styled-theming';

const DEFAULTFONTSIZES = {
  HEADING1: 33,
  HEADING2: 24,
  HEADING3: 18,
  HEADING4: 16,
  HEADING5: 12,
  TEXT: 14,
  METATEXT: 12,
  EMPHASISTEXT: 14,
  BADGETEXT: 9,
  SQUAREBADGETEXT: 8,
  CARDTEXT: 16,
  CALENDAR_LABEL_TEXT: 24,
  CALENDAR_SMALL_LABEL_TEXT: 20,
  CALENDAR_ABBR_TEXT: 12,
  CALENDAR_DAY_TEXT: 13,
  CHIP_TEXT: 14,
  CHIP_SUBTEXT: 11,
  ENTITYCARD_TEXT: 16,
  ENTITYCARD_SUBTEXT: 14,
  SELECTEDENTITY_TEXT: 14,
  SELECTEDENTITY_SUBTEXT: 13,
  TABLE_TEXT: 14,
  TEXTAREA_TEXT: 14,
  TEXTINPUT_TEXT: 14,
  TEXTINPUT_ICON_TEXT: 16,
  INPUTERROR_TEXT: 13,
  SELECT_TEXT: 14,
  TIMESTAMP: 12,
};

export const calcLargeSize = (defaultSize, specialtyMultiplier = false) => {
  if (specialtyMultiplier) defaultSize + 2;

  return defaultSize + 4;
};

const LARGEFONTSIZES = {
  HEADING1: calcLargeSize(DEFAULTFONTSIZES.HEADING1),
  HEADING2: calcLargeSize(DEFAULTFONTSIZES.HEADING2),
  HEADING3: calcLargeSize(DEFAULTFONTSIZES.HEADING3),
  HEADING4: calcLargeSize(DEFAULTFONTSIZES.HEADING4),
  HEADING5: calcLargeSize(DEFAULTFONTSIZES.HEADING5),
  TEXT: calcLargeSize(DEFAULTFONTSIZES.TEXT),
  METATEXT: calcLargeSize(DEFAULTFONTSIZES.METATEXT),
  EMPHASISTEXT: calcLargeSize(DEFAULTFONTSIZES.EMPHASISTEXT),
  BADGETEXT: calcLargeSize(DEFAULTFONTSIZES.BADGETEXT),
  SQUAREBADGETEXT: calcLargeSize(DEFAULTFONTSIZES.SQUAREBADGETEXT),
  CARDTEXT: calcLargeSize(DEFAULTFONTSIZES.CARDTEXT),
  CALENDAR_LABEL_TEXT: calcLargeSize(DEFAULTFONTSIZES.CALENDAR_LABEL_TEXT),
  CALENDAR_SMALL_LABEL_TEXT: calcLargeSize(
    DEFAULTFONTSIZES.CALENDAR_SMALL_LABEL_TEXT
  ),
  CALENDAR_ABBR_TEXT: calcLargeSize(DEFAULTFONTSIZES.CALENDAR_ABBR_TEXT),
  CALENDAR_DAY_TEXT: calcLargeSize(DEFAULTFONTSIZES.CALENDAR_DAY_TEXT),
  CHIP_TEXT: calcLargeSize(DEFAULTFONTSIZES.CHIP_TEXT),
  CHIP_SUBTEXT: calcLargeSize(DEFAULTFONTSIZES.CHIP_SUBTEXT),
  ENTITYCARD_TEXT: calcLargeSize(DEFAULTFONTSIZES.ENTITYCARD_TEXT, true),
  ENTITYCARD_SUBTEXT: calcLargeSize(DEFAULTFONTSIZES.ENTITYCARD_SUBTEXT, true),
  SELECTEDENTITY_TEXT: calcLargeSize(
    DEFAULTFONTSIZES.SELECTEDENTITY_TEXT,
    true
  ),
  SELECTEDENTITY_SUBTEXT: calcLargeSize(
    DEFAULTFONTSIZES.SELECTEDENTITY_SUBTEXT,
    true
  ),
  TABLE_TEXT: calcLargeSize(DEFAULTFONTSIZES.TABLE_TEXT),
  TEXTAREA_TEXT: calcLargeSize(DEFAULTFONTSIZES.TEXTINPUT_TEXT),
  TEXTINPUT_TEXT: calcLargeSize(DEFAULTFONTSIZES.TEXTINPUT_TEXT),
  TEXTINPUT_ICON_TEXT: calcLargeSize(DEFAULTFONTSIZES.TEXTINPUT_ICON_TEXT),
  INPUTERROR_TEXT: calcLargeSize(DEFAULTFONTSIZES.INPUTERROR_TEXT, true),
  SELECT_TEXT: calcLargeSize(DEFAULTFONTSIZES.SELECT_TEXT),
  TIMESTAMP: calcLargeSize(DEFAULTFONTSIZES.TIMESTAMP),
};

const HEADING1 = theme('fontSize', {
  default: `${DEFAULTFONTSIZES.HEADING1}px`,
  large: `${LARGEFONTSIZES.HEADING1}px`,
});

const HEADING2 = theme('fontSize', {
  default: `${DEFAULTFONTSIZES.HEADING2}px`,
  large: `${LARGEFONTSIZES.HEADING2}px`,
});

const HEADING3 = theme('fontSize', {
  default: `${DEFAULTFONTSIZES.HEADING3}px`,
  large: `${LARGEFONTSIZES.HEADING3}px`,
});

const HEADING4 = theme('fontSize', {
  default: `${DEFAULTFONTSIZES.HEADING4}px`,
  large: `${LARGEFONTSIZES.HEADING4}px`,
});

const HEADING5 = theme('fontSize', {
  default: `${DEFAULTFONTSIZES.HEADING5}px`,
  large: `${LARGEFONTSIZES.HEADING5}px`,
});

const TEXT = theme('fontSize', {
  default: `${DEFAULTFONTSIZES.TEXT}px`,
  large: `${LARGEFONTSIZES.TEXT}px`,
});

const METATEXT = theme('fontSize', {
  default: `${DEFAULTFONTSIZES.METATEXT}px`,
  large: `${LARGEFONTSIZES.METATEXT}px`,
});

const EMPHASISTEXT = theme('fontSize', {
  default: `${DEFAULTFONTSIZES.EMPHASISTEXT}px`,
  large: `${LARGEFONTSIZES.EMPHASISTEXT}px`,
});

const BADGETEXT = theme('fontSize', {
  default: `${DEFAULTFONTSIZES.BADGETEXT}px`,
  large: `${LARGEFONTSIZES.BADGETEXT}px`,
});

const SQUAREBADGETEXT = theme('fontSize', {
  default: `${DEFAULTFONTSIZES.SQUAREBADGETEXT}px`,
  large: `${LARGEFONTSIZES.SQUAREBADGETEXT}px`,
});

const CARDTEXT = theme('fontSize', {
  default: `${DEFAULTFONTSIZES.CARDTEXT}px`,
  large: `${LARGEFONTSIZES.CARDTEXT}px`,
});

const CALENDAR_LABEL_TEXT = theme('fontSize', {
  default: `${DEFAULTFONTSIZES.CALENDAR_LABEL_TEXT}px`,
  large: `${LARGEFONTSIZES.CALENDAR_LABEL_TEXT}px`,
});

const CALENDAR_SMALL_LABEL_TEXT = theme('fontSize', {
  default: `${DEFAULTFONTSIZES.CALENDAR_SMALL_LABEL_TEXT}px`,
  large: `${LARGEFONTSIZES.CALENDAR_SMALL_LABEL_TEXT}px`,
});

const CALENDAR_ABBR_TEXT = theme('fontSize', {
  default: `${DEFAULTFONTSIZES.CALENDAR_ABBR_TEXT}px`,
  large: `${LARGEFONTSIZES.CALENDAR_ABBR_TEXT}px`,
});

const CALENDAR_DAY_TEXT = theme('fontSize', {
  default: `${DEFAULTFONTSIZES.CALENDAR_DAY_TEXT}px`,
  large: `${LARGEFONTSIZES.CALENDAR_DAY_TEXT}px`,
});

const CHIP_TEXT = theme('fontSize', {
  default: `${DEFAULTFONTSIZES.CHIP_TEXT}px`,
  large: `${LARGEFONTSIZES.CHIP_TEXT}px`,
});

const CHIP_SUBTEXT = theme('fontSize', {
  default: `${DEFAULTFONTSIZES.CHIP_SUBTEXT}px`,
  large: `${LARGEFONTSIZES.CHIP_SUBTEXT}px`,
});

const ENTITYCARD_TEXT = theme('fontSize', {
  default: `${DEFAULTFONTSIZES.ENTITYCARD_TEXT}px`,
  large: `${LARGEFONTSIZES.ENTITYCARD_TEXT}px`,
});

const ENTITYCARD_SUBTEXT = theme('fontSize', {
  default: `${DEFAULTFONTSIZES.ENTITYCARD_SUBTEXT}px`,
  large: `${LARGEFONTSIZES.ENTITYCARD_SUBTEXT}px`,
});

const SELECTEDENTITY_TEXT = theme('fontSize', {
  default: `${DEFAULTFONTSIZES.SELECTEDENTITY_TEXT}px`,
  large: `${LARGEFONTSIZES.SELECTEDENTITY_TEXT}px`,
});

const SELECTEDENTITY_SUBTEXT = theme('fontSize', {
  default: `${DEFAULTFONTSIZES.SELECTEDENTITY_SUBTEXT}px`,
  large: `${LARGEFONTSIZES.SELECTEDENTITY_SUBTEXT}px`,
});

const TABLE_TEXT = theme('fontSize', {
  default: `${DEFAULTFONTSIZES.TABLE_TEXT}px`,
  large: `${LARGEFONTSIZES.TABLE_TEXT}px`,
});

const TEXTAREA_TEXT = theme('fontSize', {
  default: `${DEFAULTFONTSIZES.TEXTAREA_TEXT}px`,
  large: `${LARGEFONTSIZES.TEXTAREA_TEXT}px`,
});

const TEXTINPUT_TEXT = theme('fontSize', {
  default: `${DEFAULTFONTSIZES.TEXTINPUT_TEXT}px`,
  large: `${LARGEFONTSIZES.TEXTINPUT_TEXT}px`,
});

const TEXTINPUT_ICON_TEXT = theme('fontSize', {
  default: `${DEFAULTFONTSIZES.TEXTINPUT_ICON_TEXT}px`,
  large: `${LARGEFONTSIZES.TEXTINPUT_ICON_TEXT}px`,
});

const INPUTERROR_TEXT = theme('fontSize', {
  default: `${DEFAULTFONTSIZES.INPUTERROR_TEXT}px`,
  large: `${LARGEFONTSIZES.INPUTERROR_TEXT}px`,
});

const SELECT_TEXT = theme('fontSize', {
  default: `${DEFAULTFONTSIZES.SELECT_TEXT}px`,
  large: `${LARGEFONTSIZES.SELECT_TEXT}px`,
});

const TIMESTAMP = theme('fontSize', {
  default: `${DEFAULTFONTSIZES.TIMESTAMP}px`,
  large: `${LARGEFONTSIZES.TIMESTAMP}px`,
});

const FONTSIZE_THEMES = {
  HEADING1,
  HEADING2,
  HEADING3,
  HEADING4,
  HEADING5,
  TEXT,
  METATEXT,
  EMPHASISTEXT,
  BADGETEXT,
  SQUAREBADGETEXT,
  CARDTEXT,
  CALENDAR_LABEL_TEXT,
  CALENDAR_SMALL_LABEL_TEXT,
  CALENDAR_ABBR_TEXT,
  CALENDAR_DAY_TEXT,
  CHIP_TEXT,
  CHIP_SUBTEXT,
  ENTITYCARD_TEXT,
  ENTITYCARD_SUBTEXT,
  SELECTEDENTITY_TEXT,
  SELECTEDENTITY_SUBTEXT,
  TABLE_TEXT,
  TEXTAREA_TEXT,
  TEXTINPUT_TEXT,
  TEXTINPUT_ICON_TEXT,
  INPUTERROR_TEXT,
  SELECT_TEXT,
  TIMESTAMP,
};

export default FONTSIZE_THEMES;
