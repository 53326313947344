import React, { useState } from 'react';
import { Widget } from '../../../elements';
import Bubble from '../../Common/Bubble';
import { GraphContainer } from './styles';
import HorizontalBar from './HorizontalBar';
import COLORS from '../../../styles/colors';

interface HorizontalBarWidgetProps {
  key: string;
  barColor?: string;
  barColors?: string[];
  breakpointWidth: string;
  btnDataTestId?: string | undefined;
  btnOnClick?: () => void | undefined;
  btnText?: string | undefined;
  bubbleChildren?: React.ReactNode | null;
  countKey: string;
  formatMultiColorDataCallback?: (props: unknown) => object[] | undefined;
  graphData: { [key: string]: string | number | (() => void) }[];
  graphDataName: string;
  subheader?: string | React.ReactNode | HTMLElement | undefined;
  title: string;
  total: number;
  upperBound?: number | undefined;
}

const HorizontalBarWidget = ({
  key,
  barColor = COLORS.PK.GOLD_100,
  barColors = undefined,
  breakpointWidth,
  btnDataTestId = undefined,
  btnOnClick = undefined,
  btnText = undefined,
  bubbleChildren = null,
  countKey,
  formatMultiColorDataCallback = undefined,
  graphData,
  graphDataName,
  subheader = '',
  title,
  total,
  upperBound = undefined,
}: HorizontalBarWidgetProps) => {
  const barUpperBound = upperBound || total;

  const [isHovering, setIsHovering] = useState(false);
  return (
    <Widget
      title={title}
      subheader={subheader}
      total={total}
      breakpointWidth={breakpointWidth}
      btnText={btnText}
      btnOnClick={btnOnClick}
      btnDataTestId={btnDataTestId}
    >
      {bubbleChildren && (
        <Bubble
          isOpen={isHovering}
          onClickOutside={() => setIsHovering(false)}
          moveBubbleRightVal={20}
          moveLeftVal={201}
          bubbleWidth={160}
          top="0px"
          flipYAxis
          zIndex={101}
          dataTestId="UnclaimedMultiGroupWidget-sort-by-bubble"
        >
          {/* @ts-expect-error should resolve when bubble is converted to ts */}
          {bubbleChildren}
        </Bubble>
      )}
      <GraphContainer
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {graphData.map((graphDatum, i) => {
          return (
            <HorizontalBar
              key={key}
              name={graphDatum[graphDataName] as string}
              count={graphDatum[countKey] as number}
              barColor={barColors && barColors[i] ? barColors[i] : barColor}
              barColors={barColors}
              total={barUpperBound}
              secondaryText={graphDatum.secondaryText as string}
              overridePercentage={graphDatum.overridePercentage as number}
              nameOnClick={
                graphDatum.nameOnClick
                  ? (graphDatum.nameOnClick as () => void)
                  : null
              }
              formatMultiColorDataCallback={formatMultiColorDataCallback}
              {...graphDatum} // Makes nested data available to use in callback
            />
          );
        })}
      </GraphContainer>
    </Widget>
  );
};

const areEqual = (
  prevProps: HorizontalBarWidgetProps,
  nextProps: HorizontalBarWidgetProps
) => prevProps.total === nextProps.total && prevProps.key === nextProps.key;

export default React.memo(HorizontalBarWidget, areEqual);
