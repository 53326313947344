// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
  height: 100%;
}

body {
  background-color: #ecedec;
  color: #555459;
  margin: 0;
  padding: 0;
  font-family: 'Barlow', Sans-Serif;
  font-size: 12px;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  background: none;
  border: none;
  font-size: 1rem;
  color: inherit;
}

ul {
  padding: 0;
}

/* textarea:focus, input:focus {
  outline: #09f auto 1px;
} */

#root {
  display: flex;
  height: 100%;
}

a:link, a:visited, a:hover, a:active {
  text-decoration: none;
  color: inherit;
}

.ReactModal__Body--open {
    overflow: hidden;
}

.accordion-hide-scroll::-webkit-scrollbar {
  display: none;
}
/*
  React-modal fade in animation
  based on: http://reactcommunity.org/react-modal/styles/transitions/
*/

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 100ms cubic-bezier(.08,.28,.92,.84);
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.ReactModal__Content{
  margin-top: 50px;
  opacity: 0;
  transition: margin-top 100ms cubic-bezier(.08,.28,.92,.84), opacity 100ms cubic-bezier(.08,.28,.92,.84);
}

.ReactModal__Content--after-open{
  margin-top: 0;
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/assets/index.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,SAAS;EACT,UAAU;EACV,iCAAiC;EACjC,eAAe;EACf,WAAW;EACX,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,UAAU;AACZ;;AAEA;;GAEG;;AAEH;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,cAAc;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;EACE,aAAa;AACf;AACA;;;CAGC;;AAED;EACE,UAAU;EACV,uDAAuD;AACzD;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,uGAAuG;AACzG;;AAEA;EACE,aAAa;EACb,UAAU;AACZ","sourcesContent":["html, body {\n  height: 100%;\n}\n\nbody {\n  background-color: #ecedec;\n  color: #555459;\n  margin: 0;\n  padding: 0;\n  font-family: 'Barlow', Sans-Serif;\n  font-size: 12px;\n  width: 100%;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\nbutton {\n  background: none;\n  border: none;\n  font-size: 1rem;\n  color: inherit;\n}\n\nul {\n  padding: 0;\n}\n\n/* textarea:focus, input:focus {\n  outline: #09f auto 1px;\n} */\n\n#root {\n  display: flex;\n  height: 100%;\n}\n\na:link, a:visited, a:hover, a:active {\n  text-decoration: none;\n  color: inherit;\n}\n\n.ReactModal__Body--open {\n    overflow: hidden;\n}\n\n.accordion-hide-scroll::-webkit-scrollbar {\n  display: none;\n}\n/*\n  React-modal fade in animation\n  based on: http://reactcommunity.org/react-modal/styles/transitions/\n*/\n\n.ReactModal__Overlay {\n  opacity: 0;\n  transition: opacity 100ms cubic-bezier(.08,.28,.92,.84);\n}\n\n.ReactModal__Overlay--after-open{\n  opacity: 1;\n}\n\n.ReactModal__Overlay--before-close{\n  opacity: 0;\n}\n\n.ReactModal__Content{\n  margin-top: 50px;\n  opacity: 0;\n  transition: margin-top 100ms cubic-bezier(.08,.28,.92,.84), opacity 100ms cubic-bezier(.08,.28,.92,.84);\n}\n\n.ReactModal__Content--after-open{\n  margin-top: 0;\n  opacity: 1;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
