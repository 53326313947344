import React, { useEffect, useRef, type RefObject } from 'react';
import {
  $getSelection,
  $isRangeSelection,
  $createParagraphNode,
  type LexicalEditor,
} from 'lexical';
import { $wrapNodes } from '@lexical/selection';
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';
import { $createHeadingNode, $createQuoteNode } from '@lexical/rich-text';
import styled from 'styled-components';
import { type BlockType } from './Toolbar';

const DropdownWrapper = styled.div`
  z-index: 100;
  display: block;
  position: fixed;
  box-shadow:
    0 12px 28px 0 rgba(0, 0, 0, 0.2),
    0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  min-width: 100px;
  min-height: 40px;
  background-color: #fff;

  .item {
    margin: 0 8px;
    padding: 8px;
    color: #050505;
    cursor: pointer;
    line-height: 16px;
    font-size: 15px;
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 8px;
    border: 0;
    min-width: 268px;

    .active {
      display: flex;
      width: 20px;
      height: 20px;
      background-size: contain;
    }

    &:first-child {
      margin-top: 8px;
    }

    &:last-child {
      margin-bottom: 8px;
    }

    &:hover {
      background-color: var(--button-background-color-hover, #ffedd1);
    }

    .text {
      display: flex;
      line-height: 20px;
      flex-grow: 1;
      width: 200px;
    }

    .icon {
      display: flex;
      width: 20px;
      height: 20px;
      user-select: none;
      margin-right: 12px;
      line-height: 16px;
      background-size: contain;
      margin-top: 1.5px;
    }
  }
`;

interface BlockOptionsDropdownListProps {
  editor: LexicalEditor;
  blockType: string;
  toolbarRef: RefObject<HTMLDivElement>;
  showBlockOptionsDropDown: boolean;
  setShowBlockOptionsDropDown: (show: boolean) => void;
}

const BlockOptionsDropdownList = ({
  editor,
  blockType,
  toolbarRef,
  showBlockOptionsDropDown,
  setShowBlockOptionsDropDown,
}: BlockOptionsDropdownListProps) => {
  const dropDownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const toolbar = toolbarRef.current;
    const dropDown = dropDownRef.current;

    if (toolbar !== null && dropDown !== null) {
      const { top, left } = toolbar.getBoundingClientRect();
      dropDown.style.top = `${top + 40}px`;
      dropDown.style.left = `${left}px`;
    }
  }, [dropDownRef, toolbarRef]);

  useEffect(() => {
    const dropDown = dropDownRef.current;
    const toolbar = toolbarRef.current;

    if (dropDown !== null && toolbar !== null) {
      const handle = (event: MouseEvent) => {
        const { target } = event;

        if (
          !dropDown.contains(target as Node) &&
          !toolbar.contains(target as Node)
        ) {
          setShowBlockOptionsDropDown(false);
        }
      };
      document.addEventListener('click', handle);

      return () => {
        document.removeEventListener('click', handle);
      };
    }

    return undefined;
  }, [dropDownRef, setShowBlockOptionsDropDown, toolbarRef]);

  useEffect(() => {
    const handleButtonPositionUpdate = () => {
      if (showBlockOptionsDropDown) {
        const toolbar = toolbarRef.current;
        const dropDown = dropDownRef.current;
        if (toolbar !== null && dropDown !== null) {
          const { top } = toolbar.getBoundingClientRect();
          const newPosition = top + 40;
          if (newPosition !== dropDown.getBoundingClientRect().top) {
            dropDown.style.top = `${newPosition}px`;
          }
        }
      }
    };

    document.addEventListener('scroll', handleButtonPositionUpdate);

    return () => {
      document.removeEventListener('scroll', handleButtonPositionUpdate);
    };
  }, [showBlockOptionsDropDown, toolbarRef]);

  const formatBlock = (type: BlockType) => {
    if (blockType !== type) {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          if (['h1', 'h2', 'h3'].includes(type)) {
            $wrapNodes(selection, () =>
              $createHeadingNode(type as 'h1' | 'h2' | 'h3')
            );
            return;
          }
          if (type === 'quote') {
            $wrapNodes(selection, () => $createQuoteNode());
            return;
          }
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatBulletList = () => {
    if (blockType !== 'ul') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatNumberedList = () => {
    if (blockType !== 'ol') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
    setShowBlockOptionsDropDown(false);
  };

  return (
    <DropdownWrapper
      ref={dropDownRef}
      data-testid="rich-text-editor-text-formats-lists-dropdown"
    >
      <button
        type="button"
        className="item"
        onClick={() => formatBlock('paragraph')}
        data-testid="rich-text-editor-paragraph-button"
      >
        <span className="icon ri-text" />
        <span className="text">Normal</span>
        {blockType === 'paragraph' && <span className="active" />}
      </button>
      <button
        type="button"
        className="item"
        onClick={() => formatBlock('h1')}
        data-testid="rich-text-editor-h1-button"
      >
        <span className="icon ri-h-1" />
        <span className="text">Heading 1</span>
        {blockType === 'h1' && <span className="active" />}
      </button>
      <button
        type="button"
        className="item"
        onClick={() => formatBlock('h2')}
        data-testid="rich-text-editor-h2-button"
      >
        <span className="icon ri-h-2" />
        <span className="text">Heading 2</span>
        {blockType === 'h2' && <span className="active" />}
      </button>
      <button
        type="button"
        className="item"
        onClick={() => formatBlock('h3')}
        data-testid="rich-text-editor-h3-button"
      >
        <span className="icon ri-h-3" />
        <span className="text">Heading 3</span>
        {blockType === 'h3' && <span className="active" />}
      </button>
      <button
        type="button"
        className="item"
        onClick={() => formatBlock('quote')}
        data-testid="rich-text-editor-quote-button"
      >
        <span className="icon ri-chat-quote-line" />
        <span className="text">Quote</span>
        {blockType === 'quote' && <span className="active" />}
      </button>
      <button
        type="button"
        className="item"
        onClick={() => formatBulletList()}
        data-testid="rich-text-editor-unordered-list-button"
      >
        <span className="icon ri-list-unordered" />
        <span className="text">Bulleted List</span>
        {blockType === 'ul' && <span className="active" />}
      </button>
      <button
        type="button"
        className="item"
        onClick={() => formatNumberedList()}
        data-testid="rich-text-editor-numbered-list-button"
      >
        <span className="icon ri-list-ordered" />
        <span className="text">Numbered List</span>
        {blockType === 'ol' && <span className="active" />}
      </button>
    </DropdownWrapper>
  );
};

export default BlockOptionsDropdownList;
