import React from 'react';
import styled from 'styled-components';

const AnimatedLoadingSpinner = styled.i`
  animation: spin 3s linear infinite;
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const LoadingSpinner = () => {
  return <AnimatedLoadingSpinner className="ri-loader-line" />;
};

export default LoadingSpinner;
