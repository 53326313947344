import React from 'react';
import PropTypes from 'prop-types';
import MaterialSwitch from '@mui/material/Switch';
import styled from 'styled-components';
import THEMES from '../../styles/themes/library/switch';

const StyledMaterialSwitch = styled(MaterialSwitch)`
  width: 46px !important;
  padding: 0 !important;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')} !important;
  height: auto !important;

  .MuiSwitch-colorPrimary.Mui-checked,
  .MuiSwitch-colorPrimary.Mui-checked:hover {
    color: ${THEMES.SWITCH};
    background-color: transparent;
  }
  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: ${THEMES.BACKGROUND_CHECKED};
    opacity: 1;
  }
  .MuiSwitch-colorPrimary.Mui-disabled + .MuiSwitch-track {
    background-color: ${(props) =>
      props.checked ? THEMES.BACKGROUND_CHECKED : THEMES.BACKGROUND_UNCHECKED};
    opacity: 1;
  }

  .MuiSwitch-colorPrimary.Mui-checked.Mui-disabled,
  .MuiSwitch-colorPrimary.Mui-disabled {
    color: ${THEMES.DISABLED_SWITCH};
  }

  .MuiSwitch-track {
    border-radius: 13px;
    height: 24px;
    background-color: ${THEMES.BACKGROUND_UNCHECKED};
    opacity: 1;
    border: 1px solid ${THEMES.BORDER};
    ${(props) => props?.customtrackstyle?.(props)}
  }
  .MuiButtonBase-root {
    padding: 0;
  }
  .MuiSwitch-thumb {
    width: 24px;
    height: 24px;
    border: 1px solid ${THEMES.BORDER};
    box-shadow: none;
    ${(props) => props?.customthumbstyle?.(props)}
  }

  ${(props) => props?.customcontainerstyle?.(props)}
`;

const Switch = React.forwardRef(
  (
    {
      checked,
      onCheck,
      disabled,
      customContainerStyle,
      customThumbStyle,
      customTrackStyle,
      dataTestId,
      ...props
    },
    ref
  ) => (
    <StyledMaterialSwitch
      disableRipple
      checked={checked}
      onChange={onCheck}
      disabled={disabled}
      customcontainerstyle={customContainerStyle}
      customthumbstyle={customThumbStyle}
      customtrackstyle={customTrackStyle}
      ref={ref}
      data-testid={dataTestId}
      {...props}
    />
  )
);

Switch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  customContainerStyle: PropTypes.func,
  customThumbStyle: PropTypes.func,
  customTrackStyle: PropTypes.func,
  dataTestId: PropTypes.string,
};

Switch.defaultProps = {
  disabled: false,
  customContainerStyle: null,
  customThumbStyle: null,
  customTrackStyle: null,
  dataTestId: undefined,
};

Switch.displayName = 'Switch';

export default Switch;
