import React from 'react';
import styled from 'styled-components';
import FONTSIZE_THEMES from '../../styles/themes/fontSize/fontSize';
import THEMES from '../../styles/themes/library/table';

const TableBodyStyled = styled.tbody`
  font-size: ${FONTSIZE_THEMES.TABLE_TEXT};
  font-weight: 500;
  background-color: ${THEMES.BODY_BACKGROUND};
  color: ${THEMES.BODY_TEXT};

  td {
    height: 45px;
    padding-right: 16px;
  }

  td:first-child {
    padding-left: 16px;
  }

  td p,
  td h1,
  td h2,
  td h3,
  td h4,
  td h5 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${(props) => props?.customStyle?.()}
`;

const TableBody = ({ ...props }) => <TableBodyStyled {...props} />;

export default TableBody;
