import { useDispatch, useSelector } from 'react-redux';
import { resetActiveThread } from '../../actions';
import useWatchForReturnToInbox from '../../components/Threads/useWatchForReturnToInbox';

const useWatchRtiGlobal = () => {
  const dispatch = useDispatch();
  const contactId = useSelector((state) => state.session.currentUser.contactId);
  const threadsActiveGroupIds = useSelector(
    (state) => state.session.threadsActiveGroupIds
  );
  const activeThreadId = useSelector(
    (state) => state.threadInfo.activeThreadId
  );

  const onReturnToInbox = (returnedToInboxEvent) => {
    if (returnedToInboxEvent && returnedToInboxEvent.id === activeThreadId) {
      dispatch(resetActiveThread());
    }
  };

  useWatchForReturnToInbox({
    threadsActiveGroupIds,
    contactId,
    onReturnToInbox,
  });
};

export default useWatchRtiGlobal;
