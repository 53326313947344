import { useState } from 'react';

/*
DOCUMENTATION

useAction is designed to be used in several components.
Holds error/success messages/states after different queries/mutations.

  return:
    success: (bool) If success !== '' it means a succeed operation. It will hold the message we want to show to the UI.
    error: (bool) If error !== '' it means a failed operation. It will hold the message we want to show to the UI.
    loading: (bool) If loading === true it means a loading operation.
    handleSetLoading: Function callback that will update the loading state.
    handleSuccessStateChange: (func) Function callback that will update the success state.
    handleErrorChange: (func) Function callback that will update the error state.
    clear: (func) Callback function that will reset the whole state.
*/

// Clear states after certain amount of time.
const clearAfter = 5000;

const useAction = () => {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const handleErrorChange = (errorMessage) => {
    setError(errorMessage);
    setSuccess('');
  };

  const handleSuccessStateChange = (successMessage) => {
    setSuccess(successMessage);
    setError('');
  };

  const clear = () => {
    setTimeout(() => {
      setError('');
      setSuccess('');
      setLoading(false);
    }, clearAfter);
  };

  const handleSetLoading = (loading) => {
    setLoading(loading);
  };

  return {
    success,
    error,
    loading,
    setSuccess: handleSuccessStateChange,
    setError: handleErrorChange,
    setLoading: handleSetLoading,
    clear,
  };
};

export default useAction;
